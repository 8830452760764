import { useMemo } from 'react';
import { formattedDate, getTranslation, useAppIntl } from 'app/helpers';
import { FORMATS_EXTENDED_DATE_AND_TIME } from 'app/shared';
import { Locale, PatientBadgeMerge } from 'app/types';

export const useItems = (patientBadgeMerges: PatientBadgeMerge[] | undefined) => {
  const { formatMessage, locale } = useAppIntl();

  return useMemo(
    () =>
      patientBadgeMerges
        ? patientBadgeMerges?.map((patientBadgeMerge) => [
            {
              label: formatMessage({ id: 'CORE.LABEL.PATIENT-SOURCE' }),
              value:
                getTranslation(patientBadgeMerge.sourceClinicPatient) ||
                getTranslation(patientBadgeMerge.sourceClinicPatient, Locale.en) ||
                getTranslation(patientBadgeMerge.sourceClinicPatient, Locale.ar),
            },
            {
              label: formatMessage({ id: 'CORE.LABEL.TARGET-PATIENT' }),
              value:
                getTranslation(patientBadgeMerge.destinationClinicPatient) ||
                getTranslation(patientBadgeMerge.destinationClinicPatient, Locale.en) ||
                getTranslation(patientBadgeMerge.destinationClinicPatient, Locale.ar),
            },
            {
              label: formatMessage({ id: 'CORE.LABEL.MEDICAL-INSTITUTION' }),
              value:
                getTranslation(patientBadgeMerge.medicalInstitution) ||
                getTranslation(patientBadgeMerge.medicalInstitution, Locale.en) ||
                getTranslation(patientBadgeMerge.medicalInstitution, Locale.ar),
            },
            {
              label: formatMessage({ id: 'APPOINTMENTS.TEXT.CONFIRMED' }),
              value:
                getTranslation(patientBadgeMerge.confirmedBy, locale) ||
                getTranslation(patientBadgeMerge.confirmedBy, Locale.en) ||
                getTranslation(patientBadgeMerge.confirmedBy, Locale.ar),
            },
            {
              label: formatMessage({ id: 'APPOINTMENTS.TEXT.ACTION-DATE' }),
              value: formattedDate(patientBadgeMerge.confirmedAt, FORMATS_EXTENDED_DATE_AND_TIME),
            },
          ])
        : [],
    [patientBadgeMerges, formatMessage, locale],
  );
};
