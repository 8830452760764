import { takeEvery, throttle } from 'redux-saga/effects';
import {
  UPDATE_COVERED_BILLING_STATUSES_SUCCESS,
  UPDATE_NOT_COVERED_BILLING_STATUSES_SUCCESS,
  WS_REFRESH_BILLING_ITEMS_STATUSES,
  WS_REFRESH_ORDERS_STATUSES,
} from 'app/redux/doctorAssessment/doctorAssessment.types';
import { wsUpdateBillingItemsStatuses } from 'app/sagas/assessment/generators/wsUpdateBillingItemsStatuses';
import {
  WS_FETCH_COVERED_BILLING_ITEMS_STATUSES,
  WS_FETCH_NOT_COVERED_BILLING_ITEMS_STATUSES,
  WS_FETCH_ORDERS_STATUSES,
} from 'app/sagas/assessment/types';
import { fetchCoveredBillingItemsStatuses } from 'app/sagas/assessment/generators/fetchCoveredBillingItemsStatuses';
import { refreshLocalStatuses } from 'app/sagas/assessment/generators/refreshLocalStatuses';
import { fetchNotCoveredBillingItemsStatuses } from 'app/sagas/assessment/generators/fetchNotCoveredBillingItemsStatuses';
import { wsUpdateOrdersStatuses } from 'app/sagas/assessment/generators/wsUpdateOrdersStatuses';
import { fetchOrdersStatuses } from 'app/sagas/assessment/generators/fetchOrdersStatuses';

function* assessmentSaga() {
  yield takeEvery(WS_REFRESH_BILLING_ITEMS_STATUSES, wsUpdateBillingItemsStatuses);
  yield takeEvery(WS_REFRESH_ORDERS_STATUSES, wsUpdateOrdersStatuses);
  yield throttle(3000, WS_FETCH_COVERED_BILLING_ITEMS_STATUSES, fetchCoveredBillingItemsStatuses);
  yield throttle(3000, WS_FETCH_NOT_COVERED_BILLING_ITEMS_STATUSES, fetchNotCoveredBillingItemsStatuses);
  yield throttle(3000, WS_FETCH_ORDERS_STATUSES, fetchOrdersStatuses);
  yield takeEvery(
    [UPDATE_COVERED_BILLING_STATUSES_SUCCESS, UPDATE_NOT_COVERED_BILLING_STATUSES_SUCCESS],
    refreshLocalStatuses,
  );
}

export default assessmentSaga;
