import { useMemo } from 'react';
import { useAppIntl } from 'app/helpers';
import { InvoicePayBy, Option } from 'app/types';

export const usePayByOptions = () => {
  const { formatMessage } = useAppIntl();

  return useMemo<Option<InvoicePayBy>[]>(() => {
    return [
      {
        label: formatMessage({ id: 'CORE.LABEL.INSURANCE', defaultMessage: 'Insurance' }),
        value: InvoicePayBy.Insurance,
      },
      {
        label: formatMessage({ id: 'CORE.LABEL.PATIENT', defaultMessage: 'Patient' }),
        value: InvoicePayBy.Patient,
      },
    ];
  }, [formatMessage]);
};
