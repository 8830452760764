import { Option } from 'app/types';
import { useMemo } from 'react';
import { formattedPeriod, useAppSelector, useAppIntl, getPatientFullName } from 'app/helpers';
import { FORMS_BOOKING_WIZARD } from 'app/shared';

interface Props {
  selectedSubSpeciality: Option | null;
  selectedService: Option | null;
  selectedTime: { from: Date; to?: Date };
}

export const useStepHeaderTexts = ({ selectedSubSpeciality, selectedService, selectedTime }: Props) => {
  const { locale } = useAppIntl();
  const formValues = useAppSelector((state) => state.form[FORMS_BOOKING_WIZARD]?.values);
  const selectedPatient = useAppSelector((state) => state.bookingWizard.selectedPatient);

  const subSpecialityText = useMemo(() => selectedSubSpeciality?.label || '', [selectedSubSpeciality]);
  const serviceText = useMemo(() => selectedService?.label || '', [selectedService]);
  const timeText = useMemo(
    () => (selectedTime.from && selectedTime.to ? formattedPeriod(selectedTime.from, selectedTime.to) : ''),
    [selectedTime],
  );
  const patientText = useMemo(
    () => (selectedPatient ? formValues?.dependant?.label || getPatientFullName(selectedPatient, locale) : ''),
    [formValues, locale, selectedPatient],
  );

  return {
    subSpecialityText,
    serviceText,
    timeText,
    patientText,
  };
};
