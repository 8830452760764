import moment from 'moment';
import { Button } from 'reactstrap';
import { formatDateToTime, getApiDateAndTime } from 'app/helpers';
import { CalendarSlot } from 'app/types';

interface Props {
  date: string;
  productDuration: number;
  selectSlot: (date: string, slot?: CalendarSlot) => void;
  slots: CalendarSlot[];
  workingTimeEnd: string;
}

const generateCustomEnd = (start: string, workingTimeEnd: string, productDuration: number): string => {
  const end = moment(start).add(productDuration, 'minute');
  return end.isAfter(moment(workingTimeEnd)) ? workingTimeEnd : getApiDateAndTime(end.toDate()); // end cannot extend beyond working time
};

export const TimeFormatterSlots = ({ date, productDuration, selectSlot, slots, workingTimeEnd }: Props) => {
  return (
    <div className="timeFormatter__slots">
      {slots.map((slot) => {
        const numberOfAppointments = slot.overlappingAppointmentsIds.length;
        const hasAppointments = !!numberOfAppointments;
        const end = hasAppointments ? generateCustomEnd(slot.start, workingTimeEnd, productDuration) : slot.end;

        return (
          <Button
            className="timeFormatter__slot"
            color={hasAppointments ? 'danger' : 'success'}
            key={slot.start}
            onClick={() => selectSlot(date, { ...slot, end })}
            outline
          >
            {formatDateToTime(slot.start)}
            {!!numberOfAppointments && ` (${numberOfAppointments})`}
          </Button>
        );
      })}
    </div>
  );
};
