import { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from 'app/helpers';
import { fetchAppointment, resetAppointment, resetTicket } from 'app/redux/appointment/appointment.actions';

export const useAppointmentData = () => {
  const dispatch = useAppDispatch();
  const ticket = useAppSelector((state) => state.appointment.ticket);
  const isWaiting = !!ticket?.isWaiting;

  useEffect(() => {
    if (ticket) {
      dispatch(fetchAppointment(ticket.id, isWaiting));
    }

    return () => {
      dispatch(resetTicket());
      dispatch(resetAppointment());
    };
  }, [dispatch, isWaiting, ticket]);
};
