import { put, select } from 'redux-saga/effects';
import { RootState, InvoicesRequestsItemsTabs } from 'app/types';
import { invoicesApi } from 'app/services/invoices/invoices';

export function* refreshInvoicesRequest() {
  const {
    router: {
      location: { pathname },
    },
    queryParams: { invoicesRequests: params },
  }: RootState = yield select();

  const isDiscardedRequestsPage = pathname.includes(`invoices/${InvoicesRequestsItemsTabs.InvoicesDiscardItems}`);
  yield put<any>(
    invoicesApi.endpoints.getInvoicesRequests.initiate(
      { ...params, discarded: isDiscardedRequestsPage.toString() },
      { subscribe: false, forceRefetch: true },
    ),
  );
}
