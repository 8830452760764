import humanizeDuration from 'humanize-duration';

const getArabicForm = (c: number | undefined) => {
  if (c === 1) {
    return 0;
  }
  if (c === 2) {
    return 1;
  }
  if (c && c > 2 && c < 11) {
    return 2;
  }
  return 0;
};

/**
 * Returns a humanized version of the duration
 * @example durationHumanizer(1200); -> '1.2 seconds'
 */
export const durationHumanizer = humanizeDuration.humanizer({
  languages: {
    ar: {
      y: (c) => {
        return ['سنة', 'سنتان', 'سنوات'][getArabicForm(c)];
      },
      mo: (c) => {
        return ['شهر', 'شهران', 'أشهر'][getArabicForm(c)];
      },
      w: (c) => {
        return ['أسبوع', 'أسبوعين', 'أسابيع'][getArabicForm(c)];
      },
      d: (c) => {
        return ['يوم', 'يومين', 'أيام'][getArabicForm(c)];
      },
      h: (c) => {
        return ['ساعة', 'ساعتين', 'ساعات'][getArabicForm(c)];
      },
      m: (c) => {
        return ['دقيقة', 'دقيقتان', 'دقائق'][getArabicForm(c)];
      },
      s: (c) => {
        return ['ثانية', 'ثانيتان', 'ثواني'][getArabicForm(c)];
      },
      ms: (c) => {
        return ['جزء من الثانية', 'جزآن من الثانية', 'أجزاء من الثانية'][getArabicForm(c)];
      },
      // @ts-ignore
      decimal: ',',
      delimiter: ' و ',
    },
  },
});
