import { BillingItemSourceType, InvoiceEligibility, InvoiceItem, InvoicePayBy, InvoiceType } from 'app/types';
import { checkIsOldInvoice } from 'app/features/invoicingProcess/process/helpers';
import { PaymentModeFormData } from 'app/features/invoicingProcess/process/paymentMode/form/types';

interface Params {
  invoiceType: InvoiceType | undefined;
  isPrimaryCareSpeciality: boolean;
  item: InvoiceItem;
  paymentModeValues: Partial<PaymentModeFormData>;
}
export const getPatientShare = ({ invoiceType, isPrimaryCareSpeciality, item, paymentModeValues }: Params): number => {
  if (paymentModeValues.payBy?.value === InvoicePayBy.Insurance) {
    // 1. Shared percent for pay by insurance mode

    if (checkIsOldInvoice(invoiceType)) {
      // 1.1 Shared percent for old invoices
      return Number(paymentModeValues.patientShare || 0);
    }

    if (paymentModeValues.eligibility?.value === InvoiceEligibility.ReferralIn) {
      // 1.2 Shared percent for Referral-in eligibility
      return Number(paymentModeValues.referralToSpecializedClinicPatientShare || 0);
    }

    if (item.billingItemSourceType.value === BillingItemSourceType.Medication) {
      // 1.3 Shared percent for medication item

      if (item.billingItem.details?.isReplaceableMedication) {
        // 1.3.1 Shared percent for replaceable medication item
        return Number(paymentModeValues.replaceableBrandMedicationsPatientShare || 0);
      }

      // 1.3.2 Shared percent for non-replaceable medication item
      return Number(paymentModeValues.nonReplaceableBrandMedicationsPatientShare || 0);
    }

    // 1.4 For other cases, the shared percent is based on the isPrimaryCareSpeciality value
    return isPrimaryCareSpeciality
      ? Number(paymentModeValues.primaryClinicPatientShare || 0)
      : Number(paymentModeValues.specializedClinicPatientShare || 0);
  }

  // 2. Return 100% if not pay by insurance
  return 100;
};
