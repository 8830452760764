import { Option } from 'app/types';

export type Item = {
  clinicyId?: string;
  nameAr: string;
  nameEn: string;
  id: string;
};

export const generateOption = (item: Item): Option => ({
  label: item.nameEn,
  value: item.id,
  clinicyId: item.clinicyId,
  translation: {
    en: item.nameEn,
    ar: item.nameAr,
  },
});

export const generateOptions = (items: Item[]): Option[] => items.map((item) => generateOption(item));
