import { useMemo } from 'react';
import { FormattedMessage } from 'react-intl';
import { getPriceLocaleString } from 'app/helpers';

interface Props {
  amount: number | string | null;
  withSign?: boolean;
}

export const FormattedPrice = ({ amount, withSign = false }: Props) => {
  const amountValue = useMemo(() => getPriceLocaleString(amount, withSign), [amount, withSign]);

  return <FormattedMessage id="CORE.TEXT.PRICE-SAR" values={{ amount: amountValue }} />;
};
