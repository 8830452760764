export const FETCH_BRANCH_REQUEST = 'branch/FETCH_BRANCH_REQUEST';
export const FETCH_BRANCH_SUCCESS = 'branch/FETCH_BRANCH_SUCCESS';
export const FETCH_BRANCH_FAILURE = 'branch/FETCH_BRANCH_FAILURE';

export const ADD_BRANCH_REQUEST = 'branch/ADD_BRANCH_REQUEST';
export const ADD_BRANCH_SUCCESS = 'branch/ADD_BRANCH_SUCCESS';
export const ADD_BRANCH_FAILURE = 'branch/ADD_BRANCH_FAILURE';

export const EDIT_BRANCH_REQUEST = 'branch/EDIT_BRANCH_REQUEST';
export const EDIT_BRANCH_SUCCESS = 'branch/EDIT_BRANCH_SUCCESS';
export const EDIT_BRANCH_FAILURE = 'branch/EDIT_BRANCH_FAILURE';

export const DELETE_BRANCH_REQUEST = 'branch/DELETE_BRANCH_REQUEST';
export const DELETE_BRANCH_SUCCESS = 'branch/DELETE_BRANCH_SUCCESS';
export const DELETE_BRANCH_FAILURE = 'branch/DELETE_BRANCH_FAILURE';

export const EDIT_BRANCH_IMAGES_REQUEST = 'branch/EDIT_BRANCH_IMAGES_REQUEST';
export const EDIT_BRANCH_IMAGES_SUCCESS = 'branch/EDIT_BRANCH_IMAGES_SUCCESS';
export const EDIT_BRANCH_IMAGES_FAILURE = 'branch/EDIT_BRANCH_IMAGES_FAILURE';

export const ADD_BRANCH_WORKING_HOURS_REQUEST = 'branch/ADD_BRANCH_WORKING_HOURS_REQUEST';
export const ADD_BRANCH_WORKING_HOURS_SUCCESS = 'branch/ADD_BRANCH_WORKING_HOURS_SUCCESS';
export const ADD_BRANCH_WORKING_HOURS_FAILURE = 'branch/ADD_BRANCH_WORKING_HOURS_FAILURE';

export const REMOVE_BRANCH_WORKING_HOURS_REQUEST = 'branch/REMOVE_BRANCH_WORKING_HOURS_REQUEST';
export const REMOVE_BRANCH_WORKING_HOURS_SUCCESS = 'branch/REMOVE_BRANCH_WORKING_HOURS_SUCCESS';
export const REMOVE_BRANCH_WORKING_HOURS_FAILURE = 'branch/REMOVE_BRANCH_WORKING_HOURS_FAILURE';

export const FETCH_BRANCH_WORKING_HOURS_REQUEST = 'branch/FETCH_BRANCH_WORKING_HOURS_REQUEST';
export const FETCH_BRANCH_WORKING_HOURS_SUCCESS = 'branch/FETCH_BRANCH_WORKING_HOURS_SUCCESS';
export const FETCH_BRANCH_WORKING_HOURS_FAILURE = 'branch/FETCH_BRANCH_WORKING_HOURS_FAILURE';
