import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Button, Form } from 'reactstrap';
import { Field } from 'redux-form';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import { InputComponent, PasswordInput, required } from 'app/shared';

const trim = (value) => value && value.trim();

class FormComponent extends Component {
  static propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    intl: PropTypes.object.isRequired,
  };

  render() {
    const { submitting, intl, handleSubmit, history } = this.props;
    const { formatMessage } = intl;

    return (
      <Form onSubmit={handleSubmit}>
        <Field
          component={InputComponent}
          type="text"
          normalize={trim}
          name="username"
          validate={[required]}
          minLength={2}
          props={{
            label: formatMessage({ id: 'CORE.LABEL.USERNAME' }),
            isRequired: true,
            isRtl: false,
          }}
        />
        <div className="position-relative">
          <Link to="/forgotten-password" className="label__link">
            <FormattedMessage id="LOGIN.LINK.FORGOT-PASSWORD" />
          </Link>
          <Field
            component={PasswordInput}
            normalize={trim}
            validate={[required]}
            name="password"
            minLength={2}
            props={{
              label: formatMessage({ id: 'CORE.LABEL.PASSWORD' }),
              isRequired: true,
              isRtl: false,
            }}
          />
        </div>
        <Button block className="btn mb-2" color="primary-gradient" disabled={submitting} type="submit">
          <FormattedMessage id="LOGIN.BUTTON.LOGIN" />
        </Button>
        <Button
          block
          className="btn"
          color="primary"
          disabled={submitting}
          onClick={() => history.push('/registration')}
          outline
          type="button"
        >
          <FormattedMessage id="LOGIN.BUTTON.REGISTER" />
        </Button>
      </Form>
    );
  }
}

export default FormComponent;
