import { Option, RemoteFile } from 'app/types';

export type PatientSourcesAction = {
  id: string;
  action: string;
  actionTaker: string;
  date: string;
};

export enum PatientSourceAffectedLevel {
  MedicalInstitution = '0',
  Branch = '1',
}

export enum PatientSourceStatus {
  Active = '1',
  NotStarted = '2',
  Paused = '3',
  Expired = '4',
  Terminate = '5',
  Deleted = '6',
}

export interface PatientSource {
  clinicyId: string;
  patientSourceId: string;
  sourceType: Option;
  platform: Option | null;
  referrer: Option | null;
  promoCode: string | null;
  campaignName: Option | null;
  startsOn: string;
  expiresOn: string;
  duration: number;
  totalBookings: number;
  note: string | null;
  status: Option<PatientSourceStatus>;
}

export interface DetailedPatientSource extends PatientSource {
  attachments: RemoteFile[];
  branchesApplies: Option[];
  specialitiesApplies: Option[];
  practitionersApplies: Option[];
  subSpecialtiesApplies: Option[];
  servicesApplies: AppliedServiceOption[];
}

export type PatientSourceAction = {
  clinicyId: string;
  id: string;
  actionTaker: string;
  action: string;
  date: string;
};

export interface PatientSourceOption extends Option {
  details: {
    campaignName?: string;
    platform?: string;
    referrer?: string;
  };
}

export interface AppliedServiceOption extends Option {
  details: {
    subSpeciality: Option;
    branch: Option;
  };
}

export const PATIENT_SOURCE_AFFECTED_LEVELS: Option<PatientSourceAffectedLevel>[] = [
  {
    label: 'Medical Institution',
    value: PatientSourceAffectedLevel.MedicalInstitution,
    translation: {
      en: 'Medical Institution',
      ar: 'المنشأة الطبية',
    },
  },
  {
    label: 'Branch',
    value: PatientSourceAffectedLevel.Branch,
    translation: {
      en: 'Branch',
      ar: 'الفرع',
    },
  },
];
