import { useAppIntl } from 'app/helpers';
import './trainingLabel.scss';

const trainingWebText = {
  en: 'Training Web',
  ar: 'موقع التدريب',
};

export const TrainingLabel = () => {
  const { locale } = useAppIntl();
  const isTrainingEnv = window.location.href.includes('training');

  if (!isTrainingEnv) {
    return null;
  }

  return <div className="trainingLabel">{trainingWebText[locale]}</div>;
};
