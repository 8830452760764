import { Col, Row } from 'reactstrap';
import React, { Fragment, useMemo } from 'react';
import { Appointment, DoctorRank, Locale } from 'app/types';
import { FORMATS_EXTENDED_DATE, FORMATS_EXTENDED_DATE_AND_TIME } from 'app/shared';
import { formattedDate, getDoctorNameWithTitle, getTranslation, useAppIntl, useAppSelector } from 'app/helpers';

interface Props {
  lang: Locale;
}

export const Items = ({ lang }: Props) => {
  const { formatMessage } = useAppIntl();
  const appointment: Appointment | null = useAppSelector((state) => state.appointments.confirmation.data);

  const items = useMemo(() => {
    if (!appointment) {
      return [];
    }

    return [
      ...(appointment.status
        ? [
            {
              label: 'CORE.LABEL.STATUS',
              value: getTranslation(appointment.status, lang),
            },
          ]
        : []),
      {
        label: 'CONFIRMATION.DATE-TIME',
        value: formattedDate(
          appointment.start,
          appointment.end ? FORMATS_EXTENDED_DATE_AND_TIME : FORMATS_EXTENDED_DATE,
        ),
      },
      {
        label: 'CONFIRMATION.PATIENT-NAME',
        value: appointment.guardianName || appointment.patientName,
      },
      {
        label: 'CONFIRMATION.BRANCH',
        value: getTranslation(appointment.branch, lang),
      },
      {
        label: 'CONFIRMATION.DOCTOR-NAME',
        value: getDoctorNameWithTitle(appointment.doctorName, appointment.doctorTitle, lang),
      },
      ...(appointment.doctorRank.value !== DoctorRank.None
        ? [
            {
              label: 'CONFIRMATION.RANK',
              value: getTranslation(appointment.doctorRank, lang),
            },
          ]
        : []),
      {
        label: 'CORE.LABEL.COORDINATION-NUMBER',
        value: appointment.coordinatorPhoneNumber,
      },
      {
        label: 'CONFIRMATION.SPECIALITY',
        value: getTranslation(appointment.speciality, lang),
      },
      {
        label: 'CORE.TABLE.MEDICAL-SERVICE',
        value: getTranslation(appointment.subSpeciality, lang),
      },
    ].filter((item) => item.value);
  }, [appointment, lang]);

  return (
    <Row tag="dl">
      {items.map((item, index) => (
        <Fragment key={index}>
          <Col md={4} tag="dt">
            {formatMessage({ id: item.label })}:
          </Col>
          <Col md={8} tag="dd">
            {item.value}
          </Col>
        </Fragment>
      ))}
    </Row>
  );
};
