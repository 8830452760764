import { Badge } from 'reactstrap';
import { formattedDate, useAppIntl, useAppSelector } from 'app/helpers';

export const Header = () => {
  const { formatMessage } = useAppIntl();
  const selectedSlot = useAppSelector((state) => state.calendar.selectedSlot);

  return (
    <>
      {formatMessage({ id: 'APPOINTMENTS.TEXT.MANAGE-APPOINTMENTS' })}
      {selectedSlot && (
        <Badge className="fs-6 ms-2" color="light-primary">
          {formattedDate(selectedSlot.date)}
        </Badge>
      )}
    </>
  );
};
