import React, { FC, useCallback, useMemo, useState } from 'react';
import { Button, Col, Form, Row, Alert } from 'reactstrap';
import { BaseFieldProps, Field, reduxForm } from 'redux-form';
import { InjectedFormProps } from 'redux-form/lib/reduxForm';
import { RawDraftContentState } from 'draft-js';
import { IconPrinter, IconExclamationCircle } from '@tabler/icons-react';
import { BoxHeader, IconButton } from 'app/components';
import { FORMS_ASSESSMENT_MEDICAL_PROFILE_FORM, required } from 'app/shared';
import { ICD10Code, Option } from 'app/types';
import { useAppIntl, useAppSelector } from 'app/helpers';
import { RichText } from 'app/shared/form-elements/richText/richText';
import { useColumnsHelper } from 'app/assessment/medicalProfileSection/components/practitionerAssessment/helpers/useColumnsHelper';
import {
  DiagnosisWrapper,
  VisitDiagnosisWrapper,
  WrapperWithTable,
} from 'app/assessment/medicalProfileSection/components/helpers';
import { useCheckViewStatus } from 'app/assessment/helpers';
import {
  ProcedureLocationField,
  ProcedureLocationFieldProps,
} from 'app/shared/form-elements/procedureLocationField/procedureLocationField';
import { Values } from 'app/components/locationSelector/procedureLocation/types';
import { DiagnosesResponse, ProcedureResponse } from 'app/redux/doctorAssessment/types';

interface Props {
  toggle: () => void;
  manageBillingItemsModal: (index: number, values: Option[] | undefined, fieldName: string) => void;
}

const colProps = { className: 'mb-5' };

const AssessmentForm: FC<Props & InjectedFormProps<MedicalProfileFormData, Props>> = ({
  handleSubmit,
  initialValues,
  toggle,
  manageBillingItemsModal,
}) => {
  //Local State
  const [isProcedureUpdateOpen, setIsProcedureUpdateOpen] = useState(false);
  const { formatMessage } = useAppIntl();

  //Selectors
  const assessmentData = useAppSelector((state) => state.doctorAssessment.data);
  const appointmentInfo = useAppSelector(
    (state) => state.treatment.generalTreatmentInformation.data?.appointmentInformation,
  );
  const paymentSource = useAppSelector(
    (state) => state.treatment.generalTreatmentInformation.data?.paymentSource.paymentSource?.value,
  );
  const patientInsuranceId = useAppSelector((state) => state.doctorAssessment.data?.billingSection.patientInsuranceId);
  const isDoctorExcluded = useAppSelector((state) => !!state.doctorAssessment.data?.isDoctorExcluded);
  const isSpecialityExcluded = useAppSelector((state) => !!state.doctorAssessment.data?.isSpecialityExcluded);

  // Helper Hooks
  const {
    assessmentColumns,
    chiefComplaintColumns,
    clinicalExaminationColumns,
    commentColumns,
    otherCondition,
    healthEducationColumns,
    medicalConditionColumns,
    presentIllnessHistoryColumns,
    procedureUpdateColumns,
    systemReviewColumns,
  } = useColumnsHelper();
  const { isContinue, isDraftMode, isReadOnly } = useCheckViewStatus(
    assessmentData?.viewStatus,
    assessmentData?.completedAt,
  );

  // Conditions
  const showExclusionsAlert = patientInsuranceId && (isDoctorExcluded || isSpecialityExcluded);
  const isContinueOrDraft = useMemo(() => isContinue || isDraftMode, [isContinue, isDraftMode]);

  // Helpers
  const doctorName = appointmentInfo ? `${appointmentInfo.doctorTitle} ${appointmentInfo.doctorName}` : '';

  //Handlers
  // const sendLinkViaMobile = useCallback(() => {
  //   //TODO: FOR NOW WE DONT IMPLEMENT THIS (confirmed by saud)
  // }, []);
  const toggleProcedureUpdate = useCallback(() => setIsProcedureUpdateOpen((prevState) => !prevState), []);

  return (
    <>
      <Form className="form" onSubmit={handleSubmit}>
        <BoxHeader className={showExclusionsAlert ? 'mb-1' : 'mb-3'} customMessage={doctorName}>
          <IconButton
            icon={<IconPrinter size={22} strokeWidth={1.8} />}
            tooltipMessageId="CORE.BUTTON.PRINT"
            onClick={toggle}
          />
          {/* TODO: FOR NOW WE DONT IMPLEMENT THIS (confirmed by saud) */}
          {/*<IconButton*/}
          {/*  className="ms-1"*/}
          {/*  icon={<IconMessage2 size={22} strokeWidth={1.8} />}*/}
          {/*  onClick={sendLinkViaMobile}*/}
          {/*  tooltipMessageId="PATIENTS-ARRIVAL.TEXT.SEND-PRACTITIONER-ASSESSMENT-LINK"*/}
          {/*/>*/}
        </BoxHeader>
        {showExclusionsAlert && (
          <Alert color="danger" className="d-flex flex-column mb-4 py-1 px-2">
            {isDoctorExcluded && (
              <div>
                <IconExclamationCircle size={16} />
                <span className="ms-1 small">
                  {formatMessage({ id: 'CORE.TEXT.ASSESSMENT-PRACTITIONER-EXCLUDED' })}
                </span>
              </div>
            )}
            {isSpecialityExcluded && (
              <div>
                <IconExclamationCircle size={16} />
                <span className="ms-1 small">
                  {formatMessage({ id: 'CORE.TEXT.ASSESSMENT-PRACTITIONER-SPECIALITY-EXCLUDED' })}
                </span>
              </div>
            )}
          </Alert>
        )}
        {/*Without Historical Data*/}
        {!initialValues.diagnosisTable ? (
          <Row xs={1}>
            <Col {...colProps}>
              <h5>{formatMessage({ id: 'TREATMENT-PLAN.LABEL.CHIEF-COMPLAINT' })}</h5>
              <Field name="chiefComplaint" component={RichText} isReadOnly={isReadOnly} />
            </Col>
            <Col {...colProps}>
              <h5>{formatMessage({ id: 'TREATMENT-PLAN.LABEL.HISTORY-OF-PRESENT-ILLNESS' })}</h5>
              <Field name="presentIllnessHistory" component={RichText} isReadOnly={isReadOnly} />
            </Col>
            <Col {...colProps}>
              <h5>{formatMessage({ id: 'TREATMENT-PLAN.LABEL.MEDICAL-CONDITION' })}</h5>
              <Field name="medicalCondition" component={RichText} isReadOnly={isReadOnly} />
            </Col>
            <Col {...colProps}>
              <h5>{formatMessage({ id: 'TREATMENT-PLAN.LABEL.REVIEW-OF-SYSTEM' })}</h5>
              <Field name="systemReview" component={RichText} isReadOnly={isReadOnly} />
            </Col>
            <Col {...colProps}>
              <h5>{formatMessage({ id: 'TREATMENT-PLAN.LABEL.CLINICAL-EXAMINATION' })}</h5>
              <Field name="clinicalExamination" component={RichText} isReadOnly={isReadOnly} />
            </Col>
            <Col {...colProps}>
              <DiagnosisWrapper />
            </Col>
            {paymentSource && (
              <Col {...colProps}>
                <h5>{formatMessage({ id: 'TREATMENT-PLAN.LABEL.OTHER-CONDITION' })}</h5>
                <Field component={RichText} name="otherCondition" isReadOnly={isReadOnly} />
              </Col>
            )}
            <Col {...colProps}>
              <h5>{formatMessage({ id: 'TREATMENT-PLAN.TEXT.ASSESSMENT' })}</h5>
              <Field component={RichText} name="assessment" isReadOnly={isReadOnly} />
            </Col>
            <Col className="mb-1">
              <h5> {formatMessage({ id: 'TREATMENT-PLAN.LABEL.PLAN-SHORT-TERM' })}</h5>
              <Field<BaseFieldProps<ProcedureLocationFieldProps>>
                name="procedureLocation"
                component={ProcedureLocationField}
                props={{
                  isReadOnly: isReadOnly,
                  onManageBillingItems: (index, values) => manageBillingItemsModal(index, values, 'procedureLocation'),
                }}
              />
            </Col>
            <Col {...colProps}>
              <Field name="plan" component={RichText} isReadOnly={isReadOnly} />
            </Col>
            <Col {...colProps}>
              <h5>{formatMessage({ id: 'TREATMENT-PLAN.LABEL.GOAL' })}</h5>
              <Field<BaseFieldProps<ProcedureLocationFieldProps>>
                name="goalLocation"
                component={ProcedureLocationField}
                props={{
                  isReadOnly: isReadOnly,
                  onManageBillingItems: (index, values) => manageBillingItemsModal(index, values, 'goalLocation'),
                }}
              />
            </Col>
            <Col {...colProps}>
              <Field name="goal" component={RichText} isReadOnly={isReadOnly} />
            </Col>
            <Col {...colProps}>
              <h5>{formatMessage({ id: 'TREATMENT-PLAN.LABEL.HEALTH-EDUCATION' })}</h5>
              <Field component={RichText} name="healthEducation" isReadOnly={isReadOnly} />
            </Col>
            <Col {...colProps}>
              <h5>{formatMessage({ id: 'TREATMENT-PLAN.LABEL.PRIVATE-COMMENT' })}</h5>
              <Field component={RichText} name="comment" isReadOnly={isReadOnly} />
            </Col>
            {isProcedureUpdateOpen || !!initialValues?.procedureUpdate ? (
              <Col>
                <h5>{formatMessage({ id: 'TREATMENT-PLAN.LABEL.PROCEDURE-UPDATE' })}</h5>
                <Field component={RichText} name="procedureUpdate" isReadOnly={isReadOnly} />
              </Col>
            ) : (
              !isReadOnly && (
                <div style={{ width: '200px', margin: '0 auto' }}>
                  <Button color="primary-gradient" size="sm" className="mt-2" onClick={toggleProcedureUpdate}>
                    {formatMessage({ id: 'TREATMENT-PLAN.LABEL.PROCEDURE-UPDATE' })}
                  </Button>
                </div>
              )
            )}
          </Row>
        ) : (
          <>
            {initialValues.chiefComplaintTable && (
              <WrapperWithTable
                name="chiefComplaint"
                keyField="createdAt"
                columns={chiefComplaintColumns}
                data={initialValues.chiefComplaintTable}
                label="TREATMENT-PLAN.LABEL.CHIEF-COMPLAINT"
                isContinueOrDraft={isContinueOrDraft}
                isReadOnly={isReadOnly}
              />
            )}
            {initialValues.presentIllnessHistoryTable && (
              <WrapperWithTable
                name="presentIllnessHistory"
                keyField="createdAt"
                columns={presentIllnessHistoryColumns}
                data={initialValues.presentIllnessHistoryTable}
                label="TREATMENT-PLAN.LABEL.HISTORY-OF-PRESENT-ILLNESS"
                isContinueOrDraft={isContinueOrDraft}
                isReadOnly={isReadOnly}
              />
            )}
            {initialValues.medicalConditionTable && (
              <WrapperWithTable
                name="medicalCondition"
                keyField="createdAt"
                columns={medicalConditionColumns}
                data={initialValues.medicalConditionTable}
                label="TREATMENT-PLAN.LABEL.MEDICAL-CONDITION"
                isContinueOrDraft={isContinueOrDraft}
                isReadOnly={isReadOnly}
              />
            )}
            {initialValues.systemReviewTable && (
              <WrapperWithTable
                name="systemReview"
                keyField="createdAt"
                columns={systemReviewColumns}
                data={initialValues.systemReviewTable}
                label="TREATMENT-PLAN.LABEL.REVIEW-OF-SYSTEM"
                isContinueOrDraft={isContinueOrDraft}
                isReadOnly={isReadOnly}
              />
            )}
            {initialValues.clinicalExaminationTable && (
              <WrapperWithTable
                name="clinicalExamination"
                keyField="createdAt"
                columns={clinicalExaminationColumns}
                data={initialValues.clinicalExaminationTable}
                label="TREATMENT-PLAN.LABEL.CLINICAL-EXAMINATION"
                isContinueOrDraft={isContinueOrDraft}
                isReadOnly={isReadOnly}
              />
            )}
            {initialValues.diagnosisTable && (
              <VisitDiagnosisWrapper
                isContinue={isContinueOrDraft}
                initialValues={initialValues.diagnosisTable}
                isReadOnly={isReadOnly}
              />
            )}
            {initialValues.otherConditionTable && paymentSource && (
              <WrapperWithTable
                name="otherCondition"
                keyField="createdAt"
                columns={otherCondition}
                data={initialValues.otherConditionTable}
                label="TREATMENT-PLAN.LABEL.CONDITION"
                isContinueOrDraft={isContinueOrDraft}
                isReadOnly={isReadOnly}
              />
            )}
            {initialValues.assessmentTable && (
              <WrapperWithTable
                name="assessment"
                keyField="createdAt"
                columns={assessmentColumns}
                data={initialValues.assessmentTable}
                label="TREATMENT-PLAN.TEXT.ASSESSMENT"
                isContinueOrDraft={isContinueOrDraft}
                isReadOnly={isReadOnly}
              />
            )}
            <h5>{formatMessage({ id: 'TREATMENT-PLAN.LABEL.PLAN-SHORT-TERM' })}</h5>
            <Field<BaseFieldProps<ProcedureLocationFieldProps>>
              name="procedureLocation"
              component={ProcedureLocationField}
              props={{
                isReadOnly: isReadOnly,
                onManageBillingItems: (index, values) => manageBillingItemsModal(index, values, 'procedureLocation'),
              }}
              validate={[required]}
            />
            <Field name="plan" component={RichText} isReadOnly={isReadOnly} />
            <h5>{formatMessage({ id: 'TREATMENT-PLAN.LABEL.GOAL' })}</h5>
            <Field<BaseFieldProps<ProcedureLocationFieldProps>>
              name="goalLocation"
              component={ProcedureLocationField}
              props={{
                isReadOnly: isReadOnly,
                onManageBillingItems: (index, values) => manageBillingItemsModal(index, values, 'goalLocation'),
              }}
              validate={[required]}
            />
            <Field name="goal" component={RichText} isReadOnly={isReadOnly} />
            {initialValues.healthEducationTable && (
              <WrapperWithTable
                name="healthEducation"
                keyField="createdAt"
                columns={healthEducationColumns}
                data={initialValues.healthEducationTable}
                label="TREATMENT-PLAN.LABEL.HEALTH-EDUCATION"
                isContinueOrDraft={isContinueOrDraft}
                isReadOnly={isReadOnly}
              />
            )}
            {initialValues.commentTable && (
              <WrapperWithTable
                name="comment"
                keyField="createdAt"
                columns={commentColumns}
                data={initialValues.commentTable}
                label="TREATMENT-PLAN.LABEL.PRIVATE-COMMENT"
                isContinueOrDraft={isContinueOrDraft}
                isReadOnly={isReadOnly}
              />
            )}
            {initialValues.procedureUpdateTable && (
              <WrapperWithTable
                name="procedureUpdate"
                keyField="createdAt"
                columns={procedureUpdateColumns}
                data={initialValues.procedureUpdateTable}
                label="TREATMENT-PLAN.LABEL.PROCEDURE-UPDATE"
                isContinueOrDraft={isContinueOrDraft}
                isReadOnly={isReadOnly}
              />
            )}
          </>
        )}
      </Form>
    </>
  );
};
export const PractitionerAssessment = reduxForm<MedicalProfileFormData, Props>({
  form: FORMS_ASSESSMENT_MEDICAL_PROFILE_FORM,
  destroyOnUnmount: true,
  enableReinitialize: true,
})(AssessmentForm);

export interface MedicalProfileFormData {
  assessment?: RawDraftContentState;
  assessmentTable?: ProcedureResponse[];
  chiefComplaint?: RawDraftContentState;
  chiefComplaintTable?: ProcedureResponse[];
  clinicalExamination?: RawDraftContentState;
  clinicalExaminationTable?: ProcedureResponse[];
  comment?: RawDraftContentState;
  commentTable?: ProcedureResponse[];
  diagnosis?: RawDraftContentState;
  diagnosisTable?: DiagnosesResponse[];
  goal?: RawDraftContentState;
  goalLocation?: Values;
  healthEducation?: RawDraftContentState;
  healthEducationTable?: ProcedureResponse[];
  icdTable?: ICD10Code[] | null;
  medicalCondition?: RawDraftContentState;
  medicalConditionTable?: ProcedureResponse[];
  otherCondition?: RawDraftContentState;
  otherConditionTable?: ProcedureResponse[];
  plan?: RawDraftContentState;
  presentIllnessHistory?: RawDraftContentState;
  presentIllnessHistoryTable?: ProcedureResponse[];
  procedureLocation?: Values;
  procedureUpdate?: RawDraftContentState;
  procedureUpdateTable?: ProcedureResponse[];
  systemReview?: RawDraftContentState;
  systemReviewTable?: ProcedureResponse[];
}
