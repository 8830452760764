// @ts-ignore
import overlap from 'react-big-calendar/lib/utils/layout-algorithms/overlap';
import { DayLayoutFunction } from 'react-big-calendar';
import { CalendarDayEvent } from 'app/calendar/types';

type StyledEvent = {
  event: CalendarDayEvent;
  friends: StyledEvent[];
  idx: number;
  size: number;
  style: {
    height: string;
    left: number;
    top: number;
    width: string;
    xOffset: string;
  };
};

// This function comes from React Big Calendar, we do not want to change anything here
const getMaxIdxDFS = (node: StyledEvent, maxIdx: number, visited: StyledEvent[]) => {
  for (let i = 0; i < node.friends.length; ++i) {
    if (visited.indexOf(node.friends[i]) > -1) continue;
    maxIdx = maxIdx > node.friends[i].idx ? maxIdx : node.friends[i].idx;
    visited.push(node.friends[i]);
    const newIdx = getMaxIdxDFS(node.friends[i], maxIdx, visited);
    maxIdx = maxIdx > newIdx ? maxIdx : newIdx;
  }
  return maxIdx;
};

export const customDayLayoutAlgorithm: DayLayoutFunction<CalendarDayEvent> = ({
  events,
  minimumStartDifference,
  slotMetrics,
  accessors,
}) => {
  const styledEvents = overlap({
    events,
    minimumStartDifference,
    slotMetrics,
    accessors,
  });

  styledEvents.sort((e1: StyledEvent, e2: StyledEvent) => {
    const a = e1.style;
    const b = e2.style;

    if (a.top !== b.top) {
      return a.top > b.top ? 1 : -1;
    }

    return a.top + a.height < b.top + b.height ? 1 : -1;
  });

  for (let i = 0; i < styledEvents.length; ++i) {
    styledEvents[i].friends = [];
    delete styledEvents[i].style.left;
    delete styledEvents[i].idx;
    delete styledEvents[i].size;
  }

  for (let i = 0; i < styledEvents.length - 1; ++i) {
    const se1 = styledEvents[i];
    const y1 = se1.style.top;
    const y2 = se1.style.top + se1.style.height;

    for (let j = i + 1; j < styledEvents.length; ++j) {
      const se2 = styledEvents[j];
      const y3 = se2.style.top;
      const y4 = se2.style.top + se2.style.height;

      if ((y3 >= y1 && y4 <= y2) || (y4 > y1 && y4 <= y2) || (y3 >= y1 && y3 < y2)) {
        se1.friends.push(se2);
        se2.friends.push(se1);
      }
    }
  }

  for (let i = 0; i < styledEvents.length; ++i) {
    const se = styledEvents[i];
    const bitmap: number[] = [];
    for (let j = 0; j < 100; ++j) {
      bitmap.push(1); // 1 means available
    }

    for (let j = 0; j < se.friends.length; ++j) {
      if (se.friends[j].idx !== undefined) {
        bitmap[se.friends[j].idx] = 0; // 0 means reserved
      }
    }

    se.idx = bitmap.indexOf(1);
  }

  for (let i = 0; i < styledEvents.length; ++i) {
    let size = 0;

    if (styledEvents[i].size) continue;

    const allFriends: any[] = [];
    const maxIdx = getMaxIdxDFS(styledEvents[i], 0, allFriends);
    size = 100 / (maxIdx + 1);
    styledEvents[i].size = size;

    for (let j = 0; j < allFriends.length; ++j) {
      allFriends[j].size = size;
    }
  }

  for (let i = 0; i < styledEvents.length; ++i) {
    const e = styledEvents[i];
    e.style.left = e.idx * e.size;

    // stretch to maximum
    let maxIdx = 0;

    for (let j = 0; j < e.friends.length; ++j) {
      const idx = e.friends[j].idx;
      maxIdx = maxIdx > idx ? maxIdx : idx;
    }

    if (maxIdx <= e.idx) {
      e.size = 100 - e.idx * e.size;
    }

    // padding between events
    // for this feature, `width` is not percentage based unit anymore
    // it will be used with calc()
    const padding = e.idx === 0 ? 0 : 3;
    e.style.width = `calc(${e.size}% - ${padding}px)`;
    e.style.height = `calc(${e.style.height}% - 2px)`;
    e.style.xOffset = `calc(${e.style.left}% + ${padding}px)`;
  }

  return styledEvents;
};
