import React, { useCallback, useEffect, useState, useMemo, ReactNode } from 'react';
import paginationFactory, { PaginationProvider } from 'react-bootstrap-table2-paginator';
import BootstrapTable, { ColumnDescription, TableChangeHandler } from 'react-bootstrap-table-next';
import classNames from 'classnames';
import { useAppIntl } from 'app/helpers';
import { DEFAULT_PAGINATION_PARAMS, Loader } from 'app/shared';
import { StickyPagination } from 'app/components';
import { Option, RemoteDataParams } from 'app/types';
import { getLastPage } from 'app/helpers/getLastPage/getLastPage';

import './remoteTable.scss';

interface Props {
  classes?: string;
  columns: ColumnDescription[];
  data: any[];
  fetchData: (newParams: RemoteDataParams) => void;
  fullWidth?: boolean;
  id: string;
  initialFetch?: boolean;
  initialFetchParams?: RemoteDataParams;
  keyField: string;
  loading: boolean;
  noDataComponent?: ReactNode;
  pageSizeOptions?: Option<number>[];
  params: RemoteDataParams | undefined;
  showRowId?: boolean;
  wrapperClasses?: string;
}

export const RemoteTable = ({
  classes,
  columns,
  data,
  fetchData,
  id,
  initialFetch,
  initialFetchParams,
  keyField,
  loading,
  noDataComponent,
  pageSizeOptions,
  params,
  showRowId,
  wrapperClasses,
}: Props) => {
  const { formatMessage } = useAppIntl();
  const [initialized, setInitialized] = useState(false);
  const hasItems = data.length > 0;

  const tableColumns = useMemo((): ColumnDescription[] => {
    return !showRowId
      ? columns
      : [
          {
            dataField: 'rowId',
            text: '#',
            formatter: (cell, row, rowIndex, { page, sizePerPage }) => rowIndex + 1 + (page - 1) * sizePerPage,
            formatExtraData: {
              page: params?.page || DEFAULT_PAGINATION_PARAMS.page,
              sizePerPage: params?.sizePerPage || DEFAULT_PAGINATION_PARAMS.sizePerPage,
            },
          },
          ...columns,
        ];
  }, [columns, params?.page, params?.sizePerPage, showRowId]);

  const onTableChange: TableChangeHandler<any> = useCallback(
    (type, newState) => {
      const newParams = {
        ...params,
        page: newState.page,
        sizePerPage: newState.sizePerPage,
      };

      if (type === 'pagination') {
        fetchData(newParams);
      } else if (type === 'sort') {
        fetchData({
          ...newParams,
          order: newState.sortField,
          orderAsc: newState.sortOrder === 'asc',
        });
      }
    },
    [fetchData, params],
  );

  useEffect(() => {
    if (initialFetch && !initialized) {
      setInitialized(true);
      fetchData({ ...DEFAULT_PAGINATION_PARAMS, ...initialFetchParams });
    }
  }, [initialFetch, initialFetchParams, initialized, fetchData]);

  useEffect(() => {
    if (params && params.page > 1) {
      const lastPage = getLastPage(params);
      if (params.page > lastPage) {
        fetchData({ ...params, page: lastPage });
      }
    }
  }, [params, fetchData]);

  return (
    <PaginationProvider
      pagination={paginationFactory({
        custom: true,
        page: params?.page,
        sizePerPage: params?.sizePerPage,
        totalSize: params?.totalSize,
        hideSizePerPage: true,
      })}
    >
      {({ paginationProps, paginationTableProps }) => (
        <>
          <BootstrapTable
            {...paginationTableProps}
            bordered={false}
            classes={classes}
            columns={tableColumns}
            data={data}
            id={id}
            keyField={keyField}
            onTableChange={onTableChange}
            remote
            wrapperClasses={classNames(
              `remoteTable table-responsive ${id}Wrapper`,
              {
                'remoteTable--loading': loading,
                'remoteTable--empty': !loading && !hasItems,
              },
              wrapperClasses,
            )}
          />

          {/* Initial loader */}
          {loading && !hasItems && <Loader />}

          {/* No data message */}
          {!loading && !hasItems ? (
            noDataComponent ? (
              noDataComponent
            ) : (
              <div className="fw-medium text-center py-4">{formatMessage({ id: 'CORE.LABEL.NO-DATA' })}</div>
            )
          ) : null}

          {paginationProps && (
            <StickyPagination
              loading={loading}
              onPageChange={paginationProps.onPageChange}
              onSizePerPageChange={paginationProps.onSizePerPageChange}
              page={paginationProps.page || 1}
              pageSizeOptions={pageSizeOptions}
              sizePerPage={paginationProps.sizePerPage || DEFAULT_PAGINATION_PARAMS.sizePerPage}
              totalSize={paginationProps.totalSize || 1}
            />
          )}
        </>
      )}
    </PaginationProvider>
  );
};
