import moment from 'moment';
import { Appointment } from 'app/types';
import { FormData } from 'app/sagas/apppointment/types';

export const getFiltersFromAppointment = (appointment: Appointment, withDoctor: boolean = false): FormData => ({
  branch: appointment.branch,
  date: moment().startOf('month').toDate(),
  products: [{ ...appointment.product, value: appointment.product.label }],
  speciality: appointment.speciality,
  subSpeciality: appointment.subSpeciality,

  ...(withDoctor && {
    // The "doctor" field is required for the rebook action
    doctor: {
      label: appointment.doctorName,
      value: appointment.doctorId,
    },
  }),
});
