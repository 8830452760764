import { Doctor } from 'app/types';
import { getDoctorFullName, getTranslation, useAppIntl } from 'app/helpers';
import { Avatar } from 'app/components';
import defaultPhoto from 'assets/images/icons/default_photo.jpg';
import './doctorSection.scss';

interface Props {
  doctor: Doctor;
}

export const DoctorSection = ({ doctor }: Props) => {
  const { locale } = useAppIntl();

  return (
    <div className="doctorSection">
      <div className="doctorSection__content">
        <div className="doctorSection__avatar">
          <Avatar className="rounded-3" imageSrc={doctor.image?.url || defaultPhoto} />
        </div>
        <div className="doctorSection__details">
          <h6 className="mb-1">{getDoctorFullName(doctor, locale)}</h6>
          <ul>
            <li>{doctor.clinicyId}</li>
            <li>{doctor.speciality && getTranslation(doctor.speciality, locale)}</li>
          </ul>
        </div>
      </div>
    </div>
  );
};
