//@ts-ignore
import { PhoneNumberUtil, ShortNumberInfo } from 'google-libphonenumber';

const errorMessage = 'ERRORS.INVALID-PHONE-NUMBER';

export const phoneNumber = (value: string): string | undefined => {
  if (!value) {
    return;
  }

  if (typeof value === 'string' && value.length > 6) {
    // Instances
    const phoneUtil = PhoneNumberUtil.getInstance();
    const shortInfo = ShortNumberInfo.getInstance();

    // Numbers
    try {
      const number = phoneUtil.parseAndKeepRawInput(value, 'SA');

      // Possibilities
      const isPossibleNumber = phoneUtil.isPossibleNumber(number);
      const isPossibleShortNumber = !shortInfo.isPossibleShortNumber(number);

      if (isPossibleNumber && isPossibleShortNumber) {
        return;
      }
    } catch {
      return errorMessage;
    }
  }

  return errorMessage;
};
