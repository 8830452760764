import { Button } from 'reactstrap';
import { ColumnFormatter } from 'react-bootstrap-table-next';
import { AvailablePackage } from 'app/types';
import { FormatExtraData } from 'app/features/invoicingProcess/process/components/availablePackages/availablePackages';

export const ActionsFormatter: ColumnFormatter<AvailablePackage, FormatExtraData> = (_, row, __, formatExtraData) => (
  <Button
    disabled={row.id === formatExtraData?.loadingId}
    color="primary-gradient"
    size="sm"
    onClick={() => formatExtraData?.onIssueInvoice?.(row)}
  >
    {formatExtraData?.formatMessage?.({ id: 'CORE.BUTTON.ISSUE-INVOICE' })}
  </Button>
);
