import round from 'lodash/round';
import orderBy from 'lodash/orderBy';
import {
  BillingItemSourceType,
  GovernmentIssuedIdType,
  InvoiceItem,
  InvoiceItemVat,
  InvoicePayBy,
  InvoiceType,
} from 'app/types';
import { ExtendedItem } from 'app/features/invoicingProcess/process/items/types';
import { PaymentModeFormData } from 'app/features/invoicingProcess/process/paymentMode/form/types';
import { getPatientShare } from 'app/features/invoicingProcess/process/items/helpers/getPatientShare';
import { getPatientShareLimit } from 'app/features/invoicingProcess/process/items/helpers/getPatientShareLimit';
import {
  getDiscountValue,
  getGrossPrice,
  getPercentValue,
  getPriceAfterDiscount,
  getTotalPrice,
  getVatValue,
} from 'app/features/invoicingProcess/process/items/helpers/getters';

interface Params {
  invoiceType: InvoiceType | undefined;
  isPrimaryCareSpeciality: boolean;
  items: InvoiceItem[];
  paymentModeValues: Partial<PaymentModeFormData>;
}

export const generateExtendedItems = (params: Params): ExtendedItem[] => {
  // Payment mode fields
  const { governmentIssuedId, governmentIssuedIdType, payBy } = params.paymentModeValues;

  // Global variables
  const isSaudiPatient =
    governmentIssuedIdType?.value === GovernmentIssuedIdType.SaudiId && !!governmentIssuedId?.startsWith('1');
  const isPayByInsurance = payBy?.value === InvoicePayBy.Insurance;
  let patientShareLimit = getPatientShareLimit({
    invoiceType: params.invoiceType,
    isPrimaryCareSpeciality: params.isPrimaryCareSpeciality,
    paymentModeValues: params.paymentModeValues,
  });

  // Items
  const sortedItems = orderBy<InvoiceItem>(params.items, (item) => getPriceAfterDiscount(item), 'desc');
  const vatExemptedItems = sortedItems.filter((item) => item.billingItem.details.saudisVatExempted);
  const notVatExemptedItems = sortedItems.filter((item) => !item.billingItem.details.saudisVatExempted);
  const items: InvoiceItem[] = [...notVatExemptedItems, ...vatExemptedItems];

  return items.map((item) => {
    const isMedication = item.billingItemSourceType.value === BillingItemSourceType.Medication;
    const isVatExempted = isMedication
      ? item.billingItem.details.vatExempted
      : isSaudiPatient && item.billingItem.details.saudisVatExempted;
    const discountValue = getDiscountValue(item);
    const grossPrice = getGrossPrice(item);
    const netValue = getPriceAfterDiscount(item);
    let grandTotal = getTotalPrice(item);
    let vatValue = getVatValue(item);
    let vatExemptAmount = 0;
    let vatPercent = InvoiceItemVat.Default;

    // Patient share
    const patientShare = getPatientShare({
      invoiceType: params.invoiceType,
      isPrimaryCareSpeciality: params.isPrimaryCareSpeciality,
      item,
      paymentModeValues: params.paymentModeValues,
    });
    const newPatientShareNet = getPercentValue(netValue, patientShare);
    const isPatientShareLimitExceeded = isPayByInsurance ? patientShareLimit < newPatientShareNet : false;
    const patientShareNet = isPatientShareLimitExceeded ? patientShareLimit : newPatientShareNet;
    let patientShareVat = getPercentValue(patientShareNet, item.vat);

    if (isVatExempted) {
      // Set the amount exempt from VAT
      vatExemptAmount = patientShareVat;
      vatValue = round(vatValue - patientShareVat, 2);
      patientShareVat = 0;

      if (!isPayByInsurance) {
        // Remove VAT
        vatPercent = InvoiceItemVat.None;
        vatValue = 0;
      }

      // Update Grant Total value
      grandTotal = round(netValue + vatValue, 2);
    }

    // Patient share amount
    let patientShareTotal = round(patientShareNet + patientShareVat, 2);

    // New limits for the patient
    if (isPayByInsurance) {
      if (patientShareLimit >= newPatientShareNet) {
        patientShareLimit = round(patientShareLimit - newPatientShareNet, 2);
      } else {
        patientShareLimit = 0;
      }
    }

    // Insurance share
    let insuranceShareNet = 0;
    let insuranceShareVat = 0;
    let insuranceShareTotal = 0;

    if (isPayByInsurance) {
      insuranceShareNet = round(netValue - patientShareNet, 2);
      insuranceShareVat = getPercentValue(insuranceShareNet, item.vat);
      insuranceShareTotal = round(insuranceShareNet + insuranceShareVat, 2);

      if (isVatExempted && patientShareTotal <= 0) {
        // Fully paid by insurance company
        insuranceShareTotal = grandTotal;
      }
    }

    // Handle the rounding difference
    const acceptableDifference = 0.01;
    const grandTotalDifference = round(grandTotal - (patientShareTotal + insuranceShareTotal + vatExemptAmount), 2);

    if (grandTotalDifference && grandTotalDifference === acceptableDifference) {
      // If there is a difference, it must be added to the Insurance Share Total or Patient Share Total
      if (isPayByInsurance) {
        insuranceShareTotal = round(insuranceShareTotal + acceptableDifference, 2);
      } else {
        patientShareTotal = round(patientShareTotal + acceptableDifference, 2);
      }
    }

    const extendedItem: ExtendedItem = {
      ...item,
      discountValue,
      grandTotal,
      grossPrice,
      insuranceShareNet,
      insuranceShareTotal,
      insuranceShareVat,
      netValue,
      patientShareNet,
      patientShareTotal,
      patientShareVat,
      vatExemptAmount,
      vatPercent,
      vatValue,
    };

    return extendedItem;
  });
};
