import { PrimaryPatientBaseInfo } from 'app/features/patient/patientForm/types';
import { MergeProfilesFormData } from 'app/features/patients/mergeProfiles/form/types';

export interface AddPatientInsuranceBody {
  end: string;
  insuranceClass: string;
  insuranceCompanyId: string;
  maxPatientShare: number;
  number: string;
  patientShare: number;
  policyNumber: string;
  start: string;
}

export interface EditPatientInsuranceBody {
  deactivated: boolean;
  end: string;
  insuranceClass: string;
  maxPatientShare: number;
  number: string;
  patientShare: number;
  policyNumber: string;
  start: string;
}

export enum RequireVerificationType {
  CheckIn = 'checkIn',
  RequireVerification = 'requireVerification',
}

export interface VerificationProcessData {
  clinicId: string;
  patientId: string;
  appointmentId?: string;
  dependantId?: string | null;
  actionType: RequireVerificationType;
}

export interface PatientMergeChooserData {
  fromClinicId?: string;
  toClinicPatientId?: string;
  mergeRequestId?: string;
}

export interface MergedPatients {
  sourceClinicPatientId: string;
  destinationClinicPatientId: string;
}

export interface MergeProcess {
  mergedPatients: MergedPatients | null;
  patientBadgeMergeModalId: string | null;
  patientMergeChooser: PatientMergeChooserData | null;
  patientMergeValues: MergeProfilesFormData | null;
}

export interface PatientState {
  addedPrimaryPatients: PrimaryPatientBaseInfo[];
  verificationProcess: VerificationProcessData | null;
  mergeProcess: MergeProcess | null;
}
