import { useCallback } from 'react';
import { UncontrolledTooltip } from 'reactstrap';
import { IconPlus } from '@tabler/icons-react';

import { toggleModal } from 'app/redux/modals/modals.actions';
import { useAppDispatch, useAppIntl, useAppSelector, useCurrentUser } from 'app/helpers';
import { useLocation } from 'react-router-dom';
import './bookAppointmentTrigger.scss';

export const BookAppointmentTrigger = () => {
  const { isOpen: isBookAppointmentModalOpen } = useAppSelector((state) => state.modal.bookAppointment);
  const dispatch = useAppDispatch();
  const { formatMessage } = useAppIntl();
  const location = useLocation();

  const isCalendarView = location.pathname.includes('/calendar');
  const {
    currentUser: { allowedForBookAppointments },
    isManager,
    isReception,
    isOwner,
    isDoctor,
    isNurse,
  } = useCurrentUser();
  const showBookAppointmentButton =
    allowedForBookAppointments && (isManager || isReception || isOwner || isDoctor || isNurse);
  const toggleBookAppointment = useCallback(() => dispatch(toggleModal('bookAppointment')), [dispatch]);

  return (
    <>
      {showBookAppointmentButton && !isCalendarView && (
        <div className="bookAppointmentTrigger">
          <div
            id="bookAppointmentTrigger"
            className="bookAppointmentTrigger__buttonPlus"
            onClick={toggleBookAppointment}
          >
            <IconPlus size={16} strokeWidth={2.8} />
          </div>
          {!isBookAppointmentModalOpen && (
            <UncontrolledTooltip target="bookAppointmentTrigger">
              {formatMessage({ id: 'APPOINTMENTS.BUTTON.BOOK-APPOINTMENT' })}
            </UncontrolledTooltip>
          )}
        </div>
      )}
    </>
  );
};
