import { useCallback } from 'react';
import { WrappedFieldProps } from 'redux-form/lib/Field';
import { FormFeedback, FormGroup, Label } from 'reactstrap';
import { useAppIntl } from 'app/helpers';
import { Chips } from 'app/components/chips/chips';
import { Option } from 'app/types';

export interface ChipsFieldProps {
  isRequired?: boolean;
  label: string;
  chips: Option[];
  multiselect?: boolean;
}

export const ChipsField = ({
  chips,
  input: { value, onChange },
  isRequired,
  label,
  meta,
  multiselect = false,
}: WrappedFieldProps & ChipsFieldProps) => {
  const { formatMessage, locale } = useAppIntl();
  const inputValue: Option['value'] | Array<Option['value']> | undefined = value;
  const selectedChips = Array.isArray(inputValue) ? inputValue : inputValue ? [inputValue] : [];
  const invalid = !!meta.error && meta.touched;

  const onClick = useCallback(
    (chip: Option<string | number>) => {
      if (!inputValue) {
        onChange(multiselect ? [chip.value] : chip.value);
        return;
      }

      if (multiselect && Array.isArray(inputValue)) {
        // Multiselect
        onChange(
          inputValue.includes(String(chip.value))
            ? inputValue.filter((v) => v !== chip.value)
            : [...inputValue, chip.value],
        );
      } else {
        // Single-select
        onChange(inputValue === chip.value ? undefined : chip.value);
      }
    },
    [inputValue, multiselect, onChange],
  );

  return (
    <FormGroup>
      {label && (
        <Label>
          {label}
          {isRequired && <span className="text-danger">&nbsp;*</span>}
        </Label>
      )}
      <Chips chips={chips} invalid={invalid} onClick={onClick} selectedChips={selectedChips} locale={locale} />
      {invalid && <FormFeedback className="d-block mt-0">{formatMessage({ id: meta.error })}</FormFeedback>}
    </FormGroup>
  );
};
