import toast from 'react-hot-toast';
import { takeEvery } from 'redux-saga/effects';
import { PayloadAction } from '@reduxjs/toolkit/src/createAction';

type NotificationType = 'success' | 'danger';

type Meta = {
  notification: {
    title: string;
    type: NotificationType;
  };
};

// TODO: We should rewrite this component to middleware
export const actionSelector = (action: any) => !!action.meta && !!action.meta.notification;

export function* dispatchNotification(action: PayloadAction<unknown, string, Meta>) {
  const notification = action.meta.notification;

  if (notification) {
    if (notification.type === 'success') {
      yield toast.success(notification.title);
    } else if (notification.type === 'danger') {
      yield toast.error(notification.title);
    } else {
      yield toast.custom(notification.title);
    }
  }
}

export function* notificationsMessageSaga() {
  yield takeEvery(actionSelector, dispatchNotification);
}

export default notificationsMessageSaga;
