import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQueryWithReAuth } from 'app/helpers';
import { QueryParams, QueryResponseWithPagination } from 'app/types';
import {
  EditEmergencySpecialityContactBodyParams,
  AddEmergencySpecialityNoteContactBodyParams,
  EmergencyContactWithNotes,
  EmergencyContact,
  SpecialityNoteBodyParams,
  EmergencyContactSpecialityNote,
  AddSpecialityNoteBodyParams,
} from 'app/services/speciality/types';

export const specialityApi = createApi({
  reducerPath: 'specialityApi',
  baseQuery: baseQueryWithReAuth,
  tagTypes: ['EmergencyContacts', 'SpecialityNotes'],
  endpoints: (builder) => ({
    // Emergency Notes
    addEmergencySpecialityContact: builder.mutation<void, AddEmergencySpecialityNoteContactBodyParams>({
      query: (body) => ({
        url: `speciality-contacts`,
        method: 'POST',
        body,
      }),
      invalidatesTags: ['EmergencyContacts'],
    }),

    editEmergencySpecialityContact: builder.mutation<
      void,
      { contactId: string; body: EditEmergencySpecialityContactBodyParams }
    >({
      query: ({ contactId, body }) => ({
        url: `speciality-contacts/${contactId}`,
        method: 'PUT',
        body,
      }),
      invalidatesTags: ['EmergencyContacts'],
    }),

    getEmergencySpecialityContacts: builder.query<
      QueryResponseWithPagination<EmergencyContact[]>,
      { clinicPatientId: string; params?: QueryParams }
    >({
      query: ({ clinicPatientId, params }) => ({
        url: `clinic-patients/${clinicPatientId}/emergency-contacts`,
        method: 'GET',
        params,
      }),
      providesTags: ['EmergencyContacts'],
    }),

    getEmergencySpecialityContactsWithNotes: builder.query<
      QueryResponseWithPagination<EmergencyContactWithNotes[]>,
      { clinicPatientId: string; params?: QueryParams }
    >({
      query: ({ clinicPatientId, params }) => ({
        url: `clinic-patients/${clinicPatientId}/emergency-contacts-with-speciality-notes`,
        method: 'GET',
        params,
      }),
      providesTags: ['EmergencyContacts'],
    }),

    deleteEmergencySpecialityContact: builder.mutation<void, string>({
      query: (contactId) => ({
        url: `speciality-contacts/${contactId}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['EmergencyContacts'],
    }),

    // Speciality Notes
    addSpecialityNote: builder.mutation<void, { body: AddSpecialityNoteBodyParams }>({
      query: ({ body }) => ({
        url: 'speciality-note',
        method: 'POST',
        body,
      }),
      invalidatesTags: ['SpecialityNotes'],
    }),

    editSpecialityNote: builder.mutation<void, { noteId: string; body: SpecialityNoteBodyParams }>({
      query: ({ noteId, body }) => ({
        url: `speciality-notes/${noteId}`,
        method: 'PUT',
        body,
      }),
      invalidatesTags: ['SpecialityNotes'],
    }),

    getSpecialityNotes: builder.query<
      QueryResponseWithPagination<EmergencyContactSpecialityNote[]>,
      { clinicPatientId: string; specialityId: string; params?: QueryParams }
    >({
      query: ({ clinicPatientId, specialityId, params }) => ({
        url: `clinic-patients/${clinicPatientId}/specialities/${specialityId}/speciality-notes`,
        method: 'GET',
        params,
      }),
      providesTags: ['SpecialityNotes'],
    }),

    deleteSpecialityNote: builder.mutation<void, string>({
      query: (noteId) => ({
        url: `speciality-notes/${noteId}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['SpecialityNotes', 'EmergencyContacts'],
    }),
  }),
});

export const {
  // Emergency Notes
  useAddEmergencySpecialityContactMutation,
  useEditEmergencySpecialityContactMutation,
  useDeleteEmergencySpecialityContactMutation,
  useGetEmergencySpecialityContactsQuery,
  useGetEmergencySpecialityContactsWithNotesQuery,

  // Speciality Notes
  useAddSpecialityNoteMutation,
  useEditSpecialityNoteMutation,
  useGetSpecialityNotesQuery,
  useDeleteSpecialityNoteMutation,
} = specialityApi;
