import { ReactNode } from 'react';
import { useHistory } from 'react-router';
import { Box, LanguageSelector } from 'app/components';
import logo from 'assets/images/logo/clinicy-logo.png';
import styles from 'app/components/unauthorizedPage/unauthorizedPage.module.scss';

interface Props {
  boxSize?: 'sm' | 'md' | 'lg';
  children: ReactNode;
  languageSelector?: boolean;
}

export const UnauthorizedPage = ({ boxSize = 'sm', children, languageSelector = true }: Props) => {
  const { push } = useHistory();
  const goToHomepage = () => push('/');

  return (
    <div className={styles.wrapper}>
      <Box className="slideInUp mt-auto mb-auto" size={boxSize}>
        <div className={styles.logo}>
          <img alt="Clinicy logo" src={logo} onClick={goToHomepage} />
        </div>
        {children}
        {languageSelector && <LanguageSelector className="d-flex justify-content-center mt-3" />}
      </Box>
    </div>
  );
};
