import { ReactNode } from 'react';
import classnames from 'classnames';
import './pagination.scss';

interface PaginationProps {
  children: ReactNode;
}

export const Pagination = ({ children }: PaginationProps) => {
  return <div className="pagination">{children}</div>;
};

interface PaginationItemProps {
  active?: boolean;
  children: ReactNode | string | number;
  onClick?: () => void;
}

export const PaginationItem = ({ active, children, onClick }: PaginationItemProps) => {
  return (
    <div
      className={classnames('pagination__item btn btn-flat ms-1', {
        'pagination__item--active': active,
      })}
      onClick={onClick}
    >
      {children}
    </div>
  );
};
