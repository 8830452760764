import { InvoiceItem } from 'app/types';
import { IntlFormatters } from '@formatjs/intl/src/types';
import { ItemsSnapshot } from 'app/redux/invoicingProcess/types';

export interface FormatExtraData {
  deleteItem?: (item: InvoiceItem) => void;
  editItem?: (item: InvoiceItem) => void;
  formatMessage?: IntlFormatters['formatMessage'];
}

export enum FormMode {
  Add = 'add',
  Edit = 'edit',
}

export interface ExtendedItem extends InvoiceItem {
  discountValue: number;
  grandTotal: number;
  grossPrice: number;
  insuranceShareNet: number;
  insuranceShareTotal: number;
  insuranceShareVat: number;
  netValue: number;
  patientShareNet: number;
  patientShareTotal: number;
  patientShareVat: number;
  vatExemptAmount: number;
  vatPercent: number;
  vatValue: number;
}

export interface ExtendedItemsSnapshot extends ItemsSnapshot {
  items: ExtendedItem[];
}
