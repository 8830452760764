export const FETCH_WALLET_DETAILS_REQUEST = 'wallet/FETCH_WALLET_DETAILS_REQUEST';
export const FETCH_WALLET_DETAILS_SUCCESS = 'wallet/FETCH_WALLET_DETAILS_SUCCESS';
export const FETCH_WALLET_DETAILS_FAILURE = 'wallet/FETCH_WALLET_DETAILS_FAILURE';

export const FETCH_TRANSACTION_DETAILS_REQUEST = 'wallet/FETCH_TRANSACTION_DETAILS_REQUEST';
export const FETCH_TRANSACTION_DETAILS_SUCCESS = 'wallet/FETCH_TRANSACTION_DETAILS_SUCCESS';
export const FETCH_TRANSACTION_DETAILS_FAILURE = 'wallet/FETCH_TRANSACTION_DETAILS_FAILURE';

export const SET_PARAMS = 'wallet/SET_PARAMS';

export const FETCH_TRANSACTION_TICKET_REQUEST = 'wallets/FETCH_TRANSACTION_TICKET_REQUEST';
export const FETCH_TRANSACTION_TICKET_SUCCESS = 'wallets/FETCH_TRANSACTION_TICKET_SUCCESS';
export const FETCH_TRANSACTION_TICKET_FAILURE = 'wallets/FETCH_TRANSACTION_TICKET_FAILURE';
