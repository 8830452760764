import { takeEvery, throttle } from 'redux-saga/effects';
import {
  afterAddBillingItemSuccess,
  afterEditBillingItemSuccess,
  afterRemoveBillingItemSuccess,
  afterToggleBillingItemSuccess,
  refreshBranchPackages,
  refreshPerformerBillingItemsSoft,
  refreshPractitionerPackages,
  wsUpdatePerformerBillingItemsTable,
} from 'app/sagas/billingItemLists/generators';
import {
  ADD_PERFORMER_BILLING_ITEM_SUCCESS,
  EDIT_PERFORMER_BILLING_ITEM_SUCCESS,
  REMOVE_PERFORMER_BILLING_ITEM_SUCCESS,
  TOGGLE_PERFORMER_ITEM_SUCCESS,
  WS_UPDATE_PERFORMER_BILLING_ITEMS_TABLE,
} from 'app/redux/billingItemsLists/billingItemsLists.types';
import { WS_REFRESH_PERFORMER_BILLING_ITEMS } from 'app/sagas/billingItemLists/types';
import {
  ADD_BRANCH_BILLING_PACKAGES_SUCCESS,
  EDIT_BRANCH_BILLING_PACKAGES_SUCCESS,
  REMOVE_BRANCH_BILLING_PACKAGES_SUCCESS,
} from 'app/redux/billingPackages/billingPackages.types';
import { MASS_ADD_BRANCH_PACKAGES_SUCCESS } from 'app/redux/mass-adding/massAdding.types';
import {
  ADD_PRACTITIONER_BILLING_PACKAGES_SUCCESS,
  CHANGE_PRACTITIONER_PACKAGE_STATUS_SUCCESS,
  EDIT_PRACTITIONER_BILLING_PACKAGES_SUCCESS,
  REMOVE_PRACTITIONER_BILLING_PACKAGES_SUCCESS,
} from 'app/redux/practitionerBillingPackages/practitionerBillingPackages.types';
import {
  ASSIGN_MEDICATION_SUCCESS,
  REMOVE_PERFORMER_MEDICATION_SUCCESS,
} from 'app/redux/medications/medications.types';
import { afterAssignMedicationSuccess } from 'app/sagas/billingItemLists/generators/performerMedications/afterAssignMedicationSuccess';
import { afterRemovePerformerMedicationSuccess } from 'app/sagas/billingItemLists/generators/performerMedications/afterRemovePerformerMedicationSuccess';

function* billingItemListsSaga() {
  // Performer Medications
  yield takeEvery([ASSIGN_MEDICATION_SUCCESS], afterAssignMedicationSuccess);
  yield takeEvery([REMOVE_PERFORMER_MEDICATION_SUCCESS], afterRemovePerformerMedicationSuccess);

  // Performer Billing Items
  yield takeEvery([ADD_PERFORMER_BILLING_ITEM_SUCCESS], afterAddBillingItemSuccess);
  yield takeEvery([EDIT_PERFORMER_BILLING_ITEM_SUCCESS], afterEditBillingItemSuccess);
  yield takeEvery([REMOVE_PERFORMER_BILLING_ITEM_SUCCESS], afterRemoveBillingItemSuccess);
  yield takeEvery([TOGGLE_PERFORMER_ITEM_SUCCESS], afterToggleBillingItemSuccess);

  // Branch Packages
  yield takeEvery(
    [
      REMOVE_BRANCH_BILLING_PACKAGES_SUCCESS,
      EDIT_BRANCH_BILLING_PACKAGES_SUCCESS,
      ADD_BRANCH_BILLING_PACKAGES_SUCCESS,
      MASS_ADD_BRANCH_PACKAGES_SUCCESS,
    ],
    refreshBranchPackages,
  );

  // Performer Packages
  yield takeEvery(
    [
      REMOVE_PRACTITIONER_BILLING_PACKAGES_SUCCESS,
      EDIT_PRACTITIONER_BILLING_PACKAGES_SUCCESS,
      ADD_PRACTITIONER_BILLING_PACKAGES_SUCCESS,
      CHANGE_PRACTITIONER_PACKAGE_STATUS_SUCCESS,
    ],
    refreshPractitionerPackages,
  );

  // Web Socket
  yield takeEvery(WS_UPDATE_PERFORMER_BILLING_ITEMS_TABLE, wsUpdatePerformerBillingItemsTable);
  yield throttle(3000, WS_REFRESH_PERFORMER_BILLING_ITEMS, refreshPerformerBillingItemsSoft);
}

export default billingItemListsSaga;
