import { useCallback, useState } from 'react';
import { LocalTable } from 'app/shared';
import { FormattedPrice } from 'app/components';
import { useScrollTo } from 'app/helpers/useScrollTo/useScrollTo';
import { InvoicePaymentMethodItem, InvoiceStatus } from 'app/types';
import { useAppDispatch, useAppIntl, useAppSelector, useCurrentUser } from 'app/helpers';
import { FormMode } from 'app/features/invoicingProcess/process/paymentMethod/types';
import { ConfirmationModal } from 'app/components/confirmationModal/confirmationModal';
import { useInvoicingProcessState } from 'app/features/invoicingProcess/process/helpers';
import { useLeftToPay } from 'app/features/invoicingProcess/process/paymentMethod/helpers/useLeftToPay';
import { SectionHeading } from 'app/features/invoicingProcess/process/components/sectionHeading/sectionHeading';
import { AccountBalance } from 'app/features/invoicingProcess/process/components/accountBalance/accountBalance';
import { EditFormWrapper } from 'app/features/invoicingProcess/process/components/editFormWrapper/editFormWrapper';
import { usePaymentsColumns } from 'app/features/invoicingProcess/process/paymentMethod/helpers/usePaymentsColumns';
import { deletePaymentMethodItem, selectPaymentMethodItem } from 'app/redux/invoicingProcess/invoicingProcess.actions';
import { AddPaymentMethodForm } from 'app/features/invoicingProcess/process/paymentMethod/addPaymentMethodForm/addPaymentMethodForm';
import { EditPaymentMethodForm } from 'app/features/invoicingProcess/process/paymentMethod/editPaymentMethodForm/editPaymentMethodForm';
import { PaymentConfigurationForm } from 'app/features/invoicingProcess/process/paymentMethod/paymentConfigurationForm/paymentConfigurationForm';
import { useCreditNotePaymentsColumns } from 'app/features/invoicingProcess/process/paymentMethod/helpers/useCreditNotePaymentsColumns';

export const PaymentMethod = () => {
  const leftToPay = useLeftToPay();
  const dispatch = useAppDispatch();
  const { isViewer } = useCurrentUser();
  const { formatMessage } = useAppIntl();
  const [mode, setMode] = useState<FormMode>(FormMode.Add);
  const [headerRef, scrollToHeader] = useScrollTo<HTMLHeadingElement>();
  const [deleteConfirmationModal, setDeleteConfirmationModal] = useState(false);
  const { invoicePackageSnapshotId } = useInvoicingProcessState();
  const paymentMethodItems = useAppSelector((state) => state.invoicingProcess.paymentMethodItems);
  const selectedPaymentMethodItem = useAppSelector((state) => state.invoicingProcess.selectedPaymentMethodItem);
  const invoiceStatus = useAppSelector((state) => state.invoicingProcess.data?.invoiceStatus);
  const isClearedByCreditNote = invoiceStatus?.value === InvoiceStatus.ClearedByCreditNote;
  const creditNotePayments = useAppSelector(
    (state) => state.invoicingProcess.data?.steps?.paymentMethod?.creditNotePayments || [],
  );

  const editPaymentMethod = useCallback(
    (item: InvoicePaymentMethodItem) => {
      dispatch(selectPaymentMethodItem(item));
      setMode(FormMode.Edit);
      scrollToHeader(true);
    },
    [dispatch, scrollToHeader],
  );

  const onEditPaymentMethodSubmitSuccess = useCallback(() => {
    setMode(FormMode.Add);
  }, []);

  const deletePaymentMethod = useCallback(
    (item: InvoicePaymentMethodItem) => {
      dispatch(selectPaymentMethodItem(item));
      setDeleteConfirmationModal(true);
    },
    [dispatch],
  );

  const onConfirmDelete = useCallback(() => {
    if (selectedPaymentMethodItem) {
      setMode(FormMode.Add);
      dispatch(deletePaymentMethodItem(selectedPaymentMethodItem.id));
      setDeleteConfirmationModal(false);
    }
  }, [dispatch, selectedPaymentMethodItem]);

  const paymentsColumns = usePaymentsColumns({ deletePaymentMethod, editPaymentMethod });
  const creditNotePaymentsColumns = useCreditNotePaymentsColumns();

  return (
    <>
      <SectionHeading
        title={formatMessage({ id: 'CORE.LABEL.PAYMENTS', defaultMessage: 'Payments' })}
        ref={headerRef}
      />
      {!invoicePackageSnapshotId && <PaymentConfigurationForm />}
      {mode === FormMode.Add && !isViewer && leftToPay > 0 && !isClearedByCreditNote && <AddPaymentMethodForm />}
      {mode === FormMode.Edit && !isViewer && (
        <EditFormWrapper onCancel={() => setMode(FormMode.Add)}>
          <EditPaymentMethodForm onSubmitSuccess={onEditPaymentMethodSubmitSuccess} />
        </EditFormWrapper>
      )}
      <AccountBalance className="mt-3" />
      {!!paymentMethodItems.length && (
        <LocalTable
          classes="table-sm mt-2"
          columns={paymentsColumns}
          data={paymentMethodItems}
          id="paymentMethods"
          keyField="id"
          loading={false}
          noDataComponent={<></>}
          wrapperClasses="my-2"
        />
      )}
      {!!creditNotePayments.length && (
        <>
          <h5 className="mt-4">{formatMessage({ id: 'CORE.LABEL.REFUNDED-PAYMENTS' })}</h5>
          <LocalTable
            classes="table-sm"
            columns={creditNotePaymentsColumns}
            data={creditNotePayments}
            id="refundedPayments"
            keyField="creditNoteId"
            loading={false}
            noDataComponent={<></>}
            wrapperClasses="my-2"
          />
        </>
      )}
      <h5 className="mt-4">
        <span className="me-1">
          {leftToPay < 0
            ? formatMessage({ id: 'CORE.LABEL.OVERPAYMENT', defaultMessage: 'Overpayment' })
            : formatMessage({ id: 'CORE.LABEL.LEFT-TO-PAY', defaultMessage: 'Left to Pay' })}
          {': '}
        </span>
        <span className={leftToPay === 0 ? 'text-success' : 'text-danger'}>
          <FormattedPrice amount={Math.abs(leftToPay)} />
        </span>
      </h5>
      <ConfirmationModal
        isOpen={deleteConfirmationModal}
        onClose={() => setDeleteConfirmationModal(false)}
        onConfirm={onConfirmDelete}
        description={formatMessage({
          id: 'CORE.TEXT.DELETE-ITEM-CONFIRMATION',
          defaultMessage: 'Are you sure you want to delete this item?',
        })}
      />
    </>
  );
};
