import { put, select } from 'redux-saga/effects';
import { PayloadAction } from '@reduxjs/toolkit';
import { fetchBranchBillingMedications } from 'app/redux/remoteData/remoteData.actions';
import { Medication, RootState } from 'app/types';
import { Meta } from 'app/sagas/billingItem/types';

export function* afterChangeMedicationSuccess({ meta }: PayloadAction<{ data: Medication }, string, Meta>) {
  const {
    remoteData: {
      branchBillingMedication: { params },
    },
  }: RootState = yield select();

  if (params) {
    yield put<any>(fetchBranchBillingMedications(meta.branchId, params));
  }
}
