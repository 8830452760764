import round from 'lodash/round';
import { PackageItem } from 'app/types';

export const updatedItems = (packageItems: PackageItem[], id: string, total: number) => {
  return (
    packageItems.map((item) =>
      item.id === id
        ? {
            ...item,
            total: Number(total),
            pricePerUnit: round(Number(total) / item.quantity, 2),
          }
        : item,
    ) || []
  );
};
