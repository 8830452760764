export const exclusionsTab = {
  specialty: 'specialty',
  practitioner: 'practitioner',
  billingItem: 'billing-item',
  ICD10: 'icd-10',
} as const;

export const policiesTab = {
  removedPolicies: 'removed-policies',
  policySetup: 'policy-setup',
} as const;

export const billingItemsTab = {
  items: 'items',
  packages: 'packages',
  medications: 'medications',
} as const;

export const insuranceContractorsSetupTab = {
  contractPolicySetup: 'contract-policy-setup',
  removedExpiredContractors: 'removed-expired-contractors',
} as const;

export const insuranceClaimsTab = {
  unclaimed: 'unclaimed',
  claimed: 'claimed',
  settlement: 'settlement',
  canceled: 'canceled',
} as const;

export const ClaimsLogTabs = {
  statements: 'statements',
  claim: 'claim',
  userActions: 'user-actions',
} as const;

export const insurancesManagementPathCore = '/insurances-management';
export const insuranceContractorExclusionsPath = `${insurancesManagementPathCore}/:sourceTab/contractor/:contractorId/exclusions/scope/:scope/:tab(${Object.values(
  exclusionsTab,
).join('|')})`;
export const insuranceContractExclusionsPath = `${insurancesManagementPathCore}/:sourceTab/contract/:contractId/exclusions/scope/:scope/:tab(${Object.values(
  exclusionsTab,
).join('|')})`;
export const insuranceContractorExclusionsLogPath = `${insurancesManagementPathCore}/:sourceTab/contractor/:contractorId/exclusions/scope/:scope/:tab(${Object.values(
  exclusionsTab,
).join('|')})/actions-log`;
export const insuranceContractExclusionsLogPath = `${insurancesManagementPathCore}/:sourceTab/contract/:contractId/exclusions/scope/:scope/:tab(${Object.values(
  exclusionsTab,
).join('|')})/actions-log`;

export const insurancePoliciesSetUpPath = `${insurancesManagementPathCore}/:sourceTab/scope/:scope/:tab(${Object.values(
  policiesTab,
).join('|')})/:contractId`;

export const insuranceBillingItemsContractorPath = `${insurancesManagementPathCore}/:sourceTab/contract-billing-items/branches/:branchId/contractor/:contractorId/scope/:scope/:tab(${Object.values(
  billingItemsTab,
).join('|')})`;

export const insuranceBillingItemsContractPath = `${insurancesManagementPathCore}/:sourceTab/contract-billing-items/branches/:branchId/contract/:contractId/scope/:scope/:tab(${Object.values(
  billingItemsTab,
).join('|')})`;

export const insuranceBillingItemsInclusionContractorPath = `${insurancesManagementPathCore}/:sourceTab/:sourcePolicyTab/contract-billing-items-inclusion/branches/:branchId/insuranceClass/:insuranceClassId/scope/:scope/:tab(${Object.values(
  billingItemsTab,
).join('|')})`;

export const insuranceContractsSetUpPath = `${insurancesManagementPathCore}/scope/:scope/:tab(${Object.values(
  insuranceContractorsSetupTab,
).join('|')})`;

export const insuranceBranchContractsSetUpPath = `${insurancesManagementPathCore}/scope/:scope/:tab(${Object.values(
  insuranceContractorsSetupTab,
).join('|')})/branch/:branchId`;

export const insuranceClaimsPath = `${insurancesManagementPathCore}/claims/:tabId(${Object.values(
  insuranceClaimsTab,
).join('|')})`;

export const insuranceClaimsLogsPath = `${insurancesManagementPathCore}/claims/logs/:tab(${Object.values(
  ClaimsLogTabs,
).join('|')})`;
