import { useMemo } from 'react';
import { Option } from 'app/types';
import { useAppIntl } from 'app/helpers/useAppIntl/useAppIntl';

export const useBoolOptions = () => {
  const { formatMessage } = useAppIntl();

  return useMemo<Option<string>[]>(
    () => [
      {
        label: formatMessage({ id: 'CORE.LABEL.YES' }),
        value: 'true',
      },
      {
        label: formatMessage({ id: 'CORE.LABEL.NO' }),
        value: 'false',
      },
    ],
    [formatMessage],
  );
};
