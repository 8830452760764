import { ReactNode } from 'react';
import classnames from 'classnames';
import './avatar.scss';

interface Props {
  children?: ReactNode;
  className?: string;
  imageSrc?: string;
  onClick?: () => void;
  size?: 'xs' | 'sm' | 'lg';
  status?: boolean;
  title?: string;
}

export const Avatar = ({ children, className, imageSrc, onClick, size, status, title }: Props) => {
  return (
    <div
      className={classnames(
        'avatar',
        {
          'avatar--xs': size === 'xs',
          'avatar--sm': size === 'sm',
          'avatar--lg': size === 'lg',
        },
        className,
      )}
      onClick={onClick}
      style={imageSrc ? { backgroundImage: `url(${imageSrc})` } : undefined}
      title={title}
    >
      {/* Children */}
      {children}

      {/* Status */}
      {status !== undefined && (
        <div
          className={classnames('avatar__status', {
            'avatar__status--offline': !status,
          })}
        />
      )}
    </div>
  );
};
