import React, { useCallback, useMemo } from 'react';
import { Alert, Col, Row } from 'reactstrap';
import { FieldArray } from 'redux-form';
import { WrappedFieldArrayProps } from 'redux-form/lib/FieldArray';
import { IconApps, IconExclamationCircle } from '@tabler/icons-react';
import { IconButton } from 'app/components';
import { FORMS_ASSESSMENT_MEDICAL_PROFILE_FORM } from 'app/shared';
import { useAppDispatch, useAppIntl, useAppSelector } from 'app/helpers';
import { FieldArrayTable } from 'app/assessment/medicalProfileSection/components/helpers/wrappers/diagnosisWrapper/fieldArrayTable/fieldArrayTable';
import { toggleModal } from 'app/redux/modals/modals.actions';
import { DoctorAssessmentViewStatus, ICD10Code } from 'app/types';

export const DiagnosisWrapper = () => {
  const { formatMessage } = useAppIntl();
  const dispatch = useAppDispatch();

  // Selectors
  const isReadOnly = useAppSelector(
    (state) => state.doctorAssessment.data?.viewStatus === DoctorAssessmentViewStatus.ReadOnly,
  );
  const patientInsuranceId = useAppSelector((state) => state.doctorAssessment.data?.billingSection.patientInsuranceId);
  const assessmentDiagnoses: ICD10Code[] = useAppSelector(
    (state) => state.form[FORMS_ASSESSMENT_MEDICAL_PROFILE_FORM]?.values?.icdTable || [],
  );

  // Helper Variables
  const excludedDiagnoses = useMemo(
    () => assessmentDiagnoses.filter((diagnose) => diagnose.isExcluded),
    [assessmentDiagnoses],
  );

  // Conditions
  const isAnyDiagnoseExcluded = excludedDiagnoses.length > 0;

  // Renderers
  const renderTable = useCallback(
    (props: WrappedFieldArrayProps) => {
      return <FieldArrayTable fieldArrayProps={props} isReadOnly={isReadOnly} />;
    },
    [isReadOnly],
  );

  const openAddModal = useCallback(() => {
    dispatch(toggleModal('addInsuranceDiagnosis', true));
  }, [dispatch]);

  return (
    <>
      <div>
        <div id="popoverTarget">
          <Row>
            <Col className="flex-grow-1">
              <h5>{formatMessage({ id: 'CORE.LABEL.DIAGNOSES' })}</h5>
            </Col>
            <Col xs="auto" className="text-end">
              <IconButton
                icon={<IconApps size={22} strokeWidth={1.8} />}
                onClick={openAddModal}
                disabled={isReadOnly}
                tooltipMessageId="CORE.BUTTON.ADD-ITEM"
              />
            </Col>
          </Row>
          {patientInsuranceId && isAnyDiagnoseExcluded && (
            <Alert color="danger" className="mb-3 py-1 px-2">
              <IconExclamationCircle size={16} />
              <span className="ms-1 small">{formatMessage({ id: 'CORE.TEXT.ASSESSMENT-DIAGNOSE-EXCLUDED' })}</span>
            </Alert>
          )}
        </div>

        <FieldArray name="icdTable" component={renderTable} />
      </div>
    </>
  );
};
