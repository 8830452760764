import { generatePath } from 'react-router';
import { InsuranceContractorsScope } from 'app/types';
import {
  insuranceBillingItemsContractorPath,
  insuranceBillingItemsContractPath,
  insuranceBillingItemsInclusionContractorPath,
  insuranceBranchContractsSetUpPath,
  insuranceClaimsLogsPath,
  insuranceContractExclusionsLogPath,
  insuranceContractExclusionsPath,
  insuranceContractorExclusionsLogPath,
  insuranceContractorExclusionsPath,
  insuranceContractsSetUpPath,
  insurancePoliciesSetUpPath,
} from 'app/routes/insurancesManagement/constants';
import {
  BillingItemsTab,
  ExclusionsTab,
  InsuranceContractorsSetupTab,
  PoliciesTab,
} from 'app/routes/insurancesManagement/types';
import { ClaimsLogTab } from 'app/features/insuranceClaims/components/claimsLogsPage/types';

export const generateInsuranceContractorExclusionsPath = (
  tab: ExclusionsTab,
  contractorId: string,
  scope: InsuranceContractorsScope,
  sourceTab: InsuranceContractorsSetupTab,
) =>
  generatePath(insuranceContractorExclusionsPath, {
    contractorId,
    tab,
    scope,
    sourceTab,
  });

export const generateInsuranceContractExclusionsPath = (
  tab: ExclusionsTab,
  contractId: string,
  scope: InsuranceContractorsScope,
  sourceTab: InsuranceContractorsSetupTab,
) =>
  generatePath(insuranceContractExclusionsPath, {
    contractId,
    tab,
    scope,
    sourceTab,
  });

export const generateInsuranceContractorExclusionsLogPath = (
  tab: ExclusionsTab,
  contractorId: string,
  scope: InsuranceContractorsScope,
  sourceTab: InsuranceContractorsSetupTab,
) =>
  generatePath(insuranceContractorExclusionsLogPath, {
    contractorId,
    tab,
    scope,
    sourceTab,
  });

export const generateInsuranceContractExclusionsLogPath = (
  tab: ExclusionsTab,
  contractId: string,
  scope: InsuranceContractorsScope,
  sourceTab: InsuranceContractorsSetupTab,
) =>
  generatePath(insuranceContractExclusionsLogPath, {
    contractId,
    tab,
    scope,
    sourceTab,
  });

export const generateInsurancePoliciesSetUpPath = (
  tab: PoliciesTab,
  contractId: string,
  scope: InsuranceContractorsScope,
  sourceTab: InsuranceContractorsSetupTab,
) =>
  generatePath(insurancePoliciesSetUpPath, {
    tab,
    contractId,
    scope,
    sourceTab,
  });

export const generateInsuranceBillingItemsContractorPath = (
  tab: BillingItemsTab,
  branchId: string,
  contractorId: string,
  scope: InsuranceContractorsScope,
  sourceTab: InsuranceContractorsSetupTab,
) =>
  generatePath(insuranceBillingItemsContractorPath, {
    tab,
    branchId,
    contractorId,
    scope,
    sourceTab,
  });

export const generateInsuranceBillingItemsContractPath = (
  tab: BillingItemsTab,
  branchId: string,
  contractId: string,
  scope: InsuranceContractorsScope,
  sourceTab: InsuranceContractorsSetupTab,
) =>
  generatePath(insuranceBillingItemsContractPath, {
    tab,
    branchId,
    contractId,
    scope,
    sourceTab,
  });

export const generateInsuranceBillingItemsInclusionContractorPath = (
  tab: BillingItemsTab,
  branchId: string,
  insuranceClassId: string,
  scope: InsuranceContractorsScope,
  sourceTab: InsuranceContractorsSetupTab,
  sourcePolicyTab: PoliciesTab,
) =>
  generatePath(insuranceBillingItemsInclusionContractorPath, {
    tab,
    branchId,
    insuranceClassId,
    scope,
    sourceTab,
    sourcePolicyTab,
  });

export const generateInsuranceContractsSetUpPath = (
  tab: InsuranceContractorsSetupTab,
  scope: InsuranceContractorsScope,
) =>
  generatePath(insuranceContractsSetUpPath, {
    tab,
    scope,
  });

export const generateInsuranceBranchContractsSetUpPath = (
  tab: InsuranceContractorsSetupTab,
  branchId: string,
  scope: InsuranceContractorsScope,
) =>
  generatePath(insuranceBranchContractsSetUpPath, {
    tab,
    branchId,
    scope,
  });

export const generateClaimsLogsPath = (tab: ClaimsLogTab) =>
  generatePath(insuranceClaimsLogsPath, {
    tab,
  });
