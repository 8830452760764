import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQueryWithReAuth } from 'app/helpers';
import {
  AddExclusionToContractEntityParams,
  AddMultiExclusionsToContractEntityParams,
  ChangeStatusContractEntityExclusionParams,
  GetContractEntityExclusionsReport,
  GetInsuranceContractEntityExclusionsLogsParams,
  GetInsuranceContractEntityExclusionsParams,
  RemoveContractEntityExclusionParams,
} from 'app/services/contractsAndPoliciesExclusions/types';
import { generateUrlChunk } from 'app/services/contractsAndPoliciesExclusions/helpers/generateUrlChunk';
import { QueryResponseWithPagination } from 'app/types';
import { Exclusion, ExclusionsLog } from 'app/types/Exclusions';

export const contractsAndPoliciesExclusionsApi = createApi({
  reducerPath: 'contractsAndPoliciesExclusions',
  baseQuery: baseQueryWithReAuth,
  tagTypes: ['ContractorExclusions', 'ContractExclusions'],
  endpoints: (builder) => ({
    // [?]: Contractor Exclusions Endpoints
    addMultiExclusionsToContractor: builder.mutation<void, AddMultiExclusionsToContractEntityParams>({
      query: ({ contractEntityId, body }) => ({
        url: `insurance-contractors/${contractEntityId}/exclusions/mass-adding`,
        method: 'POST',
        body,
      }),
      invalidatesTags: ['ContractorExclusions'],
    }),

    getContractorExclusionsReport: builder.query<void, GetContractEntityExclusionsReport>({
      query: ({ contractEntityId, exclusionsTabId, params }) => ({
        url: `insurance-contractors/${contractEntityId}/${generateUrlChunk(exclusionsTabId)}/excel`,
        params,
      }),
    }),

    changeContractorExclusionStatus: builder.mutation<void, ChangeStatusContractEntityExclusionParams>({
      query: ({ exclusionId, exclusionsTabId, contractEntityId, status }) => ({
        url: `insurance-contractors/${contractEntityId}/${generateUrlChunk(exclusionsTabId)}/${exclusionId}/status`,
        method: 'PATCH',
        params: {
          status,
        },
      }),
      invalidatesTags: ['ContractorExclusions'],
    }),

    removeContractorExclusion: builder.mutation<void, RemoveContractEntityExclusionParams>({
      query: ({ contractEntityId, exclusionsTabId, exclusionId }) => ({
        url: `insurance-contractors/${contractEntityId}/${generateUrlChunk(exclusionsTabId)}/${exclusionId}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['ContractorExclusions'],
    }),

    getInsuranceContractorExclusionsLogs: builder.query<
      QueryResponseWithPagination<ExclusionsLog[]>,
      GetInsuranceContractEntityExclusionsLogsParams
    >({
      query: ({ contractEntityId, params, exclusionType }) => ({
        url: `insurance-contractors/${contractEntityId}/exclusion/logs`,
        method: 'GET',
        params: {
          exclusionType,
          ...params,
        },
      }),
      providesTags: ['ContractorExclusions'],
    }),

    addExclusionToInsuranceContractor: builder.mutation<void, AddExclusionToContractEntityParams>({
      query: ({ contractEntityId, exclusionsTabId, body }) => ({
        url: `insurance-contractors/${contractEntityId}/${generateUrlChunk(exclusionsTabId)}`,
        method: 'POST',
        body,
      }),
      invalidatesTags: ['ContractorExclusions'],
    }),

    getInsuranceContractorExclusions: builder.query<
      QueryResponseWithPagination<Exclusion[]>,
      GetInsuranceContractEntityExclusionsParams
    >({
      query: ({ contractEntityId, exclusionsTabId, params }) => ({
        url: `insurance-contractors/${contractEntityId}/${generateUrlChunk(exclusionsTabId)}`,
        method: 'GET',
        params,
      }),
      providesTags: ['ContractorExclusions'],
    }),

    // [?]: Contract Exclusions Endpoints
    addMultiExclusionsToContract: builder.mutation<void, AddMultiExclusionsToContractEntityParams>({
      query: ({ contractEntityId, body }) => ({
        url: `insurance-contracts/${contractEntityId}/exclusions/mass-adding`,
        method: 'POST',
        body,
      }),
      invalidatesTags: ['ContractExclusions'],
    }),

    getContractExclusionsReport: builder.query<void, GetContractEntityExclusionsReport>({
      query: ({ contractEntityId, exclusionsTabId, params }) => ({
        url: `insurance-contracts/${contractEntityId}/${generateUrlChunk(exclusionsTabId)}/excel`,
        params,
      }),
    }),

    changeContractExclusionStatus: builder.mutation<void, ChangeStatusContractEntityExclusionParams>({
      query: ({ exclusionId, exclusionsTabId, contractEntityId, status }) => ({
        url: `insurance-contracts/${contractEntityId}/${generateUrlChunk(exclusionsTabId)}/${exclusionId}/status`,
        method: 'PATCH',
        params: {
          status,
        },
      }),
      invalidatesTags: ['ContractExclusions'],
    }),

    removeContractExclusion: builder.mutation<void, RemoveContractEntityExclusionParams>({
      query: ({ contractEntityId, exclusionsTabId, exclusionId }) => ({
        url: `insurance-contracts/${contractEntityId}/${generateUrlChunk(exclusionsTabId)}/${exclusionId}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['ContractExclusions'],
    }),

    getInsuranceContractExclusionsLogs: builder.query<
      QueryResponseWithPagination<ExclusionsLog[]>,
      GetInsuranceContractEntityExclusionsLogsParams
    >({
      query: ({ contractEntityId, params, exclusionType }) => ({
        url: `insurance-contracts/${contractEntityId}/exclusion/logs`,
        method: 'GET',
        params: {
          exclusionType,
          ...params,
        },
      }),
      providesTags: ['ContractExclusions'],
    }),

    addExclusionToInsuranceContract: builder.mutation<void, AddExclusionToContractEntityParams>({
      query: ({ contractEntityId, exclusionsTabId, body }) => ({
        url: `insurance-contracts/${contractEntityId}/${generateUrlChunk(exclusionsTabId)}`,
        method: 'POST',
        body,
      }),
      invalidatesTags: ['ContractExclusions'],
    }),

    getInsuranceContractExclusions: builder.query<
      QueryResponseWithPagination<Exclusion[]>,
      GetInsuranceContractEntityExclusionsParams
    >({
      query: ({ contractEntityId, exclusionsTabId, params }) => ({
        url: `insurance-contracts/${contractEntityId}/${generateUrlChunk(exclusionsTabId)}`,
        method: 'GET',
        params,
      }),
      providesTags: ['ContractExclusions'],
    }),
  }),
});

export const {
  // [i]: Contractor Exclusions Endpoints
  useAddMultiExclusionsToContractorMutation,
  useLazyGetContractorExclusionsReportQuery,
  useChangeContractorExclusionStatusMutation,
  useRemoveContractorExclusionMutation,
  useGetInsuranceContractorExclusionsLogsQuery,
  useAddExclusionToInsuranceContractorMutation,
  useGetInsuranceContractorExclusionsQuery,
  // [i]: Contract Exclusions Endpoints
  useAddMultiExclusionsToContractMutation,
  useLazyGetContractExclusionsReportQuery,
  useChangeContractExclusionStatusMutation,
  useRemoveContractExclusionMutation,
  useGetInsuranceContractExclusionsLogsQuery,
  useAddExclusionToInsuranceContractMutation,
  useGetInsuranceContractExclusionsQuery,
} = contractsAndPoliciesExclusionsApi;
