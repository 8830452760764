import React, { useCallback } from 'react';
import { FormGroup, Input, Label } from 'reactstrap';
import { useAppDispatch, useAppIntl, useAppSelector } from 'app/helpers';
import { switchNoTimeItem } from 'app/redux/calendar/calendar.actions';

interface Props {
  id: string;
}

export const DateFormatterNoTimeSwitch = (props: Props) => {
  const dispatch = useAppDispatch();
  const { formatMessage } = useAppIntl();
  const switchId = `noTimeSwitch-${props.id}`;
  const isWithNoTimeMode = useAppSelector((state) => state.calendar.tableView.withNoTimeItems.includes(props.id));

  // Actions
  const switchNoTime = useCallback(() => dispatch(switchNoTimeItem(props.id)), [dispatch, props.id]);

  return (
    <div className="d-flex justify-content-center align-content-center my-3">
      <FormGroup className="lh-base user-select-none" switch>
        <Input
          id={switchId}
          name="noTimeSwitch"
          onChange={switchNoTime}
          type="checkbox"
          role="switch"
          checked={isWithNoTimeMode}
        />
        <Label for={switchId}>{formatMessage({ id: 'CORE.BUTTON.BOOK-WITH-NO-TIME' })}</Label>
      </FormGroup>
    </div>
  );
};
