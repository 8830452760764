import { useCallback } from 'react';
import { useAppDispatch, useAppSelector } from 'app/helpers';
import { InvoicingProcessStep } from 'app/redux/invoicingProcess/types';
import { setStep } from 'app/redux/invoicingProcess/invoicingProcess.actions';

export const useOnNext = () => {
  const dispatch = useAppDispatch();
  const step = useAppSelector((state) => state.invoicingProcess.step);

  return useCallback(() => {
    if (step === InvoicingProcessStep.Appointment) {
      dispatch(setStep(InvoicingProcessStep.PaymentMode));
    }

    if (step === InvoicingProcessStep.Details) {
      dispatch(setStep(InvoicingProcessStep.PaymentMode));
    }

    if (step === InvoicingProcessStep.PaymentMode) {
      dispatch(setStep(InvoicingProcessStep.Items));
    }

    if (step === InvoicingProcessStep.Items) {
      dispatch(setStep(InvoicingProcessStep.PaymentMethod));
    }

    if (step === InvoicingProcessStep.PaymentMethod) {
      dispatch(setStep(InvoicingProcessStep.Summary));
    }
  }, [dispatch, step]);
};
