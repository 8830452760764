import { Patient, Locale } from 'app/types';

export const getPatientFullName = (
  patient: Pick<Patient, 'fullNameArabic' | 'fullNameEnglish'> | undefined | null,
  locale: Locale,
): string => {
  if (!patient) {
    return '-';
  }

  const fullNameAr = patient?.fullNameArabic?.trim();
  const fullNameEn = patient?.fullNameEnglish?.trim();

  const fullNameByLocale = locale === Locale.ar ? fullNameAr : fullNameEn;

  if (fullNameByLocale) {
    // Return full name by selected locale
    return fullNameByLocale;
  }

  // One of these fields should always exist
  return fullNameAr || fullNameEn;
};
