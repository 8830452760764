import { ReactNode } from 'react';
import { Button } from 'reactstrap';
import { IconX } from '@tabler/icons-react';
import { useAppIntl } from 'app/helpers';
import styles from 'app/features/invoicingProcess/process/components/editFormWrapper/editFormWrapper.module.scss';

interface Props {
  onCancel: () => void;
  children: ReactNode;
}

export const EditFormWrapper = ({ children, onCancel }: Props) => {
  const { formatMessage } = useAppIntl();

  return (
    <div className={`${styles.wrapper} slideInUp`}>
      {children}
      <Button className="d-flex align-items-center p-0" color="link" onClick={onCancel} size="sm">
        <IconX size={16} className="text-danger me-1" />
        {formatMessage({ id: 'CORE.BUTTON.CANCEL-EDIT-MODE', defaultMessage: 'Cancel Edit Mode' })}
      </Button>
    </div>
  );
};
