import { useCallback, useState } from 'react';

export const useConfirmationModal = () => {
  const [confirmationModal, setConfirmationModal] = useState(false);
  const openConfirmationModal = useCallback(() => setConfirmationModal(true), []);
  const closeConfirmationModal = useCallback(() => setConfirmationModal(false), []);

  return {
    confirmationModal,
    openConfirmationModal,
    closeConfirmationModal,
  };
};
