import { useCallback, useRef } from 'react';
import { InvoiceType } from 'app/types';
import { useAppSelector, useConfirmationModal } from 'app/helpers';

export const useRelatedInvoicesRefundConfirmation = () => {
  const triggerRef = useRef<Function>();
  const invoicingProcess = useAppSelector((state) => state.invoicingProcess.data);
  const { confirmationModal, closeConfirmationModal, openConfirmationModal } = useConfirmationModal();

  const checkRelatedInvoicesRefundConfirmation = useCallback(
    (trigger: Function) => {
      const isAnyInsuranceInvoiceNeedToBeRefunded = !!invoicingProcess?.isAnyInsuranceInvoiceNeedToBeRefunded;
      const isFirstInsuranceInvoice = invoicingProcess?.invoiceType?.value === InvoiceType.FirstInsuranceInvoice;

      if (isAnyInsuranceInvoiceNeedToBeRefunded && isFirstInsuranceInvoice) {
        triggerRef.current = trigger;
        openConfirmationModal();
      } else {
        trigger();
      }
    },
    [invoicingProcess, openConfirmationModal],
  );

  const onConfirmRelatedInvoicesRefundConfirmation = useCallback(() => {
    if (triggerRef.current) {
      triggerRef.current();
      closeConfirmationModal();
    }
  }, [closeConfirmationModal]);

  return {
    relatedInvoicesRefundConfirmation: confirmationModal,
    onCancelRelatedInvoicesRefundConfirmation: closeConfirmationModal,
    onConfirmRelatedInvoicesRefundConfirmation,
    checkRelatedInvoicesRefundConfirmation,
  };
};
