import { Input } from 'reactstrap';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useMap, useMapsLibrary } from '@vis.gl/react-google-maps';
import { useAppIntl } from 'app/helpers';

interface Props {
  className?: string;
  setPosition?: (location: google.maps.LatLngLiteral) => void;
}

export const PlaceAutocomplete = ({ className, setPosition }: Props) => {
  const map = useMap();
  const places = useMapsLibrary('places');
  const inputRef = useRef<HTMLInputElement>(null);
  const { formatMessage } = useAppIntl();
  const [placeAutocomplete, setPlaceAutocomplete] = useState<google.maps.places.Autocomplete | null>(null);

  const onPlaceSelect = useCallback(
    (place: google.maps.places.PlaceResult | null) => {
      const location = place?.geometry?.location;

      if (location && map) {
        map.panTo(location);
        setPosition?.({ lat: location.lat(), lng: location.lng() });
      }
    },
    [map, setPosition],
  );

  useEffect(() => {
    if (!places || !inputRef.current) return;

    const options = {
      fields: ['geometry', 'name', 'formatted_address'],
    };

    setPlaceAutocomplete(new places.Autocomplete(inputRef.current, options));
  }, [places]);

  useEffect(() => {
    if (!placeAutocomplete) return;

    placeAutocomplete.addListener('place_changed', () => {
      onPlaceSelect(placeAutocomplete.getPlace());
    });
  }, [onPlaceSelect, placeAutocomplete]);

  return (
    <Input
      className={className}
      innerRef={inputRef}
      name="autocomplete"
      placeholder={formatMessage({ id: 'CORE.PLACEHOLDER.SEARCH-LOCATION' })}
      type="text"
    />
  );
};
