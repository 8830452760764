import { put } from 'redux-saga/effects';
import { PayloadAction } from '@reduxjs/toolkit';
import { toggleModal } from 'app/redux/modals/modals.actions';
import { Meta } from 'app/sagas/billingItemLists/types';
import { refreshPerformerItemLists } from 'app/sagas/billingItemLists/generators/refreshPerformerItemLists';
import { EDIT_PERFORMER_BILLING_ITEM_SUCCESS } from 'app/redux/billingItemsLists/billingItemsLists.types';

export function* afterEditBillingItemSuccess({ meta, type }: PayloadAction<void, string, Meta>) {
  if (type === EDIT_PERFORMER_BILLING_ITEM_SUCCESS) {
    yield put(toggleModal('editPerformerBillingItem', false));
    yield refreshPerformerItemLists(meta.branchId, true);
  }
}
