import { RSAA } from 'redux-api-middleware';

import * as types from './dictionaries.types';

export const fetchDictionaries = () => ({
  [RSAA]: {
    endpoint: `/dictionaries`,
    method: 'GET',
    types: [types.FETCH_DICTIONARIES_REQUEST, types.FETCH_DICTIONARIES_SUCCESS, types.FETCH_DICTIONARIES_FAILURE],
  },
});
