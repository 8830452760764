import { ReactNode } from 'react';
import './infoBar.scss';

interface InfoBarProps {
  children: ReactNode;
}

export const InfoBar = ({ children }: InfoBarProps) => (
  <div className="infoBar">
    <div className="infoBar__content">{children}</div>
  </div>
);
