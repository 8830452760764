import { useAppSelector } from 'app/helpers';
import { DescriptionList } from 'app/components';
import { useAppointmentDetailItems } from 'app/appointment/helpers/useAppointmentDetailItems';
import { FormContainer } from './form/form.container';
import { Actions } from './actions/actions';

export const Details = () => {
  // Selectors
  const appointment = useAppSelector((state) => state.appointment.data);
  const isWaiting = useAppSelector((state) => !!state.appointment.ticket?.isWaiting);

  // Helper Hooks
  const items = useAppointmentDetailItems(appointment, isWaiting, true);

  return (
    <>
      <DescriptionList items={items} />
      <FormContainer isWaiting={isWaiting} />
      <Actions />
    </>
  );
};
