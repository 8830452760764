const environments = {
  'myclinicy.com': {
    dsn: 'https://7360eeaae7f2486c91f29c1b869a3977@o305016.ingest.sentry.io/4903776',
    type: 'production',
  },
  'training.clinicy.com.sa': {
    dsn: 'https://7360eeaae7f2486c91f29c1b869a3977@o305016.ingest.sentry.io/4903776',
    type: 'training',
  },
  'staging.myclinicy.com': {
    dsn: 'https://7360eeaae7f2486c91f29c1b869a3977@o305016.ingest.sentry.io/4903776',
    type: 'staging',
  },
  'test.clinicy.com.sa': {
    dsn: 'https://7360eeaae7f2486c91f29c1b869a3977@o305016.ingest.sentry.io/4903776',
    type: 'test',
  },
};

const getConfig = (envDetails = {}) => ({
  dsn: envDetails.dsn || process.env.REACT_APP_SENTRY_DSN,
  environment: envDetails.type || process.env.ENV || process.env.NODE_ENV,
  release: envDetails.release || process.env.REACT_APP_RELEASE_VERSION || process.env.REACT_APP_RELEASE_HASH,
});

export const configuration = getConfig(environments[window.location.hostname]);
