import { FormGroup, Input, Label } from 'reactstrap';
import { WrappedFieldProps } from 'redux-form/lib/Field';
import styles from 'app/shared/form-elements/permissions/components/permissionSwitch/permissionSwitch.module.scss';

export interface PermissionSwitchProps {
  className?: string;
  formGroupClassName?: string;
  label: string;
}

export const PermissionSwitch = ({
  label,
  className,
  formGroupClassName,
  input,
}: WrappedFieldProps & PermissionSwitchProps) => {
  const inputId = input.name;

  return (
    <div className={`${styles.permissionSwitchWrapper} ${className}`}>
      <FormGroup switch className={`p-0 ${formGroupClassName}`}>
        <Label for={inputId} className={styles.permissionSwitchLabel}>
          <span>{label}</span>
          <Input
            className={styles.permissionSwitchInput}
            checked={input.value}
            id={inputId}
            name={input.name}
            onChange={input.onChange}
            role="switch"
            type="checkbox"
          />
        </Label>
      </FormGroup>
    </div>
  );
};
