import React, { ForwardedRef, ReactNode } from 'react';
import classnames from 'classnames';
import { useAppIntl } from 'app/helpers';
import { Box } from 'app/components';
import logo from 'assets/images/logo/clinicy-logo.png';
import styles from './printTemplate.module.scss';

interface Props {
  className?: string;
  title?: string;
  header?: ReactNode;
  body: ReactNode;
}

export const PrintTemplate = React.forwardRef((props: Props, ref: ForwardedRef<HTMLDivElement>) => {
  const { formatMessage, isRtl } = useAppIntl();

  return (
    <Box className={classnames('d-none', props.className)}>
      <div className={styles.printComponent} dir={isRtl ? 'rtl' : 'ltr'} ref={ref}>
        {props.header ? (
          props.header
        ) : (
          <div className="d-flex flex-column m-auto">
            <img className={styles.logo} src={logo} alt="logo" />
            {props.title && <h2 className={styles.printHeading}>{formatMessage({ id: props.title })}</h2>}
          </div>
        )}
        {props.body}
      </div>
    </Box>
  );
});
