import { put, select } from 'redux-saga/effects';
import { PayloadAction } from '@reduxjs/toolkit';
import { Appointment, RemoteData, RootState } from 'app/types';
import {
  EDIT_APPOINTMENT_NOTE_SUCCESS,
  EDIT_WAITING_APPOINTMENT_NOTE_SUCCESS,
} from 'app/redux/appointment/appointment.types';
import { fetchAppointmentActions } from 'app/redux/remoteData/remoteData.actions';
import { refreshAppointmentList } from 'app/sagas/apppointment/generators/refreshAppointmentList';

export function* afterEditNote({ payload, type }: PayloadAction<RemoteData<Appointment>>) {
  const appointmentId = payload.data.id;

  const {
    remoteData: {
      appointmentActions: { params },
    },
  }: RootState = yield select();

  // Appointment
  if (type === EDIT_APPOINTMENT_NOTE_SUCCESS) {
    if (params) {
      yield put<any>(fetchAppointmentActions(appointmentId, false, params));
    }
  }

  // Waiting Appointment
  if (type === EDIT_WAITING_APPOINTMENT_NOTE_SUCCESS) {
    if (params) {
      yield put<any>(fetchAppointmentActions(appointmentId, true, params));
    }
  }

  // Refresh Appointment List
  yield refreshAppointmentList();
}
