import moment from 'moment';
import { DEFAULT_PAGINATION_PARAMS, DEFAULT_TIME_FROM, DEFAULT_TIME_TO, FORMATS_API_DATE } from 'app/shared';
import { mapOptionItems } from 'app/helpers';
import { FormData } from 'app/sagas/apppointment/types';
import { CalendarSearchParams, ProductNameSearchStrategy } from 'app/redux/calendar/types';

const generateDate = (date: Date) => {
  // Check that the current month is selected
  if (moment(date).isSame(moment(), 'month')) {
    // Return the current day
    return moment().format(FORMATS_API_DATE);
  }
  // Return the first day of the month
  return moment(date).startOf('month').format(FORMATS_API_DATE);
};

export const generateCalendarSearchParams = (filters: FormData): CalendarSearchParams => ({
  ...DEFAULT_PAGINATION_PARAMS,

  clinicRelatedFilters: {
    branchId: filters.branch?.value,
    cityId: filters.city?.value,
    clinicId: filters.clinic?.value,
  },

  doctorRelatedFilters: {
    doctorIds: filters.doctor?.value ? [filters.doctor.value] : [],
    gender: filters.gender?.value,
    languages: filters.languages ? mapOptionItems(filters.languages) : [],
    ranks: filters.ranks ? mapOptionItems(filters.ranks) : [],
  },

  productRelatedFilters: {
    customDurationInMinutes: filters.customAppointmentSlots?.value,
    productNames: filters.products?.map((p) => p.label),
    searchStrategy: ProductNameSearchStrategy.AnyOfKeywordsIsEqualsToProductName,
    specialityId: filters.speciality.value,
    subSpecialityId: filters.subSpeciality.value,
  },

  keyword: filters.keyword,
  date: generateDate(filters.date),
  timeFrom: filters.timeFrom?.value || DEFAULT_TIME_FROM,
  timeTo: filters.timeTo?.value || DEFAULT_TIME_TO,
});
