import { useEffect } from 'react';
import { getApiDate, useAppDispatch, useAppSelector } from 'app/helpers';
import { fetchDay } from 'app/redux/appointment/appointment.actions';

export const useDayEffect = () => {
  const dispatch = useAppDispatch();

  // Selectors
  const customDurationInMinutes = useAppSelector(
    (state) => state.calendar.tableViewItems.params?.productRelatedFilters.customDurationInMinutes,
  );
  const selectedWaitingSlot = useAppSelector(
    (state) => state.calendar.selectedWaitingSlot,
    (left, right) => left?.date === right?.date,
  );

  useEffect(() => {
    if (selectedWaitingSlot) {
      dispatch(
        fetchDay({
          branchId: selectedWaitingSlot.branchId,
          date: getApiDate(selectedWaitingSlot.date),
          doctorId: selectedWaitingSlot.doctor.id,
          productRelatedFilters: {
            customDurationInMinutes,
            productId: selectedWaitingSlot.product.value,
            specialityId: selectedWaitingSlot.speciality.value,
            subSpecialityId: selectedWaitingSlot.subSpeciality.value,
          },
        }),
      );
    }
  }, [customDurationInMinutes, dispatch, selectedWaitingSlot]);
};
