import { createSlice } from '@reduxjs/toolkit';
import { QueryParamsState, SetQueryParamsAction } from 'app/redux/queryParams/types';

const initialState: QueryParamsState = {
  accountBalanceInvoices: undefined,
  adminUsers: undefined,
  branches: undefined,
  branchAppointments: undefined,
  branchDoctors: undefined,
  branchDeclarations: undefined,
  clinicBalanceSummary: undefined,
  clinicBalances: undefined,
  clinicOrders: undefined,
  clinicPatients: undefined,
  clinicTransactions: undefined,
  clinicUsers: undefined,
  clinics: undefined,
  conflictingAppointments: undefined,
  currentRequestsClinics: undefined,
  deletedBranchDoctors: undefined,
  deletedBranches: undefined,
  deletedBranchDeclarations: undefined,
  deletedClinics: undefined,
  doctorAppointments: undefined,
  doctors: undefined,
  deletedDoctors: undefined,
  generalAppointments: undefined,
  generalNotifications: undefined,
  iconLibrary: undefined,
  identityVerificationLog: undefined,
  insuranceApprovals: undefined,
  insuranceBillingItemsContractor: undefined,
  insuranceBillingItemsContract: undefined,
  insuranceBillingItemsInclusionContractor: undefined,
  insuranceBillingItemsMedications: undefined,
  insuranceBillingItemsPackages: undefined,
  insuranceContractExclusions: undefined,
  insuranceContractExclusionsLogs: undefined,
  insuranceContractSetUp: undefined,
  insuranceContractorExclusions: undefined,
  insuranceContractorExclusionsLogs: undefined,
  insuranceClinicContractorSetUp: undefined,
  insuranceClinicRemovedContractor: undefined,
  insuranceContractorSetUp: undefined,
  insurancePoliciesClassesSetUp: undefined,
  insurancePoliciesSetUp: undefined,
  insuranceReferrals: undefined,
  insuranceRemovedContractor: undefined,
  insuranceRemovedPoliciesSetUp: undefined,
  invoices: undefined,
  invoicesDrafts: undefined,
  invoicesRequests: undefined,
  massAdding: undefined,
  orderDetails: undefined,
  orders: undefined,
  pastRequestsClinics: undefined,
  patientActionLog: undefined,
  medicalInstitutionsPatientActionLog: undefined,
  patientAppointments: undefined,
  patientBalanceSummary: undefined,
  patientBalances: undefined,
  patientCategories: undefined,
  patientClinics: undefined,
  patientClinicsBalances: undefined,
  patientInvoices: undefined,
  patientMergeLog: undefined,
  patientSources: undefined,
  patientStatisticsAppointments: undefined,
  patientTransactions: undefined,
  patients: undefined,
  patientsArrival: undefined,
  pendingClinics: undefined,
  referrals: undefined,
  rejectedClinics: undefined,
  insuranceDetails: undefined,
  insuranceGlobalDetails: undefined,
  specialityContactsWithNotes: undefined,
  specialityNotes: undefined,
  specialities: undefined,
  subSpecialities: undefined,
  relatedProfile: undefined,
  guardian: undefined,
  dependents: undefined,
  patientRegistration: undefined,
  unclaimedInsurances: undefined,
  claimedInsurances: undefined,
  cancelledInsurances: undefined,
  settlements: undefined,
  insurancesActionsLog: undefined,
  insurancesStatementsLog: undefined,
  insurancesClaimsLog: undefined,
};

const slice = createSlice({
  name: 'queryParams',
  initialState,
  reducers: {
    setQueryParams: (state, action: SetQueryParamsAction) => {
      state[action.payload.name] = action.payload.params;
    },
  },
});

export const { setQueryParams } = slice.actions;

export default slice.reducer;
