import { put, select } from 'redux-saga/effects';
import moment from 'moment/moment';
import { DEFAULT_TIME_FROM, DEFAULT_TIME_TO, FORMATS_API_DATE } from 'app/shared';
import { CalendarItem, RootState } from 'app/types';
import { RefreshCalendarEvent } from 'app/calendar/helpers';
import { CalendarWeekParams, SelectedSlot, SelectedWaitingSlot } from 'app/redux/calendar/types';
import { fetchWeeks } from 'app/redux/calendar/calendar.actions';
import { refreshManageAppointments, refreshManageWaiting } from 'app/sagas/apppointment/generators';

const checkSlot = (slot: SelectedSlot | SelectedWaitingSlot, calendarEvent: RefreshCalendarEvent) =>
  slot.doctor.id === calendarEvent.DoctorId &&
  moment(slot.date).isBetween(calendarEvent.DateFrom, calendarEvent.DateTo, 'day', '[]');

export function* wsUpdateTableView(calendarEvent: RefreshCalendarEvent) {
  const {
    calendar: { selectedSlot, selectedWaitingSlot, tableViewItems },
    bookingWizard: { selectedSlot: selectedSlotWizard },
  }: RootState = yield select();

  // Check if we need to refresh the Manage Appointments modal
  if (selectedSlot && checkSlot(selectedSlot, calendarEvent)) {
    yield refreshManageAppointments(true);
  }

  // Check if we need to refresh the Manage Waiting modal
  if (selectedWaitingSlot && checkSlot(selectedWaitingSlot, calendarEvent)) {
    yield refreshManageWaiting(true);
  }

  // Check if we need to refresh Manage Appointments list embedded into Booking Wizard
  if (selectedSlotWizard && checkSlot(selectedSlotWizard, calendarEvent)) {
    yield refreshManageAppointments(true);
  }

  const rows: CalendarItem[] =
    tableViewItems.data.filter((item: CalendarItem) => {
      //Compare doctor id and branch id
      return item.doctor.id === calendarEvent.DoctorId && item.branch.id === calendarEvent.BranchId;
    }) || [];

  const params: CalendarWeekParams[] = [];

  const { timeFrom, timeTo } = tableViewItems?.params || {};

  rows.forEach((item) => {
    const firstDayDate = item.days[0]?.date;
    const lastDayDate = item.days?.[item.days.length - 1]?.date;
    const isPast = moment(firstDayDate).isBefore(moment());

    // Test if event Dates are within calendar item week
    if (
      moment(calendarEvent.DateFrom).isBetween(firstDayDate, lastDayDate, 'day', '[]') ||
      moment(calendarEvent.DateTo).isBetween(firstDayDate, lastDayDate, 'day', '[]') ||
      (!calendarEvent.ProductId &&
        (moment(firstDayDate).isBetween(calendarEvent.DateFrom, calendarEvent.DateTo, 'day', '[]') ||
          moment(lastDayDate).isBetween(calendarEvent.DateFrom, calendarEvent.DateTo, 'day', '[]')))
    )
      params.push({
        branchId: calendarEvent.BranchId,
        date: isPast ? moment().format(FORMATS_API_DATE) : moment(firstDayDate).format(FORMATS_API_DATE),
        doctorId: calendarEvent.DoctorId,
        timeFrom: timeFrom || DEFAULT_TIME_FROM,
        timeTo: timeTo || DEFAULT_TIME_TO,
        productRelatedFilters: {
          productId: item.product.value,
          specialityId: item.speciality.value,
          subSpecialityId: item.subSpeciality.value,
          customDurationInMinutes: tableViewItems.params?.productRelatedFilters.customDurationInMinutes,
        },
      });
  });

  if (params.length > 0) {
    yield put<any>(fetchWeeks(params));
  }
}
