import { useCallback, ChangeEvent } from 'react';
import { EventWithDataHandler } from 'redux-form/lib/Field';
import { PatientSearchField } from 'app/shared/patientSearch/types';
import {
  MIN_SEARCH_PHONE_NUMBER_LENGTH,
  MIN_SEARCH_CLINICY_MRN_LENGTH,
  PatientSearchFormValues,
  MIN_SEARCH_INTERNAL_FILE_NUMBER_LENGTH,
  MIN_SEARCH_GOVERNMENT_ID_LENGTH,
} from 'app/shared/patientSearch/patientSearchFilters/patientSearchFilters';

interface Props {
  anySelected: boolean;
  searchFormValues: Partial<PatientSearchFormValues>;
  onFiltersChanged?: (values: Partial<PatientSearchFormValues>) => void;
}

export const useChangeHandler = ({
  anySelected,
  searchFormValues,
  onFiltersChanged,
}: Props): EventWithDataHandler<ChangeEvent<HTMLInputElement>> => {
  return useCallback(
    (_, value, __, fieldName) => {
      if (!!fieldName && !anySelected) {
        const values: Partial<PatientSearchFormValues> = searchFormValues;
        if (fieldName === PatientSearchField.searchPhoneNumber) {
          // [i]: Take “+” sign and all the numbers
          values[fieldName] =
            value?.match(/^\+|\d+/g)?.join('').length >= MIN_SEARCH_PHONE_NUMBER_LENGTH ? value : undefined;
        } else if (fieldName === PatientSearchField.searchClinicyMrn) {
          values[fieldName] = value?.length >= MIN_SEARCH_CLINICY_MRN_LENGTH ? value : undefined;
        } else if (fieldName === PatientSearchField.searchInternalFileNumber) {
          values[fieldName] = value?.length >= MIN_SEARCH_INTERNAL_FILE_NUMBER_LENGTH ? value : undefined;
        } else if (fieldName === PatientSearchField.searchGovernmentIssuedId) {
          values[fieldName] = value?.length >= MIN_SEARCH_GOVERNMENT_ID_LENGTH ? value : undefined;
        }
        onFiltersChanged?.(values);
      }
    },
    [anySelected, searchFormValues, onFiltersChanged],
  );
};
