import { put, select } from 'redux-saga/effects';
import { RootState } from 'app/types';
import { fetchDayCalendars } from 'app/redux/remoteData/remoteData.actions';
import { RemoteDataActionConfiguration } from 'app/redux/remoteData/types';

/**
 This function refreshes the Calendar Day View if this location is selected
 */
export function* refreshCalendarDayView(configuration?: RemoteDataActionConfiguration) {
  const {
    remoteData: {
      dayCalendars: { params },
    },
    router: {
      location: { pathname },
    },
  }: RootState = yield select();

  if (
    pathname.includes('calendar/day') &&
    params &&
    params.branchId &&
    params.date &&
    params.doctorIds &&
    params.specialityIds
  ) {
    yield put<any>(
      fetchDayCalendars(
        {
          ...params,
          branchId: params.branchId,
          date: params.date,
          doctorIds: params.doctorIds,
          specialityIds: params.specialityIds,
        },
        configuration || { isSoft: true },
      ),
    );
  }
}
