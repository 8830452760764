import { v4 } from 'uuid';
import { fetchBaseQuery } from '@reduxjs/toolkit/query';
import type { RootState } from 'app/types';
import { getToken } from 'app/redux/auth/helpers';
import { API_URL } from 'app/shared/constants/api.constants';

interface Configuration {
  withAuth: boolean;
}

const baseQuery = ({ withAuth }: Configuration) =>
  fetchBaseQuery({
    baseUrl: `${API_URL}/`,
    prepareHeaders: (headers, { getState }) => {
      const state = getState() as RootState;
      const locale = state.intl.locale;
      const token = getToken();

      // General headers
      headers.set('Accept-Language', locale);
      headers.set('X-Correlation-ID', v4());

      if (withAuth && token) {
        // Authorization headers
        headers.set('Authorization', `Bearer ${token}`);
      }

      return headers;
    },
  });

export const baseQueryWithAuth = baseQuery({ withAuth: true });
export const baseQueryWithoutAuth = baseQuery({ withAuth: false });
