import { ErrorOther } from 'redux-form';
import { PrimaryPatientBaseInfo } from 'app/features/patient/patientForm/types';

export const PreSearchClinicPatientFormField = {
  phoneNumber: 'phoneNumber',
} as const;

export interface PreSearchClinicPatientFormData {
  [PreSearchClinicPatientFormField.phoneNumber]: string;
}

export type PreSearchClinicPatientFormErrorType = string | ErrorOther | undefined;

export type PreSearchDependentResult = {
  phoneNumber: string;
  primaryPatientData: PrimaryPatientBaseInfo;
};
