import { useCallback } from 'react';
import { Button } from 'reactstrap';
import { InfoBar } from 'app/shared';
import { useAppDispatch, useAppIntl, useAppSelector } from 'app/helpers';
import { resetFullEdit, setAppointment, setPatient } from 'app/redux/appointment/appointment.actions';
import { toggleModal } from 'app/redux/modals/modals.actions';
import { appointmentToPatientShortAdapter } from 'app/shared/patientSearch/patientShortDetails/helpers';

export const EditBar = () => {
  const dispatch = useAppDispatch();
  const { formatMessage } = useAppIntl();
  const data = useAppSelector((state) => state.appointment.fullEdit.data);
  const isActive = useAppSelector((state) => state.appointment.fullEdit.isActive);

  const onCancel = useCallback(() => dispatch(resetFullEdit()), [dispatch]);

  const onShow = useCallback(() => {
    if (data) {
      dispatch(setAppointment(data));
      dispatch(setPatient(appointmentToPatientShortAdapter(data)));
      dispatch(toggleModal('editAppointment'));
    }
  }, [data, dispatch]);

  if (!data || !isActive) {
    return null;
  }

  return (
    <InfoBar>
      {formatMessage({ id: 'CALENDAR.TEXT.EDITING-APPOINTMENT' }, { number: data.number })}
      <div className="mx-2">
        <Button color="light" className="btn-responsive mx-1 my-1" size="sm" onClick={onCancel}>
          {formatMessage({ id: 'CORE.BUTTON.CANCEL' })}
        </Button>
        <Button color="light" className="btn-responsive mx-1 my-1" onClick={onShow} size="sm">
          {formatMessage({ id: 'CORE.BUTTON.SHOW' })}
        </Button>
      </div>
    </InfoBar>
  );
};
