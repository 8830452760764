import { useCallback, useMemo } from 'react';
import { useAppDispatch, useAppSelector } from 'app/helpers';
import { toggleModal } from 'app/redux/modals/modals.actions';

export const useCheckCreditNotePackageActions = () => {
  const dispatch = useAppDispatch();
  const hasUsedPackageItems = useAppSelector((state) => !!state.invoicingProcess.data?.hasUsedPackageItems);
  const prevItems = useAppSelector((state) => state.invoicingProcess.data?.steps?.items || []);
  const newItems = useAppSelector((state) => state.invoicingProcess.items);

  const itemsHaveChanged = useMemo(() => {
    if (prevItems.length !== newItems.length) {
      return true; // Lengths are different
    }

    // Check if quantities have changed
    return newItems.some((newItem) => {
      const prevItem = prevItems.find((prevItem) => prevItem.id === newItem.id);
      return prevItem && prevItem.quantity === newItem.quantity;
    });
  }, [prevItems, newItems]);

  return useCallback(
    (trigger: Function) => {
      if (itemsHaveChanged && hasUsedPackageItems) {
        // Show confirmation with possible actions
        dispatch(toggleModal('creditNotePackageActions'));
      } else {
        // Continue without confirmation
        trigger();
      }
    },
    [dispatch, hasUsedPackageItems, itemsHaveChanged],
  );
};
