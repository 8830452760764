interface Params {
  [key: string | number]: unknown;
}

export const generateSearchParams = (params: Params): URLSearchParams => {
  const searchParams = new URLSearchParams();

  Object.keys(params).forEach((key) => {
    let value = params[key];

    if (value === null) {
      // null
      searchParams.append(key, '');
      return;
    }

    if (Array.isArray(value) && value.every((v) => typeof v === 'string' || typeof v === 'number')) {
      // array of strings or numbers
      searchParams.append(key, value.join(','));
      return;
    }

    if (typeof value === 'number' || typeof value === 'boolean') {
      // number or boolean
      searchParams.append(key, value.toString());
      return;
    }

    if (typeof value === 'string') {
      // string
      searchParams.append(key, value);
    }
  });

  return searchParams;
};
