import { useCallback, useMemo } from 'react';
import truncate from 'lodash/truncate';
import { ColumnDescription } from 'react-bootstrap-table-next';
import { IconEye } from '@tabler/icons-react';
import { IconButton } from 'app/components';
import { ICD10Code } from 'app/types';
import { FORMATS_EXTENDED_DATE_AND_TIME } from 'app/shared';
import { formattedDate, useAppDispatch, useAppIntl } from 'app/helpers';
import { toggleModal } from 'app/redux/modals/modals.actions';
import { setInformation } from 'app/redux/doctorAssessment/doctorAssessment.actions';
import { DiagnosesResponse } from 'app/redux/doctorAssessment/types';

interface FormatExtraData {
  toggle?: (diagnose: DiagnosesResponse) => void;
}

export const useDiagnosisColumns = () => {
  const dispatch = useAppDispatch();
  const { formatMessage } = useAppIntl();

  const toggle = useCallback(
    (diagnose: DiagnosesResponse) => {
      dispatch(setInformation(diagnose, 'TREATMENT-PLAN.TEXT.DIAGNOSIS'));
      dispatch(toggleModal('assessmentInformation'));
    },
    [dispatch],
  );

  return useMemo(
    (): ColumnDescription<DiagnosesResponse, FormatExtraData>[] => [
      {
        dataField: 'icdCode',
        text: formatMessage({ id: 'TREATMENT-PLAN.LABEL.ICD10-CODE' }),
        formatter: (_, row) => {
          const data = row.diagnoses?.map((code: ICD10Code) => code.icd10Code).join(', ');
          return truncate(data, { length: 20 });
        },
      },
      {
        dataField: 'date',
        text: formatMessage({ id: 'CORE.LABEL.DATE' }),
        formatter: (_, row) => formattedDate(row.createdAt, FORMATS_EXTENDED_DATE_AND_TIME),
      },
      {
        dataField: 'icd10Codes',
        text: '',
        formatter: (_, row, __, formatExtraData) => (
          <IconButton
            icon={<IconEye size={22} strokeWidth={1.8} />}
            tooltipMessageId="TREATMENT-PLAN.LABEL.DIAGNOSIS"
            onClick={() => formatExtraData?.toggle?.(row)}
          />
        ),
        formatExtraData: { toggle },
        classes: 'text-right',
      },
    ],
    [toggle, formatMessage],
  );
};
