import { RSAA } from 'redux-api-middleware';

import * as types from './requests.types';
import { requestTypeValues } from 'app/shared/constants/requests-types.constants';

export const fetchRequests = (params) => ({
  [RSAA]: {
    endpoint: `/requests`,
    params,
    method: 'GET',
    types: [types.FETCH_REQUESTS_LIST_REQUEST, types.FETCH_REQUESTS_LIST_SUCCESS, types.FETCH_REQUESTS_LIST_FAILURE],
  },
});

export const fetchRequestsHistory = (params) => ({
  [RSAA]: {
    endpoint: `/requests/history`,
    params,
    method: 'GET',
    types: [
      types.FETCH_REQUESTS_HISTORY_LIST_REQUEST,
      types.FETCH_REQUESTS_HISTORY_LIST_SUCCESS,
      types.FETCH_REQUESTS_HISTORY_LIST_FAILURE,
    ],
  },
});

export const fetchRequest = (requestId) => ({
  [RSAA]: {
    endpoint: `requests/${requestId}`,
    method: 'GET',
    types: [types.FETCH_REQUEST_REQUEST, types.FETCH_REQUEST_SUCCESS, types.FETCH_REQUEST_FAILURE],
  },
});

export const approveRequest = (requestId) => ({
  [RSAA]: {
    endpoint: `requests/${requestId}/approve`,
    method: 'PUT',
    types: [
      types.REQUEST_APPROVE_REQUEST,
      {
        type: types.REQUEST_APPROVE_SUCCESS,
        meta: {
          notification: {
            type: 'success',
            title: 'REQUESTS.APPROVE-SUCCESS',
          },
        },
      },
      {
        type: types.REQUEST_APPROVE_FAILURE,
        meta: {
          notification: {
            type: 'danger',
            title: 'REQUESTS.APPROVE-FAIL',
          },
        },
      },
    ],
  },
});

export const cancelRequest = (requestId) => ({
  [RSAA]: {
    endpoint: `/requests/${requestId}/cancel`,
    method: 'PUT',
    types: [
      types.REQUEST_CANCEL_REQUEST,
      {
        type: types.REQUEST_CANCEL_SUCCESS,
        meta: {
          notification: {
            type: 'success',
            title: 'REQUESTS.CANCEL-SUCCESS',
          },
        },
      },
      {
        type: types.REQUEST_CANCEL_FAILURE,
        meta: {
          notification: {
            type: 'danger',
            title: 'REQUESTS.CANCEL-FAIL',
          },
        },
      },
    ],
  },
});

export const rejectRequest = (requestId) => ({
  [RSAA]: {
    endpoint: `/requests/${requestId}/reject`,
    method: 'PUT',
    types: [
      types.REQUEST_REJECT_REQUEST,
      {
        type: types.REQUEST_REJECT_SUCCESS,
        meta: {
          notification: {
            type: 'success',
            title: 'REQUESTS.REJECT-SUCCESS',
          },
        },
      },
      {
        type: types.REQUEST_REJECT_FAILURE,
        meta: {
          notification: {
            type: 'danger',
            title: 'REQUESTS.REJECT-FAIL',
          },
        },
      },
    ],
  },
});

export const editRequest = (requestId, body, type) => ({
  [RSAA]: {
    endpoint: `/requests/${requestId}`,
    method: 'PUT',
    types: [
      types.REQUEST_EDIT_REQUEST,
      {
        type: types.REQUEST_EDIT_SUCCESS,
        meta: {
          notification: {
            type: 'success',
            title: 'REQUESTS.EDIT-SUCCESS',
          },
          type,
        },
      },
      types.REQUEST_EDIT_FAILURE,
    ],
    body:
      type === requestTypeValues.EDIT_CLINIC
        ? {
            editClinic: {
              ...body,
              id: undefined,
            },
          }
        : type === requestTypeValues.EDIT_BRANCH
        ? {
            editBranch: {
              ...body,
              id: undefined,
            },
          }
        : {
            addBranch: {
              ...body,
              id: undefined,
            },
          },
  },
});

export const setMainParams = (params) => ({
  type: types.SET_MAIN_PARAMS,
  meta: params,
});

export const setHistoryParams = (params) => ({
  type: types.SET_HISTORY_PARAMS,
  meta: params,
});
