import { LOCATION_CHANGE } from 'connected-react-router';

export const watchRouteChangeMiddleware = (store) => (next) => (action) => {
  if (action && action.type === LOCATION_CHANGE) {
    const { location } = store.getState().router;
    const nextRoute = action.payload;

    return next({
      ...action,
      payload: {
        ...nextRoute,
        location: {
          ...nextRoute.location,
          prevPathname: location.pathname,
        },
      },
    });
  }

  return next(action);
};
