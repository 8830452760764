import { getModalElement } from 'app/features/invoicingProcess/process/helpers/getModalElement';

export const scrollToInvalidField = () => {
  const modalElement = getModalElement();

  if (modalElement) {
    const invalidElement = modalElement.querySelector('.is-invalid');

    if (!!invalidElement) {
      const position = invalidElement.getBoundingClientRect().top + modalElement.scrollTop;
      modalElement.scrollTo({ top: position - 180, behavior: 'smooth' });
    }
  }
};
