import classnames from 'classnames';
import { Dispatch, SetStateAction, useCallback, useMemo, useState } from 'react';
import { Button, Col, Row } from 'reactstrap';
import { useAppIntl } from 'app/helpers';
import { Step, Template } from 'app/components/locationSelector/procedureLocation/types';
import { Actions } from 'app/components/locationSelector/components/actions/actions';
import styles from './templateSelector.module.scss';

interface Props {
  close: () => void;
  setStep: Dispatch<SetStateAction<Step | null>>;
  setTemplate: Dispatch<SetStateAction<Template | null>>;
  template: Template | null;
}

export const TemplateSelector = ({ close, setStep, setTemplate, template }: Props) => {
  const { formatMessage } = useAppIntl();
  const [selectedTemplate, setSelectedTemplate] = useState<Template | null>(template);

  const templates = useMemo(
    () => [
      {
        id: Template.MaleFullBody,
        name: formatMessage({ id: 'CORE.TEXT.MALE-BODY-TEMPLATE', defaultMessage: 'Male Body' }),
      },
      {
        id: Template.FemaleFullBody,
        name: formatMessage({ id: 'CORE.TEXT.FEMALE-BODY-TEMPLATE', defaultMessage: 'Female Body' }),
      },
      {
        id: Template.AdultTeeth,
        name: formatMessage({ id: 'CORE.TEXT.PERMANENT-TEETH-TEMPLATE', defaultMessage: 'Permanent Teeth' }),
      },
      {
        id: Template.PrimaryTeeth,
        name: formatMessage({ id: 'CORE.TEXT.PRIMARY-TEETH-TEMPLATE', defaultMessage: 'Primary Teeth' }),
      },
    ],
    [formatMessage],
  );

  const onConfirm = useCallback(() => {
    setTemplate(selectedTemplate);
    setStep(Step.SelectLocation);
  }, [selectedTemplate, setStep, setTemplate]);

  return (
    <div className={styles.content}>
      <h2 className={styles.title}>
        {formatMessage({ id: 'CORE.TEXT.SELECT-TEMPLATE', defaultMessage: 'Please select a template' })}
      </h2>
      <Row className="justify-content-center" xs={1} md={2} xl={4}>
        {templates.map((template) => (
          <Col key={template.id} className="mb-3">
            <div
              className={classnames(styles.template, {
                [styles.selectedTemplate]: template.id === selectedTemplate,
              })}
              onClick={() => setSelectedTemplate(template.id)}
            >
              <h5 className="mb-0">{template.name}</h5>
            </div>
          </Col>
        ))}
      </Row>
      {selectedTemplate && (
        <Button onClick={onConfirm} className="btn-responsive mt-3" size="lg" color="primary-gradient">
          <span>{formatMessage({ id: 'CORE.BUTTON.CONTINUE', defaultMessage: 'Continue' })}</span>
        </Button>
      )}
      <Actions close={close} />
    </div>
  );
};
