import { takeEvery, throttle } from 'redux-saga/effects';
import {
  ADD_BRANCH_BILLING_ITEM_SUCCESS,
  EDIT_BRANCH_BILLING_ITEM_SUCCESS,
  REMOVE_BRANCH_BILLING_ITEM_SUCCESS,
  WS_UPDATE_BILLING_ITEMS_TABLE,
} from 'app/redux/branchBillingItem/branchBillingItem.types';
import {
  ADD_MEDICATION_SUCCESS,
  EDIT_MEDICATION_SUCCESS,
  REMOVE_MEDICATION_SUCCESS,
  TOGGLE_MEDICATION_STATUS_SUCCESS,
} from 'app/redux/medications/medications.types';
import {
  afterAddBillingItemSuccess,
  afterAddMedicationSuccess,
  afterEditBillingItemSuccess,
  afterEditMedicationSuccess,
  afterRemoveBillingItemSuccess,
  afterRemoveMedicationSuccess,
  refreshBillingItemsSoft,
  wsUpdateBillingItemsTable,
  afterChangeMedicationSuccess,
} from './generators';
import { WS_REFRESH_BILLING_ITEMS } from 'app/sagas/billingItem/types';

function* billingItemSaga() {
  yield takeEvery(ADD_BRANCH_BILLING_ITEM_SUCCESS, afterAddBillingItemSuccess);
  yield takeEvery(ADD_MEDICATION_SUCCESS, afterAddMedicationSuccess);
  yield takeEvery(EDIT_BRANCH_BILLING_ITEM_SUCCESS, afterEditBillingItemSuccess);
  yield takeEvery(EDIT_MEDICATION_SUCCESS, afterEditMedicationSuccess);
  yield takeEvery(REMOVE_BRANCH_BILLING_ITEM_SUCCESS, afterRemoveBillingItemSuccess);
  yield takeEvery(REMOVE_MEDICATION_SUCCESS, afterRemoveMedicationSuccess);
  yield takeEvery(TOGGLE_MEDICATION_STATUS_SUCCESS, afterChangeMedicationSuccess);
  yield takeEvery(WS_UPDATE_BILLING_ITEMS_TABLE, wsUpdateBillingItemsTable);
  yield throttle(3000, WS_REFRESH_BILLING_ITEMS, refreshBillingItemsSoft);
}

export default billingItemSaga;
