import { SignatureFormData } from 'app/assessment/forms/signatureForm/types';
import { useAppSelector } from 'app/helpers';

export const useSignatureInitialValues = (): SignatureFormData | undefined => {
  // Selectors
  const doctorSign = useAppSelector((state) => state.doctorAssessment.data?.doctorSign);

  if (doctorSign && doctorSign.doctorSignFileId) {
    return {
      signatureFile: {
        id: doctorSign.doctorSignFileId,
        name: doctorSign.doctorSignFileName,
        url: doctorSign.doctorSignPath,
      },
    };
  }
  return undefined;
};
