import { AnyAction } from 'redux';
import * as types from 'app/redux/bookingWizard/bookWizard.types';
import { CalendarDay, Doctor, Option, RemoteData } from 'app/types';
import { WizardStep, WorkingTime } from 'app/redux/bookingWizard/types';
import { SelectedSlot } from 'app/redux/calendar/types';
import { PatientInfoShort } from 'app/shared/patientSearch';
import { ProductOption } from 'app/redux/appointment/types';

export interface BookWizardReducer {
  activeStep: WizardStep;
  branchId: string;
  appointmentId: string;
  isWaiting: boolean;
  day: RemoteData<CalendarDay | null>;
  doctor: RemoteData<Doctor | null>;
  isSlotFree: RemoteData<boolean>;
  selectedPatient: PatientInfoShort | null;
  selectedService: Option | null;
  selectedSubSpeciality: Option | null;
  selectedTime: { from: Date; to?: Date };
  selectedWorkingTime: WorkingTime | null;
  selectedSlot: SelectedSlot | null;
  services: RemoteData<ProductOption[]>;
  subSpecialities: RemoteData<Option[]>;
  workingTimes: RemoteData<WorkingTime[]>;
}

export const initialState: BookWizardReducer = {
  activeStep: WizardStep.SubSpeciality,
  branchId: '',
  appointmentId: '',
  isWaiting: false,

  doctor: {
    data: null,
    loading: false,
  },

  subSpecialities: {
    data: [],
    loading: false,
  },
  services: {
    data: [],
    loading: false,
  },

  workingTimes: {
    data: [],
    loading: false,
  },

  isSlotFree: {
    data: true,
    loading: false,
  },

  selectedService: null,
  selectedSubSpeciality: null,
  selectedTime: { from: new Date() },
  selectedWorkingTime: null,
  selectedSlot: null,

  // Day slots
  day: {
    data: null,
    loading: false,
  },

  // Patient related state
  selectedPatient: null,
};

export const bookingWizardReducer = (state = initialState, action: AnyAction): BookWizardReducer => {
  switch (action.type) {
    // Initial set
    case types.SET_INITIAL_DATA:
      return {
        ...state,
        activeStep: WizardStep.SubSpeciality,
        branchId: action.meta.branchId,
        selectedTime: action.meta.timePeriod,
        appointmentId: action.meta.appointmentId,
        isWaiting: !!action.meta.isWaiting,
      };

    // Doctor data
    case types.FETCH_DOCTOR_REQUEST:
      return {
        ...state,
        doctor: {
          ...initialState.doctor,
          loading: true,
        },
      };
    case types.FETCH_DOCTOR_SUCCESS:
      return {
        ...state,
        doctor: {
          data: action.payload.data,
          loading: false,
        },
      };
    case types.FETCH_DOCTOR_FAILURE:
      return {
        ...state,
        doctor: {
          data: initialState.doctor.data,
          loading: false,
          error: true,
        },
      };
    // Working Times
    case types.FETCH_WORKING_TIMES_REQUEST:
      return {
        ...state,
        workingTimes: {
          data: state.workingTimes.data,
          loading: true,
        },
      };
    case types.FETCH_WORKING_TIMES_SUCCESS:
      return {
        ...state,
        workingTimes: {
          data: action.payload.data,
          loading: false,
        },
      };
    case types.FETCH_WORKING_TIMES_FAILURE:
      return {
        ...state,
        workingTimes: {
          ...state.workingTimes,
          error: true,
        },
      };
    case types.FETCH_SUB_SPECIALITIES_REQUEST:
      return {
        ...state,
        subSpecialities: {
          data: state.subSpecialities.data,
          loading: true,
        },
      };
    case types.FETCH_SUB_SPECIALITIES_SUCCESS:
      return {
        ...state,
        subSpecialities: {
          data: action.payload.data,
          loading: false,
        },
      };
    case types.FETCH_SUB_SPECIALITIES_FAILURE:
      return {
        ...state,
        subSpecialities: {
          ...state.subSpecialities,
          error: true,
        },
      };

    case types.FETCH_PRODUCTS_REQUEST:
      return {
        ...state,
        services: {
          data: state.services.data,
          loading: true,
        },
      };
    case types.FETCH_PRODUCTS_SUCCESS:
      return {
        ...state,
        services: {
          data: action.payload.data,
          loading: false,
        },
      };
    case types.FETCH_PRODUCTS_FAILURE:
      return {
        ...state,
        services: {
          ...state.services,
          error: true,
        },
      };

    // Day slots
    case types.FETCH_DAY_REQUEST:
      return {
        ...state,
        day: {
          ...initialState.day,
          loading: true,
        },
      };
    case types.FETCH_DAY_SUCCESS:
      return {
        ...state,
        day: {
          data: action.payload,
          loading: false,
        },
      };
    case types.FETCH_DAY_FAILURE:
      return {
        ...state,
        day: initialState.day,
      };

    case types.FETCH_IS_SLOT_FREE_REQUEST:
      return {
        ...state,
        isSlotFree: {
          ...state.isSlotFree,
          loading: true,
        },
      };
    case types.FETCH_IS_SLOT_FREE_SUCCESS:
      return {
        ...state,
        isSlotFree: {
          data: action.payload,
          loading: false,
        },
      };
    case types.FETCH_IS_SLOT_FREE_FAILURE:
      return {
        ...state,
        isSlotFree: {
          data: false,
          loading: false,
          error: true,
        },
      };

    case types.SET_PATIENT:
      return {
        ...state,
        selectedPatient: action.payload,
      };
    case types.RESET_PATIENT:
      return {
        ...state,
        selectedPatient: initialState.selectedPatient,
      };
    // Select working time
    case types.SELECT_WORKING_TIME:
      return {
        ...state,
        selectedWorkingTime: action.payload.value,
        ...(!action.payload.isSilent
          ? {
              selectedSubSpeciality: null,
              selectedService: null,
            }
          : {}),
      };
    // Select subSpeciality
    case types.SELECT_SUB_SPECIALITY:
      return {
        ...state,
        selectedSubSpeciality: action.payload,
        selectedService: null,
      };
    // Select service
    case types.SELECT_SERVICE:
      return {
        ...state,
        selectedService: action.payload,
      };

    // Select time
    case types.SELECT_TIME:
      return {
        ...state,
        selectedTime: action.payload,
      };

    // Set Active Step
    case types.SET_ACTIVE_STEP:
      return {
        ...state,
        activeStep: action.payload,
      };

    // Select time slot
    case types.SELECT_SLOT:
      return {
        ...state,
        selectedSlot: action.payload,
      };

    case types.RESET:
      return initialState;
    // Default
    default:
      return state;
  }
};
