import moment from 'moment';
import { useMemo } from 'react';
import uniqBy from 'lodash/uniqBy';
import { CalendarType, DayCalendar, Locale } from 'app/types';
import { getPatientNameFromOption, getTranslation } from 'app/helpers';
import { CalendarDayEvent } from 'app/calendar/types';

export const useEvents = (data: DayCalendar[], calendarType: CalendarType, locale: Locale) => {
  return useMemo((): CalendarDayEvent[] => {
    const events: CalendarDayEvent[] = [];

    data.forEach((dayCalendar) => {
      const appointments =
        calendarType === CalendarType.Normal ? dayCalendar.appointments : dayCalendar.waitingAppointments;
      return appointments.forEach((appointment) => {
        const isAllDay = !appointment.end;
        const start = moment(appointment.start).toDate();
        const end = isAllDay ? moment(start).add(5, 'minutes').toDate() : moment(appointment.end).toDate();
        const isWaiting = 'workingTimeId' in appointment ? !!appointment.workingTimeId : false;
        const isCheckedIn = 'checkedIn' in appointment ? appointment.checkedIn : false;
        const hasInvoice = 'invoiceId' in appointment ? !!appointment.invoiceId : false;
        const isTodayOrFutureEvent = moment()
          .startOf('day')
          .isSameOrBefore(appointment.end || appointment.start);

        if (isTodayOrFutureEvent) {
          events.push({
            allDay: isAllDay,
            checkedIn: 'checkedIn' in appointment ? appointment.checkedIn : undefined,
            end,
            id: appointment.id,
            isConflicting: appointment.isConflicting,
            isDraggable: !isCheckedIn && !hasInvoice,
            isWaiting,
            resourceId: dayCalendar.doctor.id,
            start,
            status: 'status' in appointment ? appointment.status : undefined,
            title: `${getPatientNameFromOption(appointment.patient, locale)} - ${getTranslation(
              appointment.product,
              locale,
            )}`,
          });
        }
      });
    });

    return uniqBy(events, 'id');
  }, [calendarType, data, locale]);
};
