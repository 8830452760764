import { useCallback } from 'react';
import { destroy } from 'redux-form';
import { FORMS_CREDIT_NOTE } from 'app/shared';
import { useAppDispatch, useAppSelector } from 'app/helpers';
import { InvoicingProcessMode } from 'app/redux/invoicingProcess/types';
import { scrollToTop } from 'app/features/invoicingProcess/process/helpers';
import { fetchInvoice, setMode } from 'app/redux/invoicingProcess/invoicingProcess.actions';

export const useOnCancelCreditNote = () => {
  const dispatch = useAppDispatch();
  const invoicingProcess = useAppSelector((state) => state.invoicingProcess.data);

  return useCallback(async () => {
    dispatch(destroy(FORMS_CREDIT_NOTE));
    dispatch(setMode(InvoicingProcessMode.Invoice));

    if (invoicingProcess?.invoiceId) {
      dispatch(fetchInvoice(invoicingProcess.invoiceId));
    }

    scrollToTop();
  }, [dispatch, invoicingProcess]);
};
