import { useEffect, useState } from 'react';
import { HubConnection, HubConnectionBuilder } from '@microsoft/signalr';
import { IHttpConnectionOptions } from '@microsoft/signalr/src/IHttpConnectionOptions';
import { useAppSelector } from 'app/helpers/useTypedHooks/useTypedHooks';

const signalrUrl = process.env.REACT_APP_SIGNALR_URL;

export const useHubConnection = (hubName: string) => {
  const [hubConnection, setHubConnection] = useState<HubConnection | null>(null);
  const token = useAppSelector((state) => state.auth.token);

  useEffect(() => {
    if (signalrUrl && token) {
      const url = `${signalrUrl}/${hubName}`;
      const options: IHttpConnectionOptions = {
        accessTokenFactory: () => token,
      };
      const connection = new HubConnectionBuilder().withUrl(url, options).withAutomaticReconnect().build();

      setHubConnection(connection);
    }
  }, [hubName, token]);

  return hubConnection;
};
