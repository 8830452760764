import { AnyAction } from 'redux';
import * as types from './settings.types';
import { SettingsReducer } from './types';

const initialState: SettingsReducer = {
  loading: false,
  data: null,
  error: false,
};
export const settingsReducer = (state = initialState, action: AnyAction) => {
  switch (action.type) {
    case types.FETCH_SETTINGS_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case types.FETCH_SETTINGS_SUCCESS:
      return {
        ...state,
        data: action.payload,
        loading: false,
      };
    case types.FETCH_SETTINGS_FAILURE:
      return {
        ...state,
        loading: false,
        error: true,
      };
    case types.EDIT_SETTINGS_REQUEST:
      return {
        ...state,
        whileFetching: true,
      };
    case types.EDIT_SETTINGS_SUCCESS:
      return {
        ...state,
        whileFetching: false,
      };
    case types.EDIT_SETTINGS_FAILURE:
      return {
        ...state,
        whileFetching: false,
      };
    default:
      return state;
  }
};
