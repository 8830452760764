import round from 'lodash/round';
import sumBy from 'lodash/sumBy';
import { InvoiceCreditNotePayment, InvoicePaymentMethodItem, InvoiceStatus } from 'app/types';
import { ExtendedItem } from 'app/features/invoicingProcess/process/items/types';

interface Params {
  creditNotePayments: InvoiceCreditNotePayment[];
  extendedItems: ExtendedItem[];
  invoiceStatus: InvoiceStatus | undefined;
  paymentMethodItems: InvoicePaymentMethodItem[];
}

export const getLeftToPayValue = ({
  creditNotePayments,
  extendedItems,
  paymentMethodItems,
  invoiceStatus,
}: Params): number => {
  if (invoiceStatus === InvoiceStatus.ClearedByCreditNote) {
    // Return 0 SAR if the invoice has been cleared by the credit note
    return 0;
  }

  const itemsTotal = round(
    sumBy<ExtendedItem>(extendedItems, (i) => i.patientShareTotal),
    2,
  );
  const paymentsTotal = round(
    sumBy<InvoicePaymentMethodItem>(paymentMethodItems, (m) => m.amount),
    2,
  );
  const creditNotePaymentsTotal = round(
    sumBy<InvoiceCreditNotePayment>(creditNotePayments, (m) => m.amount),
    2,
  );

  return round(itemsTotal - paymentsTotal + creditNotePaymentsTotal, 2);
};
