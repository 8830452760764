import moment from 'moment';
import { Patient } from 'app/types';
import { PatientInfoShort } from 'app/shared/patientSearch/patientShortDetails/types';

export const detailedPatientToPatientShortAdapter = (patient: Patient): PatientInfoShort => ({
  id: patient.id,
  age: patient.dateOfBirth ? moment().diff(moment(patient.dateOfBirth), 'years') : 0,
  clinicyMrn: patient.clinicyMrn,
  fullNameArabic: patient.fullNameArabic,
  fullNameEnglish: patient.fullNameEnglish,
  dateOfBirth: patient.dateOfBirth,
  gender: patient.gender,
  governmentIssuedId: patient.governmentIssuedId,
  governmentIssuedIdType: patient.governmentIssuedIdType,
  patientType: patient.patientType,
  status: patient.status,
});
