import { AnyAction } from 'redux';
import * as types from './medicalLibraries.types';
import { MedicalLibrariesReducer } from './types';

const initialState: MedicalLibrariesReducer = {
  data: null,
  loading: false,
  familyIllnesses: {
    errors: [],
    warnings: [],
  },
  patientIllnesses: {
    errors: [],
    warnings: [],
  },
  surgeries: {
    errors: [],
    warnings: [],
  },
  diagnoses: {
    errors: [],
    warnings: [],
  },
  drugs: {
    errors: [],
    warnings: [],
  },
  allergies: {
    errors: [],
    warnings: [],
  },
};

export const medicalLibrariesReducer = (state = initialState, action: AnyAction) => {
  switch (action.type) {
    //familyIllnesses
    case types.FETCH_FAMILY_ILLNESSES_REQUEST:
      return {
        ...state,
        loading: true,
        familyIllnesses: {
          errors: [],
          warnings: [],
        },
      };
    case types.FETCH_FAMILY_ILLNESSES_SUCCESS:
      return {
        ...state,
        loading: false,
        familyIllnesses: {
          errors: [],
          warnings: action.payload,
        },
      };
    case types.FETCH_FAMILY_ILLNESSES_FAILURE:
      return {
        ...state,
        loading: false,
        familyIllnesses: {
          errors: action.payload.response,
          warnings: [],
        },
      };
    //patientIllnesses
    case types.FETCH_PATIENT_ILLNESSES_REQUEST:
      return {
        ...state,
        loading: true,
        patientIllnesses: {
          errors: [],
          warnings: [],
        },
      };
    case types.FETCH_PATIENT_ILLNESSES_SUCCESS:
      return {
        ...state,
        loading: false,
        patientIllnesses: {
          errors: [],
          warnings: action.payload,
        },
      };
    case types.FETCH_PATIENT_ILLNESSES_FAILURE:
      return {
        ...state,
        loading: false,
        patientIllnesses: {
          errors: action.payload.response,
          warnings: [],
        },
      };
    //surgeries
    case types.FETCH_SURGERIES_REQUEST:
      return {
        ...state,
        loading: true,
        surgeries: {
          errors: [],
          warnings: [],
        },
      };
    case types.FETCH_SURGERIES_SUCCESS:
      return {
        ...state,
        loading: false,
        surgeries: {
          errors: [],
          warnings: action.payload,
        },
      };
    case types.FETCH_SURGERIES_FAILURE:
      return {
        ...state,
        loading: false,
        surgeries: {
          errors: action.payload.response,
          warnings: [],
        },
      };
    //diagnoses
    case types.FETCH_DIAGNOSES_REQUEST:
      return {
        ...state,
        loading: true,
        diagnoses: {
          errors: [],
          warnings: [],
        },
      };
    case types.FETCH_DIAGNOSES_SUCCESS:
      return {
        ...state,
        loading: false,
        diagnoses: {
          errors: [],
          warnings: action.payload,
        },
      };
    case types.FETCH_DIAGNOSES_FAILURE:
      return {
        ...state,
        loading: false,
        diagnoses: {
          errors: action.payload.response,
          warnings: [],
        },
      };
    // drugs
    case types.FETCH_DRUGS_REQUEST:
      return {
        ...state,
        loading: true,
        drugs: {
          errors: [],
          warnings: [],
        },
      };
    case types.FETCH_DRUGS_SUCCESS:
      return {
        ...state,
        loading: false,
        drugs: {
          errors: [],
          warnings: action.payload,
        },
      };
    case types.FETCH_DRUGS_FAILURE:
      return {
        ...state,
        loading: false,
        drugs: {
          errors: action.payload.response,
          warnings: [],
        },
      };
    // allergies
    case types.FETCH_ALLERGIES_REQUEST:
      return {
        ...state,
        loading: true,
        allergies: {
          errors: [],
          warnings: [],
        },
      };
    case types.FETCH_ALLERGIES_SUCCESS:
      return {
        ...state,
        loading: false,
        allergies: {
          errors: [],
          warnings: action.payload,
        },
      };
    case types.FETCH_ALLERGIES_FAILURE:
      return {
        ...state,
        loading: false,
        allergies: {
          errors: action.payload.response,
          warnings: [],
        },
      };
    default:
      return state;
  }
};
