import { put, select } from 'redux-saga/effects';
import { fetchBranchBillingPackages } from 'app/redux/remoteData/remoteData.actions';
import { RootState } from 'app/types';
import { PayloadAction } from '@reduxjs/toolkit';
import { Meta } from 'app/sagas/billingItemLists/types';

export function* refreshBranchPackages({ meta }: PayloadAction<void, string, Meta>) {
  const {
    remoteData: { branchBillingPackages },
  }: RootState = yield select();
  if (branchBillingPackages.params) {
    yield put<any>(fetchBranchBillingPackages(meta.branchId, branchBillingPackages.params));
  }
}
