import toast from 'react-hot-toast';
import { useCallback, useMemo } from 'react';
import { FormSubmitHandler } from 'redux-form/lib/reduxForm';
import { generateTemporaryId } from 'app/helpers';
import { FORMS_INVOICING_PROCESS_ADD_ITEM } from 'app/shared';
import { ErrorType, InvoiceDiscountType, InvoiceItemVat } from 'app/types';
import { addInvoiceItem } from 'app/redux/invoicingProcess/invoicingProcess.actions';
import { ItemFormData } from 'app/features/invoicingProcess/process/items/itemForm/types';
import { ItemForm, ItemFormProps } from 'app/features/invoicingProcess/process/items/itemForm/itemForm';

export const AddItemForm = () => {
  const onSubmit = useCallback<FormSubmitHandler<ItemFormData, ItemFormProps, ErrorType>>((values, dispatch, props) => {
    dispatch(
      addInvoiceItem({
        billingItem: values.item,
        billingItemSourceType: values.itemSourceType,
        discount: {
          discountTypeView: { label: '', value: values.discountType }, // label is not required
          value: Number(values.discountValue || 0),
        },
        id: generateTemporaryId(),
        price: values.item.details.price,
        quantity: Number(values.quantity),
        vat: InvoiceItemVat.Default,
      }),
    );
    props.reset && props.reset();
    toast.success('CORE.TEXT.ITEM-ADDED-SUCCESSFULLY');
  }, []);

  const initialValues = useMemo<Partial<ItemFormData> | undefined>(
    () => ({
      discountType: InvoiceDiscountType.Percentage,
      quantity: String(1),
    }),
    [],
  );

  return <ItemForm form={FORMS_INVOICING_PROCESS_ADD_ITEM} initialValues={initialValues} onSubmit={onSubmit} />;
};
