import React, { Fragment, useCallback, useState } from 'react';
import { Accept, FileRejection, useDropzone } from 'react-dropzone';
import truncate from 'lodash/truncate';
import { WrappedFieldProps } from 'redux-form/lib/Field';
import { Button, FormFeedback, FormGroup, FormText, Label, Spinner } from 'reactstrap';
import { generateTemporaryId, useAppIntl } from 'app/helpers';
import { ImageCropper } from './imageCropper/imageCropper';
import { MEGABYTE_IN_BYTES } from './fileUploader.constants';
import { getErrorMessage } from './helpers/getErrorMessage';
import { RemoteFile } from 'app/types';
import './fileUploader.scss';

interface FileUploaderProps {
  accept?: Accept;
  disabled?: boolean;
  fileInfo?: { formats: string; maxSize?: number };
  id?: string;
  isDelayedUpload?: boolean;
  isRequired?: boolean;
  label: string;
  onUpload?: (file: Blob) => Awaited<Promise<any>>;
  showImageCropper?: boolean;
  showImagePreview?: boolean;
  text?: string;
  isFieldArray?: boolean;
}

const initialAccept: Accept = {
  'image/*': ['.png', '.jpg', '.pdf', '.zip', '.docx'],
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document': ['.docx'],
};

const initialFileInfo = {
  formats: initialAccept['image/*'].join(' '),
  maxSize: 10,
};

// TODO: This component contains invalid types and unwanted structure (we shouldn't convert everything to Blob). We have to rewrite it.
export const FileUploader = ({ input, meta, ...props }: WrappedFieldProps & FileUploaderProps) => {
  const { formatMessage } = useAppIntl();

  // Local State
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [croppingImage, setCroppingImage] = useState<boolean>(false);
  const [file, setFile] = useState<Blob | null>(null);
  const [customError, setCustomError] = useState<string>('');

  // Initial Values
  const fileInfo: { formats: string; maxSize: number } = {
    formats: props.fileInfo?.formats || initialFileInfo.formats,
    maxSize: props.fileInfo?.maxSize || initialFileInfo.maxSize,
  };
  const accept = props.accept || initialAccept;
  const dropzoneClasses = `file-uploader__dropzone form-control ${
    (meta.error && meta.touched) || customError ? 'is-invalid' : ''
  }`;

  // Conditions
  const showPreview = props.showImagePreview && input.value.url && !isLoading;
  const showImageInfo = input.value.name && !isLoading;
  const showValidFileInfo = !!fileInfo && !(input.value.name && !isLoading);
  const showImageCropper = props.showImageCropper && croppingImage && !!file;
  const showRemoveButton = input.value.name && !isLoading && !props.disabled;
  const showError = !!meta.error || customError;
  const showText = !!props.text;

  // Functions
  const onDropRejected = useCallback((files: FileRejection[]) => {
    if (files) {
      // Info: Even if there are more than one error, we take first one.
      const errorMessage = getErrorMessage(files[0].errors[0].code);
      if (!!errorMessage) {
        setCustomError(errorMessage);
      }
    }
  }, []);

  const uploadFile = useCallback(
    async (file: Blob) => {
      if (file) {
        setCustomError('');
        setIsLoading(true);

        if (props.onUpload) {
          try {
            const response = await props.onUpload(file);
            if (!response.error && !!response.payload.length) {
              const payload = response.payload;
              input.onChange(payload[0]);
            } else {
              input.onChange('');
            }
          } catch (error) {
            input.onChange('');
          }
          setIsLoading(false);
        }

        if (props.isDelayedUpload) {
          const remoteFile: RemoteFile = {
            id: generateTemporaryId(),
            name: 'name' in file && typeof file.name === 'string' ? file.name : '',
            url: URL.createObjectURL(file),
          };

          input.onChange(remoteFile);
          setIsLoading(false);
        }
      }
    },
    [input, props],
  );

  const onDrop = useCallback(
    (files: Blob[]) => {
      if (props.showImageCropper && files.length) {
        setCroppingImage(true);
        setFile(files[0]);
      } else {
        uploadFile(files[0]);
      }
    },
    [props.showImageCropper, uploadFile],
  );

  const onFileCropped = useCallback(
    (file: Blob) => {
      if (file) {
        setFile(null);
        setCroppingImage(false);
        uploadFile(file);
      }
    },
    [uploadFile],
  );

  const onCloseCropperModal = useCallback(() => {
    setFile(null);
    setCroppingImage(false);
  }, []);

  const onRemove = useCallback(() => {
    const uploaderFileInitial = {} as RemoteFile;

    setCustomError('');
    input.onChange(props.isFieldArray ? uploaderFileInitial : '');
  }, [input, props.isFieldArray]);

  const { getRootProps, getInputProps, open } = useDropzone({
    maxSize: fileInfo.maxSize * MEGABYTE_IN_BYTES,
    accept,
    noClick: true,
    noKeyboard: true,
    disabled: props.disabled,
    maxFiles: 1,
    multiple: false,
    onDrop,
    onDropRejected,
    minSize: 0,
  });

  return (
    <FormGroup className="file-uploader">
      <Label for={props.id || input.name}>
        {props.label}
        {props.isRequired && <span className="text-danger">&nbsp;*</span>}
      </Label>
      <div
        {...getRootProps({
          className: dropzoneClasses,
        })}
      >
        {!props.disabled && (
          <p className="file-uploader__information">{formatMessage({ id: 'CORE.TEXT.DRAG-AND-DROP-FILE' })}</p>
        )}
        {isLoading && (
          <div className="file-uploader__loader">
            <Spinner />
          </div>
        )}
        {showPreview && (
          <div style={{ backgroundImage: `url(${input.value.url})` }} className="file-uploader__preview" />
        )}
        {showImageInfo && (
          <div className="file-uploader__file">
            <span className="file-uploader__file-label">{formatMessage({ id: 'CORE.TEXT.FILE-NAME' })}:</span>
            <a className="file-uploader__file-link" href={input.value.url} rel="noreferrer noopener" target="_blank">
              {truncate(input.value.name, { length: 24 })}
            </a>
          </div>
        )}
        {!props.disabled && (
          <Button
            type="button"
            color="primary-gradient"
            disabled={props.disabled || isLoading}
            onClick={open}
            size="sm"
          >
            {formatMessage({ id: 'CORE.BUTTON.SELECT-FILE' })}
          </Button>
        )}
        {showRemoveButton && (
          <Fragment>
            <Button className="ms-1" color="secondary" disabled={props.disabled} size="sm" onClick={onRemove}>
              {formatMessage({ id: 'CORE.BUTTON.REMOVE' })}
            </Button>
          </Fragment>
        )}
        {showValidFileInfo && (
          <p className="file-uploader__valid-file-info">
            {formatMessage({ id: 'CORE.TEXT.VALID-FILE-INFO' }, { ...fileInfo })}
          </p>
        )}
        <input {...getInputProps()} />
      </div>
      {showError && <FormFeedback>{formatMessage({ id: customError || meta.error })}</FormFeedback>}
      {showText && <FormText>{props.text}</FormText>}
      {showImageCropper && (
        <ImageCropper file={file} closeModal={onCloseCropperModal} onFileCropped={(file) => onFileCropped(file)} />
      )}
    </FormGroup>
  );
};
