import { put } from 'redux-saga/effects';
import { PayloadAction } from '@reduxjs/toolkit';
import { WsRefreshPayload } from '../types';
import { fetchBranchBillingItemsSilent } from 'app/redux/remoteData/remoteData.actions';

export function* refreshBillingItemsSoft({ payload }: PayloadAction<WsRefreshPayload>) {
  const { branchId, params } = payload;

  if (branchId && params) {
    yield put<any>(fetchBranchBillingItemsSilent(branchId, params));
  }
}
