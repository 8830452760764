import { useMemo } from 'react';
import { ColumnDescription } from 'react-bootstrap-table-next';
import { formattedDate, useAppIntl } from 'app/helpers';
import { PreviousSurgery } from 'app/types';

const bodyStyle = { fontSize: '9px', color: 'black' };
const headerStyle = { fontSize: '10px', color: 'black' };

export const useSurgicalHistory = () => {
  const { formatMessage } = useAppIntl();

  return useMemo<ColumnDescription<PreviousSurgery>[]>(
    () => [
      {
        dataField: 'number',
        text: '#',
        formatter: (_, row, rowIndex) => rowIndex + 1,
        style: bodyStyle,
        headerStyle,
      },
      {
        dataField: 'surgery',
        text: formatMessage({ id: 'TREATMENT-PLAN.LABEL.SURGICAL-HISTORY' }),
        formatter: (_, row) => row.surgery.label,
        style: bodyStyle,
        headerStyle,
      },
      {
        dataField: 'dateOfSubmission',
        text: formatMessage({ id: 'CORE.LABEL.DATE-OF-SUBMISSION' }),
        formatter: (_, row) => formattedDate(row.addedAt),
        style: bodyStyle,
        headerStyle,
      },
    ],
    [formatMessage],
  );
};
