export const ADD_USER_ROLE_REQUEST = 'user-roles/add/REQUEST';
export const ADD_USER_ROLE_SUCCESS = 'user-roles/add/SUCCESS';
export const ADD_USER_ROLE_FAILURE = 'user-roles/add/FAILURE';

export const EDIT_USER_ROLE_REQUEST = 'user-roles/edit/REQUEST';
export const EDIT_USER_ROLE_SUCCESS = 'user-roles/edit/SUCCESS';
export const EDIT_USER_ROLE_FAILURE = 'user-roles/edit/FAILURE';

export const DELETE_USER_ROLE_REQUEST = 'user-roles/delete/REQUEST';
export const DELETE_USER_ROLE_SUCCESS = 'user-roles/delete/SUCCESS';
export const DELETE_USER_ROLE_FAILURE = 'user-roles/delete/FAILURE';

export const ADD_ADMIN_ROLE_REQUEST = 'user-roles/ADD_ADMIN_ROLE_REQUEST';
export const ADD_ADMIN_ROLE_SUCCESS = 'user-roles/ADD_ADMIN_ROLE_SUCCESS';
export const ADD_ADMIN_ROLE_FAILURE = 'user-roles/ADD_ADMIN_ROLE_FAILURE';

export const EDIT_ADMIN_ROLE_REQUEST = 'user-roles/EDIT_ADMIN_ROLE_REQUEST';
export const EDIT_ADMIN_ROLE_SUCCESS = 'user-roles/EDIT_ADMIN_ROLE_SUCCESS';
export const EDIT_ADMIN_ROLE_FAILURE = 'user-roles/EDIT_ADMIN_ROLE_FAILURE';

export const DELETE_ADMIN_ROLE_REQUEST = 'user-roles/DELETE_ADMIN_ROLE_REQUEST';
export const DELETE_ADMIN_ROLE_SUCCESS = 'user-roles/DELETE_ADMIN_ROLE_SUCCESS';
export const DELETE_ADMIN_ROLE_FAILURE = 'user-roles/DELETE_ADMIN_ROLE_FAILURE';

export const FETCH_USER_REQUEST = 'users/fetch-user-request';
export const FETCH_USER_SUCCESS = 'users/fetch-user-success';
export const FETCH_USER_FAILURE = 'users/fetch-user-failure';

export const CHECK_USER_EMAIL_REQUEST = 'auth/CHECK_USER_EMAIL_REQUEST';
export const CHECK_USER_EMAIL_SUCCESS = 'auth/CHECK_USER_EMAIL_SUCCESS';
export const CHECK_USER_EMAIL_FAILURE = 'auth/CHECK_USER_EMAIL_FAILURE';

export const FETCH_USER_BRANCHES_REQUEST = 'user-roles/FETCH_USER_BRANCHES_REQUEST';
export const FETCH_USER_BRANCHES_SUCCESS = 'user-roles/FETCH_USER_BRANCHES_SUCCESS';
export const FETCH_USER_BRANCHES_FAILURE = 'user-roles/FETCH_USER_BRANCHES_FAILURE';

export const FETCH_USER_SPECIALITIES_REQUEST = 'user-roles/FETCH_USER_SPECIALITIES_REQUEST';
export const FETCH_USER_SPECIALITIES_SUCCESS = 'user-roles/FETCH_USER_SPECIALITIES_SUCCESS';
export const FETCH_USER_SPECIALITIES_FAILURE = 'user-roles/FETCH_USER_SPECIALITIES_FAILURE';

export const FETCH_CLINIC_BRANCHES_REQUEST = 'user-roles/FETCH_CLINIC_BRANCHES_REQUEST';
export const FETCH_CLINIC_BRANCHES_SUCCESS = 'user-roles/FETCH_CLINIC_BRANCHES_SUCCESS';
export const FETCH_CLINIC_BRANCHES_FAILURE = 'user-roles/FETCH_CLINIC_BRANCHES_FAILURE';

export const FETCH_DOCTORS_REQUEST = 'user-roles/FETCH_DOCTORS_REQUEST';
export const FETCH_DOCTORS_SUCCESS = 'user-roles/FETCH_DOCTORS_SUCCESS';
export const FETCH_DOCTORS_FAILURE = 'user-roles/FETCH_DOCTORS_FAILURE';

export const FETCH_TEMPORARY_PASSWORD_REQUEST = 'user-roles/FETCH_TEMPORARY_PASSWORD_REQUEST';
export const FETCH_TEMPORARY_PASSWORD_SUCCESS = 'user-roles/FETCH_TEMPORARY_PASSWORD_SUCCESS';
export const FETCH_TEMPORARY_PASSWORD_FAILURE = 'user-roles/FETCH_TEMPORARY_PASSWORD_FAILURE';

export const SET_TEMPORARY_PASSWORD_USER_ID = 'user-roles/SET_TEMPORARY_PASSWORD_USER_ID';
