import classNames from 'classnames';
import { Button, Spinner } from 'reactstrap';
import React, { useCallback, useState } from 'react';
import { download } from 'app/redux/downloads/downloads.actions';
import { useAppDispatch, useAppIntl, useCurrentUser } from 'app/helpers';
import { useInvoicingProcessActions } from 'app/features/invoicingProcess/helpers/useInvoicingProcessActions';

interface Props {
  id: string;
  isBillingItemsBased: boolean;
  number: string;
  version?: 'v1' | 'v2';
}

export const InvoiceLink = ({ id, isBillingItemsBased, number, version = 'v1' }: Props) => {
  const { locale } = useAppIntl();
  const dispatch = useAppDispatch();
  const { token } = useCurrentUser();
  const [loading, setLoading] = useState<boolean>(false);
  const { viewInvoice } = useInvoicingProcessActions();

  const downloadInvoice = useCallback(async () => {
    setLoading(true);
    await dispatch(
      download({
        token,
        locale,
        endpoint: version === 'v1' ? `invoices/${id}/print-view` : `invoices/${id}/v2/print-view`,
        withOpening: false,
        body: false,
      }),
    );
    setLoading(false);
  }, [dispatch, id, locale, token, version]);

  if (isBillingItemsBased) {
    return (
      <Button className="p-0" color="link" size="sm" onClick={() => viewInvoice(id)}>
        {number}
      </Button>
    );
  }

  return (
    <Button className="p-0" color="link" disabled={loading} onClick={downloadInvoice} size="sm">
      <span className={classNames({ 'opacity-0': loading })}>{number}</span>
      {loading && (
        <div className="position-absolute top-0 w-100">
          <Spinner size="sm" />
        </div>
      )}
    </Button>
  );
};
