import React from 'react';
import { useAppIntl } from 'app/helpers';
import styles from './noResults.module.scss';

interface Props {
  className?: string;
  imageSrc?: string;
  messageId: string;
}

// TODO: Rework to pass both custom image and @tabler/icons as optional
export const NoResults = ({ imageSrc, messageId, className }: Props) => {
  const { formatMessage } = useAppIntl();

  return (
    <div className={`${styles.noResults} fadeIn ${className}`}>
      {imageSrc && <img alt="no results" src={imageSrc} />}
      <span>{formatMessage({ id: messageId })}</span>
    </div>
  );
};
