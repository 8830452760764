import * as types from './conflicts.types';

const initialState = {
  loading: false,
  error: false,
  pagination: {},
  list: [],
  clinics: {
    list: [],
    data: {},
  },
  appointments: {
    list: [],
    data: {},
  },
};

export const conflictsReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.FETCH_CONFLICTS_REQUEST:
    case types.FETCH_CLINIC_CONFLICTS_REQUEST:
    case types.FETCH_APPOINTMENT_CONFLICTS_REQUEST:
      return {
        ...state,
        list: [],
        loading: true,
      };
    case types.FETCH_CONFLICTS_FAILURE:
    case types.FETCH_CLINIC_CONFLICTS_FAILURE:
    case types.FETCH_APPOINTMENT_CONFLICTS_FAILURE:
      return {
        ...state,
        loading: false,
        error: true,
      };
    case types.FETCH_CONFLICTS_SUCCESS:
      return {
        ...state,
        list: action.payload,
        loading: false,
      };
    case types.FETCH_CLINIC_CONFLICTS_SUCCESS:
      return {
        ...state,
        clinics: {
          list: action.payload,
        },
        loading: false,
      };
    case types.FETCH_APPOINTMENT_CONFLICTS_SUCCESS:
      return {
        ...state,
        appointments: {
          list: action.payload,
        },
        loading: false,
      };
    default:
      return state;
  }
};
