import gsap from 'gsap';
import { Spinner } from 'reactstrap';
import { CSSProperties, useLayoutEffect, useRef } from 'react';
import { useAppIntl } from 'app/helpers';
import styles from 'app/components/refreshingBadge/refreshingBadge.module.scss';

interface Props {
  refreshing?: boolean;
  wrapperStyle?: CSSProperties;
}

export const RefreshingBadge = ({ refreshing, wrapperStyle }: Props) => {
  const { formatMessage } = useAppIntl();
  const updatingWrapper = useRef(null);

  useLayoutEffect(() => {
    if (refreshing) {
      gsap.to(updatingWrapper.current, { duration: 0.4, opacity: 1, y: -18, display: 'flex' });
    } else {
      gsap.to(updatingWrapper.current, { duration: 0.3, opacity: 0, y: 0, display: 'none' });
    }
  }, [refreshing]);

  return (
    <div className={styles.refreshingBadgeWrapper} style={wrapperStyle} ref={updatingWrapper}>
      <div className={styles.refreshingBadge}>
        <Spinner className="text-primary" size="sm" />
        <span className="ms-2">{formatMessage({ id: 'CORE.TEXT.REFRESHING' })}</span>
      </div>
    </div>
  );
};
