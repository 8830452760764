import * as types from './app.types';

export interface AdvancedFilters {
  accountBalanceInvoices: boolean;
  appointments: boolean;
  calendar: boolean;
  invoices: boolean;
  insuranceApprovals: boolean;
  claims: boolean;
}

export interface AppState {
  advancedFilters: AdvancedFilters;
  menu: {
    isExpanded: boolean;
  };
  navigation: {
    selectedItem: string | null;
    selectedSubmenu: string[];
  };
}

export interface Action {
  payload?: Partial<{
    advancedFilterName: keyof AdvancedFilters;
    id: string;
    status: boolean;
  }>;
  type: string;
}

export const initialState: AppState = {
  advancedFilters: {
    accountBalanceInvoices: false,
    appointments: false,
    calendar: false,
    invoices: false,
    insuranceApprovals: false,
    claims: false,
  },
  menu: {
    isExpanded: false,
  },
  navigation: {
    selectedItem: null,
    selectedSubmenu: [],
  },
};

export const appReducer = (state = initialState, action: Action): AppState => {
  const { advancedFilterName, status, id } = action.payload || {};

  switch (action.type) {
    // Advanced Filters
    case types.TOGGLE_ADVANCED_FILTERS:
      return {
        ...state,
        advancedFilters: {
          ...state.advancedFilters,
          ...(advancedFilterName && {
            [advancedFilterName]: status !== undefined ? status : !state.advancedFilters[advancedFilterName],
          }),
        },
      };
    // Menu
    case types.TOGGLE_MENU:
      return {
        ...state,
        menu: {
          ...state.menu,
          isExpanded: status !== undefined ? status : !state.menu.isExpanded,
        },
      };
    // Navigation
    case types.OPEN_NAVIGATION_SUBMENU:
      return {
        ...state,
        navigation: {
          ...state.navigation,
          selectedSubmenu: id ? [...state.navigation.selectedSubmenu, id] : state.navigation.selectedSubmenu,
        },
      };
    case types.CLOSE_NAVIGATION_SUBMENU:
      return {
        ...state,
        navigation: {
          ...state.navigation,
          selectedSubmenu: state.navigation.selectedSubmenu.filter((subMenuId) => subMenuId !== id),
        },
      };
    default:
      return state;
  }
};
