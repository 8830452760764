import classnames from 'classnames';
import { WrappedFieldProps } from 'redux-form/lib/Field';
import { useCallback, useState, WheelEvent, KeyboardEvent, useEffect } from 'react';
import { Label, FormGroup, InputGroup, InputGroupText, Input, Row, Col, FormFeedback } from 'reactstrap';
import { getDurationValuesFromMinutes, getMinutesFromDurationValues, useAppIntl } from 'app/helpers';
import { checkValue } from './helpers';

export interface DurationProps {
  daysInput?: boolean;
  hoursInput?: boolean;
  isRequired?: boolean;
  label: string;
  minutesInput?: boolean;
}

export const Duration = ({
  daysInput = true,
  hoursInput = true,
  input: { onChange },
  isRequired,
  label,
  meta: { error, touched, initial },
  minutesInput = true,
}: WrappedFieldProps & DurationProps) => {
  const { formatMessage } = useAppIntl();
  const invalid = !!error && touched;
  const [days, setDays] = useState<string>('');
  const [hours, setHours] = useState<string>('');
  const [minutes, setMinutes] = useState<string>('');
  const hasMaxDuration = days === '7';
  const daysLimit = 7;
  const hoursLimit = 23;
  const minutesLimit = 55;

  const onWheel = useCallback((e: WheelEvent<HTMLInputElement>) => {
    // @ts-ignore
    e.target?.blur();
  }, []);

  const onKeyDown = useCallback((e: KeyboardEvent<HTMLInputElement>) => {
    const notAllowedCharacters = ['e', 'E', '+', '-', '.', ','];

    if (notAllowedCharacters.includes(e.key)) {
      e.preventDefault();
    }
  }, []);

  useEffect(() => {
    // Change Field Value
    onChange(
      getMinutesFromDurationValues({
        days: Number(days),
        hours: Number(hours),
        minutes: Number(minutes),
      }),
    );
  }, [days, hours, minutes, onChange]);

  useEffect(() => {
    if (typeof initial === 'number') {
      const { days, hours, minutes } = getDurationValuesFromMinutes(initial);
      setDays(String(days));
      setHours(String(hours));
      setMinutes(String(minutes));
    }
  }, [initial]);

  return (
    <FormGroup>
      <Label className="mb-2">
        {label}
        {isRequired && <span className="text-danger">&nbsp;*</span>}
      </Label>
      <Row className={classnames('g-2', { 'is-invalid': invalid })}>
        {daysInput && (
          <Col>
            <InputGroup size="sm">
              <Input
                max={daysLimit}
                min={0}
                name="days"
                onChange={(e) => {
                  const targetValue = e.target.value;

                  if (checkValue(targetValue)) {
                    if (Number(targetValue) >= daysLimit) {
                      setDays(String(daysLimit));
                      setHours('0');
                      setMinutes('0');
                    } else {
                      setDays(targetValue);
                    }
                  }
                }}
                onKeyDown={onKeyDown}
                onWheel={onWheel}
                placeholder="0"
                step="1"
                type="number"
                value={days}
              />
              <InputGroupText>
                <small>{formatMessage({ id: 'CORE.TEXT.DAYS' })}</small>
              </InputGroupText>
            </InputGroup>
          </Col>
        )}
        {hoursInput && (
          <Col>
            <InputGroup size="sm">
              <Input
                disabled={hasMaxDuration}
                max={hoursLimit}
                min={0}
                name="hours"
                onChange={(e) => {
                  const targetValue = e.target.value;

                  if (checkValue(targetValue)) {
                    if (Number(targetValue) >= hoursLimit) {
                      setHours(String(hoursLimit));
                    } else {
                      setHours(targetValue);
                    }
                  }
                }}
                onKeyDown={onKeyDown}
                onWheel={onWheel}
                placeholder="0"
                step="1"
                type="number"
                value={hours}
              />
              <InputGroupText>
                <small>{formatMessage({ id: 'CORE.TEXT.HOURS' })}</small>
              </InputGroupText>
            </InputGroup>
          </Col>
        )}
        {minutesInput && (
          <Col>
            <InputGroup size="sm">
              <Input
                disabled={hasMaxDuration}
                max={minutesLimit}
                min={0}
                name="minutes"
                onChange={(e) => {
                  const targetValue = e.target.value;

                  if (checkValue(targetValue)) {
                    if (Number(targetValue) >= minutesLimit) {
                      setMinutes(String(minutesLimit));
                    } else {
                      setMinutes(targetValue);
                    }
                  }
                }}
                onKeyDown={onKeyDown}
                onWheel={onWheel}
                placeholder="0"
                step="5"
                type="number"
                value={minutes}
              />
              <InputGroupText>
                <small>{formatMessage({ id: 'CORE.TEXT.MINUTES' })}</small>
              </InputGroupText>
            </InputGroup>
          </Col>
        )}
      </Row>
      {invalid && <FormFeedback className="d-block">{formatMessage({ id: error })}</FormFeedback>}
    </FormGroup>
  );
};
