import { RemoteFile } from 'app/types';

/**
 * Create FormData with file as a Blob
 * @param file file object with url field
 * @param autoRevoke remove unnecessary reference from memory. If set to false, the file must be revoked manually!
 */
export const remoteFileFormDataAdapter = async (file: RemoteFile, autoRevoke: boolean = true): Promise<FormData> => {
  const formData = new FormData();
  const blob = await fetch(file.url).then((response) => response.blob());
  formData.append('file', blob, file.name);

  if (autoRevoke) {
    URL.revokeObjectURL(file.url);
  }

  return formData;
};
