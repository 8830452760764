import React from 'react';
import { Button, UncontrolledTooltip } from 'reactstrap';

import defaultPhoto from '../../../../../../assets/images/icons/default_photo.jpg';
import { DescriptionList } from 'app/components';
import { Doctor } from 'app/types';
import { SelectedSlot } from 'app/redux/calendar/types';
import { useAppIntl, getTranslation } from 'app/helpers';
import { useItems } from 'app/bookAppointment/bookAppointmentWizard/steps/patientStep/slotSection/helpers/useItems';

interface Props {
  doctor?: Doctor;
  selectedSlot: SelectedSlot | null;
}

export const SlotSection = ({ doctor, selectedSlot }: Props) => {
  const { formatMessage, locale } = useAppIntl();

  const items = useItems({ doctor, selectedSlot });
  const hasAboutDoctorInfo = !!(
    doctor?.aboutPractitionerInfo?.translation?.en || doctor?.aboutPractitionerInfo?.translation?.ar
  );

  if (!doctor || !selectedSlot) {
    return <span>Select time slot first</span>;
  }

  return (
    <div className="d-flex flex-column flex-lg-row">
      <div className="me-lg-4" style={{ maxWidth: '120px' }}>
        <img alt="doctor" className="img-thumbnail rounded-3" src={doctor.image?.url || defaultPhoto} width="120" />
        {hasAboutDoctorInfo && (
          <>
            <Button className="d-block w-100 mt-2" size="sm" color="light" id="info-doctor-tooltip">
              {formatMessage({ id: 'CORE.LABEL.ABOUT', defaultMessage: 'About' })}
            </Button>
            <UncontrolledTooltip target="info-doctor-tooltip">
              {doctor.aboutPractitionerInfo && getTranslation(doctor.aboutPractitionerInfo, locale)}
            </UncontrolledTooltip>
          </>
        )}
      </div>
      <div className="w-100 mt-3 mt-lg-0">
        <DescriptionList rowProps={{ md: 1, lg: 2, xl: 3 }} items={items} />
      </div>
    </div>
  );
};
