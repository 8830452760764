import moment from 'moment';

import { LANGUAGES_AR_CODE, LANGUAGES_EN_CODE } from 'app/shared';

const getCustomMomentSpecification = (lang) => {
  // Custom specification for the Arabic language
  if (lang === LANGUAGES_AR_CODE) {
    return {
      preparse: function (string) {
        return string;
      },
      postformat: function (string) {
        return string;
      },
      week: {
        dow: 0,
      },
    };
  }

  return {}; // Use the default specification
};

export const setLangAttributes = (lang) => {
  const dir = lang === LANGUAGES_AR_CODE ? 'rtl' : 'ltr';

  // Set attributes in the document
  document.getElementsByTagName('html')[0].setAttribute('lang', lang);
  document.getElementsByTagName('html')[0].setAttribute('dir', dir);

  // Set the local storage item
  localStorage.setItem('LANGUAGE', lang);

  // Set moment object
  moment.updateLocale(lang, getCustomMomentSpecification(lang));
};

export const setLanguageToSwap = (lang) => (lang === LANGUAGES_EN_CODE ? LANGUAGES_AR_CODE : LANGUAGES_EN_CODE);

export const getLanguageCode = (language) => language.charAt(0).toUpperCase() + language.charAt(1);
