import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQueryWithReAuth } from 'app/helpers';
import { QueryParams, QueryResponseWithPagination, IconLibraryItem } from 'app/types';
import { IconBodyParams } from 'app/services/iconLibrary/types';

export const iconLibraryApi = createApi({
  reducerPath: 'iconsApi',
  baseQuery: baseQueryWithReAuth,
  tagTypes: ['IconLibrary'],
  endpoints: (builder) => ({
    getIcons: builder.query<QueryResponseWithPagination<IconLibraryItem[]>, QueryParams | undefined>({
      query: (params) => ({
        url: '/icons',
        params,
      }),
      providesTags: ['IconLibrary'],
    }),
    addIcon: builder.mutation<void, IconBodyParams>({
      query: (body) => ({
        url: `/icons`,
        method: 'POST',
        body,
      }),
      invalidatesTags: ['IconLibrary'],
    }),
    editIcon: builder.mutation<void, { body: IconBodyParams; iconId: string }>({
      query: ({ body, iconId }) => ({
        url: `/icons/${iconId}`,
        method: 'PUT',
        body,
      }),
      invalidatesTags: ['IconLibrary'],
    }),
    deleteIcon: builder.mutation<void, string>({
      query: (iconId) => ({
        url: `/icons/${iconId}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['IconLibrary'],
    }),
  }),
});

export const { useGetIconsQuery, useAddIconMutation, useEditIconMutation, useDeleteIconMutation } = iconLibraryApi;
