import { ReactNode } from 'react';
import classnames from 'classnames';
import { Col, Row } from 'reactstrap';
import { RowProps } from 'reactstrap/types/lib/Row';
import { IconArrowLeft, IconArrowRight } from '@tabler/icons-react';
import { BREAKPOINT_SM, useAppIntl, useWindowSize } from 'app/helpers';
import { IconButton } from 'app/components/iconButton/iconButton';

interface PageProps {
  children: ReactNode;
  className?: string;
}

export const Page = ({ children, className }: PageProps) => {
  return <div className={classnames('page slideInUp', className)}>{children}</div>;
};

interface PageHeaderProps {
  backButtonAction?: () => void;
  children?: ReactNode;
  className?: string;
  title?: ReactNode | string;
  titleMessageId?: string;
  rowProps?: RowProps;
}

export const PageHeader = ({
  backButtonAction,
  children,
  className,
  rowProps,
  title,
  titleMessageId,
}: PageHeaderProps) => {
  const windowSize = useWindowSize();
  const { formatMessage, isRtl } = useAppIntl();
  const iconSize = windowSize.width > BREAKPOINT_SM ? 24 : 22;
  const defaultRowProps: RowProps = { xs: 1, md: children ? 2 : 1 };

  return (
    <Row {...(rowProps || defaultRowProps)} className={classnames('page__header mb-3', className)}>
      {/* Header title */}
      <Col>
        <div className="d-flex align-items-center mb-2">
          {backButtonAction && (
            <IconButton
              icon={isRtl ? <IconArrowRight size={iconSize} /> : <IconArrowLeft size={iconSize} />}
              onClick={backButtonAction}
              tooltipMessageId="CORE.BUTTON.BACK"
              className="me-1"
            />
          )}
          <h2 className="mb-0">
            {title
              ? title
              : titleMessageId
              ? formatMessage({
                  id: titleMessageId,
                  defaultMessage: '',
                })
              : null}
          </h2>
        </div>
      </Col>

      {/* Header actions */}
      {children && <Col className="text-right">{children}</Col>}
    </Row>
  );
};
