import React from 'react';

import { Box, BoxHeader } from 'app/components';
import { useAppSelector } from 'app/helpers';
import { SlotSection } from './slotSection/slotSection';
import { PatientSection } from './patientSection/patientSection';
import { PatientSearchHandlers } from 'app/shared/patientSearch/helpers/usePatientSearchHandlers';

interface Props {
  createdPatientMode?: boolean;
  resetMode: () => void;
  onAddNewPatient: () => void;
  searchHandlers: PatientSearchHandlers;
}

export const PatientStep = ({ createdPatientMode, onAddNewPatient, resetMode, searchHandlers }: Props) => {
  const selectedSlot = useAppSelector((state) => state.bookAppointment.selectedSlot);
  const clinicId = selectedSlot?.doctor.clinic?.value;

  return (
    <>
      <Box className="fadeIn pb-1">
        <BoxHeader messageId={'CORE.LABEL.DOCTOR'} />
        <SlotSection doctor={selectedSlot?.doctor} selectedSlot={selectedSlot} />
      </Box>

      {clinicId && (
        <PatientSection
          clinicId={clinicId}
          createdPatientMode={createdPatientMode}
          doctor={selectedSlot?.doctor}
          onAddNewPatient={onAddNewPatient}
          resetMode={resetMode}
          searchHandlers={searchHandlers}
        />
      )}
    </>
  );
};
