import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Form, Button, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { Field } from 'redux-form';
import { FormattedMessage } from 'react-intl';
import { PasswordInput, required, minLength, comparePasswords } from 'app/shared';

const compare = comparePasswords('newPassword');

const trim = (value) => value && value.trim();

const minLength2 = minLength(2);

class FormComponent extends Component {
  static propTypes = {
    closeModal: PropTypes.func.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    intl: PropTypes.object.isRequired,
    pristine: PropTypes.bool,
    submitting: PropTypes.bool,
  };

  render() {
    const { formatMessage } = this.props.intl;
    return (
      <Form className="form" onSubmit={this.props.handleSubmit}>
        <ModalHeader toggle={() => this.props.closeModal()}>
          {formatMessage({ id: 'CHANGE-PASSWORD.TEXT.TITLE' })}
        </ModalHeader>
        <ModalBody>
          <p>{this.props.formDescription && formatMessage({ id: this.props.formDescription })}</p>
          <p>
            <FormattedMessage id="CORE.LABEL.USERNAME" />: <b>{this.props.username}</b>
          </p>
          <Field
            normalize={trim}
            component={PasswordInput}
            name="oldPassword"
            validate={[required, minLength2]}
            minLength={2}
            props={{
              label: formatMessage({ id: 'CORE.LABEL.OLD-PASSWORD' }),
              isRequired: true,
              isRtl: false,
            }}
          />
          <Field
            minLength={2}
            normalize={trim}
            component={PasswordInput}
            name="newPassword"
            validate={[required, minLength2]}
            props={{
              label: formatMessage({ id: 'CORE.LABEL.NEW-PASSWORD' }),
              isRequired: true,
              isRtl: false,
            }}
          />
          <Field
            normalize={trim}
            component={PasswordInput}
            name="repeatPassword"
            validate={[required, minLength2, compare]}
            props={{
              label: formatMessage({ id: 'CORE.LABEL.REPEAT-PASSWORD' }),
              isRequired: true,
              isRtl: false,
            }}
          />
        </ModalBody>
        <ModalFooter>
          <Button
            type="button"
            disabled={this.props.submitting}
            color="secondary"
            onClick={() => this.props.closeModal()}
          >
            {formatMessage({ id: 'CORE.BUTTON.CANCEL' })}
          </Button>
          <Button disabled={this.props.pristine || this.props.submitting} type="submit" color="primary-gradient">
            {formatMessage({ id: 'CORE.BUTTON.CHANGE-PASSWORD' })}
          </Button>
        </ModalFooter>
      </Form>
    );
  }
}

export default FormComponent;
