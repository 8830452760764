import { AnyAction } from 'redux';
import { doctorServiceAdapter } from 'app/helpers';
import { Appointment, CalendarDay, CalendarSlot, Option, RemoteData } from 'app/types';
import { FullEdit, PreselectedPatient, ProductOption, Rebook, RegisterPatientData, Ticket } from './types';
import { PatientInfoShort } from 'app/shared/patientSearch';
import * as types from './appointment.types';

interface AppointmentReducer {
  data: Appointment | null;
  day: RemoteData<CalendarDay | null>;
  daySlots: RemoteData<CalendarSlot[] | null>;
  details: Appointment | null;
  fullEdit: FullEdit;
  loading: boolean;
  medicalServices: RemoteData<Option[]>;
  preselectedPatient: PreselectedPatient | null;
  products: RemoteData<ProductOption[]>;
  rebook: Rebook;
  registerPatientModal: RegisterPatientData | null;
  selectedPatient: RemoteData<PatientInfoShort | null>;
  ticket: Ticket | null;
}

const initialState: AppointmentReducer = {
  // Appointment data
  data: null,
  loading: false,

  // Details
  details: null,

  // Full edit mode
  fullEdit: {
    data: null,
    isActive: false,
  },

  // Full edit mode
  rebook: {
    data: null,
  },

  // Patient data
  selectedPatient: {
    data: null,
    error: false,
    loading: false,
  },

  // Preselected patient data
  preselectedPatient: null,

  // Medical services
  medicalServices: {
    data: [],
    loading: false,
  },

  // Products
  products: {
    data: [],
    loading: false,
  },

  // Day
  day: {
    data: null,
    loading: false,
  },

  // Day slots
  daySlots: {
    data: null,
    loading: false,
  },

  // Register Patient Modal
  registerPatientModal: null,

  // Ticket
  ticket: null,
};

export const appointmentReducer = (state = initialState, action: AnyAction): AppointmentReducer => {
  switch (action.type) {
    // Fetch appointment
    case types.FETCH_APPOINTMENT_REQUEST:
      return {
        ...state,
        data: initialState.data,
        loading: true,
      };
    case types.FETCH_APPOINTMENT_SUCCESS:
      return {
        ...state,
        data: action.payload.data,
        loading: false,
      };
    case types.FETCH_APPOINTMENT_FAILURE:
      return {
        ...state,
        data: initialState.data,
        loading: false,
      };

    // Appointment details
    case types.FETCH_WA_DETAILS_REQUEST:
      return {
        ...state,
        details: initialState.details,
      };
    case types.FETCH_WA_DETAILS_SUCCESS:
      return {
        ...state,
        details: action.payload.data,
      };
    case types.RESET_DETAILS:
      return {
        ...state,
        details: initialState.details,
      };

    // Appointment data
    case types.SET_APPOINTMENT:
      return {
        ...state,
        data: action.payload,
      };
    case types.RESET_APPOINTMENT:
      return {
        ...state,
        data: initialState.data,
      };

    // Full edit
    case types.SET_FULL_EDIT:
      return {
        ...state,
        fullEdit: {
          data: action.payload,
          isActive: true,
        },
      };
    case types.RESET_FULL_EDIT:
      return {
        ...state,
        fullEdit: initialState.fullEdit,
      };

    // Rebook
    case types.SET_REBOOK:
      return {
        ...state,
        rebook: {
          data: action.payload,
        },
      };
    case types.RESET_REBOOK:
      return {
        ...state,
        rebook: initialState.rebook,
      };

    // Patient data
    case types.SET_PATIENT:
      return {
        ...state,
        selectedPatient: {
          ...initialState.selectedPatient,
          data: action.payload,
        },
      };
    case types.RESET_PATIENT:
      return {
        ...state,
        selectedPatient: initialState.selectedPatient,
      };

    // Preselected patient data
    case types.SET_PRESELECTED_PATIENT:
      return {
        ...state,
        preselectedPatient: action.meta.patient,
      };
    case types.RESET_PRESELECTED_PATIENT:
      return {
        ...state,
        preselectedPatient: initialState.preselectedPatient,
      };

    // Medical services
    case types.FETCH_MEDICAL_SERVICES_REQUEST:
      return {
        ...state,
        medicalServices: {
          ...initialState.medicalServices,
          loading: true,
        },
      };
    case types.FETCH_MEDICAL_SERVICES_SUCCESS:
      return {
        ...state,
        medicalServices: {
          data: doctorServiceAdapter(action.payload.data),
          loading: false,
        },
      };
    case types.FETCH_MEDICAL_SERVICES_FAILURE:
      return {
        ...state,
        medicalServices: initialState.medicalServices,
      };

    // Medical services in range
    case types.FETCH_WORKING_HOUR_MEDICAL_SERVICES_REQUEST:
      return {
        ...state,
        medicalServices: {
          ...initialState.medicalServices,
          loading: true,
        },
      };
    case types.FETCH_WORKING_HOUR_MEDICAL_SERVICES_SUCCESS:
      return {
        ...state,
        medicalServices: {
          data: action.payload.data,
          loading: false,
        },
      };
    case types.FETCH_WORKING_HOUR_MEDICAL_SERVICES_FAILURE:
      return {
        ...state,
        medicalServices: initialState.medicalServices,
      };

    // Day
    case types.FETCH_DAY_REQUEST:
      return {
        ...state,
        ...(!action.meta?.isSilent && {
          day: {
            ...initialState.day,
            loading: true,
          },
        }),
      };
    case types.FETCH_DAY_SUCCESS:
      return {
        ...state,
        day: {
          data: action.payload,
          loading: false,
        },
      };
    case types.FETCH_DAY_FAILURE:
      return {
        ...state,
        day: initialState.day,
      };

    // Day slots
    case types.FETCH_DAY_SLOTS_REQUEST:
      return {
        ...state,
        daySlots: {
          ...initialState.daySlots,
          loading: true,
        },
      };
    case types.FETCH_DAY_SLOTS_SUCCESS:
      return {
        ...state,
        daySlots: {
          data: action.payload,
          loading: false,
        },
      };
    case types.FETCH_DAY_SLOTS_FAILURE:
      return {
        ...state,
        daySlots: initialState.daySlots,
      };

    // Register Patient Modal
    case types.SET_APPOINTMENT_REGISTER_PATIENT:
      return {
        ...state,
        registerPatientModal: {
          clinicId: action.meta.clinicId,
          patientId: action.meta.patientId,
        },
        selectedPatient: initialState.selectedPatient,
      };
    case types.RESET_APPOINTMENT_REGISTER_PATIENT:
      return {
        ...state,
        registerPatientModal: initialState.registerPatientModal,
      };

    // Fetch Products in time range
    case types.FETCH_PRODUCTS_REQUEST:
      return {
        ...state,
        products: {
          data: initialState.products.data,
          loading: true,
        },
      };
    case types.FETCH_PRODUCTS_SUCCESS:
      return {
        ...state,
        products: {
          data: action.payload.data,
          loading: false,
        },
      };
    case types.FETCH_PRODUCTS_FAILURE:
      return {
        ...state,
        products: initialState.products,
      };

    // Ticket
    case types.SET_TICKET:
      return {
        ...state,
        ticket: action.payload,
      };
    case types.RESET_TICKET:
      return {
        ...state,
        ticket: initialState.ticket,
      };

    // Default
    default:
      return state;
  }
};
