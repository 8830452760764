import delay from 'lodash/delay';
import { useCallback } from 'react';
import { useAppDispatch, useAppSelector } from 'app/helpers';
import { reset as resetRemoteData } from 'app/redux/remoteData/remoteData.actions';
import { setMode, setStep } from 'app/redux/invoicingProcess/invoicingProcess.actions';
import { InvoicingProcessMode, InvoicingProcessStep } from 'app/redux/invoicingProcess/types';

export const useOnBack = () => {
  const dispatch = useAppDispatch();
  const step = useAppSelector((state) => state.invoicingProcess.step);
  const appointmentId = useAppSelector((state) => state.invoicingProcess.appointmentId);

  return useCallback(() => {
    if (step === InvoicingProcessStep.Appointment) {
      dispatch(resetRemoteData('appointmentInvoices'));
      delay(() => dispatch(setMode(InvoicingProcessMode.Invoices)), 50); // small delay is not visible to the user but reduces the jumping effect
    }

    if (step === InvoicingProcessStep.PaymentMode) {
      if (appointmentId) {
        dispatch(setStep(InvoicingProcessStep.Appointment));
      } else {
        dispatch(setStep(InvoicingProcessStep.Details));
      }
    }

    if (step === InvoicingProcessStep.Items) {
      dispatch(setStep(InvoicingProcessStep.PaymentMode));
    }

    if (step === InvoicingProcessStep.PaymentMethod) {
      dispatch(setStep(InvoicingProcessStep.Items));
    }

    if (step === InvoicingProcessStep.Summary) {
      dispatch(setStep(InvoicingProcessStep.PaymentMethod));
    }
  }, [appointmentId, dispatch, step]);
};
