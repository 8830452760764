import { useMemo } from 'react';
import { InvoicePayBy } from 'app/types';
import { DescriptionList } from 'app/components';
import { getTranslation, useAppIntl, useAppSelector } from 'app/helpers';

export const PaymentModePreview = () => {
  const { formatMessage, locale } = useAppIntl();
  const paymentMode = useAppSelector((state) => state.invoicingProcess.data?.steps?.paymentMode);

  const listItems = useMemo(() => {
    if (!paymentMode) {
      return [];
    }

    const isPayByInsurance = paymentMode.payBy.value === InvoicePayBy.Insurance;
    const approvalNumber = paymentMode.insurance?.approvalNumber;
    const eligibilityNumber = paymentMode.insurance?.eligibilityNumber;
    const insuranceApprovalLabel =
      getTranslation(paymentMode.insurance?.insuranceApproval, locale) ||
      paymentMode.insurance?.insuranceApproval?.label;
    const referralInsuranceApprovalLabel =
      getTranslation(paymentMode.insurance?.referralInsuranceApproval, locale) ||
      paymentMode.insurance?.referralInsuranceApproval?.label;

    return paymentMode
      ? [
          {
            label: formatMessage({ id: 'CORE.LABEL.PAY-BY', defaultMessage: 'Pay by' }),
            value: getTranslation(paymentMode.payBy, locale),
          },
          ...(isPayByInsurance
            ? [
                {
                  label: formatMessage({ id: 'CORE.LABEL.INSURANCE', defaultMessage: 'Insurance' }),
                  value: paymentMode.insurance ? getTranslation(paymentMode.insurance.company, locale) : '',
                },
                ...(eligibilityNumber
                  ? [
                      {
                        label: formatMessage({
                          id: 'CORE.LABEL.ELIGIBILITY-NUMBER',
                        }),
                        value: eligibilityNumber,
                      },
                    ]
                  : []),
                // Approval Number (for old invoices)
                ...(approvalNumber
                  ? [
                      {
                        label: formatMessage({
                          id: 'CORE.LABEL.INSURANCE-APPROVAL-NUMBER',
                        }),
                        value: approvalNumber,
                      },
                    ]
                  : []),
                // Insurance Approval (for new invoices)
                ...(insuranceApprovalLabel
                  ? [
                      {
                        label: formatMessage({
                          id: 'CORE.TEXT.INSURANCE-APPROVAL',
                        }),
                        value: insuranceApprovalLabel,
                      },
                    ]
                  : []),
                ...(referralInsuranceApprovalLabel
                  ? [
                      {
                        label: formatMessage({
                          id: 'CORE.LABEL.INSURANCE-REFERRAL-APPROVAL',
                        }),
                        value: referralInsuranceApprovalLabel,
                      },
                    ]
                  : []),
              ]
            : [
                {
                  label: formatMessage({ id: 'CORE.LABEL.GOVERNMENT-ID-TYPE' }),
                  value: getTranslation(paymentMode.governmentIssuedIdType || undefined),
                },
                {
                  label: formatMessage({ id: 'CORE.LABEL.GOVERNMENT-ID' }),
                  value: paymentMode.governmentIssuedId,
                },
              ]),
        ]
      : [];
  }, [formatMessage, locale, paymentMode]);

  return <DescriptionList rowProps={{ xl: 4 }} items={listItems} />;
};
