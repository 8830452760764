import moment from 'moment';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { WrappedFieldProps } from 'redux-form/lib/Field';
import { Label } from 'reactstrap';
import { FORMATS_TIME } from 'app/shared';
import { checkIsRtl, useAppIntl } from 'app/helpers';
import { Slider } from 'app/components';
import './dateSlider.scss';

const TIMESTAMP_FORMAT = 'x';
const getDateValue = (date: Date | string): number => Number(moment(date).valueOf());
const getFormattedValue = (value: number) => moment(value, TIMESTAMP_FORMAT).format(FORMATS_TIME);
const convertToMs = (minutes: number): number => minutes * 60 * 1000; // convert to milliseconds

export interface DateSliderProps {
  disabled?: boolean;
  isRequired?: boolean;
  label: string;
  margin?: number; // minutes
  max?: Date;
  min?: Date;
  step?: number; // minutes
}

const defaultMin = moment().startOf('day').toDate();
const defaultMax = moment().endOf('day').toDate();

export const DateSlider = ({
  disabled,
  input: { onChange, value },
  isRequired,
  label,
  max = defaultMax,
  meta: { error, touched },
  min = defaultMin,
  step = 15,
  margin = 15,
}: WrappedFieldProps & DateSliderProps) => {
  const { formatMessage, locale } = useAppIntl();
  const stepValue = convertToMs(step);
  const marginValue = margin ? convertToMs(margin) : stepValue;
  const direction = useMemo(() => (checkIsRtl(locale) ? 'rtl' : 'ltr'), [locale]);

  // Initial values
  const initialFrom = useMemo(() => getDateValue(value?.from || min), [value, min]);
  const initialTo = useMemo(() => getDateValue(value?.to || max), [value, max]);

  // Values to display
  const [from, setFrom] = useState<number>(initialFrom);
  const [to, setTo] = useState<number>(initialTo);

  const range = useMemo(
    () => ({
      min: getDateValue(min),
      max: getDateValue(max),
    }),
    [min, max],
  );

  const handleOnChange = useCallback(
    (values) => {
      onChange({
        from: moment(values[0], TIMESTAMP_FORMAT).toDate(),
        to: moment(values[1], TIMESTAMP_FORMAT).toDate(),
      });
    },
    [onChange],
  );

  const handleOnSlide = useCallback((values: any[]) => {
    setFrom(Number(values[0]));
    setTo(Number(values[1]));
  }, []);

  useEffect(() => {
    setFrom(getDateValue(value?.from || min));
    setTo(getDateValue(value?.to || max));
  }, [min, max, value]);

  return (
    <div className="time-range">
      <Label>
        {label}
        {isRequired && <span className="text-danger">&nbsp;*</span>}
      </Label>
      <div className="time-range__wrapper">
        <div className="time-range__time">{getFormattedValue(from)}</div>
        <div className="time-range__slider">
          <Slider
            behaviour="tap-drag"
            connect
            direction={direction}
            disabled={disabled}
            margin={marginValue}
            onChange={handleOnChange}
            onSlide={handleOnSlide}
            range={range}
            start={[initialFrom, initialTo]}
            step={stepValue}
          />
        </div>
        <div className="time-range__time">{getFormattedValue(to)}</div>
      </div>
      {error && touched && <div className="invalid-feedback d-block">{formatMessage({ id: error })}</div>}
    </div>
  );
};
