import { useCallback } from 'react';
import { RemoteDataParams } from 'app/types';
import { useAppDispatch } from 'app/helpers';
import { fetchDropdownOptions } from 'app/redux/dropdownOptions/dropdownOptions.actions';

export const useFetchDoctorOptions = (clinicId?: string, branchIds?: string[]) => {
  const dispatch = useAppDispatch();

  return useCallback(
    (params: RemoteDataParams) =>
      dispatch(fetchDropdownOptions('/doctors/dropdown', { ...params, clinicIds: [clinicId], branchIds })),
    [dispatch, clinicId, branchIds],
  );
};
