import moment from 'moment/moment';

export const maxTimePeriodInYears = (dateFrom: Date, dateTo: Date, numberOfYears: number) => {
  const errorMessage = 'ERRORS.MAX-TIME-PERIOD';

  const isGreaterThanMaxDiff = Math.abs(moment(dateFrom).diff(moment(dateTo), 'year')) > numberOfYears;

  if (!isGreaterThanMaxDiff) {
    return undefined;
  }

  return errorMessage;
};
