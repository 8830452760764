export const getIdFromPathname = (name: string, pathname: string): string | null => {
  const regex = new RegExp(`${name}/(.*?)(/|$)`);
  const match = pathname.match(regex);

  if (match && match[1]) {
    return match[1];
  }

  return null;
};
