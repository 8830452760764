import { useMemo } from 'react';
import { useIntl } from 'react-intl';
import { ColumnDescription } from 'react-bootstrap-table-next';
import { Appointment } from 'app/types';
import { ProtectedPhoneNumber } from 'app/components';
import { getTranslation } from 'app/helpers';
import { DateAndTime } from 'app/appointment/shared';
import { ticketFormatter } from 'app/features/appointments/formatters/ticketFormatter';
import { patientFormatter } from 'app/features/appointments/formatters/patientFormatter';
import { ActionsFormatter } from 'app/calendar/modals/manageWaitingAppointments/bookedWaitingAppointments/formatters';

export const useColumns = () => {
  const { formatMessage, locale } = useIntl();

  return useMemo(
    (): ColumnDescription<Appointment>[] => [
      {
        dataField: 'patientClinicyMrn',
        text: formatMessage({ id: 'CORE.LABEL.CLINICY-MRN' }),
      },
      {
        dataField: 'patient',
        formatter: patientFormatter,
        text: formatMessage({ id: 'CORE.LABEL.PATIENT' }),
      },
      {
        dataField: 'phoneNumber',
        formatter: (cell, row) => <ProtectedPhoneNumber phoneNumber={row.patientPhoneNumber} />,
        text: formatMessage({ id: 'CORE.LABEL.PHONE-NUMBER' }),
        classes: 'min-w-180',
      },
      {
        dataField: 'timeRange',
        formatter: (_, row) => <DateAndTime appointment={row} />,
        text: formatMessage({ id: 'CORE.LABEL.PREFERRED-TIME-RANGE' }),
        classes: 'text-center text-nowrap',
      },
      {
        dataField: 'product',
        text: formatMessage({ id: 'CORE.TEXT.SERVICE' }),
        formatter: (cell, row, rowIndex, { locale }) => getTranslation(row.product, locale),
        formatExtraData: { locale },
      },
      {
        dataField: 'ticket',
        formatter: ticketFormatter,
        text: formatMessage({ id: 'CORE.TABLE.APPOINTMENT-TICKET' }),
      },
      {
        dataField: 'actions',
        formatter: ActionsFormatter,
        text: formatMessage({ id: 'CORE.TABLE.ACTIONS' }),
        classes: 'text-center sticky sticky--end',
        headerClasses: 'text-center sticky sticky--end',
      },
    ],
    [formatMessage, locale],
  );
};
