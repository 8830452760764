import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IconLibraryState } from 'app/redux/iconLibrary/types';
import { IconLibraryItem } from 'app/types';

const initialState: IconLibraryState = {
  iconLibrary: null,
};

const iconLibrary = createSlice({
  name: 'iconLibrary',
  initialState,
  reducers: {
    setIconLibrary: (state, action: PayloadAction<IconLibraryItem>) => {
      state.iconLibrary = action.payload;
    },

    resetIconLibrary: (state) => {
      state.iconLibrary = initialState.iconLibrary;
    },
  },
});

export const { setIconLibrary, resetIconLibrary } = iconLibrary.actions;
export default iconLibrary.reducer;
