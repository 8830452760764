import isEqual from 'lodash/isEqual';
import { Values } from 'app/components/locationSelector/procedureLocation/types';

interface Props {
  initialTable?: Values;
  currentTable?: Values;
}

export const isMedicalProfileLocationsPristine = ({ initialTable, currentTable }: Props): boolean => {
  // Helper Variables
  const currentContainCodes = currentTable && currentTable.notes?.length > 0;
  const initialContainCodes = initialTable && initialTable.notes?.length > 0;

  // Conditions
  const areBothEmpty = !currentContainCodes && !initialContainCodes;

  return areBothEmpty ? true : isEqual(initialTable, currentTable);
};
