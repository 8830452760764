import { AnyAction } from 'redux';
import { Package, PackageItem } from 'app/types';
import { ModalMode } from 'app/redux/billingPackages/types';
import { calculateOriginalTotalPrice, calculateTotalPrice } from 'app/redux/billingPackages/helpers/calculatePrice';
import { removeItem } from 'app/redux/billingPackages/helpers/removeItem';
import { addItem } from 'app/redux/billingPackages/helpers/addItem';
import * as types from 'app/redux/billingPackages/billingPackages.types';

export interface BillingPackagesReducer {
  items: PackageItem[];
  modalMode: ModalMode;
  packageId: string | undefined;
  data: Package | null;
  totalPrice: number;
  totalOriginalPrice: number;
}

const initialState: BillingPackagesReducer = {
  items: [],
  modalMode: ModalMode.Add,
  packageId: undefined,
  data: null,
  totalPrice: 0,
  totalOriginalPrice: 0,
};

export const billingPackagesReducer = (state = initialState, action: AnyAction): BillingPackagesReducer => {
  switch (action.type) {
    case types.ADD_ITEM:
      return addItem(state, action.payload);
    case types.RESET_ITEMS:
      return {
        ...initialState,
      };
    case types.REMOVE_ITEM:
      return removeItem(state, action.payload.id);
    case types.EDIT_PACKAGE:
      return {
        ...state,
        modalMode: ModalMode.Edit,
        data: action.payload,
        items: action.payload.packageItems,
        packageId: action.payload.package.value,
        totalPrice: calculateTotalPrice(action.payload.packageItems),
        totalOriginalPrice: calculateOriginalTotalPrice(action.payload.packageItems),
      };
    case types.SET_VIEW_MODE:
      return {
        ...state,
        modalMode: ModalMode.View,
        items: action.payload.packageItems,
        data: action.payload,
        totalPrice: calculateTotalPrice(action.payload.packageItems),
        totalOriginalPrice: calculateOriginalTotalPrice(action.payload.packageItems),
      };

    // Default
    default:
      return state;
  }
};
