import qs from 'qs';
import { useCallback, useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { defaultValues } from 'app/constants';
import { useAppIntl } from 'app/helpers/useAppIntl/useAppIntl';
import { QueryParamsState } from 'app/redux/queryParams/types';
import { setQueryParams } from 'app/redux/queryParams/queryParams';
import { useAppDispatch, useAppSelector } from 'app/helpers/useTypedHooks/useTypedHooks';

export const useQueryParams = <T extends keyof QueryParamsState>(name: T, syncURLParams: boolean = false) => {
  const { locale } = useAppIntl();
  const history = useHistory();
  const { search } = useLocation();
  const dispatch = useAppDispatch();
  const [initialized, setInitialized] = useState<boolean>(false);
  const params = useAppSelector((state) => state.queryParams[name]);

  const updateUrlParams = useCallback(
    (search: string) => {
      if (search.length < defaultValues.limits.searchLength) {
        // Update URL params if search length is
        history.replace({ search });
      }
    },
    [history],
  );

  const setParams = useCallback(
    (params?: QueryParamsState[T]) => {
      // Update query params (Redux state)
      dispatch(setQueryParams({ name, params }));

      if (syncURLParams) {
        updateUrlParams(qs.stringify(params));
      }
    },
    [dispatch, name, syncURLParams, updateUrlParams],
  );

  useEffect(() => {
    if (!initialized && syncURLParams) {
      const urlParams = qs.parse(search, { ignoreQueryPrefix: true });

      if (!search) {
        // Update URL params with existing params, locale and reset base parameters
        updateUrlParams(qs.stringify({ ...params, ...defaultValues.paginationQueryParams, locale }));
      } else if (search && !urlParams.locale) {
        // Add locale to URL params
        updateUrlParams(qs.stringify({ ...urlParams, locale }));
      }
    }

    setInitialized(true);
  }, [initialized, locale, params, search, syncURLParams, updateUrlParams]);

  return { params, setParams };
};
