import { InjectedFormProps } from 'redux-form/lib/reduxForm';
import { BaseFieldProps, Field, Form, reduxForm } from 'redux-form';
import { Option } from 'app/types';
import { useAppIntl, useAppSelector } from 'app/helpers';
import { FORMS_CREDIT_NOTE_PACKAGE_ACTIONS, required } from 'app/shared';
import { ChipsField, ChipsFieldProps } from 'app/shared/form-elements/chips/chipsField';
import {
  CreditNotePackageActionsField,
  CreditNotePackageActionsFormData,
} from 'app/features/invoicingProcess/modals/creditNotePackageActionsModal/creditNotePackageActionsForm/types';

const FormComponent = ({ handleSubmit }: InjectedFormProps<CreditNotePackageActionsFormData>) => {
  const { formatMessage } = useAppIntl();
  const packageActionOptions: Option[] = useAppSelector(
    (state) => state.dictionaries.data['package-actions-after-credit-note'] || [],
  );
  return (
    <Form onSubmit={handleSubmit}>
      <Field<BaseFieldProps<ChipsFieldProps>>
        name={CreditNotePackageActionsField.packageAction}
        component={ChipsField}
        props={{
          chips: packageActionOptions,
          isRequired: true,
          label: formatMessage({ id: 'CORE.TEXT.WHAT-TO-DO-AFTER-RETURNING-PACKAGE-ITEMS' }),
        }}
        validate={[required]}
      />
    </Form>
  );
};

export const CreditNotePackageActionsForm = reduxForm<CreditNotePackageActionsFormData>({
  form: FORMS_CREDIT_NOTE_PACKAGE_ACTIONS,
})(FormComponent);
