import Nouislider, { NouisliderProps } from 'nouislider-react';
import { useAppIntl } from 'app/helpers';

import 'nouislider/distribute/nouislider.css';
import './slider.scss';

export const Slider = (props: NouisliderProps) => {
  const { isRtl } = useAppIntl();
  const direction = isRtl ? 'rtl' : 'ltr';

  return <Nouislider behaviour="tap-drag" connect direction={direction} {...props} />;
};
