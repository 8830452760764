import classnames from 'classnames';
import { FormattedMessage } from 'react-intl';
import { WrappedFieldProps } from 'redux-form/lib/Field';
import { FormFeedback, FormGroup, Input } from 'reactstrap';
import { SwitchComponentLabel } from 'app/shared/form-elements/switch/switchComponentLabel/switchComponentLabel';

export interface SwitchComponentProps {
  className?: string;
  disabled?: boolean;
  formGroupClassName?: string;
  isRequired?: boolean;
  label: string;
  labelPosition?: 'top' | 'inline';
}

export const SwitchComponent = ({ labelPosition = 'inline', ...props }: WrappedFieldProps & SwitchComponentProps) => {
  const inputId = props.input.name;
  const invalid = !!props.meta.error && props.meta.touched;

  return (
    <div className={classnames('mb-3', props.className)}>
      {labelPosition === 'top' && (
        <SwitchComponentLabel label={props.label} isRequired={props.isRequired} inputId={inputId} />
      )}
      <FormGroup switch className={props.formGroupClassName}>
        {labelPosition === 'inline' && (
          <SwitchComponentLabel label={props.label} isRequired={props.isRequired} inputId={inputId} />
        )}
        <Input
          checked={props.input.value}
          disabled={props.disabled}
          id={inputId}
          name={props.input.name}
          onChange={props.input.onChange}
          role="switch"
          type="checkbox"
        />
      </FormGroup>
      {invalid && (
        <FormFeedback className="d-block">
          <FormattedMessage id={props.meta.error} />
        </FormFeedback>
      )}
    </div>
  );
};
