import {
  AddGuardianToDependentFormData,
  AddGuardianToDependentFormField,
} from 'app/features/patient/addGuardianToDependentModal/form/types';

export const formValuesAdapter = (values: AddGuardianToDependentFormData) => ({
  searchPhoneNumber: values?.[AddGuardianToDependentFormField.mobileNumber],
  searchClinicyMrn: values?.[AddGuardianToDependentFormField.fileNumber],
  searchGovernmentIssuedId: values?.[AddGuardianToDependentFormField.governmentId],
});
