export const FETCH_TABLE_ITEMS_REQUEST = 'calendar/FETCH_TABLE_ITEMS_REQUEST';
export const FETCH_TABLE_ITEMS_SUCCESS = 'calendar/FETCH_TABLE_ITEMS_SUCCESS';
export const FETCH_TABLE_ITEMS_FAILURE = 'calendar/FETCH_TABLE_ITEMS_FAILURE';

export const FETCH_MONTH_REQUEST = 'appointment/FETCH_MONTH_REQUEST';
export const FETCH_MONTH_SUCCESS = 'appointment/FETCH_MONTH_SUCCESS';
export const FETCH_MONTH_FAILURE = 'appointment/FETCH_MONTH_FAILURE';

export const FETCH_TABLE_WEEK_REQUEST = 'calendar/FETCH_TABLE_WEEK_REQUEST';
export const FETCH_TABLE_WEEK_SUCCESS = 'calendar/FETCH_TABLE_WEEK_SUCCESS';
export const FETCH_TABLE_WEEK_FAILURE = 'calendar/FETCH_TABLE_WEEK_FAILURE';

export const FETCH_WEEKS_REQUEST = 'calendar/FETCH_WEEKS_REQUEST';
export const FETCH_WEEKS_SUCCESS = 'calendar/FETCH_WEEKS_SUCCESS';
export const FETCH_WEEKS_FAILURE = 'calendar/FETCH_WEEKS_FAILURE';

export const FETCH_DOCTORS_REQUEST = 'calendar/FETCH_DOCTORS_REQUEST';
export const FETCH_DOCTORS_SUCCESS = 'calendar/FETCH_DOCTORS_SUCCESS';
export const FETCH_DOCTORS_FAILURE = 'calendar/FETCH_DOCTORS_FAILURE';

export const SET_CALENDAR_TYPE = 'calendar/SET_CALENDAR_TYPE';
export const SET_TABLE_ITEMS_PARAMS = 'calendar/SET_TABLE_ITEMS_PARAMS';

export const SELECT_EVENT = 'calendar/SELECT_EVENT';
export const SELECT_DATE = 'calendar/SELECT_DATE';
export const SELECT_TABLE_VIEW_DAY = 'calendar/SELECT_TABLE_VIEW_DAY';
export const SELECT_SLOT = 'calendar/SELECT_SLOT';
export const SELECT_WAITING_SLOT = 'calendar/SELECT_WAITING_SLOT';
export const SELECT_WORKING_HOURS = 'calendar/SELECT_WORKING_HOURS';

export const SWITCH_NO_TIME_ITEM = 'calendar/SWITCH_NO_TIME_ITEM';

export const RESET_TABLE_VIEW_DAY = 'calendar/RESET_TABLE_VIEW_DAY';
export const RESET = 'calendar/RESET';

export const WS_UPDATE_CALENDAR = 'calendar/WS_UPDATE_CALENDAR';
