import { put } from 'redux-saga/effects';
import { PayloadAction } from '@reduxjs/toolkit';
import { WsRefreshPayload } from 'app/sagas/assessment/types';
import { updateInsuranceBillingItemStatuses } from 'app/redux/doctorAssessment/doctorAssessment.actions';

export function* fetchCoveredBillingItemsStatuses({ payload }: PayloadAction<WsRefreshPayload>) {
  const { patientInsuranceId, body, appointmentId } = payload;

  if (patientInsuranceId) {
    yield put<any>(updateInsuranceBillingItemStatuses(appointmentId, patientInsuranceId, body));
  }
}
