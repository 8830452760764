import classNames from 'classnames';
import { IconCheck } from '@tabler/icons-react';
import { getTranslation } from 'app/helpers';
import { Locale, Option } from 'app/types';
import styles from 'app/components/chips/chips.module.scss';

interface Props {
  chipClassName?: string;
  chips: Option<string | number>[];
  className?: string;
  invalid?: boolean;
  onClick: (chip: Option<string | number>) => void;
  selectedChips: Option<string | number>['value'][];
  locale?: Locale;
}

export const Chips = (props: Props) => {
  return (
    <div className={classNames(styles.chips, props.className)}>
      {props.chips.map((chip) => {
        const isSelected = props.selectedChips.some((value) => value === chip.value);

        return (
          <div
            className={classNames(styles.chip, props.chipClassName, {
              [styles.selectedChip]: isSelected,
              [styles.invalidChip]: props.invalid,
            })}
            key={chip.value}
            onClick={() => props.onClick(chip)}
          >
            {getTranslation(chip, props.locale) || chip.label}
            <IconCheck className={styles.icon} size={18} strokeWidth={3} />
          </div>
        );
      })}
    </div>
  );
};
