import { useCallback } from 'react';
import { useAppDispatch, useAppSelector } from 'app/helpers';
import {
  FORMS_INVOICING_PROCESS_DECLARATION,
  FORMS_INVOICING_PROCESS_PAYMENT_METHOD_CFG,
  FORMS_INVOICING_PROCESS_PAYMENT_MODE,
} from 'app/shared';
import { useInvoicingProcessState } from 'app/features/invoicingProcess/process/helpers';
import { checkSteps } from 'app/features/invoicingProcess/process/actions/helpers/checkSteps';
import { bodyAdapter } from 'app/features/invoicingProcess/process/actions/helpers/bodyAdapter';
import { PaymentModeFormData } from 'app/features/invoicingProcess/process/paymentMode/form/types';
import { DeclarationFormData } from 'app/features/invoicingProcess/process/items/declarationForm/types';
import { PaymentConfigurationFormData } from 'app/features/invoicingProcess/process/paymentMethod/paymentConfigurationForm/types';
import {
  createInvoiceWithAppointment,
  createInvoiceWithoutAppointment,
} from 'app/redux/invoicingProcess/invoicingProcess.actions';

export const useOnCreate = () => {
  const dispatch = useAppDispatch();
  const { mode, step, invoicePackageSnapshotId, requestId: invoiceRequestId } = useInvoicingProcessState();
  const items = useAppSelector((state) => state.invoicingProcess.items);
  const invoicingProcess = useAppSelector((state) => state.invoicingProcess.data);
  const appointmentId = useAppSelector((state) => state.invoicingProcess.appointmentId);
  const createLoading = useAppSelector((state) => state.invoicingProcess.createLoading);
  const paymentMethodItems = useAppSelector((state) => state.invoicingProcess.paymentMethodItems);
  const creditNotePayments = useAppSelector(
    (state) => state.invoicingProcess.data?.steps?.paymentMethod?.creditNotePayments || [],
  );
  const paymentModeValues = useAppSelector((state) => state.form[FORMS_INVOICING_PROCESS_PAYMENT_MODE]?.values) as
    | PaymentModeFormData
    | undefined;
  const paymentConfigurationValues = useAppSelector(
    (state) => state.form[FORMS_INVOICING_PROCESS_PAYMENT_METHOD_CFG]?.values,
  ) as PaymentConfigurationFormData | undefined;
  const declarationValues = useAppSelector((state) => state.form[FORMS_INVOICING_PROCESS_DECLARATION]?.values) as
    | DeclarationFormData
    | undefined;

  const onCreate = useCallback(() => {
    if (invoicingProcess && items && mode && step && paymentConfigurationValues && paymentModeValues) {
      const doctorId = invoicingProcess.doctor.value;
      const isPrimaryCareSpeciality = invoicingProcess.doctor.details?.isPrimaryCareSpeciality;
      const patientId = invoicingProcess.patient?.id;
      const guardianPatientId = invoicingProcess.guardianPatient?.id;
      const branchId = invoicingProcess.clinicBranch.value;
      const draftInvoiceId = invoicingProcess.draftInvoiceId;
      const invoiceStatus = invoicingProcess.invoiceStatus?.value;
      const invoiceType = invoicingProcess.invoiceType?.value;

      const isValid = checkSteps({
        appointmentId,
        creditNotePayments,
        dispatch,
        invoiceStatus,
        invoiceType,
        invoicingProcess,
        isPrimaryCareSpeciality,
        items,
        mode,
        paymentConfigurationValues,
        paymentMethodItems,
        paymentModeValues,
        step,
      });

      if (isValid) {
        const body = bodyAdapter({
          declarationValues,
          draftInvoiceId,
          guardianPatientId,
          invoicePackageSnapshotId,
          invoiceRequestId,
          invoiceType,
          isPrimaryCareSpeciality,
          items,
          paymentConfigurationValues,
          paymentMethodItems,
          paymentModeValues,
        });

        if (appointmentId) {
          return dispatch(createInvoiceWithAppointment(appointmentId, body));
        } else if (branchId && doctorId && patientId) {
          return dispatch(createInvoiceWithoutAppointment(branchId, doctorId, patientId, body));
        }
      }
    }
  }, [
    appointmentId,
    creditNotePayments,
    declarationValues,
    dispatch,
    invoicePackageSnapshotId,
    invoiceRequestId,
    invoicingProcess,
    items,
    mode,
    paymentConfigurationValues,
    paymentMethodItems,
    paymentModeValues,
    step,
  ]);

  return {
    createLoading,
    onCreate,
  };
};
