import { ColumnFormatter } from 'react-bootstrap-table-next';
import { formattedDate } from 'app/helpers';

export const dateOfBirthFormatter: ColumnFormatter<{ dateOfBirth?: string; age?: number }> = (
  _,
  data,
  __,
  { formatMessage },
) => {
  if (data.dateOfBirth) {
    return (
      <>
        <div>{formattedDate(data.dateOfBirth)}</div>
        <div>{formatMessage({ id: 'CORE.LABEL.AGE-VALUE', defaultMessage: '(Age {age})' }, { age: data.age })}</div>
      </>
    );
  }
  return '-';
};
