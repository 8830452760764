import * as types from './clinics.types';
import { PAGINATION_DEFAULT_PARAMS } from 'app/shared';

const initialState = {
  loading: false,
  error: false,
  data: [],
  dataWithBranches: [],
  params: {
    ...PAGINATION_DEFAULT_PARAMS,
    status: 'online',
  },
  dictionaries: {
    online: [],
    blocked: [],
    offline: [],
  },
};

export const clinicsReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.FETCH_CLINICS_LIST_REQUEST:
      return {
        ...state,
        data: [],
        loading: true,
      };
    case types.FETCH_CLINICS_LIST_SUCCESS:
      return {
        ...state,
        data: action.payload.data,
        dataWithBranches: action.payload.data
          .filter((clinic) => clinic.numberOfRegisteredBranches > 0)
          .map((clinic) => ({
            label: clinic.nameEn,
            value: clinic.id,
            translation: { ar: clinic.nameAr, en: clinic.nameEn },
          })),
        loading: false,
        params: {
          ...state.params,
          ...action.payload.pagination,
        },
      };
    case types.FETCH_CLINICS_LIST_FAILURE:
      return {
        ...state,
        data: [],
        loading: false,
        error: true,
      };
    case types.FETCH_ALL_CLINICS_DICTIONARIES_SUCCESS:
      return {
        ...state,
        dictionaries: {
          ...state.dictionaries,
          ...action.payload,
        },
      };
    case types.SET_PARAMS:
      return {
        ...state,
        params: {
          ...state.params,
          ...action.meta,
        },
      };
    default:
      return state;
  }
};
