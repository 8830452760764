import { ReactNode } from 'react';
import classNames from 'classnames';
import BootstrapTable, {
  BootstrapTableProps,
  ColumnDescription,
  ExpandRowProps,
  PaginationOptions,
  RowEventHandlerProps,
} from 'react-bootstrap-table-next';
import paginationFactory, { PaginationProvider } from 'react-bootstrap-table2-paginator';
import { useAppIntl } from 'app/helpers';
import { DEFAULT_PAGINATION_PARAMS, Loader } from 'app/shared';
import { StickyPagination } from 'app/components';
import './localTable.scss';

interface Props {
  classes?: string;
  columns: ColumnDescription[];
  data: any[];
  expandRow?: ExpandRowProps<any>;
  id: string;
  keyField: string;
  loading: boolean;
  noDataComponent?: ReactNode;
  pagination?: PaginationOptions;
  rowClasses?: BootstrapTableProps['rowClasses'];
  rowEvents?: RowEventHandlerProps;
  wrapperClasses?: string;
}

export const LocalTable = ({
  classes,
  columns,
  data,
  expandRow,
  id,
  keyField,
  loading,
  noDataComponent,
  pagination,
  rowEvents,
  wrapperClasses,
  rowClasses,
}: Props) => {
  const { formatMessage } = useAppIntl();
  const hasItems = data.length > 0;

  return (
    <PaginationProvider
      pagination={paginationFactory({
        custom: true,
        hideSizePerPage: true,
        ...(!pagination && { sizePerPage: 1000 }),
        ...pagination,
      })}
    >
      {({ paginationProps, paginationTableProps }) => (
        <>
          <BootstrapTable
            {...paginationTableProps}
            bordered={false}
            classes={classes}
            columns={columns}
            data={data}
            id={id}
            keyField={keyField}
            rowClasses={rowClasses}
            rowEvents={rowEvents}
            expandRow={expandRow}
            wrapperClasses={classNames(
              'remoteTable table-responsive',
              {
                'remoteTable--loading': loading,
                'remoteTable--empty': !loading && !hasItems,
              },
              wrapperClasses,
            )}
          />

          {/* Initial loader */}
          {loading && !hasItems && <Loader />}

          {/* No data message */}
          {!loading && !hasItems ? (
            noDataComponent ? (
              noDataComponent
            ) : (
              <div className="fw-medium text-center py-4">{formatMessage({ id: 'CORE.LABEL.NO-DATA' })}</div>
            )
          ) : null}

          {pagination && paginationProps && (
            <StickyPagination
              loading={loading}
              onPageChange={paginationProps.onPageChange}
              onSizePerPageChange={paginationProps.onSizePerPageChange}
              page={paginationProps.page || 1}
              sizePerPage={paginationProps.sizePerPage || DEFAULT_PAGINATION_PARAMS.sizePerPage}
              totalSize={paginationProps.totalSize || 1}
            />
          )}
        </>
      )}
    </PaginationProvider>
  );
};
