import { AnyAction } from 'redux';
import { CalendarItem, CalendarMonth, CalendarType, Doctor, RemoteData } from 'app/types';
import { generateParams } from '../remoteData/helpers';
import {
  addToLoadingItems,
  removeFromLoadingItems,
  removeSelectedDay,
  replaceWeekDays,
  switchNoTimeItem,
  updateSelectedDays,
  updateTableViewItems,
} from './helpers';
import * as types from './calendar.types';
import {
  CalendarDoctorsParams,
  CalendarSearchParams,
  LoadingItem,
  SelectedDay,
  SelectedSlot,
  SelectedWaitingSlot,
  SelectedWorkingHours,
} from './types';

export interface CalendarState {
  doctors: RemoteData<Doctor[], CalendarDoctorsParams>;
  month: RemoteData<CalendarMonth | null>;
  selectedEvent: string | null;
  selectedSlot: SelectedSlot | null;
  selectedWaitingSlot: SelectedWaitingSlot | null;
  selectedWorkingHours: SelectedWorkingHours | null;
  tableView: {
    loadingItems: LoadingItem[];
    selectedDays: SelectedDay[];
    withNoTimeItems: string[];
  };
  tableViewItems: RemoteData<CalendarItem[], CalendarSearchParams>;
  type: CalendarType;
}

const initialState: CalendarState = {
  // General Fields
  selectedEvent: null,
  selectedSlot: null,
  selectedWaitingSlot: null,
  selectedWorkingHours: null,
  type: CalendarType.Normal,

  // Doctors
  doctors: {
    data: [],
    loading: false,
  },

  // Month
  month: {
    data: null,
    loading: false,
  },

  // Table View
  tableView: {
    loadingItems: [],
    selectedDays: [],
    withNoTimeItems: [],
  },

  // Table View Items
  tableViewItems: {
    data: [],
    loading: false,
  },
};

export const calendarReducer = (state = initialState, action: AnyAction): CalendarState => {
  const isSilent: boolean | undefined = action.meta?.isSilent;

  switch (action.type) {
    case types.FETCH_TABLE_ITEMS_REQUEST:
      return {
        ...state,
        tableViewItems: {
          ...state.tableViewItems,
          loading: true,
        },
        tableView: {
          ...state.tableView,
          selectedDays: [],
        },
      };
    case types.FETCH_TABLE_ITEMS_SUCCESS:
      return {
        ...state,
        tableViewItems: {
          ...state.tableViewItems,
          data: action.payload.data,
          params: generateParams(action),
          loading: false,
        },
      };
    case types.FETCH_TABLE_ITEMS_FAILURE:
      return {
        ...state,
        tableViewItems: {
          ...state.tableViewItems,
          loading: false,
        },
      };
    // Month
    case types.FETCH_MONTH_REQUEST:
      return {
        ...state,
        ...(!isSilent && {
          month: {
            ...initialState.month,
            loading: true,
          },
        }),
      };
    case types.FETCH_MONTH_SUCCESS:
      return {
        ...state,
        month: {
          data: action.payload,
          loading: false,
        },
      };
    case types.FETCH_MONTH_FAILURE:
      return {
        ...state,
        month: initialState.month,
      };
    case types.FETCH_TABLE_WEEK_REQUEST:
      return {
        ...state,
        tableView: {
          ...state.tableView,
          loadingItems: addToLoadingItems(state.tableView.loadingItems, action.meta.params),
          selectedDays: removeSelectedDay(state.tableView.selectedDays, action.meta.params),
        },
      };
    case types.FETCH_TABLE_WEEK_SUCCESS:
      return {
        ...state,
        tableViewItems: {
          ...state.tableViewItems,
          data: replaceWeekDays(state.tableViewItems.data, action.payload),
        },
        tableView: {
          ...state.tableView,
          loadingItems: removeFromLoadingItems(state.tableView.loadingItems, action.meta.params),
        },
      };
    case types.FETCH_TABLE_WEEK_FAILURE:
      return {
        ...state,
        tableView: {
          ...state.tableView,
          loadingItems: removeFromLoadingItems(state.tableView.loadingItems, action.meta.params),
        },
      };
    case types.FETCH_WEEKS_SUCCESS:
      return {
        ...state,
        tableViewItems: {
          ...state.tableViewItems,
          data: updateTableViewItems(state.tableViewItems.data, action.payload, action.meta.params),
        },
      };
    case types.FETCH_DOCTORS_REQUEST:
      return {
        ...state,
        doctors: {
          ...state.doctors,
          loading: true,
        },
      };
    case types.FETCH_DOCTORS_SUCCESS:
      return {
        ...state,
        doctors: {
          ...state.doctors,
          data: action.payload.data,
          params: generateParams(action),
          loading: false,
        },
      };
    case types.FETCH_DOCTORS_FAILURE:
      return {
        ...state,
        doctors: {
          ...state.doctors,
          loading: false,
        },
      };
    case types.SET_CALENDAR_TYPE:
      return {
        ...state,
        type: action.payload,
      };
    case types.SET_TABLE_ITEMS_PARAMS:
      return {
        ...state,
        tableViewItems: {
          ...state.tableViewItems,
          params: action.payload,
        },
      };
    case types.SELECT_TABLE_VIEW_DAY:
      return {
        ...state,
        tableView: {
          ...state.tableView,
          selectedDays: updateSelectedDays(state.tableView.selectedDays, action.payload),
        },
      };
    case types.RESET_TABLE_VIEW_DAY:
      return {
        ...state,
        tableView: {
          ...state.tableView,
          selectedDays: [],
        },
      };
    case types.SELECT_EVENT:
      return {
        ...state,
        selectedEvent: action.payload,
      };
    case types.SELECT_SLOT:
      return {
        ...state,
        selectedSlot: action.payload,
      };
    case types.SELECT_WAITING_SLOT:
      return {
        ...state,
        selectedWaitingSlot: action.payload,
      };
    case types.SELECT_WORKING_HOURS:
      return {
        ...state,
        selectedWorkingHours: action.payload,
      };
    case types.SWITCH_NO_TIME_ITEM:
      return {
        ...state,
        tableView: {
          ...state.tableView,
          withNoTimeItems: switchNoTimeItem(state.tableView.withNoTimeItems, action.payload),
        },
      };

    case types.RESET:
      return initialState;
    default:
      return state;
  }
};
