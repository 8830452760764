import { useParams } from 'react-router-dom';
import { useCallback, useEffect, useState } from 'react';
import { useAppDispatch, useAppIntl, useAppSelector, useCurrentUser } from 'app/helpers';
import { download } from 'app/redux/downloads/downloads.actions';
import { fetchCreditNoteShortInfo } from 'app/redux/invoice/invoice.actions';
import { DownloadDocumentPage } from 'app/historicalDocuments/components/downloadDocumentPage/downloadDocumentPage';

export const HistoricalCreditNote = () => {
  const dispatch = useAppDispatch();
  const { token } = useCurrentUser();
  const { formatMessage, locale } = useAppIntl();
  const { creditNoteId } = useParams<{ creditNoteId: string }>();
  const [downloading, setDownloading] = useState<boolean>(false);
  const loading = useAppSelector((state) => state.invoice.shortInfo.loading);
  const creditNoteShortInfo = useAppSelector((state) => state.invoice.shortInfo.creditNote);

  const downloadCreditNote = useCallback(async () => {
    if (token) {
      setDownloading(true);
      await dispatch(
        download({
          token,
          locale,
          endpoint: `credit-notes/${creditNoteId}/print-view`,
          withOpening: false,
          body: false,
        }),
      );
      setDownloading(false);
    }
  }, [dispatch, creditNoteId, locale, token]);

  useEffect(() => {
    if (token) {
      dispatch(fetchCreditNoteShortInfo(creditNoteId));
    }
  }, [dispatch, creditNoteId, token]);

  return (
    <DownloadDocumentPage
      downloading={downloading}
      loading={loading}
      number={creditNoteShortInfo?.creditNoteNumber}
      onDownload={downloadCreditNote}
      title={formatMessage({ id: 'CORE.TEXT.CREDIT-NOTE', defaultMessage: 'Credit Note' })}
    />
  );
};
