import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQueryWithReAuth } from 'app/helpers';
import {
  QueryParams,
  QueryResponseWithPagination,
  PatientSource,
  PatientSourcesAction,
  QueryResponse,
  DetailedPatientSource,
  PatientSourceAction,
  PatientSourceOption,
} from 'app/types';
import {
  AddPatientSourceParams,
  ExtendPatientSourceParams,
  GetPatientSourceActionsParams,
  ImportPatientSourcesParams,
  PatientSourceActionParams,
  PatientSourceOptionParams,
} from 'app/services/patientSources/types';

export const patientSourcesApi = createApi({
  reducerPath: 'patientSourcesApi',
  baseQuery: baseQueryWithReAuth,
  tagTypes: ['PatientSources', 'PatientSourcesActionsLog', 'PatientSourceActionsLog', 'DetailedPatientSource'],
  endpoints: (builder) => ({
    getPatientSourcesActions: builder.query<
      QueryResponseWithPagination<PatientSourcesAction[]>,
      GetPatientSourceActionsParams
    >({
      query: ({ clinicId, params }) => ({
        url: `clinics/${clinicId}/patient-sources/action-logs`,
        method: 'GET',
        params,
      }),
      providesTags: ['PatientSourcesActionsLog'],
    }),

    importPatientSources: builder.mutation<void, ImportPatientSourcesParams>({
      query: ({ clinicId, body }) => ({
        url: `clinics/${clinicId}/patient-sources/mass-adding`,
        method: 'POST',
        body,
      }),
      invalidatesTags: ['PatientSources'],
    }),

    addPatientSource: builder.mutation<void, AddPatientSourceParams>({
      query: ({ clinicId, body }) => ({
        url: `clinics/${clinicId}/patient-sources`,
        method: 'POST',
        body,
      }),
      invalidatesTags: ['PatientSources'],
    }),

    getPatientSources: builder.query<
      QueryResponseWithPagination<PatientSource[]>,
      { clinicId: string; params?: QueryParams }
    >({
      query: ({ clinicId, params }) => ({
        url: `clinics/${clinicId}/patient-sources`,
        method: 'GET',
        params,
      }),
      providesTags: ['PatientSources'],
    }),

    activatePatientSource: builder.mutation<void, PatientSourceActionParams>({
      query: ({ clinicId, patientSourceId }) => ({
        url: `clinics/${clinicId}/patient-sources/${patientSourceId}/activate`,
        method: 'PUT',
      }),
      invalidatesTags: ['PatientSources', 'DetailedPatientSource'],
    }),

    pausePatientSource: builder.mutation<void, PatientSourceActionParams>({
      query: ({ clinicId, patientSourceId }) => ({
        url: `clinics/${clinicId}/patient-sources/${patientSourceId}/pause`,
        method: 'PUT',
      }),
      invalidatesTags: ['PatientSources', 'DetailedPatientSource'],
    }),

    terminatePatientSource: builder.mutation<void, PatientSourceActionParams>({
      query: ({ clinicId, patientSourceId }) => ({
        url: `clinics/${clinicId}/patient-sources/${patientSourceId}/terminate`,
        method: 'PUT',
      }),
      invalidatesTags: ['PatientSources', 'DetailedPatientSource'],
    }),

    deletePatientSource: builder.mutation<void, PatientSourceActionParams>({
      query: ({ clinicId, patientSourceId }) => ({
        url: `clinics/${clinicId}/patient-sources/${patientSourceId}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['PatientSources', 'DetailedPatientSource'],
    }),

    extendPatientSource: builder.mutation<void, ExtendPatientSourceParams>({
      query: ({ clinicId, patientSourceId, body }) => ({
        url: `clinics/${clinicId}/patient-sources/${patientSourceId}/extend`,
        method: 'PUT',
        body,
      }),
      invalidatesTags: ['PatientSources', 'DetailedPatientSource'],
    }),

    getPatientSourcesReport: builder.query<void, { clinicId: string; params?: QueryParams }>({
      query: ({ clinicId, params }) => ({
        url: `clinics/${clinicId}/patient-sources/excel`,
        params,
      }),
    }),

    getDetailedPatientSource: builder.query<
      QueryResponse<DetailedPatientSource>,
      { clinicId: string; patientSourceId: string }
    >({
      query: ({ clinicId, patientSourceId }) => ({
        url: `clinics/${clinicId}/patient-sources/${patientSourceId}`,
        method: 'GET',
      }),
      providesTags: ['DetailedPatientSource'],
    }),

    getPatientSourceActions: builder.query<
      QueryResponseWithPagination<PatientSourceAction[]>,
      GetPatientSourceActionsParams
    >({
      query: ({ clinicId, patientSourceId, params }) => ({
        url: `clinics/${clinicId}/patient-sources/action-logs`,
        method: 'GET',
        params: {
          ...params,
          patientSourceId,
        },
      }),
      providesTags: ['PatientSourceActionsLog'],
    }),
    getPatientSourceOptions: builder.query<
      QueryResponseWithPagination<PatientSourceOption[]>,
      { clinicId: string; params: PatientSourceOptionParams }
    >({
      query: ({ clinicId, params }) => ({
        url: `/clinics/${clinicId}/patient-sources/dropdown`,
        method: 'GET',
        params,
      }),
    }),
  }),
});

export const {
  useGetPatientSourcesActionsQuery,
  useImportPatientSourcesMutation,
  useAddPatientSourceMutation,
  useGetPatientSourcesQuery,
  useActivatePatientSourceMutation,
  usePausePatientSourceMutation,
  useTerminatePatientSourceMutation,
  useDeletePatientSourceMutation,
  useExtendPatientSourceMutation,
  useLazyGetPatientSourcesReportQuery,
  useGetDetailedPatientSourceQuery,
  useGetPatientSourceActionsQuery,
  useLazyGetPatientSourceOptionsQuery,
} = patientSourcesApi;
