import { checkPrefix } from '../helpers/checkPrefix';

export const required = (value: any): string | undefined => {
  const errorMessage = 'ERRORS.REQUIRED';

  if (checkPrefix(value)) {
    value = value?.value;
  }

  if (Array.isArray(value)) {
    return value.length === 0 ? errorMessage : undefined;
  }

  if (typeof value === 'string') {
    return value.trim() === '' ? errorMessage : undefined;
  }

  return typeof value === 'undefined' || value === null ? errorMessage : undefined;
};
