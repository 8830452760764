import { useCallback, useMemo, useState } from 'react';
import { formatDateToTime, getApiDateAndTime, useAppDispatch, useAppIntl } from 'app/helpers';
import { checkFreeSlotInTimeRange } from 'app/redux/appointment/appointment.actions';
import { WaitingAppointmentFormData } from 'app/appointment/form/types';

type ResponseData = {
  isPossible: boolean;
  timeFrom: string | null;
  timeTo: string | null;
};

export const useTimeRangeConfirmation = (form: string, onSubmit: any, isPhantom: boolean) => {
  const dispatch = useAppDispatch();
  const { formatMessage } = useAppIntl();
  const [confirmationModal, setConfirmationModal] = useState(false);
  const [confirmationValues, setConfirmationValues] = useState<{ from: string; to: string } | null>(null);

  // Actions
  const openConfirmationModal = useCallback(() => setConfirmationModal(true), []);
  const closeConfirmationModal = useCallback(() => setConfirmationModal(false), []);

  const checkTimeRange = useCallback(
    async (values: WaitingAppointmentFormData) => {
      if (isPhantom) {
        const res = await dispatch(
          checkFreeSlotInTimeRange({
            from: getApiDateAndTime(values.preferredTimeRange.from),
            productId: values.product.value,
            to: getApiDateAndTime(values.preferredTimeRange.to),
            workingTimeId: values.workingTime.value,
          }),
        );

        // @ts-ignore
        const resData: ResponseData | undefined = res?.payload?.data;

        if (resData && resData.isPossible && resData.timeFrom && resData.timeTo) {
          setConfirmationValues({ from: resData.timeFrom, to: resData.timeTo });
          setConfirmationModal(true);
        } else {
          await onSubmit(values);
        }
      } else {
        await onSubmit(values);
      }
    },
    [dispatch, isPhantom, onSubmit],
  );

  // Values
  const confirmationMessage = useMemo(
    () =>
      formatMessage(
        { id: 'CORE.TEXT.PHANTOM-WAITING-CONFIRMATION' },
        {
          from: confirmationValues ? formatDateToTime(confirmationValues.from) : '',
          to: confirmationValues ? formatDateToTime(confirmationValues.to) : '',
        },
      ),
    [confirmationValues, formatMessage],
  );

  return {
    checkTimeRange,
    closeConfirmationModal,
    confirmationMessage,
    confirmationModal,
    openConfirmationModal,
  };
};
