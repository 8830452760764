import { useMemo } from 'react';
import sumBy from 'lodash/sumBy';
import { ColumnDescription } from 'react-bootstrap-table-next';
import { FormattedPrice } from 'app/components';
import { FORMATS_DATE_AND_TIME } from 'app/shared';
import type { InvoicePaymentMethodItem } from 'app/types';
import { formattedDate, useAppIntl, useAppSelector, useCurrentUser } from 'app/helpers';
import type { FormatExtraData } from 'app/features/invoicingProcess/process/paymentMethod/types';
import { MethodFormatter } from 'app/features/invoicingProcess/process/paymentMethod/methodFormatter/methodFormatter';
import { ActionsFormatter } from 'app/features/invoicingProcess/process/paymentMethod/actionsFormatter/actionsFormatter';

interface Params {
  deletePaymentMethod: FormatExtraData['deletePaymentMethod'];
  editPaymentMethod: FormatExtraData['editPaymentMethod'];
}

export const usePaymentsColumns = ({ deletePaymentMethod, editPaymentMethod }: Params) => {
  const { isViewer } = useCurrentUser();
  const { formatMessage, locale } = useAppIntl();
  const paymentMethodItems = useAppSelector((state) => state.invoicingProcess.paymentMethodItems);

  const totalAmount = useMemo<number>(
    () => sumBy<InvoicePaymentMethodItem>(paymentMethodItems, (m) => m.amount),
    [paymentMethodItems],
  );

  return useMemo<ColumnDescription<InvoicePaymentMethodItem, FormatExtraData>[]>(
    () => [
      {
        dataField: 'method',
        footer: formatMessage({ id: 'CORE.TABLE.TOTAL' }),
        formatExtraData: { formatMessage, locale },
        formatter: MethodFormatter,
        style: { width: '25%', minWidth: '180px' },
        text: formatMessage({ id: 'CORE.LABEL.PAYMENT-METHOD', defaultMessage: 'Payment Method' }),
      },
      {
        classes: 'text-nowrap',
        dataField: 'amount',
        footer: true,
        footerClasses: 'text-nowrap',
        footerFormatter: () => <FormattedPrice amount={totalAmount} />,
        formatter: (_, row) => <FormattedPrice amount={row.amount} />,
        style: { width: '20%', minWidth: '180px' },
        text: formatMessage({ id: 'CORE.LABEL.AMOUNT', defaultMessage: 'Amount' }),
      },
      {
        dataField: 'timeOfCreation',
        footer: false,
        formatter: (_, row) => formattedDate(row.timeOfCreation, FORMATS_DATE_AND_TIME),
        style: { minWidth: '180px' },
        text: formatMessage({ id: 'CORE.TABLE.DATE-AND-TIME', defaultMessage: 'Note' }),
      },
      {
        dataField: 'notes',
        footer: false,
        formatter: (_, row) => row.notes || '-',
        style: { minWidth: '180px' },
        text: formatMessage({ id: 'CORE.LABEL.NOTE', defaultMessage: 'Note' }),
      },
      ...(!isViewer
        ? [
            {
              classes: 'text-center sticky sticky--end',
              dataField: 'actions',
              footer: true,
              footerClasses: 'sticky sticky--end',
              formatter: ActionsFormatter,
              formatExtraData: { deletePaymentMethod, editPaymentMethod },
              headerClasses: 'text-center sticky sticky--end',
              text: formatMessage({ id: 'CORE.TABLE.ACTIONS' }),
            },
          ]
        : []),
    ],
    [deletePaymentMethod, editPaymentMethod, formatMessage, isViewer, locale, totalAmount],
  );
};
