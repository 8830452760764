import moment from 'moment';
import { useCallback } from 'react';
import { Button } from 'reactstrap';
import { formattedDate, getApiDateAndTime, useAppDispatch, useAppIntl, useAppSelector } from 'app/helpers';
import { CalendarItem } from 'app/types';
import { fetchTableViewWeek } from 'app/redux/calendar/calendar.actions';
import { IconChevronLeft, IconChevronRight } from '@tabler/icons-react';
import { Picker } from 'app/bookAppointment/bookAppointmentWizard/shared/dateFormatter/dateFormatterHeader/picker/picker';
import { generateCalendarWeekParams } from 'app/bookAppointment/bookAppointmentWizard/helpers';

interface Props {
  calendarItem: CalendarItem;
  loading: boolean;
}

export const DateFormatterHeader = ({ calendarItem, loading }: Props) => {
  const dispatch = useAppDispatch();
  const { formatMessage } = useAppIntl();

  // Selectors
  const params = useAppSelector((state) => state.calendar.tableViewItems.params);
  const isWizardOpen = useAppSelector((state) => state.modal.bookAppointment.isOpen);

  // Variables
  const firstDayDate = calendarItem.days[0]?.date;
  const lastDayDate = calendarItem.days?.[calendarItem.days.length - 1]?.date;
  const isCurrentWeek = moment(firstDayDate).isSame(moment(), 'week');
  const hasPrevAction = !loading && moment(firstDayDate) > moment();
  const hasNextAction = !loading;

  // Actions
  const changeWeek = useCallback(
    (actionType: 'prev' | 'next') => {
      if (params) {
        const date =
          actionType === 'prev'
            ? moment(lastDayDate).subtract(1, 'week').toISOString()
            : moment(lastDayDate).add(1, 'week').toISOString();

        dispatch(
          fetchTableViewWeek(
            generateCalendarWeekParams(
              params,
              date,
              calendarItem.branch.id,
              calendarItem.doctor.id,
              calendarItem.product.value,
            ),
          ),
        );
      }
    },
    [dispatch, calendarItem.branch, calendarItem.doctor, calendarItem.product, lastDayDate, params],
  );

  const onDateChange = useCallback(
    (date: Date) => {
      if (params) {
        dispatch(
          fetchTableViewWeek(
            generateCalendarWeekParams(
              params,
              getApiDateAndTime(date),
              calendarItem.branch.id,
              calendarItem.doctor.id,
              calendarItem.product.value,
            ),
          ),
        );
      }
    },
    [dispatch, calendarItem.branch, calendarItem.doctor, calendarItem.product, params],
  );

  return (
    <div className="dateFormatter__header">
      <Button color="icon" disabled={!hasPrevAction} onClick={() => changeWeek('prev')}>
        <IconChevronLeft className="dateFormatter__actionIcon" size={22} />
      </Button>
      <div className="text-center">
        <div className="d-flex justify-content-between align-items-center">
          <h5 className="font-weight-normal m-0">
            {formattedDate(firstDayDate)} - {formattedDate(lastDayDate)}
          </h5>
          {isWizardOpen && <Picker date={firstDayDate} onChange={onDateChange} />}
        </div>
        {isCurrentWeek && <p className="mb-0">{formatMessage({ id: 'CORE.TEXT.CURRENT-WEEK' })}</p>}
      </div>
      <Button color="icon" disabled={!hasNextAction} onClick={() => changeWeek('next')}>
        <IconChevronRight className="dateFormatter__actionIcon" size={22} />
      </Button>
    </div>
  );
};
