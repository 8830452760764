import toast from 'react-hot-toast';
import { useCallback, useMemo, useState } from 'react';
import { initialize as initializeForm } from 'redux-form';
import { IntlFormatters } from '@formatjs/intl/src/types';
import { ColumnDescription } from 'react-bootstrap-table-next';
import { Badge, Button, Modal, ModalBody, ModalHeader } from 'reactstrap';
import { ActivityStatusBadge, FormattedPrice } from 'app/components';
import { getTranslation, useAppDispatch, useAppIntl, useAppSelector, useModalState } from 'app/helpers';
import { DEFAULT_PAGINATION_PARAMS, FORMS_INVOICING_PROCESS_PAYMENT_MODE, RemoteTable } from 'app/shared';
import { AvailablePackage, InvoiceDiscountType, InvoiceItem, InvoiceItemVat, Locale } from 'app/types';
import { fetchAvailablePackages } from 'app/redux/remoteData/remoteData.actions';
import {
  fetchPackageSnapshotPaymentMode,
  setStep,
  initialize,
} from 'app/redux/invoicingProcess/invoicingProcess.actions';
import { InvoicingProcess, InvoicingProcessStep } from 'app/redux/invoicingProcess/types';
import { getPaymentModeInitialValues, useInvoicingProcessState } from 'app/features/invoicingProcess/process/helpers';
import { ItemsFormatter } from 'app/features/invoicingProcess/process/components/availablePackages/formatters/itemsFormatter';
import { ActionsFormatter } from 'app/features/invoicingProcess/process/components/availablePackages/formatters/actionsFormatter';

export interface FormatExtraData {
  formatMessage?: IntlFormatters['formatMessage'];
  loadingId?: string;
  locale?: Locale;
  onIssueInvoice?: (availablePackage: AvailablePackage) => void;
  quantityType?: 'initial' | 'remaining';
}

export const AvailablePackages = () => {
  const [loadingId, setLoadingId] = useState<string | undefined>();
  const [currentAvailablePackageQuantity, setCurrentAvailablePackageQuantity] = useState<number | undefined>(); // we need this variable to cover PT-8550 (outdated availablePackageQuantity value)
  const dispatch = useAppDispatch();
  const { isCreatedAndNotCreateCreditNoteMode } = useInvoicingProcessState();
  const { formatMessage, locale } = useAppIntl();
  const invoicingProcess = useAppSelector((state) => state.invoicingProcess.data);
  const availablePackageQuantity = useAppSelector((state) => state.invoicingProcess.data?.availablePackageQuantity);
  const patientId = useAppSelector((state) => state.invoicingProcess.data?.patient?.id);
  const guardianPatientId = useAppSelector((state) => state.invoicingProcess.data?.guardianPatient?.id);
  const branchId = useAppSelector((state) => state.invoicingProcess.data?.clinicBranch.value);
  const { data, loading, params } = useAppSelector((state) => state.remoteData.availablePackages);
  const { open, close, isOpen } = useModalState();

  const fetchData = useCallback(
    async (newParams) => {
      const clinicPatientId = guardianPatientId || patientId;

      if (clinicPatientId && branchId) {
        const res = await dispatch(fetchAvailablePackages(clinicPatientId, branchId, newParams));
        if (res && !res.error) {
          setCurrentAvailablePackageQuantity(res.payload.pagination.totalItemCount);
        }
      }
    },
    [branchId, dispatch, guardianPatientId, patientId],
  );

  const openModal = useCallback(() => {
    if (patientId && branchId) {
      fetchData(DEFAULT_PAGINATION_PARAMS);
      open();
    }
  }, [branchId, fetchData, open, patientId]);

  const onIssueInvoice = useCallback(
    async (availablePackage: AvailablePackage) => {
      const paymentModeResponse = await dispatch(fetchPackageSnapshotPaymentMode(availablePackage.id));

      if (!paymentModeResponse.error && paymentModeResponse.payload && invoicingProcess) {
        setLoadingId(availablePackage.id);

        const newInvoicingProcess: InvoicingProcess = {
          ...invoicingProcess,
          activePatientInsurance: null,
          steps: {
            ...invoicingProcess.steps,
            items: null,
            paymentMethod: null,
            paymentMode: paymentModeResponse.payload.data, // overwrite paymentMode
          },
        };

        // Generate new items
        const newItems: InvoiceItem[] = availablePackage.packageItems
          .filter((item) => item.remainingQuantity > 0)
          .map((item) => ({
            billingItem: {
              label: locale === Locale.ar ? item.performerBillingItemNameAr : item.performerBillingItemNameEn,
              value: item.performerBillingItemId,
              translation: {
                ar: item.performerBillingItemNameAr,
                en: item.performerBillingItemNameEn,
              },
              details: {
                gtinCode: null,
                internalCode: item.internalCode,
                measureValue: null,
                medicalBillingNphiesCode: null,
                price: 0,
                quantity: item.remainingQuantity,
                saudisVatExempted: false,
                unitMeasure: null,
                vatExempted: false,
              },
            },
            billingItemSourceType: item.billingItemSourceType,
            discount: {
              discountTypeView: { label: '', value: InvoiceDiscountType.Amount },
              value: 0,
            },
            id: item.performerBillingItemId,
            invoicePackageItemSnapshotId: item.id,
            price: 0,
            quantity: item.remainingQuantity,
            vat: InvoiceItemVat.Default,
          }));

        // Overwrite invoicing process values
        dispatch(
          initialize({
            invoicePackageSnapshotId: availablePackage.id,
            items: newItems,
          }),
        );

        // Initialize Payment Mode form
        dispatch(
          initializeForm(FORMS_INVOICING_PROCESS_PAYMENT_MODE, getPaymentModeInitialValues(newInvoicingProcess)),
        );

        // Complete the process of selecting available packages
        setLoadingId(undefined);
        close();

        // Open Items step
        dispatch(setStep(InvoicingProcessStep.Items));

        // Show notification
        toast.success('CORE.TEXT.ITEMS-PRESELECTED');
      }
    },
    [close, dispatch, invoicingProcess, locale],
  );

  const columns = useMemo(
    (): ColumnDescription<AvailablePackage, FormatExtraData>[] => [
      {
        dataField: 'packageCode',
        text: formatMessage({ id: 'CORE.LABEL.CODE' }),
      },
      {
        dataField: 'name',
        text: formatMessage({ id: 'CORE.LABEL.NAME' }),
        formatter: (_, row) => (locale === Locale.ar ? row.packageNameAr : row.packageNameEn),
      },
      {
        dataField: 'packageItems',
        text: formatMessage({ id: 'CORE.LABEL.ITEMS' }),
        formatter: ItemsFormatter,
        formatExtraData: { locale, quantityType: 'initial' },
      },
      {
        dataField: 'remainingPackageItems',
        text: formatMessage({ id: 'CORE.LABEL.REMAINING-ITEMS' }),
        formatter: ItemsFormatter,
        formatExtraData: { locale, quantityType: 'remaining' },
      },
      {
        dataField: 'cashPrice',
        classes: 'text-nowrap',
        text: formatMessage({ id: 'CORE.LABEL.PRICE' }),
        formatter: (_, row) => <FormattedPrice amount={row.cashPrice} />,
      },
      {
        dataField: 'activatedPerformer',
        text: formatMessage({ id: 'BILLING-ITEMS-LISTS.LABEL.ACTIVATED-PERFORMERS' }),
        formatter: (_, row) => getTranslation(row.activatedPerformer, locale),
      },
      {
        dataField: 'insuranceApplied',
        text: formatMessage({ id: 'CORE.LABEL.INSURANCE-APPLIED' }),
        formatter: (_, row) => <ActivityStatusBadge status={row.insuranceApplied} className="me-1" />,
        classes: 'text-center',
        headerClasses: 'text-center',
      },
      {
        dataField: 'actions',
        text: formatMessage({ id: 'CORE.TABLE.ACTIONS' }),
        formatter: ActionsFormatter,
        formatExtraData: { loadingId, onIssueInvoice, formatMessage },
        classes: 'sticky sticky--end text-center',
        headerClasses: 'sticky sticky--end text-center',
      },
    ],
    [formatMessage, locale, onIssueInvoice, loadingId],
  );

  return (
    <>
      <Button disabled={isCreatedAndNotCreateCreditNoteMode} color="primary-gradient" onClick={openModal}>
        {formatMessage({ id: 'CORE.LABEL.AVAILABLE-PACKAGES' })}
        <Badge className="text-primary ms-2" color="white" pill>
          {currentAvailablePackageQuantity !== undefined ? currentAvailablePackageQuantity : availablePackageQuantity}
        </Badge>
      </Button>
      <hr />
      <Modal isOpen={isOpen} toggle={close} size="xl" centered>
        <ModalHeader toggle={close}>{formatMessage({ id: 'CORE.LABEL.AVAILABLE-PACKAGES' })}</ModalHeader>
        <ModalBody className="pt-0">
          <RemoteTable
            columns={columns}
            data={data}
            fetchData={fetchData}
            id="availablePakages"
            keyField="id"
            loading={loading}
            params={params}
          />
        </ModalBody>
      </Modal>
    </>
  );
};
