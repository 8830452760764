import toast from 'react-hot-toast';
import { put, select } from 'redux-saga/effects';
import { RootState } from 'app/types';
import { fetchInvoice } from 'app/redux/invoicingProcess/invoicingProcess.actions';
import { refreshInvoiceList } from 'app/sagas/invoicingProcess/generators/refreshInvoiceList';

export function* afterUpdatePaymentsSuccess() {
  const { invoicingProcess }: RootState = yield select();
  const invoiceId = invoicingProcess.data?.invoiceId;

  if (invoiceId) {
    yield put<any>(fetchInvoice(invoiceId));
    yield toast.success('CORE.TEXT.IP-PAYMENTS-UPDATED-SUCCESSFULLY');
  }

  yield refreshInvoiceList();
}
