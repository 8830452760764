import { InvoicingProcess } from 'app/redux/invoicingProcess/types';
import { PaymentConfigurationFormData } from 'app/features/invoicingProcess/process/paymentMethod/paymentConfigurationForm/types';

export const getPaymentConfigurationInitialValues = (
  invoicingProcess: InvoicingProcess,
): Partial<PaymentConfigurationFormData> | undefined => {
  const paymentMethod = invoicingProcess.steps?.paymentMethod;

  return {
    dueDate: paymentMethod?.dueDate ? new Date(paymentMethod.dueDate) : new Date(),
  };
};
