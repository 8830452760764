import toast from 'react-hot-toast';
import { change } from 'redux-form';
import { delay, put, select, takeEvery } from 'redux-saga/effects';
import { FORMS_ADD_APPOINTMENT, ROLES_DOCTOR, ROLES_MANAGER_ADMIN, ROLES_MASTER_ADMIN } from 'app/shared';
import { PatientSearchField } from 'app/shared/patientSearch/types';
import { checkAccess, getClinicIdsFromCurrent } from 'app/helpers';
import { toggleAddPatientModal, toggleModal, togglePatientMobileNumberExists } from 'app/redux/modals/modals.actions';
import {
  ADD_PATIENT_ACCOUNT_CREDIT_SUCCESS,
  ADD_PATIENT_INSURANCE_SUCCESS,
  ADD_PATIENT_SUCCESS,
  BLOCK_PATIENT_CLINICS_SUCCESS,
  BLOCK_PATIENT_SUCCESS,
  DELETE_PATIENT_INSURANCE_SUCCESS,
  EDIT_DEPENDENT_PATIENT_SUCCESS,
  EDIT_PATIENT_NUMBER_SUCCESS,
  EDIT_PATIENT_SUCCESS,
  PATIENT_NUMBER_EXISTS_FAILURE,
  UNBLOCK_PATIENT_CLINICS_SUCCESS,
  UNBLOCK_PATIENT_SUCCESS,
  VERIFY_PATIENT_NUMBER_SUCCESS,
  WITHDRAW_ACC_CREDIT_SUCCESS,
} from 'app/redux/patient/patient.types';
import {
  fetchBlockedClinics,
  fetchDependentPatient,
  fetchPatient,
  fetchPatientInsurances,
  fetchSummary,
  resetChangePatientNumber,
  setPatientAccountCreditPayments,
  setPatientWithdrawalPayments,
} from 'app/redux/patient/patient.actions';
import { ADD_PATIENT_DEPENDENT_SUCCESS, CONVERT_TO_PATIENT_SUCCESS } from 'app/redux/dependents/dependents.types';
import { fetchDependents } from 'app/redux/dependents/dependents.actions';
import { refreshPatientList } from 'app/sagas/patient/generators/refreshPatientList';

export function* afterAddPatientSuccess() {
  const {
    auth: {
      current: { roles },
    },
    modal: { bookAppointment },
    patient: { patientMobileNumber },
  } = yield select();

  // Refresh patient list
  yield refreshPatientList();

  yield put(toggleModal('addPatient', false));

  // Custom message for the Doctor user
  const notificationMessage = checkAccess([ROLES_DOCTOR], roles)
    ? 'PATIENTS.TEXT.ADD-PATIENT-TO-CLINIC-SUCCESS'
    : 'PATIENTS.TEXT.ADD-PATIENT-SUCCESS';

  yield toast.success(notificationMessage);

  //TODO IMPROVE
  //copy mobile number to add appointment form and search for patient
  if (bookAppointment.isOpen && patientMobileNumber) {
    yield put(
      change(FORMS_ADD_APPOINTMENT, PatientSearchField.selectedPhoneNumber, {
        label: patientMobileNumber,
        value: patientMobileNumber,
      }),
    );
    // yield put(searchPatient(patientMobileNumber));
  }
}

export function* afterAddPatientDependentSuccess() {
  const {
    modal: { bookAppointment },
    patient: { patientMobileNumber },
  } = yield select();

  // Refresh patient list
  yield refreshPatientList();

  //TODO IMPROVE
  //copy mobile number to add appointment form and search for dependant
  if (bookAppointment.isOpen && patientMobileNumber) {
    yield put(
      change(FORMS_ADD_APPOINTMENT, PatientSearchField.selectedPhoneNumber, {
        label: patientMobileNumber,
        value: patientMobileNumber,
      }),
    );
    // yield put(searchPatient(patientMobileNumber));
  }
}

export function* afterEditPatientSuccess({ meta }) {
  yield put(fetchPatient(meta.patientId));
  yield put(toggleModal('editPatient', false));
}

export function* afterEditDependentPatientSuccess({ meta }) {
  yield put(fetchDependentPatient(meta.patientId));
  yield put(toggleModal('editPatient', false));
}

export function* afterVerifyPatientNumberSuccess({ meta }) {
  yield put(fetchPatient(meta.patientId));
  yield put(toggleModal('editPatientNumber', false));
  yield put(resetChangePatientNumber());
}

// NOTE: toggle the patient modal only if the patient does not exist
export function* afterPatientMobileNumberExistsFails({ meta, payload }) {
  if (payload?.status === 400) {
    return;
  }

  if (meta.toggleModals) {
    yield put(togglePatientMobileNumberExists());
    yield put(toggleAddPatientModal());
  }
}

export function* afterConvertDependentToPatient({ meta }) {
  yield put(fetchDependents(meta.patientId));
}

export function* afterBlockClinic({ meta }) {
  yield put(fetchBlockedClinics(meta.patientId));
}

export function* afterPatientStatusChange({ meta }) {
  yield put(fetchPatient(meta.patientId));
}

function* afterInsuranceModification(action) {
  const {
    meta: { patientId },
  } = action;
  yield put(fetchPatientInsurances(patientId));
}

function* afterAccountCreditModification(action) {
  const {
    meta: { patientId },
  } = action;
  yield put(setPatientWithdrawalPayments([]));
  yield put(setPatientAccountCreditPayments([]));
  yield delay(3000); //TODO change to a smaller value or eliminate delay at best
  const {
    auth: { current },
  } = yield select();
  yield put(fetchSummary(patientId, { clinicIds: getClinicIdsFromCurrent(current) }));
}

function* afterEditNumberSuccess() {
  const {
    auth: {
      current: { roles },
    },
  } = yield select();

  const notificationMessage = checkAccess([ROLES_MASTER_ADMIN, ROLES_MANAGER_ADMIN], roles)
    ? 'CORE.TEXT.CHANGES-SAVED'
    : 'PATIENTS.TEXT.SEND-VERIFICATION-CODE-SUCCESS';

  yield toast.success(notificationMessage);
}

function* patientSaga() {
  yield takeEvery(ADD_PATIENT_SUCCESS, afterAddPatientSuccess);
  yield takeEvery(ADD_PATIENT_DEPENDENT_SUCCESS, afterAddPatientDependentSuccess);
  yield takeEvery(EDIT_PATIENT_SUCCESS, afterEditPatientSuccess);
  yield takeEvery(EDIT_PATIENT_NUMBER_SUCCESS, afterEditNumberSuccess);
  yield takeEvery(EDIT_DEPENDENT_PATIENT_SUCCESS, afterEditDependentPatientSuccess);
  yield takeEvery(VERIFY_PATIENT_NUMBER_SUCCESS, afterVerifyPatientNumberSuccess);
  yield takeEvery(PATIENT_NUMBER_EXISTS_FAILURE, afterPatientMobileNumberExistsFails);
  yield takeEvery(CONVERT_TO_PATIENT_SUCCESS, afterConvertDependentToPatient);
  yield takeEvery([BLOCK_PATIENT_CLINICS_SUCCESS, UNBLOCK_PATIENT_CLINICS_SUCCESS], afterBlockClinic);
  yield takeEvery([BLOCK_PATIENT_SUCCESS, UNBLOCK_PATIENT_SUCCESS], afterPatientStatusChange);
  yield takeEvery([ADD_PATIENT_INSURANCE_SUCCESS, DELETE_PATIENT_INSURANCE_SUCCESS], afterInsuranceModification);
  yield takeEvery([ADD_PATIENT_ACCOUNT_CREDIT_SUCCESS, WITHDRAW_ACC_CREDIT_SUCCESS], afterAccountCreditModification);
}

export default patientSaga;
