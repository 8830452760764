import * as types from './clinicsRejected.types';
import { PAGINATION_DEFAULT_PARAMS } from 'app/shared';

const initialState = {
  loading: false,
  error: false,
  data: [],
  params: PAGINATION_DEFAULT_PARAMS,
};

export const clinicsRejectedReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.FETCH_CLINICS_REJECTED_LIST_REQUEST:
      return {
        ...state,
        data: [],
        loading: true,
      };
    case types.FETCH_CLINICS_REJECTED_LIST_SUCCESS:
      return {
        ...state,
        data: action.payload.data,
        loading: false,
        params: {
          ...state.params,
          ...action.payload.pagination,
        },
      };
    case types.FETCH_CLINICS_REJECTED_LIST_FAILURE:
      return {
        ...state,
        data: [],
        loading: false,
        error: true,
      };
    case types.SET_PARAMS:
      return {
        ...state,
        params: {
          ...state.params,
          ...action.meta,
        },
      };
    default:
      return state;
  }
};
