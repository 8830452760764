import { Option } from 'app/types/Option';
import { Status } from 'app/types/Status';
import {
  GovernmentIssuedIdType,
  Patient,
  PatientGender,
  PatientPhoneNumber,
  PatientStatus,
  PatientType,
} from 'app/types/Patient';

export interface ClinicPatientRegistration {
  id: string;
  clinicyId: string;
  clinicyMrn: string;
  fullNameArabic: string;
  fullNameEnglish: string;
  clinicPatientCategory: Option;
  nationality: Option;
  governmentIssuedIdType: Option<GovernmentIssuedIdType>;
  governmentIssuedId: string;
  medicalInstitution: Option;
  attendanceScore: number;
  registeredBy: RegisteredByOption | null;
  invoicesTotal: number;
  upcomingAppointments: number;
  completedAppointments: number;
  previousClinicIds: string[];
  phoneNumbers: PatientPhoneNumber[];
  cidCreationDate: string;
  patientType: Option;
  matchingRelation: Option;
  verificationStatus: Option<VerificationStatus>;
  status: Option<PatientStatus>;
}

export enum VerificationStatus {
  notVerified = '0',
  verified = '1',
  requiresVerification = '2',
  idNotEnforced = '3',
}

type IdentityVerificationLog = Pick<
  Patient,
  | 'firstNameArabic'
  | 'firstNameEnglish'
  | 'fatherNameEnglish'
  | 'fatherNameArabic'
  | 'grandFatherNameArabic'
  | 'grandFatherNameEnglish'
  | 'familyNameArabic'
  | 'familyNameEnglish'
  | 'dateOfBirth'
  | 'gender'
  | 'patientType'
  | 'governmentIssuedIdType'
  | 'governmentIssuedId'
  | 'nationality'
  | 'clinic'
  | 'clinicyMrn'
>;

export interface PatientIdentityVerificationLog extends IdentityVerificationLog {
  verificationDate?: string;
  verifiedBy?: VerifiedBy;
  verifiedStatus: Option<VerificationStatus>;
  id: string;
  branch: Option;
  clinicPatientId: string;
  patientStatus: Option<PatientStatus>;
}

export interface VerifiedBy {
  id: string;
  role: Option;
  clinicyId: string;
  userName: Option;
}

export interface GlobalBookingStatistics {
  patientId: string;
  medicalInstitutions: number;
  bookedAppointments: number;
  bookedAppointmentsThroughMi: number;
  bookedAppointmentsThroughApp: number;
  canceledByPatient: number;
  canceledByPatientThroughTicket: number;
  canceledByPatientThroughMi: number;
  canceledByPatientThroughApp: number;
  appointmentConfirmation: number;
  appointmentConfirmationThroughTicket: number;
  appointmentConfirmationThroughMi: number;
  appointmentConfirmationThroughApp: number;
  editedAppointments: number;
  editedAppointmentsThroughMi: number;
  editedAppointmentsThroughApp: number;
  noShow: number;
  patientScore: number;
}

//check types after receiving data from BE
export interface ClinicDependent {
  attendanceScore: number;
  clinicPatientCategory: Option;
  clinicPatientId: string;
  clinicyId: string;
  clinicyMrn: string;
  completedAppointments: number;
  dateOfBirth?: string;
  fullNameArabic: string;
  fullNameEnglish: string;
  gender: Option<PatientGender>;
  governmentIssuedId: string;
  governmentIssuedIdType: Option<GovernmentIssuedIdType>;
  guardianClinicPatientId: string;
  hasMergePatientsBadge: boolean;
  id: string;
  internalFileNumber: string;
  invoicesTotal: number;
  medicalInstitution: Option;
  mergeRequestId: string | null;
  nationality: Option;
  patientId: string;
  patientType: Option<PatientType>;
  phoneNumbers: PatientPhoneNumber[];
  registeredBy: RegisteredByOption | null;
  relation: Option;
  status: Option<PatientStatus>;
  upcomingAppointments: number;
  verificationStatus: Option<VerificationStatus>;
}

export interface PatientProfileInsuranceDetails {
  id: string;
  insuranceClass: Option;
  insuranceCompany: Option;
  number: string;
  policyNumber: string;
  start: string;
  status: Option<Status>;
  end: string;
  activated: boolean;
}

export interface RegisteredByOption extends Option {
  details: {
    clinicyId: string;
  };
}
