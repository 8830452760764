import { useMemo } from 'react';
import { useAppIntl } from 'app/helpers';
import styles from './legend.module.scss';

export const Legend = () => {
  const { formatMessage } = useAppIntl();

  const items = useMemo(
    () => [
      {
        className: styles.booked,
        text: formatMessage({ id: 'APPOINTMENTS.TEXT.BOOKED' }),
      },
      {
        className: styles.confirmed,
        text: formatMessage({ id: 'APPOINTMENTS.TEXT.CONFIRMED' }),
      },
      {
        className: styles.waiting,
        text: formatMessage({ id: 'APPOINTMENTS.TEXT.WAITING' }),
      },
      {
        className: styles.checkedIn,
        text: formatMessage({ id: 'APPOINTMENTS.BUTTON.CHECKED-IN' }),
      },
      {
        className: styles.noShow,
        text: formatMessage({ id: 'APPOINTMENTS.TEXT.NO-SHOW' }),
      },
      {
        className: styles.conflicting,
        text: formatMessage({ id: 'CORE.TEXT.CONFLICTING' }),
      },
    ],
    [formatMessage],
  );

  return (
    <div className="text-center mb-3">
      {items.map((item, index) => (
        <div className={`${styles.item} me-3`} key={index}>
          <div className={`${styles.color} ${item.className} me-2`} />
          <span className={styles.text}>{item.text}</span>
        </div>
      ))}
    </div>
  );
};
