import React, { ForwardedRef } from 'react';
import { Input } from 'reactstrap';

interface Props extends React.HTMLProps<HTMLInputElement> {
  disabled?: boolean;
  inputId: string;
  inputPlaceholder?: string;
  invalid: boolean;
  bsSize?: 'lg' | 'sm';
}

export const CustomInput = React.forwardRef(
  (
    { disabled, inputId, inputPlaceholder, invalid, onFocus, onClick, bsSize, value }: Props,
    ref: ForwardedRef<Input>,
  ) => {
    return (
      <Input
        autoComplete="off"
        disabled={disabled}
        id={inputId}
        invalid={invalid}
        onClick={onClick}
        onFocus={onFocus}
        placeholder={inputPlaceholder}
        readOnly
        ref={ref}
        value={value}
        bsSize={bsSize}
      />
    );
  },
);
