import { ErrorType } from 'app/types';

export const maxWords =
  (max: number) =>
  (value: string | null | undefined): ErrorType | undefined => {
    return value && value.split(' ').length > max
      ? {
          message: 'ERRORS.MAX-WORDS',
          value: max,
        }
      : undefined;
  };
