import { AnyAction } from 'redux';
import { InitialAssessment, Option, PatientsArrival } from 'app/types';
import { WizardStep } from 'app/redux/initialAssessmentWizard/types';
import * as types from './initialAssessmentWizard.types';

export interface InitialAssessmentWizardReducer {
  activeStep: WizardStep;
  vitalsCompleted: boolean;
  backgroundCompleted: boolean;
  noteCompleted: boolean;
  arrival: PatientsArrival | null;
  initialAssessment: InitialAssessment | null;
  buttons: Option[] | null;
}

export const initialState: InitialAssessmentWizardReducer = {
  activeStep: WizardStep.Background,
  vitalsCompleted: false,
  backgroundCompleted: false,
  noteCompleted: false,
  arrival: null,
  initialAssessment: null,
  buttons: null,
};

export const initialAssessmentWizardReducer = (
  state = initialState,
  action: AnyAction,
): InitialAssessmentWizardReducer => {
  switch (action.type) {
    //Set active step
    case types.SET_ACTIVE_STEP:
      return {
        ...state,
        activeStep: action.payload,
      };
    //Set vitals to completed
    case types.VITALS_STEP_COMPLETED:
      return {
        ...state,
        vitalsCompleted: true,
      };
    //Set vitals to completed
    case types.BACKGROUND_STEP_COMPLETED:
      return {
        ...state,
        backgroundCompleted: true,
      };
    case types.NOTE_STEP_COMPLETED:
      return {
        ...state,
        noteCompleted: true,
      };
    case types.SET_ARRIVAL:
      return {
        ...state,
        arrival: action.payload,
      };
    case types.GET_BUTTONS_SUCCESS:
      return {
        ...state,
        buttons: action.payload.data,
      };

    case types.FETCH_ASSESSMENT_DATA_SUCCESS:
      return {
        ...state,
        initialAssessment: action.payload.data,
        noteCompleted: true,
        backgroundCompleted: true,
        vitalsCompleted: true,
      };
    case types.RESET:
      return {
        ...initialState,
      };
    //Default
    default:
      return state;
  }
};
