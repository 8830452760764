import { RSAA } from 'redux-api-middleware';
import * as types from './calendar.types';
import { CalendarType } from 'app/types';
import {
  CalendarDoctorsParams,
  CalendarMonthParams,
  CalendarSearchParams,
  CalendarWeekParams,
  SelectedDay,
  SelectedSlot,
  SelectedWaitingSlot,
  SelectedWorkingHours,
} from './types';
import { paramsAdapter } from 'app/redux/remoteData/helpers';

export const fetchTableViewItems = (params: CalendarSearchParams) => ({
  [RSAA]: {
    endpoint: `/calendar/search`,
    method: 'POST',
    types: [
      types.FETCH_TABLE_ITEMS_REQUEST,
      {
        type: types.FETCH_TABLE_ITEMS_SUCCESS,
        meta: { params },
      },
      types.FETCH_TABLE_ITEMS_FAILURE,
    ],
    body: paramsAdapter(params),
  },
});

export const fetchMonth = (body: CalendarMonthParams, isSilent?: boolean) => ({
  [RSAA]: {
    endpoint: `/calendar/month`,
    method: 'POST',
    types: [
      {
        type: types.FETCH_MONTH_REQUEST,
        meta: { isSilent },
      },
      types.FETCH_MONTH_SUCCESS,
      types.FETCH_MONTH_FAILURE,
    ],
    body,
  },
});

export const fetchTableViewWeek = (params: CalendarWeekParams) => ({
  [RSAA]: {
    endpoint: `/calendar/week`,
    method: 'POST',
    types: [
      {
        type: types.FETCH_TABLE_WEEK_REQUEST,
        meta: { params },
      },
      {
        type: types.FETCH_TABLE_WEEK_SUCCESS,
        meta: { params },
      },
      {
        type: types.FETCH_TABLE_WEEK_FAILURE,
        meta: { params },
      },
    ],
    body: params,
  },
});

export const fetchWeeks = (params: CalendarWeekParams[]) => ({
  [RSAA]: {
    endpoint: `/calendar/weeks`,
    method: 'POST',
    types: [
      {
        type: types.FETCH_WEEKS_REQUEST,
      },
      {
        type: types.FETCH_WEEKS_SUCCESS,
        meta: { params },
      },
      {
        type: types.FETCH_WEEKS_FAILURE,
        meta: { params },
      },
    ],
    body: params,
  },
});

export const fetchCalendarDoctors = (params: CalendarDoctorsParams) => ({
  [RSAA]: {
    endpoint: '/calendar/doctors/search',
    method: 'POST',
    types: [
      types.FETCH_DOCTORS_REQUEST,
      {
        type: types.FETCH_DOCTORS_SUCCESS,
        meta: { params },
      },
      types.FETCH_DOCTORS_FAILURE,
    ],
    body: paramsAdapter(params),
  },
});

export const setCalendarType = (type: CalendarType) => ({
  type: types.SET_CALENDAR_TYPE,
  payload: type,
});

export const setTableItemsParams = (params: CalendarSearchParams) => ({
  type: types.SET_TABLE_ITEMS_PARAMS,
  payload: params,
});

export const selectEvent = (eventId: string | null) => ({ type: types.SELECT_EVENT, payload: eventId });

export const selectDate = (date: string | null) => ({
  type: types.SELECT_DATE,
  payload: date,
});

export const selectSlot = (slot: SelectedSlot | null) => ({
  type: types.SELECT_SLOT,
  payload: slot,
});

export const selectWaitingSlot = (slot: SelectedWaitingSlot | null) => ({
  type: types.SELECT_WAITING_SLOT,
  payload: slot,
});

export const selectTableViewDay = (selectedDay: SelectedDay) => ({
  type: types.SELECT_TABLE_VIEW_DAY,
  payload: selectedDay,
});

export const selectWorkingHours = (selectedWorkingHours: SelectedWorkingHours) => ({
  type: types.SELECT_WORKING_HOURS,
  payload: selectedWorkingHours,
});

export const switchNoTimeItem = (id: string) => ({
  type: types.SWITCH_NO_TIME_ITEM,
  payload: id,
});

export const resetSelectedTableViewDays = () => ({
  type: types.RESET_TABLE_VIEW_DAY,
});

export const resetCalendar = () => ({ type: types.RESET });
