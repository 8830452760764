import classnames from 'classnames';
import ReactPhoneInput, { PhoneInputProps } from 'react-phone-input-2';
import ar from 'react-phone-input-2/lang/ar.json';
import { useAppIntl } from 'app/helpers';
import { LANGUAGES_AR_CODE } from 'app/shared';
import './phoneInput.scss';

interface CustomProps {
  name: string;
  invalid?: boolean;
  valid?: boolean;
  size?: 'sm' | 'lg';
}

export const PhoneInput = (props: PhoneInputProps & CustomProps) => {
  const { formatMessage, locale } = useAppIntl();

  return (
    <ReactPhoneInput
      autocompleteSearch
      containerClass={classnames({
        disabled: props.disabled,
      })}
      disableSearchIcon
      enableSearch
      inputClass={classnames({
        'is-invalid': props.invalid,
        'is-valid': props.valid,
        'form-control-sm': props.size === 'sm',
        'form-control-lg': props.size === 'lg',
      })}
      inputProps={{
        autoComplete: 'off',
        id: props.name,
        name: props.name,
      }}
      localization={locale === LANGUAGES_AR_CODE ? ar : undefined}
      masks={{ sa: '... ... ....' }}
      placeholder={formatMessage({ id: 'CORE.PLACEHOLDER.ENTER-PHONE-NUMBER' })}
      searchNotFound={formatMessage({ id: 'CORE.LABEL.NO-DATA' })}
      searchPlaceholder={formatMessage({ id: 'CORE.PLACEHOLDER.SEARCH' })}
      specialLabel=""
      {...props}
    />
  );
};
