import React, { useCallback, useState } from 'react';
import { Box, Page } from 'app/components';
import { useAppSelector } from 'app/helpers';
import { Loader, FORMS_ASSESSMENT_MEDICAL_PROFILE_FORM } from 'app/shared';
import { AddDiagnosisModal } from 'app/assessment/medicalProfileSection/components/modals/diagnosisModal/diagnosisModal';
import { PrintModal } from 'app/assessment/medicalProfileSection/components/modals/printModal/printModal';
import { useInitialValues } from 'app/assessment/medicalProfileSection/useInitialValues';
import { PractitionerAssessment } from 'app/assessment/medicalProfileSection/components/practitionerAssessment/practitionerAssessment';
import { useHasHistoricalData } from 'app/assessment/medicalProfileSection/components/helpers';
import { useCheckViewStatus } from 'app/assessment/helpers';
import { NoteBillingItemsModal } from 'app/assessment/medicalProfileSection/components/modals/noteBillingItemModal/noteBillingItemsModal';
import { useManageGoalBillingItem } from 'app/assessment/medicalProfileSection/components/practitionerAssessment/helpers/useManageGoalBillingItem';

export const MedicalProfile = () => {
  const [isOpen, setIsOpen] = useState(false);
  const assessmentData = useAppSelector((state) => state.doctorAssessment.data);
  const procedure = useAppSelector((state) => state.doctorAssessment.data?.procedure);

  const { isContinue, isCompleted, isDraftMode, isNew, isReadOnly } = useCheckViewStatus(
    assessmentData?.viewStatus,
    assessmentData?.completedAt,
  );
  const toggle = useCallback(() => setIsOpen((prevState) => !prevState), []);
  const { initialValues, initialValuesVisit, initialValuesDraft, initialValuesWithTable } = useInitialValues(
    isContinue,
    isDraftMode,
  );
  const hasHistorical = useHasHistoricalData();
  const { manageBillingItemsModal, onConfirmBillingItems } = useManageGoalBillingItem(
    FORMS_ASSESSMENT_MEDICAL_PROFILE_FORM,
  );

  const initialValuesOption =
    isCompleted || isReadOnly
      ? initialValuesVisit
      : hasHistorical && (!isCompleted || isReadOnly)
      ? initialValuesWithTable
      : isDraftMode
      ? initialValuesDraft
      : initialValues;

  if (!procedure && !isNew) {
    return <Loader />;
  }

  return (
    <Page>
      <Box>
        <PractitionerAssessment
          initialValues={initialValuesOption}
          toggle={toggle}
          manageBillingItemsModal={manageBillingItemsModal}
        />
      </Box>
      <PrintModal isOpen={isOpen} toggle={toggle} />
      <AddDiagnosisModal />
      {assessmentData && (
        <NoteBillingItemsModal
          branchId={assessmentData.clinicBranchId}
          doctorId={assessmentData.doctorInformation.doctorId}
          onConfirm={onConfirmBillingItems}
        />
      )}
    </Page>
  );
};
