export const FETCH_PERFORMER_BILLING_ITEM_REQUEST = 'billingItemsLists/FETCH_PERFORMER_BILLING_ITEM_REQUEST';
export const FETCH_PERFORMER_BILLING_ITEM_SUCCESS = 'billingItemsLists/FETCH_PERFORMER_BILLING_ITEM_SUCCESS';
export const FETCH_PERFORMER_BILLING_ITEM_FAILURE = 'billingItemsLists/FETCH_PERFORMER_BILLING_ITEM_FAILURE';

export const ADD_PERFORMER_BILLING_ITEM_REQUEST = 'billingItemsLists/ADD_PERFORMER_BILLING_ITEM_REQUEST';
export const ADD_PERFORMER_BILLING_ITEM_SUCCESS = 'billingItemsLists/ADD_PERFORMER_BILLING_ITEM_SUCCESS';
export const ADD_PERFORMER_BILLING_ITEM_FAILURE = 'billingItemsLists/ADD_PERFORMER_BILLING_ITEM_FAILURE';

export const EDIT_PERFORMER_BILLING_ITEM_REQUEST = 'billingItemsLists/EDIT_PERFORMER_BILLING_ITEM_REQUEST';
export const EDIT_PERFORMER_BILLING_ITEM_SUCCESS = 'billingItemsLists/EDIT_PERFORMER_BILLING_ITEM_SUCCESS';
export const EDIT_PERFORMER_BILLING_ITEM_FAILURE = 'billingItemsLists/EDIT_PERFORMER_BILLING_ITEM_FAILURE';

export const REMOVE_PERFORMER_BILLING_ITEM_REQUEST = 'billingItemsLists/REMOVE_PERFORMER_BILLING_ITEM_REQUEST';
export const REMOVE_PERFORMER_BILLING_ITEM_SUCCESS = 'billingItemsLists/REMOVE_PERFORMER_BILLING_ITEM_SUCCESS';
export const REMOVE_PERFORMER_BILLING_ITEM_FAILURE = 'billingItemsLists/REMOVE_PERFORMER_BILLING_ITEM_FAILURE';

export const TOGGLE_PERFORMER_ITEM_REQUEST = 'billingItemsLists/TOGGLE_PEFROMER_ITEM_REQUEST';
export const TOGGLE_PERFORMER_ITEM_SUCCESS = 'billingItemsLists/TOGGLE_PEFROMER_ITEM_SUCCESS';
export const TOGGLE_PERFORMER_ITEM_FAILURE = 'billingItemsLists/TOGGLE_PEFROMER_ITEM_FAILURE';

export const EXPORT_PERFORMER_BILLING_ITEMS_REQUEST = 'billingItemsLists/EXPORT_PERFORMER_BILLING_ITEMS_REQUEST';
export const EXPORT_PERFORMER_BILLING_ITEMS_SUCCESS = 'billingItemsLists/EXPORT_PERFORMER_BILLING_ITEMS_SUCCESS';
export const EXPORT_PERFORMER_BILLING_ITEMS_FAILURE = 'billingItemsLists/EXPORT_PERFORMER_BILLING_ITEMS_FAILURE';

export const SET_EXPANDED_PERFORMER = 'billingItemsLists/SET_EXPANDED_PERFORMER';
export const WS_UPDATE_PERFORMER_BILLING_ITEMS_TABLE = 'branchBillingItem/WS_UPDATE_PERFORMER_BILLING_ITEMS_TABLE';
