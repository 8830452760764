import { Fragment } from 'react';
import { Col, Row } from 'reactstrap';
import { VerticalDescriptionListProps } from './types';

export const VerticalDescriptionList = (props: VerticalDescriptionListProps) => (
  <Row tag="dl" {...props.rowProps}>
    {props.items.map((item, index) => (
      <Fragment key={item.id || index}>
        <Col md={5} lg={4} xl={3} xxl={2} tag="dt" {...props.labelColProps}>
          {item.label}
        </Col>
        <Col md={7} lg={8} xl={9} xxl={10} tag="dd" {...props.valueColProps}>
          {item.value}
        </Col>
      </Fragment>
    ))}
  </Row>
);
