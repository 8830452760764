export const compareTime = (values: { [key: string]: any }) => {
  const errors: { timeTo?: string } = {};

  if (values.timeTo && values.timeFrom) {
    if (values.timeTo.value <= values.timeFrom.value) {
      errors.timeTo = 'ERRORS.END-TIME-MUST-BE-GREATER';
    }
  }
  return errors;
};
