import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQueryWithReAuth } from 'app/helpers';
import type { QueryParams, QueryResponseWithPagination, AdminUser, ClinicUser } from 'app/types';

export const usersApi = createApi({
  reducerPath: 'usersApi',
  baseQuery: baseQueryWithReAuth,
  tagTypes: ['AdminUsers', 'ClinicUsers'],
  endpoints: (builder) => ({
    // Admin Users
    getAdminUsers: builder.query<QueryResponseWithPagination<AdminUser[]>, QueryParams | undefined>({
      query: (params) => ({
        url: 'admins',
        method: 'GET',
        params,
      }),
      providesTags: ['AdminUsers'],
    }),

    // Clinic Users
    getClinicUsers: builder.query<
      QueryResponseWithPagination<ClinicUser[]>,
      { clinicId: string; params: QueryParams | undefined }
    >({
      query: ({ clinicId, params }) => ({
        url: `clinics/${clinicId}/users`,
        method: 'GET',
        params,
      }),
      providesTags: ['ClinicUsers'],
    }),
  }),
});

export const { useGetAdminUsersQuery, useGetClinicUsersQuery } = usersApi;
