import { createAction, RSAA } from 'redux-api-middleware';
import { DICTIONARIES_LIMIT } from 'app/shared';
import * as types from './user-roles.types';

export const addUserRole = (body, clinicId) => ({
  [RSAA]: {
    endpoint: `/clinics/${clinicId}/users`,
    method: 'POST',
    types: [
      types.ADD_USER_ROLE_REQUEST,
      {
        type: types.ADD_USER_ROLE_SUCCESS,
        meta: {
          clinicId: clinicId,
          notification: {
            type: 'success',
            title: 'USER-ROLES.TEXT.ADD-SUCCESS',
          },
        },
      },
      types.ADD_USER_ROLE_FAILURE,
    ],
    body,
  },
});

export const editUserRole = (body, userId, clinicId) => ({
  [RSAA]: {
    endpoint: `/clinics/users/${userId}`,
    method: 'PUT',
    types: [
      types.EDIT_USER_ROLE_REQUEST,
      {
        type: types.EDIT_USER_ROLE_SUCCESS,
        meta: {
          clinicId: clinicId,
          notification: {
            type: 'success',
            title: 'USER-ROLES.TEXT.EDIT-SUCCESS',
          },
        },
      },
      types.EDIT_USER_ROLE_FAILURE,
    ],
    body,
  },
});

export const deleteUserRole = (id, clinicId) => ({
  [RSAA]: {
    endpoint: `/users/${id}`,
    method: 'DELETE',
    types: [
      types.DELETE_USER_ROLE_REQUEST,
      {
        type: types.DELETE_USER_ROLE_SUCCESS,
        meta: {
          clinicId,
          notification: {
            type: 'success',
            title: 'USER-ROLES.TEXT.DELETE-SUCCESS',
          },
        },
      },
      types.DELETE_USER_ROLE_FAILURE,
    ],
  },
});

export const fetchUserBranches = () => ({
  [RSAA]: {
    endpoint: `/user-branches`,
    method: 'GET',
    types: [types.FETCH_USER_BRANCHES_REQUEST, types.FETCH_USER_BRANCHES_SUCCESS, types.FETCH_USER_BRANCHES_FAILURE],
  },
});

export const fetchUserSpecialities = () => ({
  [RSAA]: {
    endpoint: `/user/scoped/specialities`,
    method: 'GET',
    types: [
      types.FETCH_USER_SPECIALITIES_REQUEST,
      types.FETCH_USER_SPECIALITIES_SUCCESS,
      types.FETCH_USER_SPECIALITIES_FAILURE,
    ],
  },
});

export const fetchClinicBranches = (clinicId) => ({
  [RSAA]: {
    endpoint: `/clinics/${clinicId}/branches`,
    method: 'GET',
    params: {
      pageSize: DICTIONARIES_LIMIT,
      statuses: 'online,offline',
    },
    types: [
      types.FETCH_CLINIC_BRANCHES_REQUEST,
      types.FETCH_CLINIC_BRANCHES_SUCCESS,
      types.FETCH_CLINIC_BRANCHES_FAILURE,
    ],
  },
});

export const fetchDoctors = (clinicId, branchesIds) => ({
  [RSAA]: {
    endpoint: `/clinics/${clinicId}/doctors`,
    method: 'GET',
    types: [types.FETCH_DOCTORS_REQUEST, types.FETCH_DOCTORS_SUCCESS, types.FETCH_DOCTORS_FAILURE],
    params: {
      branchesIds,
      order: 'name',
      orderAsc: true,
      pageSize: DICTIONARIES_LIMIT,
    },
  },
});

export const addAdmin = (body) => ({
  [RSAA]: {
    endpoint: `/admins`,
    method: `POST`,
    types: [
      types.ADD_ADMIN_ROLE_REQUEST,
      {
        type: types.ADD_ADMIN_ROLE_SUCCESS,
        meta: {
          notification: {
            type: 'success',
            title: 'USER-ROLES.TEXT.ADD-SUCCESS',
          },
        },
      },
      types.ADD_ADMIN_ROLE_FAILURE,
    ],
    body: body,
  },
});

export const editAdminUser = (body, userId) => ({
  [RSAA]: {
    endpoint: `/admins/${userId}`,
    method: 'PUT',
    types: [
      types.EDIT_USER_ROLE_REQUEST,
      {
        type: types.EDIT_USER_ROLE_SUCCESS,
        meta: {
          notification: {
            type: 'success',
            title: 'USER-ROLES.TEXT.EDIT-SUCCESS',
          },
        },
      },
      types.EDIT_USER_ROLE_FAILURE,
    ],
    body,
  },
});

export const deleteAdmin = (id) => ({
  [RSAA]: {
    endpoint: `/users/${id}`,
    method: `DELETE`,
    types: [
      types.DELETE_ADMIN_ROLE_REQUEST,
      {
        type: types.DELETE_ADMIN_ROLE_SUCCESS,
        meta: {
          notification: {
            type: 'success',
            title: 'USER-ROLES.TEXT.DELETE-SUCCESS',
          },
        },
      },
      types.DELETE_ADMIN_ROLE_FAILURE,
    ],
  },
});

export const fetchUser = (id) => ({
  [RSAA]: {
    endpoint: `/users/${id}`,
    method: 'GET',
    types: [types.FETCH_USER_REQUEST, types.FETCH_USER_SUCCESS, types.FETCH_USER_FAILURE],
  },
});

export const checkUserEmailAddress = (email) =>
  createAction({
    types: [types.CHECK_USER_EMAIL_REQUEST, types.CHECK_USER_EMAIL_SUCCESS, types.CHECK_USER_EMAIL_FAILURE],
    endpoint: '/user/is-email-used',
    method: 'POST',
    body: { email },
  });

export const fetchTemporaryPassword = (userProfileId) => ({
  [RSAA]: {
    endpoint: `/users/${userProfileId}/password/temporary`,
    method: 'POST',
    types: [
      types.FETCH_TEMPORARY_PASSWORD_REQUEST,
      types.FETCH_TEMPORARY_PASSWORD_SUCCESS,
      types.FETCH_TEMPORARY_PASSWORD_FAILURE,
    ],
  },
});

export const setTemporaryPasswordUserId = (userId) => ({
  type: types.SET_TEMPORARY_PASSWORD_USER_ID,
  payload: userId,
});
