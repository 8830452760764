import { useEffect } from 'react';

const domParser = new DOMParser();
export const appVersion = process.env.REACT_APP_VERSION;

const checkVersion = async () => {
  try {
    const resp = await fetch('/', { method: 'get', mode: 'cors' });
    const text = await resp.text();
    const document = domParser.parseFromString(text, 'text/html');
    const currentVersion = document.querySelector("meta[name='version']")?.getAttribute('content');

    if (currentVersion && appVersion && currentVersion !== appVersion) {
      window.location.reload();
    }
  } catch (error) {
    console.error(error);
  }
};

/**
 * This Hook reloads the application if the version of the application is not up-to-date.
 */
export const useForceReload = () => {
  useEffect(() => {
    const interval = setInterval(checkVersion, 60000);

    return () => clearInterval(interval);
  }, []);
};
