export const doctorServiceAdapter = (items) =>
  items.map((item) => ({
    ...item,
    subspeciality: {
      label: item.subspeciality.nameEn,
      value: String(item.subspeciality.id),
      translation: {
        ar: item.subspeciality.nameAr,
        en: item.subspeciality.nameEn,
      },
      status: item.subspeciality.status,
    },
  }));
