import { createAction, RSAA } from 'redux-api-middleware';
import * as types from './patient.types';

export const fetchPatient = (patientId) => ({
  [RSAA]: {
    endpoint: `/patients/${patientId}`,
    method: 'GET',
    types: [types.FETCH_PATIENT_REQUEST, types.FETCH_PATIENT_SUCCESS, types.FETCH_PATIENT_FAILURE],
  },
});

export const fetchPatientInsurances = (patientId, date) => ({
  [RSAA]: {
    endpoint: `patients/${patientId}/insurances/`,
    method: 'GET',
    params: {
      //date is optional param - if provided, API returns only patient's active insurances for this date
      date,
    },
    types: [
      types.FETCH_PATIENT_INSURANCES_REQUEST,
      types.FETCH_PATIENT_INSURANCES_SUCCESS,
      types.FETCH_PATIENT_INSURANCES_FAILURE,
    ],
  },
});

export const fetchPatientWallets = (patientId) => ({
  [RSAA]: {
    endpoint: `wallets/patient/${patientId}`,
    method: 'GET',
    types: [
      types.FETCH_PATIENT_WALLETS_REQUEST,
      types.FETCH_PATIENT_WALLETS_SUCCESS,
      types.FETCH_PATIENT_WALLETS_FAILURE,
    ],
  },
});

export const fetchDependentPatient = (patientId) => ({
  [RSAA]: {
    endpoint: `/dependants/${patientId}`,
    method: 'GET',
    // the same types as fetchPatient
    types: [types.FETCH_PATIENT_REQUEST, types.FETCH_PATIENT_SUCCESS, types.FETCH_PATIENT_FAILURE],
  },
});

export const addPatient = (values) => {
  return {
    [RSAA]: {
      types: [
        types.ADD_PATIENT_REQUEST,
        {
          meta: { clinicId: values.clinicId },
          type: types.ADD_PATIENT_SUCCESS,
        },
        types.ADD_PATIENT_FAILURE,
      ],
      endpoint: `/patients`,
      method: 'POST',
      body: values,
    },
  };
};

export const editPatient = (values, patientId) => {
  return {
    [RSAA]: {
      types: [
        types.EDIT_PATIENT_REQUEST,
        {
          type: types.EDIT_PATIENT_SUCCESS,
          meta: { patientId },
        },
        types.EDIT_PATIENT_FAILURE,
      ],
      endpoint: `/patients/${patientId}`,
      method: 'PUT',
      body: values,
    },
  };
};

export const editDependentPatient = (values, patientId) => {
  return {
    [RSAA]: {
      types: [
        types.EDIT_DEPENDENT_PATIENT_REQUEST,
        {
          type: types.EDIT_DEPENDENT_PATIENT_SUCCESS,
          meta: {
            patientId,
            notification: {
              type: 'success',
              title: 'PATIENTS.NOTIFICATION.DEPENDENT-EDITED-SUCCESS',
            },
          },
        },
        {
          type: types.EDIT_DEPENDENT_PATIENT_FAILURE,
          meta: {
            notification: {
              type: 'danger',
              title: 'CORE.TEXT.DEFAULT-FAILURE-MESSAGE',
            },
          },
        },
      ],
      endpoint: `/dependants/${patientId}`,
      method: 'PUT',
      body: values,
    },
  };
};

export const editPatientNumber = (number, patientId) => {
  return {
    [RSAA]: {
      types: [
        types.EDIT_PATIENT_NUMBER_REQUEST,
        {
          type: types.EDIT_PATIENT_NUMBER_SUCCESS,
          meta: { patientId },
        },
        types.EDIT_PATIENT_NUMBER_FAILURE,
      ],
      endpoint: `/patients/${patientId}/change-mobile-number`,
      method: 'PUT',
      body: JSON.stringify(number),
      headers: {
        'Content-Type': 'application/json',
      },
    },
  };
};

export const verifyChangePatientNumber = (values, patientId) => {
  return {
    [RSAA]: {
      types: [
        types.VERIFY_PATIENT_NUMBER_REQUEST,
        {
          type: types.VERIFY_PATIENT_NUMBER_SUCCESS,
          meta: {
            patientId,
            notification: {
              type: 'success',
              title: 'PATIENTS.TEXT.VERIFY-PATIENT-NUMBER-SUCCESS',
            },
          },
        },
        {
          type: types.VERIFY_PATIENT_NUMBER_FAILURE,
          meta: {
            notification: {
              type: 'danger',
              title: 'PATIENTS.TEXT.VERIFY-PATIENT-NUMBER-ERROR',
            },
          },
        },
      ],
      endpoint: `/patients/${patientId}/verify-change-mobile-number`,
      method: 'PUT',
      body: values,
    },
  };
};

export const resetChangePatientNumber = () => ({
  type: types.RESET_VERIFY_PATIENT_NUMBER,
});

export const blockPatientInClinics = (values, patientId) => {
  return {
    [RSAA]: {
      types: [
        types.BLOCK_PATIENT_CLINICS_REQUEST,
        {
          type: types.BLOCK_PATIENT_CLINICS_SUCCESS,
          meta: { patientId },
        },
        types.BLOCK_PATIENT_CLINICS_FAILURE,
      ],
      endpoint: `/patients/${patientId}/clinics/blocked`,
      method: 'POST',
      body: values,
    },
  };
};

export const unblockPatientInClinics = (values, patientId) => {
  return {
    [RSAA]: {
      types: [
        types.UNBLOCK_PATIENT_CLINICS_REQUEST,
        {
          type: types.UNBLOCK_PATIENT_CLINICS_SUCCESS,
          meta: { patientId },
        },
        types.UNBLOCK_PATIENT_CLINICS_FAILURE,
      ],
      endpoint: `/patients/${patientId}/clinics/unblocked`,
      method: 'POST',
      body: values,
    },
  };
};

export const fetchBlockedClinics = (patientId) => {
  return {
    [RSAA]: {
      types: [
        types.FETCH_BLOCKED_CLINICS_REQUEST,
        types.FETCH_BLOCKED_CLINICS_SUCCESS,
        types.FETCH_BLOCKED_CLINICS_FAILURE,
      ],
      endpoint: `/patients/${patientId}/clinics/blocked`,
      method: 'GET',
    },
  };
};

export const patientMobileNumberExists = (mobileNumber, toggleModals = true) => ({
  [RSAA]: {
    endpoint: `/patients/find-by-mobile-number`,
    method: 'GET',
    types: [
      {
        type: types.PATIENT_NUMBER_EXISTS_REQUEST,
        meta: {
          params: { mobileNumber },
        },
      },
      types.PATIENT_NUMBER_EXISTS_SUCCESS,
      {
        type: types.PATIENT_NUMBER_EXISTS_FAILURE,
        meta: {
          toggleModals,
          skipError: true,
        },
      },
    ],
  },
});

export const fetchPatientRegistrations = (patientId) => ({
  [RSAA]: {
    endpoint: `patients/${patientId}/registered-clinics`,
    method: 'GET',
    types: [
      types.FETCH_PATIENT_REGISTRATIONS_REQUEST,
      types.FETCH_PATIENT_REGISTRATIONS_SUCCESS,
      types.FETCH_PATIENT_REGISTRATIONS_FAILURE,
    ],
  },
});

export const blockPatient = (patientId) => {
  return {
    [RSAA]: {
      types: [
        types.BLOCK_PATIENT_REQUEST,
        {
          type: types.BLOCK_PATIENT_SUCCESS,
          meta: { patientId },
        },
        types.BLOCK_PATIENT_FAILURE,
      ],
      endpoint: `/patients/${patientId}/block`,
      method: 'PUT',
    },
  };
};

export const unblockPatient = (patientId) => {
  return {
    [RSAA]: {
      types: [
        types.UNBLOCK_PATIENT_REQUEST,
        {
          type: types.UNBLOCK_PATIENT_SUCCESS,
          meta: { patientId },
        },
        types.UNBLOCK_PATIENT_FAILURE,
      ],
      endpoint: `/patients/${patientId}/unblock`,
      method: 'PUT',
    },
  };
};

export const addPatientInsurance = (patientId, body) => ({
  [RSAA]: {
    endpoint: `/patients/${patientId}/insurances`,
    method: 'POST',
    types: [
      types.ADD_PATIENT_INSURANCE_REQUEST,
      {
        type: types.ADD_PATIENT_INSURANCE_SUCCESS,
        meta: {
          notification: {
            type: 'success',
            title: 'INSURANCES.TEXT.ADD-PATIENT-INS-SUCCESS',
          },
          patientId,
        },
      },
      types.ADD_PATIENT_INSURANCE_FAILURE,
    ],
    body,
  },
});

export const deletePatientInsurance = (patientId, insuranceId) => ({
  [RSAA]: {
    endpoint: `/patients/${patientId}/insurances/${insuranceId}`,
    method: 'DELETE',
    types: [
      types.DELETE_PATIENT_INSURANCE_REQUEST,
      {
        type: types.DELETE_PATIENT_INSURANCE_SUCCESS,
        meta: {
          notification: {
            type: 'success',
            title: 'INSURANCES.TEXT.DELETE-PATIENT-INS-SUCCESS',
          },
          patientId,
        },
      },
      types.DELETE_PATIENT_INSURANCE_FAILURE,
    ],
  },
});

export const updatePatientInsurance = (patientId, insuranceId, body) => {
  return {
    [RSAA]: {
      endpoint: `/patients/${patientId}/insurances/${insuranceId}`,
      method: 'PUT',
      body,
      types: [
        types.UPDATE_PATIENT_INSURANCE_REQUEST,
        {
          type: types.UPDATE_PATIENT_INSURANCE_SUCCESS,
          meta: {
            notification: {
              type: 'success',
              title: 'INSURANCES.TEXT.UPDATE-PATIENT-INS-SUCCESS',
            },
            patientId,
          },
        },
        types.UPDATE_PATIENT_INSURANCE_FAILURE,
      ],
    },
  };
};

export const fetchSummary = (patientId, params) => {
  return {
    [RSAA]: {
      types: [
        types.FETCH_PATIENT_SUMMARY_REQUEST,
        types.FETCH_PATIENT_SUMMARY_SUCCESS,
        types.FETCH_PATIENT_SUMMARY_FAILURE,
      ],
      endpoint: `wallets/summary/clinic-patients/${patientId}`,
      method: 'GET',
      params,
    },
  };
};

export const addPatientAccountCredits = (patientId, body, branchDeclarationId) => ({
  [RSAA]: {
    endpoint: `/wallets/clinic-patients/${patientId}/account-credits`,
    method: 'POST',
    types: [
      types.ADD_PATIENT_ACCOUNT_CREDIT_REQUEST,
      {
        type: types.ADD_PATIENT_ACCOUNT_CREDIT_SUCCESS,
        meta: {
          notification: {
            type: 'success',
            title: 'PATIENT-PAYMENTS.TEXT.ADD-PATIENT-ACC-CREDIT-SUCCESS',
          },
          patientId,
        },
      },
      types.ADD_PATIENT_ACCOUNT_CREDIT_FAILURE,
    ],
    body,
    params: {
      branchDeclarationId,
    },
  },
});

export const withdrawFromPatientAccountCredit = (patientId, body) => ({
  [RSAA]: {
    endpoint: `/wallets/clinic-patients/${patientId}/withdraw-credits`,
    method: 'POST',
    types: [
      types.WITHDRAW_ACC_CREDIT_REQUEST,
      {
        type: types.WITHDRAW_ACC_CREDIT_SUCCESS,
        meta: {
          notification: {
            type: 'success',
            title: 'PATIENT-PAYMENTS.TEXT.WITHDRAW-PATIENT-ACC-CREDIT-SUCCESS',
          },
          patientId,
        },
      },
      types.WITHDRAW_ACC_CREDIT_FAILURE,
    ],
    body,
  },
});

export const changeInfoNotifications = (patientId) => {
  const endpoint = `/clinic-patients/${patientId}/change-info-notification`;

  return {
    [RSAA]: {
      types: [
        types.CHANGE_INFO_NOTIFICATION_REQUEST,
        {
          type: types.CHANGE_INFO_NOTIFICATION_SUCCESS,
          meta: {
            notification: {
              type: 'success',
              title: 'CORE.TEXT.SENT-LINK-SUCCESSFULLY',
            },
          },
        },
        types.CHANGE_INFO_NOTIFICATION_FAILURE,
      ],
      endpoint,
      method: 'POST',
    },
  };
};

export const fetchPatientForPinRequest = (patientId) =>
  createAction({
    types: [
      types.FETCH_PATIENT_FOR_PIN_REQUEST,
      types.FETCH_PATIENT_FOR_PIN_SUCCESS,
      types.FETCH_PATIENT_FOR_PIN_FAILURE,
    ],
    endpoint: `/clinic-patients/${patientId}/get-for-pin-request`,
    method: 'GET',
  });

export const updatePatient = (patientId, values) => {
  return {
    [RSAA]: {
      types: [
        types.CHANGE_PATIENT_REQUEST,
        {
          type: types.CHANGE_PATIENT_SUCCESS,
          meta: {
            notification: {
              type: 'success',
              title: 'CORE.TEXT.UPDATED-SUCCESSFULLY',
            },
          },
        },
        {
          type: types.CHANGE_PATIENT_FAILURE,
        },
      ],
      endpoint: `/clinic-patients/${patientId}/change-request`,
      method: 'PUT',
      body: values,
    },
  };
};

export const setPatientAccountCreditPayments = (payments) => ({
  type: types.SET_PATIENT_ACCOUNT_CREDIT_PAYMENTS,
  payload: payments,
});

export const setPatientWithdrawalPayments = (payments) => ({
  type: types.SET_PATIENT_WITHDRAWAL_PAYMENTS,
  payload: payments,
});

export const checkNationalIdExists = (nationalId, params) =>
  createAction({
    types: [
      types.CHECK_NATIONAL_ID_EXISTS_REQUEST,
      types.CHECK_NATIONAL_ID_EXISTS_SUCCESS,
      types.CHECK_NATIONAL_ID_EXISTS_FAILURE,
    ],
    endpoint: `/patients/national-id/${nationalId}`,
    method: 'GET',
    params,
  });
