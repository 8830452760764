import { put, select } from 'redux-saga/effects';
import { RootState } from 'app/types';
import { fetchBookedAppointments } from 'app/redux/remoteData/remoteData.actions';

/**
 This function refreshes the data visible in the "Manage Appointments" modal if this modal is open
 */
export function* refreshManageAppointments(isSilent: boolean = false) {
  const {
    calendar: { selectedSlot },
    bookingWizard: { selectedSlot: selectedSlotWizard },
    modal: {
      manageAppointments: { isOpen },
      bookingWizard: { isOpen: isOpenWizard },
    },
    remoteData: { bookedAppointments },
  }: RootState = yield select();

  if (isOpen && selectedSlot && bookedAppointments.params) {
    // Refresh Booked Appointments
    yield put<any>(fetchBookedAppointments(selectedSlot.doctor.id, bookedAppointments.params, isSilent));
  }

  if (isOpenWizard && selectedSlotWizard && bookedAppointments.params) {
    // Refresh Booked Appointments embedded into Booking Wizard
    yield put<any>(fetchBookedAppointments(selectedSlotWizard.doctor.id, bookedAppointments.params, true));
  }
}
