import { ColumnFormatter } from 'react-bootstrap-table-next';
import { DotsMenu, DotsMenuItem } from 'app/components';
import { InsuranceDetailsItem, PatientStatus } from 'app/types';
import { medicalInsuranceTableId } from 'app/features/patient/constants';

export interface FormatExtraDataProps {
  formatMessage: Function;
  onEdit: (patientProfileInsurance: InsuranceDetailsItem) => void;
  onDelete: (patientProfileInsurance: InsuranceDetailsItem) => void;
}

export const ActionsFormatter: ColumnFormatter<InsuranceDetailsItem, FormatExtraDataProps> = (
  _,
  row,
  __,
  formatExtraData,
) => {
  const isMergedStatus = row.patientStatus.value === PatientStatus.Merged;

  const items: DotsMenuItem[] = [
    {
      isVisible: !isMergedStatus,
      label: formatExtraData?.formatMessage({ id: 'CORE.BUTTON.EDIT' }),
      onClick: () => formatExtraData?.onEdit(row),
    },
    {
      isVisible: !isMergedStatus,
      label: formatExtraData?.formatMessage({ id: 'CORE.BUTTON.DELETE' }),
      onClick: () => formatExtraData?.onDelete(row),
    },
  ];

  return <DotsMenu container={medicalInsuranceTableId} items={items} />;
};
