import { RSAA } from 'redux-api-middleware';

import * as types from './notifications.types';

export const fetchNotificationScenarios = () => ({
  [RSAA]: {
    endpoint: `/notifications/stakeholders`,
    method: 'GET',
    types: [
      types.FETCH_NOTIFICATION_SCENARIOS_REQUEST,
      types.FETCH_NOTIFICATION_SCENARIOS_SUCCESS,
      types.FETCH_NOTIFICATION_SCENARIOS_FAILURE,
    ],
  },
});

// This is the same endpoint (as fetchNotificationScenarios), but we have to store this data in a different field
export const fetchStakeholderOptions = () => ({
  [RSAA]: {
    endpoint: `/notifications/stakeholders`,
    method: 'GET',
    types: [
      types.FETCH_STAKEHOLDER_OPTIONS_REQUEST,
      types.FETCH_STAKEHOLDER_OPTIONS_SUCCESS,
      types.FETCH_STAKEHOLDER_OPTIONS_FAILURE,
    ],
  },
});

export const fetchNotificationScenariosByStakeholder = (stakeholderId) => ({
  [RSAA]: {
    endpoint: `/notifications/${stakeholderId}`,
    method: 'GET',
    types: [
      types.FETCH_NOTIFICATION_SCENARIOS_BY_STAKEHOLDER_REQUEST,
      types.FETCH_NOTIFICATION_SCENARIOS_BY_STAKEHOLDER_SUCCESS,
      types.FETCH_NOTIFICATION_SCENARIOS_BY_STAKEHOLDER_FAILURE,
    ],
  },
});

export const fetchNotificationScenarioContent = ({ scenarioId }) => ({
  [RSAA]: {
    endpoint: `/notifications/${scenarioId}/contents`,
    method: 'GET',
    types: [
      types.FETCH_NOTIFICATION_SCENARIO_CONTENT_REQUEST,
      types.FETCH_NOTIFICATION_SCENARIO_CONTENT_SUCCESS,
      types.FETCH_NOTIFICATION_SCENARIO_CONTENT_FAILURE,
    ],
  },
});

export const fetchNotificationClinics = () => ({
  [RSAA]: {
    endpoint: `/notifications/clinics`,
    method: 'GET',
    types: [
      types.FETCH_NOTIFICATION_CLINICS_REQUEST,
      types.FETCH_NOTIFICATION_CLINICS_SUCCESS,
      types.FETCH_NOTIFICATION_CLINICS_FAILURE,
    ],
  },
});

export const fetchNotificationScheduled = () => ({
  [RSAA]: {
    endpoint: `/mass-notifications`,
    method: 'GET',
    types: [
      types.FETCH_NOTIFICATION_SCHEDULED_REQUEST,
      types.FETCH_NOTIFICATION_SCHEDULED_SUCCESS,
      types.FETCH_NOTIFICATION_SCHEDULED_FAILURE,
    ],
  },
});

export const addNotificationMassMessaging = (body) => {
  return {
    [RSAA]: {
      types: [
        types.ADD_NOTIFICATION_MASS_MESSAGING_REQUEST,
        {
          type: types.ADD_NOTIFICATION_MASS_MESSAGING_SUCCESS,
          meta: {
            notification: {
              type: 'success',
              title: 'NOTIFICATIONS.TEXT.MASS-NOTIFICATION-SUCCESS',
            },
          },
        },
        {
          type: types.ADD_NOTIFICATION_MASS_MESSAGING_FAILURE,
          meta: {
            notification: {
              type: 'danger',
              title: 'NOTIFICATIONS.TEXT.MASS-NOTIFICATION-FAILURE',
            },
          },
        },
      ],
      endpoint: '/mass-notifications/send',
      method: 'POST',
      body,
    },
  };
};

export const editNotificationScheduled = (body) => ({
  [RSAA]: {
    types: [
      types.EDIT_NOTIFICATION_SCHEDULED_REQUEST,
      {
        type: types.EDIT_NOTIFICATION_SCHEDULED_SUCCESS,
        meta: {
          notification: {
            type: 'success',
            title: 'NOTIFICATIONS.TEXT.SELECTED-NOTIFICATIONS-CANCELED',
          },
        },
      },
      types.EDIT_NOTIFICATION_SCHEDULED_FAILURE,
    ],
    endpoint: '/mass-notifications/cancel',
    method: 'PUT',
    body,
  },
});

export const editNotificationScenarioContent = (body, contentId) => ({
  [RSAA]: {
    types: [
      types.EDIT_NOTIFICATION_SCENARIO_CONTENT_REQUEST,
      {
        type: types.EDIT_NOTIFICATION_SCENARIO_CONTENT_SUCCESS,
      },
      {
        type: types.EDIT_NOTIFICATION_SCENARIO_CONTENT_FAILURE,
        meta: {
          notification: {
            type: 'danger',
            title: 'NOTIFICATIONS.TEXT.CONTENT-SAVE-FAILURE',
          },
        },
      },
    ],
    endpoint: `/notifications/contents/${contentId}`,
    method: 'PUT',
    body,
  },
});

export const editNotificationScenario = (payload) => ({
  type: types.EDIT_NOTIFICATION_SCENARIO,
  payload,
});

export const editNotificationScenarioStatus = (stakeholderId, enable) => ({
  [RSAA]: {
    types: [
      types.EDIT_NOTIFICATION_SCENARIO_STATUS_REQUEST,
      {
        type: types.EDIT_NOTIFICATION_SCENARIO_STATUS_SUCCESS,
        meta: {
          stakeholderId,
          enable,
        },
      },
      types.EDIT_NOTIFICATION_SCENARIO_STATUS_FAILURE,
    ],
    endpoint: `/notifications/stakeholders/${stakeholderId}/${enable ? 'enable' : 'disable'}`,
    method: 'PUT',
  },
});

export const disableClinicsNotifications = (clinics) => ({
  [RSAA]: {
    endpoint: `/notifications/clinics/disabled`,
    method: 'PUT',
    body: clinics,
    types: [
      types.DISABLE_CLINICS_NOTIFICATIONS_REQUEST,
      types.DISABLE_CLINICS_NOTIFICATIONS_SUCCESS,
      types.DISABLE_CLINICS_NOTIFICATIONS_FAILURE,
    ],
  },
});

export const enableClinicsNotifications = (clinics) => ({
  [RSAA]: {
    endpoint: `/notifications/clinics/enabled`,
    method: 'PUT',
    body: clinics,
    types: [
      types.ENABLE_CLINICS_NOTIFICATIONS_REQUEST,
      types.ENABLE_CLINICS_NOTIFICATIONS_SUCCESS,
      types.ENABLE_CLINICS_NOTIFICATIONS_FAILURE,
    ],
  },
});

export const resetNotifications = () => ({
  type: types.NOTIFICATION_RESET,
});
