import React from 'react';
import { Input } from 'reactstrap';
import { fieldPropTypes } from 'redux-form';

export const InputComponent = ({ input }) => (
  <Input maxLength={100} {...input} className="input-with-prefix__input form-control---no-label" />
);

InputComponent.propTypes = {
  ...fieldPropTypes,
};
