import { forwardRef } from 'react';

interface Props {
  title: string;
}

export const SectionHeading = forwardRef<HTMLHeadingElement, Props>((props, ref) => (
  <h3 className="d-flex flex-wrap flex-column mb-4 mb-lg-5" ref={ref}>
    <span className="me-2">{props.title}</span>
  </h3>
));
