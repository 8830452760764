import { put, select } from 'redux-saga/effects';
import { RootState } from 'app/types';
import { clinicsApi } from 'app/services/clinics/clinics';

export function* refreshRejectedClinicList() {
  const {
    queryParams: { rejectedClinics: params },
  }: RootState = yield select();

  yield put<any>(
    clinicsApi.endpoints.getRejectedClinics.initiate(
      { params },
      {
        subscribe: false,
        forceRefetch: true,
      },
    ),
  );
}
