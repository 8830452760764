import { discountTypes, otherOption } from './form.constants';

import { Option, RootState } from 'app/types';
import { MessageDescriptor } from 'react-intl';

export const VAT = 0.15;
export const VAT_PERCENTAGE = 15;

export const calculateGrossPrice = (netPrice: number) => {
  const calculatedVat = calculateVat(netPrice);
  return priceParser(netPrice + calculatedVat) as number;
};

export const calculateVat = (netPrice: number) => getDecimalNumber(netPrice * VAT);

export const calculateTotal = (state: RootState, selector: (state: RootState, value: string) => any) => {
  let totalDiscount;
  let totalPrice;
  const netPrice = Number(selector(state, 'netPrice')) || 0;
  const discountType = selector(state, 'discountType');
  const discountValue = Number(selector(state, 'discountValue')) || 0;
  const vatApplicable = selector(state, 'vatApplicable')?.value;

  if (discountType === discountTypes.PERCENTAGE && netPrice > 0) {
    totalDiscount = !!discountValue ? ((netPrice / 100) * discountValue).toFixed(2) : 0;
  } else if (!discountType || discountType === discountTypes.NONE) {
    totalDiscount = Number(0).toFixed(2);
  } else totalDiscount = discountValue;

  let price: number = netPrice - Number(totalDiscount);
  if (!!vatApplicable) {
    price = calculateGrossPrice(price);
  }
  totalPrice = getDecimalNumber(price);

  return { discount: totalDiscount, price: totalPrice };
};

export const getDecimalNumber = (value: number, digits = 2) => +(Math.round(value * 100) / 100).toFixed(digits);

export const formatOptions = <T = string>(
  options: Option<T>[],
  formatMessage: (message: MessageDescriptor) => string,
) =>
  options.map((option) => ({
    ...option,
    label: formatMessage({ id: option.label }),
  }));

export const mergeOptions = (options: any[]) => [otherOption, ...options];

export const priceParser = (val: number) => (val || val === 0 ? getDecimalNumber(val, 2) : undefined);

export const selectParser = (val?: { value: string }) => (val ? val.value : null);
