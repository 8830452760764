import classnames from 'classnames';
import { Html } from '@react-three/drei';
import { useAppIntl } from 'app/helpers';
import { Note, TemplateNote } from 'app/components/locationSelector/procedureLocation/types';
import { SelectedNote } from 'app/components/locationSelector/procedureLocation/selector/selectedNote/selectedNote';
import styles from './points.module.scss';

interface Props {
  closeNote: () => void;
  deleteNote: () => void;
  isReadOnly?: boolean;
  notes: TemplateNote[];
  selectNote: (id: string) => void;
  selectedNote: string | null;
  updateNote: (note: Note) => void;
  withStatus?: boolean;
}

export const Points = ({
  closeNote,
  deleteNote,
  selectedNote,
  selectNote,
  notes,
  updateNote,
  withStatus,
  isReadOnly,
}: Props) => {
  const { formatMessage } = useAppIntl();

  return (
    <>
      {notes.map((note, index) => {
        const number = note?.number || index + 1;
        const isSelected = selectedNote === note.id;
        const zIndexRange = [isSelected ? 9999 : number, 0];

        return (
          <Html center key={note.id} position={[note.lookAt.x, note.lookAt.y, note.lookAt.z]} zIndexRange={zIndexRange}>
            <div
              className={classnames(styles.point, { [styles.selectedPoint]: isSelected })}
              onClick={() => selectNote(note.id)}
            >
              {number}
            </div>
            {selectedNote === note.id && (
              <SelectedNote
                closeNote={closeNote}
                deleteNote={deleteNote}
                formatMessage={formatMessage}
                note={note}
                updateNote={updateNote}
                withStatus={withStatus}
                isReadOnly={isReadOnly}
              />
            )}
          </Html>
        );
      })}
    </>
  );
};
