import moment from 'moment';
import { DayCalendarWorkingTime } from 'app/types';

export const findWorkingTime = (
  workingTimes: DayCalendarWorkingTime[],
  start: Date,
  end: Date | undefined,
): DayCalendarWorkingTime | undefined => {
  const startMoment = moment(start);

  return workingTimes.find((wt) => {
    const wtStart = new Date(wt.start);
    const wtEnd = new Date(wt.end);

    if (end && startMoment.isSameOrAfter(wtStart, 'minutes') && moment(end).isSameOrBefore(wtEnd, 'minutes')) {
      return wt;
    } else if (startMoment.isBetween(wtStart, wtEnd, 'minutes', '[)')) {
      return wt;
    }

    return false;
  });
};
