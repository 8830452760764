import { takeEvery, put, select } from 'redux-saga/effects';
import {
  ADD_BRANCH_SUCCESS,
  ADD_BRANCH_WORKING_HOURS_SUCCESS,
  DELETE_BRANCH_SUCCESS,
  EDIT_BRANCH_IMAGES_SUCCESS,
  EDIT_BRANCH_SUCCESS,
  REMOVE_BRANCH_WORKING_HOURS_SUCCESS,
} from 'app/redux/branch/branch.types';
import { branchesApi } from 'app/services/branches/branches';
import { fetchBranch, fetchBranchWorkingHours } from 'app/redux/branch/branch.actions';
import {
  toggleAddBranchModal,
  toggleBranchImagesModal,
  toggleEditBranchModal,
  toggleEditStatusModal,
  toggleModal,
} from 'app/redux/modals/modals.actions';

export function* afterAddBranchSuccess() {
  yield put(branchesApi.util.invalidateTags(['Branches']));
  yield put(toggleAddBranchModal());
}

export function* afterDeleteBranchSuccess() {
  yield put(branchesApi.util.invalidateTags(['Branches']));
}

export function* afterEditBranchSuccess({ meta }) {
  const {
    modal: {
      editBranch: { isOpen: isEditBranchOpen },
      editStatus: { isOpen: isEditStatusOpen },
    },
  } = yield select();

  if (isEditBranchOpen) {
    yield put(toggleEditBranchModal());
  }
  if (isEditStatusOpen) {
    yield put(toggleEditStatusModal());
  }

  yield put(fetchBranch(meta.branchId));
}

export function* afterEditBranchImagesSuccess({ meta }) {
  yield put(fetchBranch(meta.branchId));
  yield put(toggleBranchImagesModal());
}

export function* afterAddWorkingHoursSuccess({ meta }) {
  yield put(toggleModal('addBranchWorkingHours', false));
  yield put(fetchBranchWorkingHours(meta.branchId));
}

export function* afterRemoveWorkingHoursSuccess({ meta }) {
  yield put(fetchBranchWorkingHours(meta.branchId));
}

function* branchesSaga() {
  yield takeEvery(ADD_BRANCH_SUCCESS, afterAddBranchSuccess);
  yield takeEvery(DELETE_BRANCH_SUCCESS, afterDeleteBranchSuccess);
  yield takeEvery(EDIT_BRANCH_SUCCESS, afterEditBranchSuccess);
  yield takeEvery(EDIT_BRANCH_IMAGES_SUCCESS, afterEditBranchImagesSuccess);
  yield takeEvery(ADD_BRANCH_WORKING_HOURS_SUCCESS, afterAddWorkingHoursSuccess);
  yield takeEvery(REMOVE_BRANCH_WORKING_HOURS_SUCCESS, afterRemoveWorkingHoursSuccess);
}

export default branchesSaga;
