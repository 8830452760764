import React, { FC } from 'react';
import { BaseFieldProps, Field, Form, reduxForm } from 'redux-form';
import { Row, Col } from 'reactstrap';
import { InjectedFormProps } from 'redux-form/lib/reduxForm';
import { useAppIntl } from 'app/helpers';
import {
  InputComponent,
  phoneNumber,
  FORMS_ADD_GUARDIAN_TO_DEPENDENT,
  PhoneNumberProps,
  PhoneNumber,
} from 'app/shared';
import {
  AddGuardianToDependentFormData,
  AddGuardianToDependentFormField,
} from 'app/features/patient/addGuardianToDependentModal/form/types';

export interface AddGuardianToDependentCustomProps {
  resetSelected: () => void;
}

export const FormComponent: FC<
  AddGuardianToDependentCustomProps &
    InjectedFormProps<AddGuardianToDependentFormData, AddGuardianToDependentCustomProps>
> = ({ resetSelected }) => {
  const { formatMessage } = useAppIntl();

  return (
    <Form>
      <h4>{formatMessage({ id: 'CORE.LABEL.SEARCH-FOR-PATIENT' })}</h4>
      <Row xs={1} lg={3}>
        <Col>
          <Field<BaseFieldProps<PhoneNumberProps>>
            name={AddGuardianToDependentFormField.mobileNumber}
            component={PhoneNumber}
            props={{
              label: formatMessage({ id: 'CORE.LABEL.PHONE-NUMBER' }),
            }}
            validate={[phoneNumber]}
            onChange={resetSelected}
          />
        </Col>
        <Col>
          <Field
            component={InputComponent}
            name={AddGuardianToDependentFormField.fileNumber}
            type="text"
            label={formatMessage({
              id: 'CORE.LABEL.CLINICY-MRN',
            })}
            onChange={resetSelected}
          />
        </Col>
        <Col>
          <Field
            component={InputComponent}
            name={AddGuardianToDependentFormField.governmentId}
            type="text"
            label={formatMessage({ id: 'CORE.LABEL.GOVERNMENT-ID-NUMBER' })}
            onChange={resetSelected}
          />
        </Col>
      </Row>
    </Form>
  );
};

export const AddGuardianToDependentForm = reduxForm<AddGuardianToDependentFormData, AddGuardianToDependentCustomProps>({
  form: FORMS_ADD_GUARDIAN_TO_DEPENDENT,
  enableReinitialize: true,
})(FormComponent);
