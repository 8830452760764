import { PayloadAction } from '@reduxjs/toolkit';
import { put, select } from 'redux-saga/effects';
import { RootState } from 'app/types';
import { DEFAULT_PAGINATION_PARAMS } from 'app/shared';
import { RefreshPerformerBillingItemsEvent } from 'app/billingItems/practitionersBillingItems/helpers';
import { WS_REFRESH_PERFORMER_BILLING_ITEMS, WsRefreshAction } from 'app/sagas/billingItemLists/types';

export function* wsUpdatePerformerBillingItemsTable({ payload }: PayloadAction<RefreshPerformerBillingItemsEvent>) {
  const {
    remoteData: {
      billingItemsPerformers: { params },
    },
  }: RootState = yield select();

  yield put<WsRefreshAction>({
    type: WS_REFRESH_PERFORMER_BILLING_ITEMS,
    payload: {
      branchId: payload.BranchId,
      performerId: payload.PerformerId,
      params: params || DEFAULT_PAGINATION_PARAMS,
    },
  });
}
