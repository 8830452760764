import { useMemo } from 'react';
import { useAppSelector, useCurrentUser, isWithinAgeRestrictions } from 'app/helpers';
import { StepTrigger, StepType } from 'app/bookAppointment/types';

interface Props {
  changeStep: (step: StepType) => void;
  currentStep: StepType;
}

export const useStepsTriggers = ({ changeStep, currentStep }: Props): StepTrigger[] => {
  const { isDoctor } = useCurrentUser();

  // Selectors
  const selectedPatient = useAppSelector((state) => state.bookAppointment.selectedPatient);
  const selectedSlot = useAppSelector((state) => state.bookAppointment.selectedSlot);
  const { selectedService, selectedSpeciality, selectedSubSpeciality } = useAppSelector(
    (state) => state.bookAppointment,
  );
  const meetsAgeRestrictions = useMemo(
    () =>
      isWithinAgeRestrictions({
        age: selectedPatient?.age,
        min: selectedSlot?.doctor.acceptablePatientAgeFrom,
        max: selectedSlot?.doctor.acceptablePatientAgeTo,
      }),
    [selectedSlot?.doctor, selectedPatient],
  );

  return useMemo(() => {
    return !isDoctor
      ? [
          {
            color: selectedSpeciality ? 'success' : 'secondary',
            outline: currentStep !== StepType.Speciality && !selectedSpeciality,
            onClick: () => changeStep(StepType.Speciality),
            label: '1',
          },
          {
            color: selectedSubSpeciality ? 'success' : 'secondary',
            disabled: !selectedSpeciality,
            outline: currentStep !== StepType.SubSpeciality && !selectedSubSpeciality,
            onClick: () => changeStep(StepType.SubSpeciality),
            label: '2',
          },
          {
            color: selectedSlot ? 'success' : 'secondary',
            disabled: !selectedSubSpeciality,
            outline: currentStep !== StepType.ServiceWithWorkingDay && !selectedSlot,
            onClick: () => changeStep(StepType.ServiceWithWorkingDay),
            label: '3',
          },
          {
            color: selectedPatient ? (meetsAgeRestrictions ? 'success' : 'danger') : 'secondary',
            outline: currentStep !== StepType.Patient,
            onClick: () => changeStep(StepType.Patient),
            disabled: !selectedService || !selectedSlot,
            label: '4',
          },
        ]
      : [
          {
            color: selectedSubSpeciality ? 'success' : 'secondary',
            outline: currentStep !== StepType.SubSpeciality && !selectedSubSpeciality,
            onClick: () => changeStep(StepType.SubSpeciality),
            label: '1',
          },
          {
            color: selectedSlot ? 'success' : 'secondary',
            disabled: !selectedSubSpeciality,
            outline: currentStep !== StepType.ServiceWithWorkingDay && !selectedSlot,
            onClick: () => changeStep(StepType.ServiceWithWorkingDay),
            label: '2',
          },
          {
            color: selectedPatient ? (meetsAgeRestrictions ? 'success' : 'danger') : 'secondary',
            outline: currentStep !== StepType.Patient,
            onClick: () => changeStep(StepType.Patient),
            disabled: !selectedService || !selectedSlot,
            label: '3',
          },
        ];
  }, [
    isDoctor,
    changeStep,
    currentStep,
    meetsAgeRestrictions,
    selectedPatient,
    selectedService,
    selectedSpeciality,
    selectedSubSpeciality,
    selectedSlot,
  ]);
};
