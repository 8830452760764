import { put, select } from 'redux-saga/effects';
import { RootState } from 'app/types';
import { referralsApi } from 'app/services/referrals/referrals';

export function* refreshCurrentReferrals() {
  const {
    queryParams: { referrals },
  }: RootState = yield select();

  if (referrals) {
    yield put<any>(
      referralsApi.endpoints.getCurrentReferrals.initiate({ referrals }, { subscribe: false, forceRefetch: true }),
    );
  }
}
