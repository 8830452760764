import moment from 'moment';
import { CalendarDay } from 'app/types';
import { checkAvailableSlotsInDay } from 'app/calendar/helpers/checkAvailableSlots';

export type DayColor = 'danger' | 'secondary' | 'success' | 'warning';

export type DaySettings = {
  color: DayColor;
  disabled: boolean;
  outline: boolean;
};

export const getDaySettings = (day: CalendarDay, isSelected: boolean): DaySettings => {
  const currentDate = moment(day.date);
  const hasAvailableSlots = checkAvailableSlotsInDay(day);
  const hasAvailableWaitingSlots = day.waitingAppointmentsCount < day.waitingAppointmentsLimit;
  const hasWaitingSlots = !hasAvailableSlots && hasAvailableWaitingSlots;
  const isFull = !hasAvailableSlots && !hasAvailableWaitingSlots;
  const isPast = currentDate.isBefore(moment(), 'd');
  const isInactive = day.workingTimesWithSlots.length === 0;
  const isDisabled = isPast || isInactive;

  const settings: DaySettings = {
    color: 'success',
    disabled: isDisabled,
    outline: !isSelected,
  };

  if (isDisabled) {
    settings.color = 'secondary';
    settings.outline = true;

    return settings;
  }

  if (hasWaitingSlots) {
    settings.color = 'warning';
  }

  if (isFull) {
    settings.color = 'danger';
  }

  return settings;
};
