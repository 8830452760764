import React, { FC, useEffect, useMemo } from 'react';
import { Field, InjectedFormProps, reduxForm } from 'redux-form';
import { Form, Button, ModalHeader, ModalBody, ModalFooter, Row, Col } from 'reactstrap';
import moment from 'moment';
import {
  DatePickerComponent,
  InputComponent,
  SwitchComponent,
  AsyncSelectComponent,
  FORMS_PATIENT_PROFILE_INSURANCE,
  required,
  dateTodayOrInFuture,
} from 'app/shared';
import { useAppIntl, useAppSelector, useDate } from 'app/helpers';
import { Option } from 'app/types';
import { useOnChangeFunctions } from 'app/features/patient/patientInsuranceDetailsForm/helpers/useOnChangeFunctions';
import { useFetchOptions } from 'app/features/patient/patientInsuranceDetailsForm/helpers/useFetchOptions';
import { validate } from 'app/features/patient/patientInsuranceDetailsForm/helpers/validator';
import { usePolicyNumberOptionFormatter } from 'app/features/patient/patientInsuranceDetailsForm/helpers/usePolicyNumberOptionFormatter';
import {
  PatientInsuranceField,
  PatientInsuranceFormData,
} from 'app/features/patient/patientInsuranceDetailsForm/types';

interface Props {
  branchId?: string;
  clinicId?: string;
  closeModal: () => void;
  headerText: string;
  isEdit?: boolean;
  submitText: string;
}

const FormComponent: FC<Props & InjectedFormProps<PatientInsuranceFormData, Props>> = ({
  branchId,
  change,
  clinicId,
  closeModal,
  handleSubmit,
  headerText,
  isEdit,
  pristine,
  submitting,
  touch,
}) => {
  const { formatMessage } = useAppIntl();
  const { todayStart } = useDate();

  const insuranceCompanyId: string | undefined = useAppSelector(
    (state) => state.form[FORMS_PATIENT_PROFILE_INSURANCE]?.values?.[PatientInsuranceField.insuranceCompany]?.value,
  );

  const insurancePolicyId: string | undefined = useAppSelector(
    (state) => state.form[FORMS_PATIENT_PROFILE_INSURANCE]?.values?.[PatientInsuranceField.policyNumber]?.value,
  );

  const selectedClinic: Option | undefined = useAppSelector(
    (state) => state.form[FORMS_PATIENT_PROFILE_INSURANCE]?.values?.[PatientInsuranceField.clinic],
  );

  const clinicBranchId: string | undefined = useAppSelector(
    (state) => state.form[FORMS_PATIENT_PROFILE_INSURANCE]?.values?.[PatientInsuranceField.clinicBranch]?.value,
  );

  const expiryDate: Date = useAppSelector(
    (state) => state.form[FORMS_PATIENT_PROFILE_INSURANCE]?.values?.[PatientInsuranceField.end],
  );

  const isClinicBranch = !clinicId && !selectedClinic;
  const isExpired = useMemo(() => moment(expiryDate).isBefore(todayStart), [expiryDate, todayStart]);

  // Helper Hooks
  const {
    fetchInsuranceOptions,
    fetchInsurancePoliciesOptions,
    fetchInsuranceClassesOptions,
    fetchClinicOptions,
    fetchBranchOptions,
  } = useFetchOptions({
    insuranceCompanyId,
    insurancePolicyId,
    selectedClinic,
    clinicBranchId,
  });
  // Change Functions
  const { onChangeClassAndPolicy, onPolicyNumberChange, onClinicChange } = useOnChangeFunctions(change);

  const formatPolicyNumberOption = usePolicyNumberOptionFormatter();

  useEffect(() => {
    if (isEdit) {
      touch(PatientInsuranceField.end);
    }
  }, [isEdit, touch]);

  return (
    <Form onSubmit={handleSubmit}>
      <ModalHeader toggle={closeModal}>{formatMessage({ id: headerText })}</ModalHeader>

      <ModalBody>
        <Row xs={1} md={2}>
          <Col>
            <Field
              name={PatientInsuranceField.insuranceCompany}
              component={AsyncSelectComponent}
              fetchOptions={fetchInsuranceOptions}
              label={formatMessage({ id: 'CORE.LABEL.INSURANCE-COMPANY' })}
              onChange={onChangeClassAndPolicy}
              disabled={isEdit}
              isRequired
            />
          </Col>
          {!clinicId && (
            <Col>
              <Field
                name={PatientInsuranceField.clinic}
                component={AsyncSelectComponent}
                fetchOptions={fetchClinicOptions}
                isRequired
                disabled={isEdit}
                label={formatMessage({ id: 'CORE.LABEL.MEDICAL-INSTITUTION' })}
                onChange={onClinicChange}
              />
            </Col>
          )}
          {!branchId && (
            <Col>
              <Field
                name={PatientInsuranceField.clinicBranch}
                cacheUniqs={[selectedClinic?.value]}
                component={AsyncSelectComponent}
                disabled={isClinicBranch}
                fetchOptions={fetchBranchOptions}
                onChange={onChangeClassAndPolicy}
                isRequired
                label={formatMessage({ id: 'CORE.LABEL.BRANCH-NAME' })}
              />
            </Col>
          )}
          <Col>
            <Field
              name={PatientInsuranceField.policyNumber}
              component={AsyncSelectComponent}
              cacheUniqs={[insuranceCompanyId, clinicBranchId]}
              fetchOptions={fetchInsurancePoliciesOptions}
              label={formatMessage({ id: 'CORE.LABEL.POLICY-NUMBER' })}
              formatOptionLabel={formatPolicyNumberOption}
              onChange={onPolicyNumberChange}
              disabled={!insuranceCompanyId || !clinicBranchId}
              isRequired
            />
          </Col>
          <Col>
            <Field
              name={PatientInsuranceField.number}
              type="text"
              label={formatMessage({ id: 'CORE.LABEL.INSURANCE-NUMBER' })}
              component={InputComponent}
              isRequired
            />
          </Col>
          <Col>
            <Field
              name={PatientInsuranceField.insuranceClass}
              component={AsyncSelectComponent}
              cacheUniqs={[insurancePolicyId]}
              fetchOptions={fetchInsuranceClassesOptions}
              label={formatMessage({ id: 'CORE.LABEL.INSURANCE-CLASS' })}
              disabled={!insurancePolicyId}
              isRequired
            />
          </Col>

          <Col>
            <Field
              name={PatientInsuranceField.start}
              component={DatePickerComponent}
              label={formatMessage({ id: 'CORE.LABEL.START-DATE' })}
              placeholder={formatMessage({ id: 'CORE.PLACEHOLDER.SELECT-DATE' })}
              disabled={isEdit}
              isRequired
            />
          </Col>
          <Col>
            <Field
              name={PatientInsuranceField.end}
              component={DatePickerComponent}
              label={formatMessage({ id: 'INSURANCES.LABEL.EXPIRY-DATE' })}
              placeholder={formatMessage({ id: 'CORE.PLACEHOLDER.SELECT-DATE' })}
              isRequired
              validate={[required, dateTodayOrInFuture]}
            />
          </Col>
          {isEdit && (
            <Col>
              <Field
                name={PatientInsuranceField.activated}
                component={SwitchComponent}
                label={formatMessage({ id: 'CORE.LABEL.ACTIVATED' })}
                isRequired
                disabled={isExpired}
              />
            </Col>
          )}
        </Row>
      </ModalBody>
      <ModalFooter>
        <Button type="button" disabled={submitting} color="secondary" onClick={closeModal}>
          {formatMessage({ id: 'CORE.BUTTON.CANCEL' })}
        </Button>
        <Button disabled={pristine || submitting} type="submit" color="primary-gradient">
          {formatMessage({ id: 'CORE.BUTTON.SUBMIT' })}
        </Button>
      </ModalFooter>
    </Form>
  );
};

export const PatientInsuranceForm = reduxForm<PatientInsuranceFormData, Props>({
  form: FORMS_PATIENT_PROFILE_INSURANCE,
  enableReinitialize: true,
  validate,
  touchOnChange: true,
})(FormComponent);
