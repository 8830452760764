import { PayloadAction } from '@reduxjs/toolkit';
import { Meta } from 'app/sagas/billingItemLists/types';
import { refreshPerformerItemLists } from 'app/sagas/billingItemLists/generators/refreshPerformerItemLists';
import { TOGGLE_PERFORMER_ITEM_SUCCESS } from 'app/redux/billingItemsLists/billingItemsLists.types';

export function* afterToggleBillingItemSuccess({ meta, type }: PayloadAction<void, string, Meta>) {
  if (type === TOGGLE_PERFORMER_ITEM_SUCCESS) {
    yield refreshPerformerItemLists(meta.branchId, true);
  }
}
