import { connect } from 'react-redux';
import { updateIntl } from 'react-intl-redux';
import { LANGUAGES_AR_CODE } from 'app/shared';
import {
  fetchAppointmentConfirmation,
  confirmAppointment,
  cancelAppointment,
} from 'app/redux/appointments/appointments.actions';
import { ConfirmAppointmentComponent } from './confirm-appointment.component';
import { translations } from '../../i18n';

const mapStateToProps = ({
  appointments: { confirmation },
  router: {
    location: { search },
  },
}) => ({
  appointment: confirmation.data,
  appointmentLoading: confirmation.loading,
  code: new URLSearchParams(search).get('code'),
  lang: new URLSearchParams(search).get('lang') || LANGUAGES_AR_CODE,
});

const mapDispatchToProps = (dispatch) => ({
  fetchAppointment: (id, params) => dispatch(fetchAppointmentConfirmation(id, params)),
  onConfirmAppointment: (id, params) => dispatch(confirmAppointment(id, params)),
  onCancelAppointment: (id, params) => dispatch(cancelAppointment(id, params)),
  changeLanguage: (code) =>
    dispatch(
      updateIntl({
        locale: code,
        messages: translations[code],
      }),
    ),
});

export const ConfirmAppointmentContainer = connect(mapStateToProps, mapDispatchToProps)(ConfirmAppointmentComponent);
