import React, { useCallback, useMemo } from 'react';
import { Col, Row } from 'reactstrap';

import { Box, StickyPagination } from 'app/components';
import { DoctorWorkingDayFormatter, NoDataMessage } from 'app/bookAppointment/bookAppointmentWizard/shared';
import { DEFAULT_PAGINATION_PARAMS, Loader } from 'app/shared';
import { Option, RemoteDataParams } from 'app/types';
import { SelectedDay } from 'app/redux/calendar/types';
import { useAppSelector } from 'app/helpers';
import './doctorWorkingDayList.scss';

interface Props {
  onSelectDate: (day: SelectedDay) => void;
  onFetchCalendarItems: (params: RemoteDataParams) => void;
}

export const DoctorWorkingDayList = ({ onSelectDate, onFetchCalendarItems }: Props) => {
  const { data, loading, params } = useAppSelector((state) => state.calendar.tableViewItems);

  const pageSizeOptions: Option<number>[] = useMemo(
    () => [
      { label: '5', value: 5 },
      { label: '10', value: 10 },
      { label: '15', value: 15 },
    ],
    [],
  );

  const onPageChange = useCallback(
    (page: number, sizePerPage: number) => {
      onFetchCalendarItems({ page, sizePerPage });
    },
    [onFetchCalendarItems],
  );

  const onSizePerPageChange = useCallback(
    (sizePerPage: number, page: number) => {
      onFetchCalendarItems({ page, sizePerPage });
    },
    [onFetchCalendarItems],
  );

  return (
    <div className="doctorWorkingDayList">
      {loading && <Loader />}

      {!loading && data.length === 0 && (
        <NoDataMessage customMessageId="BOOK-APPOINTMENT.TEXT.NO-WH-FOR-SELECTED-SERVICE" />
      )}

      {!loading && (
        <Row>
          {data.map((item, i) => {
            return (
              <Col key={i} lg={12} xl={6} className="mb-2 doctorWorkingDayList__item">
                <Box className="h-100">
                  <DoctorWorkingDayFormatter doctor={item.doctor} item={item} onSelectDate={onSelectDate} />
                </Box>
              </Col>
            );
          })}
        </Row>
      )}

      {(loading || data.length > 0) && (
        <StickyPagination
          loading={loading}
          onPageChange={onPageChange}
          onSizePerPageChange={onSizePerPageChange}
          page={params?.page || 1}
          pageSizeOptions={pageSizeOptions}
          sizePerPage={params?.sizePerPage || DEFAULT_PAGINATION_PARAMS.sizePerPage}
          totalSize={params?.totalSize || 1}
        />
      )}
    </div>
  );
};
