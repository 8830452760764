import isEmpty from 'lodash/isEmpty';
import { LANGUAGES_AR_CODE } from 'app/shared';

export const getName = (object, locale) => {
  if (isEmpty(object)) {
    return '';
  }

  return locale === LANGUAGES_AR_CODE ? object.nameAr : object.nameEn;
};
