import { Button } from 'reactstrap';
import { IconArrowLeft, IconArrowRight, IconHelp, IconTrash, IconX } from '@tabler/icons-react';
import { useAppIntl } from 'app/helpers';
import { IconButton } from 'app/components/iconButton/iconButton';
import styles from './actions.module.scss';

interface Props {
  back?: () => void;
  close: () => void;
  help?: () => void;
  isReadOnly?: boolean;
  remove?: () => void;
  save?: () => void;
}

const size = 22;
const strokeWidth = 1.8;

export const Actions = ({ back, close, help, remove, save, isReadOnly }: Props) => {
  const { formatMessage, isRtl } = useAppIntl();

  return (
    <>
      <div className={`${styles.actions} ${styles.additionalActions}`}>
        {back && !isReadOnly && (
          <IconButton
            className={`${styles.iconButton} me-2`}
            icon={
              isRtl ? (
                <IconArrowRight size={size} strokeWidth={strokeWidth} />
              ) : (
                <IconArrowLeft size={size} strokeWidth={strokeWidth} />
              )
            }
            onClick={back}
          />
        )}
        {help && !isReadOnly && (
          <IconButton
            className={styles.iconButton}
            icon={<IconHelp size={size} strokeWidth={strokeWidth} onClick={help} />}
          />
        )}
      </div>
      <div className={`${styles.actions} ${styles.mainActions}`}>
        {save && !isReadOnly && (
          <Button onClick={save} className="me-3 px-4 btn" color="primary-gradient">
            {formatMessage({ id: 'CORE.BUTTON.SAVE', defaultMessage: 'Save' })}
          </Button>
        )}
        {remove && !isReadOnly && (
          <IconButton
            className={`${styles.iconButton} me-2`}
            icon={<IconTrash size={size} strokeWidth={strokeWidth} />}
          />
        )}
        <IconButton
          className={styles.iconButton}
          icon={<IconX size={size} strokeWidth={strokeWidth} />}
          onClick={close}
        />
      </div>
    </>
  );
};
