export const FETCH_DOCTOR_REQUEST = 'bookingWizard/FETCH_DOCTOR_REQUEST';
export const FETCH_DOCTOR_SUCCESS = 'bookingWizard/FETCH_DOCTOR_SUCCESS';
export const FETCH_DOCTOR_FAILURE = 'bookingWizard/FETCH_DOCTOR_FAILURE';

export const FETCH_WORKING_TIMES_REQUEST = 'bookingWizard/FETCH_WORKING_TIMES_REQUEST';
export const FETCH_WORKING_TIMES_SUCCESS = 'bookingWizard/FETCH_WORKING_TIMES_SUCCESS';
export const FETCH_WORKING_TIMES_FAILURE = 'bookingWizard/FETCH_WORKING_TIMES_FAILURE';

export const FETCH_SUB_SPECIALITIES_REQUEST = 'bookingWizard/FETCH_SUB_SPECIALITIES_REQUEST';
export const FETCH_SUB_SPECIALITIES_SUCCESS = 'bookingWizard/FETCH_SUB_SPECIALITIES_SUCCESS';
export const FETCH_SUB_SPECIALITIES_FAILURE = 'bookingWizard/FETCH_SUB_SPECIALITIES_FAILURE';

export const FETCH_PRODUCTS_REQUEST = 'bookingWizard/FETCH_PRODUCTS_REQUEST';
export const FETCH_PRODUCTS_SUCCESS = 'bookingWizard/FETCH_PRODUCTS_SUCCESS';
export const FETCH_PRODUCTS_FAILURE = 'bookingWizard/FETCH_PRODUCTS_FAILURE';

export const FETCH_DAY_REQUEST = 'bookingWizard/FETCH_DAY_REQUEST';
export const FETCH_DAY_SUCCESS = 'bookingWizard/FETCH_DAY_SUCCESS';
export const FETCH_DAY_FAILURE = 'bookingWizard/FETCH_DAY_FAILURE';

export const FETCH_IS_SLOT_FREE_REQUEST = 'bookingWizard/FETCH_IS_SLOT_FREE_REQUEST';
export const FETCH_IS_SLOT_FREE_SUCCESS = 'bookingWizard/FETCH_IS_SLOT_FREE_SUCCESS';
export const FETCH_IS_SLOT_FREE_FAILURE = 'bookingWizard/FETCH_IS_SLOT_FREE_FAILURE';

export const RESET = 'bookingWizard/RESET';
export const RESET_PATIENT = 'bookingWizard/RESET_PATIENT';

export const SET_INITIAL_DATA = 'bookingWizard/SET_INITIAL_DATA';
export const SET_ACTIVE_STEP = 'bookingWizard/SET_ACTIVE_STEP';
export const SET_PATIENT = 'bookingWizard/SET_PATIENT';

export const SELECT_DATE = 'bookingWizard/SELECT_DATE';
export const SELECT_SERVICE = 'bookingWizard/SELECT_SERVICE';
export const SELECT_SUB_SPECIALITY = 'bookingWizard/SELECT_SUB_SPECIALITY';
export const SELECT_TIME = 'bookingWizard/SELECT_TIME';
export const SELECT_WORKING_TIME = 'bookingWizard/SELECT_WORKING_TIME';
export const SELECT_SLOT = 'bookingWizard/SELECT_SLOT';
