import { Badge } from 'reactstrap';
import { ColumnFormatter } from 'react-bootstrap-table-next';
import { getTranslation } from 'app/helpers';
import { InvoiceCreditNotePayment, InvoicePaymentMethodItem, PatientPaymentMethod } from 'app/types';
import { FormatExtraData } from 'app/features/invoicingProcess/process/paymentMethod/types';

export const MethodFormatter: ColumnFormatter<InvoicePaymentMethodItem | InvoiceCreditNotePayment, FormatExtraData> = (
  _,
  row,
  __,
  formatExtraData,
) => {
  const methodName =
    row.paymentMethod.value === PatientPaymentMethod.Other && 'otherPaymentMethod' in row
      ? row.otherPaymentMethod
      : getTranslation(row.paymentMethod, formatExtraData?.locale);

  return (
    <>
      {'isDefault' in row && row.isDefault && formatExtraData?.formatMessage && (
        <Badge color="light-secondary" className="me-2">
          {formatExtraData.formatMessage({ id: 'CORE.TEXT.INITIAL', defaultMessage: 'Initial' })}
        </Badge>
      )}
      <span>{methodName}</span>
    </>
  );
};
