import { SignatureFormData } from 'app/assessment/forms/signatureForm/types';
import { AssessmentReferral, DoctorAssessmentParams, DoctorAssessment } from 'app/redux/doctorAssessment/types';
import { BillingFormData } from 'app/assessment/billingSection/forms/billingForm/billingForm';
import {
  billingValuesAdapter,
  medicalProfileValuesAdapter,
  prescriptionValuesAdapter,
  signatureValuesAdapter,
} from 'app/assessment/helpers';
import { MedicalProfileFormData } from 'app/assessment/medicalProfileSection/components/practitionerAssessment/practitionerAssessment';
import { referralValuesAdapter } from 'app/assessment/helpers/referralValuesAdapter';
import { WrappedPrescriptionFormData } from 'app/assessment/prescriptionSection/types';
import { AssessmentOrdersFormData } from 'app/assessment/ordersSection/forms/ordersForm/types';
import { ordersValuesAdapter } from 'app/assessment/helpers/ordersValuesAdapter';

interface Props {
  doctorAssessmentData: DoctorAssessment | null;
  medicalProfileValues?: MedicalProfileFormData;
  billingValues?: BillingFormData;
  ordersValues?: AssessmentOrdersFormData;
  prescriptionValues?: WrappedPrescriptionFormData[];
  referralValues?: AssessmentReferral[];
  signatureValues?: SignatureFormData;
}

export const useCreateBodyParams = ({
  doctorAssessmentData,
  medicalProfileValues,
  billingValues,
  ordersValues,
  prescriptionValues,
  referralValues,
  signatureValues,
}: Props): DoctorAssessmentParams => {
  // Sections Params
  const medicalProfileParams = medicalProfileValuesAdapter(medicalProfileValues);
  const billingParams = billingValuesAdapter(billingValues);
  const ordersParams = ordersValuesAdapter(ordersValues);
  const prescriptionsParams = prescriptionValues && prescriptionValuesAdapter(prescriptionValues);
  const referralsParams = referralValuesAdapter(referralValues);
  const signatureParams = signatureValuesAdapter(signatureValues);

  return {
    isDoctorExcluded: !!doctorAssessmentData?.isDoctorExcluded,
    isSpecialityExcluded: !!doctorAssessmentData?.isSpecialityExcluded,
    appointmentId: doctorAssessmentData?.appointmentId,
    previousDoctorAssessmentId: doctorAssessmentData?.previousDoctorAssessmentId,
    assessmentPrescriptions: prescriptionsParams,
    ...billingParams,
    ...ordersParams,
    ...signatureParams,
    ...medicalProfileParams,
    referrals: referralsParams,
  };
};
