import { useCallback } from 'react';
import { UncontrolledTooltip } from 'reactstrap';
import { IconUsers } from '@tabler/icons-react';
import { useAppDispatch, useAppIntl } from 'app/helpers';
import { setPatientBadgeMergeModalId } from 'app/redux/patient/patientSlice';
import { toggleModal } from 'app/redux/modals/modals.actions';

interface Props {
  id?: string;
  hasMergePatientsBadge: boolean;
}

export const MergeBadge = ({ id, hasMergePatientsBadge }: Props) => {
  const { formatMessage } = useAppIntl();
  const dispatch = useAppDispatch();

  const target = `merge-badge-${id}`;

  const onPatientMergeClick = useCallback(() => {
    if (id) {
      dispatch(setPatientBadgeMergeModalId(id));
      dispatch(toggleModal('patientMergeInfo', true));
    }
  }, [dispatch, id]);

  if (!hasMergePatientsBadge || !id) {
    return null;
  }

  return (
    <div className="mx-1 cursor-pointer">
      <IconUsers size={18} strokeWidth={1.8} onClick={onPatientMergeClick} id={target} />
      <UncontrolledTooltip target={target} delay={{ show: 0, hide: 0 }}>
        {formatMessage({ id: 'PATIENTS.TEXT.PATIENT-MERGE-TOOLTIP' })}
      </UncontrolledTooltip>
    </div>
  );
};
