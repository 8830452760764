import { put, select } from 'redux-saga/effects';
import { PayloadAction } from '@reduxjs/toolkit';
import { toggleModal } from 'app/redux/modals/modals.actions';
import { fetchBranchBillingItems } from 'app/redux/remoteData/remoteData.actions';
import { BillingItem, RootState } from 'app/types';
import { Meta } from 'app/sagas/billingItem/types';

export function* afterAddBillingItemSuccess({ meta }: PayloadAction<{ data: BillingItem }, string, Meta>) {
  const {
    remoteData: {
      branchBillingItems: { params },
    },
  }: RootState = yield select();

  yield put(toggleModal('addBranchBillingItem', false));
  if (params) {
    yield put<any>(fetchBranchBillingItems(meta.branchId, params));
  }
}
