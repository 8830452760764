import { CalendarItem, CalendarWeek } from 'app/types';

export const replaceWeekDays = (items: CalendarItem[], week: CalendarWeek): CalendarItem[] => {
  return items.map((item) => {
    const { branchId, days, doctorId, productId } = week;

    if (branchId === item.branch.id && doctorId === item.doctor.id && productId === item.product.value) {
      return { ...item, days };
    }

    return item;
  });
};
