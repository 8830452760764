import { select } from 'redux-saga/effects';
import { PayloadAction } from '@reduxjs/toolkit';
import { Locale, RootState } from 'app/types';
import { InvoicingProcessPayload } from 'app/redux/invoicingProcess/types';
import { initializeInvoicingProcess } from 'app/sagas/invoicingProcess/generators/initializeInvoicingProcess';

export function* afterFetchSuccess({ payload }: PayloadAction<InvoicingProcessPayload | undefined>) {
  const {
    intl: { locale },
  }: RootState = yield select();

  if (payload?.data) {
    yield initializeInvoicingProcess(payload.data, { locale: locale as Locale, withStepDetection: true });
  }
}
