import { put, select } from 'redux-saga/effects';
import { RootState } from 'app/types';
import { fetchWeekCalendar } from 'app/redux/remoteData/remoteData.actions';
import { RemoteDataActionConfiguration } from 'app/redux/remoteData/types';

/**
 This function refreshes the Calendar Week View if this location is selected
 */
export function* refreshCalendarWeekView(configuration?: RemoteDataActionConfiguration) {
  const {
    remoteData: {
      weekCalendar: { params },
    },
    router: {
      location: { pathname },
    },
  }: RootState = yield select();

  if (
    pathname.includes('calendar/week') &&
    params &&
    params.branchId &&
    params.doctorId &&
    params.startDate &&
    params.endDate
  ) {
    yield put<any>(
      fetchWeekCalendar(
        {
          ...params,
          branchId: params.branchId,
          doctorId: params.doctorId,
          startDate: params.startDate,
          endDate: params.endDate,
          subSpecialityId: params.subSpecialityId,
        },
        configuration || { isSoft: true },
      ),
    );
  }
}
