import { PatientPaymentMethod, InvoicePaymentMethodItem } from 'app/types';
import { Payment } from 'app/redux/invoicingProcess/types';

export const paymentsAdapter = (paymentMethodItems: InvoicePaymentMethodItem[]): Payment[] => {
  // Remove payments returned to the user's wallet
  const items = paymentMethodItems.filter((item) => !item.creditNotePayment);

  return items.map((payment) => ({
    amount: payment.amount,
    id: payment.id,
    notes: payment.notes,
    paymentMethod: payment.paymentMethod.value,
    ...(payment.paymentMethod.value === PatientPaymentMethod.Other && {
      otherPaymentMethod: payment.otherPaymentMethod,
    }),
  }));
};
