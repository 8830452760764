import { PAGINATION_DEFAULT_PARAMS } from 'app/shared';
import { doctorServiceAdapter, removeItemFromArrayById } from 'app/helpers';
import * as types from './doctor.types';
import { updateProductList } from './helpers';

const initialState = {
  loading: false,
  error: false,
  data: null,
  busyTime: {
    data: [],
    pagination: PAGINATION_DEFAULT_PARAMS,
    loading: false,
  },
  workingHours: {
    current: {
      custom: {
        list: [],
        pagination: PAGINATION_DEFAULT_PARAMS,
        loading: false,
      },
      standard: {
        list: [],
        pagination: PAGINATION_DEFAULT_PARAMS,
        loading: false,
      },
    },
    past: {
      custom: {
        list: [],
        pagination: PAGINATION_DEFAULT_PARAMS,
        loading: false,
      },
      standard: {
        list: [],
        pagination: PAGINATION_DEFAULT_PARAMS,
        loading: false,
      },
    },
    changes: false,
    selected: null,
    selectedBranch: {},
    conflictingAppointmentsCount: 0,
  },
  services: [],
  branches: [],
  assignableBranches: {
    list: [],
    loading: false,
  },

  // Products
  productsModal: {
    isOpen: false,
    workingTimeId: null,
  },
  addedProducts: [],
  updatedProducts: [],
};

export const doctorReducer = (state = initialState, action) => {
  const isSilent = action.meta?.isSilent;

  switch (action.type) {
    case types.FETCH_DOCTOR_REQUEST:
      return {
        ...state,
        data: initialState.data,
        loading: true,
      };
    case types.FETCH_DOCTOR_SUCCESS:
      return {
        ...state,
        data: action.payload.data,
        loading: false,
      };
    case types.EDIT_MEDICAL_SPECIALITY_SUCCESS:
      return {
        ...state,
        services: {
          data: [],
          pagination: {},
        },
      };
    case types.FETCH_DOCTOR_FAILURE:
      return {
        ...state,
        data: action.payload,
        loading: false,
        error: true,
      };
    case types.FETCH_DOCTOR_BUSY_TIME_REQUEST:
      return {
        ...state,
        busyTime: {
          ...initialState.busyTime,
          loading: true,
        },
      };
    case types.FETCH_DOCTOR_BUSY_TIME_SUCCESS:
      return {
        ...state,
        busyTime: {
          data: action.payload.data,
          pagination: {
            ...state.busyTime.pagination,
            ...action.payload.pagination,
          },
          loading: false,
        },
      };
    case types.FETCH_DOCTOR_BUSY_TIME_FAILURE:
      return {
        ...state,
        busyTime: {
          ...state.busyTime,
          loading: false,
        },
      };
    case types.SELECT_WORKING_HOURS:
      return {
        ...state,
        workingHours: {
          ...state.workingHours,
          selected: action.payload,
        },
      };
    case types.FETCH_DOCTOR_CURRENT_WORKING_HOURS_REQUEST:
      return {
        ...state,
        workingHours: {
          ...state.workingHours,
          current: {
            ...state.workingHours.current,
            standard: {
              ...initialState.workingHours.current.standard,
              loading: true,
            },
          },
        },
      };
    case types.FETCH_DOCTOR_CURRENT_WORKING_HOURS_SUCCESS:
      return {
        ...state,
        workingHours: {
          ...state.workingHours,
          current: {
            ...state.workingHours.current,
            standard: {
              list: action.payload.data,
              pagination: {
                ...state.workingHours.current.standard.pagination,
                ...action.payload.pagination,
              },
              loading: false,
            },
          },
        },
      };
    case types.FETCH_DOCTOR_CURRENT_WORKING_HOURS_FAILURE:
      return {
        ...state,
        workingHours: {
          ...state.workingHours,
          current: {
            ...initialState.workingHours.current,
            standard: {
              ...initialState.workingHours.current.standard,
            },
          },
        },
      };
    case types.FETCH_DOCTOR_CURRENT_CUSTOM_WORKING_HOURS_REQUEST:
      return {
        ...state,
        workingHours: {
          ...state.workingHours,
          current: {
            ...state.workingHours.current,
            custom: {
              ...initialState.workingHours.current.custom,
              loading: true,
            },
          },
        },
      };
    case types.FETCH_DOCTOR_CURRENT_CUSTOM_WORKING_HOURS_SUCCESS:
      return {
        ...state,
        workingHours: {
          ...state.workingHours,
          current: {
            ...state.workingHours.current,
            custom: {
              list: action.payload.data,
              pagination: {
                ...state.workingHours.current.custom.pagination,
                ...action.payload.pagination,
              },
              loading: false,
            },
          },
        },
      };
    case types.FETCH_DOCTOR_CURRENT_CUSTOM_WORKING_HOURS_FAILURE:
      return {
        ...state,
        workingHours: {
          ...state.workingHours,
          current: {
            ...initialState.workingHours.current,
            custom: {
              ...initialState.workingHours.current.custom,
            },
          },
        },
      };
    case types.FETCH_DOCTOR_PAST_WORKING_HOURS_REQUEST:
      return {
        ...state,
        workingHours: {
          ...state.workingHours,
          past: {
            ...state.workingHours.past,
            standard: {
              ...state.workingHours.past.standard,
              loading: true,
            },
          },
        },
      };
    case types.FETCH_DOCTOR_PAST_WORKING_HOURS_SUCCESS:
      return {
        ...state,
        workingHours: {
          ...state.workingHours,
          past: {
            ...state.workingHours.past,
            standard: {
              list: action.payload.data,
              pagination: {
                ...state.workingHours.past.standard.pagination,
                ...action.payload.pagination,
              },
              loading: false,
            },
          },
        },
      };
    case types.FETCH_DOCTOR_PAST_WORKING_HOURS_FAILURE:
      return {
        ...state,
        workingHours: {
          ...state.workingHours,
          past: {
            ...state.workingHours.past,
            standard: initialState.workingHours.past.standard,
          },
        },
      };
    case types.FETCH_DOCTOR_PAST_CUSTOM_WORKING_HOURS_REQUEST:
      return {
        ...state,
        workingHours: {
          ...state.workingHours,
          past: {
            ...state.workingHours.past,
            custom: {
              ...state.workingHours.past.standard,
              loading: true,
            },
          },
        },
      };
    case types.FETCH_DOCTOR_PAST_CUSTOM_WORKING_HOURS_SUCCESS:
      return {
        ...state,
        workingHours: {
          ...state.workingHours,
          past: {
            ...state.workingHours.past,
            custom: {
              list: action.payload.data,
              pagination: {
                ...state.workingHours.past.custom.pagination,
                ...action.payload.pagination,
              },
              loading: false,
            },
          },
        },
      };
    case types.FETCH_DOCTOR_PAST_CUSTOM_WORKING_HOURS_FAILURE:
      return {
        ...state,
        workingHours: {
          ...state.workingHours,
          past: {
            ...state.workingHours.past,
            custom: initialState.workingHours.past.custom,
          },
        },
      };
    case types.ADD_DOCTOR_WORKING_HOURS_REQUEST:
      return {
        ...state,
        workingHours: {
          ...state.workingHours,
          current: {
            ...state.workingHours.current,
          },
          changes: true,
        },
      };
    case types.ADD_DOCTOR_WORKING_HOURS_SUCCESS:
      return {
        ...state,
        workingHours: {
          ...state.workingHours,
          current: {
            ...state.workingHours.current,
          },
          changes: true,
        },
      };
    case types.ADD_DOCTOR_CUSTOM_WORKING_HOURS_REQUEST:
      return {
        ...state,
        workingHours: {
          ...state.workingHours,
          current: {
            ...state.workingHours.current,
          },
          changes: true,
        },
      };
    case types.DELETE_DOCTOR_WORKING_HOURS_SUCCESS:
      return {
        ...state,
        workingHours: {
          ...state.workingHours,
          current: {
            ...state.workingHours.current,
            standard: {
              ...state.workingHours.current.standard,
              list: removeItemFromArrayById(state.workingHours.current.standard.list, action.meta.workingHourId),
            },
          },
          changes: true,
        },
      };
    case types.DELETE_DOCTOR_CUSTOM_WORKING_HOURS_SUCCESS:
      return {
        ...state,
        workingHours: {
          ...state.workingHours,
          current: {
            ...state.workingHours.current,
            custom: {
              ...state.workingHours.current.custom,
              list: removeItemFromArrayById(state.workingHours.current.custom.list, action.meta.workingHourId),
            },
          },
          changes: true,
        },
      };
    case types.CANCEL_DOCTOR_WORKING_HOURS_CHANGES:
      return {
        ...state,
        workingHours: {
          ...state.workingHours,
          changes: false,
        },
      };
    case types.FETCH_DOCTOR_SUB_SPECIALITIES_REQUEST:
      return {
        ...state,
        ...(!isSilent && {
          loading: true,
        }),
      };
    case types.FETCH_DOCTOR_SUB_SPECIALITIES_SUCCESS:
      return {
        ...state,
        loading: false,
        services: {
          ...action.payload,
          data: doctorServiceAdapter(action.payload.data),
        },
      };
    case types.FETCH_DOCTOR_SUB_SPECIALITIES_FAIL:
      return {
        ...state,
        loading: false,
      };
    case types.SET_DOCTOR_WORKING_HOUR_SELECTED_BRANCH:
      return {
        ...state,
        workingHours: {
          ...state.workingHours,
        },
      };
    case types.FETCH_DOCTOR_ASSIGNABLE_BRANCHES_REQUEST:
      return {
        ...state,
        assignableBranches: {
          list: [],
          loading: true,
        },
      };
    case types.FETCH_DOCTOR_ASSIGNABLE_BRANCHES_FAILURE:
      return {
        ...state,
        assignableBranches: {
          list: [],
          loading: false,
        },
      };
    case types.FETCH_DOCTOR_ASSIGNABLE_BRANCHES_SUCCESS:
      return {
        ...state,
        assignableBranches: {
          list: action.payload,
          loading: false,
        },
      };
    case types.FETCH_DOCTOR_BRANCHES_REQUEST:
      return {
        ...state,
        branches: [],
      };
    case types.FETCH_DOCTOR_BRANCHES_SUCCESS:
      return {
        ...state,
        branches: action.payload,
      };
    case types.FETCH_DOCTOR_BRANCHES_FAILURE:
      return {
        ...state,
        branches: [],
      };
    case types.SET_PRODUCTS_MODAL:
      return {
        ...state,
        productsModal: {
          isOpen: action.payload.isOpen,
          workingTimeId: action.payload.workingTimeId,
        },
      };
    case types.ADD_PRODUCT:
      return {
        ...state,
        addedProducts: updateProductList(state.addedProducts, action.payload),
      };
    case types.UPDATE_PRODUCT:
      return {
        ...state,
        updatedProducts: updateProductList(state.updatedProducts, action.payload),
      };
    case types.RESET_PRODUCTS:
      return {
        ...state,
        addedProducts: [],
        updatedProducts: [],
      };
    case types.FETCH_CONFLICTING_APPOINTMENTS_VALUE_REQUEST:
      return {
        ...state,
        workingHours: {
          ...state.workingHours,
          conflictingAppointmentsCount: action.meta.isUpdate
            ? state.workingHours.conflictingAppointmentsCount
            : initialState.workingHours.conflictingAppointmentsCount,
        },
      };
    case types.FETCH_CONFLICTING_APPOINTMENTS_VALUE_SUCCESS:
      return {
        ...state,
        workingHours: {
          ...state.workingHours,
          conflictingAppointmentsCount: action.payload.data.value,
        },
      };
    case types.FETCH_CONFLICTING_APPOINTMENTS_VALUE_FAILURE:
      return {
        ...state,
        workingHours: {
          ...state.workingHours,
          conflictingAppointmentsCount: initialState.workingHours.conflictingAppointmentsCount,
        },
      };
    default:
      return state;
  }
};
