import * as types from './massAdding.types';
import { AnyAction } from 'redux';
import { MassAdding, MassImport } from './types';

interface MassAddingReducer {
  billingItemsLists: MassAdding;
  branchBillingItems: MassAdding;
  products: MassAdding;
  billingItemsListsImport: MassImport;
  branchPackage: MassAdding;
  practitionerPackage: MassAdding;
  practitionerPackageImport: MassImport;
  branchMedication: MassAdding;
  performerMedication: MassAdding;
}

const initialState: MassAddingReducer = {
  branchBillingItems: {
    errors: [],
    warnings: [],
    loading: false,
    sent: false,
  },
  products: {
    errors: [],
    warnings: [],
    loading: false,
    sent: false,
  },
  billingItemsLists: {
    errors: [],
    warnings: [],
    loading: false,
    sent: false,
  },
  billingItemsListsImport: {
    errors: [],
    warnings: [],
    loading: false,
    sent: false,
  },
  branchPackage: {
    errors: [],
    warnings: [],
    loading: false,
    sent: false,
  },
  practitionerPackage: {
    errors: [],
    warnings: [],
    loading: false,
    sent: false,
  },
  practitionerPackageImport: {
    errors: [],
    warnings: [],
    loading: false,
    sent: false,
  },
  branchMedication: {
    errors: [],
    warnings: [],
    loading: false,
    sent: false,
  },
  performerMedication: {
    errors: [],
    warnings: [],
    loading: false,
    sent: false,
  },
};

export const massAddingReducer = (state = initialState, action: AnyAction): MassAddingReducer => {
  switch (action.type) {
    // Branch Billing Items
    case types.MASS_ADD_BRANCH_BILLING_ITEMS_FAILURE:
      return {
        ...state,
        branchBillingItems: {
          errors: action.payload.response,
          warnings: [],
          loading: false,
          sent: true,
        },
      };
    case types.MASS_ADD_BRANCH_BILLING_ITEMS_SUCCESS:
      return {
        ...state,
        branchBillingItems: {
          errors: [],
          warnings: action.payload,
          loading: false,
          sent: true,
        },
      };
    case types.MASS_ADD_BRANCH_BILLING_ITEMS_REQUEST:
      return {
        ...state,
        branchBillingItems: {
          errors: [],
          warnings: [],
          loading: true,
          sent: false,
        },
      };
    case types.MASS_ADD_BRANCH_BILLING_ITEMS_RESET:
      return {
        ...state,
        branchBillingItems: {
          ...initialState.branchBillingItems,
        },
      };

    // Products
    case types.MASS_ADD_PRODUCTS_FAILURE:
      return {
        ...state,
        products: {
          errors: action.payload.response,
          warnings: [],
          loading: false,
          sent: true,
        },
      };
    case types.MASS_ADD_PRODUCTS_SUCCESS:
      return {
        ...state,
        products: {
          errors: [],
          warnings: action.payload,
          loading: false,
          sent: true,
        },
      };
    case types.MASS_ADD_PRODUCTS_REQUEST:
      return {
        ...state,
        products: {
          errors: [],
          warnings: [],
          loading: true,
          sent: false,
        },
      };
    case types.MASS_ADD_PRODUCTS_RESET:
      return {
        ...state,
        products: {
          ...initialState.products,
        },
      };

    case types.MASS_ADD_BILLING_ITEMS_FAILURE:
      return {
        ...state,
        billingItemsLists: {
          errors: action.payload.response,
          warnings: [],
          loading: false,
          sent: true,
        },
      };
    case types.MASS_ADD_BILLING_ITEMS_SUCCESS:
      return {
        ...state,
        billingItemsLists: {
          errors: [],
          warnings: action.payload,
          loading: false,
          sent: true,
        },
      };
    case types.MASS_ADD_BILLING_ITEMS_REQUEST:
      return {
        ...state,
        billingItemsLists: {
          errors: [],
          warnings: [],
          loading: true,
          sent: false,
        },
      };
    case types.MASS_ADD_BILLING_ITEMS_RESET:
      return {
        ...state,
        billingItemsLists: {
          ...initialState.billingItemsLists,
        },
      };

    // Request Details
    case types.MASS_IMPORT_BILLING_ITEMS_FAILURE:
      return {
        ...state,
        billingItemsListsImport: {
          errors: action.payload.response,
          warnings: [],
          loading: false,
          sent: true,
        },
      };
    case types.MASS_IMPORT_BILLING_ITEMS_SUCCESS:
      return {
        ...state,
        billingItemsListsImport: {
          errors: [],
          warnings: action.payload,
          loading: false,
          sent: true,
        },
      };
    case types.MASS_IMPORT_BILLING_ITEMS_REQUEST:
      return {
        ...state,
        billingItemsListsImport: {
          errors: [],
          warnings: [],
          loading: true,
          sent: false,
        },
      };
    case types.MASS_IMPORT_BILLING_ITEMS_RESET:
      return {
        ...state,
        billingItemsListsImport: {
          ...initialState.billingItemsListsImport,
        },
      };
    case types.MASS_ADD_BRANCH_PACKAGES_REQUEST:
      return {
        ...state,
        branchPackage: {
          errors: [],
          warnings: [],
          loading: true,
          sent: false,
        },
      };
    case types.MASS_ADD_BRANCH_PACKAGES_SUCCESS:
      return {
        ...state,
        branchPackage: {
          errors: [],
          warnings: action.payload,
          loading: false,
          sent: true,
        },
      };
    case types.MASS_ADD_BRANCH_PACKAGES_FAILURE:
      return {
        ...state,
        branchPackage: {
          errors: action.payload.response,
          warnings: [],
          loading: false,
          sent: true,
        },
      };
    case types.MASS_ADD_BRANCH_PACKAGES_RESET:
      return {
        ...state,
        branchPackage: {
          ...initialState.branchPackage,
        },
      };
    case types.MASS_ADD_PRACTITIONER_PACKAGES_REQUEST:
      return {
        ...state,
        practitionerPackage: {
          errors: [],
          warnings: [],
          loading: true,
          sent: false,
        },
      };
    case types.MASS_ADD_PRACTITIONER_PACKAGES_SUCCESS:
      return {
        ...state,
        practitionerPackage: {
          errors: [],
          warnings: action.payload,
          loading: false,
          sent: true,
        },
      };
    case types.MASS_ADD_PRACTITIONER_PACKAGES_FAILURE:
      return {
        ...state,
        practitionerPackage: {
          errors: action.payload.response,
          warnings: [],
          loading: false,
          sent: true,
        },
      };
    case types.MASS_ADD_PRACTITIONER_PACKAGES_RESET:
      return {
        ...state,
        practitionerPackage: {
          ...initialState.practitionerPackage,
        },
      };
    case types.MASS_IMPORT_PRACTITIONER_PACKAGES_FAILURE:
      return {
        ...state,
        practitionerPackageImport: {
          errors: action.payload.response,
          warnings: [],
          loading: false,
          sent: true,
        },
      };
    case types.MASS_IMPORT_PRACTITIONER_PACKAGES_SUCCESS:
      return {
        ...state,
        practitionerPackageImport: {
          errors: [],
          warnings: action.payload,
          loading: false,
          sent: true,
        },
      };
    case types.MASS_IMPORT_PRACTITIONER_PACKAGES_REQUEST:
      return {
        ...state,
        practitionerPackageImport: {
          errors: [],
          warnings: [],
          loading: true,
          sent: false,
        },
      };
    case types.MASS_IMPORT_PRACTITIONER_PACKAGES_RESET:
      return {
        ...state,
        practitionerPackageImport: {
          ...initialState.practitionerPackageImport,
        },
      };

    case types.MASS_ADD_BRANCH_MEDICATIONS_REQUEST:
      return {
        ...state,
        branchMedication: {
          errors: [],
          warnings: [],
          loading: true,
          sent: false,
        },
      };
    case types.MASS_ADD_BRANCH_MEDICATIONS_SUCCESS:
      return {
        ...state,
        branchMedication: {
          errors: [],
          warnings: action.payload,
          loading: false,
          sent: true,
        },
      };
    case types.MASS_ADD_BRANCH_MEDICATIONS_FAILURE:
      return {
        ...state,
        branchMedication: {
          errors: action.payload.response,
          warnings: [],
          loading: false,
          sent: true,
        },
      };
    case types.MASS_ADD_BRANCH_MEDICATIONS_RESET:
      return {
        ...state,
        branchMedication: {
          ...initialState.branchMedication,
        },
      };

    case types.MASS_ADD_PERFORMERS_MEDICATIONS_REQUEST:
      return {
        ...state,
        performerMedication: {
          errors: [],
          warnings: [],
          loading: true,
          sent: false,
        },
      };
    case types.MASS_ADD_PERFORMERS_MEDICATIONS_SUCCESS:
      return {
        ...state,
        performerMedication: {
          errors: [],
          warnings: action.payload,
          loading: false,
          sent: true,
        },
      };
    case types.MASS_ADD_PERFORMERS_MEDICATIONS_FAILURE:
      return {
        ...state,
        performerMedication: {
          errors: action.payload.response,
          warnings: [],
          loading: false,
          sent: true,
        },
      };
    case types.MASS_ADD_PERFORMERS_MEDICATIONS_RESET:
      return {
        ...state,
        performerMedication: {
          ...initialState.performerMedication,
        },
      };
    default:
      return state;
  }
};
