import { useMemo } from 'react';
import { PatientFormField, PatientFormData } from 'app/features/patient/patientForm/types';

export const useInitialValues = (initialPhoneNumber: string) => {
  return useMemo(
    (): Partial<PatientFormData> => ({
      [PatientFormField.patientMobileNumbers]: [{ isDefault: true, phoneNumber: initialPhoneNumber }],
    }),
    [initialPhoneNumber],
  );
};
