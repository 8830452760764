import { useMemo } from 'react';
import { IconArrowDown, IconArrowUp, IconFocusCentered, IconZoomIn, IconZoomOut } from '@tabler/icons-react';
import styles from './toolbar.module.scss';

const size = 24;
const strokeWidth = 1.8;

interface Props {
  moveDown?: () => void;
  moveUp?: () => void;
  resetCamera?: () => void;
  zoomIn?: () => void;
  zoomOut?: () => void;
}

export const Toolbar = ({ moveDown, moveUp, resetCamera, zoomIn, zoomOut }: Props) => {
  const items = useMemo(
    () =>
      [
        {
          icon: <IconZoomIn className={styles.toolbarIcon} size={size} strokeWidth={strokeWidth} />,
          action: zoomIn,
        },
        {
          icon: <IconZoomOut className={styles.toolbarIcon} size={size} strokeWidth={strokeWidth} />,
          action: zoomOut,
        },
        {
          icon: <IconArrowUp className={styles.toolbarIcon} size={size} strokeWidth={strokeWidth} />,
          action: moveUp,
        },
        {
          icon: <IconArrowDown className={styles.toolbarIcon} size={size} strokeWidth={strokeWidth} />,
          action: moveDown,
        },
        {
          icon: <IconFocusCentered className={styles.toolbarIcon} size={size} strokeWidth={strokeWidth} />,
          action: resetCamera,
        },
      ].filter((item) => !!item.action),
    [moveDown, moveUp, resetCamera, zoomIn, zoomOut],
  );

  return (
    <div className={styles.toolbar}>
      {items.map((item, index) => (
        <div className={styles.toolbarItem} onClick={item.action} key={index}>
          {item.icon}
        </div>
      ))}
    </div>
  );
};
