import { ChangeEvent, useCallback, useMemo } from 'react';
import { debounce } from 'lodash';
import { Option } from 'app/types';

interface Props {
  subSpecialities: Option[];
  setFilteredSubSpecialities: (options: Option[]) => void;
}

export const useFilterHandlers = ({ subSpecialities, setFilteredSubSpecialities }: Props) => {
  const handleSubSpecialityFilter = useMemo(
    () =>
      debounce((value?: string) => {
        setFilteredSubSpecialities(
          value
            ? subSpecialities.filter((s: Option) => s.label.toLowerCase().includes(value.toLowerCase()))
            : subSpecialities,
        );
      }, 500),
    [subSpecialities, setFilteredSubSpecialities],
  );

  const filterSubSpecialities = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      handleSubSpecialityFilter(event.target.value);
    },
    [handleSubSpecialityFilter],
  );

  return {
    filterSubSpecialities,
  };
};
