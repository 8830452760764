import * as types from './wallets.types';
import { PAGINATION_DEFAULT_PARAMS } from 'app/shared/constants/pagination.constants';

const initialState = {
  loading: false,
  list: [],
  params: {
    ...PAGINATION_DEFAULT_PARAMS,
  },
  error: false,
};

export const walletsReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.FETCH_WALLETS_LIST_REQUEST:
      return {
        ...state,
        list: initialState.list,
        loading: true,
      };
    case types.FETCH_WALLETS_LIST_SUCCESS:
      return {
        ...state,
        list: action.payload.data,
        params: {
          ...state.params,
          ...action.payload.pagination,
        },
        loading: false,
      };
    case types.FETCH_WALLETS_LIST_FAILURE:
      return {
        ...state,
        loading: false,
        error: true,
      };
    case types.SET_PARAMS:
      return {
        ...state,
        params: {
          ...state.params,
          ...action.meta,
        },
      };
    default:
      return state;
  }
};
