import { takeEvery } from 'redux-saga/effects';

import { CHANGE_PATIENT_ARRIVAL_STATUS_SUCCESS } from 'app/redux/patientsArrival/patientsArrival.types';
import { refreshPatientsArrival } from 'app/sagas/patientsArrival/generators/refreshPatientsArrival';
import { refreshAllergies } from 'app/sagas/patientsArrival/generators/refreshAllergy';
import { ADD_ASSESSMENT_DATA_SUCCESS } from 'app/redux/initialAssessmentWizard/initialAssessmentWizard.types';
import {
  ADD_PATIENT_ALLERGY_SUCCESS,
  EDIT_PATIENT_ALLERGY_SUCCESS,
} from 'app/redux/patientAllergy/patientAllergy.types';
import { EDIT_APPOINTMENT_NOTE_SUCCESS } from 'app/redux/appointment/appointment.types';

function* patientsArrivalSaga() {
  yield takeEvery(
    [ADD_ASSESSMENT_DATA_SUCCESS, CHANGE_PATIENT_ARRIVAL_STATUS_SUCCESS, EDIT_APPOINTMENT_NOTE_SUCCESS],
    refreshPatientsArrival,
  );
  yield takeEvery([ADD_PATIENT_ALLERGY_SUCCESS, EDIT_PATIENT_ALLERGY_SUCCESS], refreshAllergies);
}

export default patientsArrivalSaga;
