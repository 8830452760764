import get from 'lodash/get';
import { ROLES_MASTER_ADMIN, ROLES_MANAGER_ADMIN, ROLES_RECEPTION_ADMIN } from 'app/shared/index';
import { checkAccess } from 'app/helpers';

export const getClinicsByRole = (state) => {
  if (checkAccess([ROLES_MASTER_ADMIN], state.auth.current.roles)) {
    return get(state, `dictionaries.data.clinics`, []);
  }

  if (checkAccess([ROLES_MANAGER_ADMIN, ROLES_RECEPTION_ADMIN], state.auth.current.roles)) {
    return get(state, `auth.current.clinics`, []);
  }

  if (get(state, `auth.current.clinic`)) {
    return [get(state, `auth.current.clinic`)];
  } else {
    return [];
  }
};
