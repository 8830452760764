import React from 'react';
import { Table } from 'reactstrap';
import { useAppIntl, getTranslation, getPatientFullName } from 'app/helpers';
import { PatientInfoShort } from 'app/shared/patientSearch/patientShortDetails/types';
import { dateOfBirthFormatter } from 'app/shared/patientSearch/patientSearchResults/helpers/dateOfBirthFormatter';

interface Props {
  data?: PatientInfoShort | null;
}

export const PatientShortDetails = ({ data }: Props) => {
  const { formatMessage, locale } = useAppIntl();

  return (
    <Table responsive className="text-center table-sm table-striped">
      <thead>
        <tr>
          <th>{formatMessage({ id: 'CORE.LABEL.CLINICY-MRN' })}</th>
          <th>{formatMessage({ id: 'CORE.TABLE.FULL-NAME' })}</th>
          <th>{formatMessage({ id: 'CORE.TABLE.GENDER' })}</th>
          <th>{formatMessage({ id: 'CORE.LABEL.DATE-OF-BIRTH' })}</th>
          <th>{formatMessage({ id: 'CORE.LABEL.GOVERNMENT-ID-TYPE' })}</th>
          <th>{formatMessage({ id: 'CORE.LABEL.GOVERNMENT-ID' })}</th>
          <th>{formatMessage({ id: 'CORE.LABEL.PATIENT-TYPE' })}</th>
        </tr>
      </thead>
      <tbody>
        {data && (
          <tr>
            <td>{data.clinicyMrn}</td>
            <td>{getPatientFullName(data, locale)}</td>
            <td>{data.gender ? getTranslation(data.gender, locale) : '-'}</td>
            <td>{dateOfBirthFormatter(null, data, 0, { formatMessage })}</td>
            <td>{data.governmentIssuedIdType ? getTranslation(data.governmentIssuedIdType, locale) : '-'}</td>
            <td>{data.governmentIssuedId || '-'}</td>
            <td>{data.patientType ? getTranslation(data.patientType, locale) : '-'}</td>
          </tr>
        )}
        {!data && (
          <tr>
            <td colSpan={7}>{formatMessage({ id: 'CORE.LABEL.NO-DATA' })}</td>
          </tr>
        )}
      </tbody>
    </Table>
  );
};
