import { AnyAction } from 'redux';
import { Medication, RemoteData } from 'app/types';
import * as types from 'app/redux/medications/medications.types';

export interface MedicationsReducer {
  medication: RemoteData<Medication | null>;
  practitionerMedication: RemoteData<Medication | null>;
  expandedPerformerId: string | null;
}

const initialState: MedicationsReducer = {
  medication: {
    data: null,
    loading: false,
  },
  practitionerMedication: {
    data: null,
    loading: false,
  },
  expandedPerformerId: null,
};

export const medicationsReducer = (state = initialState, action: AnyAction): MedicationsReducer => {
  switch (action.type) {
    case types.FETCH_MEDICATION_REQUEST:
      return {
        ...state,
        medication: {
          data: null,
          loading: true,
          error: false,
        },
      };
    case types.FETCH_MEDICATION_SUCCESS:
      return {
        ...state,
        medication: {
          data: action.payload.data,
          loading: false,
        },
      };
    case types.FETCH_MEDICATION_FAILURE:
      return {
        ...state,
        medication: {
          data: null,
          loading: false,
          error: true,
        },
      };
    case types.FETCH_PERFORMER_MEDICATION_REQUEST:
      return {
        ...state,
        practitionerMedication: {
          data: null,
          loading: true,
          error: false,
        },
      };
    case types.FETCH_PERFORMER_MEDICATION_SUCCESS:
      return {
        ...state,
        practitionerMedication: {
          data: action.payload.data,
          loading: false,
        },
      };
    case types.FETCH_PERFORMER_MEDICATION_FAILURE:
      return {
        ...state,
        practitionerMedication: {
          data: null,
          loading: false,
          error: true,
        },
      };

    // Currently expanded performer
    case types.SET_EXPANDED_PERFORMER:
      return {
        ...state,
        expandedPerformerId: action.payload,
      };

    default:
      return state;
  }
};
