import { generatePath } from 'react-router';
import { AppointmentModificationSource, AppointmentsCategory, PatientScope } from 'app/types';
import { AppointmentsTab } from 'app/routes/appointments/types';
import { AppointmentQueryMode } from 'app/services/appointments/types';
import {
  appointmentTab,
  branchAppointmentsPath,
  conflictingAppointmentsPath,
  doctorAppointmentsPath,
  generalAppointmentsPath,
  patientAppointmentsPath,
  patientStatisticsAppointmentsPath,
} from 'app/routes/appointments/constants';

export const generateGeneralAppointmentsPath = (tab: AppointmentsTab) =>
  generatePath(generalAppointmentsPath, {
    tab,
  });

export const generateBranchAppointmentsPath = (tab: AppointmentsTab, branchId: string) =>
  generatePath(branchAppointmentsPath, {
    branchId,
    tab,
  });

export const generateDoctorAppointmentsPath = (tab: AppointmentsTab, doctorId: string) =>
  generatePath(doctorAppointmentsPath, {
    doctorId,
    tab,
  });

export const generatePatientAppointmentsPath = (tab: AppointmentsTab, patientId: string, scope: PatientScope) =>
  generatePath(patientAppointmentsPath, {
    patientId,
    scope,
    tab,
  });

export const generatePatientStatisticsAppointmentsPath = (
  patientId: string,
  scope: PatientScope,
  mode: AppointmentQueryMode,
  source?: AppointmentModificationSource,
) =>
  generatePath(patientStatisticsAppointmentsPath, {
    patientId,
    scope,
    mode,
    source,
  });

export const generateConflictingAppointmentsPath = (tab: AppointmentsTab, branchId: string, doctorId: string) =>
  generatePath(conflictingAppointmentsPath, {
    branchId,
    doctorId,
    tab,
  });

export const generateAppointmentsPath = (
  category: AppointmentsCategory,
  tab: AppointmentsTab,
  params: { branchId?: string; doctorId?: string; patientId?: string; scope?: PatientScope },
) => {
  if (category === AppointmentsCategory.Branch && params.branchId) {
    return generateBranchAppointmentsPath(tab, params.branchId);
  }
  if (category === AppointmentsCategory.Doctor && params.doctorId) {
    return generateDoctorAppointmentsPath(tab, params.doctorId);
  }
  if (category === AppointmentsCategory.Patient && params.patientId && params.scope) {
    return generatePatientAppointmentsPath(tab, params.patientId, params.scope);
  }
  if (category === AppointmentsCategory.Conflicting && params.branchId && params.doctorId) {
    return generateConflictingAppointmentsPath(tab, params.branchId, params.doctorId);
  }

  return generateGeneralAppointmentsPath(tab);
};

export const checkAppointmentsTab = (tab: AppointmentsTab) => ({
  isToday: tab === appointmentTab.today,
  isUpcoming: tab === appointmentTab.upcoming,
  isPast: tab === appointmentTab.past,
  isWaiting: tab === appointmentTab.waiting,
  isPastWaiting: tab === appointmentTab.pastWaiting,
});
