import { AnyAction } from 'redux';
import {
  Allergy,
  MedicalAttachment,
  RemoteData,
  TreatmentInformation,
  TreatmentPlan,
  TreatmentPrescription,
} from 'app/types';
import * as types from 'app/redux/treatment/treatment.types';

interface TreatmentReducer {
  plan: RemoteData<TreatmentPlan | null>;
  generalTreatmentInformation: RemoteData<TreatmentInformation | null>;
  attachment: RemoteData<MedicalAttachment | null>;
  allergy: RemoteData<Allergy | null>;
  prescription: RemoteData<TreatmentPrescription | null>;
  addPrescriptionRemoteMode: boolean;
  patientPlan: RemoteData<TreatmentPlan | null>;
}

const initialState: TreatmentReducer = {
  // Treatment Plan
  plan: {
    data: null,
    loading: false,
  },

  // Treatment Info (assessment header)
  generalTreatmentInformation: {
    data: null,
    loading: false,
  },

  attachment: {
    data: null,
    loading: false,
  },

  allergy: {
    data: null,
    loading: false,
  },

  prescription: {
    data: null,
    loading: false,
  },

  addPrescriptionRemoteMode: false,

  // Treatment Plan
  patientPlan: {
    data: null,
    loading: false,
  },
};

export const treatmentReducer = (state = initialState, action: AnyAction): TreatmentReducer => {
  const isSilent = action.meta?.isSilent;

  switch (action.type) {
    // Treatment plan
    case types.FETCH_APPOINTMENT_TREATMENT_PLAN_REQUEST:
      return {
        ...state,
        ...(!isSilent && {
          plan: {
            data: initialState.plan.data,
            loading: true,
          },
        }),
      };
    case types.FETCH_APPOINTMENT_TREATMENT_PLAN_SUCCESS:
      return {
        ...state,
        plan: {
          data: action.payload.data,
          loading: false,
        },
      };
    case types.FETCH_APPOINTMENT_TREATMENT_PLAN_FAILURE:
      return {
        ...state,
        plan: {
          data: initialState.plan.data,
          loading: false,
          error: true,
        },
      };

    // Treatment info
    case types.FETCH_GENERAL_TREATMENT_INFO_REQUEST:
      return {
        ...state,
        generalTreatmentInformation: {
          data: initialState.generalTreatmentInformation.data,
          loading: true,
        },
      };
    case types.FETCH_GENERAL_TREATMENT_INFO_SUCCESS:
      return {
        ...state,
        generalTreatmentInformation: {
          data: action.payload.data,
          loading: false,
        },
      };
    case types.FETCH_GENERAL_TREATMENT_INFO_FAILURE:
      return {
        ...state,
        generalTreatmentInformation: {
          data: initialState.generalTreatmentInformation.data,
          loading: false,
          error: true,
        },
      };

    // Attachment
    case types.FETCH_PATIENT_ATTACHMENT_REQUEST:
      return {
        ...state,
        attachment: {
          data: initialState.attachment.data,
          loading: true,
        },
      };
    case types.FETCH_PATIENT_ATTACHMENT_SUCCESS:
      return {
        ...state,
        attachment: {
          data: action.payload.data,
          loading: false,
        },
      };
    case types.FETCH_PATIENT_ATTACHMENT_FAILURE:
      return {
        ...state,
        attachment: {
          data: initialState.attachment.data,
          loading: false,
          error: true,
        },
      };
    case types.SET_ATTACHMENT:
      return {
        ...state,
        attachment: {
          ...state.attachment,
          data: action.payload.attachment,
        },
      };

    // Allergy
    case types.FETCH_PATIENT_ALLERGY_REQUEST:
      return {
        ...state,
        allergy: {
          data: initialState.allergy.data,
          loading: true,
        },
      };
    case types.FETCH_PATIENT_ALLERGY_SUCCESS:
      return {
        ...state,
        allergy: {
          data: action.payload.data,
          loading: false,
        },
      };
    case types.FETCH_PATIENT_ALLERGY_FAILURE:
      return {
        ...state,
        allergy: {
          data: initialState.allergy.data,
          loading: false,
          error: true,
        },
      };

    // Prescription
    case types.FETCH_PATIENT_PRESCRIPTION_REQUEST:
      return {
        ...state,
        prescription: {
          data: initialState.prescription.data,
          loading: true,
        },
      };
    case types.FETCH_PATIENT_PRESCRIPTION_SUCCESS:
      return {
        ...state,
        prescription: {
          data: action.payload.data,
          loading: false,
        },
      };
    case types.FETCH_PATIENT_PRESCRIPTION_FAILURE:
      return {
        ...state,
        prescription: {
          data: initialState.prescription.data,
          loading: false,
          error: true,
        },
      };

    // Add prescription modal mode
    case types.SET_ADD_PRESCRIPTION_REMOTE_MODE:
      return {
        ...state,
        addPrescriptionRemoteMode: action.payload,
      };

    // Patient Treatment plan
    case types.FETCH_PATIENT_TREATMENT_PLAN_REQUEST:
      return {
        ...state,
        patientPlan: {
          data: initialState.patientPlan.data,
          loading: true,
        },
      };
    case types.FETCH_PATIENT_TREATMENT_PLAN_SUCCESS:
      return {
        ...state,
        patientPlan: {
          data: action.payload.data,
          loading: false,
        },
      };
    case types.FETCH_PATIENT_TREATMENT_PLAN_FAILURE:
      return {
        ...state,
        patientPlan: {
          data: initialState.patientPlan.data,
          loading: false,
          error: true,
        },
      };

    case types.RESET_APPOINTMENT_TREATMENT_PLAN:
      return {
        ...state,
        plan: initialState.plan,
      };

    // Default
    default:
      return state;
  }
};
