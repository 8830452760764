export const PAGINATION_DEFAULT_PARAMS = {
  pageNumber: 1,
  pageSize: 10,
};

export const DEFAULT_PAGINATION_PARAMS = {
  page: 1,
  sizePerPage: 10,
};

export const DICTIONARIES_LIMIT = 1000;

export const DEPENDANTS_LIMIT = 1000;

export const EXCEL_PAGE_SIZE_LIMIT = 1000000;
