import { RSAA } from 'redux-api-middleware';

import * as types from './clinicsPending.types';

export const fetchClinicsPending = (params) => ({
  [RSAA]: {
    endpoint: `/clinics`,
    method: 'GET',
    params,
    types: [
      types.FETCH_CLINICS_PENDING_LIST_REQUEST,
      types.FETCH_CLINICS_PENDING_LIST_SUCCESS,
      types.FETCH_CLINICS_PENDING_LIST_FAILURE,
    ],
  },
});

export const approveClinic = (clinicId) => {
  return {
    [RSAA]: {
      types: [types.APPROVE_CLINIC_REQUEST, types.APPROVE_CLINIC_SUCCESS, types.APPROVE_CLINIC_FAILURE],
      endpoint: `/clinics/${clinicId}/approve`,
      method: 'POST',
    },
  };
};

export const setParams = (params) => ({
  type: types.SET_PARAMS,
  meta: params,
});
