import { Option } from 'app/types';

export enum WorkingTimeAutomaticPromotionStatus {
  Enabled = '0',
  Disabled = '1',
}

export interface WorkingTime {
  automaticPromotion: Option<WorkingTimeAutomaticPromotionStatus>;
  cancellationDate: string | null;
  daysOfWeek: Option[];
  durationInMinutes: number;
  endDate?: string;
  frequencyByWeeks: Option;
  id: string;
  numberOfPossibleBookingsFromMobileApp: number;
  products: number;
  startDate: string;
  startTime: string;
  subSpecialities: Option[];
  waitingAppointmentsLimit: number;
}
