import * as types from './dictionaries.types';

const initialState = {
  loading: true, // initial loading
  error: false,
  data: {
    'appointment-management-systems': [],
    'appointment-reminders': [],
    'billing-item-types': [],
    'cancellation-behaviours': [],
    'cancellation-reasons': [],
    cities: [],
    'country-codes': [],
    'clinic-types': [],
    'days-of-week': [],
    'eligibility-statuses': [],
    'frequency-by-weeks': [],
    clinics: [],
    genders: [],
    'insurance-company-statuses': [],
    'invoice-discount-reasons': [],
    'invoice-discount-types': [],
    'invoice-modification-types': [],
    'invoice-statuses': [],
    languages: [],
    nationalities: [],
    periods: [],
    ranks: [],
    specialities: [],
    statuses: [],
    'martial-statuses': [],
    'invoice-payment-methods': [],
    'patient-payment-methods': [],
    occupations: [],
    'object-types': [],
    'insurance-approval-types': [],
    'credit-note-reasons': [],
    'since-periods': [],
    'mass-adding-practitioner-assessment-fields': [],
    'mass-adding-types': [],
    'clinic-attachment-file-mass-adding-statuses': [],
    'clinic-attachment-file-uploading-statuses': [],
    'mass-adding-vital-sign-fields': [],
    'import-statuses': [],
    'mass-adding-time-range-types': [],
    'declaration-locations': [],
  },
};

export const dictionariesReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.FETCH_DICTIONARIES_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case types.FETCH_DICTIONARIES_SUCCESS:
      return {
        ...state,
        data: {
          ...state.data,
          ...action.payload,
        },
        loading: false,
      };
    case types.FETCH_DICTIONARIES_FAILURE:
      return {
        ...state,
        loading: false,
        error: true,
      };
    default:
      return state;
  }
};
