import { Dispatch, SetStateAction, useCallback, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { push } from 'connected-react-router';
import toast from 'react-hot-toast';
import { useAppDispatch, useAppSelector } from 'app/helpers';
import {
  FORMS_ASSESSMENT_BILLING,
  FORMS_ASSESSMENT_MEDICAL_PROFILE_FORM,
  FORMS_ASSESSMENT_ORDERS,
  FORMS_ASSESSMENT_PRESCRIPTIONS,
  FORMS_ASSESSMENT_REFERRALS,
  FORMS_ASSESSMENT_SIGNATURE_FORM,
} from 'app/shared';
import { BillingFormData } from 'app/assessment/billingSection/forms/billingForm/billingForm';
import { SignatureFormData } from 'app/assessment/forms/signatureForm/types';
import { useCheckViewStatus, useCreateBodyParams } from 'app/assessment/helpers';
import {
  createCompletedDoctorAssessment,
  createDraftedDoctorAssessment,
  fetchDoctorAssessment,
  updateCompletedDoctorAssessment,
  updateDoctorAssessmentToCompleted,
  updateDoctorAssessmentToSaveForLater,
} from 'app/redux/doctorAssessment/doctorAssessment.actions';
import { MedicalProfileFormData } from 'app/assessment/medicalProfileSection/components/practitionerAssessment/practitionerAssessment';
import { DoctorAssessmentPageParams } from 'app/assessment/types';
import { AssessmentReferral } from 'app/redux/doctorAssessment/types';
import { WrappedPrescriptionFormData } from 'app/assessment/prescriptionSection/types';
import { AssessmentOrdersFormData } from 'app/assessment/ordersSection/forms/ordersForm/types';
import { fetchGeneralTreatmentInformation } from 'app/redux/treatment/treatment.actions';

interface Props {
  setIsSaveForLaterLoading: Dispatch<SetStateAction<boolean>>;
  setIsCompletedLoading: Dispatch<SetStateAction<boolean>>;
  setIsUpdateLoading: Dispatch<SetStateAction<boolean>>;
}

export const useAssessmentActions = ({
  setIsSaveForLaterLoading,
  setIsCompletedLoading,
  setIsUpdateLoading,
}: Props) => {
  const dispatch = useAppDispatch();
  const { assessmentId, appointmentId, treatmentPageType } = useParams<DoctorAssessmentPageParams>();

  // Selectors
  const doctorAssessmentData = useAppSelector((state) => state.doctorAssessment.data);
  const medicalProfileValues: MedicalProfileFormData | undefined = useAppSelector(
    (state) => state.form[FORMS_ASSESSMENT_MEDICAL_PROFILE_FORM]?.values,
  );
  const billingValues: BillingFormData | undefined = useAppSelector(
    (state) => state.form[FORMS_ASSESSMENT_BILLING]?.values,
  );
  const ordersValues: AssessmentOrdersFormData | undefined = useAppSelector(
    (state) => state.form[FORMS_ASSESSMENT_ORDERS]?.values,
  );
  const prescriptionValues: WrappedPrescriptionFormData[] | undefined = useAppSelector(
    (state) => state.form[FORMS_ASSESSMENT_PRESCRIPTIONS]?.values?.assessmentPrescriptions,
  );
  const referralValues: AssessmentReferral[] | undefined = useAppSelector(
    (state) => state.form[FORMS_ASSESSMENT_REFERRALS]?.values?.assessmentReferrals,
  );
  const signatureValues: SignatureFormData | undefined = useAppSelector(
    (state) => state.form[FORMS_ASSESSMENT_SIGNATURE_FORM]?.values,
  );

  // Helper Hooks
  // const backtrackUrl = useCreateBacktrackUrl(); // TODO: Commented due to PT-8071 - keep it for future
  const { isDraftMode, isNew, isCompleted, isContinue } = useCheckViewStatus(
    doctorAssessmentData?.viewStatus,
    doctorAssessmentData?.completedAt,
  );
  const bodyParams = useCreateBodyParams({
    doctorAssessmentData,
    medicalProfileValues,
    billingValues,
    ordersValues,
    prescriptionValues,
    referralValues,
    signatureValues,
  });

  // Handlers
  const pushBackToTreatment = useCallback(() => {
    // TODO: Due to PT-8071 removed 'backtrackUrl' as a path
    dispatch(push('/patients-arrival', { allowForExit: true }));
  }, [dispatch]);

  const onSaveForLater = useCallback(async () => {
    setIsSaveForLaterLoading(true);
    try {
      if (isNew || isContinue) {
        const result = await dispatch(createDraftedDoctorAssessment(bodyParams));

        if (!result.error && result.payload.data) {
          dispatch(
            push(`/treatment/${appointmentId}/assessments/${result.payload.data}/view/${treatmentPageType}`, {
              allowForExit: true,
            }),
          );
        }
      }

      if (isDraftMode && assessmentId) {
        const result = await dispatch(updateDoctorAssessmentToSaveForLater(bodyParams, assessmentId));

        if (!result.error) {
          dispatch(
            push(`/treatment/${appointmentId}/assessments/${assessmentId}/view/${treatmentPageType}`, {
              allowForExit: true,
            }),
          );
          dispatch(fetchDoctorAssessment(assessmentId));
        }
      }

      dispatch(fetchGeneralTreatmentInformation(appointmentId));
      toast.success('TREATMENT-PLAN.TEXT.ASSESSMENT-DRAFT-SAVE-SUCCESS');
    } catch (error) {
      toast.error('CORE.TEXT.DEFAULT-FAILURE-MESSAGE');
    }
    setIsSaveForLaterLoading(false);
  }, [
    appointmentId,
    assessmentId,
    bodyParams,
    dispatch,
    isContinue,
    isDraftMode,
    isNew,
    setIsSaveForLaterLoading,
    treatmentPageType,
  ]);

  const onCompleted = useCallback(async () => {
    setIsCompletedLoading(true);
    if (isNew || isContinue) {
      await dispatch(createCompletedDoctorAssessment(bodyParams));
    }
    if (isDraftMode && assessmentId) {
      await dispatch(updateDoctorAssessmentToCompleted(bodyParams, assessmentId));
    }
    setIsCompletedLoading(false);
    pushBackToTreatment();
  }, [assessmentId, bodyParams, dispatch, isContinue, isDraftMode, isNew, pushBackToTreatment, setIsCompletedLoading]);

  const onUpdate = useCallback(async () => {
    setIsUpdateLoading(true);
    if (isCompleted && assessmentId) {
      await dispatch(updateCompletedDoctorAssessment(bodyParams, assessmentId));
    }
    setIsUpdateLoading(false);
    pushBackToTreatment();
  }, [assessmentId, bodyParams, dispatch, isCompleted, pushBackToTreatment, setIsUpdateLoading]);

  return useMemo(
    () => ({
      bodyParams,
      onSaveForLater,
      onCompleted,
      onUpdate,
    }),
    [bodyParams, onCompleted, onSaveForLater, onUpdate],
  );
};
