import { getTranslation, useAppIntl } from 'app/helpers';
import { Appointment } from 'app/types';

interface Props {
  appointment: Appointment;
}

export const Header = ({ appointment }: Props) => {
  const { formatMessage, locale } = useAppIntl();

  return (
    <div className="text-center mb-5">
      <h3>
        {formatMessage({ id: 'CONFIRMATION.HEADER' }, { clinicName: getTranslation(appointment.clinic, locale) })}
      </h3>
      <img alt="clinic logo" className="img-thumbnail" src={appointment.clinicLogo.url} width="200" />
    </div>
  );
};
