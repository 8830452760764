import { Option, PatientType } from 'app/types';

export interface PatientOption extends Option {
  details?: {
    hasDependants?: boolean;
    patientType: Option<PatientType>;
    phoneNumber?: string;
  };
}

export const DetailsField = {
  branch: 'branch',
  dependant: 'dependant',
  doctor: 'doctor',
  patient: 'patient',
} as const;

export type DetailsFormData = {
  [DetailsField.branch]?: Option;
  [DetailsField.dependant]?: Option;
  [DetailsField.doctor]: Option;
  [DetailsField.patient]?: PatientOption;
};
