import moment from 'moment';
import { useMemo } from 'react';

export const useDate = () => {
  const today = moment().startOf('day').toISOString(); // to force a recalculation if day changes

  return useMemo(() => {
    return {
      todayStart: moment().startOf('day').toDate(),
      todayEnd: moment().endOf('day').toDate(),
      tomorrowStart: moment().add('1', 'day').startOf('day').toDate(),
      endOfCurrentYear: moment().endOf('year').toDate(),
      startOfPreviousYear: moment().subtract('1', 'year').startOf('year').toDate(),
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [today]);
};
