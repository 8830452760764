import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import React from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import { createBrowserHistory } from 'history';
import { IntlProvider } from 'react-intl-redux';
import { configureStore } from '@reduxjs/toolkit';
import { setupListeners } from '@reduxjs/toolkit/query';
import { ConnectedRouter } from 'connected-react-router';
import sagas from 'app/sagas';
import { Locale } from 'app/types';
import { Routes } from 'app/routes';
import { createRootReducer } from 'app/redux';
import { setLangAttributes } from 'app/helpers';
import { registerSentry } from 'app/sentry/sentry';
import { configuration } from 'app/sentry/configuration';
import { createMiddleware, sagaMiddleware } from 'app/middlewares';
import { translations } from './i18n';

import './index.scss';

// Moment locales
import 'moment/locale/ar';

export const history = createBrowserHistory();

const initialLocale = localStorage.getItem('LANGUAGE') || Locale.en;
// @ts-ignore
const initialMessages = translations[initialLocale];

setLangAttributes(initialLocale);

const preloadedState = {
  intl: {
    locale: initialLocale as Locale,
    messages: initialMessages,
  },
};

export const store = configureStore({
  reducer: createRootReducer(history),
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({ immutableCheck: false, serializableCheck: false }).concat(createMiddleware(history)),
  devTools: process.env.NODE_ENV !== 'production',
  preloadedState,
});

// Listeners
sagaMiddleware.run(sagas);
setupListeners(store.dispatch);
registerSentry(configuration);

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

root.render(
  <Provider store={store}>
    {/* @ts-ignore - react-intl-redux issue */}
    <IntlProvider locale={initialLocale}>
      <ConnectedRouter history={history}>
        <Routes />
      </ConnectedRouter>
    </IntlProvider>
  </Provider>,
);
