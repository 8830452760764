import get from 'lodash/get';
import { isRSAA, RSAA } from 'redux-api-middleware';
import { generateSearchParams } from 'app/helpers';

const prepareEndpoint = ({ endpoint, types }, actionParams) => {
  //TODO: Remove parsing meta params, change all actions with meta.params
  const params = get(types, '0.meta.params') || actionParams;

  if (params) {
    const searchParams = generateSearchParams(params);
    return endpoint + '?' + searchParams.toString();
  } else {
    return endpoint;
  }
};

export const paramsMiddleware = () => (next) => (action) => {
  if (!isRSAA(action)) {
    return next(action);
  }

  let { params, ...apiMiddleware } = action[RSAA];

  params = {
    ...params,
    first: undefined,
    last: undefined,
    next: undefined,
    totalItems: undefined,
    pageCount: undefined,
    totalItemCount: undefined,
  };

  if (params && params.filterValues) {
    params = {
      ...params,
      ...params.filterValues,
      filterValues: undefined,
    };
  }

  return next({
    [RSAA]: {
      ...apiMiddleware,
      endpoint: prepareEndpoint(apiMiddleware, params),
    },
  });
};
