import { Button, Spinner } from 'reactstrap';
import { useHistory } from 'react-router-dom';
import { Loader } from 'app/shared';
import { UnauthorizedPage } from 'app/components';
import { useAppIntl, useCurrentUser } from 'app/helpers';

interface Props {
  downloading: boolean;
  loading: boolean;
  number: string | undefined;
  onDownload: () => void;
  title: string;
}

export const DownloadDocumentPage = ({ downloading, loading, number, onDownload, title }: Props) => {
  const { push } = useHistory();
  const { token } = useCurrentUser();
  const { formatMessage } = useAppIntl();

  return (
    <UnauthorizedPage>
      {loading && <Loader />}
      {!loading && (
        <div className="text-center pt-2 pb-3">
          {!token && (
            <>
              <p>
                {formatMessage({
                  id: 'CORE.TEXT.LOGIN-TO-DOWNLOAD-FILE',
                  defaultMessage: 'You need to be logged in to download the file',
                })}
              </p>
              <Button color="primary-gradient" onClick={() => push('/')} style={{ minWidth: 140 }}>
                {formatMessage({ id: 'LOGIN.BUTTON.LOGIN' })}
              </Button>
            </>
          )}

          {token && number && (
            <>
              <p className="mb-1">{title}:</p>
              <h4 className="text-nowrap">{number}</h4>
              <Button color="primary-gradient" disabled={downloading} style={{ minWidth: 140 }} onClick={onDownload}>
                {downloading && <Spinner size="sm" />}
                {!downloading && formatMessage({ id: 'CORE.BUTTON.DOWNLOAD' })}
              </Button>
            </>
          )}

          {token && !number && <h5 className="mb-0">{formatMessage({ id: 'CORE.TEXT-FILE-NOT-FOUND' })}</h5>}
        </div>
      )}
    </UnauthorizedPage>
  );
};
