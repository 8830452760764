import { Badge } from 'reactstrap';
import { useAppIntl, isWithinAgeRestrictions } from 'app/helpers';
import { PatientInfoShort } from 'app/shared/patientSearch/patientShortDetails/types';
import { Doctor } from 'app/types';

type DoctorBookingRestrictions = Pick<Doctor, 'acceptablePatientAgeFrom' | 'acceptablePatientAgeTo'>;

interface Props {
  doctor?: DoctorBookingRestrictions | null;
  patient?: PatientInfoShort | null;
}

export const BookingRestrictionsStatus = ({ doctor, patient }: Props) => {
  const { formatMessage } = useAppIntl();

  const requireAgeCheck = !!(doctor?.acceptablePatientAgeFrom || doctor?.acceptablePatientAgeTo);
  const hasDateOfBirth = !!patient?.dateOfBirth;
  const meetsAgeRequirements = isWithinAgeRestrictions({
    age: patient?.age,
    min: doctor?.acceptablePatientAgeFrom,
    max: doctor?.acceptablePatientAgeTo,
  });

  if (!doctor || !patient) {
    return null;
  }

  return (
    <>
      {requireAgeCheck && (!hasDateOfBirth || !meetsAgeRequirements) && (
        <div className="w-100 mb-2">
          <Badge color="light-danger" className="d-block text-wrap">
            {!hasDateOfBirth &&
              formatMessage({
                id: 'CORE.TEXT.DATE-OF-BIRTH-BOOKING-RESTRICTIONS-ERROR',
                defaultMessage: 'Date of birth is required for booking with age restrictions',
              })}
            {hasDateOfBirth &&
              !meetsAgeRequirements &&
              formatMessage({
                id: 'CORE.TEXT.SELECTED-PATIENT-BOOKING-RESTRICTIONS-ERROR',
                defaultMessage: 'Selected patient is not meeting the practitioner booking age restrictions',
              })}
          </Badge>
        </div>
      )}
    </>
  );
};
