import { useAppDispatch, useAppSelector, useCurrentUser, useIsMounted } from 'app/helpers';
import { useEffect, useState } from 'react';
import toast from 'react-hot-toast';

import { CalendarItem, Option, PatientSourceOption, PatientSourceStatus } from 'app/types';
import { SelectedDay, SelectedSlot } from 'app/redux/calendar/types';
import { StepType } from 'app/bookAppointment/types';
import {
  fetchDoctors,
  fetchDoctorProducts,
  fetchDoctorSubSpecialities,
  fetchDoctorUser,
  fetchProducts,
  fetchSpecialities,
  selectBranch,
} from 'app/redux/book-appointment/book-appointment.actions';
import { resetSelectedTableViewDays } from 'app/redux/calendar/calendar.actions';
import { useClinicId } from 'app/bookAppointment/helpers';
import { PatientSourceOptionParams } from 'app/services/patientSources/types';
import { useLazyGetPatientSourceOptionsQuery } from 'app/services/patientSources/patientSources';

interface Props {
  selectedBranch: Option | null;
  selectedDay: SelectedDay | null;
  selectedPatientSource: PatientSourceOption | null;
  selectedSlot: SelectedSlot | null;
  selectedSpeciality: Option | null;
  selectedSubSpeciality: Option | null;
  setCurrentStep: (step: StepType) => void;
  setSelectedDay: (day: (previousDay: SelectedDay | null) => SelectedDay | null) => void;
  setSelectedDayItem: (item: CalendarItem | null) => void;
  setHasPatientSources: (value: boolean) => void;
}

export const useWizardEffects = ({
  selectedBranch,
  selectedDay,
  selectedPatientSource,
  selectedSlot,
  selectedSpeciality,
  selectedSubSpeciality,
  setCurrentStep,
  setSelectedDayItem,
  setHasPatientSources,
}: Props) => {
  const dispatch = useAppDispatch();
  const [patientSourceChecked, setPatientSourceChecked] = useState(false);
  const clinicId = useClinicId();
  const isMounted = useIsMounted();

  const branchesData = useAppSelector((state) => state.bookAppointment.branches.data);
  const referralInfo = useAppSelector((state) => state.bookAppointment.referralInfo);
  const selectedDoctor = useAppSelector((state) => state.bookAppointment.selectedDoctor);
  const { data: items } = useAppSelector((state) => state.calendar.tableViewItems);
  const {
    currentUser: { filtersDefaults, id: currentUserId },
    isDoctor,
  } = useCurrentUser();
  const [fetchPatientSources] = useLazyGetPatientSourceOptionsQuery();

  useEffect(() => {
    if (selectedDay) {
      const calendarItem = items.find(
        (item) =>
          selectedDay.branchId === item.branch.id &&
          selectedDay.doctorId === item.doctor.id &&
          selectedDay.productId === item.product.value,
      );
      if (calendarItem) {
        setSelectedDayItem(calendarItem);
      }
    } else {
      setSelectedDayItem(null);
      dispatch(resetSelectedTableViewDays());
    }
  }, [dispatch, items, selectedDay, setSelectedDayItem]);

  useEffect(() => {
    if (selectedSubSpeciality && selectedSpeciality && selectedBranch) {
      dispatch(
        !selectedDoctor?.id
          ? fetchProducts({
              branchId: selectedBranch.value,
              specialityId: selectedSpeciality.value,
              subSpecialityId: selectedSubSpeciality.value,
              patientSourceId: selectedPatientSource?.value,
            })
          : fetchDoctorProducts({
              branchId: selectedBranch.value,
              doctorId: selectedDoctor.id,
              subSpecialityId: selectedSubSpeciality.value,
              patientSourceId: selectedPatientSource?.value,
            }),
      );
    }
  }, [
    dispatch,
    isDoctor,
    selectedBranch,
    selectedDoctor,
    selectedSpeciality,
    selectedSubSpeciality,
    selectedPatientSource?.value,
  ]);

  useEffect(() => {
    if (selectedBranch && !isDoctor) {
      if (!referralInfo) {
        dispatch(fetchDoctors(selectedBranch.value, undefined, undefined, selectedPatientSource?.value));
        dispatch(fetchSpecialities(selectedBranch.value, selectedPatientSource?.value));
      } else {
        dispatch(
          fetchDoctors(selectedBranch.value, undefined, referralInfo.speciality.value, selectedPatientSource?.value),
        );
      }
    }
  }, [clinicId, dispatch, isDoctor, selectedBranch, referralInfo, selectedPatientSource?.value]);

  useEffect(() => {
    if (selectedSlot && (selectedSlot.overlappingAppointmentsIds?.length === 0 || !selectedSlot.start)) {
      setCurrentStep(StepType.Patient);
    }
  }, [selectedSlot, setCurrentStep]);

  useEffect(() => {
    if (isDoctor && currentUserId) {
      dispatch(fetchDoctorUser(currentUserId));
    }
  }, [currentUserId, dispatch, isDoctor]);

  useEffect(() => {
    // preselect branch, select first if user does not have default branch set in profile
    if (!selectedBranch && branchesData.length > 0) {
      const defaultBranch = filtersDefaults?.defaultBranch;
      if (defaultBranch && branchesData.find((branch) => branch.value === defaultBranch.value)) {
        dispatch(selectBranch(filtersDefaults.defaultBranch, isDoctor, isDoctor || !!referralInfo));
      } else {
        dispatch(selectBranch(branchesData[0], isDoctor, isDoctor || !!referralInfo));
      }
    }
  }, [dispatch, branchesData, filtersDefaults, isDoctor, selectedBranch, referralInfo]);

  useEffect(() => {
    if (isDoctor && selectedBranch && selectedDoctor) {
      if (referralInfo?.speciality.value) {
        // fetch sub-specialities only if doctor speciality match with referral
        if (referralInfo?.speciality.value === selectedDoctor.speciality?.value) {
          dispatch(fetchDoctorSubSpecialities(selectedDoctor.id, selectedBranch.value, selectedPatientSource?.value));
        }
      } else {
        dispatch(fetchDoctorSubSpecialities(selectedDoctor.id, selectedBranch.value, selectedPatientSource?.value));
      }
    }
  }, [
    dispatch,
    isDoctor,
    selectedBranch,
    selectedDoctor,
    referralInfo?.speciality.value,
    selectedPatientSource?.value,
  ]);

  useEffect(() => {
    if (!patientSourceChecked && clinicId) {
      const checkPatientSource = async () => {
        const params: PatientSourceOptionParams = {
          practitionerIds: selectedDoctor?.id,
          statuses: PatientSourceStatus.Active,
        };
        try {
          const response = await fetchPatientSources({ clinicId, params }).unwrap();
          if (isMounted()) {
            setHasPatientSources(!!response.data.length);
            setPatientSourceChecked(true);
          }
        } catch (error) {
          toast.error('CORE.TEXT.DEFAULT-FAILURE-MESSAGE');
        }
      };

      checkPatientSource();
    }
  }, [isMounted, patientSourceChecked, clinicId, selectedDoctor, fetchPatientSources, setHasPatientSources]);
};
