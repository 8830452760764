import { takeEvery } from 'redux-saga/effects';
import * as types from 'app/redux/invoicingProcess/invoicingProcess.types';
import {
  afterActionFailure,
  afterCreateCreditNoteSuccess,
  afterCreateDraftSuccess,
  afterCreateInvoiceSuccess,
  afterFetchSuccess,
  afterUpdatePaymentsSuccess,
} from 'app/sagas/invoicingProcess/generators';

function* invoicingProcessSaga() {
  yield takeEvery(
    [
      types.FETCH_INVOICE_SUCCESS,
      types.FETCH_DRAFT_SUCCESS,
      types.FETCH_CREDIT_NOTE_SUCCESS,
      types.FETCH_EMPTY_WITH_APPOINTMENT_SUCCESS,
      types.FETCH_EMPTY_WITHOUT_APPOINTMENT_SUCCESS,
      types.FETCH_EMPTY_BY_REQUEST_SUCCESS,
    ],
    afterFetchSuccess,
  );
  yield takeEvery(
    [types.CREATE_WITH_APPOINTMENT_SUCCESS, types.CREATE_WITHOUT_APPOINTMENT_SUCCESS],
    afterCreateInvoiceSuccess,
  );
  yield takeEvery(
    [types.CREATE_DRAFT_WITH_APPOINTMENT_SUCCESS, types.CREATE_DRAFT_WITHOUT_APPOINTMENT_SUCCESS],
    afterCreateDraftSuccess,
  );
  yield takeEvery(types.CREATE_CREDIT_NOTE_SUCCESS, afterCreateCreditNoteSuccess);
  yield takeEvery(types.UPDATE_PAYMENTS_SUCCESS, afterUpdatePaymentsSuccess);
  yield takeEvery(
    [
      types.CREATE_WITH_APPOINTMENT_FAILURE,
      types.CREATE_WITHOUT_APPOINTMENT_FAILURE,
      types.CREATE_DRAFT_WITH_APPOINTMENT_FAILURE,
      types.CREATE_DRAFT_WITHOUT_APPOINTMENT_FAILURE,
      types.CREATE_CREDIT_NOTE_FAILURE,
      types.UPDATE_PAYMENTS_FAILURE,
    ],
    afterActionFailure,
  );
}

export default invoicingProcessSaga;
