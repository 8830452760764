import { getApiDate } from 'app/helpers';
import {
  PatientInsuranceField,
  PatientInsuranceFormData,
} from 'app/features/patient/patientInsuranceDetailsForm/types';

export const formValuesAdapter = (values: PatientInsuranceFormData) => ({
  end: getApiDate(values[PatientInsuranceField.end]),
  clinicBranchId: values[PatientInsuranceField.clinicBranch].value,
  insuranceClassId: values[PatientInsuranceField.insuranceClass].value,
  insuranceCompanyId: values[PatientInsuranceField.insuranceCompany].value,
  number: values[PatientInsuranceField.number],
  start: getApiDate(values[PatientInsuranceField.start]),
});
