import { InvoiceEligibility, InvoiceItem, InvoicePayBy } from 'app/types';

interface Params {
  invoiceEligibility: InvoiceEligibility | undefined;
  invoicePayBy: InvoicePayBy | undefined;
  items: InvoiceItem[];
}

export const checkIsPaymentModeItemsInvalid = ({ invoiceEligibility, invoicePayBy, items }: Params) => {
  return (
    invoicePayBy === InvoicePayBy.Insurance &&
    (invoiceEligibility === InvoiceEligibility.ReferralIn ||
      invoiceEligibility === InvoiceEligibility.PreviousApproval) &&
    !items.length
  );
};
