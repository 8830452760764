import classNames from 'classnames';
import { useCallback } from 'react';
import { MapProps } from '@vis.gl/react-google-maps/dist/components/map';
import { APIProvider, Map, Marker } from '@vis.gl/react-google-maps';
import { MapMouseEvent } from '@vis.gl/react-google-maps/dist/components/map/use-map-events';
import { MAPS_DEFAULT_CENTER, MAPS_DEFAULT_ZOOM, MAPS_GOOGLE_MAP_KEY } from 'app/shared';
import { PlaceAutocomplete } from 'app/components/map/components/placeAutocomplete/placeAutocomplete';
import styles from 'app/components/map/mapWithPlaceAutocomplete/mapWithPlaceAutocomplete.module.scss';

interface Props {
  defaultCenter?: MapProps['defaultCenter'];
  defaultZoom?: MapProps['defaultZoom'];
  invalid?: boolean;
  position?: google.maps.LatLngLiteral | null;
  setPosition?: (location: google.maps.LatLngLiteral) => void;
}

export const MapWithPlaceAutocomplete = ({
  defaultCenter = MAPS_DEFAULT_CENTER,
  defaultZoom = MAPS_DEFAULT_ZOOM,
  invalid,
  setPosition,
  position,
}: Props) => {
  const onClick = useCallback(
    (event: MapMouseEvent) => {
      const location = event.detail.latLng;

      if (location) {
        event.map.panTo(location);
        setPosition?.(location);
      }
    },
    [setPosition],
  );

  return (
    <APIProvider apiKey={MAPS_GOOGLE_MAP_KEY} libraries={['places', 'geometry']}>
      <div className={classNames(styles.mapWrapper, { [styles.mapWrapperInvalid]: invalid })}>
        <PlaceAutocomplete className={classNames(styles.mapInput)} setPosition={setPosition} />
        <Map defaultZoom={defaultZoom} defaultCenter={defaultCenter} className={styles.map} onClick={onClick}>
          {position && <Marker position={position} />}
        </Map>
      </div>
    </APIProvider>
  );
};
