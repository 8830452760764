import { useCallback } from 'react';
import { Col, Row } from 'reactstrap';
import { Field, Form, reduxForm } from 'redux-form';
import { InjectedFormProps } from 'redux-form/lib/reduxForm';
import { BranchDeclarationLocation, RemoteDataParams } from 'app/types';
import { useAppDispatch, useAppIntl, useAppSelector } from 'app/helpers';
import { AsyncSelectComponent, FORMS_INVOICING_PROCESS_DECLARATION } from 'app/shared';
import { useInvoicingProcessState } from 'app/features/invoicingProcess/process/helpers';
import { fetchDropdownOptions } from 'app/redux/dropdownOptions/dropdownOptions.actions';
import { DeclarationContent } from 'app/features/branchDeclarations/components/declarationContent/declarationContent';
import {
  DeclarationFormData,
  DeclarationFormField,
} from 'app/features/invoicingProcess/process/items/declarationForm/types';

const FormComponent = ({ form, handleSubmit }: InjectedFormProps<DeclarationFormData>) => {
  const dispatch = useAppDispatch();
  const { formatMessage } = useAppIntl();
  const branchId: string | undefined = useAppSelector((state) => state.invoicingProcess.data?.clinicBranch.value);
  const { isCreated, isCreateCreditNoteMode } = useInvoicingProcessState();
  const disabled = isCreated && !isCreateCreditNoteMode;
  const declaration: DeclarationFormData['declaration'] | undefined = useAppSelector(
    (state) => state.form[form]?.values?.[DeclarationFormField.declaration],
  );

  const fetchDeclarationOptions = useCallback(
    (params: RemoteDataParams) => {
      if (branchId) {
        return dispatch(
          fetchDropdownOptions(`/branches/${branchId}/declarations/dropdown`, {
            ...params,
            branchId,
            DeclarationLocation: BranchDeclarationLocation.Invoice,
          }),
        );
      }
    },
    [branchId, dispatch],
  );

  return (
    <Form onSubmit={handleSubmit}>
      <Row xs={1} xl={3}>
        <Col>
          <Field
            component={AsyncSelectComponent}
            name={DeclarationFormField.declaration}
            props={{
              disabled,
              fetchOptions: fetchDeclarationOptions,
              isClearable: true,
              label: formatMessage({ id: 'CORE.LABEL.DECLARATION' }),
            }}
          />
        </Col>
      </Row>
      <DeclarationContent contentAr={declaration?.details.contentAr} contentEn={declaration?.details.contentEn} />
    </Form>
  );
};

export const DeclarationForm = reduxForm<DeclarationFormData>({
  form: FORMS_INVOICING_PROCESS_DECLARATION,
  destroyOnUnmount: false,
})(FormComponent);
