import { useMemo } from 'react';
import { checkAccess } from 'app/helpers';

const PrivateSectionComponent = ({ children, allowedRoles, userRoles }) => {
  const hasAccess = useMemo(() => checkAccess(allowedRoles, userRoles), [allowedRoles, userRoles]);

  if (hasAccess) {
    return children;
  } else {
    return null;
  }
};

export default PrivateSectionComponent;
