import React, { useMemo } from 'react';
import { Input } from 'reactstrap';
import { ColumnDescription } from 'react-bootstrap-table-next';
import { useAppIntl, getPatientFullName } from 'app/helpers';
import { Locale } from 'app/types';
import { ClinicPatientShortInfo } from 'app/services/patients/types';

interface Props {
  onRowSelectionChange: (rowIndex: number) => void;
  itemIds: string[];
}

interface FormatExtraData extends Props {
  locale: Locale;
}

export const useColumns = ({ onRowSelectionChange, itemIds }: Props) => {
  const { formatMessage, locale } = useAppIntl();

  return useMemo(
    (): ColumnDescription<ClinicPatientShortInfo, FormatExtraData>[] => [
      {
        dataField: 'select',
        text: formatMessage({ id: 'CORE.BUTTON.SELECT' }),
        formatter: (_, row, rowIndex, formatExtraData) => (
          <Input
            id={`guardian.${rowIndex}`}
            onChange={() => formatExtraData?.onRowSelectionChange(rowIndex)}
            type="checkbox"
            checked={!!formatExtraData?.itemIds.includes(row.id)}
          />
        ),
        formatExtraData: { onRowSelectionChange, itemIds, locale },
      },
      {
        dataField: 'clinicyMrn',
        text: formatMessage({ id: 'CORE.LABEL.CLINICY-MRN' }),
        classes: 'text-center',
        headerClasses: 'text-center',
      },
      {
        dataField: 'fullName',
        text: formatMessage({ id: 'CORE.TABLE.FULL-NAME' }),
        formatter: (_, row) => getPatientFullName(row, locale),
        formatExtraData: { onRowSelectionChange, itemIds, locale },
        classes: 'text-nowrap',
      },
      {
        dataField: 'governmentIssuedId',
        formatter: (_, row) => row.governmentIssuedId || '-',
        text: formatMessage({ id: 'CORE.LABEL.GOVERNMENT-ID-NUMBER' }),
        classes: 'text-center',
        headerClasses: 'text-center',
      },
    ],
    [formatMessage, itemIds, locale, onRowSelectionChange],
  );
};
