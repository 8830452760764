import { ChangeEvent, MutableRefObject, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import classnames from 'classnames';
import { IconCircleCheck, IconTrash, IconX } from '@tabler/icons-react';
import { IntlFormatters } from '@formatjs/intl/src/types';
import { Note, NoteStatus, Template } from 'app/components/locationSelector/procedureLocation/types';
import styles from 'app/components/locationSelector/procedureLocation/selector/selectedNote/selectedNote.module.scss';

interface Props {
  closeNote: () => void;
  deleteNote: () => void;
  formatMessage: IntlFormatters['formatMessage'];
  note: Note;
  updateNote: (note: Note) => void;
  withStatus?: boolean;
  isReadOnly?: boolean;
}

const size = 20;
const strokeWidth = 1.8;

export const SelectedNote = ({
  closeNote,
  deleteNote,
  formatMessage,
  updateNote,
  note,
  withStatus,
  isReadOnly,
}: Props) => {
  const titleRef = useRef<HTMLTextAreaElement | null>(null);
  const descriptionRef = useRef<HTMLTextAreaElement | null>(null);
  const [titleValue, setTitleValue] = useState<string>(note.title || '');
  const [descriptionValue, setDescriptionValue] = useState<string>(note.description || '');
  const isTeethTemplate = note.template === Template.AdultTeeth || note.template === Template.PrimaryTeeth;

  // Conditions
  const isCompleted = useMemo(() => withStatus && note.status === NoteStatus.Completed, [note.status, withStatus]);

  // Handlers
  const resizeTextarea = useCallback<(ref: MutableRefObject<HTMLTextAreaElement | null>) => void>((ref) => {
    if (ref.current) {
      const defaultScrollHeight = 22;
      ref.current.style.height = 'auto';
      ref.current.style.height = `${ref.current.scrollHeight || defaultScrollHeight}px`;
    }
  }, []);

  const onChangeTitle = useCallback(
    (e: ChangeEvent<HTMLTextAreaElement>) => {
      if (note) {
        setTitleValue(e.target.value);
        updateNote({ ...note, title: e.target.value, updateDate: new Date().toISOString() });
      }
    },
    [note, updateNote],
  );

  const onChangeDescription = useCallback(
    (e: ChangeEvent<HTMLTextAreaElement>) => {
      if (note) {
        setDescriptionValue(e.target.value);
        updateNote({ ...note, description: e.target.value, updateDate: new Date().toISOString() });
      }
    },
    [note, updateNote],
  );

  const onChangeStatus = useCallback(() => {
    if (note) {
      updateNote({
        ...note,
        status: note.status === NoteStatus.Open ? NoteStatus.Completed : NoteStatus.Open,
        updateDate: new Date().toISOString(),
      });
    }
  }, [note, updateNote]);

  // Effects
  useEffect(() => {
    resizeTextarea(titleRef);
  }, [resizeTextarea, titleValue]);

  useEffect(() => {
    resizeTextarea(descriptionRef);
  }, [resizeTextarea, descriptionValue]);

  return (
    <div key={note.id} className={styles.selectedNote}>
      <textarea
        placeholder={formatMessage({ id: 'CORE.PLACEHOLDER.TITLE', defaultMessage: 'Title...' })}
        rows={1}
        onChange={onChangeTitle}
        ref={titleRef}
        value={titleValue}
        className={classnames(styles.title, { [styles.titleCompleted]: isCompleted })}
        data-gramm="false"
        maxLength={120}
        disabled={isCompleted || isReadOnly}
      />
      <textarea
        placeholder={formatMessage({ id: 'CORE.PLACEHOLDER.DESCRIPTION', defaultMessage: 'Description...' })}
        rows={1}
        onChange={onChangeDescription}
        ref={descriptionRef}
        value={descriptionValue}
        className={classnames(styles.description, { [styles.descriptionCompleted]: isCompleted })}
        data-gramm="false"
        maxLength={1500}
        disabled={isCompleted || isReadOnly}
      />
      {note.element && isTeethTemplate && (
        <span className={styles.element}>
          {formatMessage({ id: 'CORE.LABEL.TOOTH-NUMBER', defaultMessage: 'Tooth number' })}
          {': '}
          {note.element}
        </span>
      )}
      <div className={styles.actions}>
        {withStatus && !isReadOnly && (
          <IconCircleCheck
            className={classnames(styles.action, { 'text-success': isCompleted })}
            onClick={onChangeStatus}
            size={size}
            strokeWidth={strokeWidth}
          />
        )}

        {!isReadOnly && (
          <>
            <IconTrash onClick={deleteNote} className={styles.action} size={size} strokeWidth={strokeWidth} />
            <IconX onClick={closeNote} className={styles.action} size={size} strokeWidth={strokeWidth} />
          </>
        )}
      </div>
    </div>
  );
};
