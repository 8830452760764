import uniq from 'lodash/uniq';
import { Template, Values } from 'app/components/locationSelector/billingItemLocation/types';

const toothNumberRegExp = /^(1[1-8]|2[1-8]|3[1-8]|4[1-8]|5[1-5]|6[1-5]|7[1-5]|8[1-5]),?\s*$/;

export const checkToothNumber = (value: string): boolean => toothNumberRegExp.test(value);
export const joinElements = (elements: string[] | undefined): string => (elements ? elements.sort().join(', ') : '');
export const splitElements = (value: string): string[] => uniq(value.split(/[, ]+/).filter(Boolean));
export const getLocationValue = (elements: string[], template: Template): Values | null =>
  !!elements.length
    ? {
        elements,
        template,
      }
    : null;
