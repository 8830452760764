import { useMemo } from 'react';
import { LocalTable } from 'app/shared';
import { useAppIntl, useAppSelector } from 'app/helpers';
import { CalendarWorkingTime } from 'app/types';
import { useColumns, useDayEffect } from './helpers';

export const AvailableWaitingAppointments = () => {
  const { formatMessage } = useAppIntl();
  const day = useAppSelector((state) => state.appointment.day);
  const data = useMemo((): CalendarWorkingTime[] => {
    if (day.data) {
      return day.data.workingTimesWithSlots.filter((wt) => !!wt.slots.length);
    }
    return [];
  }, [day]);
  const columns = useColumns();

  useDayEffect();

  return (
    <>
      <h5 className="mb-3">{formatMessage({ id: 'APPOINTMENTS.TEXT.AVAILABLE-WAITING-APPOINTMENTS' })}</h5>
      <div className="mb-4">
        <LocalTable
          columns={columns}
          data={data}
          id="availableWaitingAppointments"
          keyField="workingTimeStart"
          loading={day.loading}
        />
      </div>
    </>
  );
};
