import { useMemo } from 'react';
import { durationHumanizer, getDurationValuesFromMinutes, useAppIntl } from 'app/helpers';
import { Unit } from 'humanize-duration';

interface Props {
  inline?: boolean;
  minutes: number;
}

interface Item {
  label: string;
  unit: Unit;
  value: number;
}

export const FormattedDuration = ({ inline, minutes }: Props) => {
  const { formatMessage, locale } = useAppIntl();
  const { days: d, hours: h, minutes: m } = getDurationValuesFromMinutes(minutes);

  const items: Item[] = useMemo(
    () =>
      [
        {
          label: formatMessage({ id: 'CORE.TEXT.DAYS' }),
          unit: 'd',
          value: d * 24 * 60 * 60 * 1000,
        } as Item,
        {
          label: formatMessage({ id: 'CORE.TEXT.HOURS' }),
          unit: 'h',
          value: h * 60 * 60 * 1000,
        } as Item,
        {
          label: formatMessage({ id: 'CORE.TEXT.MINUTES' }),
          unit: 'm',
          value: m * 60 * 1000,
        } as Item,
      ].filter((i) => i.value > 0),
    [formatMessage, d, h, m],
  );

  const classes = useMemo(() => (inline ? 'text-nowrap me-1' : 'd-block text-nowrap'), [inline]);

  return (
    <>
      {items.map((i, index) => (
        <span className={classes} key={index}>
          {durationHumanizer(i.value, {
            language: locale,
            fallbacks: ['en'],
            units: [i.unit],
            maxDecimalPoints: 0,
          })}
        </span>
      ))}
    </>
  );
};
