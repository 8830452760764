export enum WizardStep {
  SubSpeciality = 'subSpeciality',
  Service = 'service',
  ConflictingAppointments = 'conflictingAppointments',
  Patient = 'patient',
}

export interface TimePeriod {
  from: Date;
  to?: Date;
}

export interface WorkingTime {
  id: string;
  startTime: string;
  endTime: string;
}

export interface SlotParams {
  start: string; //api date&time
  end: string; //api date&time
  doctorId: string;
  branchId: string;
  ignoredAppointmentId?: string;
}

export interface InitialDataParams {
  branchId: string;
  timePeriod: TimePeriod;
  appointmentId?: string;
  isWaiting?: boolean;
}
