import { FormattedMessage } from 'react-intl';
import { WrappedFieldProps } from 'redux-form/lib/Field';
import { FormFeedback, FormGroup, Label } from 'reactstrap';
import { EditorProps } from 'react-draft-wysiwyg';
import { TextEditor } from 'app/components/textEditor/textEditor';

interface Props extends Partial<EditorProps> {
  label?: string;
  isRequired?: boolean;
  isReadOnly?: boolean;
  className?: string;
}

export const RichText = ({ input, isRequired, isReadOnly, meta, label, className }: WrappedFieldProps & Props) => {
  // Helper Variables
  const inputId = input.name;
  const invalid = !!meta.error && meta.touched;

  return (
    <FormGroup className={className}>
      {label && (
        <Label for={inputId}>
          {label}
          {isRequired && <span className="text-danger">&nbsp;*</span>}
        </Label>
      )}
      <TextEditor
        onContentStateChange={(contentState) => input.onChange(contentState)}
        initialContentState={meta.initial}
        readOnly={!!isReadOnly}
      />
      {invalid && (
        <FormFeedback className="d-block">
          <FormattedMessage id={meta.error} />
        </FormFeedback>
      )}
    </FormGroup>
  );
};
