import { createAction, RSAA } from 'redux-api-middleware';
import * as types from './invoice.types';
import { DICTIONARIES_LIMIT } from 'app/shared';
import { RemoteDataParams } from 'app/types';
import {
  CreditNoteShortInfoPayload,
  DoctorPriceListParams,
  InsurancePayment,
  InvoiceDraftItemEditParams,
  InvoiceItem,
  InvoiceItemEditParams,
  InvoicePayment,
  InvoiceShortInfoPayload,
  NewInvoice,
} from './types';
import { HistoricalInvoiceParams } from './types/HistoricalInvoiceParams';
import { paramsAdapter } from '../remoteData/helpers';

export const addDraftItem = (body: Partial<InvoiceItem>) => ({
  type: types.ADD_DRAFT_ITEM,
  payload: body,
});

export const addInvoiceItem = (body: Partial<InvoiceItem>, invoiceId: string) => ({
  [RSAA]: {
    endpoint: `/invoices/${invoiceId}/items`,
    method: 'POST',
    types: [
      types.ADD_INVOICE_ITEM_REQUEST,
      {
        type: types.ADD_INVOICE_ITEM_SUCCESS,
        meta: {
          notification: {
            type: 'success',
            title: 'INVOICES.TEXT.ITEM-ADD-SUCCESS',
          },
          invoiceId,
        },
      },
      {
        type: types.ADD_INVOICE_ITEM_FAILURE,
        meta: {
          notification: {
            type: 'danger',
            title: 'CORE.TEXT.DEFAULT-FAILURE-MESSAGE',
          },
          invoiceId,
        },
      },
    ],
    body,
  },
});

export const addInsurancePayment = (body: InsurancePayment, invoiceId: string) => ({
  [RSAA]: {
    endpoint: `/invoices/${invoiceId}/insurance-payments`,
    method: 'POST',
    types: [
      types.ADD_INSURANCE_PAYMENT_REQUEST,
      {
        type: types.ADD_INSURANCE_PAYMENT_SUCCESS,
        meta: {
          notification: {
            type: 'success',
            title: 'INVOICES.TEXT.PAYMENT-ADD-SUCCESS',
          },
          invoiceId,
        },
      },
      {
        type: types.ADD_INSURANCE_PAYMENT_FAILURE,
        meta: {
          notification: {
            type: 'danger',
            title: 'CORE.TEXT.DEFAULT-FAILURE-MESSAGE',
          },
          invoiceId,
        },
      },
    ],
    body,
  },
});

export const addPatientPayment = (body: InvoicePayment, invoiceId: string) => ({
  [RSAA]: {
    endpoint: `/invoices/${invoiceId}/patient-payments`,
    method: 'POST',
    types: [
      types.ADD_PATIENT_PAYMENT_REQUEST,
      {
        type: types.ADD_PATIENT_PAYMENT_SUCCESS,
        meta: {
          notification: {
            type: 'success',
            title: 'INVOICES.TEXT.PAYMENT-ADD-SUCCESS',
          },
          invoiceId,
        },
      },
      {
        type: types.ADD_PATIENT_PAYMENT_FAILURE,
        meta: {
          notification: {
            type: 'danger',
            title: 'CORE.TEXT.DEFAULT-FAILURE-MESSAGE',
          },
          invoiceId,
        },
      },
    ],
    body,
  },
});

export const createInvoice = (body: NewInvoice) => {
  return {
    [RSAA]: {
      types: [
        types.CREATE_INVOICE_REQUEST,
        {
          type: types.CREATE_INVOICE_SUCCESS,
          meta: {
            notification: {
              type: 'success',
              title: 'INVOICES.TEXT.INVOICE-CREATED-SUCCESS',
            },
          },
        },
        {
          type: types.CREATE_INVOICE_FAILURE,
        },
      ],
      endpoint: '/invoices',
      method: 'POST',
      body,
    },
  };
};

export const deleteInvoiceItem = (invoiceId: string, itemId: string) => ({
  [RSAA]: {
    types: [
      types.DELETE_INVOICE_ITEM_REQUEST,
      {
        type: types.DELETE_INVOICE_ITEM_SUCCESS,
        meta: {
          notification: {
            type: 'success',
            title: 'INVOICES.TEXT.ITEM-DELETE-SUCCESS',
          },
          invoiceId,
        },
      },
      {
        type: types.DELETE_INVOICE_ITEM_FAILURE,
        meta: {
          notification: {
            type: 'danger',
            title: 'CORE.TEXT.DEFAULT-FAILURE-MESSAGE',
          },
          invoiceId,
        },
      },
    ],
    endpoint: `/invoices/${invoiceId}/items/${itemId}`,
    method: 'DELETE',
  },
});

export const deleteDraftItem = (index: number) => ({
  type: types.DELETE_DRAFT_ITEM,
  payload: index,
});

export const deleteInvoicePayment = (invoiceId: string, paymentId: string, isPatientPayment: boolean) => ({
  [RSAA]: {
    types: [
      types.DELETE_INVOICE_PAYMENT_REQUEST,
      {
        type: types.DELETE_INVOICE_PAYMENT_SUCCESS,
        meta: {
          notification: {
            type: 'success',
            title: 'INVOICES.TEXT.PAYMENT-DELETE-SUCCESS',
          },
          invoiceId,
        },
      },
      {
        type: types.DELETE_INVOICE_PAYMENT_FAILURE,
        meta: {
          notification: {
            type: 'danger',
            title: 'CORE.TEXT.DEFAULT-FAILURE-MESSAGE',
          },
          invoiceId,
        },
      },
    ],
    endpoint: isPatientPayment
      ? `invoices/${invoiceId}/patient-payments/${paymentId}`
      : `invoices/${invoiceId}/insurance-payments/${paymentId}`,
    method: 'DELETE',
  },
});

export const editDraftItem = ({ body, itemIndex }: InvoiceDraftItemEditParams) => ({
  type: types.EDIT_DRAFT_ITEM,
  payload: { body, itemIndex },
});

export const editInvoiceItem = ({ body, invoiceId, isAppointmentServiceItem = false }: InvoiceItemEditParams) => ({
  [RSAA]: {
    endpoint: isAppointmentServiceItem
      ? `/invoices/${invoiceId}/appointment-service-item`
      : `/invoices/${invoiceId}/items`,
    method: 'PUT',
    types: [
      types.EDIT_INVOICE_ITEM_REQUEST,
      {
        type: types.EDIT_INVOICE_ITEM_SUCCESS,
        meta: {
          notification: {
            type: 'success',
            title: 'INVOICES.TEXT.ITEM-EDIT-SUCCESS',
          },
          invoiceId,
        },
      },
      {
        type: types.EDIT_INVOICE_ITEM_FAILURE,
        meta: {
          notification: {
            type: 'danger',
            title: 'CORE.TEXT.DEFAULT-FAILURE-MESSAGE',
          },
          invoiceId,
        },
      },
    ],
    body,
  },
});

export const editInsurancePayment = (body: InsurancePayment, invoiceId: string, paymentId: string) => ({
  [RSAA]: {
    endpoint: `/invoices/${invoiceId}/insurance-payments/${paymentId}`,
    method: 'PUT',
    types: [
      types.EDIT_INSURANCE_PAYMENT_REQUEST,
      {
        type: types.EDIT_INSURANCE_PAYMENT_SUCCESS,
        meta: {
          notification: {
            type: 'success',
            title: 'INVOICES.TEXT.PAYMENT-EDIT-SUCCESS',
          },
          invoiceId,
        },
      },
      {
        type: types.EDIT_INSURANCE_PAYMENT_FAILURE,
        meta: {
          notification: {
            type: 'danger',
            title: 'CORE.TEXT.DEFAULT-FAILURE-MESSAGE',
          },
          invoiceId,
        },
      },
    ],
    body,
  },
});

export const editPatientPayment = (body: InvoicePayment, invoiceId: string, paymentId: string) => ({
  [RSAA]: {
    endpoint: `/invoices/${invoiceId}/patient-payments/${paymentId}`,
    method: 'PUT',
    types: [
      types.EDIT_PATIENT_PAYMENT_REQUEST,
      {
        type: types.EDIT_PATIENT_PAYMENT_SUCCESS,
        meta: {
          notification: {
            type: 'success',
            title: 'INVOICES.TEXT.PAYMENT-EDIT-SUCCESS',
          },
          invoiceId,
        },
      },
      {
        type: types.EDIT_PATIENT_PAYMENT_FAILURE,
        meta: {
          notification: {
            type: 'danger',
            title: 'CORE.TEXT.DEFAULT-FAILURE-MESSAGE',
          },
          invoiceId,
        },
      },
    ],
    body,
  },
});

export const fetchInvoiceAppointment = (appointmentId: string) => ({
  [RSAA]: {
    endpoint: '/invoices/create',
    params: {
      appointmentId,
    },
    method: 'GET',
    types: [
      types.FETCH_INVOICE_APPOINTMENT_REQUEST,
      types.FETCH_INVOICE_APPOINTMENT_SUCCESS,
      types.FETCH_INVOICE_APPOINTMENT_FAILURE,
    ],
  },
});

export const fetchInvoiceDetails = (invoiceId: string) => ({
  [RSAA]: {
    endpoint: `/invoices/${invoiceId}`,
    method: 'GET',
    types: [
      types.FETCH_INVOICE_DETAILS_REQUEST,
      types.FETCH_INVOICE_DETAILS_SUCCESS,
      types.FETCH_INVOICE_DETAILS_FAILURE,
    ],
  },
});

export const fetchHistoricalInvoiceDetails = (invoiceId: string, params: HistoricalInvoiceParams) => ({
  [RSAA]: {
    endpoint: `/historical-invoices/${invoiceId}`,
    method: 'GET',
    params,
    types: [
      types.FETCH_INVOICE_DETAILS_REQUEST,
      types.FETCH_INVOICE_DETAILS_SUCCESS,
      types.FETCH_INVOICE_DETAILS_FAILURE,
    ],
  },
});

export const fetchInvoiceHistory = (invoiceId: string, params: RemoteDataParams) => ({
  [RSAA]: {
    endpoint: `invoices/${invoiceId}/history`,
    params: paramsAdapter(params),
    method: 'GET',
    types: [
      types.FETCH_INVOICE_HISTORY_REQUEST,
      {
        type: types.FETCH_INVOICE_HISTORY_SUCCESS,
        meta: params,
      },
      types.FETCH_INVOICE_HISTORY_FAILURE,
    ],
  },
});

export const fetchHistoricalInvoiceHistory = (invoiceId: string, params: RemoteDataParams) => ({
  [RSAA]: {
    endpoint: `historical-invoices/${invoiceId}/history`,
    params: paramsAdapter(params),
    method: 'GET',
    types: [
      types.FETCH_INVOICE_HISTORY_REQUEST,
      {
        type: types.FETCH_INVOICE_HISTORY_SUCCESS,
        meta: params,
      },
      types.FETCH_INVOICE_HISTORY_FAILURE,
    ],
  },
});

export const fetchSpecialitiesDictionaries = (branchId: string) => ({
  [RSAA]: {
    endpoint: `/branches/${branchId}/all-specialities`,
    method: 'GET',
    types: [
      types.FETCH_SPECIALITIES_DICTIONARIES_REQUEST,
      types.FETCH_SPECIALITIES_DICTIONARIES_SUCCESS,
      types.FETCH_SPECIALITIES_DICTIONARIES_FAILURE,
    ],
  },
});

export const fetchSubSpecialitiesDictionaries = (
  specialityId: string,
  params: { branchId: string; doctorId?: string },
) => ({
  [RSAA]: {
    endpoint: `/specialities/${specialityId}/subspecialities`,
    method: 'GET',
    params: {
      pageSize: DICTIONARIES_LIMIT,
      ...params,
    },
    types: [
      types.FETCH_SUBSPECIALITIES_DICTIONARIES_REQUEST,
      types.FETCH_SUBSPECIALITIES_DICTIONARIES_SUCCESS,
      types.FETCH_SUBSPECIALITIES_DICTIONARIES_FAILURE,
    ],
  },
});

export const fetchDoctorPriceList = ({ doctorId, branchId, productId, dateFrom }: DoctorPriceListParams) => ({
  [RSAA]: {
    endpoint: `doctors/${doctorId}/branches/${branchId}/products/${productId}/price-list`,
    method: 'GET',
    params: { dateFrom: dateFrom },
    types: [
      types.FETCH_DOCTOR_PRODUCT_PRICE_LIST_REQUEST,
      types.FETCH_DOCTOR_PRODUCT_PRICE_LIST_SUCCESS,
      types.FETCH_DOCTOR_PRODUCT_PRICE_LIST_FAILURE,
    ],
  },
});

export const fetchInvoiceShortInfo = (invoiceId: string) =>
  createAction<any, InvoiceShortInfoPayload, undefined>({
    endpoint: `hi/${invoiceId}/short-info`,
    method: 'GET',
    types: [types.FETCH_INVOICE_SHORT_REQUEST, types.FETCH_INVOICE_SHORT_SUCCESS, types.FETCH_INVOICE_SHORT_FAILURE],
  });

export const fetchCreditNoteShortInfo = (creditNoteId: string) =>
  createAction<any, CreditNoteShortInfoPayload, undefined>({
    endpoint: `hcn/${creditNoteId}/short-info`,
    method: 'GET',
    types: [
      types.FETCH_CREDIT_NOTE_SHORT_REQUEST,
      types.FETCH_CREDIT_NOTE_SHORT_SUCCESS,
      types.FETCH_CREDIT_NOTE_SHORT_FAILURE,
    ],
  });
