export const ADD_INVOICE_ITEM = 'invoicingProcess/ADD_INVOICE_ITEM';
export const ADD_PAYMENT_METHOD_ITEM = 'invoicingProcess/ADD_PAYMENT_METHOD_ITEM';
export const DELETE_INVOICE_ITEM = 'invoicingProcess/DELETE_INVOICE_ITEM';
export const DELETE_PAYMENT_METHOD_ITEM = 'invoicingProcess/DELETE_PAYMENT_METHOD_ITEM';
export const EDIT_INVOICE_ITEM = 'invoicingProcess/EDIT_INVOICE_ITEM';
export const EDIT_PAYMENT_METHOD_ITEM = 'invoicingProcess/EDIT_PAYMENT_METHOD_ITEM';
export const INITIALIZE = 'invoicingProcess/INITIALIZE';
export const RESET = 'invoicingProcess/RESET';
export const SELECT_ITEM = 'invoicingProcess/SELECT_ITEM';
export const RESET_INVOICE_ITEMS = 'invoicingProcess/RESET_INVOICE_ITEMS';
export const SELECT_PAYMENT_METHOD_ITEM = 'invoicingProcess/SELECT_PAYMENT_METHOD_ITEM';
export const SET_APPOINTMENT = 'invoicingProcess/SET_APPOINTMENT';
export const SET_REQUEST = 'invoicingProcess/SET_REQUEST';
export const SET_INVOICE_PACKAGE_SNAPSHOT_ID = 'invoicingProcess/SET_INVOICE_PACKAGE_SNAPSHOT_ID';
export const SET_MODE = 'invoicingProcess/SET_MODE';
export const SET_STEP = 'invoicingProcess/SET_STEP';
export const SET_CREDIT_NOTE_CONFIGURATION = 'invoicingProcess/SET_CREDIT_NOTE_CONFIGURATION';
export const SET_INSURANCE_COMPANY_CACHE_UNIQ = 'invoicingProcess/SET_INSURANCE_COMPANY_CACHE_UNIQ';

export const FETCH_INVOICE_REQUEST = 'invoicingProcess/FETCH_INVOICE_REQUEST';
export const FETCH_INVOICE_SUCCESS = 'invoicingProcess/FETCH_INVOICE_SUCCESS';
export const FETCH_INVOICE_FAILURE = 'invoicingProcess/FETCH_INVOICE_FAILURE';

export const FETCH_DRAFT_REQUEST = 'invoicingProcess/FETCH_DRAFT_REQUEST';
export const FETCH_DRAFT_SUCCESS = 'invoicingProcess/FETCH_DRAFT_SUCCESS';
export const FETCH_DRAFT_FAILURE = 'invoicingProcess/FETCH_DRAFT_FAILURE';

export const FETCH_CREDIT_NOTE_REQUEST = 'invoicingProcess/FETCH_CREDIT_NOTE_REQUEST';
export const FETCH_CREDIT_NOTE_SUCCESS = 'invoicingProcess/FETCH_CREDIT_NOTE_SUCCESS';
export const FETCH_CREDIT_NOTE_FAILURE = 'invoicingProcess/FETCH_CREDIT_NOTE_FAILURE';

export const FETCH_EMPTY_WITH_APPOINTMENT_REQUEST = 'invoicingProcess/FETCH_EMPTY_WITH_APPOINTMENT_REQUEST';
export const FETCH_EMPTY_WITH_APPOINTMENT_SUCCESS = 'invoicingProcess/FETCH_EMPTY_WITH_APPOINTMENT_SUCCESS';
export const FETCH_EMPTY_WITH_APPOINTMENT_FAILURE = 'invoicingProcess/FETCH_EMPTY_WITH_APPOINTMENT_FAILURE';

export const FETCH_EMPTY_WITHOUT_APPOINTMENT_REQUEST = 'invoicingProcess/FETCH_EMPTY_WITHOUT_APPOINTMENT_REQUEST';
export const FETCH_EMPTY_WITHOUT_APPOINTMENT_SUCCESS = 'invoicingProcess/FETCH_EMPTY_WITHOUT_APPOINTMENT_SUCCESS';
export const FETCH_EMPTY_WITHOUT_APPOINTMENT_FAILURE = 'invoicingProcess/FETCH_EMPTY_WITHOUT_APPOINTMENT_FAILURE';

export const FETCH_EMPTY_BY_REQUEST_REQUEST = 'invoicingProcess/FETCH_EMPTY_BY_REQUEST_REQUEST';
export const FETCH_EMPTY_BY_REQUEST_SUCCESS = 'invoicingProcess/FETCH_EMPTY_BY_REQUEST_SUCCESS';
export const FETCH_EMPTY_BY_REQUEST_FAILURE = 'invoicingProcess/FETCH_EMPTY_BY_REQUEST_FAILURE';

export const FETCH_INSURANCE_REFERRAL_ITEMS_REQUEST = 'invoicingProcess/FETCH_INSURANCE_REFERRAL_ITEMS_REQUEST';
export const FETCH_INSURANCE_REFERRAL_ITEMS_SUCCESS = 'invoicingProcess/FETCH_INSURANCE_REFERRAL_ITEMS_SUCCESS';
export const FETCH_INSURANCE_REFERRAL_ITEMS_FAILURE = 'invoicingProcess/FETCH_INSURANCE_REFERRAL_ITEMS_FAILURE';

export const FETCH_INSURANCE_APPROVAL_ITEMS_REQUEST = 'invoicingProcess/FETCH_INSURANCE_APPROVAL_ITEMS_REQUEST';
export const FETCH_INSURANCE_APPROVAL_ITEMS_SUCCESS = 'invoicingProcess/FETCH_INSURANCE_APPROVAL_ITEMS_SUCCESS';
export const FETCH_INSURANCE_APPROVAL_ITEMS_FAILURE = 'invoicingProcess/FETCH_INSURANCE_APPROVAL_ITEMS_FAILURE';

export const FETCH_PACKAGE_PAYMENT_MODE_REQUEST = 'invoicingProcess/FETCH_PACKAGE_PAYMENT_MODE_REQUEST';
export const FETCH_PACKAGE_PAYMENT_MODE_SUCCESS = 'invoicingProcess/FETCH_PACKAGE_PAYMENT_MODE_SUCCESS';
export const FETCH_PACKAGE_PAYMENT_MODE_FAILURE = 'invoicingProcess/FETCH_PACKAGE_PAYMENT_MODE_FAILURE';

export const CREATE_WITH_APPOINTMENT_REQUEST = 'invoicingProcess/CREATE_WITH_APPOINTMENT_REQUEST';
export const CREATE_WITH_APPOINTMENT_SUCCESS = 'invoicingProcess/CREATE_WITH_APPOINTMENT_SUCCESS';
export const CREATE_WITH_APPOINTMENT_FAILURE = 'invoicingProcess/CREATE_WITH_APPOINTMENT_FAILURE';

export const CREATE_WITHOUT_APPOINTMENT_REQUEST = 'invoicingProcess/CREATE_WITHOUT_APPOINTMENT_REQUEST';
export const CREATE_WITHOUT_APPOINTMENT_SUCCESS = 'invoicingProcess/CREATE_WITHOUT_APPOINTMENT_SUCCESS';
export const CREATE_WITHOUT_APPOINTMENT_FAILURE = 'invoicingProcess/CREATE_WITHOUT_APPOINTMENT_FAILURE';

export const CREATE_DRAFT_WITH_APPOINTMENT_REQUEST = 'invoicingProcess/CREATE_DRAFT_WITH_APPOINTMENT_REQUEST';
export const CREATE_DRAFT_WITH_APPOINTMENT_SUCCESS = 'invoicingProcess/CREATE_DRAFT_WITH_APPOINTMENT_SUCCESS';
export const CREATE_DRAFT_WITH_APPOINTMENT_FAILURE = 'invoicingProcess/CREATE_DRAFT_WITH_APPOINTMENT_FAILURE';

export const CREATE_DRAFT_WITHOUT_APPOINTMENT_REQUEST = 'invoicingProcess/CREATE_DRAFT_WITHOUT_APPOINTMENT_REQUEST';
export const CREATE_DRAFT_WITHOUT_APPOINTMENT_SUCCESS = 'invoicingProcess/CREATE_DRAFT_WITHOUT_APPOINTMENT_SUCCESS';
export const CREATE_DRAFT_WITHOUT_APPOINTMENT_FAILURE = 'invoicingProcess/CREATE_DRAFT_WITHOUT_APPOINTMENT_FAILURE';

export const CREATE_CREDIT_NOTE_REQUEST = 'invoicingProcess/CREATE_CREDIT_NOTE_REQUEST';
export const CREATE_CREDIT_NOTE_SUCCESS = 'invoicingProcess/CREATE_CREDIT_NOTE_SUCCESS';
export const CREATE_CREDIT_NOTE_FAILURE = 'invoicingProcess/CREATE_CREDIT_NOTE_FAILURE';

export const UPDATE_PAYMENTS_REQUEST = 'invoicingProcess/UPDATE_PAYMENTS_REQUEST';
export const UPDATE_PAYMENTS_SUCCESS = 'invoicingProcess/UPDATE_PAYMENTS_SUCCESS';
export const UPDATE_PAYMENTS_FAILURE = 'invoicingProcess/UPDATE_PAYMENTS_FAILURE';
