import React from 'react';
import baseDeepDiff from 'deep-diff';
import { FormattedMessage } from 'react-intl';

import { institutionLabels, ownerLabels } from 'app/shared/index';
import { ownerPrefix } from 'app/registration/registration.constants';

const fileFields = ['images', 'logo', 'medicalLicenseFile'];

const isDifferenceToDisplay = (item, locale = 'en') => {
  //value and label are irrelevant because they goes along wih translated changes
  if (item.path.includes('value') || item.path.includes('label')) {
    return false;
  }
  if (item.path.includes('translation') && !item.path.includes(locale)) {
    return false;
  }
  return !(isFileField(item) && !getFileChange(item));
};

function prepare(data) {
  return {
    ...data,
    location: data.location && `${data.location.lat}/${data.location.lng}`,
    images: (data.images || []).reduce((acc, image) => {
      acc[image.url] = image.url;
      return acc;
    }, {}),
  };
}

export function deepDiff(oldData, newData, locale) {
  const [oldBaseData, newBaseData, removedSpecialities, addedSpecialities] = extractSpecialities(oldData, newData);
  const differences = [
    ...(baseDeepDiff(prepare(oldBaseData), prepare(newBaseData)) || []), //generate without specialities
    ...(baseDeepDiff({ specialities: removedSpecialities }, { specialities: [] }) || []), //generate as removed
    ...(baseDeepDiff({ specialities: [] }, { specialities: addedSpecialities }) || []), //generate as added
  ];
  return differences ? differences.filter((item) => isDifferenceToDisplay(item, locale)) : [];
}

function extractSpecialities(oldData, newData) {
  const old = { ...oldData, specialities: [] };
  const newest = { ...newData, specialities: [] };
  const removedSpecialities = oldData.specialities || [];
  const addedSpecialities = newData.specialities || [];
  return [old, newest, removedSpecialities, addedSpecialities];
}

export function renderDiffItemTitle(difference) {
  const isOwnerField = difference.path[0] === ownerPrefix;
  const fieldName = isOwnerField ? difference.path[1] : difference.path[0];
  const label = isOwnerField ? ownerLabels[fieldName] : institutionLabels[fieldName];

  return label ? <FormattedMessage id={label} /> : <span>"-"</span>;
}

export function getFileChange(difference, locale) {
  if (difference.path[0] === 'images' || difference.path.some((elem) => elem === 'url')) {
    return renderDiffForImage(difference, locale);
  }
}

export function isFileField(difference) {
  return fileFields.includes(difference.path[0]) || difference.path[1] === 'crFile';
}

export const renderDiffItem = (difference, locale = 'en') => {
  if (isFileField(difference)) {
    return getFileChange(difference, locale);
  }
  if (difference.path[0] === 'location') {
    return renderDiffForLocation(difference);
  }
  if (
    (typeof difference.path[0] === 'string' && difference.path[0].toLowerCase().includes('phonenumber')) ||
    (difference.path.length > 1 &&
      typeof difference.path[1] === 'string' &&
      difference.path[1].toLowerCase().includes('phonenumber'))
  ) {
    return renderDiffForPhoneNumber(difference);
  }

  return renderDiffForItem(difference, locale);
};

function renderDiffForPhoneNumber(difference) {
  return renderDiffForItem(difference, 'en');
}

function renderDiffForLocation(difference) {
  const oldLocation = difference.lhs.split('/');
  const newLocation = difference.rhs.split('/');

  return (
    <span className="info__item--value">
      <a
        href={`https://maps.google.com/?q=${oldLocation[0]},${oldLocation[1]}`}
        rel="noopener noreferrer"
        target="_blank"
      >
        <span className="info__item--value">
          <FormattedMessage id="CORE.LABEL.SEE-ON-MAP" />
        </span>
      </a>
      {' -> '}
      <a
        href={`https://maps.google.com/?q=${newLocation[0]},${newLocation[1]}`}
        rel="noopener noreferrer"
        target="_blank"
      >
        <span className="info__item--value">
          <FormattedMessage id="CORE.LABEL.SEE-ON-MAP" />
        </span>
      </a>
    </span>
  );
}

function renderDiffForImage(difference, locale) {
  switch (difference.kind) {
    case 'E':
      return (
        <span className="info__item--value">
          <a href={difference.lhs} target="_blank" rel="noopener noreferrer">
            <s dir={locale === 'ar' ? 'rtl' : 'ltr'}>{difference.lhs}</s>
          </a>
          {' -> '}
          <a href={difference.rhs} target="_blank" rel="noopener noreferrer">
            {difference.rhs}
          </a>
        </span>
      );
    case 'N':
      return (
        <span className="info__item--value">
          +{' '}
          <a href={difference.rhs} target="_blank" rel="noopener noreferrer">
            {difference.rhs}
          </a>
        </span>
      );
    case 'D':
      return (
        <span className="info__item--value">
          -{' '}
          <a href={difference.lhs} target="_blank" rel="noopener noreferrer">
            <s dir={locale === 'ar' ? 'rtl' : 'ltr'}>{difference.lhs}</s>
          </a>
        </span>
      );
    default:
      return undefined;
  }
}

function renderDiffForItem(difference, locale) {
  switch (difference.kind) {
    case 'E':
      return renderDiffForEditedItem(difference, locale);
    case 'A':
      return renderDiffForAddedItem(difference, locale);
    case 'N':
      return renderDiffForAddedItem(difference, locale);
    case 'D':
      return renderDiffForDeletedItem(difference, locale);
    default:
      return undefined;
  }
}

function renderDiffForEditedItem(difference, locale) {
  return (
    <>
      {typeof difference.lhs === 'boolean' || typeof difference.rhs === 'boolean' ? (
        <span className="info__item--value">
          <s dir={locale === 'ar' ? 'rtl' : 'ltr'}> {difference.lhs.toString()} </s>
          {' -> '}
          <span dir={locale === 'ar' ? 'rtl' : 'ltr'}>{difference.rhs.toString()}</span>
        </span>
      ) : (
        <span className="info__item--value">
          <s dir={locale === 'ar' ? 'rtl' : 'ltr'}>{difference.lhs === null ? 'null' : difference.lhs}</s>
          {' -> '}
          <span dir={locale === 'ar' ? 'rtl' : 'ltr'}>{difference.rhs === null ? 'null' : difference.rhs}</span>
        </span>
      )}
    </>
  );
}

function renderDiffForDeletedItem(difference) {
  return (
    <span className="info__item--value">
      - <s>{JSON.stringify(difference.item)}</s>
    </span>
  );
}

function renderDiffForAddedItem(difference, locale) {
  const value = difference.item ? difference.item : difference;
  const changedValue = value.lhs ? value.lhs : value.rhs;
  const sign = value.kind === 'D' ? '-' : '+';
  let result = '';

  if (typeof changedValue === 'string') {
    result = changedValue;
  }
  if (typeof changedValue === 'object' && changedValue.translation) {
    result = changedValue.translation[locale];
  }
  return <span className="info__item--value">{`${sign} ${result}`}</span>;
}
