import toast from 'react-hot-toast';
import { ApiError } from 'redux-api-middleware';
import { PayloadAction } from '@reduxjs/toolkit';

export function* afterActionFailure(action: PayloadAction<ApiError<{ message?: string }>>) {
  const hasResponseMessage = 'response' in action.payload ? !!action.payload.response.message : false;

  if (!hasResponseMessage) {
    yield toast.error('CORE.TEXT.GENERAL-ERROR');
  }
}
