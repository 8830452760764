import { put } from 'redux-saga/effects';
import { toggleModal } from 'app/redux/modals/modals.actions';
import { refreshManageWaiting } from 'app/sagas/apppointment/generators/refreshManageWaiting';
import { refreshCalendarDayView, refreshCalendarWeekView } from 'app/sagas/calendar/generators';
import { refreshAppointmentList } from 'app/sagas/apppointment/generators/refreshAppointmentList';

export function* afterPromoteWaiting() {
  // Close modal
  yield put(toggleModal('promoteWaitingAppointment', false));

  // Refresh appointment list
  yield refreshAppointmentList();

  // Refresh actions
  yield refreshManageWaiting();

  // Refresh Calendar Day and Week View
  yield refreshCalendarDayView();
  yield refreshCalendarWeekView();
}
