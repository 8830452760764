import * as types from './patient.types';

const initialState = {
  data: null,
  searchPatient: {
    loading: false,
    data: null,
    error: false,
  },
  insurances: {
    loading: false,
    error: false,
    data: [],
    dictionaries: [],
    activeInvoices: [],
  },
  registrations: {
    loading: false,
    error: false,
    data: [],
  },
  wallets: {
    loading: false,
    error: false,
    data: [],
  },
  changeMobileNumber: {
    changeSuccess: false,
    changeError: false,
  },
  blockedClinics: [],
  summary: {
    data: {},
    error: false,
    loading: false,
  },
  accountCreditPayments: {
    data: [],
    loading: false,
    error: false,
  },
  withdrawFromCreditPayments: {
    data: [],
    loading: false,
  },
  updatePatient: {
    loading: false,
    data: null,
    error: false,
  },
};

export const patientReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.DELETE_PATIENT_INSURANCE_REQUEST:
      return {
        ...state,
        insurances: {
          ...state.insurances,
          activeInvoices: initialState.insurances.activeInvoices,
          loading: true,
        },
      };

    case types.DELETE_PATIENT_INSURANCE_SUCCESS:
      return {
        ...state,
        insurances: {
          ...state.insurances,
          loading: false,
        },
      };

    case types.DELETE_PATIENT_INSURANCE_FAILURE:
      return {
        ...state,
        insurances: {
          ...state.insurances,
          loading: false,
          activeInvoices: action.payload.response.invoiceIds || [],
        },
      };

    case types.FETCH_PATIENT_REQUEST:
      return {
        ...state,
        data: initialState.data,
        loading: true,
      };
    case types.FETCH_PATIENT_SUCCESS:
      return {
        ...state,
        data: action.payload.data,
        loading: false,
      };
    case types.FETCH_PATIENT_FAILURE:
      return {
        ...state,
        data: initialState.data,
        loading: false,
        error: true,
      };
    case types.FETCH_PATIENT_INSURANCES_REQUEST:
      return {
        ...state,
        insurances: {
          ...initialState.insurances,
          loading: true,
        },
      };
    case types.FETCH_PATIENT_INSURANCES_SUCCESS:
      return {
        ...state,
        insurances: {
          ...state.insurances,
          loading: false,
          data: action.payload.data,
          dictionaries: mapInsurances(action.payload.data),
        },
      };
    case types.FETCH_PATIENT_INSURANCES_FAILURE:
      return {
        ...state,
        insurances: {
          ...state.insurances,
          loading: false,
          error: true,
        },
      };
    case types.FETCH_PATIENT_WALLETS_REQUEST:
      return {
        ...state,
        wallets: {
          ...initialState.wallets,
          loading: true,
        },
      };
    case types.FETCH_PATIENT_WALLETS_SUCCESS:
      return {
        ...state,
        wallets: {
          loading: false,
          data: action.payload,
        },
      };
    case types.FETCH_PATIENT_WALLETS_FAILURE:
      return {
        ...state,
        wallets: {
          ...state.wallets,
          loading: false,
          error: true,
        },
      };
    case types.EDIT_PATIENT_REQUEST:
      return {
        ...state,
        whileFetching: true,
      };
    case types.EDIT_PATIENT_SUCCESS:
      return {
        ...state,
        whileFetching: false,
      };
    case types.EDIT_PATIENT_FAILURE:
      return {
        ...state,
        whileFetching: false,
      };
    case types.EDIT_DEPENDENT_PATIENT_REQUEST:
      return {
        ...state,
        whileFetching: true,
      };
    case types.EDIT_DEPENDENT_PATIENT_SUCCESS:
      return {
        ...state,
        whileFetching: false,
      };
    case types.EDIT_DEPENDENT_PATIENT_FAILURE:
      return {
        ...state,
        whileFetching: false,
      };
    case types.EDIT_PATIENT_NUMBER_SUCCESS:
      return {
        ...state,
        changeMobileNumber: {
          ...state.changeMobileNumber,
          changeSuccess: true,
        },
      };
    case types.EDIT_PATIENT_NUMBER_FAILURE:
      return {
        ...state,
        changeMobileNumber: {
          ...state.changeMobileNumber,
          changeSuccess: false,
          changeError: true,
        },
      };
    case types.RESET_VERIFY_PATIENT_NUMBER:
      return {
        ...state,
        changeMobileNumber: {
          changeSuccess: false,
          changeError: false,
        },
      };
    case types.FETCH_PATIENT_REGISTRATIONS_REQUEST:
      return {
        ...state,
        registrations: {
          data: [],
          loading: true,
        },
      };
    case types.FETCH_PATIENT_REGISTRATIONS_SUCCESS:
      return {
        ...state,
        registrations: {
          data: action.payload,
          loading: false,
          error: false,
        },
        loading: false,
      };
    case types.FETCH_PATIENT_REGISTRATIONS_FAILURE:
      return {
        ...state,
        registrations: {
          data: initialState.registrations.data,
          loading: false,
          error: true,
        },
      };
    case types.FETCH_BLOCKED_CLINICS_REQUEST:
      return {
        ...state,
        blockedClinics: [],
        loading: true,
      };
    case types.FETCH_BLOCKED_CLINICS_SUCCESS:
      return {
        ...state,
        blockedClinics: action.payload,
        loading: false,
      };
    case types.BLOCK_PATIENT_REQUEST:
    case types.UNBLOCK_PATIENT_REQUEST:
    case types.BLOCK_PATIENT_CLINICS_REQUEST:
    case types.UNBLOCK_PATIENT_CLINICS_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case types.BLOCK_PATIENT_SUCCESS:
    case types.BLOCK_PATIENT_FAILURE:
    case types.UNBLOCK_PATIENT_SUCCESS:
    case types.UNBLOCK_PATIENT_FAILURE:
    case types.BLOCK_PATIENT_CLINICS_SUCCESS:
    case types.BLOCK_PATIENT_CLINICS_FAILURE:
    case types.UNBLOCK_PATIENT_CLINICS_SUCCESS:
    case types.UNBLOCK_PATIENT_CLINICS_FAILURE:
      return {
        ...state,
        loading: false,
      };
    case types.FETCH_PATIENT_SUMMARY_REQUEST:
      return {
        ...state,
        summary: {
          loading: true,
          error: false,
        },
      };
    case types.FETCH_PATIENT_SUMMARY_SUCCESS:
      return {
        ...state,
        summary: {
          data: action.payload.data,
          loading: false,
        },
      };
    case types.FETCH_PATIENT_SUMMARY_FAILURE:
      return {
        ...state,
        summary: {
          data: action.payload,
          loading: false,
          error: true,
        },
      };
    case types.ADD_PATIENT_ACCOUNT_CREDIT_REQUEST:
      return {
        ...state,
        accountCreditPayments: {
          ...state.accountCreditPayments,
          loading: true,
          error: false,
        },
      };
    case types.ADD_PATIENT_ACCOUNT_CREDIT_SUCCESS:
      return {
        ...state,
        accountCreditPayments: {
          data: [],
          loading: false,
          error: false,
        },
      };
    case types.ADD_PATIENT_ACCOUNT_CREDIT_FAILURE:
      return {
        ...state,
        accountCreditPayments: {
          data: state.accountCreditPayments.data,
          loading: false,
          error: true,
        },
      };
    case types.SET_PATIENT_ACCOUNT_CREDIT_PAYMENTS:
      return {
        ...state,
        accountCreditPayments: {
          ...state.accountCreditPayments,
          data: action.payload,
        },
      };
    case types.SET_PATIENT_WITHDRAWAL_PAYMENTS:
      return {
        ...state,
        withdrawFromCreditPayments: {
          ...state.withdrawFromCreditPayments,
          data: action.payload,
        },
      };
    case types.FETCH_PATIENT_FOR_PIN_REQUEST:
      return {
        ...state,
        updatePatient: {
          loading: true,
          error: false,
          data: null,
        },
      };
    case types.FETCH_PATIENT_FOR_PIN_SUCCESS:
      return {
        ...state,
        updatePatient: {
          loading: false,
          error: false,
          data: action.payload.data,
        },
      };
    case types.FETCH_PATIENT_FOR_PIN_FAILURE:
      return {
        ...state,
        updatePatient: {
          loading: false,
          error: true,
          data: null,
        },
      };
    case types.WITHDRAW_ACC_CREDIT_REQUEST:
      return {
        ...state,
        withdrawFromCreditPayments: {
          ...state.withdrawFromCreditPayments,
          loading: true,
        },
      };
    case types.WITHDRAW_ACC_CREDIT_SUCCESS:
      return {
        ...state,
        withdrawFromCreditPayments: {
          ...state.withdrawFromCreditPayments,
          loading: false,
        },
      };
    case types.WITHDRAW_ACC_CREDIT_FAILURE:
      return {
        ...state,
        withdrawFromCreditPayments: {
          ...state.withdrawFromCreditPayments,
          loading: false,
        },
      };
    default:
      return state;
  }
};

const mapInsurances = (insurances) => {
  if (insurances && !!insurances.length) {
    return insurances.map(({ id, number, insuranceCompany, packageType }) => ({
      value: id,
      label: number,
      translation: {
        ar: `${insuranceCompany.nameAr} /${number} ${packageType ? `/${packageType}` : ''}`,
        en: `${insuranceCompany.nameEn} /${number} ${packageType ? `/${packageType}` : ''}`,
      },
    }));
  } else {
    return [];
  }
};
