import toast from 'react-hot-toast';
import { PayloadAction } from '@reduxjs/toolkit';
import { refreshAppointmentList } from 'app/sagas/apppointment/generators';
import { REBOOK_CONFLICT_APPOINTMENT_SUCCESS } from 'app/redux/conflict-appointments/conflict-appointments.types';

interface RebookResult {
  notRebookedAppointmentsCount: number;
  rebookedAppointmentsCount: number;
}

export function* afterCancelOrRebookAppointment({ type, payload }: PayloadAction<RebookResult>) {
  yield refreshAppointmentList();

  if (type === REBOOK_CONFLICT_APPOINTMENT_SUCCESS) {
    if (payload.notRebookedAppointmentsCount > 0) {
      yield toast.error('CONFLICT-APPOINTMENTS.TEXT.REBOOK-FAILURE');
    }

    if (payload.rebookedAppointmentsCount > 0) {
      yield toast.success('CONFLICT-APPOINTMENTS.TEXT.REBOOK-SUCCESS');
    }
  }
}
