import isEmpty from 'lodash/isEmpty';
import { LANGUAGES_EN_CODE } from 'app/shared';
import { Doctor, DoctorRank, DoctorTitle, Locale, Option } from 'app/types';
import { getTranslation } from 'app/helpers';

/**
 * Returns the full name of the Doctor with the Title for the selected locale
 * @param doctor
 * @param locale
 */
export const getDoctorFullName = (
  doctor: Pick<Doctor, 'firstNameEn' | 'lastNameEn' | 'firstNameAr' | 'lastNameAr' | 'title'>,
  locale: Locale,
): string => {
  if (isEmpty(doctor)) {
    return '';
  }

  const doctorFullName =
    locale === LANGUAGES_EN_CODE
      ? `${doctor.firstNameEn} ${doctor.lastNameEn}`
      : `${doctor.firstNameAr} ${doctor.lastNameAr}`;

  if (doctor.title && doctor.title.value !== DoctorTitle.None) {
    return `${getTranslation(doctor.title, locale)} ${doctorFullName}`;
  }

  // title does not exist or value is "None"
  return doctorFullName;
};

/**
 * Return Returns the name of the Doctor with the Title for the selected locale
 * @param doctorName
 * @param doctorTitle
 * @param locale
 */
export const getDoctorNameWithTitle = (doctorName: string, doctorTitle: Option, locale: Locale = Locale.en): string => {
  if (doctorTitle && doctorTitle.value !== DoctorTitle.None) {
    return `${getTranslation(doctorTitle, locale)} ${doctorName}`;
  }

  // title does not exist or value is "None"
  return doctorName;
};

/**
 * Returns the Rank for the selected locale or a dash if the "None" value is selected
 * @param doctor
 * @param locale
 */
export const getDoctorRank = (doctor: Pick<Doctor, 'rank'>, locale: Locale): string => {
  if (isEmpty(doctor) || !doctor.rank) {
    return '';
  }

  if (doctor.rank.value === DoctorRank.None) {
    return '-';
  }

  return getTranslation(doctor.rank, locale);
};
