export const FETCH_PATIENT_DEPENDENTS_REQUEST = 'dependents/FETCH_PATIENT_DEPENDENTS_REQUEST';
export const FETCH_PATIENT_DEPENDENTS_SUCCESS = 'dependents/FETCH_PATIENT_DEPENDENTS_SUCCESS';
export const FETCH_PATIENT_DEPENDENTS_FAILURE = 'dependents/FETCH_PATIENT_DEPENDENTS_FAILURE';

export const ADD_PATIENT_DEPENDENT_REQUEST = 'dependents/ADD_PATIENT_DEPENDENT_REQUEST';
export const ADD_PATIENT_DEPENDENT_SUCCESS = 'dependents/ADD_PATIENT_DEPENDENT_SUCCESS';
export const ADD_PATIENT_DEPENDENT_FAILURE = 'dependents/ADD_PATIENT_DEPENDENT_FAILURE';

export const CONVERT_TO_PATIENT_REQUEST = 'dependents/CONVERT_TO_PATIENT_REQUEST';
export const CONVERT_TO_PATIENT_SUCCESS = 'dependents/CONVERT_TO_PATIENT_SUCCESS';
export const CONVERT_TO_PATIENT_FAILURE = 'dependents/CONVERT_TO_PATIENT_FAILURE';

export const DELETE_PATIENT_DEPENDENTS_REQUEST = 'dependents/DELETE_PATIENT_DEPENDENTS_REQUEST';
export const DELETE_PATIENT_DEPENDENTS_SUCCESS = 'dependents/DELETE_PATIENT_DEPENDENTS_SUCCESS';
export const DELETE_PATIENT_DEPENDENTS_FAILURE = 'dependents/DELETE_PATIENT_DEPENDENTS_FAILURE';

export const DEPENDENT_SWITCH_TO_PATIENT_REQUEST = 'dependents/DEPENDENT_SWITCH_TO_PATIENT_REQUEST';
export const DEPENDENT_SWITCH_TO_PATIENT_SUCCESS = 'dependents/DEPENDENT_SWITCH_TO_PATIENT_SUCCESS';
export const DEPENDENT_SWITCH_TO_PATIENT_FAILURE = 'dependents/DEPENDENT_SWITCH_TO_PATIENT_FAILURE';
