import * as types from './notifications.types';

const initialState = {
  clinics: [],
  content: [],
  error: false,
  loading: false,
  scenarios: [],
  scheduled: [],
  stakeholderOptions: [],
};

export const notificationsReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.FETCH_NOTIFICATION_SCENARIOS_BY_STAKEHOLDER_REQUEST:
    case types.FETCH_NOTIFICATION_SCENARIOS_REQUEST:
      return {
        ...state,
        loading: true,
        content: [],
        scenarios: [],
      };
    case types.FETCH_NOTIFICATION_SCENARIO_CONTENT_REQUEST:
      return {
        ...state,
        loading: true,
        content: [],
      };
    case types.FETCH_NOTIFICATION_SCENARIOS_BY_STAKEHOLDER_FAILURE:
    case types.FETCH_NOTIFICATION_SCENARIO_CONTENT_FAILURE:
    case types.FETCH_NOTIFICATION_SCENARIOS_FAILURE:
    case types.FETCH_NOTIFICATION_SCHEDULED_FAILURE:
    case types.ADD_NOTIFICATION_MASS_MESSAGING_FAILURE:
    case types.EDIT_NOTIFICATION_SCHEDULED_FAILURE:
      return {
        ...state,
        error: true,
        loading: false,
      };
    case types.FETCH_NOTIFICATION_SCENARIOS_BY_STAKEHOLDER_SUCCESS:
    case types.FETCH_NOTIFICATION_SCENARIOS_SUCCESS:
      return {
        ...state,
        loading: false,
        scenarios: action.payload,
        content: [],
      };
    case types.FETCH_STAKEHOLDER_OPTIONS_SUCCESS:
      return {
        ...state,
        loading: false,
        stakeholderOptions: mapStakeholderOptions(action.payload),
      };
    case types.FETCH_NOTIFICATION_SCENARIO_CONTENT_SUCCESS:
      return {
        ...state,
        loading: false,
        content: action.payload,
      };
    case types.FETCH_NOTIFICATION_CLINICS_REQUEST:
      return {
        ...state,
        clinics: [],
        loading: true,
      };
    case types.FETCH_NOTIFICATION_CLINICS_SUCCESS:
      return {
        ...state,
        loading: false,
        clinics: action.payload,
      };
    case types.FETCH_NOTIFICATION_SCHEDULED_REQUEST:
    case types.ADD_NOTIFICATION_MASS_MESSAGING_REQUEST:
    case types.EDIT_NOTIFICATION_SCHEDULED_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case types.FETCH_NOTIFICATION_SCHEDULED_SUCCESS:
      return {
        ...state,
        loading: false,
        scheduled: action.payload,
      };
    case types.EDIT_NOTIFICATION_SCENARIO_STATUS_SUCCESS: {
      return {
        ...state,
        loading: false,
        scenarios: state.scenarios.map((scenario) =>
          scenario.stakeholderId === action.meta.stakeholderId
            ? { ...scenario, isEnabled: action.meta.enable }
            : scenario,
        ),
      };
    }
    case types.NOTIFICATION_RESET: {
      return {
        ...state,
        scenarios: [],
        content: [],
      };
    }
    case types.EDIT_NOTIFICATION_SCHEDULED_SUCCESS: {
      return {
        ...state,
        loading: false,
      };
    }
    default:
      return state;
  }
};

const mapStakeholderOptions = (stakeholderOptions) => {
  if (stakeholderOptions && stakeholderOptions.length > 0) {
    return stakeholderOptions.map(({ stakeholderId, nameAr, nameEn }) => ({
      label: nameEn,
      value: stakeholderId,
      translation: { ar: nameAr, en: nameEn },
    }));
  } else {
    return [];
  }
};
