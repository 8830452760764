import { Product } from 'app/types';

export const workingTimeProductsAdapter = (products: Array<Product>) =>
  products.map((product) => ({
    id: product.id,
    options: {
      appearOnMobileApp: product.appearOnMobileApp,
      duration: product.duration,
    },
    status: product.status,
  }));

export const updateProductList = (currentProducts: Array<Product>, product: Product) => {
  const newProducts = [...currentProducts];
  const productIndex = currentProducts.findIndex((p) => p.id === product.id);

  if (productIndex === -1) {
    // Add a new product
    newProducts.push(product);
  } else {
    // Update an existing product
    newProducts[productIndex] = product;
  }

  return newProducts;
};
