import { RSAA } from 'redux-api-middleware';
import { generateAppointmentsEndpoint } from 'app/helpers';
import { AppointmentModificationSource, AppointmentTimeType, AppointmentType } from 'app/types';
import * as types from './appointments.types';

export const fetchUpcomingAppointments = (params, endpoint) => {
  const defaultEndpoint = generateAppointmentsEndpoint(
    undefined,
    AppointmentType.Standard,
    AppointmentTimeType.Upcoming,
  );

  return {
    [RSAA]: {
      endpoint: endpoint || defaultEndpoint,
      method: 'GET',
      params,
      types: [
        types.FETCH_UPCOMING_APPOINTMENTS_REQUEST,
        types.FETCH_UPCOMING_APPOINTMENTS_SUCCESS,
        types.FETCH_UPCOMING_APPOINTMENTS_FAILURE,
      ],
    },
  };
};

export const appointmentCheckIn = (appointmentId) => ({
  [RSAA]: {
    endpoint: `/appointments/${appointmentId}/check-in`,
    method: 'PUT',
    types: [
      {
        type: types.APPOINTMENT_CHECKIN_REQUEST,
        meta: {
          appointmentId,
        },
      },
      {
        type: types.APPOINTMENT_CHECKIN_SUCCESS,
        meta: {
          appointmentId,
          notification: {
            type: 'success',
            title: 'PATIENTS-ARRIVAL.TEXT.CHECK-IN-NOTIFICATION',
          },
        },
      },
      {
        type: types.APPOINTMENT_CHECKIN_FAILURE,
      },
    ],
  },
});

export const appointmentUncheckIn = (appointmentId) => ({
  [RSAA]: {
    endpoint: `/appointments/${appointmentId}/uncheck-in`,
    method: 'PUT',
    types: [
      {
        type: types.APPOINTMENT_UNCHECKIN_REQUEST,
        meta: {
          appointmentId,
        },
      },
      {
        type: types.APPOINTMENT_UNCHECKIN_SUCCESS,
        meta: {
          appointmentId,
          notification: {
            type: 'success',
            title: 'PATIENTS-ARRIVAL.TEXT.CANCEL-CHECK-IN-NOTIFICATION',
          },
        },
      },
      {
        type: types.APPOINTMENT_UNCHECKIN_FAILURE,
      },
    ],
  },
});

export const fetchAppointmentConfirmation = (id, params) => ({
  [RSAA]: {
    endpoint: `/patient-appointments/${id}`,
    method: 'GET',
    params,
    types: [
      types.FETCH_APPOINTMENT_CONFIRMATION_REQUEST,
      types.FETCH_APPOINTMENT_CONFIRMATION_SUCCESS,
      types.FETCH_APPOINTMENT_CONFIRMATION_FAILURE,
    ],
  },
});

export const confirmAppointment = (id, params) => ({
  [RSAA]: {
    endpoint: `/patient-appointments/${id}/confirm`,
    method: 'PUT',
    params,
    body: {
      AppointmentSource: AppointmentModificationSource.Ticket,
    },
    types: [
      types.CONFIRM_APPOINTMENT_REQUEST,
      {
        type: types.CONFIRM_APPOINTMENT_SUCCESS,
        meta: {
          notification: {
            type: 'success',
            title: 'CONFIRMATION.CONFIRM-SUCCESS',
          },
        },
      },
      {
        type: types.CONFIRM_APPOINTMENT_FAILURE,
        meta: {
          notification: {
            type: 'danger',
            title: 'CONFIRMATION.FAILURE',
          },
        },
      },
    ],
  },
});

export const cancelAppointment = (id, params) => ({
  [RSAA]: {
    endpoint: `/patient-appointments/${id}/cancel`,
    method: 'PUT',
    params,
    types: [
      types.CANCEL_APPOINTMENT_REQUEST,
      {
        type: types.CANCEL_APPOINTMENT_SUCCESS,
        meta: {
          notification: {
            type: 'success',
            title: 'CONFIRMATION.CANCEL-SUCCESS',
          },
        },
      },
      {
        type: types.CANCEL_APPOINTMENT_FAILURE,
        meta: {
          notification: {
            type: 'danger',
            title: 'CONFIRMATION.FAILURE',
          },
        },
      },
    ],
  },
});

export const exportAppointments = (endpoint, body) => ({
  [RSAA]: {
    endpoint,
    method: 'POST',
    body,
    types: [
      types.EXPORT_APPOINTMENTS_REQUEST,
      {
        type: types.EXPORT_APPOINTMENTS_SUCCESS,
        meta: {
          notification: {
            type: 'success',
            title: 'CORE.TEXT.EXPORT-IN-PROGRESS-CONFIRMATION',
          },
        },
      },
      {
        type: types.EXPORT_APPOINTMENTS_FAILURE,
        meta: {
          notification: {
            type: 'danger',
            title: 'CORE.TEXT.DEFAULT-FAILURE-MESSAGE',
          },
        },
      },
    ],
  },
});
