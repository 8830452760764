import { Option } from 'app/types';

export const PatientInsuranceField = {
  activated: 'activated',
  clinic: 'clinic',
  clinicBranch: 'clinicBranch',
  end: 'end',
  insuranceClass: 'insuranceClass',
  insuranceCompany: 'insuranceCompany',
  number: 'number',
  policyNumber: 'policyNumber',
  start: 'start',
} as const;

export type PatientInsuranceFormData = {
  [PatientInsuranceField.activated]?: boolean;
  [PatientInsuranceField.clinic]: Option;
  [PatientInsuranceField.clinicBranch]: Option;
  [PatientInsuranceField.end]: Date;
  [PatientInsuranceField.insuranceClass]: Option;
  [PatientInsuranceField.insuranceCompany]: Option;
  [PatientInsuranceField.number]: string;
  [PatientInsuranceField.policyNumber]: Option;
  [PatientInsuranceField.start]: Date;
};
