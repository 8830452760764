import { useAppSelector } from 'app/helpers';
import {
  GovernmentIssuedIdType,
  Option,
  PatientEmergencyContactRelation,
  PatientMaritalStatus,
  PatientPhoneNumber,
} from 'app/types';
import { PatientFormField } from 'app/features/patient/patientForm/types';

export const useFormValues = (form: string) => {
  // Selectors
  const firstNameArValue: string | undefined = useAppSelector(
    (state) => state.form[form]?.values?.[PatientFormField.firstNameAr],
  );
  const firstNameEnValue: string | undefined = useAppSelector(
    (state) => state.form[form]?.values?.[PatientFormField.firstNameEn],
  );
  const grandFatherNameArValue: string | undefined = useAppSelector(
    (state) => state.form[form]?.values?.[PatientFormField.grandFatherNameAr],
  );
  const grandFatherNameEnValue: string | undefined = useAppSelector(
    (state) => state.form[form]?.values?.[PatientFormField.grandFatherNameEn],
  );
  const fatherNameArValue: string | undefined = useAppSelector(
    (state) => state.form[form]?.values?.[PatientFormField.fatherNameAr],
  );
  const fatherNameEnValue: string | undefined = useAppSelector(
    (state) => state.form[form]?.values?.[PatientFormField.fatherNameEn],
  );
  const familyNameArValue: string | undefined = useAppSelector(
    (state) => state.form[form]?.values?.[PatientFormField.familyNameAr],
  );
  const familyNameEnValue: string | undefined = useAppSelector(
    (state) => state.form[form]?.values?.[PatientFormField.familyNameEn],
  );
  const preferredNameArValue: string | undefined = useAppSelector(
    (state) => state.form[form]?.values?.[PatientFormField.preferredNameAr],
  );
  const preferredNameEnValue: string | undefined = useAppSelector(
    (state) => state.form[form]?.values?.[PatientFormField.preferredNameEn],
  );
  const selectedMaritalStatus: Option<PatientMaritalStatus> | undefined = useAppSelector(
    (state) => state.form[form]?.values?.[PatientFormField.maritalStatus],
  );
  const selectedGovernmentIdType: Option<GovernmentIssuedIdType> | undefined = useAppSelector(
    (state) => state.form[form]?.values?.[PatientFormField.typeOfGovernmentIssuedId],
  );
  const selectedEmergencyRelation: Option<PatientEmergencyContactRelation> | undefined = useAppSelector(
    (state) => state.form[form]?.values?.[PatientFormField.emergencyContactRelation],
  );
  const emergencyContactName: string | undefined = useAppSelector(
    (state) => state.form[form]?.values?.[PatientFormField.emergencyContactName],
  );

  const mobileNumbers: PatientPhoneNumber[] = useAppSelector(
    (state) => state.form[form]?.values?.[PatientFormField.patientMobileNumbers],
  );

  return {
    firstNameArValue,
    firstNameEnValue,
    grandFatherNameArValue,
    grandFatherNameEnValue,
    fatherNameArValue,
    fatherNameEnValue,
    familyNameArValue,
    familyNameEnValue,
    preferredNameArValue,
    preferredNameEnValue,
    selectedMaritalStatus,
    selectedGovernmentIdType,
    selectedEmergencyRelation,
    emergencyContactName,
    mobileNumbers,
  };
};
