import React, { useCallback } from 'react';
import { defineMessage } from 'react-intl';
import { useAppIntl } from 'app/helpers';
import { LANGUAGES_AR_CODE } from 'app/shared';
import { SlotOption } from 'app/appointment/form/types';

export const useSlotLabelFormatter = (showSlotsWithNumber: boolean) => {
  const { formatMessage, locale } = useAppIntl();

  return useCallback(
    (option: SlotOption) => {
      const numberOfAppointments = option.details?.numberOfAppointments;

      const message = defineMessage(
        locale === LANGUAGES_AR_CODE
          ? {
              id: 'bookedAppointmentsCount',
              defaultMessage: 'يوجد {count, plural, one {# موعد محجوز} other {# موعد محجوز}}',
            }
          : {
              id: 'bookedAppointmentsCount',
              defaultMessage: '{count, plural, one {# booked appointment} other {# booked appointments}}',
            },
      );

      if (showSlotsWithNumber && numberOfAppointments && numberOfAppointments > 0) {
        return (
          <div className="d-flex justify-content-between align-items-center">
            <span className="text-left text-nowrap">{option.label}</span>
            <span className="mx-2" />
            <small className="text-right text-danger">
              {formatMessage({ ...message }, { count: numberOfAppointments })}
            </small>
          </div>
        );
      }

      return option.label;
    },
    [formatMessage, locale, showSlotsWithNumber],
  );
};
