import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQueryWithReAuth } from 'app/helpers';
import { PatientClinicBalance, ClinicBalance, Transaction, AccountBalanceSummary } from 'app/types';
import { QueryResponseWithPagination, QueryParams } from 'app/types';
import { BalanceParams, TransactionParams } from 'app/services/accountBalances/types';

export const accountBalancesApi = createApi({
  reducerPath: 'accountBalancesApi',
  baseQuery: baseQueryWithReAuth,
  endpoints: (builder) => ({
    getClinicBalances: builder.query<QueryResponseWithPagination<ClinicBalance[]>, BalanceParams | undefined>({
      query: (params) => ({ url: 'wallets/summary/clinics', params }),
    }),
    getPatientBalances: builder.query<
      QueryResponseWithPagination<PatientClinicBalance[]>,
      { clinicId: string; params: QueryParams | undefined }
    >({
      query: ({ clinicId, params }) => ({ url: `wallets/summary/patients/clinics/${clinicId}`, params }),
    }),
    getPatientClinicBalances: builder.query<
      QueryResponseWithPagination<PatientClinicBalance[]>,
      { patientId: string; params: QueryParams | undefined }
    >({
      query: ({ patientId, params }) => ({ url: `wallets/summary/patients/${patientId}/clinics`, params }),
    }),
    getClinicTransactions: builder.query<
      QueryResponseWithPagination<Transaction[]>,
      { clinicId: string; params: QueryParams | undefined }
    >({
      query: ({ clinicId, params }) => ({ url: `wallets/clinics/${clinicId}`, params }),
    }),
    getPatientTransactions: builder.query<
      QueryResponseWithPagination<Transaction[]>,
      { patientId: string; params: TransactionParams | undefined }
    >({
      query: ({ patientId, params }) => ({ url: `wallets/clinic-patients/${patientId}/transactions`, params }),
    }),
    getClinicTransactionsReport: builder.mutation<void, { clinicId: string; params: QueryParams | undefined }>({
      query: ({ clinicId, params }) => ({ url: `wallets/clinics/${clinicId}/excel`, params }),
    }),
    getPatientTransactionsReport: builder.mutation<void, { patientId: string; params: QueryParams | undefined }>({
      query: ({ patientId, params }) => ({ url: `wallets/clinic-patients/${patientId}/excel`, params }),
    }),
    getSummaryReport: builder.mutation<void, { clinicId: string; params: QueryParams | undefined }>({
      query: ({ clinicId, params }) => ({ url: `wallets/summary/patients/clinics/${clinicId}/excel`, params }),
    }),
    getClinicBalanceSummary: builder.query<AccountBalanceSummary, QueryParams | undefined>({
      query: (params) => ({ url: 'clinic-balance-summary', params }),
    }),
    getClinicPatientBalanceSummary: builder.query<AccountBalanceSummary, QueryParams | undefined>({
      query: (params) => ({ url: 'clinic-patient-balance-summary', params }),
    }),
  }),
});

export const {
  useGetClinicBalancesQuery,
  useGetPatientBalancesQuery,
  useGetPatientClinicBalancesQuery,
  useGetClinicTransactionsQuery,
  useGetPatientTransactionsQuery,
  useGetClinicTransactionsReportMutation,
  useGetPatientTransactionsReportMutation,
  useGetSummaryReportMutation,
  useGetClinicBalanceSummaryQuery,
  useGetClinicPatientBalanceSummaryQuery,
} = accountBalancesApi;
