import { useEffect, useMemo } from 'react';
import isEmpty from 'lodash/isEmpty';
import sortBy from 'lodash/sortBy';
import { useAppDispatch, useAppIntl, useAppSelector } from 'app/helpers';
import { fetchDaySlots } from 'app/redux/appointment/appointment.actions';
import { CurrentParams, SlotOption } from 'app/appointment/form/types';
import { generateSlotOption } from './generateSlotOption';

export const useDaySlotOptions = (
  currentParams: CurrentParams,
  showFullSlots: boolean = false,
  showSlotsWithNumber: boolean = false,
): SlotOption[] => {
  const dispatch = useAppDispatch();
  const { formatMessage } = useAppIntl();

  // Selectors
  const daySlots = useAppSelector((state) => state.appointment.daySlots.data);
  const appointment = useAppSelector((state) => state.appointment.data);
  const productRelatedFilters = useAppSelector((state) => state.calendar.tableViewItems.params?.productRelatedFilters);

  // Variables
  const customDurationInMinutes = productRelatedFilters?.customDurationInMinutes;
  const noTimeMessage = formatMessage({ id: 'CORE.TEXT.WITHOUT-TIME' });

  useEffect(() => {
    const { branchId, date, doctorId, productId, specialityId, subSpecialityId } = currentParams;

    // Fetch day slots
    if (branchId && date && doctorId && productId && specialityId && subSpecialityId) {
      dispatch(
        fetchDaySlots({
          branchId,
          date,
          doctorId,
          productRelatedFilters: {
            customDurationInMinutes,
            productId,
            specialityId,
            subSpecialityId,
          },
          ignoredAppointmentIds: appointment ? [appointment.id] : undefined,
        }),
      );
    }
  }, [appointment, currentParams, customDurationInMinutes, dispatch]);

  return useMemo(() => {
    const isWaitingAppointment = !!appointment?.workingTimeId;
    const isAppointmentWithNoTime = !appointment?.end && !isWaitingAppointment;

    if (!appointment || !daySlots || isEmpty(daySlots)) {
      return [];
    }

    // Generate slot options
    let options = daySlots
      .filter((s) => {
        if (showFullSlots) {
          // Select all slots (full and free)
          return true;
        }

        // Select only free slots
        return s.overlappingAppointmentsIds.length === 0;
      })
      .map((s) => {
        if (showSlotsWithNumber) {
          // Generate slots with number of appointments
          return generateSlotOption(s.start, s.end, s.overlappingAppointmentsIds.length);
        }

        // Generate slots
        return generateSlotOption(s.start, s.end);
      });

    // IMPORTANT: This case has been handled on the BE side
    // Check if we need to add the appointment slot
    // if (
    //   !isWaitingAppointment &&
    //   appointment.start &&
    //   appointment.end &&
    //   moment(currentParams.date, FORMATS_API_DATE).isSame(appointment.start, 'day') &&
    //   appointment.product.value === currentParams.productId &&
    //   !appointment.isConflicting
    // ) {
    //   // Add an appointment slot if appointment day has been selected and the slot is no longer available
    //   if (!options.some(({ value }) => value.start === appointment.start && value.end === appointment.end)) {
    //     options.push(generateSlotOption(appointment.start, appointment.end));
    //   }
    // }

    if (isAppointmentWithNoTime) {
      // Add "Without Time" option
      options.push(generateSlotOption(appointment.start, null, null, noTimeMessage));
    }

    // Return sorted options
    return sortBy(options, 'value.start');
  }, [appointment, daySlots, noTimeMessage, showFullSlots, showSlotsWithNumber]);
};
