import React, { useCallback } from 'react';
import sortBy from 'lodash/sortBy';
import { change, Field } from 'redux-form';
import { Button, Col, FormFeedback, Row } from 'reactstrap';
import { IconCircleCheck, IconTrash } from '@tabler/icons-react';
import { WrappedFieldArrayProps } from 'redux-form/lib/FieldArray';
import classNames from 'classnames';
import { IconButton } from 'app/components';
import { PatientPhoneNumber } from 'app/types';
import { PhoneNumber, phoneNumber, required } from 'app/shared';
import { useAppDispatch, useAppIntl } from 'app/helpers';
import styles from 'app/features/patients/mergeProfiles/mobileNumbersFieldArray/mobileNumbersFieldArray.module.scss';

export const MobileNumbersFieldArray = ({ fields, meta }: WrappedFieldArrayProps<Partial<PatientPhoneNumber>>) => {
  const dispatch = useAppDispatch();
  const { formatMessage } = useAppIntl();

  const patientPhoneNumbers = fields.getAll();

  // Conditions
  const isRemoveDisabled = patientPhoneNumbers?.length === 1;

  // Handlers
  const makeDefault = useCallback(
    (index: number, fieldValues: Partial<PatientPhoneNumber>[]) => {
      if (!fieldValues[index].isDefault) {
        const data: PatientPhoneNumber[] = fieldValues.map((value, i) => ({
          phoneNumber: value.phoneNumber || '',
          isDefault: i === index,
          id: value.id,
        }));
        const sortedData = sortBy(data, [(item) => (item.isDefault ? -1 : 1), 'id']);
        dispatch(change(meta.form, fields.name, sortedData));
      }
    },
    [dispatch, meta, fields.name],
  );

  const addMobileNumber = useCallback(() => {
    fields.push({ phoneNumber: '', isDefault: fields.length === 0 });
  }, [fields]);

  const removeMobileNumber = useCallback(
    (index: number) => {
      if (patientPhoneNumbers.length > 1 && patientPhoneNumbers[index].isDefault) {
        const updatedNumbers = patientPhoneNumbers.slice(1).map(
          (item, index): PatientPhoneNumber => ({
            id: item.id,
            phoneNumber: item.phoneNumber!,
            isDefault: index === 0,
          }),
        );
        dispatch(change(meta.form, fields.name, updatedNumbers));
      } else {
        fields.remove(index);
      }
    },
    [dispatch, fields, meta.form, patientPhoneNumbers],
  );

  return (
    <>
      {fields.map((name: string, index: number, fields) => (
        <div key={`mobileNumber-${index}`} className={styles.phoneNumberRow}>
          <div className={classNames(styles.phoneNumber, 'me-2')}>
            <Field name={`${name}.phoneNumber`} component={PhoneNumber} size="sm" validate={[required, phoneNumber]} />
          </div>
          <div className={styles.actions}>
            <IconButton
              icon={
                <IconCircleCheck
                  size={18}
                  strokeWidth={1.8}
                  color={fields.get(index).isDefault ? '#2ace73' : 'lightgray'}
                />
              }
              className="me-1"
              onClick={() => makeDefault(index, fields.getAll())}
              tooltipMessageId={!fields.get(index).isDefault ? 'CORE.LABEL.MAKE-DEFAULT-MOBILE-NUMBER' : undefined}
            />
            <IconButton
              disabled={isRemoveDisabled}
              icon={<IconTrash size={18} strokeWidth={1.8} />}
              onClick={() => removeMobileNumber(index)}
            />
          </div>
        </div>
      ))}
      {meta.error && <FormFeedback className="d-block mb-1">{formatMessage({ id: meta.error })}</FormFeedback>}
      <Row className="mt-1">
        <Col>
          <Button type="button" size="sm" className="btn-light w-100 mt-1" onClick={addMobileNumber}>
            {formatMessage({
              id: 'CORE.LABEL.ADD-NEW',
              defaultMessage: 'Add new',
            })}
          </Button>
        </Col>
      </Row>
    </>
  );
};
