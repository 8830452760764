import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { WrappedFieldProps } from 'redux-form';
import classnames from 'classnames';
import { FormFeedback, FormGroup, FormText, Label, Input } from 'reactstrap';
import { IconEye, IconEyeOff } from '@tabler/icons-react';
import { useAppIntl } from 'app/helpers';
import './passwordInput.scss';

export interface PasswordInputProps {
  id?: string;
  isRequired?: boolean;
  label: string;
  text?: string;
  isRtl?: boolean;
}

export const PasswordInput = ({
  id,
  input,
  isRequired,
  label,
  meta: { error, touched },
  text,
  isRtl,
}: WrappedFieldProps & PasswordInputProps) => {
  const iconSize = 20;
  const fieldId = id || input.name;
  const invalid = !!error && touched;
  const [passwordVisible, setPasswordVisible] = useState(false);

  const { isRtl: defaultIsRtl } = useAppIntl();
  const isRtlDir = isRtl === undefined ? defaultIsRtl : isRtl;

  const togglePasswordVisibility = () => setPasswordVisible(!passwordVisible);

  return (
    <FormGroup>
      <Label for={fieldId}>
        {label}
        {isRequired && <span className="text-danger">&nbsp;*</span>}
      </Label>
      <div
        className={classnames('passwordInput__wrapper', {
          'passwordInput__wrapper--invalid': invalid,
          'passwordInput__wrapper--isRtl': isRtlDir,
        })}
      >
        <Input {...input} maxLength={50} id={fieldId} invalid={invalid} type={passwordVisible ? 'text' : 'password'} />
        {passwordVisible ? (
          <IconEyeOff
            className={classnames('passwordInput__showIcon', {
              'passwordInput__showIcon--isRtl': isRtlDir,
              'passwordInput__showIcon--isLtr': !isRtlDir,
            })}
            size={iconSize}
            onClick={togglePasswordVisibility}
          />
        ) : (
          <IconEye
            className={classnames('passwordInput__showIcon', {
              'passwordInput__showIcon--isRtl': isRtlDir,
              'passwordInput__showIcon--isLtr': !isRtlDir,
            })}
            size={iconSize}
            onClick={togglePasswordVisibility}
          />
        )}
      </div>
      {invalid && (
        <FormFeedback className="d-block">
          <FormattedMessage id={error.message ? error.message : error} values={{ value: error.value }} />
        </FormFeedback>
      )}
      {!!text && <FormText>{text}</FormText>}
    </FormGroup>
  );
};
