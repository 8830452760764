import { createAction, RSAA, RSAAAction } from 'redux-api-middleware';
import * as types from './auth.types';
import { Tokens } from './types';

export const changePassword = (body: any) => {
  return {
    [RSAA]: {
      types: [types.CHANGE_PASSWORD_REQUEST, types.CHANGE_PASSWORD_SUCCESS, types.CHANGE_PASSWORD_FAILURE],
      endpoint: '/user/password/change',
      method: 'POST',
      body,
    },
  };
};

export const forgottenPassword = (body: any) => {
  return {
    [RSAA]: {
      types: [
        types.FORGOTTEN_PASSWORD_REQUEST,
        {
          type: types.FORGOTTEN_PASSWORD_SUCCESS,
          meta: {
            notification: {
              type: 'success',
              title: 'FORGOTTEN-PASSWORD.TEXT.FORGOT-PASSWORD-SUCCESS',
            },
          },
        },
        {
          type: types.FORGOTTEN_PASSWORD_FAILURE,
        },
      ],
      endpoint: '/user/password/forgetten',
      method: 'POST',
      body,
    },
  };
};

export const resetPassword = (body: any) => {
  return {
    [RSAA]: {
      types: [
        types.RESET_PASSWORD_REQUEST,
        {
          type: types.RESET_PASSWORD_SUCCESS,
          meta: {
            notification: {
              type: 'success',
              title: 'FORGOTTEN-PASSWORD.TEXT.RESET-PASSWORD-SUCCESS',
            },
          },
        },
        {
          type: types.RESET_PASSWORD_FAILURE,
        },
      ],
      endpoint: '/user/password/reset',
      method: 'POST',
      body,
    },
  };
};

export const login = (body: { username: string; password: string }) => ({
  [RSAA]: {
    body,
    endpoint: '/user/login',
    method: 'POST',
    skipAuth: true,
    types: [
      types.LOGIN_USER_REQUEST,
      types.LOGIN_USER_SUCCESS,
      {
        type: types.LOGIN_USER_FAILURE,
        meta: {
          skipError: true,
        },
      },
    ],
  },
});

export const logout = () => ({
  type: types.LOGOUT,
});

// Logout the user without removing the token from the localStorage
export const softLogout = () => ({
  type: types.SOFT_LOGOUT,
});

export const refreshToken = (body: { jwtToken: string; refreshToken: string }) =>
  createAction({
    // @ts-ignore (we do it via a separate middleware, so the types don't match)
    body,
    endpoint: '/user/refresh-token',
    method: 'POST',
    skipAuth: true,
    types: [
      types.REFRESH_TOKEN_REQUEST,
      types.REFRESH_TOKEN_SUCCESS,
      {
        type: types.REFRESH_TOKEN_FAILURE,
        meta: (action: RSAAAction, state: any, response: Response | undefined) => ({
          skipError: true,
          status: response?.status, // response is undefined for failed requests
        }),
      },
    ],
  });

export const fetchCurrentUser = () => ({
  [RSAA]: {
    types: [
      types.FETCH_CURRENT_USER_REQUEST,
      types.FETCH_CURRENT_USER_SUCCESS,
      {
        type: types.FETCH_CURRENT_USER_FAILURE,
        meta: {
          skipError: true,
        },
      },
    ],
    endpoint: '/user/me',
    method: 'GET',
  },
});

export const editProfile = (body: any) => ({
  [RSAA]: {
    types: [
      types.EDIT_USER_PROFILE_REQUEST,
      {
        type: types.EDIT_USER_PROFILE_SUCCESS,
        meta: {
          notification: {
            type: 'success',
            title: 'PROFILE.TEXT.EDIT-PROFILE-SUCCESS',
          },
        },
      },
      types.EDIT_USER_PROFILE_FAILURE,
    ],
    endpoint: `/user/me/edit`,
    method: 'PUT',
    body,
  },
});

export const fetchSpecialityOptions = () => ({
  [RSAA]: {
    endpoint: `/user/scoped/specialities`,
    method: 'GET',
    types: [
      types.FETCH_SPECIALITY_OPTIONS_REQUEST,
      types.FETCH_SPECIALITY_OPTIONS_SUCCESS,
      types.FETCH_SPECIALITY_OPTIONS_FAILURE,
    ],
  },
});

export const fetchOwnerBranchOptions = () => ({
  [RSAA]: {
    endpoint: `/branches/available`,
    method: 'GET',
    types: [
      types.FETCH_OWNER_BRANCH_OPTIONS_REQUEST,
      types.FETCH_OWNER_BRANCH_OPTIONS_SUCCESS,
      types.FETCH_OWNER_BRANCH_OPTIONS_FAILURE,
    ],
  },
});

export const setTokens = (tokens: Tokens) => ({
  type: types.SET_TOKENS,
  payload: tokens,
});

export const replaceTokens = (token: string, refreshToken: string, tokenExpiryDate: string) => ({
  type: types.REPLACE_TOKENS,
  payload: { token, refreshToken, tokenExpiryDate },
});
