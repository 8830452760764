import moment from 'moment';
import toast from 'react-hot-toast';
import classnames from 'classnames';
import { IconX } from '@tabler/icons-react';
import { MouseEventHandler, useCallback, useMemo, useState } from 'react';
import { GeneralNotification } from 'app/types';
import { useMarkAsReadMutation, useRemoveMutation } from 'app/services/generalNotifications/generalNotifications';
import styles from 'app/components/header/generalNotifications/notification/notification.module.scss';

interface Props {
  notification: GeneralNotification;
  refreshNotifications: () => void;
}

export const Notification = ({
  notification: { actionUrl, description, expireDate, generalNotificationId, isRead, readAt, timeOfCreation, title },
  refreshNotifications,
}: Props) => {
  const [loading, setLoading] = useState(false);
  const isExpired = useMemo(() => expireDate && moment(expireDate).isSameOrBefore(moment()), [expireDate]);
  const [markAsRead] = useMarkAsReadMutation();
  const [remove] = useRemoveMutation();

  const handleOnClick = useCallback(async () => {
    if (actionUrl && !isExpired) {
      // Open notification link
      window.open(actionUrl, '_blank', 'noopener,noreferrer')?.focus();
    }

    if (!isRead) {
      // Mark notification as read
      setLoading(true);
      try {
        await markAsRead(generalNotificationId).unwrap();
        await refreshNotifications();
      } catch (error) {
        toast.error('CORE.TEXT.GENERAL-ERROR');
      }
      setLoading(false);
    }
  }, [actionUrl, generalNotificationId, isExpired, isRead, markAsRead, refreshNotifications]);

  const handleRemove: MouseEventHandler<HTMLDivElement> = useCallback(
    async (event) => {
      event.stopPropagation();
      setLoading(true);
      try {
        await remove(generalNotificationId).unwrap();
        await refreshNotifications();
      } catch (error) {
        toast.error('CORE.TEXT.GENERAL-ERROR');
      }
      setLoading(false);
    },
    [generalNotificationId, refreshNotifications, remove],
  );

  return (
    <div
      className={classnames(styles.notification, {
        [styles.clickable]: !isRead || (!!actionUrl && !isExpired),
        [styles.disabled]: loading,
      })}
      onClick={handleOnClick}
    >
      <div className="me-2">
        <div
          className={classnames(styles.status, {
            [styles.unread]: !readAt,
          })}
        />
      </div>
      <div>
        <h6 className={styles.title}>
          {title} <span className={styles.date}>({moment(timeOfCreation).fromNow()})</span>
        </h6>
        {description && <p className={styles.description}>{description}</p>}
      </div>
      <div className={classnames('closeAction', styles.close)} onClick={handleRemove}>
        <IconX size={14} />
      </div>
    </div>
  );
};
