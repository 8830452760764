import { getApiDate, useAppSelector } from 'app/helpers';
import { PrintHeaderPrescriptionData } from 'app/assessment/prescriptionSection/components/prescriptionPrintTemplate/prescriptionPrintHeader';
import { TreatmentInformation } from 'app/types';

export const usePrescriptionData = (): PrintHeaderPrescriptionData | null => {
  const generalInformation: TreatmentInformation | null = useAppSelector(
    (state) => state.treatment.generalTreatmentInformation.data,
  );
  const completedAt = useAppSelector((state) => state.doctorAssessment.data?.completedAt);
  const clinicLogo = useAppSelector((state) => state.doctorAssessment.clinicLogo);
  const signatureUrl = useAppSelector((state) => state.prescription.printView?.doctor.signature.accessPath);

  if (!generalInformation) {
    return null;
  }

  const { doctorName, doctorTitle, specialityName: speciality } = generalInformation.appointmentInformation;
  const { firstName, fatherName, gender, age, internalFileNumber } = generalInformation.patientInformation;
  const {
    clinicId,
    clinicBranchNames: branchName,
    clinicBranchAddress,
    clinicBranchPhoneNumber,
  } = generalInformation.clinicInformation;
  const date = completedAt && getApiDate(completedAt);
  const patientName = `${firstName} ${fatherName}`;

  return {
    branchName,
    clinicBranchAddress,
    clinicBranchPhoneNumber,
    doctorFullName: `${doctorTitle} ${doctorName}`,
    speciality,
    internalFileNumber,
    patientName,
    age,
    gender,
    clinicId,
    clinicLogo,
    date,
    signatureUrl,
  };
};
