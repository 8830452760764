export const FETCH_DOCTORS_REQUEST = 'bookAppointment/FETCH_DOCTORS_REQUEST';
export const FETCH_DOCTORS_SUCCESS = 'bookAppointment/FETCH_DOCTORS_SUCCESS';
export const FETCH_DOCTORS_FAILURE = 'bookAppointment/FETCH_DOCTORS_FAILURE';

export const FETCH_DOCTOR_REQUEST = 'bookAppointment/FETCH_DOCTOR_REQUEST';
export const FETCH_DOCTOR_SUCCESS = 'bookAppointment/FETCH_DOCTOR_SUCCESS';
export const FETCH_DOCTOR_FAILURE = 'bookAppointment/FETCH_DOCTOR_FAILURE';

export const FETCH_BRANCHES_REQUEST = 'bookAppointment/FETCH_BRANCHES_REQUEST';
export const FETCH_BRANCHES_SUCCESS = 'bookAppointment/FETCH_BRANCHES_SUCCESS';
export const FETCH_BRANCHES_FAILURE = 'bookAppointment/FETCH_BRANCHES_FAILURE';

export const FETCH_SPECIALITIES_REQUEST = 'bookAppointment/FETCH_SPECIALITIES_REQUEST';
export const FETCH_SPECIALITIES_SUCCESS = 'bookAppointment/FETCH_SPECIALITIES_SUCCESS';
export const FETCH_SPECIALITIES_FAILURE = 'bookAppointment/FETCH_SPECIALITIES_FAILURE';

export const FETCH_SUB_SPECIALITIES_REQUEST = 'bookAppointment/FETCH_SUB_SPECIALITIES_REQUEST';
export const FETCH_SUB_SPECIALITIES_SUCCESS = 'bookAppointment/FETCH_SUB_SPECIALITIES_SUCCESS';
export const FETCH_SUB_SPECIALITIES_FAILURE = 'bookAppointment/FETCH_SUB_SPECIALITIES_FAILURE';

export const FETCH_PRODUCTS_REQUEST = 'bookAppointment/FETCH_PRODUCTS_REQUEST';
export const FETCH_PRODUCTS_SUCCESS = 'bookAppointment/FETCH_PRODUCTS_SUCCESS';
export const FETCH_PRODUCTS_FAILURE = 'bookAppointment/FETCH_PRODUCTS_FAILURE';

export const RESET = 'bookAppointment/RESET';
export const RESET_APPOINTMENT_REGISTER_PATIENT = 'bookAppointment/RESET_APPOINTMENT_REGISTER_PATIENT';
export const RESET_PATIENT = 'bookAppointment/RESET_PATIENT';

export const SET_APPOINTMENT_REGISTER_PATIENT = 'bookAppointment/SET_APPOINTMENT_REGISTER_PATIENT';
export const SET_PATIENT = 'bookAppointment/SET_PATIENT';
export const SET_PATIENT_DEPENDANTS = 'bookAppointment/SET_PATIENT_DEPENDANTS';
export const SET_ADD_DEPENDANT_MODE = 'bookAppointment/SET_ADD_DEPENDANT_MODE';

export const SELECT_SLOT = 'bookAppointment/SELECT_SLOT';
export const SELECT_BRANCH = 'bookAppointment/SELECT_BRANCH';
export const SELECT_SPECIALITY = 'bookAppointment/SELECT_SPECIALITY';
export const SELECT_DOCTOR = 'bookAppointment/SELECT_DOCTOR';
export const SELECT_SUB_SPECIALITY = 'bookAppointment/SELECT_SUB_SPECIALITY';
export const SELECT_SERVICE = 'bookAppointment/SELECT_SERVICE';
export const SET_REFERRAL_INFO = 'bookAppointment/SET_REFERRAL_INFO';
