import React, { useCallback, useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { reset, submit, change } from 'redux-form';
import { useLocation } from 'react-router-dom';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader, Spinner } from 'reactstrap';
import classnames from 'classnames';
import {
  AddWaitingAppointmentModal,
  EditAppointmentModal,
  EditWaitingAppointmentModal,
  PromoteWaitingAppointmentModal,
} from 'app/appointment/modals';
import { BookAppointmentWizard } from './bookAppointmentWizard/bookAppointmentWizard';
import { ManageAppointmentsModal, ManageWaitingAppointmentsModal } from 'app/calendar/modals';
import { StepTrigger, StepType } from './types';
import { formConfig } from './shared';
import {
  getPreviousStep,
  isBackAvailable,
  useStepsTriggers,
  useNavigateToCalendarAction,
  useBookAppointmentHandler,
  useFetchBranches,
  useCanBookAppointment,
} from './helpers';
import { resetBookAppointmentData, selectSlot, setPatient } from 'app/redux/book-appointment/book-appointment.actions';
import { resetCalendar } from 'app/redux/calendar/calendar.actions';
import { resetPreselectedPatient } from 'app/redux/appointment/appointment.actions';
import { toggleModal } from 'app/redux/modals/modals.actions';
import { useAppDispatch, useAppIntl, useAppSelector, useCurrentUser } from 'app/helpers';
import { FORMS_BOOK_APPOINTMENT } from 'app/shared';
import { PatientSearchField } from 'app/shared/patientSearch/types';
import { SelectedSlot } from 'app/redux/calendar/types';
import './bookApoointment.scss';

export const BookAppointment = () => {
  const dispatch = useAppDispatch();
  const { formatMessage } = useAppIntl();
  const { isDoctor } = useCurrentUser();
  const { pathname } = useLocation();
  const [currentStep, setCurrentStep] = useState<StepType>(isDoctor ? StepType.SubSpeciality : StepType.Speciality);

  const isOpen = useAppSelector((state) => state.modal.bookAppointment.isOpen);
  const submitting = useAppSelector((state) => state.form?.[formConfig.form]?.submitting);

  const isPreselectedPatient = useAppSelector((state) => !!state.appointment.preselectedPatient);
  const fullEditActivated = useAppSelector((state) => state.appointment.fullEdit.isActive);
  const referralInfo = useAppSelector((state) => state.bookAppointment.referralInfo);

  const modalBodyIndex = 2;
  const modalFooterIndex = modalBodyIndex - 1;
  const canBookAppointment = useCanBookAppointment();

  // Actions
  const handleFetchBranches = useFetchBranches(referralInfo);
  const handleBookAppointment = useBookAppointmentHandler();
  const onNavigateToCalendar = useNavigateToCalendarAction();

  const changeStep = useCallback(
    (step: StepType) => {
      setCurrentStep(step);
    },
    [setCurrentStep],
  );

  const setPreviousStep = useCallback(() => {
    setCurrentStep((step) => getPreviousStep(step));
  }, [setCurrentStep]);

  const resetState = useCallback(() => {
    dispatch(resetBookAppointmentData());
    if (!fullEditActivated) {
      dispatch(resetCalendar());
    }
    dispatch(reset(formConfig.form));
    setCurrentStep(isDoctor ? StepType.SubSpeciality : StepType.Speciality);
  }, [dispatch, fullEditActivated, isDoctor, setCurrentStep]);

  const toggle = useCallback(() => {
    dispatch(resetPreselectedPatient());
    dispatch(toggleModal('bookAppointment'));
  }, [dispatch]);

  const submitForm = useCallback(() => {
    dispatch(submit(FORMS_BOOK_APPOINTMENT));
  }, [dispatch]);

  const handleAddPhantom = useCallback(
    (slot: SelectedSlot) => {
      dispatch(selectSlot(slot));
      setCurrentStep(StepType.Patient);
    },
    [dispatch, setCurrentStep],
  );

  const handleModalOpen = useCallback(() => {
    handleFetchBranches();

    if (!!referralInfo) {
      dispatch(setPatient(referralInfo.patient));
      dispatch(change(formConfig.form, PatientSearchField.patientId, referralInfo.patient.id));
      dispatch(change(formConfig.form, PatientSearchField.selectedGuardian, referralInfo.guardian));
      dispatch(change(formConfig.form, PatientSearchField.selectedPhoneNumber, referralInfo.patientMobileNumber));
    }
  }, [dispatch, handleFetchBranches, referralInfo]);

  // Steps
  const steps: StepTrigger[] = useStepsTriggers({ changeStep, currentStep });

  // Effects
  useEffect(() => {
    dispatch(toggleModal('bookAppointment', false));
  }, [dispatch, pathname]);

  return (
    <Modal
      backdrop="static"
      keyboard={false}
      size="xl"
      isOpen={isOpen}
      toggle={toggle}
      onClosed={resetState}
      onOpened={handleModalOpen}
    >
      <ModalHeader toggle={toggle}>
        <div className="d-flex flex-column flex-md-row align-items-md-center w-100">
          <div className="d-flex flex-row">
            {steps.map((step: StepTrigger, index) => (
              <Button
                key={`stepTrigger-${index}`}
                className={classnames('bookAppointment__step my-1', { 'me-3': index < steps.length - 1 })}
                color={step.color}
                disabled={step.disabled}
                outline={step.outline}
                onClick={step.onClick}
              >
                {step.label}
              </Button>
            ))}
          </div>
          {isPreselectedPatient && (
            <Button onClick={onNavigateToCalendar} color="primary" className="ms-md-3 mt-1 mt-md-0" outline>
              {formatMessage({ id: 'CORE.LABEL.GO-TO-CALENDAR', defaultMessage: 'Go To Calendar' })}
            </Button>
          )}
        </div>
      </ModalHeader>

      <ModalBody style={{ zIndex: modalBodyIndex }}>
        <BookAppointmentWizard
          currentStep={currentStep}
          setCurrentStep={setCurrentStep}
          handleFetchBranches={handleFetchBranches}
          onSubmit={handleBookAppointment}
        />

        <AddWaitingAppointmentModal />
        <EditAppointmentModal />
        <EditWaitingAppointmentModal />
        <ManageAppointmentsModal onAddPhantom={handleAddPhantom} />
        <ManageWaitingAppointmentsModal />
        <PromoteWaitingAppointmentModal />
      </ModalBody>

      <ModalFooter style={{ justifyContent: 'space-between', zIndex: modalFooterIndex }}>
        {isBackAvailable(currentStep, isDoctor) && (
          <Button type="button" color="primary" onClick={setPreviousStep} outline>
            <FormattedMessage id="CORE.BUTTON.BACK" />
          </Button>
        )}
        <div />
        {currentStep === StepType.Patient && (
          <Button type="button" color="primary" disabled={!canBookAppointment || submitting} onClick={submitForm}>
            {formatMessage({ id: 'APPOINTMENTS.BUTTON.BOOK-APPOINTMENT' })}
            {submitting && <Spinner size="sm" className="buttonIcon buttonIcon--right" />}
          </Button>
        )}
      </ModalFooter>
    </Modal>
  );
};
