import { refreshCalendarDayView, refreshCalendarWeekView } from 'app/sagas/calendar/generators';
import { refreshAppointmentList } from 'app/sagas/apppointment/generators/refreshAppointmentList';
import { refreshPatientsArrival } from 'app/sagas/patientsArrival/generators/refreshPatientsArrival';

export function* afterCheckInChange() {
  // Refresh Appointment List
  yield refreshAppointmentList();

  // Refresh Calendar
  yield refreshCalendarDayView();
  yield refreshCalendarWeekView();

  // Patients Arrival
  yield refreshPatientsArrival();
}
