import React, { useCallback, useMemo } from 'react';
import { IconSquare, IconSquareCheck } from '@tabler/icons-react';
import { ColumnDescription, ColumnFormatter } from 'react-bootstrap-table-next';
import { useAppIntl } from 'app/helpers';
import { Note, NoteStatus } from 'app/components/locationSelector/procedureLocation/types';

const bodyStyle = { fontSize: '9px', color: 'black' };
const headerStyle = { fontSize: '10px', color: 'black' };

export const useLocationsColumns = () => {
  const { formatMessage } = useAppIntl();

  // Formatters
  const statusFormatter = useCallback<ColumnFormatter<Note>>((_, row) => {
    return row.status === NoteStatus.Open ? (
      <IconSquare size={20} strokeWidth={1.8} />
    ) : (
      <IconSquareCheck size={20} strokeWidth={1.8} />
    );
  }, []);

  return useMemo<ColumnDescription<Note>[]>(
    () => [
      {
        dataField: 'number',
        text: '#',
        formatter: (_, row, rowIndex) => rowIndex + 1,
        style: bodyStyle,
        headerStyle,
      },
      {
        dataField: 'status',
        formatter: statusFormatter,
        text: formatMessage({ id: 'CORE.LABEL.STATUS' }),
        classes: 'text-center',
        headerClasses: 'text-center',
        style: bodyStyle,
        headerStyle,
      },
      {
        dataField: 'title',
        text: formatMessage({ id: 'CORE.LABEL.TITLE' }),
        formatter: (_, row) => row.title,
        style: bodyStyle,
        headerStyle,
      },
      {
        dataField: 'description',
        text: formatMessage({ id: 'CORE.TABLE.DESCRIPTION' }),
        formatter: (_, row) => row.description,
        style: bodyStyle,
        headerStyle,
      },
      {
        dataField: 'element',
        text: formatMessage({ id: 'CORE.LABEL.TOOTH-NUMBER' }),
        formatter: (_, row) => row.element || '-',
        style: bodyStyle,
        headerStyle,
      },
    ],
    [formatMessage, statusFormatter],
  );
};
