import toast from 'react-hot-toast';
import { reset } from 'redux-form';
import { put, select } from 'redux-saga/effects';
import { PayloadAction } from '@reduxjs/toolkit';
import { FORMS_BOOK_APPOINTMENT, FORMS_BOOKING_WIZARD } from 'app/shared';
import { Appointment, RemoteData, RootState } from 'app/types';
import { isCurrentReferralsPage } from 'app/sagas/apppointment/helpers';
import { toggleModal } from 'app/redux/modals/modals.actions';
import { afterRebook } from 'app/sagas/apppointment/generators/afterRebook';
import { resetPreselectedPatient } from 'app/redux/appointment/appointment.actions';
import { refreshManageWaiting } from 'app/sagas/apppointment/generators/refreshManageWaiting';
import { refreshCalendarDayView, refreshCalendarWeekView } from 'app/sagas/calendar/generators';
import { refreshAppointmentList } from 'app/sagas/apppointment/generators/refreshAppointmentList';
import { setAppointmentReferralInfo } from 'app/redux/book-appointment/book-appointment.actions';
import { refreshCurrentReferrals } from 'app/sagas/apppointment/generators/refreshCurrentReferrals';
import { refreshManageAppointments } from 'app/sagas/apppointment/generators/refreshManageAppointments';
import { ADD_APPOINTMENT_SUCCESS, ADD_WAITING_APPOINTMENT_SUCCESS } from 'app/redux/appointment/appointment.types';

export function* afterAddAppointment({ type, payload }: PayloadAction<RemoteData<Appointment>>) {
  const {
    appointment: {
      rebook: { data: rebookData },
    },
    modal: {
      bookAppointment: { isOpen },
      bookingWizard: { isOpen: isBookingWizardOpen },
    },
    router: {
      location: { pathname },
    },
  }: RootState = yield select();

  // Confirmation
  if (type === ADD_APPOINTMENT_SUCCESS && !!payload.data.start) {
    // Custom confirmation for Appointments and Phantom Appointments
    yield put(toggleModal('appointmentConfirmation', true));
  } else {
    yield toast.success('APPOINTMENTS.TEXT.ADD-SUCCESS');
  }

  // Reset preselected patient data
  yield put(resetPreselectedPatient());

  // Appointment
  if (type === ADD_APPOINTMENT_SUCCESS) {
    yield refreshAppointmentList();
    yield refreshManageAppointments();
    yield put(toggleModal('addAppointment', false));

    // Check if user is in rebook mode
    if (rebookData) {
      yield afterRebook();
    }
    if (isOpen) {
      yield put(reset(FORMS_BOOK_APPOINTMENT));
      yield put(toggleModal('bookAppointment', false));
    }
    if (isBookingWizardOpen) {
      yield put(reset(FORMS_BOOKING_WIZARD));
      yield put(toggleModal('bookingWizard', false));
    }
    if (isCurrentReferralsPage(pathname)) {
      yield refreshCurrentReferrals();
    }
  }

  // Waiting Appointment
  if (type === ADD_WAITING_APPOINTMENT_SUCCESS) {
    yield refreshAppointmentList();
    yield refreshManageWaiting();
    yield put(toggleModal('addWaitingAppointment', false));

    if (isBookingWizardOpen) {
      yield put(reset(FORMS_BOOKING_WIZARD));
      yield put(toggleModal('bookingWizard', false));
    }

    if (isOpen) {
      yield put(setAppointmentReferralInfo(null));
      yield put(reset(FORMS_BOOK_APPOINTMENT));
      yield put(toggleModal('bookAppointment', false));
    }

    if (isCurrentReferralsPage(pathname)) {
      yield refreshCurrentReferrals();
    }
  }

  // Refresh Calendar Day and Week View
  yield refreshCalendarDayView();
  yield refreshCalendarWeekView();
}
