import { createAction, RSAA } from 'redux-api-middleware';
import { DICTIONARIES_LIMIT } from 'app/shared';
import { generateAppointmentsEndpoint } from 'app/helpers';
import {
  AppointmentInvoice,
  AppointmentTimeType,
  AppointmentType,
  AvailablePackage,
  QueryResponseWithPagination,
  RemoteDataParams,
} from 'app/types';
import {
  DashboardParams,
  DayCalendarsParams,
  InvoicesParams,
  NotificationLogParams,
  PerformersBillingItemsParams,
  RemoteDataActionConfiguration,
  RemoteDataEntityName,
  UserLogParams,
  WeekCalendarParams,
} from 'app/redux/remoteData/types';
import { CalendarDayParams } from 'app/redux/calendar/types';
import { RESET_DATA } from 'app/redux/remoteData/remoteData.types';
import { generateTypes, paramsAdapter } from 'app/redux/remoteData/helpers';

export const fetchBranchBillingItems = (branchId: string, params: RemoteDataParams) => ({
  [RSAA]: {
    endpoint: `/branches/${branchId}/branch-billing-items`,
    method: 'GET',
    types: generateTypes('branchBillingItems', params),
    params: paramsAdapter(params),
  },
});
export const fetchBranchBillingPackages = (branchId: string, params: RemoteDataParams, isSilent?: boolean) => ({
  [RSAA]: {
    endpoint: `/branches/${branchId}/branch-billing-packages`,
    method: 'GET',
    types: generateTypes('branchBillingPackages', params, { isSilent }),
    params: paramsAdapter(params),
  },
});
export const fetchPractitionersBillingPackages = (
  branchId: string,
  performerId: string,
  params: RemoteDataParams,
  isSilent?: boolean,
) => ({
  [RSAA]: {
    endpoint: `/branch/${branchId}/performers/${performerId}/performer-billing-packages`,
    method: 'GET',
    types: generateTypes('practitionerPackages', params, { isSilent }),
    params: paramsAdapter(params),
  },
});
export const fetchPractitionersBillingPackagesCollapse = (branchId: string, params: RemoteDataParams) => ({
  [RSAA]: {
    endpoint: `/branch/${branchId}/packages/performers`,
    method: 'GET',
    types: generateTypes('practitionersBillingPackages', params),
    params: paramsAdapter(params),
  },
});

export const fetchBranchBillingMedications = (branchId: string, params: RemoteDataParams, isSilent?: boolean) => ({
  [RSAA]: {
    endpoint: `/branches/${branchId}/branch-billing-medications`,
    method: 'GET',
    types: generateTypes('branchBillingMedication', params, { isSilent }),
    params: paramsAdapter(params),
  },
});

export const fetchPerformerBillingMedications = (
  branchId: string,
  performerId: string,
  params: RemoteDataParams,
  isSilent?: boolean,
) => ({
  [RSAA]: {
    endpoint: `/branches/${branchId}/performers/${performerId}/performer-billing-medications`,
    method: 'GET',
    types: generateTypes('performerBillingMedications', params, { isSilent }),
    params: paramsAdapter(params),
  },
});

export const fetchBranchBillingItemsSilent = (branchId: string, params: RemoteDataParams) => ({
  [RSAA]: {
    endpoint: `/branches/${branchId}/branch-billing-items`,
    method: 'GET',
    types: generateTypes('branchBillingItems', params, { isSilent: true }),
    params: paramsAdapter(params),
  },
});

export const fetchBillingItemsPerformers = (
  branchId: string,
  params: PerformersBillingItemsParams,
  isSilent?: boolean,
) => ({
  [RSAA]: {
    endpoint: `/branch/${branchId}/billing-items/performers`,
    method: 'GET',
    types: generateTypes('billingItemsPerformers', params, { isSilent }),
    params,
  },
});

export const fetchPerformerBillingMedicationsPerformers = (
  branchId: string,
  params: PerformersBillingItemsParams,
  isSilent?: boolean,
) => ({
  [RSAA]: {
    endpoint: `/branch/${branchId}/billing-medications/performers`,
    method: 'GET',
    types: generateTypes('billingMedicationsPerformers', params, { isSilent }),
    params,
  },
});

export const fetchPerformerBillingItems = (
  branchId: string,
  performerId: string,
  params: PerformersBillingItemsParams,
  isSilent?: boolean,
) => ({
  [RSAA]: {
    endpoint: `/branch/${branchId}/performers/${performerId}/performer-billing-items`,
    method: 'GET',
    types: generateTypes('performerBillingItems', params, { isSilent }),
    params: paramsAdapter(params),
  },
});

export const fetchCurrentAppointments = (
  params: RemoteDataParams,
  endpoint?: string,
  configuration?: RemoteDataActionConfiguration,
) => {
  const defaultEndpoint = generateAppointmentsEndpoint(
    undefined,
    AppointmentType.Standard,
    AppointmentTimeType.Current,
  );

  return {
    [RSAA]: {
      endpoint: endpoint || defaultEndpoint,
      method: 'GET',
      types: generateTypes('currentAppointments', params, configuration),
      params: paramsAdapter(params),
    },
  };
};

export const fetchUpcomingAppointments = (
  params: RemoteDataParams,
  endpoint?: string,
  configuration?: RemoteDataActionConfiguration,
) => {
  const defaultEndpoint = generateAppointmentsEndpoint(
    undefined,
    AppointmentType.Standard,
    AppointmentTimeType.Upcoming,
  );

  return {
    [RSAA]: {
      endpoint: endpoint || defaultEndpoint,
      method: 'GET',
      types: generateTypes('upcomingAppointments', params, configuration),
      params: paramsAdapter(params),
    },
  };
};

export const fetchPassedAppointments = (
  params: RemoteDataParams,
  endpoint?: string,
  configuration?: RemoteDataActionConfiguration,
) => {
  const defaultEndpoint = generateAppointmentsEndpoint(undefined, AppointmentType.Standard, AppointmentTimeType.Past);

  return {
    [RSAA]: {
      endpoint: endpoint || defaultEndpoint,
      method: 'GET',
      types: generateTypes('passedAppointments', params, configuration),
      params: paramsAdapter(params),
    },
  };
};

export const fetchWaitingUpcomingAppointments = (
  params: RemoteDataParams,
  endpoint?: string,
  configuration?: RemoteDataActionConfiguration,
) => {
  const defaultEndpoint = generateAppointmentsEndpoint(
    undefined,
    AppointmentType.Waiting,
    AppointmentTimeType.Upcoming,
  );

  return {
    [RSAA]: {
      endpoint: endpoint || defaultEndpoint,
      method: 'GET',
      types: generateTypes('waitingUpcomingAppointments', params, configuration),
      params: paramsAdapter(params),
    },
  };
};

export const fetchWaitingPassedAppointments = (
  params: RemoteDataParams,
  endpoint?: string,
  configuration?: RemoteDataActionConfiguration,
) => {
  const defaultEndpoint = generateAppointmentsEndpoint(undefined, AppointmentType.Waiting, AppointmentTimeType.Past);

  return {
    [RSAA]: {
      endpoint: endpoint || defaultEndpoint,
      method: 'GET',
      types: generateTypes('waitingPassedAppointments', params, configuration),
      params: paramsAdapter(params),
    },
  };
};

export const fetchAppointmentActions = (id: string, isWaiting: boolean, params: RemoteDataParams) => ({
  [RSAA]: {
    endpoint: `/${isWaiting ? 'waiting-appointments' : 'appointments'}/${id}/actions`,
    method: 'GET',
    types: generateTypes('appointmentActions', params),
    params: paramsAdapter(params),
  },
});

export const fetchBookedAppointments = (doctorId: string, params: RemoteDataParams, isSilent?: boolean) => ({
  [RSAA]: {
    endpoint: `/appointments/doctor/${doctorId}/in-slot`,
    method: 'GET',
    types: generateTypes('bookedAppointments', params, { isSilent }),
    params: paramsAdapter(params),
  },
});

export const fetchBookedWaitingAppointments = (
  params: RemoteDataParams,
  calendarDayParams: CalendarDayParams,
  isSilent?: boolean,
) => ({
  [RSAA]: {
    endpoint: '/waiting-appointments/doctor/manage',
    method: 'POST',
    types: generateTypes('bookedWaitingAppointments', params, { isSilent }),
    body: calendarDayParams,
    params: paramsAdapter(params),
  },
});

export const fetchBranchServices = (branchId: string, params: RemoteDataParams, isSilent?: boolean) => ({
  [RSAA]: {
    endpoint: `/branches/${branchId}/products`,
    method: 'GET',
    types: generateTypes('branchServices', params, { isSilent }),
    params: paramsAdapter(params),
  },
});

export const fetchConflictUpcomingAppointments = (params: RemoteDataParams) => ({
  [RSAA]: {
    endpoint: '/conflicting-appointments',
    method: 'GET',
    types: generateTypes('conflictUpcomingAppointments', params),
    params: paramsAdapter(params),
  },
});

export const fetchConflictWaitingUpcomingAppointments = (params: RemoteDataParams) => ({
  [RSAA]: {
    endpoint: '/conflicting-waiting-appointments',
    method: 'GET',
    types: generateTypes('conflictWaitingUpcomingAppointments', params),
    params: paramsAdapter(params),
  },
});

export const fetchUserLogs = (userId: string, params: UserLogParams) => ({
  [RSAA]: {
    endpoint: `/users/${userId}/logs`,
    method: 'GET',
    types: generateTypes('userLogs', params),
    params: paramsAdapter(params),
  },
});

export const fetchCurrentLogs = (params: NotificationLogParams) => ({
  [RSAA]: {
    endpoint: `/notifications/current-logs`,
    method: 'GET',
    types: generateTypes('currentLog', params),
    params: paramsAdapter(params),
  },
});

export const fetchArchivedLogs = (params: NotificationLogParams) => ({
  [RSAA]: {
    endpoint: `/notifications/archived-logs`,
    method: 'GET',
    types: generateTypes('archivedLog', params),
    params: paramsAdapter(params),
  },
});

export const fetchDoctors = (clinicId: string, params: RemoteDataParams) => ({
  [RSAA]: {
    endpoint: `/clinics/${clinicId}/doctors`,
    method: 'GET',
    types: generateTypes('doctors', params),
    params: paramsAdapter(params),
  },
});

export const fetchDayCalendars = (params: DayCalendarsParams, configuration?: RemoteDataActionConfiguration) => ({
  [RSAA]: {
    endpoint: `/day-calendars/branch/${params.branchId}`,
    method: 'POST',
    types: generateTypes('dayCalendars', params, configuration),
    body: paramsAdapter({
      ...params,
      branchId: undefined, // remove branchId
      subSpecialityId: params.subSpecialityId,
      serviceId: params.serviceId,
    }),
  },
});

export const fetchWeekCalendar = (params: WeekCalendarParams, configuration?: RemoteDataActionConfiguration) => ({
  [RSAA]: {
    endpoint: `/week-calendar/doctors/${params.doctorId}`,
    method: 'GET',
    types: generateTypes('weekCalendar', params, configuration),
    params: {
      ...params,
      doctorId: undefined, // remove doctorId
    },
  },
});

export const fetchAppointmentsPerformers = (
  params: DashboardParams,
  configuration?: RemoteDataActionConfiguration,
) => ({
  [RSAA]: {
    endpoint: `dashboard/performers-appointments`,
    method: 'GET',
    types: generateTypes('performersAppointments', params, configuration),
    params: paramsAdapter(params),
  },
});

export const fetchUserPerformance = (params: DashboardParams, configuration?: RemoteDataActionConfiguration) => ({
  [RSAA]: {
    endpoint: `dashboard/users-performance`,
    method: 'GET',
    types: generateTypes('usersPerformance', params, configuration),
    params: paramsAdapter(params),
  },
});

export const fetchGeneralNotifications = (
  userId: string,
  params: RemoteDataParams,
  configuration?: RemoteDataActionConfiguration,
) => ({
  [RSAA]: {
    endpoint: `/users/${userId}/generalnotifications`,
    method: 'GET',
    types: generateTypes('generalNotifications', params, configuration),
    params: paramsAdapter(params),
  },
});

export const fetchAllergies = (params: RemoteDataParams, patientId: string) => {
  return {
    [RSAA]: {
      endpoint: `/patients/${patientId}/patient-allergies`,
      method: 'GET',
      types: generateTypes('allergy', params),
      params: paramsAdapter(params),
    },
  };
};

export const fetchInvoiceLogs = (invoiceId: string, params: RemoteDataParams) =>
  createAction({
    endpoint: `/invoices/${invoiceId}/action-logs`,
    method: 'GET',
    types: generateTypes('invoiceLogs', params),
    // @ts-ignore custom params implementation
    params: paramsAdapter(params),
  });

export const fetchAvailableBranches = (params: InvoicesParams) => ({
  [RSAA]: {
    endpoint: `/branches`,
    params: {
      page: DICTIONARIES_LIMIT,
      statuses: params.statuses,
    },
    method: 'GET',
    types: generateTypes('branches', params),
  },
});

export const fetchScopedSpecialities = (params: InvoicesParams) => ({
  [RSAA]: {
    endpoint: `/user/scoped/specialities`,
    params: {
      page: DICTIONARIES_LIMIT,
      statuses: params.statuses,
    },
    method: 'GET',
    types: generateTypes('userScopedSpecialities', params),
  },
});

export const fetchInsuranceCompanies = (params: RemoteDataParams) => ({
  [RSAA]: {
    endpoint: '/insurance-companies',
    method: 'GET',
    types: generateTypes('insuranceCompanies', params),
    params: paramsAdapter(params),
  },
});

export const fetchAttachmentsLinkedToAppointment = (params: RemoteDataParams, appointmentId: string) => ({
  [RSAA]: {
    endpoint: `/appointments/${appointmentId}/attachments`,
    method: 'GET',
    types: generateTypes('appointmentAttachments', params),
    params: paramsAdapter(params),
  },
});

export const fetchAppointmentInvoices = (params: RemoteDataParams, appointmentId: string) =>
  createAction<any, AppointmentInvoice, undefined>({
    endpoint: `appointments/${appointmentId}/all-invoices`,
    method: 'GET',
    types: generateTypes('appointmentInvoices', params),
    // @ts-ignore custom params middleware
    params: paramsAdapter(params),
  });

export const fetchReferralActions = (params: RemoteDataParams, referralId: string) => ({
  [RSAA]: {
    endpoint: `/referrals/${referralId}/action-log`,
    method: 'GET',
    types: generateTypes('referralLogs', params),
    params: paramsAdapter(params),
  },
});

export const fetchWarnings = (params: RemoteDataParams, branchId: string, branchBillingItemId: string) => ({
  [RSAA]: {
    endpoint: `branches/${branchId}/branch-billing-items/${branchBillingItemId}/warnings`,
    method: 'GET',
    types: generateTypes('packageWarning', params),
    params: paramsAdapter(params),
  },
});

export const fetchAvailablePackages = (clinicPatientId: string, branchId: string, params: RemoteDataParams) =>
  createAction<any, QueryResponseWithPagination<AvailablePackage[]>, undefined>({
    endpoint: `/clinic-patients/${clinicPatientId}/branches/${branchId}/available-packages`,
    method: 'GET',
    types: generateTypes('availablePackages', params),
    // @ts-ignore custom params implementation
    params: paramsAdapter(params),
  });

export const reset = (name: RemoteDataEntityName) => ({
  type: RESET_DATA,
  meta: { name },
});
