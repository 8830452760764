import { fork } from 'redux-saga/effects';

import i18nSaga from './i18n/i18n.saga';
import authSaga from './auth/auth.saga';
import clinicSaga from './clinic/clinic.saga';
import billingItemSaga from 'app/sagas/billingItem/billingItem.saga';
import billingItemListsSaga from 'app/sagas/billingItemLists/billingItemLists.saga';
import branchesSaga from './branches/branches.saga';
import doctorsSaga from './doctors/doctors.saga';
import notificationsMessageSaga from './notifications-message/notifications-message.saga';
import appointmentSaga from './apppointment/appointment.saga';
import calendarSaga from './calendar/calendar.saga';
import patientSaga from './patient/patient.saga';
import userRolesSaga from './user-roles/user-roles.saga';
import requestsSaga from './requests/requests.saga';
import notificationSaga from './notifications/notification.saga';
import invoiceSaga from './invoice/invoice.saga';
import insurancesSaga from 'app/sagas/insurances/insurances.saga';
import commonSaga from './common/common.saga';
import conflictAppointmentSaga from './conflict-appointments/conflict-appointments.saga';
import patientsArrivalSaga from './patientsArrival/patientsArrival.saga';
import treatmentSaga from 'app/sagas/treatment/treatment.saga';
import assessmentSaga from 'app/sagas/assessment/assessment.saga';
import financialSaga from 'app/sagas/financial/financial.saga';
import invoicingProcessSaga from 'app/sagas/invoicingProcess/invoicingProcess.saga';

export default function* sagas() {
  yield fork(i18nSaga);
  yield fork(authSaga);
  yield fork(billingItemSaga);
  yield fork(billingItemListsSaga);
  yield fork(clinicSaga);
  yield fork(branchesSaga);
  yield fork(doctorsSaga);
  yield fork(notificationsMessageSaga);
  yield fork(appointmentSaga);
  yield fork(calendarSaga);
  yield fork(conflictAppointmentSaga);
  yield fork(patientSaga);
  yield fork(userRolesSaga);
  yield fork(requestsSaga);
  yield fork(notificationSaga);
  yield fork(invoiceSaga);
  yield fork(insurancesSaga);
  yield fork(patientsArrivalSaga);
  yield fork(treatmentSaga);
  yield fork(assessmentSaga);
  yield fork(financialSaga);
  yield fork(invoicingProcessSaga);
  //IMPORTANT! DON'T CHANGE generalNotificationsSaga AND commonSaga ORDER
  yield fork(commonSaga);
}
