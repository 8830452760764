import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { FormFeedback, FormGroup } from 'reactstrap';
import { WrappedFieldProps } from 'redux-form/lib/Field';
import { checkIsRtl, useAppIntl } from 'app/helpers';
import { Slider } from 'app/components';
import styles from 'app/shared/form-elements/valueSlider/valueSlider.module.scss';
import { CustomLabel } from 'app/components/customLabel/customLabel';

export interface ValueSliderProps {
  disabled?: boolean;
  id?: string;
  info?: string;
  isRequired?: boolean;
  label: string;
  margin?: number;
  max?: number;
  min?: number;
  step?: number;
}

const defaultMin = 0;
const defaultMax = 100;

export const ValueSlider = ({
  disabled,
  id,
  input: { onChange, value, name },
  info,
  isRequired,
  label,
  max = defaultMax,
  meta: { error, touched },
  min = defaultMin,
  step = 1,
  margin = 1,
}: WrappedFieldProps & ValueSliderProps) => {
  const { formatMessage, locale } = useAppIntl();
  const fieldId = id || name;
  const marginValue = margin || step;
  const direction = useMemo(() => (checkIsRtl(locale) ? 'rtl' : 'ltr'), [locale]);

  // Initial values
  const initialFrom = useMemo(() => value?.from || min, [value, min]);
  const initialTo = useMemo(() => value?.to || max, [value, max]);

  // Values to display
  const [from, setFrom] = useState<number>(initialFrom);
  const [to, setTo] = useState<number>(initialTo);

  // Helper Variable
  const invalid = !!error && touched;

  const handleOnChange = useCallback(
    (values) => {
      onChange({
        from: parseInt(values[0]),
        to: parseInt(values[1]),
      });
    },
    [onChange],
  );

  const handleOnSlide = useCallback((values: any[]) => {
    setFrom(parseInt(values[0]));
    setTo(parseInt(values[1]));
  }, []);

  useEffect(() => {
    setFrom(value?.from || min);
    setTo(value?.to || max);
  }, [min, max, value]);

  return (
    <FormGroup>
      <CustomLabel fieldId={fieldId} info={info} isRequired={isRequired} label={label} />
      <div className={styles.valueRangeWrapper}>
        <div className={styles.valueRangeValue}>{from}</div>
        <div className={styles.valueRangeSlider}>
          <Slider
            behaviour="tap-drag"
            connect
            direction={direction}
            disabled={disabled}
            margin={marginValue}
            onChange={handleOnChange}
            onSlide={handleOnSlide}
            range={{ min, max }}
            start={[initialFrom, initialTo]}
            step={step}
          />
        </div>
        <div className={styles.valueRangeValue}>{to}</div>
      </div>
      {invalid && <FormFeedback className="d-block">{formatMessage({ id: error })}</FormFeedback>}
    </FormGroup>
  );
};
