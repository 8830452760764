import { ParsedQs } from 'qs';
import omitBy from 'lodash/omitBy';
import isEmpty from 'lodash/isEmpty';
import { QueryParams } from 'app/types';
import type { Field } from 'app/helpers/useInitialValuesFromUrlParams/useInitialValuesFromUrlParams';
import {
  getCoreQueryParams,
  getQueryParamsFromOption,
  getQueryParamsFromOptions,
  getQueryParamsFromTimeRange,
} from 'app/helpers/queryParams/queryParams';

export const getQueryParams = (fields: Field[], urlParams: ParsedQs): QueryParams =>
  omitBy(
    Object.assign(
      {
        ...getCoreQueryParams(urlParams),
      },
      ...fields.map((field) => {
        const name = field.name;
        const type = field.type;

        if (type === 'option') {
          return getQueryParamsFromOption(name, urlParams);
        }

        if (type === 'options') {
          return getQueryParamsFromOptions(name, urlParams);
        }

        if (type === 'timeRange') {
          return getQueryParamsFromTimeRange(name, urlParams);
        }

        // simple or date
        return {
          [name]: urlParams[name],
        };
      }),
    ),
    isEmpty,
  );
