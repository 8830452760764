import { RSAA, createAction } from 'redux-api-middleware';
import { DICTIONARIES_LIMIT } from 'app/shared';
import { RemoteData } from 'app/types';
import { RemoteInvoiceDataParams } from 'app/redux/invoice/types/RemoteInvoiceDataParams';
import * as types from './invoices.types';

export const fetchPatientInvoicesToPay = (patientId: string) => ({
  [RSAA]: {
    endpoint: `/invoices/to-pay/patients/${patientId}`,
    method: 'GET',
    types: [
      types.FETCH_PATIENT_INVOICES_TO_PAY_REQUEST,
      types.FETCH_PATIENT_INVOICES_TO_PAY_SUCCESS,
      types.FETCH_PATIENT_INVOICES_TO_PAY_FAILURE,
    ],
    params: {
      pageSize: DICTIONARIES_LIMIT,
    },
  },
});

export const setPatientInvoicesParams = (params: RemoteData) => ({
  type: types.SET_PATIENT_INVOICES_PARAMS,
  meta: params,
});

export const addPatientPayments = (body: RemoteData, patientId: string) => ({
  [RSAA]: {
    endpoint: `/invoices/patients/payments`,
    method: 'POST',
    types: [
      types.ADD_INVOICE_PAYMENTS_REQUEST,
      {
        type: types.ADD_INVOICE_PAYMENTS_SUCCESS,
        meta: {
          notification: {
            type: 'success',
            title: 'INVOICES.TEXT.PAYMENT-ADD-SUCCESS',
          },
          patientId,
        },
      },
      {
        type: types.ADD_INVOICE_PAYMENTS_FAILURE,
        meta: {
          notification: {
            type: 'danger',
            title: 'CORE.TEXT.DEFAULT-FAILURE-MESSAGE',
          },
          patientId,
        },
      },
    ],
    body,
  },
});

export const fetchInvoicesMove = (params: RemoteInvoiceDataParams, invoiceRequestId?: string) => ({
  [RSAA]: {
    endpoint: `/invoice-requests/${invoiceRequestId}/undiscard`,
    method: 'PUT',
    types: [
      types.PUT_INVOICES_MOVE_REQUEST,
      {
        type: types.PUT_INVOICES_MOVE_SUCCESS,
        meta: {
          notification: {
            type: 'success',
            title: 'INVOICES.TEXT.UPDATED-SUCCESS',
          },
        },
      },
      {
        type: types.PUT_INVOICES_MOVE_FAILURE,
        meta: {
          notification: {
            type: 'danger',
            title: 'CORE.TEXT.DEFAULT-FAILURE-MESSAGE',
          },
        },
      },
    ],
    body: params,
  },
});

export const fetchInvoicesDiscard = (params: RemoteInvoiceDataParams, invoiceRequestId?: string) => ({
  [RSAA]: {
    endpoint: `/invoice-requests/${invoiceRequestId}/discard`,
    method: 'PUT',
    types: [
      types.PUT_INVOICES_DISCARD_REQUEST,
      {
        type: types.PUT_INVOICES_DISCARD_SUCCESS,
        meta: {
          notification: {
            type: 'success',
            title: 'INVOICES.TEXT.UPDATED-SUCCESS',
          },
        },
      },
      {
        type: types.PUT_FINANCIAL_INVOICES_DISCARD_FAILURE,
        meta: {
          notification: {
            type: 'danger',
            title: 'CORE.TEXT.DEFAULT-FAILURE-MESSAGE',
          },
        },
      },
    ],
    body: params,
  },
});

export const fetchInvoicesCreate = (params: RemoteInvoiceDataParams, invoiceRequestId?: string) => ({
  [RSAA]: {
    endpoint: `/invoice-requests/${invoiceRequestId}`,
    method: 'POST',
    types: [
      types.ADD_INVOICES_REQUEST,
      {
        type: types.ADD_INVOICES_SUCCESS,
        meta: {
          notification: {
            type: 'success',
            title: 'INVOICES.TEXT.CREATE-SUCCESS',
          },
        },
      },
      {
        type: types.ADD_INVOICES_FAILURE,
        meta: {
          notification: {
            type: 'danger',
            title: 'CORE.TEXT.DEFAULT-FAILURE-MESSAGE',
          },
        },
      },
    ],
    body: params,
  },
});

export const exportInvoices = (body: Record<string, boolean | string | string[] | undefined>) =>
  createAction<any, void, unknown>({
    endpoint: 'invoices/excel',
    method: 'POST',
    types: [
      types.EXPORT_INVOICES_REQUEST,
      {
        type: types.EXPORT_INVOICES_SUCCESS,
        meta: {
          notification: {
            type: 'success',
            title: 'CORE.TEXT.EXPORT-IN-PROGRESS-CONFIRMATION',
          },
        },
      },
      {
        type: types.EXPORT_INVOICES_FAILURE,
        meta: {
          notification: {
            type: 'danger',
            title: 'CORE.TEXT.DEFAULT-FAILURE-MESSAGE',
          },
        },
      },
    ],
    // @ts-ignore custom body middleware
    body,
  });
