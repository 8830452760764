import { LANGUAGES_AR_CODE } from 'app/shared';

export const getPatientName = (patient, locale, prefix = 'full') => {
  if (!patient) {
    return '';
  }

  if (!prefix) {
    return locale === LANGUAGES_AR_CODE
      ? patient.nameAr || patient.nameEn || ''
      : patient.nameEn || patient.nameAr || '';
  }

  return locale === LANGUAGES_AR_CODE
    ? patient[`${prefix}NameAr`] || patient[`${prefix}NameEn`] || ''
    : patient[`${prefix}NameEn`] || patient[`${prefix}NameAr`] || '';
};
