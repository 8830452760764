import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { useAppIntl } from 'app/helpers';
import './breadcrumbs.scss';

export const Breadcrumb = ({ items }) => {
  const { formatMessage } = useAppIntl();

  const formattedItems = useMemo(() => {
    const homeItem = {
      href: '/',
      text: formatMessage({ id: 'CORE.LINK.HOME' }),
    };

    const formattedItems = items.map(({ customMessage, href, messageId }) => ({
      href,
      text: customMessage === undefined ? formatMessage({ id: messageId }) : customMessage,
    }));

    return [homeItem, ...formattedItems];
  }, [formatMessage, items]);

  return (
    <div className="custom-breadcrumb">
      {formattedItems.map((item, index) => {
        return (
          <div className="custom-breadcrumb__item" key={index}>
            {item.href ? <Link to={item.href}>{item.text}</Link> : <span>{item.text}</span>}
          </div>
        );
      })}
    </div>
  );
};

export const BreadcrumbItemsType = PropTypes.arrayOf(
  PropTypes.shape({
    customMessage: PropTypes.string,
    href: PropTypes.string,
    messageId: PropTypes.string,
  }),
);

Breadcrumb.propTypes = {
  items: BreadcrumbItemsType.isRequired,
};
