import { Option } from 'app/types';

export const PatientSearchField = {
  searchPhoneNumber: 'searchPartialPhoneNumber',
  searchClinicyMrn: 'searchClinicyMrn',
  searchInternalFileNumber: 'searchInternalFileNumber',
  searchGovernmentIssuedId: 'searchGovernmentIssuedId',
  selectedPhoneNumber: 'selectedPhoneNumber',
  selectedGuardian: 'selectedGuardian',
  patientId: 'patientId',
} as const;

export interface PatientSearchFormData {
  [PatientSearchField.searchPhoneNumber]: string;
  [PatientSearchField.searchClinicyMrn]: string;
  [PatientSearchField.searchInternalFileNumber]: string;
  [PatientSearchField.searchGovernmentIssuedId]: string;
  [PatientSearchField.patientId]: string;
  [PatientSearchField.selectedPhoneNumber]: Option;
  [PatientSearchField.selectedGuardian]?: Option;
}
