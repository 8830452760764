import { Action } from 'redux';
import { PerformersBillingItemsParams } from 'app/redux/remoteData/types';

export type NotificationType = 'success' | 'danger';

export const WS_REFRESH_PERFORMER_BILLING_ITEMS = 'saga/WS_REFRESH_PERFORMER_BILLING_ITEMS';

export type Meta = {
  branchId: string;
  notification: {
    title: string;
    type: NotificationType;
  };
};

export interface WsRefreshPayload {
  branchId: string;
  performerId: string;
  params: PerformersBillingItemsParams;
}

export interface WsRefreshAction extends Action<string> {
  payload: WsRefreshPayload;
}
