import { PayloadAction } from '@reduxjs/toolkit';
import { put, select } from 'redux-saga/effects';
import { RefreshBranchBillingItemsEvent } from 'app/billingItems/branchBillingItems/helpers/useLiveRefresh';
import { RootState } from 'app/types';
import { DEFAULT_PAGINATION_PARAMS } from 'app/shared';
import { WS_REFRESH_BILLING_ITEMS, WsRefreshAction } from 'app/sagas/billingItem/types';

export function* wsUpdateBillingItemsTable({ payload }: PayloadAction<RefreshBranchBillingItemsEvent>) {
  const {
    remoteData: {
      branchBillingItems: { params },
    },
  }: RootState = yield select();

  yield put<WsRefreshAction>({
    type: WS_REFRESH_BILLING_ITEMS,
    payload: {
      branchId: payload.BranchId,
      params: params || DEFAULT_PAGINATION_PARAMS,
    },
  });
}
