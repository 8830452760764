import orderBy from 'lodash/orderBy';
import { Patient, PatientPhoneNumberProps } from 'app/types';
import { PatientFormData } from 'app/features/patient/patientForm/types';

export const useInitialValues = (isClinic: boolean, data?: Patient): PatientFormData | undefined => {
  if (data) {
    return {
      firstNameAr: data.firstNameArabic,
      fatherNameAr: data.fatherNameArabic || null,
      grandFatherNameAr: data.grandFatherNameArabic || null,
      familyNameAr: data.familyNameArabic,
      preferredNameAr: data.aliasNameArabic || null,

      firstNameEn: data.firstNameEnglish,
      fatherNameEn: data.fatherNameEnglish || null,
      grandFatherNameEn: data.grandFatherNameEnglish || null,
      familyNameEn: data.familyNameEnglish,
      preferredNameEn: data.aliasNameEnglish || null,

      internalPatientFileNumber: isClinic ? data.internalFileNumber : undefined,
      gender: data.gender || null,
      dateOfBirth: data.dateOfBirth ? new Date(data.dateOfBirth) : null,
      nationality: data.nationality || null,
      typeOfGovernmentIssuedId: data.governmentIssuedIdType,
      governmentIssuedIdNumber: data.governmentIssuedId,
      city: data.city || null,
      preferredLanguage: data.preferredNotificationLanguage || null,
      maritalStatus: data.maritalStatus || null,
      otherMaritalStatus: data.otherMaritalStatus,
      occupation: data.occupation || null,
      patientClassification: data.patientCategory || null,
      email: data.email || null,

      emergencyContactName: data.emergencyContactName || null,
      emergencyContactNumber: data.emergencyContactNumber || null,
      emergencyContactRelation: data.emergencyContactRelation || null,
      otherEmergencyContactRelation: data.otherEmergencyContactRelation,

      sourceOfPatient: isClinic ? data.patientSource : undefined,
      generalNote: isClinic ? data.generalNote : undefined,

      patientMobileNumbers: orderBy(data.phoneNumbers, [PatientPhoneNumberProps.isDefault], ['desc']),

      primaryPatients: data.guardians?.map((guardian) => ({
        id: guardian.id,
        clinicyMrn: guardian.clinicyMrn,
        fullNameArabic: guardian.fullNameArabic,
        fullNameEnglish: guardian.fullNameEnglish,
        relation: guardian.relation,
        otherRelationText: guardian.otherRelationText,
      })),
    };
  }
};
