import React, { useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { ReactComponent as LoaderSvg } from 'assets/images/icons/loader.svg';
import './loader.scss';

const offsetAnimationEffect = (ref) => {
  let animation;
  const path = ref.current.querySelector('path');
  const startOffset = 300;
  const fps = 60;
  const duration = fps * 1.2;

  const offsetMe = (startOffset, offset, duration, direction) => {
    let animationDirection = direction;
    path.style.strokeDashoffset = offset;
    if (direction === 'down') {
      if (offset <= 0) animationDirection = 'up';
      offset = offset - startOffset / duration;
    }
    if (direction === 'up') {
      if (offset >= startOffset) animationDirection = 'down';
      offset = offset + startOffset / duration;
    }
    animation = requestAnimationFrame(() => offsetMe(startOffset, offset, duration, animationDirection));
  };

  animation = requestAnimationFrame(() => offsetMe(startOffset, startOffset, duration, 'down'));

  return () => cancelAnimationFrame(animation);
};

export const Loader = ({ className, style }) => {
  let loader = useRef();
  useEffect(() => offsetAnimationEffect(loader), []);

  return (
    <div className={classnames('loader-wrapper', className)} style={style}>
      <div className="loader">
        <LoaderSvg ref={loader} />
      </div>
    </div>
  );
};

Loader.propTypes = {
  className: PropTypes.string,
  style: PropTypes.object,
};

export const FullScreenLoader = () => {
  return (
    <div className="fullScreenLoader">
      <Loader />
    </div>
  );
};

FullScreenLoader.propTypes = {
  className: PropTypes.string,
  style: PropTypes.object,
};
