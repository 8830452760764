import React, { useMemo } from 'react';
import { ColumnDescription } from 'react-bootstrap-table-next';
import { IconX } from '@tabler/icons-react';
import { useAppIntl, useAppSelector } from 'app/helpers';
import { IconButton } from 'app/components';
import { ExcludedStatus } from 'app/assessment/billingSection/formatters/excludedStatus';
import { ICD10Code } from 'app/types';

export const useColumns = (removeField: (rowIndex: number) => void, isReadOnly?: boolean) => {
  const { formatMessage } = useAppIntl();

  // Selectors
  const patientInsuranceId = useAppSelector((state) => state.doctorAssessment.data?.billingSection.patientInsuranceId);

  return useMemo(
    (): ColumnDescription<ICD10Code>[] => [
      {
        dataField: 'icd10code',
        text: formatMessage({ id: 'TREATMENT-PLAN.LABEL.ICD10-CODE' }),
        formatter: (_, row) => row.icd10Code,
      },
      {
        dataField: 'description',
        text: formatMessage({ id: 'INVOICES.TEXT.ACTION-DESCRIPTION' }),
        formatter: (_, row) => row.description,
      },
      {
        dataField: 'isExcluded',
        text: formatMessage({ id: 'CORE.LABEL.EXCLUSION-STATUS' }),
        formatter: (_, row) => <ExcludedStatus isExcluded={row.isExcluded} />,
        classes: 'text-center',
        headerClasses: 'text-center',
        hidden: !patientInsuranceId,
      },
      {
        dataField: 'remove',
        text: '',
        formatter: (_, __, rowIndex) => (
          <IconButton
            icon={<IconX size={20} />}
            tooltipMessageId="CORE.BUTTON.REMOVE"
            onClick={() => removeField(rowIndex)}
          />
        ),
        classes: 'text-right',
        hidden: isReadOnly,
      },
    ],
    [formatMessage, patientInsuranceId, isReadOnly, removeField],
  );
};
