import { ReactNode, useMemo } from 'react';
import { checkAccess, useAppSelector } from 'app/helpers';
import { UserRoles } from 'app/types';

interface Props {
  children: ReactNode;
  roles: UserRoles[];
}

export const HiddenSection = ({ children, roles }: Props) => {
  const userRoles = useAppSelector((state) => state.auth.current.roles);
  const hasAccess = useMemo(() => checkAccess(roles, userRoles), [roles, userRoles]);

  if (hasAccess) {
    return null;
  } else {
    return <>{children}</>;
  }
};
