import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQueryWithReAuth } from 'app/helpers';
import {
  InsuranceContract,
  InsuranceContractEntityActionLog,
  InsuranceContractEntityAttachment,
  QueryParams,
  QueryResponse,
  QueryResponseWithPagination,
} from 'app/types';
import { EditContractorsAttachmentParams } from 'app/services/insuranceContractors/types';
import {
  ActivateContractParams,
  DeactivateContractParams,
  InsuranceContractorsContracts,
  RemoveContractParams,
  RetrieveContractParams,
} from 'app/services/insuranceContracts/types';

export const insuranceContractApi = createApi({
  reducerPath: 'insuranceContractApi',
  baseQuery: baseQueryWithReAuth,
  tagTypes: ['InsuranceContract'],
  endpoints: (builder) => ({
    getInsuranceContract: builder.query<QueryResponse<InsuranceContract>, string>({
      query: (contractId) => ({
        url: `insurance-contracts/${contractId}`,
        method: 'GET',
      }),
      providesTags: ['InsuranceContract'],
    }),

    getContractorInsuranceContract: builder.query<
      QueryResponseWithPagination<InsuranceContract[]>,
      InsuranceContractorsContracts
    >({
      query: ({ contractorId, removedAndExpired, params }) => ({
        url: `insurance-contractors/${contractorId}/insurance-contracts`,
        method: 'GET',
        params: { ...params, removedAndExpired },
      }),
      providesTags: ['InsuranceContract'],
    }),

    getInsuranceContractsAttachments: builder.query<
      QueryResponseWithPagination<InsuranceContractEntityAttachment[]>,
      string | undefined
    >({
      query: (contractId) => ({
        url: `insurance-contracts/${contractId}/attachments`,
      }),
      providesTags: (_, __, contractId) => [
        {
          type: 'InsuranceContract',
          id: contractId,
        },
      ],
    }),

    editContractAttachments: builder.mutation<void, { contractId: string; body: EditContractorsAttachmentParams }>({
      query: ({ contractId, body }) => ({
        url: `insurance-contracts/${contractId}/attachments`,
        method: 'PUT',
        body,
      }),
      invalidatesTags: (_, __, { contractId }) => [{ type: 'InsuranceContract', contractId }],
    }),

    activateContract: builder.mutation<void, ActivateContractParams>({
      query: (body) => ({
        url: `insurance-contracts/${body.contractId}/activate`,
        method: 'PUT',
      }),
      invalidatesTags: ['InsuranceContract'],
    }),

    deactivateContract: builder.mutation<void, DeactivateContractParams>({
      query: (body) => ({
        url: `insurance-contracts/${body.contractId}/deactivate`,
        method: 'PUT',
      }),
      invalidatesTags: ['InsuranceContract'],
    }),

    retrieveContract: builder.mutation<void, { contractId: string; body: RetrieveContractParams }>({
      query: ({ contractId, body }) => ({
        url: `insurance-contracts/${contractId}/retrieve`,
        method: 'PUT',
        body,
      }),
      invalidatesTags: ['InsuranceContract'],
    }),

    removeContract: builder.mutation<void, RemoveContractParams>({
      query: (body) => ({
        url: `insurance-contracts/${body.contractId}/remove`,
        method: 'DELETE',
      }),
      invalidatesTags: ['InsuranceContract'],
    }),

    getInsuranceContractsActionLogs: builder.query<
      QueryResponseWithPagination<InsuranceContractEntityActionLog[]>,
      QueryParams | undefined
    >({
      query: (params) => ({
        url: `insurance-contracts/action-logs`,
        params,
      }),
      providesTags: [{ type: 'InsuranceContract' }],
    }),

    getInsuranceContractBillingItems: builder.query<QueryResponseWithPagination<any[]>, { contractId: string }>({
      query: ({ contractId }) => ({
        url: `insurance-contract/${contractId}/branch-billing-items/group/statuses`,
      }),
      providesTags: [{ type: 'InsuranceContract' }],
    }),
  }),
});

export const {
  useGetInsuranceContractQuery,
  useEditContractAttachmentsMutation,
  useActivateContractMutation,
  useDeactivateContractMutation,
  useGetInsuranceContractsActionLogsQuery,
  useGetInsuranceContractsAttachmentsQuery,
  useRemoveContractMutation,
  useRetrieveContractMutation,
  useGetInsuranceContractBillingItemsQuery,
  useGetContractorInsuranceContractQuery,
} = insuranceContractApi;
