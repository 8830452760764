import { useCallback, ChangeEvent } from 'react';
import moment from 'moment';
import { change } from 'redux-form';
import { EventWithDataHandler } from 'redux-form/lib/Field';
import { FORMS_BOOKING_WIZARD, DEFAULT_PAGINATION_PARAMS } from 'app/shared';
import {
  isSlotFree,
  setActiveStep,
  fetchDay,
  fetchDoctorSubSpecialities,
  fetchDoctorProducts,
  selectTime,
  selectSubSpeciality,
} from 'app/redux/bookingWizard/bookWizard.actions';
import { getApiDateAndTime, getApiDate, useAppSelector, useAppDispatch } from 'app/helpers';
import { WizardStep, WorkingTime } from 'app/redux/bookingWizard/types';
import { fetchBookedAppointments } from 'app/redux/remoteData/remoteData.actions';
import { isTimePeriodValid } from 'app/bookingWizardModal/bookingWizard/helpers/isTimePeriodValid';
import { Option, RemoteDataParams, PatientSourceOption } from 'app/types';

interface Props {
  branchId: string;
  isWaiting?: boolean;
  patientSourceId?: string;
}

export const useBookingWizardActions = ({ branchId, isWaiting, patientSourceId }: Props) => {
  const dispatch = useAppDispatch();

  // Selectors
  const doctor = useAppSelector((state) => state.bookingWizard.doctor.data);
  const appointmentId = useAppSelector((state) => state.bookingWizard.appointmentId);
  const selectedSubSpeciality = useAppSelector((state) => state.bookingWizard.selectedSubSpeciality);
  const selectedWorkingTime = useAppSelector((state) => state.bookingWizard.selectedWorkingTime);
  const selectedTime = useAppSelector((state) => state.bookingWizard.selectedTime);
  const selectedService = useAppSelector((state) => state.bookingWizard.selectedService);

  // Helper Functions
  const checkSlotIsFree = useCallback(
    async (timeFrom: Date, timeTo: Date) => {
      if (!isWaiting && doctor && selectedWorkingTime && isTimePeriodValid(selectedWorkingTime, timeFrom, timeTo)) {
        const isSlotFreeResponse = await dispatch(
          isSlotFree({
            doctorId: doctor.id,
            start: getApiDateAndTime(timeFrom),
            end: getApiDateAndTime(timeTo),
            branchId: branchId,
            ignoredAppointmentId: appointmentId,
          }),
        );
        // @ts-ignore
        return !!isSlotFreeResponse?.payload;
      }
      return true;
    },
    [dispatch, appointmentId, branchId, isWaiting, doctor, selectedWorkingTime],
  );

  const onFetchDay = useCallback(
    (service: Option) => {
      if (doctor?.speciality && service && selectedSubSpeciality && selectedTime.from) {
        dispatch(
          fetchDay({
            branchId,
            date: getApiDate(selectedTime.from),
            doctorId: doctor.id,
            productRelatedFilters: {
              productId: service.value,
              specialityId: doctor.speciality.value,
              subSpecialityId: selectedSubSpeciality.value,
            },
            ignoredAppointmentIds: appointmentId ? [appointmentId] : undefined,
          }),
        );
      }
    },
    [dispatch, appointmentId, branchId, doctor, selectedSubSpeciality, selectedTime.from],
  );

  const onFetchBookedAppointments = useCallback(
    (start?: string, end?: string, params?: RemoteDataParams) => {
      if (doctor && start && end) {
        dispatch(
          fetchBookedAppointments(doctor.id, {
            ...(params || DEFAULT_PAGINATION_PARAMS),
            branchesIds: [branchId],
            start,
            end,
          }),
        );
      }
    },
    [dispatch, branchId, doctor],
  );

  const handleSubSpecialityChange = useCallback(
    (value: Option) => {
      if (selectedWorkingTime) {
        dispatch(setActiveStep(WizardStep.Service));
        dispatch(fetchDoctorProducts(selectedWorkingTime.id, value.value, patientSourceId));
      }
    },
    [dispatch, selectedWorkingTime, patientSourceId],
  );

  const handleServiceChange = useCallback(
    async (value: Option) => {
      if (!isWaiting && selectedTime.to) {
        const isSlotFree = await checkSlotIsFree(selectedTime.from, selectedTime.to);

        dispatch(setActiveStep(!isSlotFree ? WizardStep.ConflictingAppointments : WizardStep.Patient));
        onFetchDay(value);

        if (!isSlotFree) {
          onFetchBookedAppointments(getApiDateAndTime(selectedTime.from), getApiDateAndTime(selectedTime.to));
        }
      } else {
        dispatch(setActiveStep(WizardStep.Patient));
      }
    },
    [dispatch, checkSlotIsFree, isWaiting, onFetchBookedAppointments, onFetchDay, selectedTime],
  );

  const onRefreshConflictingAppointment = useCallback(async () => {
    if (!isWaiting && selectedTime.to && selectedService) {
      const isSlotFree = await checkSlotIsFree(selectedTime.from, selectedTime.to);

      dispatch(setActiveStep(!isSlotFree ? WizardStep.ConflictingAppointments : WizardStep.Patient));
      onFetchDay(selectedService);

      if (!isSlotFree) {
        onFetchBookedAppointments(getApiDateAndTime(selectedTime.from), getApiDateAndTime(selectedTime.to));
      }
    }
  }, [checkSlotIsFree, dispatch, isWaiting, onFetchBookedAppointments, onFetchDay, selectedService, selectedTime]);

  const handleTimeChange = useCallback(
    async (time: { from: Date; to: Date }) => {
      if (!isWaiting) {
        const isSlotFree = await checkSlotIsFree(time.from, time.to);

        if (!isSlotFree) {
          onFetchBookedAppointments(getApiDateAndTime(time.from), getApiDateAndTime(time.to));
        }
      }
    },
    [checkSlotIsFree, isWaiting, onFetchBookedAppointments],
  );

  const handleWorkingTimeChange = useCallback(
    (workingTime: WorkingTime) => {
      const startTime = moment(workingTime.startTime).toDate();
      const endTime = moment(workingTime.startTime).add(5, 'minute').toDate();

      dispatch(change(FORMS_BOOKING_WIZARD, 'start', startTime));
      dispatch(change(FORMS_BOOKING_WIZARD, 'end', endTime));
      dispatch(selectTime({ from: startTime, to: endTime }));

      if (doctor) {
        dispatch(fetchDoctorSubSpecialities(workingTime.id, patientSourceId));
      }
    },
    [dispatch, doctor, patientSourceId],
  );

  const handlePatientSourceChange: EventWithDataHandler<ChangeEvent<HTMLSelectElement>> = useCallback(
    (_, newValue: PatientSourceOption | null) => {
      if (newValue?.value !== patientSourceId) {
        dispatch(setActiveStep(WizardStep.SubSpeciality));
        dispatch(selectSubSpeciality(null));

        if (selectedWorkingTime) {
          dispatch(fetchDoctorSubSpecialities(selectedWorkingTime.id, newValue?.value));
        }
      }
    },
    [dispatch, patientSourceId, selectedWorkingTime],
  );

  return {
    handlePatientSourceChange,
    handleSubSpecialityChange,
    handleServiceChange,
    handleTimeChange,
    handleWorkingTimeChange,
    onFetchBookedAppointments,
    onRefreshConflictingAppointment,
  };
};
