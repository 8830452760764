import { Option } from 'app/types';

export interface Allergy {
  allergyCause: Option;
  allergyStatus: Option;
  allergyType: Option;
  hasMergePatientsBadge: boolean;
  id: string;
  note?: string;
  otherAllergyType?: string;
  otherReaction?: string;
  patientId: string;
  reaction: Option;
  severity: Option;
}

export enum AllergyStatus {
  Active = '0',
  Inactive = '1',
}

export enum AllergyType {
  Drug = '0',
  NonDrug = '1',
  Other = '2',
}

export enum AllergyReaction {
  AcuteKidneyFailure = '0',
  Anaphylaxis = '1',
  Arthralgia = '2',
  Chiills = '3',
  Cough = '4',
  Fever = '5',
  Headache = '6',
  Hives = '7',
  MalaiseFatigue = '8',
  Myalgia = '9',
  NasalCongestion = '10',
  Nausea = '11',
  PainSoreness = '12',
  Rash = '13',
  RespiratoryDistress = '14',
  Rhinorrhea = '15',
  ShortnessDifficultyBreathing = '16',
  SoreThroat = '17',
  Swelling = '18',
  Other = '19',
}
