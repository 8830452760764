import { Option } from 'app/types/Option';
import { InsurancePoliciesStatus } from 'app/types/InsuranceRemovedContract';

export enum RoomType {
  Shared = '0',
  Private = '1',
  Deluxe = '2',
  Suite = '3',
  Royal = '4',
  Other = '5',
}

export enum InsurancePoliciesClassStatus {
  Active = '0',
  Inactive = '1',
  Expired = '2',
  Removed = '3',
}

export enum ClassBillingItemsGroup {
  Consultation = '1',
  Service = '2',
  Procedure = '3',
  Lab = '4',
  Radiology = '5',
}

export enum InsurancePoliciesEntityStatus {
  Active = '0',
  Deactivate = '1',
}

export interface InsurancePoliciesClasses {
  id: string;
  insuranceContractId: string;
  name: string;
  policyId: string;
  branchId: string;
  nonReplaceableBrandMedicationsMaxPatientShare: number;
  nonReplaceableBrandMedicationsPatientSharePercentage: number;
  insuranceCompanyName: Option;
  preAuthorizedLimit: number;
  primaryMaxPatientShare: number;
  primaryPatientSharePercentage: number;
  referralMaxPatientShare: number;
  referralPatientSharePercentage: number;
  replaceableBrandMedicationsMaxPatientShare: number;
  replaceableBrandMedicationsPatientSharePercentage: number;
  roomType?: Option<RoomType>;
  otherRoomInformation?: string;
  specializedMaxPatientShare: number;
  specializedPatientSharePercentage: number;
  policyStatus: Option<InsurancePoliciesStatus>;
  status: Option<InsurancePoliciesClassStatus | InsurancePoliciesStatus>;
}

export interface InsurancePolicies {
  id: string;
  nameAr: string;
  nameEn: string;
  insuranceClasses: InsurancePoliciesClasses[];
  policyNumber: string;
  startDate: string;
  endDate: string;
  status: Option;
}

export interface RemovedInsurancePolicies {
  id: string;
  nameAr: string;
  nameEn: string;
  policyNumber: string;
  startDate: string;
  endDate: string;
  status: Option<InsurancePoliciesStatus>;
  contractStatus: Option<InsurancePoliciesStatus>;
}
