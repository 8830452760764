import { Option } from 'app/types/Option';

export enum ExclusionStatus {
  Active = '0',
  Deactivate = '1',
  Removed = '2',
}

export enum ExclusionType {
  Speciality = '0',
  Practitioner = '1',
  BillingItem = '2',
  ICD10 = '3',
}

export interface ExclusionsLog {
  id: string;
  exclusionLabel: string;
  action: Option;
  actionTaker: Option;
  date: string;
}

export interface Exclusion {
  id: string;
  insuranceContractorId: string;
  status: Option<ExclusionStatus>;
  object: Option;
  description?: Option; // Only for Billing Items
}
