import { ChangeAction } from 'redux-form';
import { ChangeEvent, useCallback } from 'react';
import { PatientFormField } from 'app/features/patient/patientForm/types';

export const useChange = (change: ChangeAction) => {
  const onEmergencyContactNameChange = useCallback(
    (_: ChangeEvent, value?: string) => {
      if (!value) {
        change(PatientFormField.emergencyContactRelation, null);
        change(PatientFormField.otherEmergencyContactRelation, null);
        change(PatientFormField.emergencyContactNumber, null);
      }
    },
    [change],
  );

  const onEmergencyContactRelationChange = useCallback(() => {
    change(PatientFormField.otherEmergencyContactRelation, null);
  }, [change]);

  const onMaritalStatusChange = useCallback(() => {
    change(PatientFormField.otherMaritalStatus, null);
  }, [change]);

  const onGovernmentIdTypeChange = useCallback(() => {
    change(PatientFormField.governmentIssuedIdNumber, null);
  }, [change]);

  return {
    onEmergencyContactNameChange,
    onEmergencyContactRelationChange,
    onMaritalStatusChange,
    onGovernmentIdTypeChange,
  };
};
