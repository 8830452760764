import toast from 'react-hot-toast';
import { useCallback, useMemo } from 'react';
import { FormSubmitHandler } from 'redux-form/lib/reduxForm';
import { useAppSelector } from 'app/helpers';
import { FORMS_INVOICING_PROCESS_EDIT_PAYMENT_METHOD } from 'app/shared';
import { eitPaymentMethodItem } from 'app/redux/invoicingProcess/invoicingProcess.actions';
import { PaymentMethodFormData } from 'app/features/invoicingProcess/process/paymentMethod/paymentMethodForm/types';
import { PaymentMethodForm } from 'app/features/invoicingProcess/process/paymentMethod/paymentMethodForm/paymentMethodForm';

interface Props {
  onSubmitSuccess: () => void;
}

export const EditPaymentMethodForm = ({ onSubmitSuccess }: Props) => {
  const selectedPaymentMethodItem = useAppSelector((state) => state.invoicingProcess.selectedPaymentMethodItem);

  const onSubmit = useCallback<FormSubmitHandler<PaymentMethodFormData>>(
    (values, dispatch) => {
      if (selectedPaymentMethodItem) {
        dispatch(
          eitPaymentMethodItem({
            amount: Number(values.amount),
            creditNotePayment: selectedPaymentMethodItem.creditNotePayment,
            id: selectedPaymentMethodItem.id,
            isDefault: selectedPaymentMethodItem.isDefault,
            notes: values.notes || null,
            otherPaymentMethod: values.otherPaymentMethod,
            paymentMethod: values.paymentMethod,
            timeOfCreation: selectedPaymentMethodItem.timeOfCreation,
          }),
        );
        onSubmitSuccess();
        toast.success('CORE.TEXT.ITEM-UPDATED-SUCCESSFULLY');
      }
    },
    [onSubmitSuccess, selectedPaymentMethodItem],
  );

  const initialValues = useMemo<Partial<PaymentMethodFormData> | undefined>(() => {
    if (selectedPaymentMethodItem) {
      return {
        amount: String(selectedPaymentMethodItem.amount),
        notes: selectedPaymentMethodItem.notes || undefined,
        otherPaymentMethod: selectedPaymentMethodItem.otherPaymentMethod,
        paymentMethod: selectedPaymentMethodItem.paymentMethod,
      };
    }

    return;
  }, [selectedPaymentMethodItem]);

  return (
    <PaymentMethodForm
      form={FORMS_INVOICING_PROCESS_EDIT_PAYMENT_METHOD}
      initialValues={initialValues}
      onSubmit={onSubmit}
    />
  );
};
