export enum InvoicesItemsTabs {
  InvoiceDraftItems = 'invoices-draft-items',
  InvoicesItems = 'invoices-items',
}

export enum InvoicesRequestsItemsTabs {
  InvoicesDiscardItems = 'invoices-discard-items',
  InvoicesRequestsItems = 'invoices-requests-items',
}

export enum InvoicesRequestStatus {
  Pending = '0',
  Discarded = '1',
  Approved = '2',
}
