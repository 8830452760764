import { Button } from 'reactstrap';
import { ColumnFormatter } from 'react-bootstrap-table-next';
import { AppointmentInvoice } from 'app/types';
import { InvoiceLink } from 'app/features/invoices/components/invoiceLink/invoiceLink';
import { FormatExtraData } from 'app/features/invoicingProcess/invoices/helpers/useColumns';

export const InvoiceNumberFormatter: ColumnFormatter<AppointmentInvoice, FormatExtraData> = (
  _,
  row,
  rowIndex,
  formatExtraData,
) => {
  if (row.billingItemsBased || row.isDraft) {
    return (
      <Button
        color="link"
        className="p-0"
        onClick={() => (row.isDraft ? formatExtraData?.viewDraft?.(row.id) : formatExtraData?.viewInvoice?.(row.id))}
        size="sm"
      >
        {row.invoiceNumber ||
          row.draftNumber ||
          formatExtraData?.formatMessage?.({ id: 'CORE.TEXT.DRAFT-INVOICE', defaultMessage: 'Draft Invoice' })}
      </Button>
    );
  }

  if (row.invoiceNumber) {
    return <InvoiceLink id={row.id} isBillingItemsBased={row.billingItemsBased} number={row.invoiceNumber} />;
  }

  return '-';
};
