import { useMemo } from 'react';
import { useAppSelector } from 'app/helpers';
import { useExtendedItems } from 'app/features/invoicingProcess/process/items/helpers';
import { getLeftToPayValue } from 'app/features/invoicingProcess/process/paymentMethod/helpers/getLeftToPayValue';

export const useLeftToPay = (): number => {
  const invoicingProcessItems = useAppSelector((state) => state.invoicingProcess.items);
  const paymentMethodItems = useAppSelector((state) => state.invoicingProcess.paymentMethodItems);
  const invoiceStatus = useAppSelector((state) => state.invoicingProcess.data?.invoiceStatus?.value);
  const creditNotePayments = useAppSelector(
    (state) => state.invoicingProcess.data?.steps?.paymentMethod?.creditNotePayments || [],
  );
  const extendedItems = useExtendedItems(invoicingProcessItems);

  return useMemo(
    () => getLeftToPayValue({ creditNotePayments, extendedItems, paymentMethodItems, invoiceStatus }),
    [creditNotePayments, extendedItems, invoiceStatus, paymentMethodItems],
  );
};
