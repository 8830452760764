import { checkPrefix } from '../helpers/checkPrefix';

export const onlyArabic = (value: any) => {
  if (checkPrefix(value)) {
    value = value?.value;
  }

  return typeof value === 'string' && value !== '' && !/^[\u0600-\u06FF\d\W_]+$/.test(value)
    ? 'ERRORS.ONLY-ARABIC'
    : undefined;
};
