import React, { useState, useCallback, useEffect, useMemo } from 'react';
import { PaginationOptions } from 'react-bootstrap-table-next';
import { ClinicPatientShortInfo } from 'app/services/patients/types';
import { useColumns } from 'app/shared/patientSearch/patientSearchResults/helpers/useColumns';
import { useGetRelatedPatientsForAppointmentQuery } from 'app/services/patients/patients';
import { LocalTable } from 'app/shared';

interface Props {
  patientId: string;
  phoneNumber: string;
  selectionChanged: (data: ClinicPatientShortInfo | null) => void;
}

export const List = ({ patientId, phoneNumber, selectionChanged }: Props) => {
  const [selectedRow, setSelectedRow] = useState<number>(-1);

  const { data, isFetching, isLoading } = useGetRelatedPatientsForAppointmentQuery({ patientId, phoneNumber });
  //Actions
  const checkIsRowSelected = useCallback((index: number) => selectedRow === index, [selectedRow]);

  const resetSelection = useCallback(() => {
    setSelectedRow(-1);
    selectionChanged(null);
  }, [selectionChanged]);

  const handleSelectionChange = useCallback(
    (index: number) => {
      const selectedIndex = selectedRow === index ? -1 : index;
      setSelectedRow(selectedIndex);
      if (selectedIndex >= 0 && selectedIndex < (data?.data?.length || 0) && data?.data[selectedIndex]) {
        const selectedPatient = data.data[selectedIndex];
        selectionChanged(selectedPatient);
      } else {
        selectionChanged(null);
      }
    },
    [data?.data, selectedRow, selectionChanged],
  );

  const paginationSettings: PaginationOptions = useMemo(
    () => ({
      totalSize: data?.data.length || 0,
      onSizePerPageChange: resetSelection,
      onPageChange: resetSelection,
    }),
    [data?.data, resetSelection],
  );

  const columns = useColumns({
    checkIsSelected: checkIsRowSelected,
    selectionChanged: handleSelectionChange,
  });

  useEffect(() => {
    if (isFetching || isLoading) {
      resetSelection();
    }
  }, [isFetching, isLoading, resetSelection]);

  return (
    <LocalTable
      classes="table-striped table-sm"
      columns={columns}
      data={data?.data || []}
      id="relatedPatients"
      keyField="id"
      loading={isFetching || isLoading}
      pagination={paginationSettings}
    />
  );
};
