import { useMemo } from 'react';
import sumBy from 'lodash/sumBy';
import { formattedDate, useAppIntl, useAppSelector } from 'app/helpers';
import { DescriptionList, FormattedPrice, InvoiceStatusBadge } from 'app/components';
import { useExtendedItems } from 'app/features/invoicingProcess/process/items/helpers';
import { PaymentMethodLabel } from 'app/features/invoicingProcess/process/summary/paymentMethodPreview/paymentMethodLabel/paymentMethodLabel';

export const PaymentMethodPreview = () => {
  const invoicingProcessItems = useAppSelector((state) => state.invoicingProcess.items);
  const invoiceStatus = useAppSelector((state) => state.invoicingProcess.data?.invoiceStatus);
  const paymentMethod = useAppSelector((state) => state.invoicingProcess.data?.steps?.paymentMethod);
  const { formatMessage } = useAppIntl();
  const items = useExtendedItems(invoicingProcessItems);

  const listItems = useMemo(() => {
    if (!paymentMethod) {
      return [];
    }

    const totalDue = sumBy(items, (item) => item.patientShareTotal);
    const totalPaid = sumBy(paymentMethod.items, (item) => item.amount);

    return [
      {
        label: formatMessage({ id: 'CORE.LABEL.DUE-DATE', defaultMessage: 'Due Date' }),
        value: formattedDate(paymentMethod.dueDate),
      },
      {
        label: formatMessage({ id: 'CORE.LABEL.PAYMENT-METHOD', defaultMessage: 'Payment Method' }),
        value: <PaymentMethodLabel paymentMethodItems={paymentMethod.items} />,
      },
      {
        label: formatMessage({ id: 'CORE.LABEL.TOTAL-DUE', defaultMessage: 'Total Due' }),
        value: <FormattedPrice amount={totalDue} />,
      },
      {
        label: formatMessage({ id: 'CORE.LABEL.TOTAL-PAID', defaultMessage: 'Total Paid' }),
        value: <FormattedPrice amount={totalPaid} />,
      },
      {
        label: formatMessage({ id: 'CORE.LABEL.STATUS' }),
        value: invoiceStatus ? <InvoiceStatusBadge className="text-wrap text-start" status={invoiceStatus} /> : '-',
      },
    ];
  }, [formatMessage, invoiceStatus, items, paymentMethod]);

  return <DescriptionList rowProps={{ xl: 5 }} items={listItems} />;
};
