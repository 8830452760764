import { InitialAssessmentAppointmentType, PatientGender } from 'app/types';

export enum WizardStep {
  Background = 'background',
  Vitals = 'vitals',
  Note = 'note',
}

export interface InitialAssessmentBody {
  patientIllness: {
    allergies: boolean;
    checkUp: boolean;
    chronicDiseases: boolean;
    cleaning: boolean;
    congenitalOrDevelopmental: boolean;
    indicateLmp?: string;
    infertility: boolean;
    medications: boolean;
    na: boolean;
    orthodonticOrAesthetics: boolean;
    other: boolean;
    otherMessage: string;
    pregnancy: boolean;
    psychiatric: boolean;
    regularDentalTreatment: boolean;
    rta: boolean;
    smokingStatus: boolean;
    sportRelated: boolean;
    vaccination: boolean;
    workRelatedAccident: boolean;
  };
  initialAssessmentPatientVitals: {
    temperature?: number;
    pulse?: number;
    bloodPressureFirstPart?: number;
    bloodPressureSecondPart?: number;
    respiratoryRate?: number;
    oxygenSaturation?: number;
    bloodGlucose?: number;
    pain?: number;
    headCircumference?: number;
  };
  appointmentType: InitialAssessmentAppointmentType;
  reasonOfVisit?: string;
  how?: string;
  when?: string;
  where?: string;
  durationOfIllnessInDays?: number;
  familyHistoryIllnessIds?: string[];
  patientMedicalIllnessIds?: string[];
  previousSurgeries?: string[];
  note?: string;
  height?: number;
  weight?: number;
  bmi?: number;
  selectedPatientGender: PatientGender | null;
}
