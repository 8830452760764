import { Middleware } from 'redux';
import toast from 'react-hot-toast';
import { MiddlewareAPI } from '@reduxjs/toolkit';
import { ApiError, RSAAResultAction } from 'redux-api-middleware';

const isRSAAResultAction = (action: unknown): action is RSAAResultAction<unknown, unknown> =>
  typeof action === 'object' && action != null && 'error' in action && 'payload' in action;

const isRSAAApiError = (error: unknown): error is ApiError<{ message: string } | undefined> =>
  typeof error === 'object' && error != null && 'name' in error && error.name === 'ApiError';

export const errorMiddleware: Middleware = (api: MiddlewareAPI) => (next) => (action) => {
  // Check RSAA error
  if (isRSAAResultAction(action) && isRSAAApiError(action.payload)) {
    // @ts-ignore (custom meta field, not expected with ApiError)
    const skipError = !!action.meta?.skipError;

    if (!skipError) {
      if (action.payload.response?.message && action.payload.status === 400) {
        toast.error(action.payload.response.message);
      } else {
        toast.error('CORE.TEXT.GENERAL-ERROR');
      }
    }
  }

  return next(action);
};
