export const LOGOUT = 'auth/LOGOUT';
export const SOFT_LOGOUT = 'auth/SOFT_LOGOUT';
export const SET_TOKENS = 'auth/SET_TOKENS';
export const REPLACE_TOKENS = 'auth/REPLACE_TOKENS';

export const LOGIN_USER_REQUEST = 'auth/LOGIN_USER_REQUEST';
export const LOGIN_USER_SUCCESS = 'auth/LOGIN_USER_SUCCESS';
export const LOGIN_USER_FAILURE = 'auth/LOGIN_USER_FAILURE';

export const REFRESH_TOKEN_REQUEST = 'auth/REFRESH_TOKEN_REQUEST';
export const REFRESH_TOKEN_SUCCESS = 'auth/REFRESH_TOKEN_SUCCESS';
export const REFRESH_TOKEN_FAILURE = 'auth/REFRESH_TOKEN_FAILURE';

export const CHANGE_PASSWORD_REQUEST = 'auth/CHANGE_PASSWORD_REQUEST';
export const CHANGE_PASSWORD_SUCCESS = 'auth/CHANGE_PASSWORD_SUCCESS';
export const CHANGE_PASSWORD_FAILURE = 'auth/CHANGE_PASSWORD_FAILURE';

export const FORGOTTEN_PASSWORD_REQUEST = 'auth/FORGOTTEN_PASSWORD_REQUEST';
export const FORGOTTEN_PASSWORD_SUCCESS = 'auth/FORGOTTEN_PASSWORD_SUCCESS';
export const FORGOTTEN_PASSWORD_FAILURE = 'auth/FORGOTTEN_PASSWORD_FAILURE';

export const RESET_PASSWORD_REQUEST = 'auth/RESET_PASSWORD_REQUEST';
export const RESET_PASSWORD_SUCCESS = 'auth/RESET_PASSWORD_SUCCESS';
export const RESET_PASSWORD_FAILURE = 'auth/RESET_PASSWORD_FAILURE';

export const FETCH_CURRENT_USER_REQUEST = 'auth/FETCH_CURRENT_USER_REQUEST';
export const FETCH_CURRENT_USER_SUCCESS = 'auth/FETCH_CURRENT_USER_SUCCESS';
export const FETCH_CURRENT_USER_FAILURE = 'auth/FETCH_CURRENT_USER_FAILURE';

export const EDIT_USER_PROFILE_REQUEST = 'auth/EDIT_USER_PROFILE_REQUEST';
export const EDIT_USER_PROFILE_SUCCESS = 'auth/EDIT_USER_PROFILE_SUCCESS';
export const EDIT_USER_PROFILE_FAILURE = 'auth/EDIT_USER_PROFILE_FAILURE';

export const FETCH_SPECIALITY_OPTIONS_REQUEST = 'auth/FETCH_SPECIALITY_OPTIONS_REQUEST';
export const FETCH_SPECIALITY_OPTIONS_SUCCESS = 'auth/FETCH_SPECIALITY_OPTIONS_SUCCESS';
export const FETCH_SPECIALITY_OPTIONS_FAILURE = 'auth/FETCH_SPECIALITY_OPTIONS_FAILURE';

export const FETCH_OWNER_BRANCH_OPTIONS_REQUEST = 'auth/FETCH_OWNER_BRANCH_OPTIONS_REQUEST';
export const FETCH_OWNER_BRANCH_OPTIONS_SUCCESS = 'auth/FETCH_OWNER_BRANCH_OPTIONS_SUCCESS';
export const FETCH_OWNER_BRANCH_OPTIONS_FAILURE = 'auth/FETCH_OWNER_BRANCH_OPTIONS_FAILURE';
