import { InsuranceClassPatientShare, InsuranceClassPatientShareType } from 'app/types';
import { PaymentModeFormData } from 'app/features/invoicingProcess/process/paymentMode/form/types';

export const mapInsuranceClassPatientSharesToFields = (
  shares: InsuranceClassPatientShare[] | undefined,
): Partial<PaymentModeFormData> => {
  if (!shares) {
    return {};
  }

  const nonReplaceableBrandMedications = shares.find(
    (s) => s.shareType === InsuranceClassPatientShareType.NonReplaceableBrandMedications,
  );
  const primaryClinic = shares.find((s) => s.shareType === InsuranceClassPatientShareType.PrimaryClinic);
  const referralToSpecializedClinic = shares.find(
    (s) => s.shareType === InsuranceClassPatientShareType.ReferralToSpecializedClinic,
  );
  const replaceableBrandMedications = shares.find(
    (s) => s.shareType === InsuranceClassPatientShareType.ReplaceableBrandMedications,
  );
  const specializedClinic = shares.find((s) => s.shareType === InsuranceClassPatientShareType.SpecializedClinic);

  return {
    nonReplaceableBrandMedicationsMaxPatientShare: String(nonReplaceableBrandMedications?.maxPatientShare || 0),
    nonReplaceableBrandMedicationsPatientShare: String(nonReplaceableBrandMedications?.sharePercentage || 0),
    primaryClinicMaxPatientShare: String(primaryClinic?.maxPatientShare || 0),
    primaryClinicPatientShare: String(primaryClinic?.sharePercentage || 0),
    referralToSpecializedClinicMaxPatientShare: String(referralToSpecializedClinic?.maxPatientShare || 0),
    referralToSpecializedClinicPatientShare: String(referralToSpecializedClinic?.sharePercentage || 0),
    replaceableBrandMedicationsMaxPatientShare: String(replaceableBrandMedications?.maxPatientShare || 0),
    replaceableBrandMedicationsPatientShare: String(replaceableBrandMedications?.sharePercentage || 0),
    specializedClinicMaxPatientShare: String(specializedClinic?.maxPatientShare || 0),
    specializedClinicPatientShare: String(specializedClinic?.sharePercentage || 0),
  };
};
