import React from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import { FORMATS_DATE } from '../';

export const FormattedDate = ({ date, format, isWrap = false }) => (
  <span className={!isWrap ? 'text-nowrap' : undefined}>{moment(date).format(format || FORMATS_DATE)}</span>
);

FormattedDate.propTypes = {
  date: PropTypes.any.isRequired,
  isWrap: PropTypes.bool,
};
