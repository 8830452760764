import { Option, PatientPaymentMethod } from 'app/types';

export const PaymentMethodField = {
  amount: 'amount',
  notes: 'notes',
  otherPaymentMethod: 'otherPaymentMethod',
  paymentMethod: 'paymentMethod',
} as const;

export interface PaymentMethodFormData {
  [PaymentMethodField.amount]: string;
  [PaymentMethodField.notes]?: string;
  [PaymentMethodField.otherPaymentMethod]?: string;
  [PaymentMethodField.paymentMethod]: Option<PatientPaymentMethod>;
}
