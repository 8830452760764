import { useCallback } from 'react';
import { Button } from 'reactstrap';
import { formattedDate, useAppIntl, useAppSelector } from 'app/helpers';

export const DoctorAppointments = () => {
  const { formatMessage } = useAppIntl();
  const selectedSlot = useAppSelector((state) => state.calendar.selectedSlot);

  const onClick = useCallback(() => {
    if (selectedSlot) {
      const newWindow = window.open(
        `/appointments/upcoming/doctor/${selectedSlot.doctor.id}?filterFrom=${selectedSlot.date}&filterTo=${selectedSlot.date}`,
        '_blank',
      );

      if (newWindow) {
        newWindow.focus();
      }
    }
  }, [selectedSlot]);

  if (!selectedSlot) {
    return null;
  }

  return (
    <div className="mt-3">
      <Button className="btn-responsive" color="flat" onClick={onClick} size="sm">
        {formatMessage({ id: 'APPOINTMENTS.TEXT.APPOINTMENTS' })}
        {': '}
        {`${formattedDate(selectedSlot.date)}`}
      </Button>
    </div>
  );
};
