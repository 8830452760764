import sumBy from 'lodash/sumBy';
import { useCallback } from 'react';
import { ColumnDescription } from 'react-bootstrap-table-next';
import { FORMS_INVOICING_PROCESS_PAYMENT_MODE } from 'app/shared';
import { FormattedPercent, FormattedPrice } from 'app/components';
import { getTranslation, useAppIntl, useAppSelector } from 'app/helpers';
import { InvoiceDiscountType, InvoiceEligibility, InvoiceItem, InvoiceItemTotal, InvoicePayBy } from 'app/types';
import { useInvoicingProcessState } from 'app/features/invoicingProcess/process/helpers';
import { getGrossPrice } from 'app/features/invoicingProcess/process/items/helpers/getters';
import { ExtendedItem, FormatExtraData } from 'app/features/invoicingProcess/process/items/types';
import { PaymentModeField, PaymentModeFormData } from 'app/features/invoicingProcess/process/paymentMode/form/types';
import { ActionsFormatter } from 'app/features/invoicingProcess/process/items/formatters/actionsFormatter/actionsFormatter';
import { PatientShareFormatter } from 'app/features/invoicingProcess/process/items/formatters/patientShareFormatter/patientShareFormatter';

export const useColumns = (editItem: (item: InvoiceItem) => void, deleteItem: (item: InvoiceItem) => void) => {
  const { formatMessage, locale } = useAppIntl();
  const { isCreated, isCreateCreditNoteMode, invoicePackageSnapshotId } = useInvoicingProcessState();
  const payBy: PaymentModeFormData['payBy'] | undefined = useAppSelector(
    (state) => state.form[FORMS_INVOICING_PROCESS_PAYMENT_MODE]?.values?.[PaymentModeField.payBy],
  );
  const eligibility: PaymentModeFormData['eligibility'] | undefined = useAppSelector(
    (state) => state.form[FORMS_INVOICING_PROCESS_PAYMENT_MODE]?.values?.[PaymentModeField.eligibility],
  );
  const isReferralInOrPreviousApproval =
    eligibility?.value === InvoiceEligibility.ReferralIn || eligibility?.value === InvoiceEligibility.PreviousApproval;
  const isPayByInsurance = payBy?.value === InvoicePayBy.Insurance;

  return useCallback<
    (items: ExtendedItem[], hasActions?: boolean) => ColumnDescription<ExtendedItem, FormatExtraData>[]
  >(
    (items, hasActions = true) => {
      const hasDeleteAction = !(
        (isCreateCreditNoteMode || isReferralInOrPreviousApproval || invoicePackageSnapshotId) &&
        items.length <= 1
      );

      const total: InvoiceItemTotal = {
        discount: sumBy(items, (i) => i.discountValue),
        grandTotalValue: sumBy(items, (i) => i.grandTotal),
        insuranceShare: sumBy(items, (i) => i.insuranceShareTotal),
        patientShare: sumBy(items, (i) => i.patientShareTotal),
        price: sumBy(items, (i) => getGrossPrice(i)),
        vatValue: sumBy(items, (i) => i.vatValue),
      };

      return [
        {
          dataField: 'internalCode',
          footer: false,
          formatter: (cell, row) => row.billingItem.details.internalCode || '-',
          text: formatMessage({ id: 'BILLING-ITEMS.LABEL.INTERNAL-CODE', defaultMessage: 'Internal Code' }),
        },
        {
          dataField: 'billingItem',
          footer: false,
          formatter: (cell, row) => getTranslation(row.billingItem, locale),
          text: formatMessage({ id: 'CORE.LABEL.DESCRIPTION', defaultMessage: 'Description' }),
        },
        {
          classes: 'text-center',
          dataField: 'quantity',
          footer: formatMessage({ id: 'CORE.TABLE.TOTAL' }),
          footerClasses: 'text-right',
          text: formatMessage({ id: 'CORE.LABEL.QUANTITY', defaultMessage: 'Quantity' }),
        },
        {
          classes: 'text-nowrap',
          dataField: 'price',
          footer: true,
          footerClasses: 'text-nowrap',
          footerFormatter: () => <FormattedPrice amount={total.price} />,
          formatter: (_, row) => <FormattedPrice amount={getGrossPrice(row)} />,
          text: formatMessage({ id: 'CORE.LABEL.PRICE', defaultMessage: 'Price' }),
        },
        {
          classes: 'text-center',
          dataField: 'discount',
          footer: true,
          footerClasses: 'text-center',
          footerFormatter: () => <FormattedPrice amount={total.discount} />,
          formatter: (_, row) =>
            row.discount.discountTypeView.value === InvoiceDiscountType.Percentage ? (
              <FormattedPercent value={row.discount.value} />
            ) : (
              <FormattedPrice amount={row.discount.value} />
            ),
          text: formatMessage({ id: 'CORE.LABEL.DISCOUNT', defaultMessage: 'Discount' }),
        },
        {
          classes: 'text-center',
          dataField: 'vatPercent',
          footer: true,
          footerClasses: 'text-center',
          footerFormatter: () => '-',
          formatter: (_, row) => <FormattedPercent value={row.vatPercent} />,
          text: formatMessage({ id: 'CORE.LABEL.VAT-PERCENT', defaultMessage: 'VAT Percent' }),
        },
        {
          classes: 'text-nowrap',
          dataField: 'vatValue',
          footer: true,
          footerClasses: 'text-nowrap',
          footerFormatter: () => <FormattedPrice amount={total.vatValue} />,
          formatter: (cell, row) => <FormattedPrice amount={row.vatValue} />,
          text: formatMessage({ id: 'CORE.LABEL.VAT-VALUE', defaultMessage: 'VAT Value' }),
        },
        {
          classes: 'text-nowrap',
          dataField: 'totalValue',
          footer: true,
          footerClasses: 'text-nowrap',
          footerFormatter: () => <FormattedPrice amount={total.grandTotalValue} />,
          formatter: (cell, row) => <FormattedPrice amount={row.grandTotal} />,
          text: formatMessage({ id: 'CORE.LABEL.GRAND-TOTAL', defaultMessage: 'Grand Total' }),
        },
        {
          classes: 'text-nowrap',
          dataField: 'patientShare',
          footer: true,
          footerClasses: 'text-nowrap',
          footerFormatter: () => <FormattedPrice amount={total.patientShare} />,
          formatExtraData: { formatMessage },
          formatter: PatientShareFormatter,
          text: formatMessage({ id: 'CORE.LABEL.PATIENT-SHARE', defaultMessage: 'Patient Share' }),
        },
        {
          classes: 'text-nowrap',
          dataField: 'insuranceShare',
          footer: true,
          footerClasses: 'text-nowrap',
          footerFormatter: () => <FormattedPrice amount={total.insuranceShare} />,
          formatter: (_, row) => <FormattedPrice amount={row.insuranceShareTotal} />,
          hidden: !isPayByInsurance,
          text: formatMessage({ id: 'CORE.LABEL.INSURANCE-SHARE', defaultMessage: 'Insurance Share' }),
        },
        {
          classes: 'text-center sticky sticky--end',
          dataField: 'actions',
          footer: true,
          footerClasses: 'sticky sticky--end',
          formatter: ActionsFormatter,
          formatExtraData: { deleteItem: hasDeleteAction ? deleteItem : undefined, editItem },
          headerClasses: 'text-center sticky sticky--end',
          hidden: !(hasActions && (!isCreated || isCreateCreditNoteMode)),
          text: formatMessage({ id: 'CORE.TABLE.ACTIONS' }),
        },
      ];
    },
    [
      deleteItem,
      editItem,
      formatMessage,
      invoicePackageSnapshotId,
      isCreateCreditNoteMode,
      isCreated,
      isPayByInsurance,
      isReferralInOrPreviousApproval,
      locale,
    ],
  );
};
