import { InvoiceItem, InvoicePaymentMethodItem } from 'app/types';
import { InvoicingProcess } from 'app/redux/invoicingProcess/types';

export const addInvoiceItem = (items: InvoiceItem[], newItem: InvoiceItem): InvoiceItem[] => {
  return [...items, newItem];
};

export const editInvoiceItem = (items: InvoiceItem[], newItem: InvoiceItem): InvoiceItem[] => {
  return items.map((item) => {
    if (item.id === newItem.id) {
      return newItem;
    }

    return item;
  });
};

export const deleteInvoiceItem = (items: InvoiceItem[], id: string): InvoiceItem[] => {
  return items.filter((item) => item.id !== id);
};

export const addPaymentMethod = (
  paymentMethods: InvoicePaymentMethodItem[],
  newPaymentMethod: InvoicePaymentMethodItem,
): InvoicePaymentMethodItem[] => {
  return [...paymentMethods, newPaymentMethod];
};

export const editPaymentMethod = (
  paymentMethods: InvoicePaymentMethodItem[],
  newPaymentMethod: InvoicePaymentMethodItem,
): InvoicePaymentMethodItem[] => {
  return paymentMethods.map((paymentMethod) => {
    if (paymentMethod.id === newPaymentMethod.id) {
      return newPaymentMethod;
    }

    return paymentMethod;
  });
};

export const deletePaymentMethod = (
  paymentMethods: InvoicePaymentMethodItem[],
  id: string,
): InvoicePaymentMethodItem[] => {
  return paymentMethods.filter((paymentMethod) => paymentMethod.id !== id);
};

/**
 * It adapts the Invoicing Process data structure to the needs of the configurator.
 * The configurator uses the "steps.items" field to generate invoice elements.
 * We must keep the default state in the dedicated field and overwrite "steps.items" with the current state of the invoice.
 * @param invoicingProcess
 */
export const overwriteItems = (invoicingProcess: InvoicingProcess): InvoicingProcess => {
  // 1. Omit credit notes without items
  const itemsSnapshots = !!invoicingProcess.itemsSnapshots?.length
    ? invoicingProcess.itemsSnapshots.filter((s) => !!s.items.length)
    : null;

  return {
    ...invoicingProcess,

    // 2. Move default items to the dedicated field
    defaultItems: invoicingProcess.steps?.items || null,

    // 3. Overwrite steps items with the latest credit note items
    ...(itemsSnapshots &&
      itemsSnapshots[0] && {
        steps: invoicingProcess.steps
          ? {
              ...invoicingProcess.steps,
              items: itemsSnapshots[0].items,
            }
          : null,
      }),
  };
};
