import { AppointmentValues } from 'app/redux/appointment/types';
import { BookAppointmentFormData } from 'app/bookAppointment/types';
import { SelectedSlot } from 'app/redux/calendar/types';

interface Props {
  formValues: BookAppointmentFormData;
  selectedSlot: SelectedSlot;
  start: string;
  end: string | null;
  referralId: string | null;
}

export const formValuesAdapter = ({ formValues, selectedSlot, start, end, referralId }: Props): AppointmentValues => {
  return {
    branchId: selectedSlot.branchId,
    guardianId: formValues.selectedGuardian?.value || null,
    doctorId: selectedSlot.doctor.id,
    end: end,
    note: formValues.note || '',
    patientId: formValues.patientId,
    productId: selectedSlot.product?.value,
    start: start,
    subSpecialityId: selectedSlot.subSpeciality?.value,
    patientSourceId: formValues.patientSource?.value,
    referralId: referralId,
    phoneNumber: formValues.selectedPhoneNumber.label,
  };
};
