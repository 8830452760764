import React from 'react';
import classnames from 'classnames';
import { Locale, Option, PatientGender, RemoteFile } from 'app/types';
import { getTranslation, useAppIntl } from 'app/helpers';
import styles from './printPrescription.module.scss';

export interface PrintHeaderPrescriptionData {
  branchName: Option;
  clinicBranchAddress?: Option;
  clinicBranchPhoneNumber: string;
  doctorFullName: string;
  speciality: string;
  internalFileNumber?: string;
  patientName: string;
  age?: number;
  gender?: Option<PatientGender>;
  clinicId: string;
  clinicLogo: RemoteFile | null;
  date?: string;
  signatureUrl?: string;
}

interface Props {
  data: PrintHeaderPrescriptionData;
}

export const PrescriptionPrintHeader = ({ data }: Props) => {
  const { formatMessage } = useAppIntl();
  const {
    branchName,
    clinicBranchAddress,
    clinicBranchPhoneNumber,
    doctorFullName,
    speciality,
    age,
    gender,
    patientName,
    clinicLogo,
    date,
  } = data;

  return (
    <div
      className={classnames(
        styles.generalInformation,
        'd-flex flex-row align-items-center justify-content-between text-black ',
      )}
    >
      <div>
        <h2 className={styles.prescriptionTitle}>{formatMessage({ id: 'CORE.TEXT.PRESCRIPTION' })}</h2>
        <div>
          <span className={styles.boldTitle}>Date: </span>
          <span className="fw-light">{date}</span>
        </div>
        <div>
          <span className={styles.boldTitle}>Prescribed by: </span>
          <span className="fw-light">{doctorFullName}</span>
        </div>
        <div>
          <span className={styles.boldTitle}>Specialty: </span>
          <span className="fw-light">{speciality}</span>
        </div>
        <div style={{ marginTop: '17px' }}>
          <span className={styles.boldTitle}>Patient: </span>
          <span className="fw-light">{patientName}</span>
        </div>
        <div>
          <span className={styles.boldTitle}>Gender: </span>
          <span className="fw-light"> {gender && getTranslation(gender, Locale.en)}</span>
        </div>
        <div>
          <span className={styles.boldTitle}>Age: </span>
          <span className="fw-light"> {age}</span>
        </div>
      </div>
      <div className="text-right">
        <div>
          <img className={styles.logo} src={clinicLogo?.url} alt={clinicLogo?.name} />
        </div>
        <h4 className={styles.branchName}>{branchName && getTranslation(branchName, Locale.en)}</h4>
        <div style={{ marginBottom: '-2px' }}>
          {clinicBranchAddress && getTranslation(clinicBranchAddress, Locale.en)}
        </div>
        <div>{clinicBranchAddress && getTranslation(clinicBranchAddress, Locale.ar)}</div>
        <div>
          <span className={styles.boldTitle}>Phone: </span>
          {clinicBranchPhoneNumber}
        </div>
      </div>
    </div>
  );
};
