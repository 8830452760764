export const CANCEL_ALL_CONFLICT_APPOINTMENTS_REQUEST = 'conflict-appointments/cancel-all/REQUEST';
export const CANCEL_ALL_CONFLICT_APPOINTMENTS_SUCCESS = 'conflict-appointments/cancel-all/SUCCESS';
export const CANCEL_ALL_CONFLICT_APPOINTMENTS_FAILURE = 'conflict-appointments/cancel-all/FAILURE';

export const CANCEL_CONFLICT_APPOINTMENT_REQUEST = 'conflict-appointments/cancel-appointment/REQUEST';
export const CANCEL_CONFLICT_APPOINTMENT_SUCCESS = 'conflict-appointments/cancel-appointment/SUCCESS';
export const CANCEL_CONFLICT_APPOINTMENT_FAILURE = 'conflict-appointments/cancel-appointment/FAILURE';

export const CANCEL_CONFLICT_WAITING_APPOINTMENT_REQUEST = 'conflict-appointments/cancel-waiting-appointment/REQUEST';
export const CANCEL_CONFLICT_WAITING_APPOINTMENT_SUCCESS = 'conflict-appointments/cancel-waiting-appointment/SUCCESS';
export const CANCEL_CONFLICT_WAITING_APPOINTMENT_FAILURE = 'conflict-appointments/cancel-waiting-appointment/FAILURE';

export const EXPORT_CONFLICT_APPOINTMENT_REQUEST = 'conflict-appointments/EXPORT_CONFLICT_APPOINTMENT_REQUEST';
export const EXPORT_CONFLICT_APPOINTMENT_SUCCESS = 'conflict-appointments/EXPORT_CONFLICT_APPOINTMENT_SUCCESS';
export const EXPORT_CONFLICT_APPOINTMENT_FAILURE = 'conflict-appointments/EXPORT_CONFLICT_APPOINTMENT_FAILURE';

export const EXPORT_CONFLICT_WAITING_APPOINTMENT_REQUEST = 'conflict-appointments/export-waiting-appointment/REQUEST';
export const EXPORT_CONFLICT_WAITING_APPOINTMENT_SUCCESS = 'conflict-appointments/export-waiting-appointment/SUCCESS';
export const EXPORT_CONFLICT_WAITING_APPOINTMENT_FAILURE = 'conflict-appointments/export-waiting-appointment/FAILURE';

export const BULK_ACTION_CONFLICT_APPOINTMENTS_REQUEST = 'conflict-appointments/bulk-action/REQUEST';
export const BULK_ACTION_CONFLICT_APPOINTMENTS_SUCCESS = 'conflict-appointments/bulk-action/SUCCESS';
export const BULK_ACTION_CONFLICT_APPOINTMENTS_FAILURE = 'conflict-appointments/bulk-action/FAILURE';

export const REBOOK_CONFLICT_APPOINTMENT_REQUEST = 'conflict-appointments/rebook/REQUEST';
export const REBOOK_CONFLICT_APPOINTMENT_SUCCESS = 'conflict-appointments/rebook/SUCCESS';
export const REBOOK_CONFLICT_APPOINTMENT_FAILURE = 'conflict-appointments/rebook/FAILURE';
