import React, { useCallback } from 'react';
import { Modal } from 'reactstrap';
import { Option } from 'app/types';
import { BillingItemsForm } from 'app/assessment/medicalProfileSection/components/modals/noteBillingItemModal/form/form';
import { NoteBillingItemsFormData } from 'app/assessment/medicalProfileSection/components/modals/noteBillingItemModal/form/types';
import { toggleModal } from 'app/redux/modals/modals.actions';
import { useModalCleaner, useAppSelector, useAppDispatch } from 'app/helpers';

interface Props {
  branchId: string;
  doctorId: string;
  onConfirm: (value?: Option[]) => void;
}

export const NoteBillingItemsModal = ({ branchId, doctorId, onConfirm }: Props) => {
  const dispatch = useAppDispatch();

  const isOpen = useAppSelector((state) => state.modal.noteBillingItems.isOpen);

  const onToggle = useCallback(() => {
    dispatch(toggleModal('noteBillingItems'));
  }, [dispatch]);

  const onSubmit = useCallback(
    (values: Partial<NoteBillingItemsFormData>) => {
      onConfirm(values.billingItems);
    },
    [onConfirm],
  );

  useModalCleaner('noteBillingItems');

  return (
    <Modal backdrop="static" keyboard={false} size="md" isOpen={isOpen} toggle={onToggle}>
      <BillingItemsForm onSubmit={onSubmit} branchId={branchId} doctorId={doctorId} onToggle={onToggle} />
    </Modal>
  );
};
