import React, { useMemo } from 'react';
import { UncontrolledTooltip } from 'reactstrap';
import { IconInfoCircle } from '@tabler/icons-react';

import defaultPhoto from '../../../../../assets/images/icons/default_photo.jpg';
import { DoctorData } from 'app/redux/book-appointment/types';
import { DescriptionList } from 'app/components';
import { useAppIntl, getDoctorFullName, getTranslation } from 'app/helpers';
import { useItems } from './helpers/useItems';

interface Props {
  doctor: DoctorData;
  workingDaySection?: boolean;
}

export const DoctorFormatter = ({ doctor, workingDaySection }: Props) => {
  const { formatMessage, locale } = useAppIntl();

  const items = useItems({ doctor });

  const sizeProps = useMemo(
    () => (workingDaySection ? { xs: '1', sm: '2', lg: '4', xl: '4' } : { xs: '1', sm: '2', lg: '3', xl: '4' }),
    [workingDaySection],
  );
  const hasAboutDoctorInfo = !!(
    doctor.aboutPractitionerInfo?.translation?.en || doctor.aboutPractitionerInfo?.translation?.ar
  );

  return (
    <>
      <div className="d-flex align-items-center mb-3">
        <img alt="doctor" className="img-thumbnail rounded-3" src={doctor.image?.url || defaultPhoto} width="80" />
        <div className="ms-4">
          <h3 className="mb-0">{getDoctorFullName(doctor, locale)}</h3>
          <p className="mb-0 text-secondary">{`${formatMessage({ id: 'CORE.LABEL.CLINICY-ID' })}  ${
            doctor.clinicyId
          }`}</p>
          {hasAboutDoctorInfo && (
            <>
              <div className="d-inline-flex align-items-center cursor-pointer" id="info-doctor-tooltip">
                <IconInfoCircle size={16} strokeWidth={1.4} />
                <span className="ms-1">
                  {formatMessage({ id: 'CORE.LABEL.ABOUT-DOCTOR', defaultMessage: 'About Practitioner' })}
                </span>
              </div>
              <UncontrolledTooltip target="info-doctor-tooltip">
                {doctor.aboutPractitionerInfo && getTranslation(doctor.aboutPractitionerInfo, locale)}
              </UncontrolledTooltip>
            </>
          )}
        </div>
      </div>
      <DescriptionList rowProps={sizeProps} items={items} />
    </>
  );
};
