import { ChangeEvent, useCallback, useMemo } from 'react';
import { debounce } from 'lodash';

import { Option } from 'app/types';
import { fetchDoctors } from 'app/redux/book-appointment/book-appointment.actions';
import { useAppDispatch, useAppSelector } from 'app/helpers';

interface Props {
  specialities: Option[];
  setFilteredSpecialities?: (options: Option[]) => void;
}

export const useFilterHandlers = ({ specialities, setFilteredSpecialities }: Props) => {
  const dispatch = useAppDispatch();
  const selectedBranch = useAppSelector((state) => state.bookAppointment.selectedBranch);

  const handleSpecialityFilter = useMemo(
    () =>
      debounce((value) => {
        if (setFilteredSpecialities) {
          setFilteredSpecialities(
            value
              ? specialities.filter((f: Option) => f.label.toLowerCase().includes(value.toLowerCase()))
              : specialities,
          );
        }
      }, 500),
    [specialities, setFilteredSpecialities],
  );

  const handlePractitionerFilter = useMemo(
    () =>
      debounce((value) => {
        if (selectedBranch) {
          dispatch(fetchDoctors(selectedBranch.value, value));
        }
      }, 500),
    [dispatch, selectedBranch],
  );

  const filterSpecialities = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      handleSpecialityFilter(event.target.value);
    },
    [handleSpecialityFilter],
  );

  const filterPractitioners = useCallback(
    (event: ChangeEvent, practitioner?: string) => {
      // check event - ignores InputComponent onBlur -> onChange redirection
      if (!!event) {
        handlePractitionerFilter(practitioner);
      }
    },
    [handlePractitionerFilter],
  );

  return {
    filterSpecialities,
    filterPractitioners,
  };
};
