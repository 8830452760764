import { ColumnFormatter } from 'react-bootstrap-table-next';
import { InvoiceLogItem } from 'app/types';

export const actionTakerFormatter: ColumnFormatter<InvoiceLogItem> | undefined = (cell, row) => {
  return (
    <>
      <span className="d-block">{row.actionTaker.label}</span>
      {row.actionTaker.clinicyId}
    </>
  );
};
