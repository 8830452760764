import { Button } from 'reactstrap';
import { useAppIntl, useAppSelector } from 'app/helpers';

interface Props {
  bookWithNoTime: () => void;
  hasBookWithNoTime: boolean;
}

export const TimeFormatterFooter = ({ bookWithNoTime, hasBookWithNoTime }: Props) => {
  const { formatMessage } = useAppIntl();
  const isFullEdit = useAppSelector((state) => state.appointment.fullEdit.isActive);

  return (
    <div className="timeFormatter__footer">
      {hasBookWithNoTime && (
        <Button color="primary-gradient" disabled={isFullEdit} onClick={bookWithNoTime} size="sm">
          {formatMessage({ id: 'APPOINTMENTS.TEXT.ADD-NO-TIME-APPOINTMENT' })}
        </Button>
      )}
    </div>
  );
};
