import { CalendarWeekParams } from '../types';
import { CalendarItem, CalendarWeek } from 'app/types';

export const updateTableViewItems = (items: CalendarItem[], payload: CalendarWeek[], params: CalendarWeekParams[]) => {
  return items.map((item) => {
    // Search the list of updated CalendarWeek data for item's week
    const week = payload.find(
      (w) => w.branchId === item.branch.id && w.doctorId === item.doctor.id && w.productId === item.product.value,
    );
    if (week) {
      // Check if update request match
      const requiresUpdate = params.some(
        (p) =>
          p.doctorId === item.doctor.id &&
          p.branchId === item.branch.id &&
          p.productRelatedFilters.productId === item.product.value,
      );

      if (requiresUpdate) {
        return {
          ...item,
          days: week.days,
        };
      }
    }

    return item;
  });
};
