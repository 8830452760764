import qs from 'qs';
import { useCallback } from 'react';
import { updateIntl } from 'react-intl-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { translations } from 'i18n/index';
import { Locale } from 'app/types';
import { useAppIntl } from 'app/helpers/useAppIntl/useAppIntl';
import { useAppDispatch } from 'app/helpers/useTypedHooks/useTypedHooks';

export const useChangeLocale = () => {
  const { locale } = useAppIntl();
  const history = useHistory();
  const { search } = useLocation();
  const dispatch = useAppDispatch();

  return useCallback(
    (newLocale: Locale | string) => {
      const isSameLocale = locale === newLocale;
      const isSupportedLocale = [Locale.en, Locale.ar].some((locale) => locale === newLocale);
      const urlParams = qs.parse(search, { ignoreQueryPrefix: true });

      if (isSameLocale || !isSupportedLocale) {
        // There is no need to update locale
        return;
      }

      if (urlParams.locale) {
        // We need to update the URL parameter
        const newSearch = qs.stringify({ ...urlParams, locale: newLocale });
        history.replace({ search: newSearch });
      }

      dispatch(
        updateIntl({
          locale: newLocale,
          // @ts-ignore
          messages: translations[newLocale],
        }),
      );
    },
    [dispatch, history, locale, search],
  );
};
