import { useMemo } from 'react';
import { InvoiceItem } from 'app/types';
import { useAppSelector } from 'app/helpers';
import { FORMS_INVOICING_PROCESS_PAYMENT_MODE } from 'app/shared';
import { ExtendedItem } from 'app/features/invoicingProcess/process/items/types';
import { PaymentModeFormData } from 'app/features/invoicingProcess/process/paymentMode/form/types';
import { generateExtendedItems } from 'app/features/invoicingProcess/process/items/helpers/generateExtendedItems';

export const useExtendedItems = (items: InvoiceItem[]): ExtendedItem[] => {
  const invoiceType = useAppSelector((state) => state.invoicingProcess.data?.invoiceType?.value);
  const paymentModeValues: Partial<PaymentModeFormData> | undefined = useAppSelector(
    (state) => state.form[FORMS_INVOICING_PROCESS_PAYMENT_MODE],
  )?.values;
  const isPrimaryCareSpeciality = useAppSelector(
    (state) => !!state.invoicingProcess.data?.doctor?.details?.isPrimaryCareSpeciality,
  );

  return useMemo<ExtendedItem[]>(() => {
    if (paymentModeValues) {
      return generateExtendedItems({
        invoiceType,
        isPrimaryCareSpeciality,
        items,
        paymentModeValues,
      });
    }

    return [];
  }, [paymentModeValues, invoiceType, isPrimaryCareSpeciality, items]);
};
