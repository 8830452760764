import React, { useMemo } from 'react';
import { Badge } from 'reactstrap';
import {
  AppointmentStatus,
  BillingItemPaymentStatus,
  BranchStatus,
  DoctorStatus,
  InsuranceApprovalsActionTarget,
  InsuranceApprovalStatus,
  InsuranceClaimSettlementStatus,
  InsuranceClaimsStatus,
  InsuranceContractEntityStatus,
  InsurancePoliciesClassStatus,
  InsurancePoliciesStatus,
  InsuranceReferralStatus,
  InsuranceStatus,
  InvoicesRequestStatus,
  InvoiceStatus,
  MassAddingFilesStatus,
  MassAddingFilesUploadingStatus,
  MedicalInstitutionStatus,
  Option,
  OrderStatus,
  PatientElementTypeStatus,
  PatientSourceStatus,
  PatientStatus,
  ReferralStatus,
  Status,
  VerificationStatus,
} from 'app/types';
import { getTranslation, useAppIntl } from 'app/helpers';
import { ExclusionStatus } from 'app/types/Exclusions';
import { InsuranceBillingItemsContractStatus } from 'app/types/InsuranceBillingItemsContract';

interface StatusBadgeProps {
  status: Option<Status>;
}

export const StatusBadge = ({ status }: StatusBadgeProps) => {
  const { locale } = useAppIntl();
  const value = status.value;

  const color = useMemo(() => {
    if (value === Status.Enabled) {
      return 'light-success';
    }
    if (value === Status.Disabled) {
      return 'light-danger';
    }

    return;
  }, [value]);

  return <Badge color={color}>{getTranslation(status, locale)}</Badge>;
};

interface ActivityStatusBadgeProps {
  status: boolean | null | undefined;
  type?: 'enabled-disabled' | 'yes-no';
  className?: string;
}

export const ActivityStatusBadge = ({ status, type = 'yes-no', className }: ActivityStatusBadgeProps) => {
  const { formatMessage } = useAppIntl();

  if (type === 'enabled-disabled' && typeof status === 'boolean') {
    return (
      <Badge color={status ? 'light-success' : 'light-danger'} className={className}>
        {formatMessage({ id: status ? 'CORE.TEXT.ENABLED' : 'CORE.TEXT.DISABLED' })}
      </Badge>
    );
  }

  if (type === 'yes-no' && typeof status === 'boolean') {
    return (
      <Badge color={status ? 'light-success' : 'light-danger'} className={className}>
        {formatMessage({ id: status ? 'CORE.LABEL.YES' : 'CORE.LABEL.NO' })}
      </Badge>
    );
  }

  // [?]: StatusType: YesNo and status: null or undefined
  return (
    <Badge color="light-secondary" className={className}>
      {formatMessage({ id: 'CORE.LABEL.NA' })}
    </Badge>
  );
};

interface AvailabilityStatusBadgeProps {
  status: boolean | number;
  className?: string;
}

export const AvailabilityStatusBadge = ({ status, className }: AvailabilityStatusBadgeProps) => {
  const { formatMessage } = useAppIntl();
  const statusValue = !!status;
  const messageId = statusValue ? 'CORE.LABEL.IN-STOCK' : 'CORE.LABEL.OUT-OF-STOCK';

  const color = useMemo(() => {
    if (statusValue) {
      return 'light-success';
    } else {
      return 'light-secondary';
    }
  }, [statusValue]);

  return (
    <Badge color={color} className={className}>
      {formatMessage({ id: messageId })}
    </Badge>
  );
};

export const InsuranceStatusBadge = ({ status }: { status: boolean }) => {
  const { formatMessage } = useAppIntl();

  const color = useMemo(() => {
    if (status) {
      return 'light-success';
    }
    if (!status) {
      return 'light-danger';
    }

    return;
  }, [status]);

  return (
    <Badge color={color}>
      {status ? formatMessage({ id: 'CORE.LABEL.ACTIVATED' }) : formatMessage({ id: 'CORE.LABEL.NOT-ACTIVATED' })}
    </Badge>
  );
};

interface DoctorStatusBadgeProps {
  status: Option<DoctorStatus>;
}

export const DoctorStatusBadge = ({ status }: DoctorStatusBadgeProps) => {
  const { locale } = useAppIntl();
  const value = status.value;

  const color = useMemo(() => {
    if (value === DoctorStatus.Active) {
      return 'light-success';
    }
    if (value === DoctorStatus.Deleted) {
      return 'light-danger';
    }

    return;
  }, [value]);

  return <Badge color={color}>{getTranslation(status, locale)}</Badge>;
};

interface BillingStatusBadgeProps {
  status: Option<BillingItemPaymentStatus>;
}

export const BillingStatusBadge = ({ status }: BillingStatusBadgeProps) => {
  const { locale } = useAppIntl();
  const value = status?.value;

  const color = useMemo(() => {
    if (value === BillingItemPaymentStatus.None) {
      return 'light-secondary';
    }
    if (value === BillingItemPaymentStatus.ApprovedByInsurance || value === BillingItemPaymentStatus.InvoiceIssued) {
      return 'light-success';
    }
    if (
      value === BillingItemPaymentStatus.PendingApproval ||
      value === BillingItemPaymentStatus.PendingInvoice ||
      value === BillingItemPaymentStatus.PartiallyInvoiceIssued
    ) {
      return 'light-warning';
    }
    if (value === BillingItemPaymentStatus.RejectedByInsurance) {
      return 'light-danger';
    }

    return;
  }, [value]);

  return <Badge color={color}>{getTranslation(status, locale)}</Badge>;
};

interface InsuranceApprovalStatusBadgeProps {
  status: Option<InsuranceApprovalStatus>;
}

export const InsuranceApprovalStatusBadge = ({ status }: InsuranceApprovalStatusBadgeProps) => {
  const { locale } = useAppIntl();
  const value = status.value;

  const color = useMemo(() => {
    if (value === InsuranceApprovalStatus.NotSent) {
      return 'light-secondary';
    }
    if (value === InsuranceApprovalStatus.Sent) {
      return 'light-primary';
    }
    if (value === InsuranceApprovalStatus.Accepted) {
      return 'light-success';
    }
    if (value === InsuranceApprovalStatus.Rejected) {
      return 'light-danger';
    }

    return;
  }, [value]);

  return <Badge color={color}>{getTranslation(status, locale)}</Badge>;
};

interface InsuranceCompanyStatusBadgeProps {
  status: Option<InsuranceStatus>;
}

interface InsuranceReferralStatusBadgeProps {
  status: Option<InsuranceReferralStatus>;
}

export const InsuranceReferralStatusBadge = ({ status }: InsuranceReferralStatusBadgeProps) => {
  const { locale } = useAppIntl();
  const value = status.value;

  const color = useMemo(() => {
    if (value === InsuranceReferralStatus.PendingUse) {
      return 'light-warning';
    }
    if (value === InsuranceReferralStatus.PartiallyUsed) {
      return 'light-primary';
    }
    if (value === InsuranceReferralStatus.Accepted) {
      return 'light-success';
    }

    return 'light-danger';
  }, [value]);

  return <Badge color={color}>{getTranslation(status, locale)}</Badge>;
};

interface InsuranceCompanyStatusBadgeProps {
  status: Option<InsuranceStatus>;
}

export const InsuranceCompanyStatusBadge = ({ status }: InsuranceCompanyStatusBadgeProps) => {
  const { locale } = useAppIntl();
  const value = status.value;

  const color = useMemo(() => {
    if (value === InsuranceStatus.Enabled) {
      return 'light-success';
    }
    if (value === InsuranceStatus.Disabled) {
      return 'light-secondary';
    }
    if (value === InsuranceStatus.Deleted) {
      return 'light-danger';
    }

    return;
  }, [value]);

  return <Badge color={color}>{getTranslation(status, locale)}</Badge>;
};

interface InvoiceStatusBadgeProps {
  className?: string;
  status: Option<InvoiceStatus> | null;
}

export const InvoiceStatusBadge = ({ className, status }: InvoiceStatusBadgeProps) => {
  const { formatMessage, locale } = useAppIntl();
  const value = status?.value;

  const color = useMemo(() => {
    if (!value) {
      return 'light-secondary';
    }
    if (value === InvoiceStatus.Settled) {
      return 'light-success';
    }
    if (value === InvoiceStatus.PartiallySettled) {
      return 'light-warning';
    }
    if ([InvoiceStatus.NotSettled, InvoiceStatus.Canceled, InvoiceStatus.ClearedByCreditNote].includes(value)) {
      return 'light-danger';
    }

    return 'light-primary';
  }, [value]);

  const title = status
    ? getTranslation(status, locale)
    : formatMessage({ id: 'CORE.LABEL.DRAFT', defaultMessage: 'Draft' });

  return (
    <Badge className={className} color={color} title={title}>
      {title}
    </Badge>
  );
};

interface InvoiceRequestStatusBadgeProps {
  status: Option<InvoicesRequestStatus>;
}

export const InvoiceRequestStatusBadge = ({ status }: InvoiceRequestStatusBadgeProps) => {
  const { locale } = useAppIntl();
  const value = status.value;

  const color = useMemo(() => {
    if (value === InvoicesRequestStatus.Approved) {
      return 'light-success';
    }
    if (value === InvoicesRequestStatus.Pending) {
      return 'light-warning';
    }
    if (value === InvoicesRequestStatus.Discarded) {
      return 'light-danger';
    }

    return 'light-primary';
  }, [value]);

  return <Badge color={color}>{getTranslation(status, locale)}</Badge>;
};

interface AppointmentStatusBadgeProps {
  status: Option<AppointmentStatus>;
}

export const AppointmentStatusBadge = ({ status }: AppointmentStatusBadgeProps) => {
  const { locale } = useAppIntl();
  const value = status.value;

  const color = useMemo(() => {
    if ([AppointmentStatus.ConfirmedByStaff, AppointmentStatus.Confirmed, AppointmentStatus.Attended].includes(value)) {
      return 'light-success';
    }
    if (
      [
        AppointmentStatus.CancelledByAdmin,
        AppointmentStatus.CancelledByPatient,
        AppointmentStatus.CancelledByMedicalInstitution,
        AppointmentStatus.NoShow,
      ].includes(value)
    ) {
      return 'light-danger';
    }

    return 'light-secondary';
  }, [value]);

  return <Badge color={color}>{getTranslation(status, locale)}</Badge>;
};

interface ReferralStatusBadgeProps {
  status: Option<ReferralStatus>;
}

export const ReferralStatusBadge = ({ status }: ReferralStatusBadgeProps) => {
  const { locale } = useAppIntl();
  const value = status.value;

  const color = useMemo(() => {
    if (value === ReferralStatus.BookedAppointment) {
      return 'light-primary';
    }
    if (value === ReferralStatus.Active) {
      return 'light-success';
    }
    if (value === ReferralStatus.Discarded) {
      return 'light-danger';
    }

    return 'light-secondary';
  }, [value]);

  return <Badge color={color}>{getTranslation(status, locale)}</Badge>;
};

interface PatientStatusBadgeProps {
  status: Option<PatientStatus>;
}

export const PatientStatusBadge = ({ status }: PatientStatusBadgeProps) => {
  const { locale } = useAppIntl();
  const value = status.value;

  const color = useMemo(() => {
    if (value === PatientStatus.Deleted) {
      return 'light-danger';
    }
    if (value === PatientStatus.Blocked) {
      return 'light-secondary';
    }
    if (value === PatientStatus.Splitted) {
      return 'light-warning';
    }
    if (value === PatientStatus.Merged) {
      return 'light-primary';
    }

    return 'light-success';
  }, [value]);

  return <Badge color={color}>{getTranslation(status, locale)}</Badge>;
};

interface ApprovalTargetStatusBadgeProps {
  status: Option<InsuranceApprovalsActionTarget>;
}

export const InsuranceApprovalTargetStatusBadge = ({ status }: ApprovalTargetStatusBadgeProps) => {
  const { locale } = useAppIntl();
  const value = status.value;

  const color = useMemo(() => {
    if (value === InsuranceApprovalsActionTarget.ApprovalRequest) {
      return 'light-warning';
    }

    return 'light-success';
  }, [value]);

  return <Badge color={color}>{getTranslation(status, locale)}</Badge>;
};

interface ExclusionStatusBadgeProps {
  status: Option<ExclusionStatus>;
}

export const ExclusionStatusBadge = ({ status }: ExclusionStatusBadgeProps) => {
  const { locale } = useAppIntl();
  const value = status.value;

  const color = useMemo(() => {
    if (value === ExclusionStatus.Removed) {
      return 'light-danger';
    }

    if (value === ExclusionStatus.Deactivate) {
      return 'light-secondary';
    }

    return 'light-success';
  }, [value]);

  return <Badge color={color}>{getTranslation(status, locale)}</Badge>;
};

interface OrderStatusBadgeProps {
  status: Option<OrderStatus>;
}

export const OrderStatusBadge = ({ status }: OrderStatusBadgeProps) => {
  const { locale } = useAppIntl();
  const value = status.value;

  const color = useMemo(() => {
    if (value === OrderStatus.NotSend) {
      return 'light-secondary';
    }

    if (value === OrderStatus.Cancelled) {
      return 'light-danger';
    }

    if (value === OrderStatus.Done) {
      return 'light-success';
    }

    return 'light-warning';
  }, [value]);

  return <Badge color={color}>{getTranslation(status, locale)}</Badge>;
};

interface ClinicStatusBadgeProps {
  status: Option<MedicalInstitutionStatus>;
}

export const ClinicStatusBadge = ({ status }: ClinicStatusBadgeProps) => {
  const { locale } = useAppIntl();
  const value = status.value;

  const color = () => {
    if (value === MedicalInstitutionStatus.Blocked) {
      return 'light-warning';
    }
    if (value === MedicalInstitutionStatus.Deleted) {
      return 'light-danger';
    }
    if (value === MedicalInstitutionStatus.Offline) {
      return 'light-secondary';
    }

    return 'light-success';
  };

  return <Badge color={color()}>{getTranslation(status, locale)}</Badge>;
};

interface ContractStatusBadgeProps {
  status:
    | Option<InsuranceBillingItemsContractStatus | InsurancePoliciesStatus | InsuranceContractEntityStatus>
    | Option;
}

export const ContractStatusBadge = ({ status }: ContractStatusBadgeProps) => {
  const { locale } = useAppIntl();
  const value = status.value;

  const color = useMemo(() => {
    if (value === InsuranceBillingItemsContractStatus.Active) {
      return 'light-success';
    } else if (value === InsuranceBillingItemsContractStatus.Deactivate) {
      return 'light-secondary';
    }
    return 'light-danger';
  }, [value]);

  return <Badge color={color}>{getTranslation(status, locale)}</Badge>;
};

interface MassAddingFilesStatusBadgeProps {
  status: Option<MassAddingFilesStatus | MassAddingFilesUploadingStatus>;
}

export const MassAddingFilesStatusBadge = ({ status }: MassAddingFilesStatusBadgeProps) => {
  const { locale } = useAppIntl();
  const value = status.value;

  const color = () => {
    if (value === MassAddingFilesUploadingStatus.Finished || value === MassAddingFilesStatus.Processed) {
      return 'light-success';
    }
    if (value === MassAddingFilesStatus.Pending || value === MassAddingFilesUploadingStatus.Uploading) {
      return 'light-warning';
    }
    return 'light-secondary';
  };

  return <Badge color={color()}>{getTranslation(status, locale)}</Badge>;
};

interface PolicyClassStatusBadgeProps {
  status: Option<InsurancePoliciesClassStatus>;
}

export const PolicyClassStatusBadge = ({ status }: PolicyClassStatusBadgeProps) => {
  const { locale } = useAppIntl();
  const value = status.value;

  const color = useMemo(() => {
    if (value === InsurancePoliciesClassStatus.Active) {
      return 'light-success';
    }
    if (value === InsurancePoliciesClassStatus.Removed) {
      return 'light-danger';
    }
    return 'light-secondary';
  }, [value]);

  return <Badge color={color}>{getTranslation(status, locale)}</Badge>;
};

interface VerificationStatusBadgeProps {
  status: Option<VerificationStatus>;
}

export const VerificationStatusBadge = ({ status }: VerificationStatusBadgeProps) => {
  const { locale } = useAppIntl();
  const value = status.value;

  const color = useMemo(() => {
    if (value === VerificationStatus.requiresVerification) {
      return 'light-warning';
    }
    if (value === VerificationStatus.notVerified) {
      return 'light-danger';
    }
    if (value === VerificationStatus.idNotEnforced) {
      return 'light-secondary';
    }

    return 'light-success';
  }, [value]);
  return <Badge color={color}>{getTranslation(status, locale)}</Badge>;
};

interface PatientSourceStatusBadgeProps {
  status: Option<PatientSourceStatus>;
}

export const PatientSourceStatusBadge = ({ status }: PatientSourceStatusBadgeProps) => {
  const { locale } = useAppIntl();
  const value = status.value;

  const color = useMemo(() => {
    if (value === PatientSourceStatus.Active) {
      return 'light-success';
    }
    if (value === PatientSourceStatus.NotStarted) {
      return 'light-primary';
    }
    if (value === PatientSourceStatus.Paused) {
      return 'light-warning';
    }
    if (value === PatientSourceStatus.Expired) {
      return 'light-secondary';
    }

    return 'light-danger';
  }, [value]);

  return <Badge color={color}>{getTranslation(status, locale)}</Badge>;
};

interface TypeOfStatusesBadgeProps {
  status: Option<PatientElementTypeStatus>;
}

export const PatientLogElementStatusBadge = ({ status }: TypeOfStatusesBadgeProps) => {
  const { locale } = useAppIntl();
  const value = status.value;

  const color = useMemo(() => {
    if (value === PatientElementTypeStatus.Active) {
      return 'light-success';
    }
    if (value === PatientElementTypeStatus.Merged) {
      return 'light-primary';
    }
    if (value === PatientElementTypeStatus.Splitted) {
      return 'light-warning';
    }
    if (value === PatientElementTypeStatus.Blocked) {
      return 'light-secondary';
    }

    return 'light-danger';
  }, [value]);

  return <Badge color={color}>{getTranslation(status, locale)}</Badge>;
};

interface InsuranceActivityBadgeProps {
  status: Option<Status>;
}

export const InsuranceActivityBadge = ({ status }: InsuranceActivityBadgeProps) => {
  const { locale } = useAppIntl();

  const color = useMemo(() => {
    if (status.value === Status.Enabled) {
      return 'light-success';
    }
    if (status.value === Status.Disabled) {
      return 'light-danger';
    }
  }, [status]);
  return <Badge color={color}>{getTranslation(status, locale)}</Badge>;
};

interface BranchStatusBadgeProps {
  status: Option<BranchStatus>;
}

export const BranchStatusBadge = ({ status }: BranchStatusBadgeProps) => {
  const { locale } = useAppIntl();
  const value = status.value;

  const color = useMemo(() => {
    if (value === BranchStatus.Online) {
      return 'light-success';
    }
    if (value === BranchStatus.Offline) {
      return 'light-secondary';
    }
    if (value === BranchStatus.Blocked) {
      return 'light-warning';
    }

    return 'light-danger';
  }, [value]);

  return <Badge color={color}>{getTranslation(status, locale)}</Badge>;
};

interface ClaimsStatusBadgeProps {
  status: Option<InsuranceClaimsStatus>;
}

export const ClaimsStatusBadge = ({ status }: ClaimsStatusBadgeProps) => {
  const { locale } = useAppIntl();
  const value = status.value;

  const color = useMemo(() => {
    if (value === InsuranceClaimsStatus.Completed) {
      return 'light-success';
    }
    if (value === InsuranceClaimsStatus.ScanNotTaken || value === InsuranceClaimsStatus.LabSampleNotCollected) {
      return 'light-secondary';
    }

    return 'light-warning';
  }, [value]);

  return <Badge color={color}>{getTranslation(status, locale)}</Badge>;
};

interface ClaimSettlementStatusBadgeProps {
  status: Option<InsuranceClaimSettlementStatus>;
}

export const ClaimSettlementStatusBadge = ({ status }: ClaimSettlementStatusBadgeProps) => {
  const { locale } = useAppIntl();
  const value = status.value;

  const color = useMemo(() => {
    if (value === InsuranceClaimSettlementStatus.Approved) {
      return 'light-success';
    }
    if (
      value === InsuranceClaimSettlementStatus.PartiallyApproved ||
      value === InsuranceClaimSettlementStatus.ConsolidatedSettlement
    ) {
      return 'light-warning';
    }
    if (value === InsuranceClaimSettlementStatus.NotDefined) {
      return 'light-secondary';
    }

    return 'light-danger';
  }, [value]);

  return <Badge color={color}>{getTranslation(status, locale)}</Badge>;
};
