import { takeEvery, put, select } from 'redux-saga/effects';
import {
  REQUEST_APPROVE_SUCCESS,
  REQUEST_CANCEL_SUCCESS,
  REQUEST_EDIT_SUCCESS,
  REQUEST_REJECT_SUCCESS,
} from 'app/redux/requests/requests.types';
import { fetchRequests } from 'app/redux/requests/requests.actions';
import { toggleModal } from 'app/redux/modals/modals.actions';

export function* afterRequestChange() {
  const {
    requests: {
      main: { params },
    },
  } = yield select();

  yield put(fetchRequests(params));
}
export function* afterRequestEdit() {
  yield put(toggleModal('editClinicRequest', false));
  yield put(toggleModal('editBranchRequest', false));
}

function* clinicSaga() {
  yield takeEvery([REQUEST_APPROVE_SUCCESS, REQUEST_CANCEL_SUCCESS, REQUEST_REJECT_SUCCESS], afterRequestChange);
  yield takeEvery(REQUEST_EDIT_SUCCESS, afterRequestEdit);
}

export default clinicSaga;
