import { AnyAction } from 'redux';
import moment from 'moment';
import { AppointmentsSummary, RemoteData, UserPerformanceAppointmentsSummary } from 'app/types';
import * as types from 'app/redux/dashboard/dashboard.types';

interface DashboardState {
  minDate: Date;
  appointmentsSummary: RemoteData<AppointmentsSummary | null>;
  usersPerformanceAppointmentsSummary: RemoteData<UserPerformanceAppointmentsSummary | null>;
}

const initialState: DashboardState = {
  minDate: moment().startOf('day').toDate(),
  appointmentsSummary: {
    data: null,
    error: false,
    loading: false,
  },
  usersPerformanceAppointmentsSummary: {
    data: null,
    error: false,
    loading: false,
  },
};

export const dashboard = (state = initialState, action: AnyAction): DashboardState => {
  const isSilent = action.meta?.isSilent;

  switch (action.type) {
    case types.FETCH_MIN_DATE_SUCCESS:
      return {
        ...state,
        minDate: moment(action.payload.data).toDate(),
      };
    case types.FETCH_APPOINTMENTS_SUMMARY_REQUEST:
      return {
        ...state,
        ...(!isSilent && {
          appointmentsSummary: {
            ...state.appointmentsSummary,
            data: initialState.appointmentsSummary.data,
            loading: true,
          },
        }),
      };
    case types.FETCH_APPOINTMENTS_SUMMARY_SUCCESS:
      return {
        ...state,
        appointmentsSummary: {
          ...state.appointmentsSummary,
          data: action.payload.data,
          loading: false,
          error: false,
        },
      };
    case types.FETCH_APPOINTMENTS_SUMMARY_FAILURE:
      return {
        ...state,
        appointmentsSummary: {
          ...state.appointmentsSummary,
          error: true,
          loading: false,
        },
      };
    case types.FETCH_USER_PERFORMANCE_REQUEST:
      return {
        ...state,
        ...(!isSilent && {
          usersPerformanceAppointmentsSummary: {
            ...state.usersPerformanceAppointmentsSummary,
            data: initialState.usersPerformanceAppointmentsSummary.data,
            loading: true,
          },
        }),
      };
    case types.FETCH_USER_PERFORMANCE_SUCCESS:
      return {
        ...state,
        usersPerformanceAppointmentsSummary: {
          ...state.usersPerformanceAppointmentsSummary,
          data: action.payload.data,
          loading: false,
          error: false,
        },
      };
    case types.FETCH_USER_PERFORMANCE_FAILURE:
      return {
        ...state,
        usersPerformanceAppointmentsSummary: {
          ...state.usersPerformanceAppointmentsSummary,
          error: true,
          loading: false,
        },
      };
    default:
      return state;
  }
};
