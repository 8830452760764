import { RSAA } from 'redux-api-middleware';

import * as types from './insurance.types';

export const addInsurance = (values) => ({
  [RSAA]: {
    endpoint: '/insurance-companies',
    method: 'POST',
    types: [
      types.ADD_INSURANCE_REQUEST,
      {
        type: types.ADD_INSURANCE_SUCCESS,
        meta: {
          notification: {
            type: 'success',
            title: 'INSURANCES.TEXT.ADD-SUCCESS',
          },
        },
      },
      types.ADD_INSURANCE_FAILURE,
    ],
    body: values,
  },
});

export const editInsurance = (values, companyId) => ({
  [RSAA]: {
    endpoint: `/insurance-companies/${companyId}`,
    method: 'PUT',
    types: [
      types.EDIT_INSURANCE_REQUEST,
      {
        type: types.EDIT_INSURANCE_SUCCESS,
        meta: {
          notification: {
            type: 'success',
            title: 'INSURANCES.TEXT.EDIT-SUCCESS',
          },
          companyId,
        },
      },
      types.EDIT_INSURANCE_FAILURE,
    ],
    body: values,
  },
});

export const fetchInsurance = (companyId) => ({
  [RSAA]: {
    endpoint: `/insurance-companies/${companyId}`,
    method: 'GET',
    types: [types.FETCH_INSURANCE_REQUEST, types.FETCH_INSURANCE_SUCCESS, types.FETCH_INSURANCE_FAILURE],
  },
});

export const exportInsurance = (endpoint, body) => ({
  [RSAA]: {
    endpoint,
    method: 'POST',
    body,
    types: [
      types.EXPORT_INSURANCE_REQUEST,
      {
        type: types.EXPORT_INSURANCE_SUCCESS,
        meta: {
          notification: {
            type: 'success',
            title: 'CORE.TEXT.EXPORT-IN-PROGRESS-CONFIRMATION',
          },
        },
      },
      {
        type: types.EXPORT_INSURANCE_FAILURE,
        meta: {
          notification: {
            type: 'danger',
            title: 'CORE.TEXT.DEFAULT-FAILURE-MESSAGE',
          },
        },
      },
    ],
  },
});
