import { useMemo } from 'react';
import { ColumnDescription } from 'react-bootstrap-table-next';
import { getTranslation, useAppIntl } from 'app/helpers';
import { AssessmentReferral } from 'app/redux/doctorAssessment/types';

const bodyStyle = { fontSize: '9px', color: 'black' };
const headerStyle = { fontSize: '10px', color: 'black' };

export const useReferralsColumns = () => {
  const { formatMessage, locale } = useAppIntl();

  return useMemo<ColumnDescription<AssessmentReferral>[]>(
    () => [
      {
        dataField: 'specialityReferredTo',
        text: formatMessage({ id: 'REFERRALS.TEXT.DEPARTMENT-REFERRED-TO' }),
        formatter: (_, row, __, { locale }) => getTranslation(row.specialityReferredTo, locale),
        formatExtraData: { locale },
        style: bodyStyle,
        headerStyle,
      },
      {
        dataField: 'comment',
        text: formatMessage({ id: 'REFERRALS.TEXT.REFERRAL-COMMENT' }),
        formatter: (_, row) => row.comment || '-',
        headerClasses: 'text-center',
        classes: 'text-center',
        style: bodyStyle,
        headerStyle,
      },
    ],
    [formatMessage, locale],
  );
};
