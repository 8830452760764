import * as types from './appointments.types';
import { PAGINATION_DEFAULT_PARAMS } from 'app/shared';

const initialParams = {
  ...PAGINATION_DEFAULT_PARAMS,
  order: 'date',
  orderAsc: true,
};

const initialState = {
  branch: {
    data: [],
    params: initialParams,
    loading: false,
  },
  confirmation: {
    data: null,
    loading: false,
  },
  checkInLoading: false,
};

export const appointmentsReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.APPOINTMENT_CHECKIN_REQUEST:
    case types.APPOINTMENT_UNCHECKIN_REQUEST:
      return {
        ...state,
        checkInLoading: true,
      };
    case types.APPOINTMENT_CHECKIN_FAILURE:
    case types.APPOINTMENT_UNCHECKIN_FAILURE:
    case types.APPOINTMENT_CHECKIN_SUCCESS:
    case types.APPOINTMENT_UNCHECKIN_SUCCESS:
      return {
        ...state,
        checkInLoading: false,
      };
    case types.FETCH_BRANCH_APPOINTMENTS_REQUEST:
      return {
        ...state,
        branch: {
          ...state.branch,
          data: initialState.branch.data,
          loading: true,
        },
      };
    case types.FETCH_BRANCH_APPOINTMENTS_SUCCESS:
      return {
        ...state,
        branch: {
          data: action.payload.data,
          params: {
            ...state.branch.params,
            ...action.payload.pagination,
          },
          loading: false,
        },
      };
    case types.FETCH_BRANCH_APPOINTMENTS_FAILURE:
      return {
        ...state,
        branch: {
          ...state.branch,
          loading: false,
        },
      };
    case types.FETCH_APPOINTMENT_CONFIRMATION_REQUEST:
      return {
        ...state,
        confirmation: {
          ...state.confirmation,
          loading: true,
        },
      };
    case types.FETCH_APPOINTMENT_CONFIRMATION_SUCCESS:
      return {
        ...state,
        confirmation: {
          data: action.payload.data,
          loading: false,
        },
      };
    case types.FETCH_APPOINTMENT_CONFIRMATION_FAILURE:
      return {
        ...state,
        confirmation: {
          ...state.confirmation,
          loading: false,
        },
      };
    default:
      return state;
  }
};
