import { useEffect, MutableRefObject } from 'react';
import { useDispatch } from 'react-redux';
import { toggleModal } from 'app/redux/modals/modals.actions';
import { ModalsState } from 'app/redux/modals/modals.reducer';

export interface ModalProps {
  close: () => void;
  destroy: () => void;
}

export const useModalCleaner = (modalName: keyof ModalsState, modalRef?: MutableRefObject<ModalProps | null>) => {
  const dispatch = useDispatch();

  useEffect(() => {
    if (modalRef?.current && modalRef.current.close && modalRef.current.destroy) {
      //PT-3766 when we have two or more the same modal on different pages the scrollbar is blocked and we have to remove old modal manually
      modalRef.current.destroy();
      modalRef.current.close();
    }
  }, [modalRef]);

  useEffect(() => {
    return () => {
      // Close the modal if it was not closed manually (e.g. after clicking the back/forward button in the browser)
      dispatch(toggleModal(modalName, false));
    };
  }, [dispatch, modalName]);
};
