import { put, select } from 'redux-saga/effects';
import { RootState } from 'app/types';
import { toggleModal } from 'app/redux/modals/modals.actions';
import { fetchTableViewItems } from 'app/redux/calendar/calendar.actions';
import { generateCalendarSearchParams, getFiltersFromAppointment } from 'app/sagas/apppointment/helpers';

export function* afterSetFullEdit() {
  const {
    appointment: { fullEdit },
  }: RootState = yield select();

  if (fullEdit.data) {
    // Close modals
    yield put(toggleModal('editAppointment', false));
    yield put(toggleModal('manageAppointments', false));
    yield put(toggleModal('bookAppointment', false));
    yield put(toggleModal('bookingWizard', false));

    // Prefetch the calendar data
    yield put<any>(fetchTableViewItems(generateCalendarSearchParams(getFiltersFromAppointment(fullEdit.data))));
  }
}
