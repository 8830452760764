import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQueryWithReAuth } from 'app/helpers';
import { PatientsArrival, QueryParams, QueryResponseWithPagination } from 'app/types';

export const patientsArrivalApi = createApi({
  reducerPath: 'patientsArrivalApi',
  baseQuery: baseQueryWithReAuth,
  tagTypes: ['Arrivals'],
  endpoints: (builder) => ({
    getArrivals: builder.query<QueryResponseWithPagination<PatientsArrival[]>, QueryParams | undefined>({
      query: (params) => ({
        url: `patient-arrivals/branches/${params?.branchId}`,
        params,
      }),
      providesTags: ['Arrivals'],
    }),
  }),
});

export const { useGetArrivalsQuery } = patientsArrivalApi;
