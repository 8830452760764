import { ErrorOther } from 'redux-form';
import {
  Option,
  PatientGender,
  PatientMaritalStatus,
  PatientOccupation,
  PatientPhoneNumber,
  PatientEmergencyContactRelation,
  PatientRelation,
  GovernmentIssuedIdType,
} from 'app/types';
import { PreferredLanguage } from 'app/shared';

export const PatientFormField = {
  // Names Ar
  firstNameAr: 'firstNameAr',
  fatherNameAr: 'fatherNameAr',
  grandFatherNameAr: 'grandFatherNameAr',
  familyNameAr: 'familyNameAr',
  preferredNameAr: 'preferredNameAr',

  // Names En
  firstNameEn: 'firstNameEn',
  fatherNameEn: 'fatherNameEn',
  grandFatherNameEn: 'grandFatherNameEn',
  familyNameEn: 'familyNameEn',
  preferredNameEn: 'preferredNameEn',

  // General Info
  internalPatientFileNumber: 'internalPatientFileNumber',
  gender: 'gender',
  dateOfBirth: 'dateOfBirth',
  nationality: 'nationality',
  typeOfGovernmentIssuedId: 'typeOfGovernmentIssuedId',
  governmentIssuedIdNumber: 'governmentIssuedIdNumber',
  city: 'city',
  preferredLanguage: 'preferredLanguage',
  maritalStatus: 'maritalStatus',
  otherMaritalStatus: 'otherMaritalStatus',
  occupation: 'occupation',
  patientClassification: 'patientClassification',
  email: 'email',

  // Emergency Info
  emergencyContactName: 'emergencyContactName',
  emergencyContactRelation: 'emergencyContactRelation',
  otherEmergencyContactRelation: 'otherEmergencyContactRelation',
  emergencyContactNumber: 'emergencyContactNumber',

  // Additional Info
  sourceOfPatient: 'sourceOfPatient',
  generalNote: 'generalNote',

  // Numbers
  patientMobileNumbers: 'patientMobileNumbers',

  // Primary Patients
  primaryPatients: 'primaryPatients',
} as const;

export interface PatientFormData {
  // Names Ar
  [PatientFormField.firstNameAr]: string;
  [PatientFormField.fatherNameAr]: string | null;
  [PatientFormField.grandFatherNameAr]: string | null;
  [PatientFormField.familyNameAr]: string;
  [PatientFormField.preferredNameAr]: string | null;

  // Names En
  [PatientFormField.firstNameEn]: string;
  [PatientFormField.fatherNameEn]: string | null;
  [PatientFormField.grandFatherNameEn]: string | null;
  [PatientFormField.familyNameEn]: string;
  [PatientFormField.preferredNameEn]: string | null;

  // General Info
  [PatientFormField.internalPatientFileNumber]?: string | null;
  [PatientFormField.gender]: Option<PatientGender> | null;
  [PatientFormField.dateOfBirth]: Date | null;
  [PatientFormField.nationality]: Option | null; // TODO: Precise Type
  [PatientFormField.typeOfGovernmentIssuedId]?: Option<GovernmentIssuedIdType> | null; // TODO: Precise Type
  [PatientFormField.governmentIssuedIdNumber]?: string | null;
  [PatientFormField.city]: Option | null;
  [PatientFormField.preferredLanguage]: Option<PreferredLanguage> | null;
  [PatientFormField.maritalStatus]: Option<PatientMaritalStatus> | null;
  [PatientFormField.otherMaritalStatus]?: string;
  [PatientFormField.occupation]: Option<PatientOccupation> | null;
  [PatientFormField.patientClassification]: Option | null; // TODO: Precise Type (P012-05 Patient Classification Set-up)
  [PatientFormField.email]: string | null;

  // Emergency Info
  [PatientFormField.emergencyContactName]: string | null;
  [PatientFormField.emergencyContactRelation]: Option<PatientEmergencyContactRelation> | null;
  [PatientFormField.otherEmergencyContactRelation]?: string;
  [PatientFormField.emergencyContactNumber]: string | null;

  // Additional Info
  [PatientFormField.sourceOfPatient]?: Option | null;
  [PatientFormField.generalNote]?: string | null;

  // Numbers
  [PatientFormField.patientMobileNumbers]: PatientPhoneNumber[];

  // Primary Patients
  [PatientFormField.primaryPatients]?: PrimaryPatientBaseInfo[];
}

export type PatientFormErrorType = string | ErrorOther | undefined;

export type PrimaryPatientBaseInfo = {
  clinicyMrn: string;
  id: string;
  fullNameArabic: string;
  fullNameEnglish: string;
  relation?: Option<PatientRelation>;
  otherRelationText?: string;
};

export type ValidatorType = {
  isGrandFatherNameRequired: boolean;
  isFatherNameRequired: boolean;
  isNationalIdRequired?: boolean;
};

export type NamesSectionValues = Pick<
  PatientFormData,
  | 'firstNameAr'
  | 'familyNameAr'
  | 'fatherNameAr'
  | 'grandFatherNameAr'
  | 'firstNameEn'
  | 'familyNameEn'
  | 'fatherNameEn'
  | 'grandFatherNameEn'
>;
