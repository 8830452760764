import { BillingItemType, OrderLink } from 'app/types';
import { AssessmentOrdersFormData } from 'app/assessment/ordersSection/forms/ordersForm/types';
import { LocationParams } from 'app/assessment/helpers/medicalProfileValuesAdapter';
import { OrderItemParams } from 'app/services/order/types';

export interface AssessmentOrderParams {
  invoicePackageSnapshotId: string | null;
  orderId: string | null;
  billingItemType: BillingItemType;
  details: string | null;
  links: OrderLink[];
  orderItem: OrderItemParams;
  locations: LocationParams[];
  attachmentFileIds?: string[];
}

interface OrdersSectionParams {
  assessmentOrders: AssessmentOrderParams[] | null;
}

export const ordersValuesAdapter = (values: AssessmentOrdersFormData | undefined): OrdersSectionParams => ({
  assessmentOrders:
    values?.assessmentOrders?.map(
      (i): AssessmentOrderParams => ({
        invoicePackageSnapshotId: i.invoicePackageSnapshotId || null,
        orderItem: {
          id: i.id || undefined,
          billingItemType: i.billingItemType.value,
          performerBillingItemId: i.performerBillingItemId!,
          quantity: i.quantity,
          invoicePackageItemSnapshotId: i.invoicePackageItemSnapshotId,
        },
        orderId: i.id || null,
        billingItemType: i.billingItemType.value,
        details: i.details || null,
        links: i.links || [],
        locations:
          i.locations?.map(
            (note): LocationParams => ({
              id: null,
              status: null,
              lookAt: note.lookAt,
              description: note.description,
              title: note.title,
              cameraPosition: note.cameraPosition,
              element: note.element,
              locationTemplate: note.template,
            }),
          ) || [],
        attachmentFileIds: i.attachments?.filter((file) => !!file.id).map((item) => item.id),
      }),
    ) || null,
});
