import { ReactNode } from 'react';
import classnames from 'classnames';
import { useCurrentUser } from 'app/helpers';
import { Header } from '../header/header';
import { Menu } from '../menu/menu';
import './layout.scss';

interface Props {
  children: ReactNode;
}

export const Layout = ({ children }: Props) => {
  const { isAuthenticated } = useCurrentUser();

  return (
    <div className="layout fadeIn" id="layout">
      {isAuthenticated && <Menu />}
      <div
        className={classnames('layout__content', {
          'layout__content--withMenu': isAuthenticated,
        })}
      >
        {isAuthenticated && <Header />}
        {children}
      </div>
    </div>
  );
};
