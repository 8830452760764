export const FETCH_INSURANCE_REQUEST = 'insurance/FETCH_INSURANCE_REQUEST';
export const FETCH_INSURANCE_SUCCESS = 'insurance/FETCH_INSURANCE_SUCCESS';
export const FETCH_INSURANCE_FAILURE = 'insurance/FETCH_INSURANCE_FAILURE';

export const EXPORT_INSURANCE_REQUEST = 'insurance/EXPORT_INSURANCE_REQUEST';
export const EXPORT_INSURANCE_SUCCESS = 'insurance/EXPORT_INSURANCE_SUCCESS';
export const EXPORT_INSURANCE_FAILURE = 'insurance/EXPORT_INSURANCE_FAILURE';

export const ADD_INSURANCE_REQUEST = 'insurance/ADD_INSURANCE_REQUEST';
export const ADD_INSURANCE_SUCCESS = 'insurance/ADD_INSURANCE_SUCCESS';
export const ADD_INSURANCE_FAILURE = 'insurance/ADD_INSURANCE_FAILURE';

export const EDIT_INSURANCE_REQUEST = 'insurance/EDIT_INSURANCE_REQUEST';
export const EDIT_INSURANCE_SUCCESS = 'insurance/EDIT_INSURANCE_SUCCESS';
export const EDIT_INSURANCE_FAILURE = 'insurance/EDIT_INSURANCE_FAILURE';

export const DELETE_INSURANCE_REQUEST = 'insurance/DELETE_INSURANCE_REQUEST';
export const DELETE_INSURANCE_SUCCESS = 'insurance/DELETE_INSURANCE_SUCCESS';
export const DELETE_INSURANCE_FAILURE = 'insurance/DELETE_INSURANCE_FAILURE';
