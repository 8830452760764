import { Option } from 'app/types';

export interface BillingItemsFormData {
  keyword: string;
  specialities: Option[];
  practitioners: Option[];
  items: string;
  itemType: Option;
  saudiVatExempted: Option<boolean>;
}

export interface PractitionerBillingItemsPackageFormData {
  keyword: string;
  doctor: Option[];
}

export interface MedicationsFiltersFormData {
  keyword: string;
  practitioners: Option[];
  drugGroup: Option;
  drugType: Option;
  vatExempted: Option<boolean>;
}

export const saudiVatExemptedOptions = [
  {
    value: true,
    label: 'CORE.LABEL.YES',
  },
  {
    value: false,
    label: 'CORE.LABEL.NO',
  },
];

export type StatusChangeHandler = (id: string, newStatus: boolean) => void | Promise<any>;
