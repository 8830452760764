import { Option } from 'app/types/Option';
import { RemoteFile } from 'app/types/RemoteFile';
import { PaymentSource } from 'app/types/PaymentSource';
import { PatientBadges, PatientGender } from 'app/types/Patient';
import { SpecialtyFormType } from 'app/types/Speciality';

export interface PatientsArrival {
  age: number;
  appointmentNumber: string;
  appointmentStatus: Option;
  branch: Option;
  checkedIn: boolean;
  clinic: Option;
  clinicLogo: RemoteFile;
  doctor: Option;
  doctorImage: RemoteFile;
  doctorTitle: Option;
  end: string;
  gender: Option<PatientGender>;
  id: string; // Appointment ID
  invoiceId: string;
  isVerificationRequired: boolean;
  lastStatusUpdate: Date;
  nationality: Option;
  patient: Option;
  patientArrivalId: string;
  patientClinicyId: string;
  patientDayOfBirth: string;
  paymentSource: PaymentSource;
  product: Option;
  referralId: string | null;
  speciality: Option;
  specialityType: SpecialtyFormType;
  start: Date;
  status: Option<PatientArrivalStatusId>;
  subSpeciality: Option;
  patientBadges: PatientBadges;
  hasMergePatientsBadge: boolean;
}

//TODO: reduce it to once Status
export enum PatientArrivalStatusId {
  NotArrived = '0',
  Arrived = '1',
  CompletedAssessment = '2',
  InTreatment = '3',
  InInvoicing = '4',
  HaveLeft = '5',
}

export enum PatientArrivalStatus {
  NotArrived = 'notArrived',
  Arrived = 'arrived',
  CompletedAssessment = 'completedAssessment',
  InTreatment = 'inTreatment',
  InInvoicing = 'inInvoicing',
  HaveLeft = 'haveLeft',
}
