import { useEffect, useMemo } from 'react';
import { useAppIntl } from 'app/helpers';
import { LANGUAGES_AR_CODE } from 'app/shared';
import { createTawkToScript } from './helpers';

export const TawkToChat = () => {
  const { locale } = useAppIntl();

  const src = useMemo(
    () =>
      locale === LANGUAGES_AR_CODE ? process.env.REACT_APP_TAWK_CHAT_URL_AR : process.env.REACT_APP_TAWK_CHAT_URL_EN,
    [locale],
  );

  useEffect(() => {
    try {
      createTawkToScript(src);
    } catch (error) {
      console.error('tawk.to script could not be added');
    }
  }, [src]);

  return null;
};
