import * as types from './wallet.types';
import { PAGINATION_DEFAULT_PARAMS } from 'app/shared/constants/pagination.constants';

const initialState = {
  loading: true,
  error: false,
  list: [],
  params: {
    ...PAGINATION_DEFAULT_PARAMS,
  },
  summary: {
    walletId: '',
    currentBalance: '',
  },
  details: {
    data: {},
    loading: false,
    error: false,
  },
  transactionTicket: {
    data: [],
    loading: false,
    error: false,
  },
};

export const walletReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.FETCH_WALLET_DETAILS_REQUEST:
      return {
        ...state,
        list: initialState.list,
        loading: true,
      };
    case types.FETCH_WALLET_DETAILS_SUCCESS:
      return {
        ...state,
        list: action.payload.data,
        summary: action.payload.summary,
        params: {
          ...state.params,
          ...action.payload.pagination,
        },
        loading: false,
      };
    case types.FETCH_WALLET_DETAILS_FAILURE:
      return {
        ...state,
        loading: false,
        error: true,
      };
    case types.FETCH_TRANSACTION_DETAILS_REQUEST:
      return {
        ...state,
        details: {
          ...initialState.details,
          loading: true,
        },
      };

    case types.FETCH_TRANSACTION_DETAILS_SUCCESS:
      return {
        ...state,
        details: {
          data: action.payload,
          loading: false,
        },
      };
    case types.FETCH_TRANSACTION_DETAILS_FAILURE:
      return {
        ...state,
        details: {
          ...state.details,
          loading: false,
          error: true,
        },
      };

    case types.SET_PARAMS:
      return {
        ...state,
        params: {
          ...state.params,
          ...action.meta,
        },
      };
    case types.FETCH_TRANSACTION_TICKET_REQUEST:
      return {
        ...state,
        transactionTicket: {
          data: initialState.transactionTicket.data,
          loading: true,
        },
      };
    case types.FETCH_TRANSACTION_TICKET_SUCCESS:
      return {
        ...state,
        transactionTicket: {
          data: action.payload,
          loading: false,
        },
      };
    case types.FETCH_TRANSACTION_TICKET_FAILURE:
      return {
        ...state,
        transactionTicket: {
          ...state.transactionTicket,
          loading: false,
          error: true,
        },
      };
    default:
      return state;
  }
};
