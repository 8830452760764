import { AnyAction } from 'redux';
import { BillingItem } from 'app/types';
import * as types from 'app/redux/branchBillingItem/branchBillingItem.types';

interface BranchBillingItemReducer {
  data: BillingItem | null;
  loading: boolean;
}

const initialState: BranchBillingItemReducer = {
  data: null,
  loading: false,
};

export const branchBillingItemReducer = (state = initialState, action: AnyAction): BranchBillingItemReducer => {
  switch (action.type) {
    // Fetch Branch Billing Item
    case types.FETCH_BRANCH_BILLING_ITEM_REQUEST:
      return {
        ...state,
        data: initialState.data,
        loading: true,
      };
    case types.FETCH_BRANCH_BILLING_ITEM_SUCCESS:
      return {
        ...state,
        data: action.payload.data,
        loading: false,
      };
    case types.FETCH_BRANCH_BILLING_ITEM_FAILURE:
      return {
        ...state,
        data: initialState.data,
        loading: false,
      };

    // Default
    default:
      return state;
  }
};
