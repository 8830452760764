interface Props {
  age?: number;
  min?: number;
  max?: number;
}

export const isWithinAgeRestrictions = ({ age, min, max }: Props) => {
  if (typeof age === 'number') {
    if (!min && !max) {
      // no restrictions
      return true;
    } else if (min && max) {
      // range
      return age >= min && age <= max;
    } else if (min) {
      // above
      return age >= min;
    } else if (max) {
      // below
      return age <= max;
    }
  }
  return false;
};
