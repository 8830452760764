import { CalendarDay, CalendarWorkingTime, WorkingTimeAutomaticPromotionStatus } from 'app/types';

/**
 * Returns true if the selected Calendar Working Time contains an Appointment
 */
export const checkAppointments = (workingTime: CalendarWorkingTime): boolean => {
  return workingTime.slots.some((slot) => !!slot.overlappingAppointmentsIds.length);
};

/**
 * Returns true if the selected Calendar Working Time contains free slots
 */
export const checkAvailableSlots = (workingTime: CalendarWorkingTime): boolean => {
  return workingTime.slots.some((slot) => slot.overlappingAppointmentsIds.length === 0);
};

/**
 * Returns true if the selected Calendar Day contains free slots
 */
export const checkAvailableSlotsInDay = (day: CalendarDay): boolean => {
  return day.workingTimesWithSlots.some((workingTime) => checkAvailableSlots(workingTime));
};

/**
 * Returns true if the limit of Waiting Appointments for the selected Calendar Working Time has not been exceeded
 */
export const checkWaitingLimit = (workingTime: CalendarWorkingTime): boolean =>
  workingTime.waitingAppointmentsCount < workingTime.waitingAppointmentsLimit;

/**
 * Returns true if it is possible to create a Waiting Appointment for the selected Calendar Working Time
 */
export const isPossibleToCreateWaiting = (workingTime: CalendarWorkingTime): boolean =>
  checkWaitingLimit(workingTime) && !checkAvailableSlots(workingTime);

/**
 * Returns true if it is possible to create a Phantom Waiting Appointment for the selected Calendar Working Time
 */
export const isPossibleToCreatePhantomWaiting = (workingTime: CalendarWorkingTime): boolean =>
  workingTime.automaticPromotion.value === WorkingTimeAutomaticPromotionStatus.Disabled ||
  checkAppointments(workingTime);
