import { useCallback } from 'react';
import { useAppDispatch } from 'app/helpers';
import { DropdownOptionsParams } from 'app/redux/dropdownOptions/types';
import { fetchDropdownOptions } from 'app/redux/dropdownOptions/dropdownOptions.actions';

export const useFetchOptions = (insuranceId: string | undefined) => {
  const dispatch = useAppDispatch();

  const fetchIcd10CodeOptions = useCallback(
    (params: DropdownOptionsParams) => {
      return dispatch(fetchDropdownOptions('/diagnoses/fuzzy-search', { ...params, insuranceId }));
    },
    [dispatch, insuranceId],
  );

  return {
    fetchIcd10CodeOptions,
  };
};
