import { ChangeEvent, useCallback, useMemo } from 'react';
import { debounce } from 'lodash';

import { Option } from 'app/types';

interface Props {
  services: Option[];
  setFilteredServices: (options: Option[]) => void;
}

export const useFilterHandlers = ({ services, setFilteredServices }: Props) => {
  const handleServiceFilter = useMemo(
    () =>
      debounce((value?: string) => {
        setFilteredServices(
          value ? services.filter((s: Option) => s.label.toLowerCase().includes(value.toLowerCase())) : services,
        );
      }, 500),
    [services, setFilteredServices],
  );

  const filterServices = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      handleServiceFilter(event.target.value);
    },
    [handleServiceFilter],
  );

  return {
    filterServices,
  };
};
