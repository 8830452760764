import { Mutex } from 'async-mutex';
import type { BaseQueryFn, FetchArgs, FetchBaseQueryError } from '@reduxjs/toolkit/query';
import { Tokens } from 'app/redux/auth/types';
import { logout, setTokens } from 'app/redux/auth/auth.actions';
import { getRefreshToken, getToken } from 'app/redux/auth/helpers';
import { baseQueryWithoutAuth, baseQueryWithAuth } from 'app/helpers/baseQuery/baseQuery';

const mutex = new Mutex();

export const baseQueryWithReAuth: BaseQueryFn<string | FetchArgs, unknown, FetchBaseQueryError> = async (
  args,
  api,
  extraOptions,
) => {
  await mutex.waitForUnlock();
  let result = await baseQueryWithAuth(args, api, extraOptions);

  if (result.error && result.error.status === 401) {
    const refreshToken = getRefreshToken();
    const token = getToken();

    if (!mutex.isLocked() && token && refreshToken) {
      const release = await mutex.acquire();

      try {
        const refreshResult = await baseQueryWithoutAuth(
          { url: 'user/refresh-token', method: 'POST', body: { jwtToken: token, refreshToken } },
          api,
          extraOptions,
        );

        if (refreshResult.data) {
          const tokens = refreshResult.data as Tokens;
          // update tokens
          api.dispatch(setTokens(tokens));
          // retry the initial query
          result = await baseQueryWithAuth(args, api, extraOptions);
        } else {
          api.dispatch(logout());
        }
      } finally {
        release();
      }
    } else {
      await mutex.waitForUnlock();
      result = await baseQueryWithAuth(args, api, extraOptions);
    }
  }

  return result;
};
