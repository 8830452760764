import { v4 } from 'uuid';
import { Middleware } from 'redux';
import { isRSAA, RSAA } from 'redux-api-middleware';

export const requestMiddleware: Middleware<{}, { intl: { locale: string } }> = (store) => (next: any) => (action) => {
  if (!isRSAA(action)) {
    return next(action);
  }

  const { locale } = store.getState().intl;

  return next({
    [RSAA]: {
      ...action[RSAA],
      headers: {
        'Content-Security-Policy': "default-src 'self'",
        'X-Frame-Options': 'DENY',
        'Referrer-Policy': 'strict-origin-when-cross-origin',
        'Accept-Language': locale,
        'X-Correlation-ID': v4(),
        ...action[RSAA].headers,
      },
    },
  });
};
