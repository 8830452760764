export const FETCH_PATIENT_INVOICES_TO_PAY_REQUEST = 'patient-invoices/fetch/REQUEST';
export const FETCH_PATIENT_INVOICES_TO_PAY_SUCCESS = 'patient-invoices/fetch/SUCCESS';
export const FETCH_PATIENT_INVOICES_TO_PAY_FAILURE = 'patient-invoices/fetch/FAILURE';

export const SET_PATIENT_INVOICES_PARAMS = 'patient-invoices/SET_PARAMS';

export const ADD_INVOICE_PAYMENTS_REQUEST = 'invoice/ADD_INVOICE_PAYMENTS_REQUEST';
export const ADD_INVOICE_PAYMENTS_SUCCESS = 'invoice/ADD_INVOICE_PAYMENTS_SUCCESS';
export const ADD_INVOICE_PAYMENTS_FAILURE = 'invoice/ADD_INVOICE_PAYMENTS_FAILURE';

export const ADD_INVOICES_REQUEST = 'invoice/ADD_INVOICES_REQUEST';
export const ADD_INVOICES_SUCCESS = 'invoice/ADD_INVOICES_SUCCESS';
export const ADD_INVOICES_FAILURE = 'invoice/ADD_FINANCIAL_INVOICES_FAILURE';

export const PUT_INVOICES_DISCARD_REQUEST = 'invoice/PUT_INVOICES_DISCARD_REQUEST';
export const PUT_INVOICES_DISCARD_SUCCESS = 'invoice/PUT_INVOICES_DISCARD_SUCCESS';
export const PUT_FINANCIAL_INVOICES_DISCARD_FAILURE = 'invoice/PUT_FINANCIAL_INVOICES_DISCARD_FAILURE';

export const PUT_INVOICES_MOVE_REQUEST = 'invoice/PUT_INVOICES_MOVE_REQUEST';
export const PUT_INVOICES_MOVE_SUCCESS = 'invoice/PUT_INVOICES_MOVE_SUCCESS';
export const PUT_INVOICES_MOVE_FAILURE = 'invoice/PUT_INVOICES_MOVE_FAILURE';

export const EXPORT_INVOICES_REQUEST = 'invoice/EXPORT_INVOICES_REQUEST';
export const EXPORT_INVOICES_SUCCESS = 'invoice/EXPORT_INVOICES_SUCCESS';
export const EXPORT_INVOICES_FAILURE = 'invoice/EXPORT_INVOICES_FAILURE';

export const WS_UPDATE_INVOICE_LIST = 'invoices/WS_UPDATE_INVOICE_LIST';
