import {
  FETCH_CLINICS_PENDING_LIST_FAILURE,
  FETCH_CLINICS_PENDING_LIST_REQUEST,
  FETCH_CLINICS_PENDING_LIST_SUCCESS,
  SET_PARAMS,
} from './clinicsPending.types';
import { PAGINATION_DEFAULT_PARAMS } from 'app/shared';

const initialState = {
  loading: false,
  error: false,
  data: [],
  params: {
    status: ['pendingRegistrationApproval'],
    ...PAGINATION_DEFAULT_PARAMS,
  },
};

export const clinicsPendingReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_CLINICS_PENDING_LIST_REQUEST:
      return {
        ...state,
        data: [],
        loading: true,
      };
    case FETCH_CLINICS_PENDING_LIST_SUCCESS:
      return {
        ...state,
        data: action.payload.data,
        params: {
          ...state.params,
          ...action.payload.pagination,
        },
        loading: false,
      };
    case FETCH_CLINICS_PENDING_LIST_FAILURE:
      return {
        ...state,
        list: action.payload,
        loading: false,
        error: true,
      };
    case SET_PARAMS:
      return {
        ...state,
        params: {
          ...state.params,
          ...action.meta,
        },
      };
    default:
      return state;
  }
};
