import React, { CSSProperties, ReactNode, useMemo } from 'react';
import { UncontrolledTooltip } from 'reactstrap';
import classnames from 'classnames';
import { v4 } from 'uuid';
import { IconInfoCircle } from '@tabler/icons-react';
import { useAppIntl } from 'app/helpers';
import './box.scss';

interface BoxProps {
  children: ReactNode;
  className?: string;
  size?: 'sm' | 'md' | 'lg';
  style?: CSSProperties;
  onClick?: () => void;
}

interface BoxContentProps {
  children: ReactNode;
  className?: string;
  type: 'full' | 'fullWidth';
}

interface BoxHeaderProps {
  children?: ReactNode;
  className?: string;
  customMessage?: string;
  messageId?: string;
  headerHintMessageId?: string;
}

export const Box = ({ children, className, size, style, onClick }: BoxProps) => {
  return (
    <div style={style} className={classnames('box', { [`box--${size}`]: size }, className)} onClick={onClick}>
      {children}
    </div>
  );
};

export const BoxContent = ({ children, className, type }: BoxContentProps) => {
  return <div className={classnames('box__content', `box__content--${type}`, className)}>{children}</div>;
};

export const BoxHeader = ({ children, className, customMessage, messageId, headerHintMessageId }: BoxHeaderProps) => {
  const { formatMessage } = useAppIntl();
  const uniqueId = useMemo(() => `boxHeaderHint-${v4()}`, []);

  return (
    <div className={classnames('box__header', className)}>
      {(customMessage || messageId) && <h3>{customMessage ? customMessage : formatMessage({ id: messageId })}</h3>}
      {headerHintMessageId && (
        <div>
          <UncontrolledTooltip target={uniqueId}>{formatMessage({ id: headerHintMessageId })}</UncontrolledTooltip>
          <div className="text-secondary ms-1" id={uniqueId}>
            <IconInfoCircle size={18} strokeWidth={2.0} />
          </div>
        </div>
      )}
      <div className="flex-grow-1 text-right">{children}</div>
    </div>
  );
};
