import qs from 'qs';
import isEmpty from 'lodash/isEmpty';
import { initialize } from 'redux-form';
import { useEffect, useState } from 'react';
import { QueryParamsState } from 'app/redux/queryParams/types';
import { setQueryParams } from 'app/redux/queryParams/queryParams';
import { useAppDispatch } from 'app/helpers/useTypedHooks/useTypedHooks';
import { useChangeLocale } from 'app/helpers/useChangeLocale/useChangeLocale';
import { getFormValues } from 'app/helpers/useInitialValuesFromUrlParams/helpers/getFormValues';
import { getQueryParams } from 'app/helpers/useInitialValuesFromUrlParams/helpers/getQueryParams';

interface FieldOptions {
  dateFormat?: string;
}

export type FieldType = 'simple' | 'option' | 'options' | 'date' | 'timeRange';

export interface Field {
  name: string;
  options?: FieldOptions;
  type: FieldType;
}

interface Params {
  fields: Field[];
  formName?: string;
  ignoreLocale?: boolean;
  initialValues?: object;
  paramsName: keyof QueryParamsState;
}

export const useInitialValuesFromUrlParams = ({
  fields,
  formName,
  ignoreLocale,
  initialValues,
  paramsName,
}: Params) => {
  const changeLocale = useChangeLocale();
  const dispatch = useAppDispatch();
  const [initialized, setInitialized] = useState<boolean>(false);

  useEffect(() => {
    if (!initialized) {
      const search = window.location.search;

      if (search) {
        const urlParams = qs.parse(search, { ignoreQueryPrefix: true });

        // Update query params (Redux state)
        dispatch(setQueryParams({ name: paramsName, params: getQueryParams(fields, urlParams) }));

        if (formName) {
          // Set form state (search params are more important than initial values)
          dispatch(
            initialize(formName, {
              ...initialValues,
              ...getFormValues(fields, urlParams),
            }),
          );
        }

        if (!ignoreLocale && urlParams.locale && typeof urlParams.locale === 'string') {
          // Update locale
          changeLocale(urlParams.locale);
        }
      } else if (initialValues && !isEmpty(initialValues)) {
        if (formName) {
          // Set form state
          dispatch(initialize(formName, initialValues));
        }
      }

      setInitialized(true);
    }
  }, [changeLocale, dispatch, fields, formName, ignoreLocale, initialValues, initialized, paramsName]);
};
