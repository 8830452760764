import { useEffect, useMemo } from 'react';
import { useAppDispatch, useAppIntl, useAppSelector } from 'app/helpers';
import { fetchInitialAssessment } from 'app/redux/initialAssessmentWizard/initialAssessmentsWizard.actions';
import styles from 'app/assessment/medicalProfileSection/components/helpers/printVisitPractitionerAssessment/printVisitPractitionerAssessment.module.scss';
import { Col, Row } from 'reactstrap';

export const InitialAssessmentVitalsItems = () => {
  const dispatch = useAppDispatch();
  const { formatMessage } = useAppIntl();

  const initialAssessment = useAppSelector((state) => state.initialAssessmentWizard.initialAssessment);
  const data = useAppSelector((state) => state.treatment.generalTreatmentInformation.data);

  useEffect(() => {
    data && dispatch(fetchInitialAssessment(data.appointmentInformation.id, data.clinicInformation.clinicBranchId));
  }, [dispatch, data]);

  const items = useMemo(() => {
    if (!initialAssessment) {
      return [];
    }

    return [
      {
        label: formatMessage({ id: 'INITIAL-ASSESSMENT.LABEL.BLOOD-PRESSURE' }),
        value: initialAssessment.initialAssessmentPatientVitals.bloodPressureFirstPart
          ? `${initialAssessment.initialAssessmentPatientVitals.bloodPressureFirstPart}/${initialAssessment.initialAssessmentPatientVitals.bloodPressureSecondPart}`
          : undefined,
      },
      {
        label: formatMessage({ id: 'INITIAL-ASSESSMENT.LABEL.TEMPERATURE' }),
        value: initialAssessment.initialAssessmentPatientVitals.temperature,
      },
      {
        label: formatMessage({ id: 'INITIAL-ASSESSMENT.LABEL.PULSE' }),
        value: initialAssessment.initialAssessmentPatientVitals.pulse,
      },
      {
        label: formatMessage({ id: 'INITIAL-ASSESSMENT.LABEL.RESPIRATORY-RATE' }),
        value: initialAssessment.initialAssessmentPatientVitals.respiratoryRate,
      },
      {
        label: formatMessage({ id: 'INITIAL-ASSESSMENT.LABEL.OXYGEN-SATURATION' }),
        value: initialAssessment.initialAssessmentPatientVitals.oxygenSaturation,
      },
      {
        label: formatMessage({ id: 'INITIAL-ASSESSMENT.LABEL.PAIN' }),
        value: initialAssessment.initialAssessmentPatientVitals.pain,
      },
      {
        label: formatMessage({ id: 'INITIAL-ASSESSMENT.LABEL.HEAD-CIRCUMFERENCE' }),
        value: initialAssessment.initialAssessmentPatientVitals.headCircumference,
      },
    ].filter((item) => item.value);
  }, [formatMessage, initialAssessment]);

  return (
    <>
      {items.length > 0 && (
        <p className={styles.sectionTitle}>{formatMessage({ id: 'TREATMENT-PLAN-PAGE.TEXT.VITALS' })}</p>
      )}
      <Row xs={2} className={styles.sectionSubtitle}>
        {items.map((item, index) => (
          <Col key={index}>
            <p className={styles.sectionTitle}>{item.label}:</p>
            <p className={styles.sectionSubtitle}>{item.value || '-'}</p>
          </Col>
        ))}
      </Row>
    </>
  );
};
