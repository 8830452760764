import { AnyAction } from 'redux';
import { BillingPractitionerPackagesReducer, ModalMode } from 'app/redux/practitionerBillingPackages/types';
import { calculateOriginalTotalPrice, calculateTotalPrice } from 'app/redux/billingPackages/helpers/calculatePrice';
import * as types from 'app/redux/practitionerBillingPackages/practitionerBillingPackages.types';
import { changeItem } from 'app/redux/practitionerBillingPackages/helpers/changeItem';
import { updatedInsurances } from 'app/redux/practitionerBillingPackages/helpers/updatedInsurances';

const initialState: BillingPractitionerPackagesReducer = {
  modalMode: ModalMode.Add,
  data: null,
  totalPrice: 0,
  totalOriginalPrice: 0,
  loading: false,
  error: false,
};

export const billingPractitionerPackagesReducer = (
  state = initialState,
  action: AnyAction,
): BillingPractitionerPackagesReducer => {
  switch (action.type) {
    case types.FETCH_PACKAGE_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case types.FETCH_PACKAGE_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload.data,
        totalPrice: calculateTotalPrice(action.payload.data.packageItems),
        totalOriginalPrice: calculateOriginalTotalPrice(action.payload.data.packageItems),
      };
    case types.FETCH_PACKAGE_FAILURE:
      return {
        ...state,
        loading: false,
        error: true,
      };
    case types.EDIT_PACKAGE:
      return {
        ...state,
        modalMode: ModalMode.Edit,
        data: action.payload,
        totalPrice: calculateTotalPrice(action.payload.packageItems),
        totalOriginalPrice: calculateOriginalTotalPrice(action.payload.packageItems),
      };
    case types.SET_VIEW_MODE:
      return {
        ...state,
        modalMode: ModalMode.View,
        data: action.payload,
        totalPrice: calculateTotalPrice(action.payload.packageItems),
        totalOriginalPrice: calculateOriginalTotalPrice(action.payload.packageItems),
      };
    case types.CHANGE_ITEM:
      return changeItem(state, action.payload);
    case types.CHANGE_INSURANCE:
      return updatedInsurances(state, action.payload);
    case types.RESET_ITEMS:
      return {
        ...initialState,
      };

    // Default
    default:
      return state;
  }
};
