import { takeEvery } from 'redux-saga/effects';
import { afterSetCalendarType, wsUpdateCalendar } from './generators';
import { SET_CALENDAR_TYPE, WS_UPDATE_CALENDAR } from 'app/redux/calendar/calendar.types';

function* calendarSaga() {
  yield takeEvery(WS_UPDATE_CALENDAR, wsUpdateCalendar);
  yield takeEvery(SET_CALENDAR_TYPE, afterSetCalendarType);
}

export default calendarSaga;
