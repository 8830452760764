import classNames from 'classnames';
import { BranchDeclaration } from 'app/types';

interface Props {
  contentAr: BranchDeclaration['contentAr'] | undefined;
  contentEn: BranchDeclaration['contentEn'] | undefined;
}

export const DeclarationContent = ({ contentAr, contentEn }: Props) => {
  return (
    <>
      {contentAr && (
        <div
          className={classNames('small', {
            'mb-3': contentAr && contentEn,
          })}
          dir="rtl"
        >
          {contentAr}
        </div>
      )}
      {contentEn && <div className="small">{contentEn}</div>}
    </>
  );
};
