import isArray from 'lodash/isArray';
import { SubmissionError } from 'redux-form';

function errorsToObject(errors) {
  let object = {};

  if (isArray(errors.children)) {
    object = [];
  }

  for (let property of Object.keys(errors.children)) {
    if (errors.children[property].hasOwnProperty('children')) {
      object[property] = errorsToObject(errors.children[property]);

      if (!Object.keys(object[property]).length) {
        if (isArray(object)) {
          object.splice(property, 1);
        } else {
          delete object[property];
        }
      }
    } else if (errors.children[property].hasOwnProperty('errors')) {
      object[property] = errors.children[property].errors[0];
    }
  }

  return object;
}

export const validationMiddleware = () => (next) => (action) => {
  if (!action.error || !(action.payload instanceof Error)) {
    return next(action);
  }

  if (!action.payload.response) {
    return next(action);
  }

  if (action.payload.response.children) {
    next(action);
    throw new SubmissionError(errorsToObject(action.payload.response));
  }

  return next(action);
};
