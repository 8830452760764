import React, { Fragment, useCallback } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { FormFeedback, FormGroup, FormText, Input, InputGroup, InputGroupText } from 'reactstrap';
import classnames from 'classnames';
import { CustomLabel } from 'app/components/customLabel/customLabel';
import { useAppIntl } from 'app/helpers';
import './input.scss';

export const InputComponent = ({
  appendContent,
  className,
  disabled,
  id,
  info,
  input,
  isRequired,
  isRtl,
  label,
  max,
  maxLength,
  meta: { error, touched },
  min,
  minLength,
  noMargin,
  onFocusChange,
  onKeyDown,
  pattern,
  placeholder,
  readOnly,
  size,
  step,
  style,
  text,
  type,
}) => {
  const fieldId = id || input.name;
  const { isRtl: defaultIsRtl } = useAppIntl();
  const isRtlDir = isRtl === undefined ? defaultIsRtl : isRtl;
  const invalid = !!error && touched;

  const getDefaultMax = () => {
    const name = input.name.toLowerCase();
    if (name.includes('phone') || name.includes('mobile')) {
      return 50;
    } else if (input.name === 'keyword') {
      return 150;
    } else if (type === 'text' || type === 'email') {
      return 100;
    } else if (type === 'url') {
      return 5000;
    } else return 1500;
  };

  const handleOnBlur = (e) => {
    if (!!onFocusChange) {
      onFocusChange(e);
    }

    if (type === 'text' || type === 'password' || type === 'email' || type === 'textarea') {
      input.onBlur(e.target.value.trim());
    } else {
      input.onBlur(e.target.value);
    }
  };

  const handleOnPaste = (e) => {
    if (type === 'text') {
      const pastedData = e.clipboardData?.getData('Text');

      if (pastedData) {
        e.preventDefault();
        input.onChange(pastedData.trim());
      }
    }
  };

  const onWheel = useCallback(
    (e) => {
      if (type === 'number') {
        e.target.blur();
      }
    },
    [type],
  );

  maxLength = maxLength || getDefaultMax();

  return (
    <Fragment>
      <FormGroup noMargin={noMargin}>
        {label && <CustomLabel fieldId={fieldId} info={info} isRequired={isRequired} label={label} />}

        <InputGroup className={classnames('inputComponent', { 'inputComponent--isRtl': isRtlDir })}>
          <Input
            {...input}
            disabled={disabled}
            id={fieldId}
            invalid={invalid}
            max={max}
            maxLength={maxLength}
            min={min}
            minLength={minLength}
            onBlur={handleOnBlur}
            onKeyDown={onKeyDown}
            onPaste={handleOnPaste}
            onWheel={onWheel}
            pattern={pattern}
            placeholder={placeholder}
            readOnly={readOnly}
            bsSize={size}
            step={step}
            type={type}
            style={style}
            className={className}
          />
          {type !== 'textarea' &&
            appendContent &&
            (typeof appendContent === 'string' ? <InputGroupText>{appendContent}</InputGroupText> : appendContent)}
        </InputGroup>

        {invalid && (
          <FormFeedback className="d-block">
            <FormattedMessage id={error.message ? error.message : error} values={{ value: error.value }} />
          </FormFeedback>
        )}
        {input.value.length === maxLength && (
          <FormText>
            <FormattedMessage id="CORE.TEXT.MAX-LENGTH-MESSAGE" values={{ value: maxLength }} />
          </FormText>
        )}
        {!!text && <FormText>{text}</FormText>}
      </FormGroup>
    </Fragment>
  );
};

InputComponent.propTypes = {
  appendContent: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  disabled: PropTypes.bool,
  id: PropTypes.string,
  info: PropTypes.string,
  input: PropTypes.object.isRequired,
  isRequired: PropTypes.bool,
  label: PropTypes.string,
  max: PropTypes.number,
  meta: PropTypes.object.isRequired,
  min: PropTypes.number,
  noMargin: PropTypes.bool,
  onKeyDown: PropTypes.func,
  pattern: PropTypes.string,
  placeholder: PropTypes.string,
  readOnly: PropTypes.bool,
  size: PropTypes.string,
  step: PropTypes.number,
  text: PropTypes.string,
  type: PropTypes.string.isRequired,
};
