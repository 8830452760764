import remove from 'lodash/remove';
import unset from 'lodash/unset';

export const removeItemFromArrayById = (items, id) =>
  remove(items, function (item) {
    return item.id !== id;
  });

export const addNewItemToArray = (arr, item) => arr.concat([item]);

export const removePropertyFromObjectsInArray = (arr, path) => {
  if (arr && arr.length > 0) {
    arr.forEach((item) => unset(item, path));
    return arr;
  }
  return [];
};
