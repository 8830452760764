import { useMemo } from 'react';
import { useAppSelector } from 'app/helpers';
import { BookingFormData } from 'app/bookingWizardModal/types';
import { generateWorkingTimeOption } from 'app/bookingWizardModal/bookingWizard/helpers';
import { getAppointmentPatientFormData } from 'app/appointment/form/helpers';

export const useInitialValues = (isEdit: boolean): Partial<BookingFormData> | undefined => {
  const selectedTime = useAppSelector((state) => state.bookingWizard.selectedTime);
  const selectedWorkingTime = useAppSelector((state) => state.bookingWizard.selectedWorkingTime);
  const appointment = useAppSelector((state) => state.appointment.data || state.appointment.rebook.data);
  const fullEditActive = useAppSelector((state) => state.appointment.fullEdit.isActive);

  return useMemo(() => {
    if (selectedWorkingTime && !isEdit) {
      return {
        date: selectedTime.from,
        start: selectedTime.from,
        end: selectedTime.to,
        workingTime: generateWorkingTimeOption(selectedWorkingTime),
        isNoTime: !selectedTime.to,
      };
    } else if (selectedWorkingTime && isEdit && appointment) {
      return {
        date: selectedTime.from,
        start: selectedTime.from,
        end: selectedTime.to,
        workingTime: generateWorkingTimeOption(selectedWorkingTime),
        note: appointment.note,
        isNoTime: !selectedTime.to,
        patientSource: appointment.patientSource,
        ...getAppointmentPatientFormData(appointment),
      };
    } else if (!selectedWorkingTime && isEdit && appointment && appointment.isConflicting && !fullEditActive) {
      return {
        date: selectedTime.from,
        start: selectedTime.from,
        end: selectedTime.to,
        note: appointment.note,
        isNoTime: !selectedTime.to,
        patientSource: appointment.patientSource,
        ...getAppointmentPatientFormData(appointment),
      };
    }
    return undefined;
  }, [selectedTime, selectedWorkingTime, appointment, isEdit, fullEditActive]);
};
