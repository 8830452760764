import { RSAA } from 'redux-api-middleware';
import * as types from 'app/redux/bookingWizard/bookWizard.types';
import { Option } from 'app/types';
import { PatientInfoShort } from 'app/shared/patientSearch';
import { SelectedSlot, CalendarDayParams } from 'app/redux/calendar/types';
import { WizardStep, SlotParams, WorkingTime, InitialDataParams } from 'app/redux/bookingWizard/types';

export const fetchDoctor = (doctorId: string) => ({
  [RSAA]: {
    endpoint: `/doctors/${doctorId}`,
    method: 'GET',
    types: [types.FETCH_DOCTOR_REQUEST, types.FETCH_DOCTOR_SUCCESS, types.FETCH_DOCTOR_FAILURE],
  },
});

export const fetchDoctorWorkingTimes = (doctorId: string, branchId: string, date: string) => {
  return {
    [RSAA]: {
      endpoint: `/working-hours/available-for-booking-wizard`,
      method: 'GET',
      params: {
        branchId,
        doctorId,
        date,
      },
      types: [types.FETCH_WORKING_TIMES_REQUEST, types.FETCH_WORKING_TIMES_SUCCESS, types.FETCH_WORKING_TIMES_FAILURE],
    },
  };
};

export const fetchDoctorSubSpecialities = (workingTimeId: string, patientSourceId?: string) => ({
  [RSAA]: {
    endpoint: `/working-hours/${workingTimeId}/subSpecialities`,
    method: 'GET',
    params: { patientSourceId },
    types: [
      types.FETCH_SUB_SPECIALITIES_REQUEST,
      types.FETCH_SUB_SPECIALITIES_SUCCESS,
      types.FETCH_SUB_SPECIALITIES_FAILURE,
    ],
  },
});

export const fetchDoctorProducts = (workingTimeId: string, subSpecialityId: string, patientSourceId?: string) => {
  return {
    [RSAA]: {
      endpoint: `/working-hours/${workingTimeId}/sub-specialities/${subSpecialityId}/products-for-booking`,
      method: 'GET',
      params: { patientSourceId },
      types: [types.FETCH_PRODUCTS_REQUEST, types.FETCH_PRODUCTS_SUCCESS, types.FETCH_PRODUCTS_FAILURE],
    },
  };
};

export const fetchDay = (body: CalendarDayParams) => ({
  [RSAA]: {
    endpoint: `/calendar/day`,
    method: 'POST',
    types: [types.FETCH_DAY_REQUEST, types.FETCH_DAY_SUCCESS, types.FETCH_DAY_FAILURE],
    body,
  },
});

export const isSlotFree = (params: SlotParams) => ({
  [RSAA]: {
    endpoint: `/calendar/is-slot-free`,
    method: 'GET',
    params,
    types: [types.FETCH_IS_SLOT_FREE_REQUEST, types.FETCH_IS_SLOT_FREE_SUCCESS, types.FETCH_IS_SLOT_FREE_FAILURE],
  },
});

export const setInitialData = (data: InitialDataParams) => ({
  type: types.SET_INITIAL_DATA,
  meta: data,
});

export const setPatient = (payload: PatientInfoShort) => ({
  type: types.SET_PATIENT,
  payload,
});

export const setActiveStep = (step: WizardStep) => ({
  type: types.SET_ACTIVE_STEP,
  payload: step,
});

export const resetPatient = () => ({
  type: types.RESET_PATIENT,
});

export const resetBookingWizard = () => ({
  type: types.RESET,
});

export const selectDate = (date: Date) => ({
  type: types.SELECT_DATE,
  payload: date,
});

export const selectTime = (time: { from: Date; to?: Date }) => ({
  type: types.SELECT_TIME,
  payload: time,
});

export const selectWorkingTime = (value: WorkingTime | null, isSilent = false) => ({
  type: types.SELECT_WORKING_TIME,
  payload: { value, isSilent },
});

export const selectSubSpeciality = (option: Option | null) => ({
  type: types.SELECT_SUB_SPECIALITY,
  payload: option,
});

export const selectService = (option: Option | null) => ({
  type: types.SELECT_SERVICE,
  payload: option,
});

export const selectSlot = (slot: SelectedSlot | null) => ({
  type: types.SELECT_SLOT,
  payload: slot,
});
