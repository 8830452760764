import { useCallback } from 'react';
import { DropdownOptionsParams } from 'app/redux/dropdownOptions/types';
import { fetchDropdownOptions } from 'app/redux/dropdownOptions/dropdownOptions.actions';
import { useAppDispatch } from 'app/helpers';
import { PatientSourceStatus } from 'app/types';

export const useFetchOptions = (clinicId?: string) => {
  const dispatch = useAppDispatch();

  const fetchPatientCategories = useCallback(
    (params: DropdownOptionsParams) => {
      return dispatch(
        fetchDropdownOptions(
          !!clinicId ? `/clinics/${clinicId}/patient-categories/dropdown` : '/patient-categories/dropdown',
          params,
        ),
      );
    },
    [dispatch, clinicId],
  );

  const fetchPatientSources = useCallback(
    (params: DropdownOptionsParams) => {
      return dispatch(
        fetchDropdownOptions(`/clinics/${clinicId}/patient-sources/dropdown`, {
          ...params,
          statuses: [PatientSourceStatus.Active],
        }),
      );
    },
    [dispatch, clinicId],
  );

  return {
    fetchPatientCategories,
    fetchPatientSources,
  };
};
