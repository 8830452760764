import delay from 'lodash/delay';

export const scrollToCurrentTime = (isWeekView?: boolean) => {
  delay(() => {
    const timeContent = document.querySelector<HTMLElement>('.rbc-time-content');
    const timeIndicator = document.querySelector<HTMLElement>('.rbc-current-time-indicator');
    const todayHeader = document.querySelector<HTMLElement>('.rbc-header.rbc-today');

    if (timeContent) {
      let top = 0;
      let left = 0;

      if (timeIndicator) {
        // Get top offset
        top = timeIndicator.offsetTop - 100;
      }

      if (todayHeader) {
        // Get left offset
        const todayHeaderLeft = todayHeader.offsetLeft + todayHeader.offsetWidth;
        const timeContentLeft = timeContent.offsetLeft + timeContent.offsetWidth;

        if (todayHeaderLeft >= timeContentLeft + timeContent.scrollLeft) {
          // from left
          left = todayHeaderLeft - timeContentLeft;
        } else {
          // from right
          left = todayHeader.offsetLeft - timeContent.offsetLeft;
        }
      }

      timeContent.scrollTo({
        top,
        left: isWeekView ? left : undefined,
        behavior: 'smooth',
      });
    }
  }, 200);
};
