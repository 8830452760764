import { Action } from 'redux';
import { BillingInformation } from 'app/redux/doctorAssessment/doctorAssessment.actions';

export const WS_FETCH_COVERED_BILLING_ITEMS_STATUSES = 'saga/WS_FETCH_COVERED_BILLING_ITEMS_STATUSES';
export const WS_FETCH_NOT_COVERED_BILLING_ITEMS_STATUSES = 'saga/WS_FETCH_NOT_COVERED_BILLING_ITEMS_STATUSES';
export const WS_FETCH_ORDERS_STATUSES = 'saga/WS_FETCH_ORDERS_STATUSES';

export interface WsRefreshPayload {
  appointmentId: string;
  patientInsuranceId?: string;
  body: BillingInformation[];
}

export interface WsRefreshOrdersStatusesPayload {
  appointmentId: string;
}

export interface WsRefreshAction extends Action<string> {
  payload: WsRefreshPayload;
}

export interface WsRefreshOrdersStatusesAction extends Action<string> {
  payload: WsRefreshOrdersStatusesPayload;
}
