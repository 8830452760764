import classnames from 'classnames';
import toast from 'react-hot-toast';
import { IconCopy } from '@tabler/icons-react';
import copyToClipboard from 'copy-to-clipboard';
import { useCallback, useMemo, useRef, useState } from 'react';
import { useClickOutside, useCurrentUser } from 'app/helpers';
import { IconButton } from 'app/components/iconButton/iconButton';
import { generateStars, getCountryCodeLength } from './helpers';
import styles from './protectedPhoneNumber.module.scss';

interface Props {
  className?: string;
  phoneNumber: string | null;
  useUserConfiguration?: boolean;
  withoutProtection?: boolean;
}

export const ProtectedPhoneNumber = ({
  className,
  phoneNumber,
  useUserConfiguration = true,
  withoutProtection,
}: Props) => {
  const wrapperRef = useRef(null);
  const {
    currentUser: { allowedForNotEncryptedPhoneNumbers },
  } = useCurrentUser();
  const number = phoneNumber ? (phoneNumber.startsWith('+') ? phoneNumber : `+${phoneNumber}`) : '';

  const [isProtected, setIsProtected] = useState<boolean>(true);
  const hide = useCallback(() => setIsProtected(true), []);
  const show = useCallback(() => setIsProtected(false), []);

  const protectPhoneNumber = useMemo<boolean>(() => {
    if (withoutProtection) {
      return false;
    }

    return useUserConfiguration ? !allowedForNotEncryptedPhoneNumbers : true;
  }, [allowedForNotEncryptedPhoneNumbers, useUserConfiguration, withoutProtection]);

  const protectedNumber = useMemo<string>(() => {
    const leftPartOffset = getCountryCodeLength(number) + 1; // country code and "+"
    const rightPartOffset = 4;

    let leftPart = number.slice(0, leftPartOffset);
    let rightPart = number.slice(leftPartOffset);

    // Check if the phone number protection is possible
    if (rightPart.length > rightPartOffset) {
      rightPart = `${generateStars(rightPart.length - rightPartOffset)}${rightPart.slice(-rightPartOffset)}`;
      return `${leftPart}${rightPart}`;
    }

    // Phone number is too short to protect
    return number;
  }, [number]);

  const numberToDisplay = useMemo<string>(() => {
    if (!protectPhoneNumber || !isProtected) {
      return number;
    }

    return protectPhoneNumber ? protectedNumber : number;
  }, [isProtected, number, protectPhoneNumber, protectedNumber]);

  const copy = useCallback(() => {
    if (copyToClipboard(number.slice(1))) {
      toast.custom('CORE.TEXT.NUMBER-COPIED', { duration: 1800 });
    }
  }, [number]);

  useClickOutside(wrapperRef, hide);

  return (
    <div className={classnames(styles.wrapper, className)} ref={wrapperRef}>
      <span
        className={classnames('text-nowrap', {
          [styles.isProtected]: protectPhoneNumber && isProtected,
        })}
        dir="ltr"
        onClick={show}
      >
        {numberToDisplay || '-'}
      </span>
      {numberToDisplay && (
        <IconButton className={styles.copyButton} icon={<IconCopy size={18} strokeWidth={1.8} />} onClick={copy} />
      )}
    </div>
  );
};
