import { useCallback } from 'react';
import { DropdownOptionsParams } from 'app/redux/dropdownOptions/types';
import { fetchDropdownOptions } from 'app/redux/dropdownOptions/dropdownOptions.actions';
import { useAppDispatch } from 'app/helpers';
import { Option } from 'app/types';

interface Props {
  insuranceCompany?: Option;
  insurancePolicy?: Option;
}

export const useFetchOptions = ({ insuranceCompany, insurancePolicy }: Props) => {
  const dispatch = useAppDispatch();

  // Handlers
  const fetchInsurancePoliciesOptions = useCallback(
    (params: DropdownOptionsParams) => {
      if (insuranceCompany)
        return dispatch(
          fetchDropdownOptions(`insurance-companies/${insuranceCompany.value}/insurance-policies`, params),
        );
    },
    [dispatch, insuranceCompany],
  );

  const fetchInsuranceClassesOptions = useCallback(
    (params: DropdownOptionsParams) => {
      if (insurancePolicy)
        return dispatch(
          fetchDropdownOptions(`insurance-classes/dropdown`, { ...params, insurancePolicyId: insurancePolicy.value }),
        );
    },
    [dispatch, insurancePolicy],
  );

  return {
    fetchInsurancePoliciesOptions,
    fetchInsuranceClassesOptions,
  };
};
