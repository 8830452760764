import { CalendarWeekParams, LoadingItem } from '../types';

export const addToLoadingItems = (items: LoadingItem[], params: CalendarWeekParams): LoadingItem[] => [
  ...items,
  {
    branchId: params.branchId,
    doctorId: params.doctorId,
    productId: params.productRelatedFilters.productId,
  },
];

export const removeFromLoadingItems = (items: LoadingItem[], params: CalendarWeekParams): LoadingItem[] =>
  items.filter(
    (item) =>
      item.branchId !== params.branchId &&
      item.doctorId !== params.doctorId &&
      item.productId !== params.productRelatedFilters.productId,
  );
