import React from 'react';
import PropTypes from 'prop-types';
import { FormGroup, Label, Input } from 'reactstrap';

export const CheckboxComponent = ({
  className,
  input,
  meta: { error, touched },
  id,
  label,
  disabled,
  linkText,
  linkTarget,
  onLinkClick,
  inline,
  isRequired,
}) => {
  const fieldId = id || input.name;
  const invalid = !!error && touched;

  return (
    <FormGroup className={className} check inline={inline}>
      <Label for={fieldId} check>
        <Input {...input} id={fieldId} type="checkbox" invalid={invalid} disabled={disabled} checked={input.value} />
        <Label for={fieldId} check>
          {label && label}
          {linkText && ': '}
          {linkText && (
            <a
              href={linkTarget || '#'}
              onClick={onLinkClick}
              target={linkTarget && '_blank'}
              rel={linkTarget && 'noreferrer noopener'}
            >
              {linkText}
            </a>
          )}
          {isRequired && <span className="text-danger">&nbsp;*</span>}
        </Label>
      </Label>
    </FormGroup>
  );
};

CheckboxComponent.propTypes = {
  /**
   * Provided by redux-form
   */
  input: PropTypes.object.isRequired,
  /**
   * Provided by redux-form
   */
  meta: PropTypes.object.isRequired,
  className: PropTypes.string,
  id: PropTypes.string,
  label: PropTypes.string,
  disabled: PropTypes.bool,
  linkText: PropTypes.string,
  linkTarget: PropTypes.string,
  inline: PropTypes.bool,
  isRequired: PropTypes.bool,
};
