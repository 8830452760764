import moment from 'moment';
import { ForwardedRef, forwardRef, useMemo } from 'react';
import { IconCalendar } from '@tabler/icons-react';
import { DatePicker, IconButton } from 'app/components';

interface Props {
  date: string;
  onChange: (date: Date) => void;
}

interface CustomInputProps {
  onClick?: () => void;
}

const CustomInput = forwardRef(({ onClick }: CustomInputProps, ref: ForwardedRef<HTMLDivElement>) => (
  <div ref={ref}>
    <IconButton onClick={onClick} icon={<IconCalendar size={20} />} />
  </div>
));

export const Picker = ({ date, onChange }: Props) => {
  const selectedDate = useMemo(() => {
    if (moment(date).isBefore(moment())) {
      return moment().toDate();
    }
    return moment(date).toDate();
  }, [date]);

  return (
    <DatePicker
      selected={selectedDate}
      onChange={onChange}
      minDate={moment().toDate()}
      customInput={<CustomInput />}
      wrapperClassName="ms-1 w-auto"
    />
  );
};
