import { Template, TemplateConfiguration } from 'app/components/locationSelector/procedureLocation/types';

export const TEMPLATE_CONFIGURATION: { [key in Template]: TemplateConfiguration } = {
  [Template.MaleFullBody]: {
    fov: 10,
    maxDistance: 16,
    maxPolarAngle: Math.PI / 2.2,
    minDistance: 2,
    minPolarAngle: Math.PI / 2.2,
  },
  [Template.FemaleFullBody]: {
    fov: 10,
    maxDistance: 16,
    maxPolarAngle: Math.PI / 2.2,
    minDistance: 2,
    minPolarAngle: Math.PI / 2.2,
  },
  [Template.PrimaryTeeth]: {
    fov: 10,
    maxDistance: 16,
    maxPolarAngle: Math.PI,
    minDistance: 4,
    minPolarAngle: 0,
  },
  [Template.AdultTeeth]: {
    fov: 10,
    maxDistance: 16,
    maxPolarAngle: Math.PI,
    minDistance: 4,
    minPolarAngle: 0,
  },
};
