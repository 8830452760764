import { Dispatch } from 'redux';
import { useCallback } from 'react';
import { destroy } from 'redux-form';
import { useAppDispatch, useAppIntl } from 'app/helpers';
import { FORMS_INVOICING_PROCESS_PAYMENT_MODE } from 'app/shared';
import { InvoicingProcessStep } from 'app/redux/invoicingProcess/types';
import { DetailsForm } from 'app/features/invoicingProcess/process/details/form/form';
import { DetailsFormData } from 'app/features/invoicingProcess/process/details/form/types';
import { FETCH_EMPTY_WITHOUT_APPOINTMENT_SUCCESS } from 'app/redux/invoicingProcess/invoicingProcess.types';
import { SectionHeading } from 'app/features/invoicingProcess/process/components/sectionHeading/sectionHeading';
import {
  fetchEmptyInvoiceWithoutAppointment,
  reset,
  setStep,
} from 'app/redux/invoicingProcess/invoicingProcess.actions';

export const Details = () => {
  const dispatch = useAppDispatch();
  const { formatMessage } = useAppIntl();

  const onSubmit = useCallback(
    async ({ branch, dependant, doctor, patient }: Partial<DetailsFormData>) => {
      if (branch && doctor && patient) {
        const res = await dispatch(
          fetchEmptyInvoiceWithoutAppointment(branch.value, doctor.value, patient.value, dependant?.value),
        );

        if (!res.error && res.type === FETCH_EMPTY_WITHOUT_APPOINTMENT_SUCCESS) {
          dispatch(setStep(InvoicingProcessStep.PaymentMode));
        }
      }
    },
    [dispatch],
  );

  const onChange = useCallback((_, dispatch: Dispatch<any>) => {
    dispatch(reset({ keepMode: true, keepStep: true }));
    dispatch(destroy(FORMS_INVOICING_PROCESS_PAYMENT_MODE));
  }, []);

  return (
    <>
      <SectionHeading title={formatMessage({ id: 'CORE.TEXT.DETAILS', defaultMessage: 'Details' })} />
      <DetailsForm onChange={onChange} onSubmit={onSubmit} />
    </>
  );
};
