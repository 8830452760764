import { Option } from 'app/types';
import isArray from 'lodash/isArray';

export const mapOptionItems = (items: Option[]): string[] => {
  if (isArray(items)) {
    return items.map((i) => i.value);
  }

  return [];
};
