import { ChangeAction } from 'redux-form';
import { ChangeEvent, useCallback } from 'react';
import { useDate } from 'app/helpers';
import { EventWithDataHandler } from 'redux-form/lib/Field';

export const useOnChangeFunctions = (change: ChangeAction, selectedDate: Date | null) => {
  const { todayStart } = useDate();
  const date = selectedDate || todayStart;

  const onSubSpecialityChange: EventWithDataHandler<ChangeEvent> = useCallback(() => {
    change('product', null);
    change('date', date);
    change('slot', null);
  }, [change, date]);

  const onProductChange: EventWithDataHandler<ChangeEvent> = useCallback(() => {
    change('date', date);
    change('slot', null);
  }, [change, date]);

  const onDateChange: EventWithDataHandler<ChangeEvent> = useCallback(() => {
    change('slot', null);
  }, [change]);

  return {
    onDateChange,
    onProductChange,
    onSubSpecialityChange,
  };
};
