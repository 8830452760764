import { checkAccess } from '../index';
import { ROLES_MANAGER_ADMIN, ROLES_MASTER_ADMIN, ROLES_RECEPTION_ADMIN } from 'app/shared';

export const getClinicIdsFromCurrent = (current) => {
  if (checkAccess([ROLES_MASTER_ADMIN, ROLES_MANAGER_ADMIN, ROLES_RECEPTION_ADMIN], current.roles)) {
    return current.clinics.map((c) => c.value);
  } else {
    return [current.clinic.value];
  }
};
