export const institutionLabels = {
  addressAr: 'CORE.LABEL.ADDRESS-AR',
  addressEn: 'CORE.LABEL.ADDRESS-EN',
  appointmentReminderBefore: 'CLINICS.TEXT.APPOINTMENT-REMINDER',
  city: 'CORE.LABEL.CITY',
  clinicType: 'CLINICS.TEXT.TYPE',
  descriptionAr: 'CORE.LABEL.CLINIC-DESCRIPTION-AR',
  descriptionEn: 'CORE.LABEL.CLINIC-DESCRIPTION-EN',
  images: 'CORE.LABEL.LOGO',
  location: 'CORE.LABEL.LOCATION',
  logo: 'CORE.LABEL.LOGO',
  managementSystem: 'CLINICS.TEXT.CURRENT-SYSTEM',
  medicalLicenseExpiryDate: 'CORE.LABEL.MEDICAL-LICENSE-EXPIRY-DATE',
  medicalLicenseFile: 'CORE.LABEL.MEDICAL-LICENSE-IMAGE',
  medicalLicenseNumber: 'CORE.LABEL.MEDICAL-LICENSE-NUMBER',
  nameAr: 'CORE.LABEL.CLINIC-NAME-AR',
  nameEn: 'CORE.LABEL.NAME-EN',
  otherClinicType: 'CORE.LABEL.OTHER-CLINIC-TYPE',
  otherManagementSystem: 'CORE.LABEL.OTHER-MANAGEMENT-SYSTEM',
  phoneNumber: 'CORE.LABEL.PHONE-NUMBER',
  protectPhoneNumber: 'CLINICS.TEXT.PROTECT-PHONE-NUMBERS',
  requireSaudiIdDuringPatientRegistration: 'CLINICS.TEXT.REQUIRED-NATIONAL-ID',
  specialities: 'CORE.LABEL.MEDICAL-SPECIALITIES',
  status: 'CORE.LABEL.STATUS',
  vatRegistrationNumber: 'CORE.LABEL.VAT-NUMBER',
  allowedForEmr: 'MODULES.LABEL.EMR',
  allowedForInsuranceManagement: 'CORE.LABEL.INSURANCE-MANAGEMENT',
  nphiesId: 'CORE.LABEL.NPHIES-ID',
};

export const ownerLabels = {
  crExpiryDate: 'CLINICS.TEXT.CR-EXPIRY',
  crFile: 'CORE.LABEL.CR-FILE',
  crNumber: 'CORE.LABEL.CR-NUMBER',
  email: 'CLINICS.TEXT.OWNER-EMAIL',
  nameAr: 'CLINICS.TEXT.OWNER-NAME-AR',
  nameEn: 'CLINICS.TEXT.OWNER-NAME-EN',
  officePhoneNumber: 'CLINICS.TEXT.OWNER-PHONE',
  phoneNumber: 'CLINICS.TEXT.OWNER-MOBILE',
};
