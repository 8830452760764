import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { replaceTokens, softLogout } from 'app/redux/auth/auth.actions';
import { useAppSelector } from 'app/helpers/useTypedHooks/useTypedHooks';
import { getRefreshToken, getToken, getTokenExpirationDate } from 'app/redux/auth/helpers';

export const useTokenDetector = () => {
  const dispatch = useDispatch();
  const token = useAppSelector((state) => state.auth.token);
  const refreshToken = useAppSelector((state) => state.auth.refreshToken);
  const tokenExpiryDate = useAppSelector((state) => state.auth.tokenExpiryDate);

  useEffect(() => {
    const interval = setInterval(() => {
      if (!!token) {
        const localToken = getToken();
        const localRefreshToken = getRefreshToken();
        const localTokenExpirationDate = getTokenExpirationDate();

        if (!localToken) {
          // User was logged out on another tab
          dispatch(softLogout());
          return;
        }

        if (
          localToken &&
          localRefreshToken &&
          localTokenExpirationDate &&
          (localToken !== token || localRefreshToken !== refreshToken) &&
          tokenExpiryDate &&
          new Date(localTokenExpirationDate) > new Date(tokenExpiryDate)
        ) {
          // Token was refreshed on another tab
          dispatch(replaceTokens(localToken, localRefreshToken, localTokenExpirationDate));
        }
      }
    }, 5000);

    return () => {
      clearInterval(interval);
    };
  }, [dispatch, refreshToken, token, tokenExpiryDate]);
};
