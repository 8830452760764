export const EXPORT_PRACTITIONER_BILLING_PACKAGES_REQUEST =
  'practitionerBillingPackages/EXPORT_PRACTITIONER_BILLING_PACKAGES_REQUEST';
export const EXPORT_PRACTITIONER_BILLING_PACKAGES_SUCCESS =
  'practitionerBillingPackages/EXPORT_PRACTITIONER_BILLING_PACKAGES_SUCCESS';
export const EXPORT_PRACTITIONER_BILLING_PACKAGES_FAILURE =
  'practitionerBillingPackages/EXPORT_PRACTITIONER_BILLING_PACKAGES_FAILURE';

export const ADD_PRACTITIONER_BILLING_PACKAGES_REQUEST =
  'practitionerBillingPackages/ADD_PRACTITIONER_BILLING_PACKAGES_REQUEST';
export const ADD_PRACTITIONER_BILLING_PACKAGES_SUCCESS =
  'practitionerBillingPackages/ADD_PRACTITIONER_BILLING_PACKAGES_SUCCESS';
export const ADD_PRACTITIONER_BILLING_PACKAGES_FAILURE =
  'practitionerBillingPackages/ADD_PRACTITIONER_BILLING_PACKAGES_FAILURE';

export const EDIT_PRACTITIONER_BILLING_PACKAGES_REQUEST =
  'practitionerBillingPackages/EDIT_PRACTITIONER_BILLING_PACKAGES_REQUEST';
export const EDIT_PRACTITIONER_BILLING_PACKAGES_SUCCESS =
  'practitionerBillingPackages/EDIT_PRACTITIONER_BILLING_PACKAGES_SUCCESS';
export const EDIT_PRACTITIONER_BILLING_PACKAGES_FAILURE =
  'practitionerBillingPackages/EDIT_PRACTITIONER_BILLING_PACKAGES_FAILURE';

export const REMOVE_PRACTITIONER_BILLING_PACKAGES_REQUEST =
  'practitionerBillingPackages/REMOVE_PRACTITIONER_BILLING_PACKAGES_REQUEST';
export const REMOVE_PRACTITIONER_BILLING_PACKAGES_SUCCESS =
  'practitionerBillingPackages/REMOVE_PRACTITIONER_BILLING_PACKAGES_SUCCESS';
export const REMOVE_PRACTITIONER_BILLING_PACKAGES_FAILURE =
  'practitionerBillingPackages/REMOVE_PRACTITIONER_BILLING_PACKAGES_FAILURE';

export const CHANGE_PRACTITIONER_PACKAGE_STATUS_REQUEST =
  'practitionerBillingPackages/CHANGE_PRACTITIONER_PACKAGE_STATUS_REQUEST';
export const CHANGE_PRACTITIONER_PACKAGE_STATUS_SUCCESS =
  'practitionerBillingPackages/CHANGE_PRACTITIONER_PACKAGE_STATUS_SUCCESS';
export const CHANGE_PRACTITIONER_PACKAGE_STATUS_FAILURE =
  'practitionerBillingPackages/CHANGE_PRACTITIONER_PACKAGE_STATUS_FAILURE';

export const FETCH_PACKAGE_REQUEST = 'practitionerBillingPackages/FETCH_PACKAGE_REQUEST';
export const FETCH_PACKAGE_SUCCESS = 'practitionerBillingPackages/FETCH_PACKAGE_SUCCESS';
export const FETCH_PACKAGE_FAILURE = 'practitionerBillingPackages/FETCH_PACKAGE_FAILURE';

export const RESET_ITEMS = 'practitionerBillingPackages/RESET_ITEMS';
export const SET_VIEW_MODE = 'practitionerBillingPackages/SET_VIEW_MODE';
export const EDIT_PACKAGE = 'practitionerBillingPackages/EDIT_PACKAGE';
export const CHANGE_ITEM = 'practitionerBillingPackages/CHANGE_ITEM';
export const CHANGE_INSURANCE = 'practitionerBillingPackages/CHANGE_INSURANCE';
