import { Option } from 'app/types';
import { PatientSearchFormData } from 'app/shared/patientSearch/types';

export enum StepType {
  Speciality,
  SubSpeciality,
  ServiceWithWorkingDay,
  Patient,
}

export const BookAppointmentWizardField = {
  branchId: 'branchId',
  doctorFilter: 'doctorFilter',
  note: 'note',
  searchBy: 'searchBy',
  doctor: 'doctor',
  timeFrom: 'timeFrom',
  timeTo: 'timeTo',
  patientSource: 'patientSource',
} as const;

export interface BookAppointmentFormData extends PatientSearchFormData {
  [BookAppointmentWizardField.branchId]?: Option;
  [BookAppointmentWizardField.doctorFilter]: string;
  [BookAppointmentWizardField.note]: string;
  [BookAppointmentWizardField.searchBy]: string;
  [BookAppointmentWizardField.doctor]: Option;
  [BookAppointmentWizardField.timeFrom]: Option;
  [BookAppointmentWizardField.timeTo]: Option;
  [BookAppointmentWizardField.patientSource]?: Option;
}

export type StepTrigger = {
  color: string;
  disabled?: boolean;
  outline: boolean;
  onClick: () => void;
  label: string;
};

export type TimeRange = {
  timeFrom?: string;
  timeTo?: string;
};
