import * as types from './branch.types';

const initialState = {
  data: null,
  whileFetching: false,
  workingHours: {
    custom: [],
    loading: false,
  },
};

export const branchReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.FETCH_BRANCH_REQUEST:
      return {
        ...state,
        data: initialState.data,
        whileFetching: true,
      };
    case types.FETCH_BRANCH_SUCCESS:
      return {
        ...state,
        data: action.payload.data,
        whileFetching: false,
      };
    case types.FETCH_BRANCH_FAILURE:
      return {
        ...state,
        whileFetching: false,
      };
    case types.EDIT_BRANCH_REQUEST:
      return {
        ...state,
        whileFetching: true,
      };
    case types.EDIT_BRANCH_SUCCESS:
      return {
        ...state,
        whileFetching: false,
      };
    case types.EDIT_BRANCH_FAILURE:
      return {
        ...state,
        whileFetching: false,
      };
    case types.EDIT_BRANCH_IMAGES_REQUEST:
      return {
        ...state,
        whileFetching: true,
      };
    case types.EDIT_BRANCH_IMAGES_SUCCESS:
      return {
        ...state,
        whileFetching: false,
      };
    case types.EDIT_BRANCH_IMAGES_FAILURE:
      return {
        ...state,
        whileFetching: false,
      };
    case types.FETCH_BRANCH_WORKING_HOURS_REQUEST:
      return {
        ...state,
        workingHours: {
          ...state.workingHours,
          loading: true,
        },
      };
    case types.FETCH_BRANCH_WORKING_HOURS_SUCCESS:
      return {
        ...state,
        workingHours: {
          ...state.workingHours,
          custom: action.payload.data,
          loading: false,
        },
      };
    case types.FETCH_BRANCH_WORKING_HOURS_FAILURE:
      return {
        ...state,
        workingHours: {
          ...state.workingHours,
          loading: false,
        },
      };
    default:
      return state;
  }
};
