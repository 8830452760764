export const generateUserOptions = (items) =>
  items.map((item) => {
    const labelEn = `${item.firstNameEn} ${item.lastNameEn}`;

    return {
      label: labelEn,
      value: item.id,
      translation: {
        ar: `${item.firstNameAr} ${item.lastNameAr}`,
        en: labelEn,
      },
    };
  });
