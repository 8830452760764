import { RSAA } from 'redux-api-middleware';

import * as types from './branch.types';

export const fetchBranch = (branchId) => ({
  [RSAA]: {
    endpoint: `/branches/${branchId}`,
    method: 'GET',
    types: [types.FETCH_BRANCH_REQUEST, types.FETCH_BRANCH_SUCCESS, types.FETCH_BRANCH_FAILURE],
  },
});

export const addBranch = (values, clinicId) => {
  return {
    [RSAA]: {
      types: [
        types.ADD_BRANCH_REQUEST,
        {
          type: types.ADD_BRANCH_SUCCESS,
          payload: (action, state, res) => res.json(),
          meta: {
            clinicId,
            notification: {
              type: 'success',
              title: 'BRANCHES.TEXT.BRANCH-ADD-SUCCESS',
            },
          },
        },
        types.ADD_BRANCH_FAILURE,
      ],
      endpoint: `/clinics/${clinicId}/branches`,
      method: 'POST',
      body: values,
    },
  };
};

export const editBranch = (values, branchId, editPersisted = false) => {
  return {
    [RSAA]: {
      types: [
        types.EDIT_BRANCH_REQUEST,
        {
          type: types.EDIT_BRANCH_SUCCESS,
          meta: {
            branchId,
            notification: {
              type: 'success',
              title: editPersisted ? 'BRANCHES.TEXT.BRANCH-EDIT-SUCCESS' : 'BRANCHES.TEXT.BRANCH-EDIT-REQUEST',
            },
          },
        },
        types.EDIT_BRANCH_FAILURE,
      ],
      endpoint: `/branches/${branchId}`,
      method: 'PUT',
      body: values,
    },
  };
};

export const editBranchStatus = (status, branchId) => {
  return {
    [RSAA]: {
      types: [
        types.EDIT_BRANCH_REQUEST,
        {
          type: types.EDIT_BRANCH_SUCCESS,
          meta: {
            branchId,
            notification: {
              type: 'success',
              title: 'BRANCHES.TEXT.BRANCH-EDIT-SUCCESS',
            },
          },
        },
        types.EDIT_BRANCH_FAILURE,
      ],
      endpoint: `/branches/${branchId}/status`,
      method: 'PUT',
      body: { status },
    },
  };
};

export const editBranchImages = (values, branchId) => {
  return {
    [RSAA]: {
      types: [
        types.EDIT_BRANCH_IMAGES_REQUEST,
        {
          type: types.EDIT_BRANCH_IMAGES_SUCCESS,
          meta: {
            branchId,
            notification: {
              type: 'success',
              title: 'BRANCHES.TEXT.BRANCH-EDIT-REQUEST',
            },
          },
        },
        types.EDIT_BRANCH_IMAGES_FAILURE,
      ],
      endpoint: `/branches/${branchId}`,
      method: 'PUT',
      body: {
        ...values,
        images: values.images.filter((i) => !!i).map((i) => i.id),
      },
    },
  };
};

export const addWorkingHours = (values, branchId) => ({
  [RSAA]: {
    types: [
      types.ADD_BRANCH_WORKING_HOURS_REQUEST,
      {
        type: types.ADD_BRANCH_WORKING_HOURS_SUCCESS,
        meta: {
          branchId,
        },
      },
      types.ADD_BRANCH_WORKING_HOURS_FAILURE,
    ],
    endpoint: `/branches/${branchId}/working-hours`,
    method: 'POST',
    body: values,
  },
});

export const removeWorkingHours = (branchId, id) => ({
  [RSAA]: {
    types: [
      types.REMOVE_BRANCH_WORKING_HOURS_REQUEST,
      {
        type: types.REMOVE_BRANCH_WORKING_HOURS_SUCCESS,
        meta: {
          branchId,
        },
      },
      types.REMOVE_BRANCH_WORKING_HOURS_FAILURE,
    ],
    endpoint: `/branches/${branchId}/working-hours/${id}`,
    method: 'DELETE',
  },
});

export const fetchBranchWorkingHours = (branchId) => {
  return {
    [RSAA]: {
      endpoint: `/branches/${branchId}/working-hours`,
      method: 'GET',
      types: [
        types.FETCH_BRANCH_WORKING_HOURS_REQUEST,
        types.FETCH_BRANCH_WORKING_HOURS_SUCCESS,
        types.FETCH_BRANCH_WORKING_HOURS_FAILURE,
      ],
    },
  };
};

export const deleteBranch = (branchId, clinicId) => {
  return {
    [RSAA]: {
      types: [
        types.DELETE_BRANCH_REQUEST,
        {
          type: types.DELETE_BRANCH_SUCCESS,
          meta: {
            clinicId,
          },
        },
        types.DELETE_BRANCH_FAILURE,
      ],
      endpoint: `/branches/${branchId}`,
      method: 'DELETE',
    },
  };
};
