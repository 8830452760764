import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Button } from 'reactstrap';
import { FormattedMessage } from 'react-intl';
import get from 'lodash/get';
import { Loader } from 'app/shared';
import { Page } from 'app/components';

class AccessDeniedComponent extends Component {
  static propTypes = {
    location: PropTypes.object.isRequired, // provided by ConnectedRouter
    onRedirect: PropTypes.func.isRequired,
    userRoles: PropTypes.array.isRequired,
    whileFetching: PropTypes.bool.isRequired,
  };

  componentDidUpdate(prevProps) {
    if (prevProps.userRoles !== this.props.userRoles) {
      /*
       * 'state.referrer' contains previous route information.
       * It is used along with private-route component for redirection after user data is fetched.
       * */
      this.props.onRedirect(get(this.props.location, 'state.referrer', '/'));
    }
  }

  renderLoggingSection = () => (
    <>
      <h1 className="mb-2">
        <FormattedMessage id="ACCESS-DENIED.TEXT.LOGGING-TITLE" />
      </h1>
      <h3 className="fw-light mb-4">
        <FormattedMessage id="ACCESS-DENIED.TEXT.LOGGING-DESCRIPTION" />
      </h3>
      <Loader />
    </>
  );

  renderAccessDeniedSection = () => (
    <>
      <h1 className="mb-2">
        <FormattedMessage id="ACCESS-DENIED.TEXT.ACCESS-TITLE" />
      </h1>
      <h3 className="fw-light mb-4">
        <FormattedMessage id="ACCESS-DENIED.TEXT.ACCESS-DESCRIPTION" />
      </h3>
      <Button color="primary-gradient" tag={Link} to="/">
        <FormattedMessage id="CORE.BUTTON.HOME-PAGE" />
      </Button>
    </>
  );

  render() {
    return (
      <Page className="text-center py-6">
        {this.props.whileFetching ? this.renderLoggingSection() : this.renderAccessDeniedSection()}
      </Page>
    );
  }
}

export default AccessDeniedComponent;
