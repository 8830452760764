import delay from 'lodash/delay';

export const scrollToFirstElement = () => {
  delay(() => {
    const timeContent = document.querySelector<HTMLElement>('.rbc-time-content');

    if (timeContent) {
      timeContent.scrollTo({
        left: 0,
        behavior: 'smooth',
      });
    }
  }, 200);
};
