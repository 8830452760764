import { useMemo } from 'react';
import { UserPermissions } from 'app/types';
import { useAppSelector } from 'app/helpers/useTypedHooks/useTypedHooks';

export const useCurrentUserPermissions = () => {
  const currentUser = useAppSelector((state) => state.auth.current);

  return useMemo<UserPermissions>(
    () => ({
      allowedForExportInsuranceClaim: !!currentUser.allowedForExportInsuranceClaim,
      allowedForManageInsuranceClaim: !!currentUser.allowedForManageInsuranceClaim,
      allowedForViewInsuranceClaim: !!currentUser.allowedForViewInsuranceClaim,
      allowedForAppointmentsActions: !!currentUser.allowedForAppointmentsActions,
      allowedForBilling: !!currentUser.allowedForBilling,
      allowedForCreateCreditNote: !!currentUser.allowedForCreateCreditNote,
      allowedForCreateInvoice: !!currentUser.allowedForCreateInvoice,
      allowedForCreateOrders: !!currentUser.allowedForCreateOrders,
      allowedForCreatePatientProfile: !!currentUser.allowedForCreatePatientProfile,
      allowedForCreateSpecialityNotes: !!currentUser.allowedForCreateSpecialityNotes,
      allowedForDashboard: !!currentUser.allowedForDashboard,
      allowedForDeclarations: !!currentUser.allowedForDeclarations,
      allowedForEMR: !!currentUser.allowedForEMR,
      allowedForEditAppointments: !!currentUser.allowedForEditAppointments,
      allowedForEditPatientProfile: !!currentUser.allowedForEditPatientProfile,
      allowedForEditPayment: !!currentUser.allowedForEditPayment,
      allowedForExportAppointments: !!currentUser.allowedForExportAppointments,
      allowedForInitialAssessment: !!currentUser.allowedForInitialAssessment,
      allowedForInsuranceApproval: !!currentUser.allowedForInsuranceApproval,
      allowedForManageInsuranceApproval: !!currentUser.allowedForManageInsuranceApproval,
      allowedForInsuranceClaims: !!currentUser.allowedForInsuranceClaims,
      allowedForInsuranceManagement: !!currentUser.allowedForInsuranceManagement,
      allowedForInvoicing: !!currentUser.allowedForInvoicing,
      allowedForMassAdding: !!currentUser.allowedForMassAdding,
      allowedForMergePatientProfile: !!currentUser.allowedForMergePatientProfile,
      allowedForNotEncryptedPhoneNumbers: !!currentUser.allowedForNotEncryptedPhoneNumbers,
      allowedForTakeActionReferral: !!currentUser.allowedForTakeActionReferral,
      allowedForTreatmentView: !!currentUser.allowedForTreatmentView,
      allowedForUpdateOrders: !!currentUser.allowedForUpdateOrders,
      allowedForUpdateSpecialityNotes: !!currentUser.allowedForUpdateSpecialityNotes,
      allowedForViewBillingItems: !!currentUser.allowedForViewBillingItems,
      allowedForViewInsuranceContractsAndPolicies: currentUser.allowedForViewInsuranceContractsAndPolicies,
      allowedForViewInvoice: !!currentUser.allowedForViewInvoice,
      allowedForViewOrders: !!currentUser.allowedForViewOrders,
      allowedForViewPatientProfile: !!currentUser.allowedForViewPatientProfile,
      allowedForViewPatientSources: !!currentUser.allowedForViewPatientSources,
      allowedForViewReferral: !!currentUser.allowedForViewReferral,
      allowedForWriteBillingItems: !!currentUser.allowedForWriteBillingItems,
      allowedForWritePatientSources: !!currentUser.allowedForWritePatientSources,
      allowedForReferralApproval: !!currentUser.allowedForReferralApproval,
      allowedForManageReferralApproval: !!currentUser.allowedForManageReferralApproval,
    }),
    [currentUser],
  );
};
