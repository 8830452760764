import { FormErrors } from 'redux-form';
import { required } from 'app/shared';
import { dateToGreaterThanFrom } from 'app/helpers/extendedValidationRules';
import {
  PatientInsuranceField,
  PatientInsuranceFormData,
} from 'app/features/patient/patientInsuranceDetailsForm/types';

export const validate = (values?: Partial<PatientInsuranceFormData>): FormErrors<PatientInsuranceFormData> => {
  let errors: Partial<{ [Property in keyof PatientInsuranceFormData]: string | undefined }> = {};

  if (values) {
    const requiredFields: Array<keyof PatientInsuranceFormData> = [
      PatientInsuranceField.activated,
      PatientInsuranceField.end,
      PatientInsuranceField.clinic,
      PatientInsuranceField.clinicBranch,
      PatientInsuranceField.insuranceClass,
      PatientInsuranceField.insuranceCompany,
      PatientInsuranceField.number,
      PatientInsuranceField.policyNumber,
      PatientInsuranceField.start,
    ];

    // Check required field
    requiredFields.forEach((field) => {
      const validation = required(values[field]);

      if (validation) {
        errors = {
          ...errors,
          [field]: validation,
        };
      }
    });

    // Check dates
    if (values.start && values.end) {
      const dateToGreaterThanFromResult = dateToGreaterThanFrom(values.start, values.end);

      if (dateToGreaterThanFromResult) {
        errors.end = dateToGreaterThanFromResult;
      }
    }
  }

  return errors;
};
