import React, { useCallback, useMemo } from 'react';
import { Button, Row } from 'reactstrap';
import { Field } from 'redux-form';
import { Box, BoxHeader } from 'app/components';
import { InputComponent, FORMS_BOOK_APPOINTMENT } from 'app/shared';
import { useAppIntl, useAppSelector, getPatientFullName, useCurrentUser } from 'app/helpers';
import { PatientType, DEFAULT_EMPTY_PAGINATION } from 'app/types';
import { PatientSearchFilters, PatientSearchResults, PatientShortDetails } from 'app/shared/patientSearch';
import { PatientSearchHandlers } from 'app/shared/patientSearch/helpers/usePatientSearchHandlers';
import { BookAppointmentWizardField } from 'app/bookAppointment/types';
import { BookingRestrictionsStatus } from 'app/shared/patientSearch/patientSelectedDetails/bookingRestrictionStatus/bookingRestrictionsStatus';
import { DoctorData } from 'app/redux/book-appointment/types';
import { PatientSelectedDetails } from 'app/shared/patientSearch/patientSelectedDetails/patientSelectedDetails';

interface Props {
  clinicId: string;
  createdPatientMode?: boolean;
  doctor?: DoctorData;
  resetMode: () => void;
  onAddNewPatient: () => void;
  searchHandlers: PatientSearchHandlers;
}

export const PatientSection = ({
  clinicId,
  createdPatientMode,
  doctor,
  onAddNewPatient,
  resetMode,
  searchHandlers,
}: Props) => {
  const { formatMessage, locale } = useAppIntl();
  const { currentUser } = useCurrentUser();

  // Selectors
  const preselectedPatient = useAppSelector((state) => state.appointment.preselectedPatient);
  const patient = useAppSelector((state) => state.bookAppointment.selectedPatient);
  const selectedPatient = preselectedPatient || patient;
  const referralInfo = useAppSelector((state) => state.bookAppointment.referralInfo);

  const {
    data,
    checkIsRowSelected,
    isAnySelected,
    isFetching,
    isLoading,
    params,
    selectionChanged,
    handleFilterChange,
    handleSetParams,
  } = searchHandlers;

  const stepTitle = useMemo(() => {
    return !isAnySelected
      ? formatMessage({ id: 'APPOINTMENTS.BUTTON.SEARCH-PATIENT' })
      : `${formatMessage({ id: 'PATIENTS.TEXT.PATIENT' })} ${
          selectedPatient ? getPatientFullName(selectedPatient, locale) : ''
        }`;
  }, [formatMessage, locale, isAnySelected, selectedPatient]);

  const onChangePatient = useCallback(() => {
    resetMode();
  }, [resetMode]);

  return (
    <>
      <Box className="fadeIn">
        <BoxHeader customMessage={stepTitle}>
          {currentUser.allowedForCreatePatientProfile &&
            !createdPatientMode &&
            !preselectedPatient &&
            !referralInfo &&
            !isAnySelected && (
              <Button type="button" color="primary" onClick={onAddNewPatient} size="sm">
                {formatMessage({ id: 'CORE.LABEL.ADD-NEW-PATIENT', defaultMessage: 'Add New Patient' })}
              </Button>
            )}
          {createdPatientMode && (
            <Button type="button" color="primary" onClick={onChangePatient} size="sm">
              {formatMessage({ id: 'CORE.LABEL.CHANGE-PATIENT', defaultMessage: 'Change patient' })}
            </Button>
          )}
        </BoxHeader>

        {!createdPatientMode && !preselectedPatient && !referralInfo && (
          <>
            <PatientSearchFilters
              formName={FORMS_BOOK_APPOINTMENT}
              anySelected={isAnySelected}
              isDependent={selectedPatient?.patientType.value === PatientType.Dependent}
              clinicId={clinicId}
              patientId={selectedPatient?.id}
              onFiltersChanged={handleFilterChange}
            />
            <BookingRestrictionsStatus doctor={doctor} patient={selectedPatient} />
            <PatientSearchResults
              data={data?.data || []}
              isFetching={isFetching}
              isLoading={isLoading}
              params={params}
              pagination={data?.pagination || { ...DEFAULT_EMPTY_PAGINATION, pageSize: 5 }}
              setParams={handleSetParams}
              checkIsSelected={checkIsRowSelected}
              selectionChanged={selectionChanged}
            />
          </>
        )}

        {(createdPatientMode || !!preselectedPatient || !!referralInfo) && selectedPatient && (
          <>
            <Row xs={1} lg={3}>
              <PatientSelectedDetails
                clinicId={clinicId}
                patientId={selectedPatient.id}
                isDependent={selectedPatient.patientType.value === PatientType.Dependent}
              />
            </Row>
            <BookingRestrictionsStatus doctor={doctor} patient={selectedPatient} />
            <PatientShortDetails data={selectedPatient} />
          </>
        )}

        {selectedPatient && (
          <div className="mt-3">
            <Field
              name={BookAppointmentWizardField.note}
              component={InputComponent}
              label={formatMessage({ id: 'APPOINTMENTS.TEXT.APPOINTMENT-NOTE' })}
              type="textarea"
            />
          </div>
        )}
      </Box>
    </>
  );
};
