import { useCallback } from 'react';
import { Badge, Button } from 'reactstrap';
import { ColumnFormatter } from 'react-bootstrap-table-next';
import { Appointment } from 'app/types';
import { useAppDispatch, useAppIntl } from 'app/helpers';
import { toggleModal } from 'app/redux/modals/modals.actions';
import { setTicket } from 'app/redux/appointment/appointment.actions';

interface Props {
  appointment: Appointment;
}

const Ticket = ({ appointment }: Props) => {
  const { formatMessage } = useAppIntl();
  const dispatch = useAppDispatch();

  const openAppointmentTicket = useCallback(() => {
    dispatch(setTicket({ id: appointment.id, isWaiting: !!appointment.workingTimeId }));
    dispatch(toggleModal('appointmentTicket'));
  }, [appointment, dispatch]);

  return (
    <>
      <Button className="px-0 py-1" color="link" onClick={openAppointmentTicket} size="sm">
        {appointment.number || '-'}
      </Button>

      {!!appointment.note && (
        <div>
          <Badge color="light-primary">{formatMessage({ id: 'APPOINTMENTS.TEXT.APPOINTMENT-NOTE' })}</Badge>
        </div>
      )}
    </>
  );
};

export const ticketFormatter: ColumnFormatter<Appointment> = (_, row) => <Ticket appointment={row} />;
