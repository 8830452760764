import moment from 'moment';
import { FORMATS_API_DATE } from 'app/shared';
import { CalendarSearchParams, CalendarWeekParams } from 'app/redux/calendar/types';

export const generateCalendarWeekParams = (
  params: CalendarSearchParams,
  date: string,
  branchId: string,
  doctorId: string,
  productId: string,
): CalendarWeekParams => ({
  branchId,
  date: moment(date).format(FORMATS_API_DATE),
  doctorId,
  productRelatedFilters: {
    customDurationInMinutes: params.productRelatedFilters.customDurationInMinutes,
    productId,
    specialityId: params.productRelatedFilters.specialityId,
    subSpecialityId: params.productRelatedFilters.subSpecialityId,
  },
  timeFrom: params.timeFrom,
  timeTo: params.timeTo,
});
