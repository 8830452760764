import { SelectedDay } from '../types';

export const updateSelectedDays = (days: SelectedDay[], selectedDay: SelectedDay): SelectedDay[] => {
  const newDays = days;
  const index = days.findIndex(
    (day) =>
      day.branchId === selectedDay.branchId &&
      day.doctorId === selectedDay.doctorId &&
      day.productId === selectedDay.productId,
  );

  if (index !== -1) {
    newDays[index] = selectedDay;
  } else {
    newDays.push(selectedDay);
  }

  return newDays;
};

type RemoveSelectedDayParams = {
  branchId: string;
  doctorId: string;
  productId: string;
};

export const removeSelectedDay = (days: SelectedDay[], params: RemoveSelectedDayParams) =>
  days.filter(
    (day) => day.doctorId !== params.doctorId || day.branchId !== params.branchId || day.productId !== params.productId,
  );
