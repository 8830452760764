import { round } from 'lodash';
import { PackageItem } from 'app/types';
import { BillingPackagesReducer } from 'app/redux/billingPackages/billingPackages.reducer';
import { isItemExisting } from 'app/redux/billingPackages/helpers/isItemExisting';

export const addItem = (state: BillingPackagesReducer, payload: PackageItem) => {
  if (isItemExisting(state.items, payload.id)) {
    return { ...state };
  } else {
    return {
      ...state,
      items: [...state.items, payload],
      totalPrice: round(state.totalPrice + payload.pricePerUnit * payload.quantity, 2),
      totalOriginalPrice: round(state.totalOriginalPrice + payload.originalPrice * payload.quantity, 2),
    };
  }
};
