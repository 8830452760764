import { useMemo } from 'react';
import { isWithinAgeRestrictions, useAppSelector } from 'app/helpers';
import { formConfig } from 'app/bookAppointment/shared';

export const useCanBookAppointment = () => {
  const mobileNumber = useAppSelector((state) => state.form?.[formConfig.form]?.values?.selectedPhoneNumber);
  const selectedSlot = useAppSelector((state) => state.bookAppointment.selectedSlot);
  const preselectedPatient = useAppSelector((state) => state.appointment.preselectedPatient);
  const patient = useAppSelector((state) => state.bookAppointment.selectedPatient);
  const selectedPatient = preselectedPatient || patient;

  const meetsAgeRestrictions = useMemo(
    () =>
      isWithinAgeRestrictions({
        age: selectedPatient?.age,
        min: selectedSlot?.doctor.acceptablePatientAgeFrom,
        max: selectedSlot?.doctor.acceptablePatientAgeTo,
      }),
    [selectedSlot?.doctor, selectedPatient],
  );

  return mobileNumber && selectedSlot && selectedPatient && meetsAgeRestrictions;
};
