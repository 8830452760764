import { takeEvery } from 'redux-saga/effects';
import { setLangAttributes } from 'app/helpers';

export function* afterIntlUpdate({ payload }) {
  const currentLang = document.getElementsByTagName('html')[0].getAttribute('lang');
  if (currentLang !== payload.locale) {
    yield setLangAttributes(payload.locale);
  }
}

function* i18nSaga() {
  yield takeEvery('@@intl/UPDATE', afterIntlUpdate);
}

export default i18nSaga;
