export const SEARCH_PATIENT_REQUEST = 'patient/SEARCH_PATIENT_REQUEST';
export const SEARCH_PATIENT_SUCCESS = 'patients/SEARCH_PATIENT_SUCCESS';
export const SEARCH_PATIENT_FAILURE = 'patients/SEARCH_PATIENT_FAILURE';

export const FETCH_PATIENT_OPTIONS_REQUEST = 'patients/FETCH_PATIENT_OPTIONS_REQUEST';
export const FETCH_PATIENT_OPTIONS_SUCCESS = 'patients/FETCH_PATIENT_OPTIONS_SUCCESS';
export const FETCH_PATIENT_OPTIONS_FAILURE = 'patients/FETCH_PATIENT_OPTIONS_FAILURE';

export const SET_PARAMS = 'patients/SET_PARAMS';
export const SET_CLINIC_PARAMS = 'patients/SET_CLINIC_PARAMS';
