import { RSAA } from 'redux-api-middleware';

import * as types from './dependents.types';

export const fetchDependents = (patientId, params) => ({
  [RSAA]: {
    endpoint: `patients/${patientId}/dependants`,
    method: 'GET',
    types: [
      types.FETCH_PATIENT_DEPENDENTS_REQUEST,
      types.FETCH_PATIENT_DEPENDENTS_SUCCESS,
      types.FETCH_PATIENT_DEPENDENTS_FAILURE,
    ],
    params,
  },
});

export const addDependent = (patientId, body) => ({
  [RSAA]: {
    endpoint: `patients/${patientId}/dependants`,
    method: 'POST',
    types: [
      types.ADD_PATIENT_DEPENDENT_REQUEST,
      {
        type: types.ADD_PATIENT_DEPENDENT_SUCCESS,
        meta: {
          patientId,
          notification: {
            type: 'success',
            title: 'PATIENTS.NOTIFICATION.DEPENDENT-ADDED-SUCCESS',
          },
        },
      },
      {
        type: types.ADD_PATIENT_DEPENDENT_FAILURE,
        meta: {
          notification: {
            type: 'danger',
            title: 'CORE.TEXT.GENERAL-ERROR',
          },
        },
      },
    ],
    body,
  },
});

export const covertToPatient = (dependentId, phoneNumber, patientId) => ({
  [RSAA]: {
    endpoint: `/dependants/${dependentId}/convert-to-patient`,
    method: 'PUT',
    headers: { 'Content-Type': 'application/json' },
    types: [
      types.CONVERT_TO_PATIENT_REQUEST,
      {
        type: types.CONVERT_TO_PATIENT_SUCCESS,
        meta: {
          patientId,
        },
      },
      types.CONVERT_TO_PATIENT_FAILURE,
    ],
    body: {
      phoneNumber,
    },
  },
});

export const deleteDependent = (dependentId) => ({
  [RSAA]: {
    endpoint: `dependants/${dependentId}`,
    method: 'DELETE',
    types: [
      types.DELETE_PATIENT_DEPENDENTS_REQUEST,
      {
        type: types.DELETE_PATIENT_DEPENDENTS_SUCCESS,
        meta: {
          notification: {
            type: 'success',
            title: 'PATIENTS.NOTIFICATION.DEPENDENT-REMOVED-SUCCESS',
          },
        },
      },
      {
        type: types.DELETE_PATIENT_DEPENDENTS_FAILURE,
        meta: {
          notification: {
            type: 'danger',
            title: 'CORE.TEXT.DEFAULT-FAILURE-MESSAGE',
          },
        },
      },
    ],
  },
});

export const switchDependentWithPrimaryPatient = (dependentId, patientId) => ({
  [RSAA]: {
    endpoint: `/dependants/${dependentId}/patients/${patientId}/switch`,
    method: 'PUT',
    types: [
      types.DEPENDENT_SWITCH_TO_PATIENT_REQUEST,
      {
        type: types.DEPENDENT_SWITCH_TO_PATIENT_SUCCESS,
        meta: {
          notification: {
            type: 'success',
            title: 'PATIENTS.TEXT.MAKE-PRIMARY-SUCCESS',
          },
          dependentId,
        },
      },
      {
        type: types.DEPENDENT_SWITCH_TO_PATIENT_FAILURE,
        meta: {
          notification: {
            type: 'danger',
            title: 'PATIENTS.TEXT.MAKE-PRIMARY-FAILURE',
          },
        },
      },
    ],
  },
});
