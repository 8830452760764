import { RSAA } from 'redux-api-middleware';
import { DICTIONARIES_LIMIT } from 'app/shared';
import { Appointment } from 'app/types';
import { CalendarDayParams } from 'app/redux/calendar/types';
import {
  AppointmentNoteParams,
  AppointmentPeriods,
  AppointmentValues,
  ChangeStatusBodyParams,
  ProductParams,
  SubSpecialityParams,
  WaitingAppointmentValues,
} from './types';
import { PatientInfoShort } from 'app/shared/patientSearch';
import * as types from './appointment.types';

export const fetchAppointment = (id: string, isWaiting: boolean) => ({
  [RSAA]: {
    endpoint: `/${isWaiting ? 'waiting-appointments' : 'appointments'}/${id}`,
    method: 'GET',
    types: [types.FETCH_APPOINTMENT_REQUEST, types.FETCH_APPOINTMENT_SUCCESS, types.FETCH_APPOINTMENT_FAILURE],
  },
});

export const fetchWaitingAppointmentDetails = (id: string) => ({
  [RSAA]: {
    endpoint: `/waiting-appointments/${id}`,
    method: 'GET',
    types: [types.FETCH_WA_DETAILS_REQUEST, types.FETCH_WA_DETAILS_SUCCESS, types.FETCH_WA_DETAILS_FAILURE],
  },
});

export const resetDetails = () => ({
  type: types.RESET_DETAILS,
});

export const addAppointment = (body: AppointmentValues, isPhantom = false) => {
  const endpoint = isPhantom ? '/appointments/phantom' : body.end !== null ? '/appointments' : '/appointments/no-time';

  return {
    [RSAA]: {
      types: [types.ADD_APPOINTMENT_REQUEST, types.ADD_APPOINTMENT_SUCCESS, types.ADD_APPOINTMENT_FAILURE],
      endpoint,
      method: 'POST',
      body,
    },
  };
};

export const addWaitingAppointment = (body: WaitingAppointmentValues, isPhantom: boolean) => {
  const endpoint = isPhantom ? '/waiting-appointments/phantom' : '/waiting-appointments';

  return {
    [RSAA]: {
      types: [
        types.ADD_WAITING_APPOINTMENT_REQUEST,
        types.ADD_WAITING_APPOINTMENT_SUCCESS,
        types.ADD_WAITING_APPOINTMENT_FAILURE,
      ],
      endpoint,
      method: 'POST',
      body,
    },
  };
};

export const checkFreeSlotInTimeRange = (params: {
  from: string;
  productId: string;
  to: string;
  workingTimeId: string;
}) => {
  return {
    [RSAA]: {
      types: [types.CHECK_TIME_RANGE_REQUEST, types.CHECK_TIME_RANGE_SUCCESS, types.CHECK_TIME_RANGE_FAILURE],
      endpoint: '/calendar/is-possible-to-book-phantom-waiting-appointment',
      method: 'GET',
      params,
    },
  };
};

export const setAppointment = (appointment: Appointment | null) => ({
  type: types.SET_APPOINTMENT,
  payload: appointment,
});

export const resetAppointment = () => ({
  type: types.RESET_APPOINTMENT,
});

export const setTicket = (ticket: { id: string; isWaiting: boolean }) => ({
  type: types.SET_TICKET,
  payload: ticket,
});

export const resetTicket = () => ({
  type: types.RESET_TICKET,
});

export const setFullEdit = (appointment: Appointment) => ({
  type: types.SET_FULL_EDIT,
  payload: appointment,
});

export const resetFullEdit = () => ({
  type: types.RESET_FULL_EDIT,
});

export const setRebook = (appointment: Appointment) => ({
  type: types.SET_REBOOK,
  payload: appointment,
});

export const resetRebook = () => ({
  type: types.RESET_REBOOK,
});

export const editAppointment = (appointmentId: string, values: AppointmentValues) => {
  return {
    [RSAA]: {
      types: [
        types.EDIT_APPOINTMENT_REQUEST,
        {
          type: types.EDIT_APPOINTMENT_SUCCESS,
          meta: {
            notification: {
              type: 'success',
              title: 'APPOINTMENTS.TEXT.EDIT-SUCCESS',
            },
          },
        },
        {
          type: types.EDIT_APPOINTMENT_FAILURE,
        },
      ],
      endpoint: `/appointments/${appointmentId}`,
      method: 'PUT',
      body: values,
    },
  };
};

export const editAppointmentNote = ({ id, note }: AppointmentNoteParams) => {
  return {
    [RSAA]: {
      types: [
        types.EDIT_APPOINTMENT_NOTE_REQUEST,
        {
          type: types.EDIT_APPOINTMENT_NOTE_SUCCESS,
          meta: {
            notification: {
              type: 'success',
              title: 'APPOINTMENTS.TEXT.EDIT-SUCCESS',
            },
          },
        },
        {
          type: types.EDIT_APPOINTMENT_NOTE_FAILURE,
        },
      ],
      endpoint: `/appointments/${id}/note`,
      method: 'PUT',
      body: {
        note,
      },
    },
  };
};

export const editWaitingAppointmentNote = ({ id, note }: AppointmentNoteParams) => {
  return {
    [RSAA]: {
      types: [
        types.EDIT_WAITING_APPOINTMENT_NOTE_REQUEST,
        {
          type: types.EDIT_WAITING_APPOINTMENT_NOTE_SUCCESS,
          meta: {
            notification: {
              type: 'success',
              title: 'APPOINTMENTS.TEXT.EDIT-SUCCESS',
            },
          },
        },
        {
          type: types.EDIT_WAITING_APPOINTMENT_NOTE_FAILURE,
        },
      ],
      endpoint: `/waiting-appointments/${id}/note`,
      method: 'PUT',
      body: {
        note,
      },
    },
  };
};

export const editWaitingAppointment = (id: string, values: WaitingAppointmentValues) => {
  return {
    [RSAA]: {
      types: [
        types.EDIT_WAITING_APPOINTMENT_REQUEST,
        {
          type: types.EDIT_WAITING_APPOINTMENT_SUCCESS,
          meta: {
            notification: {
              type: 'success',
              title: 'APPOINTMENTS.TEXT.EDIT-SUCCESS',
            },
          },
        },
        {
          type: types.EDIT_WAITING_APPOINTMENT_FAILURE,
        },
      ],
      endpoint: `/waiting-appointments/${id}`,
      method: 'PUT',
      body: values,
    },
  };
};

export const promoteWaitingAppointment = (appointment: Appointment, values: AppointmentValues) => {
  return {
    [RSAA]: {
      types: [
        types.PROMOTE_WAITING_APPOINTMENT_REQUEST,
        {
          type: types.PROMOTE_WAITING_APPOINTMENT_SUCCESS,
          meta: {
            appointment,
            notification: {
              type: 'success',
              title: 'APPOINTMENTS.TEXT.PROMOTE-SUCCESS',
            },
          },
        },
        {
          type: types.PROMOTE_WAITING_APPOINTMENT_FAILURE,
        },
      ],
      endpoint: `/waiting-appointments/${appointment.id}/promote`,
      method: 'POST',
      body: values,
    },
  };
};

export const setPatient = (payload: PatientInfoShort) => ({
  type: types.SET_PATIENT,
  payload,
});

export const resetPatient = () => ({
  type: types.RESET_PATIENT,
});

export const setPreselectedPatient = (patient: PatientInfoShort) => ({
  type: types.SET_PRESELECTED_PATIENT,
  meta: {
    patient,
  },
});

export const resetPreselectedPatient = () => ({
  type: types.RESET_PRESELECTED_PATIENT,
});

export const cancelAppointment = (appointmentId: string) => {
  return {
    [RSAA]: {
      types: [
        types.CANCEL_APPOINTMENT_REQUEST,
        {
          type: types.CANCEL_APPOINTMENT_SUCCESS,
          meta: {
            notification: {
              type: 'success',
              title: 'APPOINTMENTS.TEXT.CANCEL-SUCCESS',
            },
          },
        },
        types.CANCEL_APPOINTMENT_FAILURE,
      ],
      endpoint: `/appointments/${appointmentId}`,
      method: 'DELETE',
    },
  };
};
export const cancelWaitingAppointment = (appointmentId: string) => {
  return {
    [RSAA]: {
      types: [
        types.CANCEL_WAITING_APPOINTMENT_REQUEST,
        {
          type: types.CANCEL_WAITING_APPOINTMENT_SUCCESS,
          meta: {
            notification: {
              type: 'success',
              title: 'APPOINTMENTS.TEXT.CANCEL-SUCCESS',
            },
          },
        },
        types.CANCEL_WAITING_APPOINTMENT_FAILURE,
      ],
      endpoint: `/waiting-appointments/${appointmentId}`,
      method: 'DELETE',
    },
  };
};

export const changeAppointmentStatus = (appointmentId: string, body: ChangeStatusBodyParams) => {
  return {
    [RSAA]: {
      endpoint: `/appointments/${appointmentId}/${body.status}`,
      body,
      method: `PUT`,
      types: [
        types.CHANGE_APPOINTMENT_STATUS_REQUEST,
        {
          type: types.CHANGE_APPOINTMENT_STATUS_SUCCESS,
          meta: {
            notification: {
              type: 'success',
              title: 'APPOINTMENTS.TEXT.STATUS-CHANGE-SUCCESS',
            },
          },
        },
        {
          type: types.CHANGE_APPOINTMENT_STATUS_FAILURE,
          meta: {
            notification: {
              type: 'danger',
              title: 'APPOINTMENTS.TEXT.STATUS-CHANGE-FAIL',
            },
          },
        },
      ],
    },
  };
};

export const openAppointmentRegisterPatient = (patientId: string, clinicId: string) => ({
  type: types.SET_APPOINTMENT_REGISTER_PATIENT,
  meta: {
    patientId,
    clinicId,
  },
});

export const setAppointmentsPeriods = (periods: AppointmentPeriods) => ({
  type: types.SET_APPOINTMENT_PERIODS,
  meta: {
    periods,
  },
});

export const fetchMedicalServices = (doctorId: string) => {
  return {
    [RSAA]: {
      endpoint: `/doctors/${doctorId}/subspecialities`,
      params: {
        disabled: true,
        pageSize: DICTIONARIES_LIMIT,
      },
      method: 'GET',
      types: [
        types.FETCH_MEDICAL_SERVICES_REQUEST,
        types.FETCH_MEDICAL_SERVICES_SUCCESS,
        types.FETCH_MEDICAL_SERVICES_FAILURE,
      ],
    },
  };
};

export const fetchDay = (body: CalendarDayParams, isSilent: boolean = false) => ({
  [RSAA]: {
    endpoint: '/calendar/day',
    method: 'POST',
    types: [
      {
        type: types.FETCH_DAY_REQUEST,
        meta: { isSilent },
      },
      types.FETCH_DAY_SUCCESS,
      types.FETCH_DAY_FAILURE,
    ],
    body,
  },
});

export const fetchDaySlots = (body: CalendarDayParams) => ({
  [RSAA]: {
    endpoint: `/calendar/day-slots`,
    method: 'POST',
    types: [types.FETCH_DAY_SLOTS_REQUEST, types.FETCH_DAY_SLOTS_SUCCESS, types.FETCH_DAY_SLOTS_FAILURE],
    body,
  },
});

export const fetchProducts = (params: ProductParams) => ({
  [RSAA]: {
    types: [types.FETCH_PRODUCTS_REQUEST, types.FETCH_PRODUCTS_SUCCESS, types.FETCH_PRODUCTS_FAILURE],
    endpoint: `/working-hour-products/available-in-time-range`,
    method: 'GET',
    params,
  },
});

export const fetchProductsForWaiting = (workingTimeId: string, subSpecialityId: string) => ({
  [RSAA]: {
    types: [types.FETCH_PRODUCTS_REQUEST, types.FETCH_PRODUCTS_SUCCESS, types.FETCH_PRODUCTS_FAILURE],
    endpoint: `/working-hours/${workingTimeId}/sub-specialities/${subSpecialityId}/products-for-booking`,
    method: 'GET',
  },
});

export const fetchWorkingHourMedicalServices = (params: SubSpecialityParams) => ({
  [RSAA]: {
    types: [
      types.FETCH_WORKING_HOUR_MEDICAL_SERVICES_REQUEST,
      types.FETCH_WORKING_HOUR_MEDICAL_SERVICES_SUCCESS,
      types.FETCH_WORKING_HOUR_MEDICAL_SERVICES_FAILURE,
    ],
    endpoint: `/working-hour-subspecialities/available-in-time-range`,
    method: 'GET',
    params,
  },
});

export const fetchWorkingHourMedicalServicesForWaiting = (workingTimeId: string) => ({
  [RSAA]: {
    types: [
      types.FETCH_WORKING_HOUR_MEDICAL_SERVICES_REQUEST,
      types.FETCH_WORKING_HOUR_MEDICAL_SERVICES_SUCCESS,
      types.FETCH_WORKING_HOUR_MEDICAL_SERVICES_FAILURE,
    ],
    endpoint: `/working-hours/${workingTimeId}/subspecialities-for-waiting-appointment-edit`,
    method: 'GET',
  },
});
