import { useCallback } from 'react';
import { useAppDispatch } from 'app/helpers';
import { Doctor, Option } from 'app/types';
import { selectWaitingSlot } from 'app/redux/calendar/calendar.actions';
import { toggleModal } from 'app/redux/modals/modals.actions';

export const useManageWaiting = (
  branchId: string,
  doctor: Doctor,
  product: Option,
  speciality: Option,
  subSpeciality: Option,
) => {
  const dispatch = useAppDispatch();

  return useCallback(
    (date: string) => {
      dispatch(
        selectWaitingSlot({
          branchId,
          date,
          doctor,
          isPhantom: false,
          product,
          speciality,
          subSpeciality,
        }),
      );
      dispatch(toggleModal('manageWaitingAppointments'));
    },
    [branchId, dispatch, doctor, product, speciality, subSpeciality],
  );
};
