import { Option } from 'app/types/Option';

/**
 * -------------------------------------------
 * Glossary:
 * -------------------------------------------
 *
 * - Contractor:
 *   An individual or company that undertakes a contract.
 *
 * - Contract:
 *   A legally binding agreement between two or more parties.
 *
 * - Contract Entity:
 *   An abstract representation of a contract or contractor.
 *
 * -------------------------------------------
 */

export enum InsuranceContractorsScope {
  MedicalInstitution = '0',
  Global = '1',
}

export enum InsuranceContractEntityStatus {
  Active = '0',
  Deactivate = '1',
  Expired = '2',
  Removed = '3',
}

export interface InsuranceContract {
  id: string;
  nameEn: string;
  nameAr: string;
  insuranceCompany: Option;
  contractStartDate: string;
  contractEndDate: string;
  contractorStatus: Option<InsuranceContractEntityStatus>;
  status: Option<InsuranceContractEntityStatus>;
  branchId: string;
}

export interface InsuranceContractor {
  id: string;
  nameEn: string;
  nameAr: string;
  contractingStartDate: string;
  contractingEndDate: string;
  clinicBranch: Option;
  status: Option<InsuranceContractEntityStatus>;
  insuranceCotracts: InsuranceContract[];
}

export interface InsuranceContractEntityActionLog {
  id: string;
  action: string;
  date: string;
  actionTake: string;
}

export interface InsuranceContractEntityAttachment {
  id: string;
  url: string;
  name: string;
}

export enum InsuranceContractRoomType {
  Other = '5',
}
