import { useCallback } from 'react';
import { destroy } from 'redux-form';
import { Appointment } from 'app/types';
import { useAppDispatch } from 'app/helpers';
import {
  FORMS_CREDIT_NOTE,
  FORMS_INVOICING_PROCESS_DECLARATION,
  FORMS_INVOICING_PROCESS_DETAILS,
  FORMS_INVOICING_PROCESS_PAYMENT_METHOD_CFG,
  FORMS_INVOICING_PROCESS_PAYMENT_MODE,
} from 'app/shared';
import { toggleModal } from 'app/redux/modals/modals.actions';
import { InvoicingProcessMode, InvoicingProcessStep } from 'app/redux/invoicingProcess/types';
import {
  fetchDraft,
  fetchEmptyByRequestId,
  fetchEmptyInvoiceWithAppointment,
  fetchInvoice,
  reset,
  setAppointment,
  setMode,
  setRequest,
  setStep,
} from 'app/redux/invoicingProcess/invoicingProcess.actions';

export type CreateInvoice = (appointmentId?: string | null) => void;
export type CreateInvoiceFromRequest = (requestId: string, appointmentId: string) => void;
export type OpenAppointmentInvoices = (appointment: Appointment) => void;
export type ViewDraft = (draftId: string, appointmentId?: string | null) => void;
export type ViewInvoice = (invoiceId: string, appointmentId?: string | null) => void;

export const useInvoicingProcessActions = () => {
  const dispatch = useAppDispatch();

  const resetInvoicingProcess = useCallback(() => {
    dispatch(reset());
    dispatch(
      destroy(
        FORMS_INVOICING_PROCESS_DETAILS,
        FORMS_INVOICING_PROCESS_PAYMENT_MODE,
        FORMS_INVOICING_PROCESS_PAYMENT_METHOD_CFG,
        FORMS_INVOICING_PROCESS_DECLARATION,
        FORMS_CREDIT_NOTE,
      ),
    );
  }, [dispatch]);

  const createInvoice = useCallback<CreateInvoice>(
    (appointmentId) => {
      resetInvoicingProcess();

      if (appointmentId) {
        dispatch(setAppointment(appointmentId));
        dispatch(setStep(InvoicingProcessStep.PaymentMode));
        dispatch(setMode(InvoicingProcessMode.CreateInvoice));
        dispatch(fetchEmptyInvoiceWithAppointment(appointmentId));
        dispatch(toggleModal('invoicingProcess', true));
      } else {
        dispatch(setStep(InvoicingProcessStep.Details));
        dispatch(setMode(InvoicingProcessMode.CreateInvoice));
        dispatch(toggleModal('invoicingProcess', true));
      }
    },
    [dispatch, resetInvoicingProcess],
  );

  const createInvoiceFromRequest = useCallback<CreateInvoiceFromRequest>(
    (requestId, appointmentId) => {
      resetInvoicingProcess();

      dispatch(setAppointment(appointmentId));
      dispatch(setRequest(requestId));
      dispatch(setStep(InvoicingProcessStep.PaymentMode));
      dispatch(setMode(InvoicingProcessMode.CreateInvoice));
      dispatch(fetchEmptyByRequestId(requestId));
      dispatch(toggleModal('invoicingProcess', true));
    },
    [dispatch, resetInvoicingProcess],
  );

  const openAppointmentInvoices = useCallback<OpenAppointmentInvoices>(
    (appointment) => {
      resetInvoicingProcess();

      dispatch(setAppointment(appointment.id));
      dispatch(setMode(InvoicingProcessMode.Invoices));
      dispatch(toggleModal('invoicingProcess', true));
    },
    [dispatch, resetInvoicingProcess],
  );

  const viewDraft = useCallback<ViewDraft>(
    (draftId, appointmentId) => {
      if (appointmentId) {
        dispatch(setAppointment(appointmentId));
      }

      // Step will be set by initializeInvoicingProcess
      dispatch(setMode(InvoicingProcessMode.Draft));
      dispatch(fetchDraft(draftId));
      dispatch(toggleModal('invoicingProcess', true));
    },
    [dispatch],
  );

  const viewInvoice = useCallback<ViewInvoice>(
    (invoiceId, appointmentId) => {
      if (appointmentId) {
        dispatch(setAppointment(appointmentId));
      }

      dispatch(setStep(InvoicingProcessStep.Summary));
      dispatch(setMode(InvoicingProcessMode.Invoice));
      dispatch(fetchInvoice(invoiceId));
      dispatch(toggleModal('invoicingProcess', true));
    },
    [dispatch],
  );

  return {
    createInvoice,
    createInvoiceFromRequest,
    openAppointmentInvoices,
    resetInvoicingProcess,
    viewDraft,
    viewInvoice,
  };
};
