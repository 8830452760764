import { Action, AdvancedFilters } from './app.reducer';
import * as types from './app.types';

export const toggleMenu = (status?: boolean): Action => ({
  type: types.TOGGLE_MENU,
  payload: {
    status,
  },
});

export const toggleAdvancedFilters = (advancedFilterName: keyof AdvancedFilters, status?: boolean): Action => ({
  type: types.TOGGLE_ADVANCED_FILTERS,
  payload: {
    advancedFilterName,
    status,
  },
});

export const openNavigationSubmenu = (id: string): Action => ({
  type: types.OPEN_NAVIGATION_SUBMENU,
  payload: {
    id,
  },
});

export const closeNavigationSubmenu = (id: string): Action => ({
  type: types.CLOSE_NAVIGATION_SUBMENU,
  payload: {
    id,
  },
});
