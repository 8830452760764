import { InvoiceEligibility, InvoicePayBy, InvoiceType } from 'app/types';
import { checkIsOldInvoice } from 'app/features/invoicingProcess/process/helpers';
import { PaymentModeFormData } from 'app/features/invoicingProcess/process/paymentMode/form/types';

interface Params {
  invoiceType: InvoiceType | undefined;
  isPrimaryCareSpeciality: boolean;
  paymentModeValues: Partial<PaymentModeFormData>;
}
export const getPatientShareLimit = ({ invoiceType, isPrimaryCareSpeciality, paymentModeValues }: Params): number => {
  if (paymentModeValues.payBy?.value === InvoicePayBy.Insurance) {
    // 1. Limit for pay by insurance mode

    if (checkIsOldInvoice(invoiceType)) {
      // 1.1 Limit for old invoices
      return Number(paymentModeValues.maxPatientShare || 0);
    }

    if (paymentModeValues.eligibility?.value === InvoiceEligibility.ReferralIn) {
      // 1.2 Limit for Referral-in eligibility
      return Number(paymentModeValues.referralToSpecializedClinicMaxPatientShare || 0);
    }

    // 1.3 For other cases, the limit is based on the isPrimaryCareSpeciality value
    return isPrimaryCareSpeciality
      ? Number(paymentModeValues.primaryClinicMaxPatientShare || 0)
      : Number(paymentModeValues.specializedClinicMaxPatientShare || 0);
  }

  // 2. Return 0 SAR if not pay by insurance
  return 0;
};
