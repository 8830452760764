import { RemoteDataParams } from 'app/types';

type ClinicRelatedFilters = {
  branchId?: string;
  cityId?: string;
  clinicId?: string;
};

type DoctorRelatedFilters = {
  doctorIds: string[];
  gender?: string;
  languages: string[];
  ranks: string[];
};

type ProductsRelatedFilters = {
  customDurationInMinutes?: string;
  productNames?: string[];
  searchStrategy?: ProductNameSearchStrategy;
  specialityId: string;
  subSpecialityId: string;
};

type ProductRelatedFilters = {
  customDurationInMinutes?: string;
  productId: string;
  specialityId: string;
  subSpecialityId: string;
};

export enum ProductNameSearchStrategy {
  AnyOfKeywordsContainsProductName = 1,
  AnyOfKeywordsIsEqualsToProductName = 2,
}

export interface CalendarSearchParams extends RemoteDataParams {
  clinicRelatedFilters: ClinicRelatedFilters;
  date: string;
  doctorRelatedFilters: DoctorRelatedFilters;
  keyword?: string;
  productRelatedFilters: ProductsRelatedFilters;
  timeFrom: string;
  timeTo: string;
}

export type CalendarMonthParams = {
  branchId: string;
  date: string;
  doctorId: string;
  productRelatedFilters: ProductRelatedFilters;
  timeFrom: string;
  timeTo: string;
};

export type CalendarWeekParams = {
  branchId: string;
  date: string;
  doctorId: string;
  productRelatedFilters: ProductRelatedFilters;
  timeFrom: string;
  timeTo: string;
};

export type CalendarDayParams = {
  branchId: string;
  date: string;
  doctorId: string;
  productRelatedFilters: ProductRelatedFilters;
  ignoredAppointmentIds?: string[];
};

export interface CalendarDoctorsParams extends RemoteDataParams {
  branchId: string;
  genders?: string[];
  keyword?: string;
  languages?: string[];
  nationalities?: string[];
  productId: string;
  ranks?: string[];
  specialityId: string;
  subSpecialityId: string;
}
