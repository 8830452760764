import { Package } from 'app/types';

export enum ModalMode {
  Add = '0',
  Edit = '1',
  View = '2',
}

export interface PractitionerPackagesBody {
  performerIds?: string[];
  items: PractitionerPackageItem[];
}

export interface PractitionerPackageItem {
  quantity: number;
  pricePerUnit: number;
  branchBillingItemId: string;
  enabled: boolean;
}

export interface BillingPractitionerPackagesReducer {
  modalMode: ModalMode;
  data: Package | null;
  totalPrice: number;
  totalOriginalPrice: number;
  loading: boolean;
  error: boolean;
}
