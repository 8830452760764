import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQueryWithReAuth } from 'app/helpers';
import { GetTranslationParams, GetTranslationResponse } from 'app/services/general/types';

export const generalApi = createApi({
  reducerPath: 'generalApi',
  baseQuery: baseQueryWithReAuth,
  tagTypes: [],
  endpoints: (builder) => ({
    getTranslation: builder.query<GetTranslationResponse, GetTranslationParams>({
      query: (params) => ({
        url: 'translation/google-translate',
        method: 'POST',
        params,
      }),
    }),
  }),
});

export const { useLazyGetTranslationQuery } = generalApi;
