import { takeEvery } from 'redux-saga/effects';
import {
  BULK_ACTION_CONFLICT_APPOINTMENTS_SUCCESS,
  CANCEL_ALL_CONFLICT_APPOINTMENTS_SUCCESS,
  CANCEL_CONFLICT_APPOINTMENT_SUCCESS,
  CANCEL_CONFLICT_WAITING_APPOINTMENT_SUCCESS,
  REBOOK_CONFLICT_APPOINTMENT_SUCCESS,
} from 'app/redux/conflict-appointments/conflict-appointments.types';
import { afterBulkAction, afterCancelAllWaitingAppointments, afterCancelOrRebookAppointment } from './generators';

function* conflictAppointmentSaga() {
  yield takeEvery(
    [
      CANCEL_CONFLICT_APPOINTMENT_SUCCESS,
      CANCEL_CONFLICT_WAITING_APPOINTMENT_SUCCESS,
      REBOOK_CONFLICT_APPOINTMENT_SUCCESS,
    ],
    afterCancelOrRebookAppointment,
  );
  yield takeEvery([BULK_ACTION_CONFLICT_APPOINTMENTS_SUCCESS], afterBulkAction);
  yield takeEvery([CANCEL_ALL_CONFLICT_APPOINTMENTS_SUCCESS], afterCancelAllWaitingAppointments);
}

export default conflictAppointmentSaga;
