export const PRICE_VALUE_MIN = 0;
export const PRICE_VALUE_MAX = 99999999;
export const PRICE_ERROR = 'ERRORS.PRICE';

export const price = (value: number | string | undefined): string | undefined => {
  if (value === undefined || value === '') {
    return;
  }

  const numberValue = typeof value === 'string' ? Number(value) : value;
  const decimalLength = String(numberValue).split('.')?.[1]?.length || 0;

  return decimalLength > 2 || numberValue < PRICE_VALUE_MIN || numberValue > PRICE_VALUE_MAX ? PRICE_ERROR : undefined;
};
