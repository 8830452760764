import { useCallback } from 'react';
import { useAppDispatch } from 'app/helpers';
import { toggleModal } from 'app/redux/modals/modals.actions';

export const useOnCreditNote = () => {
  const dispatch = useAppDispatch();

  return useCallback(async () => {
    dispatch(toggleModal('creditNote', true));
  }, [dispatch]);
};
