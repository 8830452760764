import { ColumnFormatter } from 'react-bootstrap-table-next';
import moment from 'moment';
import { formattedPeriod, useAppIntl, useAppSelector } from 'app/helpers';
import { CalendarItem } from 'app/types';
import { checkAvailableSlotsInDay } from 'app/calendar/helpers';
import { TimeFormatterHeader } from 'app/bookAppointment/bookAppointmentWizard/shared/timeFormatter/timeFormatterHeader/timeFormatterHeader';
import { TimeFormatterSlots } from 'app/bookAppointment/bookAppointmentWizard/shared/timeFormatter/timeFormatterSlots/timeFormatterSlots';
import { TimeFormatterFooter } from 'app/bookAppointment/bookAppointmentWizard/shared/timeFormatter/timeFormatterFooter/timeFormatterFooter';
import {
  useManageWaiting,
  useSelectSlot,
} from 'app/bookAppointment/bookAppointmentWizard/shared/timeFormatter/helpers';
import 'app/bookAppointment/bookAppointmentWizard/shared/timeFormatter/timeFormatter.scss';

const Time = ({ branch, days, doctor, id, speciality, subSpeciality, product }: CalendarItem) => {
  const { formatMessage } = useAppIntl();

  // Selectors
  const isWithNoTimeMode = useAppSelector((state) => state.calendar.tableView.withNoTimeItems.includes(id));
  const selectedDays = useAppSelector(
    (state) => state.calendar.tableView.selectedDays,
    () => false,
  );
  const selectedDay = selectedDays.find(
    (day) => day.branchId === branch.id && day.doctorId === doctor.id && day.productId === product.value,
  );
  const customProductDuration = useAppSelector(
    (state) => state.calendar.tableViewItems.params?.productRelatedFilters.customDurationInMinutes,
  );

  // Actions
  const manageWaiting = useManageWaiting(branch.id, doctor, product, speciality, subSpeciality);
  const selectSlot = useSelectSlot(branch.id, doctor, product, speciality, subSpeciality);

  if (selectedDay) {
    const day = days.find((day) => moment(day.date).isSame(selectedDay.date, 'date'));

    if (day) {
      const hasWorkingTimes = !!day.workingTimesWithSlots.length;
      const hasAvailableSlots = checkAvailableSlotsInDay(day);
      const hasBookWithNoTime = isWithNoTimeMode && (!hasWorkingTimes || hasAvailableSlots);

      return (
        <div className="timeFormatter">
          <TimeFormatterHeader day={day} isWithNoTimeMode={isWithNoTimeMode} manageWaiting={manageWaiting} />
          {day.workingTimesWithSlots.map((wt) => {
            if (!wt.slots.length) {
              // Working Time is empty
              return null;
            }

            return (
              <div className="timeFormatter__workingTime" key={wt.workingTimeStart}>
                <h6>{formattedPeriod(wt.workingTimeStart, wt.workingTimeEnd)}</h6>
                {!isWithNoTimeMode && (
                  <TimeFormatterSlots
                    date={day.date}
                    productDuration={customProductDuration ? Number(customProductDuration) : wt.defaultProductDuration}
                    selectSlot={selectSlot}
                    slots={wt.slots}
                    workingTimeEnd={wt.workingTimeEnd}
                  />
                )}
              </div>
            );
          })}
          <TimeFormatterFooter bookWithNoTime={() => selectSlot(day.date)} hasBookWithNoTime={hasBookWithNoTime} />
        </div>
      );
    }
  }

  // The user did not select a day
  return <div className="text-center">{formatMessage({ id: 'CALENDAR-TABLE.TEXT.SELECT-DAY' })}</div>;
};

export const TimeFormatter: ColumnFormatter<CalendarItem> = (cell, row) => <Time {...row} />;
