import { useCallback, useRef } from 'react';
import { useAppSelector, useConfirmationModal } from 'app/helpers';

export const useWithoutPaymentConfirmation = () => {
  const triggerRef = useRef<Function>();
  const items = useAppSelector((state) => state.invoicingProcess.items);
  const paymentMethodItems = useAppSelector((state) => state.invoicingProcess.paymentMethodItems);
  const { confirmationModal, closeConfirmationModal, openConfirmationModal } = useConfirmationModal();

  const checkWithoutPaymentConfirmation = useCallback(
    (trigger: Function) => {
      const hasPaymentMethodItems = !!paymentMethodItems.length;
      const isFullDiscount = items.every((item) => item.discount.value === 100);
      const isZeroPrice = items.every((item) => item.price === 0);

      if (!hasPaymentMethodItems && !isFullDiscount && !isZeroPrice) {
        triggerRef.current = trigger;
        openConfirmationModal();
      } else {
        trigger();
      }
    },
    [items, openConfirmationModal, paymentMethodItems.length],
  );

  const onConfirmWithoutPaymentConfirmation = useCallback(() => {
    if (triggerRef.current) {
      triggerRef.current();
      closeConfirmationModal();
    }
  }, [closeConfirmationModal]);

  return {
    withoutPaymentConfirmation: confirmationModal,
    onCancelWithoutPaymentConfirmation: closeConfirmationModal,
    onConfirmWithoutPaymentConfirmation,
    checkWithoutPaymentConfirmation,
  };
};
