import { useEffect } from 'react';
import hotjar from 'react-use-hotjar';

const HOTJAR_ID = Number(process.env.REACT_APP_HJID);

export const useHotjar = () => {
  const { initHotjar } = hotjar();

  useEffect(() => {
    if (!!HOTJAR_ID) {
      initHotjar(HOTJAR_ID, 6);
    }
  }, [initHotjar]);
};
