import { RSAA } from 'redux-api-middleware';
import { DICTIONARIES_LIMIT } from 'app/shared';
import * as types from './clinic.types';

export const addClinic = (body, registration) => {
  return {
    [RSAA]: {
      types: [
        types.ADD_CLINIC_REQUEST,
        {
          type: types.ADD_CLINIC_SUCCESS,
          meta: {
            registration,
            notification: {
              type: 'success',
              title: 'CLINICS.NOTIFICATIONS.ADD-SUCCESS',
            },
          },
        },
        {
          type: types.ADD_CLINIC_FAILURE,
          meta: {
            notification: {
              type: 'danger',
              title: 'CLINICS.NOTIFICATIONS.ADD-FAILURE',
            },
          },
        },
      ],
      endpoint: '/clinics',
      method: 'POST',
      body,
    },
  };
};

export const fetchClinic = (clinicId) => {
  return {
    [RSAA]: {
      endpoint: `/clinics/${clinicId}`,
      method: 'GET',
      types: [
        types.FETCH_CLINIC_REQUEST,
        {
          type: types.FETCH_CLINIC_SUCCESS,
          meta: { clinicId },
        },
        types.FETCH_CLINIC_FAILURE,
      ],
    },
  };
};

export const fetchClinicMode = (clinicId) => {
  return {
    [RSAA]: {
      endpoint: `/clinics/${clinicId}/mode`,
      method: 'GET',
      types: [types.FETCH_CLINIC_MODE_REQUEST, types.FETCH_CLINIC_MODE_SUCCESS, types.FETCH_CLINIC_MODE_FAILURE],
    },
  };
};

export const editClinic = (body, clinicId, editPersisted) => {
  return {
    [RSAA]: {
      types: [
        types.EDIT_CLINIC_REQUEST,
        {
          type: types.EDIT_CLINIC_SUCCESS,
          meta: {
            notification: {
              type: 'success',
              title: editPersisted ? 'CLINICS.NOTIFICATIONS.EDIT-SUCCESS-SHORT' : 'CLINICS.NOTIFICATIONS.EDIT-SUCCESS',
            },
          },
        },
        {
          type: types.EDIT_CLINIC_FAILURE,
          meta: {
            notification: {
              type: 'danger',
              title: 'CLINICS.NOTIFICATIONS.EDIT-FAILURE',
            },
          },
        },
      ],
      endpoint: `/clinics/${clinicId}`,
      method: 'PUT',
      body,
    },
  };
};

export const editOwner = (body, clinicId) => {
  return {
    [RSAA]: {
      types: [
        types.EDIT_SYSTEM_OWNER_REQUEST,
        {
          type: types.EDIT_SYSTEM_OWNER_SUCCESS,
          meta: {
            notification: {
              type: 'success',
              title: 'CLINICS.NOTIFICATIONS.EDIT-SYSTEM-OWNER-SUCCESS',
            },
            clinicId,
          },
        },
        types.EDIT_SYSTEM_OWNER_FAILURE,
      ],
      endpoint: `/clinics/${clinicId}/systemOwner`,
      method: 'PUT',
      body,
    },
  };
};

export const updateClinic = (clinicId, body) => {
  return {
    [RSAA]: {
      types: [types.EDIT_CLINIC_REQUEST, types.EDIT_CLINIC_SUCCESS, types.EDIT_CLINIC_FAILURE],
      endpoint: `/clinics/${clinicId}`,
      ...body,
    },
  };
};

export const setClinic = (clinic) => {
  return {
    type: types.SET_CLINIC,
    payload: clinic,
  };
};

export const approveClinic = (clinicId) => {
  return {
    [RSAA]: {
      types: [types.APPROVE_CLINIC_REQUEST, types.APPROVE_CLINIC_SUCCESS, types.APPROVE_CLINIC_FAILURE],
      endpoint: `/clinics/${clinicId}/approve`,
      method: 'POST',
    },
  };
};

export const rejectClinic = (clinicId, reason) => {
  return {
    [RSAA]: {
      types: [types.REJECT_CLINIC_REQUEST, types.REJECT_CLINIC_SUCCESS, types.REJECT_CLINIC_FAILURE],
      body: reason,
      endpoint: `/clinics/${clinicId}/reject`,
      method: 'POST',
    },
  };
};

export const fetchClinicSystemOwner = (clinicId) => {
  return {
    [RSAA]: {
      types: [types.FETCH_SYSTEM_OWNER_REQUEST, types.FETCH_SYSTEM_OWNER_SUCCESS, types.FETCH_SYSTEM_OWNER_FAILURE],
      endpoint: `/clinics/${clinicId}/systemOwner`,
      method: 'GET',
    },
  };
};

export const fetchClinicSpecialities = (clinicId) => {
  return {
    [RSAA]: {
      types: [
        types.FETCH_CLINIC_SPECIALITIES_REQUEST,
        types.FETCH_CLINIC_SPECIALITIES_SUCCESS,
        types.FETCH_CLINIC_SPECIALITIES_FAIL,
      ],
      endpoint: `/clinics/${clinicId}/specialities`,
      method: `GET`,
    },
  };
};

export const fetchClinicBranches = (id) => ({
  [RSAA]: {
    endpoint: `/clinics/${id}/branches`,
    method: 'GET',
    params: {
      pageSize: DICTIONARIES_LIMIT,
      statuses: 'online',
    },
    types: [types.FETCH_CLINIC_BRANCHES_REQUEST, types.FETCH_CLINIC_BRANCHES_SUCCESS, types.FETCH_CLINIC_BRANCHES_FAIL],
  },
});
