import React from 'react';
import { IconAlertCircle, IconTrafficCone } from '@tabler/icons-react';
import { useAppIntl } from 'app/helpers';
import { Alert, AlertType } from 'app/redux/mass-adding/types';

interface Props {
  item: Alert;
  type: AlertType;
  listKey: string;
}

export const AlertListItem = ({ item, type, listKey }: Props) => {
  const { formatMessage } = useAppIntl();

  // Conditions
  const isError = type === 'danger';
  const isWarning = type === 'warning';

  return (
    <div>
      <div className="d-flex mb-3">
        {isError && <IconAlertCircle size={19} strokeWidth={1.8} />}
        {isWarning && <IconTrafficCone size={19} strokeWidth={1.8} />}
        <h6 className="mb-0 ms-1">
          {formatMessage({ id: 'CORE.TEXT.ROW' })}: {item.row}
        </h6>
      </div>
      <ul className="m-0 mb-3 fs-6">
        {/* TODO: Improvement for error translations from BE */}
        {item[listKey].map((error, index) => (
          <li key={`error_${index}`}>{error}</li>
        ))}
      </ul>
    </div>
  );
};
