import { connect } from 'react-redux';
import { toggleModal } from 'app/redux/modals/modals.actions';
import { AppointmentTicketModalComponent } from './appointment-ticket-modal.component';

const mapStateToProps = ({ modal }) => ({
  isOpen: modal.appointmentTicket.isOpen,
});

const mapDispatchToProps = (dispatch) => ({
  toggle: () => dispatch(toggleModal('appointmentTicket')),
});

export const AppointmentTicketModalContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
)(AppointmentTicketModalComponent);
