export const FETCH_CONFLICTS_REQUEST = 'conflicts/fetch/REQUEST';
export const FETCH_CONFLICTS_SUCCESS = 'conflicts/fetch/SUCCESS';
export const FETCH_CONFLICTS_FAILURE = 'conflicts/fetch/FAILURE';

export const FETCH_CLINIC_CONFLICTS_REQUEST = 'clinic-conflicts/fetch/REQUEST';
export const FETCH_CLINIC_CONFLICTS_SUCCESS = 'clinic-conflicts/fetch/SUCCESS';
export const FETCH_CLINIC_CONFLICTS_FAILURE = 'clinic-conflicts/fetch/FAILURE';

export const FETCH_APPOINTMENT_CONFLICTS_REQUEST = 'appointment-conflicts/fetch/REQUEST';
export const FETCH_APPOINTMENT_CONFLICTS_SUCCESS = 'appointment-conflicts/fetch/SUCCESS';
export const FETCH_APPOINTMENT_CONFLICTS_FAILURE = 'appointment-conflicts/fetch/FAILURE';
