export const FETCH_BRANCH_BILLING_ITEM_REQUEST = 'branchBillingItem/FETCH_BRANCH_BILLING_ITEM_REQUEST';
export const FETCH_BRANCH_BILLING_ITEM_SUCCESS = 'branchBillingItem/FETCH_BRANCH_BILLING_ITEM_SUCCESS';
export const FETCH_BRANCH_BILLING_ITEM_FAILURE = 'branchBillingItem/FETCH_BRANCH_BILLING_ITEM_FAILURE';

export const ADD_BRANCH_BILLING_ITEM_REQUEST = 'branchBillingItem/ADD_BRANCH_BILLING_ITEM_REQUEST';
export const ADD_BRANCH_BILLING_ITEM_SUCCESS = 'branchBillingItem/ADD_BRANCH_BILLING_ITEM_SUCCESS';
export const ADD_BRANCH_BILLING_ITEM_FAILURE = 'branchBillingItem/ADD_BRANCH_BILLING_ITEM_FAILURE';

export const EDIT_BRANCH_BILLING_ITEM_REQUEST = 'branchBillingItem/EDIT_BRANCH_BILLING_ITEM_REQUEST';
export const EDIT_BRANCH_BILLING_ITEM_SUCCESS = 'branchBillingItem/EDIT_BRANCH_BILLING_ITEM_SUCCESS';
export const EDIT_BRANCH_BILLING_ITEM_FAILURE = 'branchBillingItem/EDIT_BRANCH_BILLING_ITEM_FAILURE';

export const REMOVE_BRANCH_BILLING_ITEM_REQUEST = 'branchBillingItem/REMOVE_BRANCH_BILLING_ITEM_REQUEST';
export const REMOVE_BRANCH_BILLING_ITEM_SUCCESS = 'branchBillingItem/REMOVE_BRANCH_BILLING_ITEM_SUCCESS';
export const REMOVE_BRANCH_BILLING_ITEM_FAILURE = 'branchBillingItem/REMOVE_BRANCH_BILLING_ITEM_FAILURE';

export const EXPORT_BRANCH_BILLING_ITEMS_REQUEST = 'branchBillingItem/EXPORT_BRANCH_BILLING_ITEMS_REQUEST';
export const EXPORT_BRANCH_BILLING_ITEMS_SUCCESS = 'branchBillingItem/EXPORT_BRANCH_BILLING_ITEMS_SUCCESS';
export const EXPORT_BRANCH_BILLING_ITEMS_FAILURE = 'branchBillingItem/EXPORT_BRANCH_BILLING_ITEMS_FAILURE';

export const CHECK_INTERNAL_CODE_REQUEST = 'branchBillingItem/CHECK_INTERNAL_CODE_REQUEST';
export const CHECK_INTERNAL_CODE_SUCCESS = 'branchBillingItem/CHECK_INTERNAL_CODE_SUCCESS';
export const CHECK_INTERNAL_CODE_FAILURE = 'branchBillingItem/CHECK_INTERNAL_CODE_FAILURE';

export const WS_UPDATE_BILLING_ITEMS_TABLE = 'branchBillingItem/WS_UPDATE_BILLING_ITEMS_TABLE';
