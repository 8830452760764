import * as types from './massAddingContracts.types';
import { AnyAction } from 'redux';
import { MassAdding } from './types';

interface MassAddingContractsReducer {
  contracts: MassAdding;
}

const initialState: MassAddingContractsReducer = {
  contracts: {
    errors: [],
    warnings: [],
    loading: false,
    sent: false,
  },
};

export const massAddingContractsReducer = (state = initialState, action: AnyAction): MassAddingContractsReducer => {
  switch (action.type) {
    // Contracts
    case types.MASS_ADD_CONTRACTS_FAILURE:
      return {
        ...state,
        contracts: {
          errors: action.payload.response,
          warnings: [],
          loading: false,
          sent: true,
        },
      };
    case types.MASS_ADD_CONTRACTS_SUCCESS:
      return {
        ...state,
        contracts: {
          errors: [],
          warnings: action.payload,
          loading: false,
          sent: true,
        },
      };
    case types.MASS_ADD_CONTRACTS_REQUEST:
      return {
        ...state,
        contracts: {
          errors: [],
          warnings: [],
          loading: true,
          sent: false,
        },
      };
    case types.MASS_ADD_CONTRACTS_RESET:
      return {
        ...state,
        contracts: initialState.contracts,
      };

    default:
      return state;
  }
};
