import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQueryWithReAuth } from 'app/helpers';
import { Doctor, Product, QueryParams, QueryResponse, QueryResponseWithPagination } from 'app/types';
import { DoctorsQueryParams } from 'app/services/doctors/types';

export const doctorsApi = createApi({
  reducerPath: 'doctorsApi',
  baseQuery: baseQueryWithReAuth,
  tagTypes: ['Doctors', 'Doctor', 'DoctorProducts'],
  endpoints: (builder) => ({
    getDoctor: builder.query<QueryResponse<Doctor>, { doctorId: string }>({
      query: ({ doctorId }) => ({
        url: `doctors/${doctorId}`,
        method: 'GET',
      }),
      providesTags: ['Doctor'],
    }),
    getClinicDoctors: builder.query<
      QueryResponseWithPagination<Doctor[]>,
      { clinicId: string; params: DoctorsQueryParams }
    >({
      query: ({ clinicId, params }) => ({
        url: `/clinics/${clinicId}/doctors`,
        method: 'GET',
        params,
      }),
      providesTags: ['Doctors'],
    }),
    getClinicDeletedDoctors: builder.query<
      QueryResponseWithPagination<Doctor[]>,
      { clinicId: string; params: DoctorsQueryParams }
    >({
      query: ({ clinicId, params }) => ({
        url: `/clinics/${clinicId}/doctors/deleted`,
        method: 'GET',
        params,
      }),
      providesTags: ['Doctors'],
    }),
    getProductsTemplate: builder.query<Product[], { branchId: string; params: QueryParams | undefined }>({
      query: ({ branchId, params }) => ({
        url: `/branches/${branchId}/products/template`,
        params,
      }),
      providesTags: ['DoctorProducts'],
    }),

    getWorkingTimeProducts: builder.query<Product[], { workingTimeId: string; params: QueryParams | undefined }>({
      query: ({ workingTimeId, params }) => ({
        url: `/working-hours/${workingTimeId}/products`,
        params,
      }),
      providesTags: ['DoctorProducts'],
    }),
  }),
});

export const {
  useGetDoctorQuery,
  useGetClinicDoctorsQuery,
  useGetClinicDeletedDoctorsQuery,
  useGetProductsTemplateQuery,
  useGetWorkingTimeProductsQuery,
} = doctorsApi;
