import { RSAA } from 'redux-api-middleware';
import isPlainObject from 'lodash/isPlainObject';

function isJSONLikeObject(value) {
  return isPlainObject(value) || Array.isArray(value);
}

export const bodyMiddleware = () => (next) => (action) => {
  const apiCall = action[RSAA];

  if (!apiCall) {
    return next(action);
  }

  const isJSON = isJSONLikeObject(apiCall.body);

  return next({
    [RSAA]: {
      ...apiCall,
      headers:
        isJSON && typeof apiCall.headers !== 'function'
          ? {
              ...apiCall.headers,
              'Content-Type': 'application/json',
            }
          : apiCall.headers,
      body: isJSON ? JSON.stringify(apiCall.body) : apiCall.body,
    },
  });
};
