import { formattedPeriod } from 'app/helpers';
import { SlotOption } from 'app/appointment/form/types';

export const generateSlotOption = (
  start: string,
  end?: string | null,
  numberOfAppointments: number | null = null,
  noTimeMessage: string = '-',
): SlotOption => {
  if (start && end) {
    return {
      label: formattedPeriod(start, end),
      value: {
        start: start,
        end: end,
      },
      ...(numberOfAppointments !== null && {
        details: {
          numberOfAppointments,
        },
      }),
    };
  }

  return {
    label: noTimeMessage,
    value: {
      start: start,
      end: null,
    },
  };
};
