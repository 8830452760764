import { IntlFormatters } from '@formatjs/intl/src/types';
import { InvoicePaymentMethodItem, Locale } from 'app/types';

export interface FormatExtraData {
  deletePaymentMethod?: (item: InvoicePaymentMethodItem) => void;
  editPaymentMethod?: (item: InvoicePaymentMethodItem) => void;
  formatMessage?: IntlFormatters['formatMessage'];
  locale?: Locale;
}

export enum FormMode {
  Add = 'add',
  Edit = 'edit',
}
