import { connect } from 'react-redux';
import get from 'lodash/get';

import PrivateSectionComponent from './private-section.component';

const mapStateToProps = (state) => ({
  userRoles: get(state, 'auth.current.roles', []),
});

export const PrivateSectionContainer = connect(mapStateToProps)(PrivateSectionComponent);
