import { put, select } from 'redux-saga/effects';
import { getApiDate } from 'app/helpers';
import { RootState } from 'app/types';
import { fetchDay } from 'app/redux/appointment/appointment.actions';
import { fetchBookedWaitingAppointments } from 'app/redux/remoteData/remoteData.actions';
import { CalendarDayParams } from 'app/redux/calendar/types';

/**
 This function refreshes the data visible in the "Manage Waiting" modal if this modal is open
 */
export function* refreshManageWaiting(isSilent: boolean = false) {
  const {
    calendar: { selectedWaitingSlot, tableViewItems },
    modal: {
      manageWaitingAppointments: { isOpen },
    },
    remoteData: { bookedAppointments },
  }: RootState = yield select();

  // Selected customDurationInMinutes value
  const customDurationInMinutes = tableViewItems.params?.productRelatedFilters.customDurationInMinutes;

  if (isOpen && selectedWaitingSlot) {
    const calendarDayParams: CalendarDayParams = {
      branchId: selectedWaitingSlot.branchId,
      date: getApiDate(selectedWaitingSlot.date),
      doctorId: selectedWaitingSlot.doctor.id,
      productRelatedFilters: {
        customDurationInMinutes,
        productId: selectedWaitingSlot.product.value,
        specialityId: selectedWaitingSlot.speciality.value,
        subSpecialityId: selectedWaitingSlot.subSpeciality.value,
      },
    };

    // Refresh Available Waiting Appointments
    yield put<any>(fetchDay(calendarDayParams, isSilent));

    if (bookedAppointments.params) {
      // Refresh Booked Waiting Appointments
      yield put<any>(fetchBookedWaitingAppointments(bookedAppointments.params, calendarDayParams, isSilent));
    }
  }
}
