import { v4 } from 'uuid';
import classnames from 'classnames';
import { useCallback, useMemo } from 'react';
import { FormattedMessage } from 'react-intl';
import { WrappedFieldProps } from 'redux-form/lib/Field';
import { FormFeedback, FormGroup, Input, Label } from 'reactstrap';
import type { Option } from 'app/types';
import { getTranslation, useAppIntl } from 'app/helpers';
import { CustomLabel } from 'app/components/customLabel/customLabel';

export interface MultiCheckboxComponentProps {
  className?: string;
  disabled?: boolean;
  isRequired?: boolean;
  label?: string;
  options: Option[];
}

export const MultiCheckboxComponent = ({
  className,
  disabled,
  input: { onChange, value },
  isRequired,
  label,
  meta,
  options,
}: WrappedFieldProps & MultiCheckboxComponentProps) => {
  const { locale } = useAppIntl();
  const inputValue = value as string[] | null | undefined;
  const invalid = !!meta.error && meta.touched;
  const fieldId = useMemo(() => `mc-${v4()}`, []);

  const handleOnChange = useCallback(
    (checkboxValue: string) => {
      if (inputValue) {
        if (inputValue && inputValue.includes(checkboxValue)) {
          onChange(inputValue.filter((v) => v !== checkboxValue));
        } else {
          onChange([...inputValue, checkboxValue]);
        }
      } else {
        onChange([checkboxValue]);
      }
    },
    [inputValue, onChange],
  );

  return (
    <div className={classnames('mb-3', className, { 'is-invalid': invalid })}>
      {label && <CustomLabel isRequired={isRequired} label={label} />}
      {options.map((option, index) => {
        const id = `${fieldId}-${option.value}`;
        return (
          <FormGroup check key={index}>
            <Input disabled={disabled} id={id} onChange={() => handleOnChange(option.value)} type="checkbox" />
            <Label for={id} check>
              {getTranslation(option, locale) || option.label}
            </Label>
          </FormGroup>
        );
      })}
      {invalid && (
        <FormFeedback className="d-block">
          <FormattedMessage id={meta.error} />
        </FormFeedback>
      )}
    </div>
  );
};
