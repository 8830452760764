import * as types from './invoices.types';
import { PAGINATION_DEFAULT_PARAMS } from 'app/shared/constants/pagination.constants';

const initialState = {
  loading: false,
  params: {
    ...PAGINATION_DEFAULT_PARAMS,
  },
  error: false,
  invoicesToPay: {
    loading: false,
    error: false,
    data: [],
    params: {
      ...PAGINATION_DEFAULT_PARAMS,
    },
  },
};

export const invoicesReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.FETCH_PATIENT_INVOICES_TO_PAY_REQUEST:
      return {
        ...state,
        invoicesToPay: {
          ...state.invoicesToPay,
          data: [],
          loading: true,
          error: false,
        },
      };
    case types.FETCH_PATIENT_INVOICES_TO_PAY_SUCCESS:
      return {
        ...state,
        invoicesToPay: {
          ...state.invoicesToPay,
          data: action.payload.data,
          params: {
            ...state.invoicesToPay.params,
            ...action.payload.pagination,
          },
          loading: false,
          error: false,
        },
      };
    case types.FETCH_PATIENT_INVOICES_TO_PAY_FAILURE:
      return {
        ...state,
        invoicesToPay: {
          data: action.payload,
          loading: false,
          error: true,
        },
      };
    case types.SET_PATIENT_INVOICES_PARAMS:
      return {
        ...state,
        invoicesToPay: {
          ...state.invoicesToPay,
          params: {
            ...state.params,
            ...action.meta,
          },
        },
      };
    case types.ADD_INVOICE_PAYMENTS_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case types.ADD_INVOICE_PAYMENTS_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case types.ADD_INVOICE_PAYMENTS_FAILURE:
      return {
        ...state,
        loading: false,
        error: true,
      };
    default:
      return state;
  }
};
