import React from 'react';
import { Col } from 'reactstrap';
import { Field } from 'redux-form';
import { AsyncSelectComponent } from 'app/shared/form-elements/select/asyncSelect/asyncSelect.component';
import { PatientSearchField } from 'app/shared/patientSearch/types';
import { required } from 'app/shared/validation';
import { useOptionFormatter } from 'app/shared/patientSearch/patientSearchFilters/helpers/useOptionFormatter';
import { useFetchOptions } from 'app/shared/patientSearch/patientSelectedDetails/helpers/useFetchOptions';
import { useAppIntl } from 'app/helpers';

interface Props {
  clinicId?: string;
  patientId?: string;
  isDependent?: boolean;
  isDisabled?: boolean;
  size?: 'sm' | 'lg';
}

export const PatientSelectedDetails = ({ patientId, clinicId, isDependent, isDisabled, size }: Props) => {
  const { formatMessage } = useAppIntl();
  const optionFormatter = useOptionFormatter();
  const { fetchPhoneNumbers, fetchGuardian } = useFetchOptions({ clinicId, patientId });

  return (
    <>
      <Col md={6}>
        <Field
          name={PatientSearchField.selectedPhoneNumber}
          component={AsyncSelectComponent}
          label={formatMessage({ id: 'CORE.LABEL.MOBILE-NUMBER' })}
          fetchOptions={fetchPhoneNumbers}
          formatOptionLabel={optionFormatter}
          disabled={isDisabled || !patientId || !clinicId}
          validate={[required]}
          cacheUniqs={[patientId]}
          size={size}
          isRequired
        />
      </Col>
      {isDependent && (
        <Col md={6}>
          <Field
            name={PatientSearchField.selectedGuardian}
            component={AsyncSelectComponent}
            label={formatMessage({ id: 'CORE.LABEL.GUARDIAN', defaultMessage: 'Parent/Guardian/Caregiver' })}
            fetchOptions={fetchGuardian}
            disabled={isDisabled || !patientId}
            showClinicyId={false}
            cacheUniqs={[patientId]}
            size={size}
          />
        </Col>
      )}
    </>
  );
};
