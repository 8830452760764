import { RSAA } from 'redux-api-middleware';
import { DICTIONARIES_LIMIT } from 'app/shared';
import { workingTimeProductsAdapter } from './helpers';
import * as types from './doctor.types';

export const fetchDoctor = (doctorId) => {
  return {
    [RSAA]: {
      endpoint: `/doctors/${doctorId}`,
      method: 'GET',
      types: [types.FETCH_DOCTOR_REQUEST, types.FETCH_DOCTOR_SUCCESS, types.FETCH_DOCTOR_FAILURE],
    },
  };
};

export const addDoctor = (values, clinicId) => {
  return {
    [RSAA]: {
      types: [
        types.ADD_DOCTOR_REQUEST,
        {
          type: types.ADD_DOCTOR_SUCCESS,
          meta: { clinicId },
          payload: (action, state, res) => res.json(),
        },
        types.ADD_DOCTOR_FAILURE,
      ],
      endpoint: `/doctors`,
      method: 'POST',
      body: {
        ...values,
        clinicId,
      },
    },
  };
};

export const editDoctor = (values, clinicId, doctorId) => {
  return {
    [RSAA]: {
      types: [
        types.EDIT_DOCTOR_REQUEST,
        {
          type: types.EDIT_DOCTOR_SUCCESS,
          meta: { clinicId, doctorId },
        },
        types.EDIT_DOCTOR_FAILURE,
      ],
      endpoint: `/doctors/${doctorId}`,
      method: 'PUT',
      body: values,
    },
  };
};

export const fetchDoctorBusyTime = (doctorId, branchId, type, params) => {
  return {
    [RSAA]: {
      endpoint: `busy-times/doctors/${doctorId}/branches/${branchId}/${type}`,
      method: 'GET',
      types: [
        types.FETCH_DOCTOR_BUSY_TIME_REQUEST,
        types.FETCH_DOCTOR_BUSY_TIME_SUCCESS,
        types.FETCH_DOCTOR_BUSY_TIME_FAILURE,
      ],
      params,
    },
  };
};

export const addDoctorBusyTime = (body, doctorId, branchId) => {
  return {
    [RSAA]: {
      types: [
        types.ADD_DOCTOR_REQUEST,
        {
          type: types.ADD_DOCTOR_BUSY_TIME_SUCCESS,
          meta: { doctorId, branchId },
        },
        types.ADD_DOCTOR_FAILURE,
      ],
      endpoint: `busy-times/doctors/${doctorId}/branches/${branchId}`,
      method: 'POST',
      body,
    },
  };
};

export const endDoctorBusyTime = (doctorId, branchId, busyTimeId) => {
  return {
    [RSAA]: {
      types: [
        types.END_DOCTOR_BUSY_TIME_REQUEST,
        {
          type: types.END_DOCTOR_BUSY_TIME_SUCCESS,
          meta: { doctorId, branchId },
        },
        types.END_DOCTOR_BUSY_TIME_FAILURE,
      ],
      endpoint: `/busy-times/${busyTimeId}`,
      method: 'DELETE',
    },
  };
};

export const selectWorkingHours = (workingHours) => ({
  type: types.SELECT_WORKING_HOURS,
  payload: workingHours,
});

export const fetchDoctorCurrentWorkingHours = (doctorId, branchId, params) => {
  return {
    [RSAA]: {
      endpoint: `/doctors/${doctorId}/working-hours/${branchId}/current`,
      method: 'GET',
      types: [
        types.FETCH_DOCTOR_CURRENT_WORKING_HOURS_REQUEST,
        types.FETCH_DOCTOR_CURRENT_WORKING_HOURS_SUCCESS,
        types.FETCH_DOCTOR_CURRENT_WORKING_HOURS_FAILURE,
      ],
      params,
    },
  };
};

export const fetchDoctorCurrentCustomWorkingHours = (doctorId, branchId, params) => {
  return {
    [RSAA]: {
      endpoint: `/doctors/${doctorId}/custom-working-hours/${branchId}/current`,
      method: 'GET',
      types: [
        types.FETCH_DOCTOR_CURRENT_CUSTOM_WORKING_HOURS_REQUEST,
        types.FETCH_DOCTOR_CURRENT_CUSTOM_WORKING_HOURS_SUCCESS,
        types.FETCH_DOCTOR_CURRENT_CUSTOM_WORKING_HOURS_FAILURE,
      ],
      params,
    },
  };
};

export const fetchDoctorPastWorkingHours = (doctorId, branchId, params) => {
  return {
    [RSAA]: {
      endpoint: `/doctors/${doctorId}/working-hours/${branchId}/past`,
      method: 'GET',
      types: [
        types.FETCH_DOCTOR_PAST_WORKING_HOURS_REQUEST,
        types.FETCH_DOCTOR_PAST_WORKING_HOURS_SUCCESS,
        types.FETCH_DOCTOR_PAST_WORKING_HOURS_FAILURE,
      ],
      params,
    },
  };
};

export const fetchDoctorPastCustomWorkingHours = (doctorId, branchId, params) => {
  return {
    [RSAA]: {
      endpoint: `/doctors/${doctorId}/custom-working-hours/${branchId}/past`,
      method: 'GET',
      types: [
        types.FETCH_DOCTOR_PAST_CUSTOM_WORKING_HOURS_REQUEST,
        types.FETCH_DOCTOR_PAST_CUSTOM_WORKING_HOURS_SUCCESS,
        types.FETCH_DOCTOR_PAST_CUSTOM_WORKING_HOURS_FAILURE,
      ],
      params,
    },
  };
};

export const deleteWorkingHours = (workingHourId) => {
  return {
    [RSAA]: {
      endpoint: `/working-hours/${workingHourId}`,
      method: 'DELETE',
      types: [
        types.DELETE_DOCTOR_WORKING_HOURS_REQUEST,
        {
          type: types.DELETE_DOCTOR_WORKING_HOURS_SUCCESS,
          meta: { workingHourId },
        },
        types.DELETE_DOCTOR_WORKING_HOURS_FAILURE,
      ],
    },
  };
};

export const deleteCustomWorkingHours = (workingHourId) => {
  return {
    [RSAA]: {
      endpoint: `/custom-working-hours/${workingHourId}`,
      method: 'DELETE',
      types: [
        types.DELETE_DOCTOR_CUSTOM_WORKING_HOURS_REQUEST,
        {
          type: types.DELETE_DOCTOR_CUSTOM_WORKING_HOURS_SUCCESS,
          meta: { workingHourId },
        },
        types.DELETE_DOCTOR_CUSTOM_WORKING_HOURS_FAILURE,
      ],
    },
  };
};

export const addWorkingHours = (values, doctorId, branchId) => {
  return {
    [RSAA]: {
      endpoint: `/doctors/${doctorId}/working-hours/${branchId}`,
      method: 'POST',
      types: [
        types.ADD_DOCTOR_WORKING_HOURS_REQUEST,
        {
          type: types.ADD_DOCTOR_WORKING_HOURS_SUCCESS,
          meta: {
            doctorId,
            branchId,
          },
        },
        types.ADD_DOCTOR_WORKING_HOURS_FAIL,
      ],
      body: values,
    },
  };
};

export const addCustomWorkingHours = (values, doctorId, branchId) => {
  return {
    [RSAA]: {
      endpoint: `/doctors/${doctorId}/custom-working-hours/${branchId}`,
      method: 'POST',
      types: [
        types.ADD_DOCTOR_CUSTOM_WORKING_HOURS_REQUEST,
        {
          type: types.ADD_DOCTOR_CUSTOM_WORKING_HOURS_SUCCESS,
          meta: {
            doctorId,
            branchId,
            notification: {
              type: 'success',
              title: 'DOCTORS.TEXT.CUSTOM-WORKING-HOURS-SUCCESS',
            },
          },
        },
        types.ADD_DOCTOR_CUSTOM_WORKING_HOURS_FAIL,
      ],
      body: values,
    },
  };
};

export const editWorkingHours = (values, doctorId, branchId, isCustom = false) => ({
  [RSAA]: {
    endpoint: `${isCustom ? 'custom-working-hours' : 'working-hours'}/${values.id}`,
    method: 'PUT',
    types: [
      types.EDIT_DOCTOR_WORKING_HOURS_REQUEST,
      {
        type: types.EDIT_DOCTOR_WORKING_HOURS_SUCCESS,
        meta: {
          branchId,
          doctorId,
          isCustom,
        },
      },
      types.EDIT_DOCTOR_WORKING_HOURS_FAILURE,
    ],
    body: values,
  },
});

export const cancelWorkingHoursChanges = () => ({
  type: types.CANCEL_DOCTOR_WORKING_HOURS_CHANGES,
});

export const saveWorkingHoursChanges = (body, doctorId, branchId) => {
  return {
    [RSAA]: {
      types: [
        types.SAVE_DOCTOR_WORKING_HOURS_REQUEST,
        {
          type: types.SAVE_DOCTOR_WORKING_HOURS_SUCCESS,
          meta: { doctorId, branchId },
        },
        types.SAVE_DOCTOR_WORKING_HOURS_FAILURE,
      ],
      endpoint: `/doctors/${doctorId}/workingHours/${branchId}`,
      method: 'POST',
      body,
    },
  };
};

export const deleteMedicalService = (clinicId, doctorId, serviceId, isSilent) => {
  return {
    [RSAA]: {
      types: [
        { type: types.DELETE_MEDICAL_SERVICE_REQUEST, meta: { isSilent } },
        {
          type: types.DELETE_MEDICAL_SERVICE_SUCCESS,
          meta: {
            clinicId,
            doctorId,
            serviceId,
            notification: {
              type: 'success',
              title: 'DOCTORS.TEXT.SUB-SPECIALITY-REMOVED-SUCCESSFULLY',
            },
          },
        },
        {
          type: types.DELETE_MEDICAL_SERVICE_FAILURE,
          meta: {
            notification: {
              type: 'danger',
              title: 'CORE.TEXT.DEFAULT-FAILURE-MESSAGE',
            },
          },
        },
      ],
      endpoint: `/doctors/${doctorId}/subspecialities/${serviceId}`,
      method: 'DELETE',
    },
  };
};

export const addMedicalServices = (values, clinicId, doctorId) => {
  return {
    [RSAA]: {
      types: [
        types.ADD_MEDICAL_SERVICES_REQUEST,
        {
          type: types.ADD_MEDICAL_SERVICES_SUCCESS,
          meta: {
            notification: {
              type: 'success',
              title: 'DOCTORS.TEXT.SUB-SPECIALITY-ADDED-SUCCESSFULLY',
            },
            clinicId,
            doctorId,
          },
        },
        {
          type: types.ADD_MEDICAL_SERVICES_FAILURE,
          meta: {
            notification: {
              type: 'danger',
              title: 'DOCTORS.TEXT.WRONG-BRANCHES',
            },
          },
        },
      ],
      endpoint: `/doctors/${doctorId}/subspecialities`,
      method: 'POST',
      body: values,
    },
  };
};

export const editMedicalSpeciality = (values, clinicId, doctorId) => {
  return {
    [RSAA]: {
      types: [
        types.EDIT_MEDICAL_SPECIALITY_REQUEST,
        {
          type: types.EDIT_MEDICAL_SPECIALITY_SUCCESS,
          meta: { clinicId, doctorId },
        },
        types.EDIT_MEDICAL_SPECIALITY_FAILURE,
      ],
      endpoint: `/doctors/${doctorId}`,
      method: 'PUT',
      body: values,
    },
  };
};

export const addDoctorProduct = (values) => {
  return {
    [RSAA]: {
      endpoint: `/products`,
      method: 'POST',
      types: [types.ADD_DOCTOR_PRODUCT_REQUEST, types.ADD_DOCTOR_PRODUCT_SUCCESS, types.ADD_DOCTOR_PRODUCT_FAILURE],
      body: values,
    },
  };
};

export const fetchDoctorSubSpecialities = (doctorId, params, isSilent) => {
  return {
    [RSAA]: {
      endpoint: `/doctors/${doctorId}/subspecialities`,
      method: 'GET',
      params: {
        ...params,
        pageSize: DICTIONARIES_LIMIT,
      },
      types: [
        { type: types.FETCH_DOCTOR_SUB_SPECIALITIES_REQUEST, meta: { isSilent } },
        types.FETCH_DOCTOR_SUB_SPECIALITIES_SUCCESS,
        types.FETCH_DOCTOR_SUB_SPECIALITIES_FAIL,
      ],
    },
  };
};

export const setDoctorSelectedBranch = (branch) => {
  return { type: types.SET_DOCTOR_WORKING_HOUR_SELECTED_BRANCH, payload: branch };
};

export const deleteDoctor = (doctorId, clinicId) => {
  return {
    [RSAA]: {
      types: [
        types.DELETE_DOCTOR_REQUEST,
        {
          type: types.DELETE_DOCTOR_SUCCESS,
          meta: {
            clinicId,
          },
        },
        types.DELETE_DOCTOR_FAILURE,
      ],
      endpoint: `/doctors/${doctorId}`,
      method: 'DELETE',
    },
  };
};

export const fetchAssignableBranches = (doctorId) => {
  return {
    [RSAA]: {
      types: [
        types.FETCH_DOCTOR_ASSIGNABLE_BRANCHES_REQUEST,
        types.FETCH_DOCTOR_ASSIGNABLE_BRANCHES_SUCCESS,
        types.FETCH_DOCTOR_ASSIGNABLE_BRANCHES_FAILURE,
      ],
      endpoint: `/doctors/${doctorId}/assignable-branches`,
      method: 'GET',
    },
  };
};

export const fetchDoctorBranches = (doctorId) => {
  return {
    [RSAA]: {
      types: [
        types.FETCH_DOCTOR_BRANCHES_REQUEST,
        types.FETCH_DOCTOR_BRANCHES_SUCCESS,
        types.FETCH_DOCTOR_BRANCHES_FAILURE,
      ],
      endpoint: `/doctors/${doctorId}/branches`,
      method: 'GET',
    },
  };
};

export const updateWorkingTimeProducts = (workingTimeId, newProducts, updatedProducts) => ({
  [RSAA]: {
    types: [types.UPDATE_PRODUCTS_REQUEST, types.UPDATE_PRODUCTS_SUCCESS, types.UPDATE_PRODUCTS_FAILURE],
    endpoint: `/working-hours/${workingTimeId}/products`,
    method: 'POST',
    body: {
      newProducts: workingTimeProductsAdapter(newProducts),
      updatedProducts: workingTimeProductsAdapter(updatedProducts),
    },
  },
});

export const fetchConflictingAppointmentsValue = (params, isUpdate = false) => ({
  [RSAA]: {
    types: [
      {
        type: types.FETCH_CONFLICTING_APPOINTMENTS_VALUE_REQUEST,
        meta: {
          isUpdate,
        },
      },
      types.FETCH_CONFLICTING_APPOINTMENTS_VALUE_SUCCESS,
      types.FETCH_CONFLICTING_APPOINTMENTS_VALUE_FAILURE,
    ],
    endpoint: `/conflicting-appointments/count`,
    method: 'GET',
    params,
  },
});

export const addProduct = (product) => ({
  type: types.ADD_PRODUCT,
  payload: product,
});

export const updateProduct = (product) => ({
  type: types.UPDATE_PRODUCT,
  payload: product,
});

export const resetProducts = () => ({
  type: types.RESET_PRODUCTS,
});

export const setProductsModal = (isOpen, workingTimeId = null) => ({
  type: types.SET_PRODUCTS_MODAL,
  payload: { isOpen, workingTimeId },
});
