import React, { ReactNode } from 'react';
import { Button, Modal, ModalBody, ModalFooter, Spinner } from 'reactstrap';
import { useAppIntl } from 'app/helpers';
import styles from 'app/components/confirmationModal/confirmationModal.module.scss';

interface Props {
  cancelButtonText?: string;
  confirmButtonText?: string;
  description: ReactNode | string;
  isOpen: boolean;
  loading?: boolean;
  onCancel?: () => void;
  onClose: () => void;
  onClosed?: () => void;
  onConfirm: () => void;
  size?: string;
}

export const ConfirmationModal = (props: Props) => {
  const { formatMessage } = useAppIntl();

  return (
    <Modal centered isOpen={props.isOpen} size={props.size} toggle={props.onClose} onClosed={props.onClosed}>
      <ModalBody className="text-center pt-5">
        {typeof props.description === 'string' ? (
          <h6 className="text-pre-line mb-0">{props.description}</h6>
        ) : (
          props.description
        )}
      </ModalBody>
      <ModalFooter className="justify-content-center">
        <Button className={styles.button} color="primary" outline onClick={props.onCancel || props.onClose}>
          {props.cancelButtonText || formatMessage({ id: 'CORE.BUTTON.CANCEL', defaultMessage: 'Cancel' })}
        </Button>
        <Button className={styles.button} color="primary-gradient" disabled={props.loading} onClick={props.onConfirm}>
          {props.loading && <Spinner size="sm" />}
          {!props.loading &&
            (props.confirmButtonText || formatMessage({ id: 'CORE.BUTTON.CONFIRM', defaultMessage: 'Confirm' }))}
        </Button>
      </ModalFooter>
    </Modal>
  );
};
