import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQueryWithReAuth } from 'app/helpers';
import {
  ClinicMassAddingFilePreUpload,
  MassAddingFile,
  MassAddingItem,
  MassAddingItemDetails,
  MassAddingRow,
  QueryParams,
  QueryResponse,
  QueryResponseWithPagination,
  RemoteFile,
} from 'app/types';
import {
  ClinicFileStartUploadsParams,
  ClinicFileUploadParams,
  GetClinicFileParams,
  GetClinicFileStartParams,
  GetClinicFileStartUploadParams,
  GetErrorFileResponse,
  RemoveFileParams,
  TriggerMassAddingParams,
} from 'app/services/massAdding/types';

export const massAddingApi = createApi({
  reducerPath: 'massAddingApi',
  baseQuery: baseQueryWithReAuth,
  tagTypes: ['History', 'Files'],
  endpoints: (builder) => ({
    getHistory: builder.query<QueryResponseWithPagination<MassAddingItem[]>, QueryParams | undefined>({
      query: (params) => ({
        params,
        url: 'appointments/mass-adding',
      }),
      providesTags: ['History'],
    }),
    getDetails: builder.query<QueryResponse<MassAddingItemDetails>, { id: string }>({
      query: ({ id }) => ({
        url: `appointments/mass-adding/${id}`,
      }),
    }),
    getMassAddingItems: builder.query<
      QueryResponseWithPagination<MassAddingRow[]>,
      { id: string; params: QueryParams | undefined }
    >({
      query: ({ id, params }) => ({
        url: `appointments/mass-adding/${id}/rows`,
        params,
      }),
    }),
    getReport: builder.query<void, QueryParams | undefined>({
      query: (params) => ({
        params,
        url: 'appointments/mass-adding/excel',
      }),
    }),
    getFile: builder.query<QueryResponse<RemoteFile>, string>({
      query: (id) => ({
        url: `mass-adding/${id}/file`,
      }),
    }),
    getErrorFile: builder.query<GetErrorFileResponse, string>({
      query: (id) => ({
        url: `mass-adding/${id}/error-file`,
      }),
    }),
    triggerMedicalRecords: builder.mutation<void, TriggerMassAddingParams>({
      query: ({ body, branchId }) => ({
        body,
        method: 'POST',
        url: `appointments/mass-adding-with-medical-records/branch/${branchId}`,
      }),
      invalidatesTags: ['History'],
    }),
    triggerAppointments: builder.mutation<void, TriggerMassAddingParams>({
      query: ({ body, branchId }) => ({
        body,
        method: 'POST',
        url: `appointments/mass-adding/branch/${branchId}`,
      }),
      invalidatesTags: ['History'],
    }),

    getMassAddingFiles: builder.query<QueryResponseWithPagination<MassAddingFile[]>, QueryParams | undefined>({
      query: (params) => ({
        params,
        url: 'appointments/mass-adding/attachment-files',
      }),
      providesTags: ['Files'],
    }),
    removeMassAddingFile: builder.mutation<void, RemoveFileParams>({
      query: ({ clinicId, clinicAttachmentFileId }) => ({
        method: 'DELETE',
        url: `appointments/mass-adding/clinic/${clinicId}/attachment-files/${clinicAttachmentFileId}`,
      }),
      invalidatesTags: ['Files'],
    }),
    getClinicFile: builder.query<QueryResponseWithPagination<RemoteFile>, GetClinicFileParams>({
      query: ({ clinicId, clinicAttachmentFileId, ...params }) => ({
        params,
        url: `appointments/mass-adding/clinic/${clinicId}/attachment-files/${clinicAttachmentFileId}`,
      }),
      providesTags: ['Files'],
    }),

    massAddingFileStartUpload: builder.mutation<QueryResponse<ClinicMassAddingFilePreUpload>, GetClinicFileStartParams>(
      {
        query: ({ clinicId, ...body }) => ({
          method: 'POST',
          url: `appointments/mass-adding/clinic/${clinicId}/start-uploading-attachment`,
          body,
        }),
      },
    ),

    massAddingFileUploadConfirm: builder.mutation<void, ClinicFileUploadParams>({
      query: ({ clinicId, ...body }) => ({
        method: 'POST',
        url: `appointments/mass-adding/clinic/${clinicId}/finish-uploading-attachment`,
        body,
      }),
      invalidatesTags: ['Files'],
    }),

    getClinicUploadFile: builder.query<ClinicFileStartUploadsParams, GetClinicFileStartUploadParams>({
      query: ({ clinicId, fileName }) => ({
        url: `appointments/mass-adding/clinic/${clinicId}/upload-attachment-path`,
        params: {
          fileName,
        },
      }),
      providesTags: ['Files'],
    }),
  }),
});

export const {
  useGetMassAddingItemsQuery,
  useGetDetailsQuery,
  useGetHistoryQuery,
  useLazyGetErrorFileQuery,
  useLazyGetFileQuery,
  useLazyGetReportQuery,
  useTriggerAppointmentsMutation,
  useTriggerMedicalRecordsMutation,
  useGetMassAddingFilesQuery,
  useRemoveMassAddingFileMutation,
  useGetClinicFileQuery,
  useMassAddingFileUploadConfirmMutation,
  useMassAddingFileStartUploadMutation,
  useLazyGetClinicUploadFileQuery,
} = massAddingApi;
