import React, { ReactElement } from 'react';
import ReactSelect, {
  components,
  ClearIndicatorProps,
  DropdownIndicatorProps,
  Props,
  GroupBase,
  InputProps,
} from 'react-select';
import {
  AsyncPaginate,
  AsyncPaginateProps,
  withAsyncPaginate,
  UseAsyncPaginateParams,
  ComponentProps,
} from 'react-select-async-paginate';
import { CreatableProps } from 'react-select/creatable';
import { CreatableAdditionalProps } from 'react-select/dist/declarations/src/useCreatable';
import Creatable from 'react-select/creatable';
import { IconX } from '@tabler/icons-react';
import classnames from 'classnames';
import { useAppIntl } from 'app/helpers';
import { CollapseIcon } from 'app/components';
import './select.scss';

function ClearIndicator<Option, IsMulti extends boolean = false, Group extends GroupBase<Option> = GroupBase<Option>>(
  props: ClearIndicatorProps<Option, IsMulti, Group>,
) {
  return (
    <components.ClearIndicator className="cursor-pointer" {...props}>
      <IconX size={12} strokeWidth={2.5} />
    </components.ClearIndicator>
  );
}

function DropdownIndicator<
  Option,
  IsMulti extends boolean = false,
  Group extends GroupBase<Option> = GroupBase<Option>,
>(props: DropdownIndicatorProps<Option, IsMulti, Group>) {
  return (
    <components.DropdownIndicator className="cursor-pointer" {...props}>
      <CollapseIcon active={props.selectProps.menuIsOpen} size={14} strokeWidth={2.5} />
    </components.DropdownIndicator>
  );
}

type CustomInputProps = {
  maxLength?: number;
};

function InputComponent<Option, IsMulti extends boolean = false, Group extends GroupBase<Option> = GroupBase<Option>>(
  props: InputProps<Option, IsMulti, Group> & CustomInputProps,
) {
  const maxLength = props.maxLength || 150;

  return (
    <components.Input {...props} maxLength={maxLength}>
      {props.children}
    </components.Input>
  );
}

type CustomProps = {
  invalid?: boolean;
  size?: 'sm' | 'lg';
};

export function AsyncSelect<
  Option,
  IsMulti extends boolean = false,
  Group extends GroupBase<Option> = GroupBase<Option>,
>(props: AsyncPaginateProps<Option, Group, any, IsMulti> & CustomProps & CustomInputProps) {
  const { formatMessage, isRtl } = useAppIntl();

  return (
    <AsyncPaginate
      className={classnames('select-container', {
        'select-container--sm': props.size === 'sm',
        'select-container--lg': props.size === 'lg',
        'select-container--invalid is-invalid': props.invalid,
      })}
      classNamePrefix="select"
      components={{
        ClearIndicator,
        DropdownIndicator,
        Input: InputComponent,
      }}
      isRtl={isRtl}
      loadingMessage={() => formatMessage({ id: 'CORE.TEXT.LOADING' })}
      noOptionsMessage={() => formatMessage({ id: 'CORE.TEXT.NO-OPTIONS' })}
      {...props}
      placeholder={props.placeholder || formatMessage({ id: 'CORE.PLACEHOLDER.SELECT' })}
    />
  );
}

export function Select<Option, IsMulti extends boolean = false, Group extends GroupBase<Option> = GroupBase<Option>>(
  props: Props<Option, IsMulti, Group> & CustomProps,
) {
  const { formatMessage, isRtl } = useAppIntl();

  return (
    <ReactSelect
      className={classnames('select-container', {
        'select-container--sm': props.size === 'sm',
        'select-container--lg': props.size === 'lg',
        'select-container--invalid is-invalid': props.invalid,
      })}
      classNamePrefix="select"
      components={{ ClearIndicator, DropdownIndicator }}
      isRtl={isRtl}
      loadingMessage={() => formatMessage({ id: 'CORE.TEXT.LOADING' })}
      noOptionsMessage={() => formatMessage({ id: 'CORE.TEXT.NO-OPTIONS' })}
      {...props}
      placeholder={props.placeholder || formatMessage({ id: 'CORE.PLACEHOLDER.SELECT' })}
    />
  );
}

type AsyncPaginateCreatableProps<
  OptionType,
  Group extends GroupBase<OptionType>,
  Additional,
  IsMulti extends boolean,
> = CreatableProps<OptionType, IsMulti, Group> &
  UseAsyncPaginateParams<OptionType, Group, Additional> &
  ComponentProps<OptionType, Group, IsMulti>;

type AsyncPaginateCreatableType = <
  OptionType,
  Group extends GroupBase<OptionType>,
  Additional,
  IsMulti extends boolean = false,
>(
  props: AsyncPaginateCreatableProps<OptionType, Group, Additional, IsMulti>,
) => ReactElement;

const CreatableAsyncPaginate = withAsyncPaginate(Creatable) as AsyncPaginateCreatableType;

export function AsyncCreatableSelect<
  Option,
  IsMulti extends boolean = false,
  Group extends GroupBase<Option> = GroupBase<Option>,
>(
  props: AsyncPaginateProps<Option, Group, any, IsMulti> &
    CreatableAdditionalProps<Option, Group> &
    CustomProps &
    CustomInputProps,
) {
  const { formatMessage, isRtl } = useAppIntl();

  return (
    <CreatableAsyncPaginate
      className={classnames('select-container', {
        'select-container--sm': props.size === 'sm',
        'select-container--lg': props.size === 'lg',
        'select-container--invalid is-invalid': props.invalid,
      })}
      classNamePrefix="select"
      components={{
        ClearIndicator,
        DropdownIndicator,
        Input: (inputProps) => <InputComponent {...inputProps} maxLength={props.maxLength} />,
      }}
      isRtl={isRtl}
      loadingMessage={() => formatMessage({ id: 'CORE.TEXT.LOADING' })}
      noOptionsMessage={() => formatMessage({ id: 'CORE.TEXT.NO-OPTIONS' })}
      {...props}
      placeholder={props.placeholder || formatMessage({ id: 'CORE.PLACEHOLDER.SELECT' })}
    />
  );
}
