import { useMemo } from 'react';
import { useAppSelector } from 'app/helpers';
import { FORMS_INVOICING_PROCESS_PAYMENT_MODE } from 'app/shared';
import { InvoiceBillingItemOption, InvoiceDiscountType, InvoiceEligibility } from 'app/types';
import { MAX_PRICE_VALUE } from 'app/shared/constants/prices.constants';
import { ItemField } from 'app/features/invoicingProcess/process/items/itemForm/types';
import { useInvoicingProcessState } from 'app/features/invoicingProcess/process/helpers';
import { PaymentModeField, PaymentModeFormData } from 'app/features/invoicingProcess/process/paymentMode/form/types';

interface Limit {
  min: number;
  max: number;
}

interface Limits {
  [ItemField.quantity]: Limit;
  [ItemField.discountValue]: Limit;
}

export const useLimits = (item: InvoiceBillingItemOption | undefined, form: string) => {
  const { isCreateCreditNoteMode, invoicePackageSnapshotId } = useInvoicingProcessState();
  const selectedItem = useAppSelector((state) => state.invoicingProcess.selectedItem);
  const invoicingProcessItems = useAppSelector((state) => state.invoicingProcess.data?.steps?.items);
  const discountType: InvoiceDiscountType | undefined = useAppSelector(
    (state) => state.form[form]?.values?.[ItemField.discountType],
  );
  const eligibility: PaymentModeFormData['eligibility'] | undefined = useAppSelector(
    (state) => state.form[FORMS_INVOICING_PROCESS_PAYMENT_MODE]?.values?.[PaymentModeField.eligibility],
  );
  const isPercentageDiscountType = discountType === InvoiceDiscountType.Percentage;
  const isReferralInOrPreviousApproval =
    eligibility?.value === InvoiceEligibility.ReferralIn || eligibility?.value === InvoiceEligibility.PreviousApproval;

  return useMemo<Limits>(() => {
    const invoicingProcessItem = invoicingProcessItems?.find((i) => i.id === selectedItem?.id);

    // Credit Note with existing item
    if (isCreateCreditNoteMode && invoicingProcessItem) {
      return {
        quantity: {
          min: 1,
          max: invoicingProcessItem.quantity,
        },
        discountValue: {
          min: invoicingProcessItem.discount.value,
          max: isPercentageDiscountType ? 100 : invoicingProcessItem.price,
        },
      };
    }

    // Eligibility is set to Referral-in or Previous Approved
    if (isReferralInOrPreviousApproval) {
      return {
        quantity: {
          min: 1,
          max: item?.details.quantity || 1,
        },
        discountValue: {
          min: 0,
          max: isPercentageDiscountType ? 100 : item?.details.price || MAX_PRICE_VALUE,
        },
      };
    }

    // Item from the package
    if (!!invoicePackageSnapshotId) {
      return {
        quantity: {
          min: 1,
          max: item?.details.quantity || 1,
        },
        discountValue: {
          min: 0,
          max: 0,
        },
      };
    }

    // Default limits
    return {
      quantity: {
        min: 1,
        max: 99999,
      },
      discountValue: {
        min: 0,
        max: isPercentageDiscountType ? 100 : item?.details.price || MAX_PRICE_VALUE,
      },
    };
  }, [
    invoicePackageSnapshotId,
    invoicingProcessItems,
    isCreateCreditNoteMode,
    isPercentageDiscountType,
    isReferralInOrPreviousApproval,
    item,
    selectedItem?.id,
  ]);
};
