import React, { useEffect, useRef, useState } from 'react';
import Cropper from 'react-cropper';
import { FormattedMessage } from 'react-intl';
import { ModalHeader, ModalFooter, Modal, ModalBody, Button } from 'reactstrap';
import 'cropperjs/dist/cropper.css';

interface ImageCropperProps {
  file?: Blob;
  closeModal: () => void;
  onFileCropped: (blob: Blob) => void;
}

export const ImageCropper = (props: ImageCropperProps) => {
  const fileReader = useRef(new FileReader());
  const [cropper, setCropper] = useState<Cropper | null>(null);
  const [fileSrc, setFileSrc] = useState<string | undefined>(undefined);

  useEffect(() => {
    const reader = fileReader.current;
    const loadAction = () => {
      if (typeof reader.result === 'string') {
        setFileSrc(reader.result);
      }
    };
    reader.addEventListener('load', loadAction);
    if (props.file) {
      reader.readAsDataURL(props.file);
    }
    return () => reader.removeEventListener('load', loadAction);
  }, [props.file]);

  const onCrop = () => {
    if (cropper && props.file) {
      cropper.getCroppedCanvas().toBlob((blob) => {
        if (blob) {
          props.closeModal();
          props.onFileCropped(blob);
        }
      }, props.file.type);
    }
  };

  return (
    <Modal backdrop="static" keyboard={false} size="lg" isOpen={true} toggle={props.closeModal}>
      <ModalHeader toggle={props.closeModal} />
      <ModalBody>
        <Cropper
          aspectRatio={1}
          autoCropArea={1}
          background={false}
          guides
          initialAspectRatio={1}
          minCropBoxHeight={100}
          minCropBoxWidth={100}
          onInitialized={setCropper}
          responsive
          src={fileSrc}
          style={{ maxHeight: '70vh' }}
          viewMode={1}
        />
      </ModalBody>
      <ModalFooter>
        <Button onClick={props.closeModal} data-cy="imageCropperCancelButton">
          <FormattedMessage id="CORE.BUTTON.CANCEL" />
        </Button>
        <Button color="primary-gradient" onClick={onCrop} data-cy="imageCropperConfirmButton">
          <FormattedMessage id="CORE.BUTTON.CONFIRM" />
        </Button>
      </ModalFooter>
    </Modal>
  );
};
