export const ADD_DOCTOR_REQUEST = 'doctor/ADD_DOCTOR_REQUEST';
export const ADD_DOCTOR_SUCCESS = 'doctor/ADD_DOCTOR_SUCCESS';
export const ADD_DOCTOR_FAILURE = 'doctor/ADD_DOCTOR_FAILURE';

export const FETCH_DOCTOR_REQUEST = 'doctor/FETCH_DOCTOR_REQUEST';
export const FETCH_DOCTOR_SUCCESS = 'doctor/FETCH_DOCTOR_SUCCESS';
export const FETCH_DOCTOR_FAILURE = 'doctor/FETCH_DOCTOR_FAILURE';

export const EDIT_DOCTOR_REQUEST = 'doctor/EDIT_DOCTOR_REQUEST';
export const EDIT_DOCTOR_SUCCESS = 'doctor/EDIT_DOCTOR_SUCCESS';
export const EDIT_DOCTOR_FAILURE = 'doctor/EDIT_DOCTOR_FAILURE';

export const FETCH_DOCTOR_CURRENT_WORKING_HOURS_REQUEST = 'doctor/FETCH_DOCTOR_CURRENT_WORKING_HOURS_REQUEST';
export const FETCH_DOCTOR_CURRENT_WORKING_HOURS_SUCCESS = 'doctor/FETCH_DOCTOR_CURRENT_WORKING_HOURS_SUCCESS';
export const FETCH_DOCTOR_CURRENT_WORKING_HOURS_FAILURE = 'doctor/FETCH_DOCTOR_CURRENT_WORKING_HOURS_FAILURE';

export const FETCH_DOCTOR_CURRENT_CUSTOM_WORKING_HOURS_REQUEST =
  'doctor/FETCH_DOCTOR_CURRENT_CUSTOM_WORKING_HOURS_REQUEST';
export const FETCH_DOCTOR_CURRENT_CUSTOM_WORKING_HOURS_SUCCESS =
  'doctor/FETCH_DOCTOR_CURRENT_CUSTOM_WORKING_HOURS_SUCCESS';
export const FETCH_DOCTOR_CURRENT_CUSTOM_WORKING_HOURS_FAILURE =
  'doctor/FETCH_DOCTOR_CURRENT_CUSTOM_WORKING_HOURS_FAILURE';

export const FETCH_DOCTOR_PAST_WORKING_HOURS_REQUEST = 'doctor/FETCH_DOCTOR_PAST_WORKING_HOURS_REQUEST';
export const FETCH_DOCTOR_PAST_WORKING_HOURS_SUCCESS = 'doctor/FETCH_DOCTOR_PAST_WORKING_HOURS_SUCCESS';
export const FETCH_DOCTOR_PAST_WORKING_HOURS_FAILURE = 'doctor/FETCH_DOCTOR_PAST_WORKING_HOURS_FAILURE';

export const FETCH_DOCTOR_PAST_CUSTOM_WORKING_HOURS_REQUEST = 'doctor/FETCH_DOCTOR_PAST_CUSTOM_WORKING_HOURS_REQUEST';
export const FETCH_DOCTOR_PAST_CUSTOM_WORKING_HOURS_SUCCESS = 'doctor/FETCH_DOCTOR_PAST_CUSTOM_WORKING_HOURS_SUCCESS';
export const FETCH_DOCTOR_PAST_CUSTOM_WORKING_HOURS_FAILURE = 'doctor/FETCH_DOCTOR_PAST_CUSTOM_WORKING_HOURS_FAILURE';

export const ADD_DOCTOR_WORKING_HOURS_REQUEST = 'doctor/ADD_DOCTOR_WORKING_HOURS_REQUEST';
export const ADD_DOCTOR_WORKING_HOURS_SUCCESS = 'doctor/ADD_DOCTOR_WORKING_HOURS_SUCCESS';
export const ADD_DOCTOR_WORKING_HOURS_FAIL = 'doctor/ADD_DOCTOR_WORKING_HOURS_FAIL';

export const ADD_DOCTOR_CUSTOM_WORKING_HOURS_REQUEST = 'doctor/ADD_DOCTOR_CUSTOM_WORKING_HOURS_REQUEST';
export const ADD_DOCTOR_CUSTOM_WORKING_HOURS_SUCCESS = 'doctor/ADD_DOCTOR_CUSTOM_WORKING_HOURS_SUCCESS';
export const ADD_DOCTOR_CUSTOM_WORKING_HOURS_FAIL = 'doctor/ADD_DOCTOR_CUSTOM_WORKING_HOURS_FAIL';

export const EDIT_DOCTOR_WORKING_HOURS_REQUEST = 'doctor/EDIT_DOCTOR_WORKING_HOURS_REQUEST';
export const EDIT_DOCTOR_WORKING_HOURS_SUCCESS = 'doctor/EDIT_DOCTOR_WORKING_HOURS_SUCCESS';
export const EDIT_DOCTOR_WORKING_HOURS_FAILURE = 'doctor/EDIT_DOCTOR_WORKING_HOURS_FAILURE';

export const DELETE_DOCTOR_WORKING_HOURS_REQUEST = 'doctor/DELETE_DOCTOR_WORKING_HOURS_REQUEST';
export const DELETE_DOCTOR_WORKING_HOURS_SUCCESS = 'doctor/DELETE_DOCTOR_WORKING_HOURS_SUCCESS';
export const DELETE_DOCTOR_WORKING_HOURS_FAILURE = 'doctor/DELETE_DOCTOR_WORKING_HOURS_FAILURE';

export const DELETE_DOCTOR_CUSTOM_WORKING_HOURS_REQUEST = 'doctor/DELETE_DOCTOR_CUSTOM_WORKING_HOURS_REQUEST';
export const DELETE_DOCTOR_CUSTOM_WORKING_HOURS_SUCCESS = 'doctor/DELETE_DOCTOR_CUSTOM_WORKING_HOURS_SUCCESS';
export const DELETE_DOCTOR_CUSTOM_WORKING_HOURS_FAILURE = 'doctor/DELETE_DOCTOR_CUSTOM_WORKING_HOURS_FAILURE';

export const CANCEL_DOCTOR_WORKING_HOURS_CHANGES = 'doctor/CANCEL_DOCTOR_WORKING_HOURS_CHANGES';

export const SAVE_DOCTOR_WORKING_HOURS_REQUEST = 'doctor/SAVE_DOCTOR_WORKING_HOURS_REQUEST';
export const SAVE_DOCTOR_WORKING_HOURS_SUCCESS = 'doctor/SAVE_DOCTOR_WORKING_HOURS_SUCCESS';
export const SAVE_DOCTOR_WORKING_HOURS_FAILURE = 'doctor/SAVE_DOCTOR_WORKING_HOURS_FAILURE';

export const FETCH_DOCTOR_BUSY_TIME_REQUEST = 'doctor/FETCH_DOCTOR_BUSY_TIME_REQUEST';
export const FETCH_DOCTOR_BUSY_TIME_SUCCESS = 'doctor/FETCH_DOCTOR_BUSY_TIME_SUCCESS';
export const FETCH_DOCTOR_BUSY_TIME_FAILURE = 'doctor/FETCH_DOCTOR_BUSY_TIME_FAILURE';

export const END_DOCTOR_BUSY_TIME_REQUEST = 'doctor/END_DOCTOR_BUSY_TIME_REQUEST';
export const END_DOCTOR_BUSY_TIME_SUCCESS = 'doctor/END_DOCTOR_BUSY_TIME_SUCCESS';
export const END_DOCTOR_BUSY_TIME_FAILURE = 'doctor/END_DOCTOR_BUSY_TIME_FAILURE';

export const ADD_DOCTOR_BUSY_TIME_REQUEST = 'doctor/ADD_DOCTOR_BUSY_TIME_REQUEST';
export const ADD_DOCTOR_BUSY_TIME_SUCCESS = 'doctor/ADD_DOCTOR_BUSY_TIME_SUCCESS';
export const ADD_DOCTOR_BUSY_TIME_FAILURE = 'doctor/ADD_DOCTOR_BUSY_TIME_FAILURE';

export const DELETE_MEDICAL_SERVICE_REQUEST = 'doctor/DELETE_MEDICAL_SERVICE_REQUEST';
export const DELETE_MEDICAL_SERVICE_SUCCESS = 'doctor/DELETE_MEDICAL_SERVICE_SUCCESS';
export const DELETE_MEDICAL_SERVICE_FAILURE = 'doctor/DELETE_MEDICAL_SERVICE_FAILURE';

export const ADD_MEDICAL_SERVICES_REQUEST = 'doctor/ADD_MEDICAL_SERVICES_REQUEST';
export const ADD_MEDICAL_SERVICES_SUCCESS = 'doctor/ADD_MEDICAL_SERVICES_SUCCESS';
export const ADD_MEDICAL_SERVICES_FAILURE = 'doctor/ADD_MEDICAL_SERVICES_FAILURE';

export const EDIT_MEDICAL_SPECIALITY_REQUEST = 'doctor/EDIT_MEDICAL_SPECIALITY_REQUEST';
export const EDIT_MEDICAL_SPECIALITY_SUCCESS = 'doctor/EDIT_MEDICAL_SPECIALITY_SUCCESS';
export const EDIT_MEDICAL_SPECIALITY_FAILURE = 'doctor/EDIT_MEDICAL_SPECIALITY_FAILURE';

export const ADD_DOCTOR_PRODUCT_REQUEST = 'doctor/ADD_DOCTOR_PRODUCT_REQUEST';
export const ADD_DOCTOR_PRODUCT_SUCCESS = 'doctor/ADD_DOCTOR_PRODUCT_SUCCESS';
export const ADD_DOCTOR_PRODUCT_FAILURE = 'doctor/ADD_DOCTOR_PRODUCT_FAILURE';

export const FETCH_DOCTOR_SUB_SPECIALITIES_REQUEST = 'doctor/FETCH_DOCTOR_SUB_SPECIALITIES_REQUEST';
export const FETCH_DOCTOR_SUB_SPECIALITIES_SUCCESS = 'doctor/FETCH_DOCTOR_SUB_SPECIALITIES_SUCCESS';
export const FETCH_DOCTOR_SUB_SPECIALITIES_FAIL = 'doctor/FETCH_DOCTOR_SUB_SPECIALITIES_FAIL';

export const SET_DOCTOR_WORKING_HOUR_SELECTED_BRANCH = 'doctor/SET_DOCTOR_WORKING_HOUR_SELECTED_BRANCH';

export const DELETE_DOCTOR_REQUEST = 'doctor/DELETE_DOCTOR_REQUEST';
export const DELETE_DOCTOR_SUCCESS = 'doctor/DELETE_DOCTOR_SUCCESS';
export const DELETE_DOCTOR_FAILURE = 'doctor/DELETE_DOCTOR_FAILURE';

export const FETCH_DOCTOR_ASSIGNABLE_BRANCHES_REQUEST = 'doctor/FETCH_DOCTOR_ASSIGNABLE_BRANCHES_REQUEST';
export const FETCH_DOCTOR_ASSIGNABLE_BRANCHES_SUCCESS = 'doctor/FETCH_DOCTOR_ASSIGNABLE_BRANCHES_SUCCESS';
export const FETCH_DOCTOR_ASSIGNABLE_BRANCHES_FAILURE = 'doctor/FETCH_DOCTOR_ASSIGNABLE_BRANCHES_FAILURE';

export const FETCH_DOCTOR_BRANCHES_REQUEST = 'doctor/FETCH_DOCTOR_BRANCHES_REQUEST';
export const FETCH_DOCTOR_BRANCHES_SUCCESS = 'doctor/FETCH_DOCTOR_BRANCHES_SUCCESS';
export const FETCH_DOCTOR_BRANCHES_FAILURE = 'doctor/FETCH_DOCTOR_BRANCHES_FAILURE';

export const UPDATE_PRODUCTS_REQUEST = 'doctor/UPDATE_PRODUCTS_REQUEST';
export const UPDATE_PRODUCTS_SUCCESS = 'doctor/UPDATE_PRODUCTS_SUCCESS';
export const UPDATE_PRODUCTS_FAILURE = 'doctor/UPDATE_PRODUCTS_FAILURE';

export const FETCH_CONFLICTING_APPOINTMENTS_VALUE_REQUEST = 'doctor/FETCH_CONFLICTING_APPOINTMENTS_VALUE_REQUEST';
export const FETCH_CONFLICTING_APPOINTMENTS_VALUE_SUCCESS = 'doctor/FETCH_CONFLICTING_APPOINTMENTS_VALUE_SUCCESS';
export const FETCH_CONFLICTING_APPOINTMENTS_VALUE_FAILURE = 'doctor/FETCH_CONFLICTING_APPOINTMENTS_VALUE_FAILURE';

export const SET_PRODUCTS_MODAL = 'doctor/SET_PRODUCTS_MODAL';

export const ADD_PRODUCT = 'doctor/ADD_PRODUCT';
export const UPDATE_PRODUCT = 'doctor/UPDATE_PRODUCT';
export const RESET_PRODUCTS = 'doctor/RESET_PRODUCTS';

export const SELECT_WORKING_HOURS = 'doctor/SELECT_WORKING_HOURS';
