import React, { useCallback } from 'react';
import { OptionContext } from 'react-select';
import { useAppIntl } from 'app/helpers';
import { PatientSourceOption } from 'app/types';

export const useSourceOfPatientFormatter = () => {
  const { formatMessage } = useAppIntl();

  return useCallback(
    (option: PatientSourceOption, meta: { context: OptionContext }) => {
      // Helper Variables
      const { label, details } = option;

      // Return - option selected
      if (meta.context === 'value') {
        return <span>{label}</span>;
      }

      // Return - option in menu
      return (
        <>
          <span className="d-block fw-medium">{label}</span>
          <div className="fw-light small">
            <div>
              {formatMessage({ id: 'CORE.LABEL.SOURCE-PLATFORM' })}: {details?.platform || '-'}
            </div>
            <div>
              {formatMessage({ id: 'CORE.LABEL.REFERRER' })}: {details?.referrer || '-'}
            </div>
            <div>
              {formatMessage({ id: 'CORE.LABEL.CAMPAIGN-NAME' })}: {details?.campaignName || '-'}
            </div>
          </div>
        </>
      );
    },
    [formatMessage],
  );
};
