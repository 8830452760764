import { getApiDate } from 'app/helpers';
import { InvoiceEligibility, InvoiceItem, InvoicePayBy, InvoicePaymentMethodItem, InvoiceType } from 'app/types';
import { InvoicingProcessBody } from 'app/redux/invoicingProcess/types';
import { generateExtendedItems } from 'app/features/invoicingProcess/process/items/helpers';
import { PaymentModeFormData } from 'app/features/invoicingProcess/process/paymentMode/form/types';
import { paymentsAdapter } from 'app/features/invoicingProcess/process/actions/helpers/paymentsAdapter';
import { DeclarationFormData } from 'app/features/invoicingProcess/process/items/declarationForm/types';
import { PaymentConfigurationFormData } from 'app/features/invoicingProcess/process/paymentMethod/paymentConfigurationForm/types';

interface Params {
  declarationValues?: DeclarationFormData;
  draftInvoiceId?: string | null;
  guardianPatientId?: string;
  invoicePackageSnapshotId: string | null;
  invoiceRequestId?: string | null;
  invoiceType: InvoiceType | undefined;
  isPrimaryCareSpeciality: boolean;
  items: InvoiceItem[];
  paymentConfigurationValues: PaymentConfigurationFormData;
  paymentMethodItems: InvoicePaymentMethodItem[];
  paymentModeValues: PaymentModeFormData;
}

export const bodyAdapter = ({
  declarationValues,
  draftInvoiceId,
  guardianPatientId,
  invoicePackageSnapshotId,
  invoiceRequestId,
  invoiceType,
  isPrimaryCareSpeciality,
  items,
  paymentConfigurationValues,
  paymentMethodItems,
  paymentModeValues,
}: Params): InvoicingProcessBody => {
  const payBy = paymentModeValues.payBy?.value;
  const eligibility = paymentModeValues.eligibility?.value;

  const extendedItems = generateExtendedItems({
    invoiceType,
    isPrimaryCareSpeciality,
    items,
    paymentModeValues,
  });

  return {
    branchDeclarationId: declarationValues?.declaration?.value || null,
    draftInvoiceId: draftInvoiceId || null,
    guardianPatientId: guardianPatientId || null,
    invoicePackageSnapshotId: invoicePackageSnapshotId,
    invoiceRequestId: invoiceRequestId || null,
    paymentMode: {
      governmentIssuedId: paymentModeValues.governmentIssuedId,
      governmentIssuedIdType: paymentModeValues.governmentIssuedIdType.value,
      payBy,
      ...(payBy === InvoicePayBy.Insurance && {
        insurance: {
          approvalNumber: paymentModeValues.approvalNumber,
          approvalThreshold: Number(paymentModeValues.approvalThreshold),
          approvalType: paymentModeValues.approvalType?.value,
          benefitLimit: Number(paymentModeValues.benefitLimit),
          eligibilityType: paymentModeValues.eligibility?.value,
          end: getApiDate(paymentModeValues.end),
          insuranceId: paymentModeValues.insuranceCompany.value,
          insuranceNumber: paymentModeValues.insuranceNumber,
          maxConsultFee: Number(paymentModeValues.maxConsultFee),
          start: getApiDate(paymentModeValues.start),

          // Conditional fields
          ...(eligibility === InvoiceEligibility.Eligible && {
            eligibilityNumber: paymentModeValues.eligibilityNumber,
          }),
          ...(eligibility === InvoiceEligibility.ReferralIn && {
            referralInsuranceApprovalId: paymentModeValues.insuranceReferralApproval?.value,
          }),
          ...(eligibility === InvoiceEligibility.PreviousApproval && {
            insuranceApprovalId: paymentModeValues.insuranceApproval?.value,
          }),

          // Shares
          maxPatientShare: Number(paymentModeValues.maxPatientShare),
          patientShare: Number(paymentModeValues.patientShare),
          nonReplaceableBrandMedicationsMaxPatientShare: Number(
            paymentModeValues.nonReplaceableBrandMedicationsMaxPatientShare || 0,
          ),
          nonReplaceableBrandMedicationsPatientSharePercentage: Number(
            paymentModeValues.nonReplaceableBrandMedicationsPatientShare || 0,
          ),
          primaryClinicMaxPatientShare: Number(paymentModeValues.primaryClinicMaxPatientShare || 0),
          primaryClinicPatientSharePercentage: Number(paymentModeValues.primaryClinicPatientShare || 0),
          referralMaxPatientShare: Number(paymentModeValues.referralToSpecializedClinicMaxPatientShare || 0),
          referralPatientSharePercentage: Number(paymentModeValues.referralToSpecializedClinicPatientShare || 0),
          replaceableBrandMedicationsMaxPatientShare: Number(
            paymentModeValues.replaceableBrandMedicationsMaxPatientShare || 0,
          ),
          replaceableBrandMedicationsPatientSharePercentage: Number(
            paymentModeValues.replaceableBrandMedicationsPatientShare || 0,
          ),
          specializedClinicMaxPatientShare: Number(paymentModeValues.specializedClinicMaxPatientShare || 0),
          specializedClinicPatientSharePercentage: Number(paymentModeValues.specializedClinicPatientShare || 0),
        },
      }),
    },
    items: extendedItems.map((item) => ({
      billingItemId: item.billingItem.value,
      billingItemSourceType: item.billingItemSourceType.value,
      discountType: item.discount.discountTypeView.value,
      discountValue: item.discount.value,
      id: item.id,
      insuranceShare: item.insuranceShareTotal,
      invoicePackageItemSnapshotId: item.invoicePackageItemSnapshotId, // NOT the same as global invoicePackageItemSnapshotId
      patientShare: item.patientShareTotal,
      price: item.price,
      quantity: item.quantity,
      vat: item.vat,
    })),
    paymentMethod: {
      dueDate: paymentConfigurationValues.dueDate ? getApiDate(paymentConfigurationValues.dueDate) : undefined,
      payments: paymentsAdapter(paymentMethodItems),
    },
  };
};
