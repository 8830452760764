import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ClaimInfo, InsuranceClaim, InsuranceClaimsState } from 'app/redux/insuranceClaims/types';

const initialState: InsuranceClaimsState = {
  selectedClaims: [],
  selectedId: null,
  statement: null,
  row: null,
  openModalName: null,
  comment: null,
};

const insuranceClaims = createSlice({
  name: 'insuranceClaims',
  initialState,
  reducers: {
    setClaims: (state, action: PayloadAction<ClaimInfo[]>) => {
      state.selectedClaims = action.payload;
    },
    filterOutIds: (state, action: PayloadAction<string>) => {
      state.selectedClaims = state.selectedClaims.filter((claim) => claim.id !== action.payload);
    },
    selectedId: (state, action: PayloadAction<string | null>) => {
      state.selectedId = action.payload;
    },
    setRow: (state, action: PayloadAction<InsuranceClaim | null>) => {
      state.row = action.payload;
    },
    setStatement: (state, action: PayloadAction<InsuranceClaimsState['statement']>) => {
      state.statement = action.payload;
    },
    setOpenModalName: (state, action: PayloadAction<InsuranceClaimsState['openModalName']>) => {
      state.openModalName = action.payload;
    },
    setComment: (state, action: PayloadAction<string | null>) => {
      state.comment = action.payload;
    },
  },
});

export const { setClaims, filterOutIds, setRow, setStatement, selectedId, setOpenModalName, setComment } =
  insuranceClaims.actions;
export default insuranceClaims.reducer;
