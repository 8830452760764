import { compose } from 'redux';
import { connect } from 'react-redux';
import { reduxForm } from 'redux-form';
import { injectIntl } from 'react-intl';
import { FORMS_LOGIN_FORM } from 'app/shared';
import FormComponent from './form.component';
import { login } from '../../redux/auth/auth.actions';
import { withRouter } from 'react-router';

const mapStateToProps = () => ({
  form: FORMS_LOGIN_FORM,
});

const mapDispatchToProps = (dispatch) => ({
  onSubmit: (values) => dispatch(login(values)),
});

export const FormContainer = compose(
  withRouter,
  injectIntl,
  connect(mapStateToProps, mapDispatchToProps),
  reduxForm(),
)(FormComponent);
