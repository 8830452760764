import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Order } from 'app/types';
import { Note } from 'app/components/locationSelector/procedureLocation/types';
import { OrderState } from 'app/redux/order/types';

const initialState: OrderState = {
  selectedOrder: null,
  selectOrderProcedure: null,
  orderTicketId: null,
};

const order = createSlice({
  name: 'order',
  initialState,
  reducers: {
    setOrderTicketId: (state, action: PayloadAction<string>) => {
      state.orderTicketId = action.payload;
    },

    setOrder: (state, action: PayloadAction<{ order: Order; rowIndex: number }>) => {
      state.selectedOrder = action.payload;
    },

    selectOrderProcedure: (state, action: PayloadAction<Note>) => {
      state.selectOrderProcedure = action.payload;
    },

    resetOrderTicketId: (state) => {
      state.orderTicketId = initialState.orderTicketId;
    },

    resetOrder: (state) => {
      state.selectedOrder = initialState.selectedOrder;
    },

    resetOrderProcedure: (state) => {
      state.selectOrderProcedure = initialState.selectOrderProcedure;
    },
  },
});

export const { setOrder, setOrderTicketId, resetOrderTicketId, resetOrder, selectOrderProcedure, resetOrderProcedure } =
  order.actions;
export default order.reducer;
