import { useCallback } from 'react';
import { push } from 'connected-react-router';
import { reset } from 'redux-form';
import { FORMS_CALENDAR_DAY_FILTER, FORMS_CALENDAR_WEEK_FILTER } from 'app/shared';
import { reset as resetRemoteData } from 'app/redux/remoteData/remoteData.actions';
import { resetCalendar } from 'app/redux/calendar/calendar.actions';
import { useAppDispatch } from 'app/helpers';

export const useNavigateToCalendarAction = () => {
  const dispatch = useAppDispatch();

  return useCallback(() => {
    dispatch(resetCalendar());
    dispatch(reset(FORMS_CALENDAR_DAY_FILTER));
    dispatch(reset(FORMS_CALENDAR_WEEK_FILTER));
    dispatch(resetRemoteData('dayCalendars'));
    dispatch(resetRemoteData('weekCalendar'));
    dispatch(push(`/calendar/week`));
  }, [dispatch]);
};
