import { useMemo } from 'react';
import sumBy from 'lodash/sumBy';
import { ColumnDescription } from 'react-bootstrap-table-next';
import { FormattedPrice } from 'app/components';
import { FORMATS_DATE_AND_TIME } from 'app/shared';
import type { InvoiceCreditNotePayment } from 'app/types';
import { formattedDate, useAppIntl, useAppSelector } from 'app/helpers';
import { MethodFormatter } from 'app/features/invoicingProcess/process/paymentMethod/methodFormatter/methodFormatter';

export const useCreditNotePaymentsColumns = () => {
  const { formatMessage, locale } = useAppIntl();
  const creditNotePayments = useAppSelector(
    (state) => state.invoicingProcess.data?.steps?.paymentMethod?.creditNotePayments || [],
  );

  const totalAmount = useMemo<number>(
    () => sumBy<InvoiceCreditNotePayment>(creditNotePayments, (m) => m.amount),
    [creditNotePayments],
  );

  return useMemo<ColumnDescription<InvoiceCreditNotePayment>[]>(
    () => [
      {
        dataField: 'method',
        footer: formatMessage({ id: 'CORE.TABLE.TOTAL' }),
        formatExtraData: { formatMessage, locale },
        formatter: MethodFormatter,
        style: { width: '25%', minWidth: '180px' },
        text: formatMessage({ id: 'CORE.LABEL.PAYMENT-METHOD', defaultMessage: 'Payment Method' }),
      },
      {
        classes: 'text-nowrap',
        dataField: 'amount',
        footer: true,
        footerClasses: 'text-nowrap',
        footerFormatter: () => <FormattedPrice amount={totalAmount} />,
        formatter: (_, row) => <FormattedPrice amount={row.amount} />,
        style: { width: '20%', minWidth: '180px' },
        text: formatMessage({ id: 'CORE.LABEL.AMOUNT', defaultMessage: 'Amount' }),
      },
      {
        dataField: 'timeOfCreation',
        footer: false,
        formatter: (_, row) => formattedDate(row.timeOfCreation, FORMATS_DATE_AND_TIME),
        style: { minWidth: '180px' },
        text: formatMessage({ id: 'CORE.TABLE.DATE-AND-TIME', defaultMessage: 'Note' }),
      },
    ],
    [formatMessage, locale, totalAmount],
  );
};
