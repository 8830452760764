import { useMemo } from 'react';
import { useAppIntl } from 'app/helpers';
import { ColumnFormatter } from 'react-bootstrap-table-next';
import { InvoicePaymentMethodItem } from 'app/types';
import { DotsMenu, DotsMenuItem } from 'app/components';
import { useInvoicingProcessState } from 'app/features/invoicingProcess/process/helpers';
import { invoicingProcessContentId } from 'app/features/invoicingProcess/process/process';
import { FormatExtraData } from 'app/features/invoicingProcess/process/paymentMethod/types';

interface Props extends FormatExtraData {
  method: InvoicePaymentMethodItem;
}

const Actions = ({ deletePaymentMethod, editPaymentMethod, method }: Props) => {
  const { formatMessage } = useAppIntl();
  const { isCreateCreditNoteMode } = useInvoicingProcessState();
  const isDisabled = (!isCreateCreditNoteMode && method.isDefault) || method.creditNotePayment;

  const items = useMemo<DotsMenuItem[]>(
    () => [
      {
        isVisible: !isDisabled && !!editPaymentMethod,
        label: formatMessage({ id: 'CORE.BUTTON.EDIT' }),
        onClick: () => editPaymentMethod && editPaymentMethod(method),
      },
      {
        isVisible: !isDisabled && !!deletePaymentMethod,
        label: formatMessage({ id: 'CORE.BUTTON.DELETE' }),
        onClick: () => deletePaymentMethod && deletePaymentMethod(method),
      },
    ],
    [deletePaymentMethod, editPaymentMethod, formatMessage, isDisabled, method],
  );

  return <DotsMenu container={`#${invoicingProcessContentId}`} items={items} />;
};

export const ActionsFormatter: ColumnFormatter<InvoicePaymentMethodItem, FormatExtraData> = (
  _,
  row,
  __,
  formatExtraData,
) => <Actions method={row} {...formatExtraData} />;
