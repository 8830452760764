import { compose } from 'redux';
import { connect } from 'react-redux';
import { reduxForm } from 'redux-form';
import { injectIntl } from 'react-intl';
import get from 'lodash/get';
import { PatientStatus, UserRoles } from 'app/types';
import { checkAccess } from 'app/helpers';
import { FORMS_APPOINTMENT_TICKET_FORM } from 'app/shared';
import { download } from 'app/redux/downloads/downloads.actions';
import { editAppointmentNote, editWaitingAppointmentNote } from 'app/redux/appointment/appointment.actions';
import { FormComponent } from './form.component';

const mapStateToProps = ({ appointment: { data: appointmentData }, auth, downloads }) => ({
  appointmentData,
  downloading: downloads.loading,
  form: FORMS_APPOINTMENT_TICKET_FORM,
  initialValues: {
    id: appointmentData?.id,
    note: appointmentData?.note,
  },
  token: auth.token,
  allowedForEditAppointments: !!auth.current.allowedForEditAppointments,
  isMergedPatient: appointmentData?.patientStatus.value === PatientStatus.Merged,
  isDifferentDoctor:
    checkAccess([UserRoles.Doctor], auth.current.roles) && auth.current.id !== appointmentData?.data?.doctorId,
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  exportToPDF: (id, token) =>
    dispatch(
      download({
        endpoint: `${ownProps.isWaiting ? 'waiting-appointments' : 'appointments'}/${id}/ticket`,
        locale: ownProps.intl.locale,
        token: token,
      }),
    ),
  onSubmit: async (values) => {
    const { id, note } = values;

    if (ownProps.isWaiting) {
      await dispatch(editWaitingAppointmentNote({ id, note }));
    } else {
      await dispatch(editAppointmentNote({ id, note }));
    }
  },
});

const mergeProps = (stateProps, dispatchProps, ownProps) => {
  return {
    ...dispatchProps,
    ...ownProps,
    ...stateProps,
    exportToPDF: () => dispatchProps.exportToPDF(get(stateProps, 'appointmentData.id'), stateProps.token),
  };
};

export const FormContainer = compose(
  injectIntl,
  connect(mapStateToProps, mapDispatchToProps, mergeProps),
  reduxForm(),
)(FormComponent);
