import { AnyAction } from 'redux';
import * as types from 'app/redux/illnessTypes/illnessTypes.types';
import { Option } from 'app/types';

interface IllnessTypesReducer {
  data: Option[] | null;
  loading: boolean;
  error: boolean;
}

const initialState: IllnessTypesReducer = {
  data: null,
  loading: false,
  error: false,
};

export const illnessTypesReducer = (state = initialState, action: AnyAction): IllnessTypesReducer => {
  switch (action.type) {
    case types.FETCH_ILLNESS_TYPES_REQUEST:
      return {
        ...state,
        data: initialState.data,
        loading: true,
        error: false,
      };
    case types.FETCH_ILLNESS_TYPES_SUCCESS:
      return {
        ...state,
        data: action.payload.data,
        loading: false,
        error: false,
      };
    case types.FETCH_ILLNESS_TYPES_FAILURE:
      return {
        ...state,
        data: initialState.data,
        loading: false,
        error: true,
      };
    default:
      return state;
  }
};
