import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQueryWithReAuth } from 'app/helpers';
import type { GeneralNotificationsParams, GeneralNotificationsResponse } from 'app/services/generalNotifications/types';

export const generalNotificationsApi = createApi({
  reducerPath: 'generalNotificationsApi',
  baseQuery: baseQueryWithReAuth,
  endpoints: (builder) => ({
    getNotifications: builder.query<GeneralNotificationsResponse, GeneralNotificationsParams>({
      query: ({ userId, params }) => ({
        url: `/users/${userId}/generalnotifications`,
        params,
      }),
    }),
    markAsRead: builder.mutation<void, string>({
      query: (id) => ({
        url: `/generalnotifications/${id}`,
        method: 'PUT',
      }),
    }),
    markAllAsRead: builder.mutation<void, string>({
      query: (userId) => ({
        url: `/users/${userId}/generalnotifications`,
        method: 'PUT',
      }),
    }),
    remove: builder.mutation<void, string>({
      query: (id) => ({
        url: `/generalnotifications/${id}`,
        method: 'DELETE',
      }),
    }),
  }),
});

export const { useGetNotificationsQuery, useMarkAsReadMutation, useMarkAllAsReadMutation, useRemoveMutation } =
  generalNotificationsApi;
