import { ColumnFormatter } from 'react-bootstrap-table-next';
import { InvoiceLogItem } from 'app/types';
import { getTranslation } from 'app/helpers';
import { FormattedPrice } from 'app/components';
import { FormatExtraData } from 'app/features/invoicingProcess/modals/invoiceHistoryModal/invoiceHistoryModal';

export const walletTransactionFormatter: ColumnFormatter<InvoiceLogItem, FormatExtraData> | undefined = (
  cell,
  row,
  rowIndex,
  formatExtraData,
) =>
  row.walletTransactionType ? (
    <>
      <span className="d-block">{getTranslation(row.walletTransactionType, formatExtraData?.locale)}</span>
      <span>
        {formatExtraData?.formatMessage &&
          formatExtraData.formatMessage({ id: 'CORE.LABEL.AMOUNT', defaultMessage: 'Amount' })}
        {': '}
        <FormattedPrice amount={row.walletTransactionAmount} />
      </span>
    </>
  ) : (
    '-'
  );
