// DATE AND TIME

// moment
export const FORMATS_DAY_OF_WEEK = 'dddd';
export const FORMATS_MONTH_AND_YEAR = 'MM/YYYY';
export const FORMATS_DATE = 'DD/MM/YYYY';
export const FORMATS_EXTENDED_DATE = `${FORMATS_DAY_OF_WEEK}, ${FORMATS_DATE}`;
export const FORMATS_TIME = 'hh:mm A';
export const FORMATS_DATE_AND_TIME = `${FORMATS_DATE} ${FORMATS_TIME}`;
export const FORMATS_EXTENDED_DATE_AND_TIME = `${FORMATS_EXTENDED_DATE} ${FORMATS_TIME}`;
export const FORMATS_DATE_AND_TIME_JS_DATE = `dd/MM/yyyy hh:mm aa`;
export const FORMATS_TIME_JS_DATE = `hh:mm aa`;

// default time
export const DEFAULT_TIME_FROM = '00:00:00';
export const DEFAULT_TIME_TO = '23:59:59';

// api
export const FORMATS_API_DATE = 'YYYY-MM-DD';
export const FORMATS_API_TIME = 'HH:mm:ss';
export const FORMATS_API_DATE_AND_TIME = 'YYYY-MM-DDTHH:mm:ss';

export const FORMATS_TIMEZONE = 'Asia/Riyadh';
