import { Button, Spinner } from 'reactstrap';
import { useCallback, useMemo, useState } from 'react';
import { IconFileInvoice, IconHistory } from '@tabler/icons-react';
import { useAppDispatch, useAppIntl, useAppSelector, useCurrentUser } from 'app/helpers';
import { toggleModal } from 'app/redux/modals/modals.actions';
import { download } from 'app/redux/downloads/downloads.actions';
import styles from 'app/features/invoicingProcess/process/summary/actions/actions.module.scss';

export const Actions = () => {
  const dispatch = useAppDispatch();
  const { token } = useCurrentUser();
  const { formatMessage, locale } = useAppIntl();
  const [invoiceLoading, setInvoiceLoading] = useState<boolean>(false);
  const invoiceId = useAppSelector((state) => state.invoicingProcess.data?.invoiceId);

  const downloadInvoice = useCallback(async () => {
    if (invoiceId) {
      setInvoiceLoading(true);
      await dispatch(
        download({
          token,
          locale,
          endpoint: `invoices/${invoiceId}/v2/print-view`,
          withOpening: false,
          body: false,
        }),
      );
      setInvoiceLoading(false);
    }
  }, [dispatch, invoiceId, locale, token]);

  const openInvoiceHistory = useCallback(() => {
    dispatch(toggleModal('invoiceHistory', true));
  }, [dispatch]);

  const actions = useMemo(
    () => [
      {
        icon: <IconFileInvoice size={24} strokeWidth={1.8} />,
        loading: invoiceLoading,
        onClick: downloadInvoice,
        text: formatMessage({ id: 'CORE.TEXT.INVOICE', defaultMessage: 'Invoice' }),
      },
      {
        icon: <IconHistory size={24} strokeWidth={1.8} />,
        loading: false,
        onClick: openInvoiceHistory,
        text: formatMessage({ id: 'CORE.TEXT.HISTORY', defaultMessage: 'History' }),
      },
    ],
    [downloadInvoice, formatMessage, invoiceLoading, openInvoiceHistory],
  );

  return (
    <div className="d-flex">
      {actions.map((action, index) => (
        <Button
          className="btn me-2"
          color="light"
          disabled={action.loading}
          key={index}
          onClick={action.onClick}
          style={{ minWidth: 130 }}
        >
          {action.loading && <Spinner size="sm" style={{ marginBottom: 2 }} />}
          {!action.loading && (
            <>
              {action.icon}
              <span className={styles.text}>{action.text}</span>
            </>
          )}
        </Button>
      ))}
    </div>
  );
};
