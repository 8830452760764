import { connect } from 'react-redux';
import get from 'lodash/get';
import { push } from 'connected-react-router';

import AccessDeniedComponent from './access-denied.component';

const mapStateToProps = (state) => ({
  whileFetching: get(state, 'auth.whileFetching'),
  userRoles: get(state, 'auth.current.roles', []),
});

const mapDispatchToProps = (dispatch) => ({
  onRedirect: (location) => dispatch(push(location)),
});

export const AccessDeniedContainer = connect(mapStateToProps, mapDispatchToProps)(AccessDeniedComponent);
