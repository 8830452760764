import toast from 'react-hot-toast';
import { submit, touch } from 'redux-form';
import { useCallback, useRef } from 'react';
import { FormSubmitHandler } from 'redux-form/lib/reduxForm';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader, Spinner } from 'reactstrap';
import { InvoiceCreditNoteAction } from 'app/types';
import { FORMS_INVOICING_PROCESS_PAYMENT_MODE, FORMS_CREDIT_NOTE } from 'app/shared';
import { useAppDispatch, useAppIntl, useAppSelector, useModalCleaner } from 'app/helpers';
import { toggleModal } from 'app/redux/modals/modals.actions';
import { CreditNoteConfiguration, InvoicingProcessMode } from 'app/redux/invoicingProcess/types';
import { scrollToTop } from 'app/features/invoicingProcess/process/helpers';
import { PaymentModeField } from 'app/features/invoicingProcess/process/paymentMode/form/types';
import {
  setMode,
  fetchCreditNote,
  setCreditNoteConfiguration,
} from 'app/redux/invoicingProcess/invoicingProcess.actions';
import { useOnCreateCreditNote } from 'app/features/invoicingProcess/process/actions/helpers/useOnCreateCreditNote';
import { CreditNoteFormData } from 'app/features/invoicingProcess/modals/creditNoteModal/creditNoteForm/types';
import { CreditNoteForm } from 'app/features/invoicingProcess/modals/creditNoteModal/creditNoteForm/creditNoteForm';

export const CreditNoteModal = () => {
  const dispatch = useAppDispatch();
  const { formatMessage } = useAppIntl();
  const modalRef = useRef(null);
  const { createCreditNoteLoading, onCreateCreditNote } = useOnCreateCreditNote();
  const isOpen = useAppSelector((state) => state.modal.creditNote.isOpen);
  const invoiceId = useAppSelector((state) => state.invoicingProcess.data?.invoiceId);

  const closeModal = useCallback(() => {
    dispatch(toggleModal('creditNote', false));
  }, [dispatch]);

  const submitForm = useCallback(() => {
    dispatch(submit(FORMS_CREDIT_NOTE));
  }, [dispatch]);

  const onSubmit = useCallback<FormSubmitHandler<CreditNoteFormData>>(
    async (values, dispatch) => {
      const creditNoteConfiguration: CreditNoteConfiguration = {
        action: values.action,
        otherReason: values.otherReason,
        packageAction: values.packageAction,
        reason: values.reason,
      };

      // Set credit note configuration
      dispatch(setCreditNoteConfiguration(creditNoteConfiguration));

      // Trigger credit note flow
      if (values.action === InvoiceCreditNoteAction.Refund) {
        await onCreateCreditNote(creditNoteConfiguration);
      } else if (invoiceId) {
        dispatch(toggleModal('creditNote', false));
        await dispatch(fetchCreditNote(invoiceId));
        toast.success('CORE.TEXT.CREDIT-IS-READY', { duration: 5000 });
        dispatch(setMode(InvoicingProcessMode.CreateCreditNote));
        dispatch(touch(FORMS_INVOICING_PROCESS_PAYMENT_MODE, ...Object.keys(PaymentModeField)));
        scrollToTop();
      }
    },
    [invoiceId, onCreateCreditNote],
  );

  useModalCleaner('creditNote', modalRef);

  return (
    <Modal centered isOpen={isOpen} ref={modalRef} size="lg" toggle={closeModal}>
      <ModalHeader toggle={closeModal}>
        {formatMessage({ id: 'CORE.BUTTON.CREATE-CREDIT-NOTE', defaultMessage: 'Create Credit Note' })}
      </ModalHeader>
      <ModalBody className="pb-1">
        <CreditNoteForm onSubmit={onSubmit} />
      </ModalBody>
      <ModalFooter>
        <Button
          className="btn btn-responsive btn-responsive--lg"
          color="primary-gradient"
          disabled={createCreditNoteLoading}
          onClick={submitForm}
          style={{ minWidth: 160 }}
        >
          {createCreditNoteLoading ? (
            <Spinner size="sm" />
          ) : (
            formatMessage({ id: 'CORE.BUTTON.CONTINUE', defaultMessage: 'Continue' })
          )}
        </Button>
      </ModalFooter>
    </Modal>
  );
};
