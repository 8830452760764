import React, { useCallback, useMemo, useRef } from 'react';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { submit } from 'redux-form';
import { useIntl } from 'react-intl';
import { push } from 'connected-react-router';
import moment from 'moment';
import { FORMS_EDIT_APPOINTMENT } from 'app/shared';
import { useAppDispatch, useAppSelector, useCurrentUser, useModalCleaner } from 'app/helpers';
import { toggleModal } from 'app/redux/modals/modals.actions';
import {
  appointmentFormValuesAdapter,
  getAppointmentPatientFormData,
  generateSlotOption,
  useAppointmentFormStatus,
  useCreatePatientHandlers,
} from 'app/appointment/form/helpers';
import { AppointmentForm } from 'app/appointment/form';
import { editAppointment, setFullEdit, setPatient, resetPatient } from 'app/redux/appointment/appointment.actions';
import { AppointmentFormData } from 'app/appointment/form/types';
import { CreatePatientModals } from 'app/appointment/shared';

export const EditAppointmentModal = () => {
  const dispatch = useAppDispatch();
  const modalRef = useRef(null);
  const { formatMessage } = useIntl();
  const {
    currentUser: { allowedForEditAppointments },
  } = useCurrentUser();

  // Selectors
  const isOpen = useAppSelector((state) => state.modal.editAppointment.isOpen);
  const appointment = useAppSelector((state) => state.appointment.data);
  const submitting = !!useAppSelector((state) => state.form[FORMS_EDIT_APPOINTMENT]?.submitting);
  const { isFormDisabled } = useAppointmentFormStatus(appointment?.start);

  // Values
  const clinicId = appointment?.clinic.value;
  const noTimeMessage = formatMessage({ id: 'CORE.TEXT.WITHOUT-TIME' });
  const initialValues = useMemo((): Partial<AppointmentFormData> | undefined => {
    if (appointment) {
      return {
        branchId: appointment.branch.value,
        date: moment(appointment.start).toDate(),
        doctorId: appointment.doctorId,
        doctorName: appointment.doctorName,
        note: appointment.note,
        patientSource: appointment.patientSource,
        product: appointment.product,
        slot: generateSlotOption(appointment.start, appointment.end, null, noTimeMessage),
        speciality: appointment.speciality,
        subSpeciality: appointment.subSpeciality,
        ...getAppointmentPatientFormData(appointment),
      };
    }

    return undefined;
  }, [appointment, noTimeMessage]);

  // Actions
  const { createdPatient, onAddNewPatient, onResetCreated, setPatientData, initialPhoneNumber } =
    useCreatePatientHandlers({
      formName: FORMS_EDIT_APPOINTMENT,
      setPatient,
      resetPatient,
    });
  const toggle = useCallback(() => dispatch(toggleModal('editAppointment')), [dispatch]);
  const onFullEdit = useCallback(() => {
    if (appointment) {
      dispatch(setFullEdit(appointment));
      dispatch(push('/calendar/day'));
    }
  }, [appointment, dispatch]);
  const submitForm = useCallback(() => dispatch(submit(FORMS_EDIT_APPOINTMENT)), [dispatch]);
  const onSubmit = useCallback(
    async (values: AppointmentFormData) => {
      if (appointment) {
        return await dispatch(editAppointment(appointment.id, appointmentFormValuesAdapter(values)));
      }
    },
    [appointment, dispatch],
  );

  useModalCleaner('editAppointment', modalRef);

  return (
    <Modal ref={modalRef} backdrop="static" keyboard={false} isOpen={isOpen} size="lg" toggle={toggle}>
      <ModalHeader toggle={toggle}>{formatMessage({ id: 'APPOINTMENTS.TEXT.EDIT-APPOINTMENT' })}</ModalHeader>
      <ModalBody>
        {appointment && clinicId && (
          <AppointmentForm
            form={FORMS_EDIT_APPOINTMENT}
            initialValues={initialValues}
            clinicId={clinicId}
            createdPatientMode={!!createdPatient}
            isEdit
            isFormDisabled={isFormDisabled}
            onAddNewPatient={onAddNewPatient}
            onFullEdit={onFullEdit}
            onSubmit={onSubmit}
            showFullSlots
            showSlotsWithNumber
            resetMode={onResetCreated}
            ageRestrictions={{
              acceptablePatientAgeFrom: appointment.acceptablePatientAgeFrom,
              acceptablePatientAgeTo: appointment.acceptablePatientAgeTo,
            }}
          />
        )}
      </ModalBody>
      <ModalFooter>
        <Button onClick={toggle}>{formatMessage({ id: 'CORE.BUTTON.CANCEL' })}</Button>
        <Button
          color="primary-gradient"
          disabled={isFormDisabled || submitting || !allowedForEditAppointments}
          onClick={submitForm}
        >
          {formatMessage({ id: 'CORE.BUTTON.SAVE-CHANGES' })}
        </Button>
      </ModalFooter>

      <CreatePatientModals
        initialPhoneNumber={initialPhoneNumber}
        clinicId={clinicId}
        setPatientData={setPatientData}
      />
    </Modal>
  );
};
