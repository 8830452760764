import React from 'react';
import { Button } from 'reactstrap';
import { useAppIntl } from 'app/helpers';
import { Appointment, AppointmentStatus } from 'app/types';

interface Props {
  appointment: Appointment;
  loading: boolean;
  onConfirm: () => void;
  showConfirmation: () => void;
}

export const Actions = ({ appointment: { status }, loading, onConfirm, showConfirmation }: Props) => {
  const { formatMessage } = useAppIntl();

  if (!status) {
    return null;
  }

  const isBooked = [AppointmentStatus.Booked, AppointmentStatus.BookedBefore24].includes(status.value);
  const isConfirmed = [AppointmentStatus.Confirmed, AppointmentStatus.ConfirmedByStaff].includes(status.value);
  const isBookedOrConfirmed = isBooked || isConfirmed;

  if (!isBookedOrConfirmed) {
    return null;
  }

  return (
    <div className="mb-3">
      {isBooked && (
        <Button className="mb-2" block color="primary-gradient" disabled={loading} onClick={onConfirm} type="button">
          {formatMessage({ id: 'CONFIRMATION.CONFIRM' })}
        </Button>
      )}
      <Button block color="danger" disabled={loading} onClick={showConfirmation} type="button">
        {formatMessage({ id: 'CONFIRMATION.CANCEL' })}
      </Button>
    </div>
  );
};
