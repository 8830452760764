import { Option } from 'app/types/Option';
import { PaymentSource } from 'app/types/PaymentSource';
import { PatientGender } from 'app/types/Patient';
import { SpecialtyFormType } from 'app/types/Speciality';

export enum InitialAssessmentAppointmentType {
  NewVisit = '0',
  FollowUp = '1',
  Referral = '2',
  ReturnVisit = '3',
  PlanType = '4',
}

export interface InitialAssessment {
  age: number;
  appointmentId: string;
  appointmentType?: InitialAssessmentAppointmentType;
  branchId: string;
  doctor: Option;
  durationOfIllnessInDays?: number;
  end: string;
  familyHistoryIllnesses?: Option[];
  gender?: Option<PatientGender>;
  hasMergePatientsBadge: boolean;
  how?: string;
  id: string;
  illnessStartDate?: number;
  illnessType: IllnessTypes;
  initialAssessmentPatientVitals: InitialAssessmentPatientVitals;
  nationality?: Option;
  note?: string;
  patient: Option;
  patientDayOfBirth?: string;
  patientMedicalIllnesses?: Option[];
  paymentSource: PaymentSource;
  previousSurgeries?: Option[];
  product: Option[];
  reasonOfVisit?: string;
  referralId: string | null;
  speciality: Option;
  specialityType: SpecialtyFormType;
  start: string;
  subSpeciality: Option;
  when?: string;
  where?: string;
}

export interface IllnessTypes {
  allergies?: boolean | null;
  checkUp?: boolean | null;
  chronicDiseases?: boolean | null;
  cleaning?: boolean | null;
  congenitalOrDevelopmental?: boolean | null;
  indicateLmp?: string;
  infertility?: boolean | null;
  medications?: boolean | null;
  na?: boolean | null;
  orthodonticOrAesthetics?: boolean | null;
  other?: boolean | null;
  otherMessage?: string;
  pregnancy?: boolean | null;
  psychiatric?: boolean | null;
  regularDentalTreatment?: boolean | null;
  rta?: boolean | null;
  smokingStatus?: boolean | null;
  sportRelated?: boolean | null;
  vaccination?: boolean | null;
  workRelatedAccident?: boolean | null;
}

export interface InitialAssessmentPatientVitals {
  bloodGlucose?: number;
  bloodPressureFirstPart?: number;
  bloodPressureSecondPart?: number;
  bmi?: number;
  headCircumference?: number;
  height?: number;
  oxygenSaturation?: number;
  pain?: number;
  pulse?: number;
  respiratoryRate?: number;
  temperature?: number;
  weight?: number;
}
