import {
  PatientInsuranceDetailsOption,
  InvoicePayBy,
  Option,
  GovernmentIssuedIdType,
  InvoiceEligibility,
  ReferralInsuranceApprovalOption,
  InsuranceClassOption,
} from 'app/types';

export const PaymentModeField = {
  approvalNumber: 'approvalNumber',
  approvalThreshold: 'approvalThreshold',
  approvalType: 'approvalType',
  benefitLimit: 'benefitLimit',
  eligibility: 'eligibility',
  eligibilityNumber: 'eligibilityNumber',
  end: 'end',
  governmentIssuedId: 'governmentIssuedId',
  governmentIssuedIdType: 'governmentIssuedIdType',
  insuranceApproval: 'insuranceApproval',
  insuranceClass: 'insuranceClass',
  insuranceCompany: 'insuranceCompany',
  insuranceNumber: 'insuranceNumber',
  insuranceReferralApproval: 'insuranceReferralApproval',
  maxConsultFee: 'maxConsultFee',
  maxPatientShare: 'maxPatientShare',
  nonReplaceableBrandMedicationsMaxPatientShare: 'nonReplaceableBrandMedicationsMaxPatientShare',
  nonReplaceableBrandMedicationsPatientShare: 'nonReplaceableBrandMedicationsPatientShare',
  patientShare: 'patientShare',
  payBy: 'payBy',
  policyNumber: 'policyNumber',
  primaryClinicMaxPatientShare: 'primaryClinicMaxPatientShare',
  primaryClinicPatientShare: 'primaryClinicPatientShare',
  referralToSpecializedClinicMaxPatientShare: 'referralToSpecializedClinicMaxPatientShare',
  referralToSpecializedClinicPatientShare: 'referralToSpecializedClinicPatientShare',
  replaceableBrandMedicationsMaxPatientShare: 'replaceableBrandMedicationsMaxPatientShare',
  replaceableBrandMedicationsPatientShare: 'replaceableBrandMedicationsPatientShare',
  specializedClinicMaxPatientShare: 'specializedClinicMaxPatientShare',
  specializedClinicPatientShare: 'specializedClinicPatientShare',
  start: 'start',
} as const;

export type PaymentModeFormData = {
  [PaymentModeField.approvalNumber]: string;
  [PaymentModeField.approvalThreshold]?: string;
  [PaymentModeField.approvalType]: Option;
  [PaymentModeField.benefitLimit]?: string;
  [PaymentModeField.eligibilityNumber]: string;
  [PaymentModeField.eligibility]: Option<InvoiceEligibility>;
  [PaymentModeField.end]: Date;
  [PaymentModeField.governmentIssuedIdType]: Option<GovernmentIssuedIdType>;
  [PaymentModeField.governmentIssuedId]: string;
  [PaymentModeField.insuranceApproval]: Option;
  [PaymentModeField.insuranceClass]: InsuranceClassOption;
  [PaymentModeField.insuranceCompany]: PatientInsuranceDetailsOption;
  [PaymentModeField.insuranceNumber]: string;
  [PaymentModeField.insuranceReferralApproval]?: ReferralInsuranceApprovalOption;
  [PaymentModeField.maxConsultFee]?: string;
  [PaymentModeField.maxPatientShare]: string;
  [PaymentModeField.nonReplaceableBrandMedicationsMaxPatientShare]: string;
  [PaymentModeField.nonReplaceableBrandMedicationsPatientShare]: string;
  [PaymentModeField.patientShare]: string;
  [PaymentModeField.payBy]: Option<InvoicePayBy>;
  [PaymentModeField.policyNumber]: Option;
  [PaymentModeField.primaryClinicMaxPatientShare]: string;
  [PaymentModeField.primaryClinicPatientShare]: string;
  [PaymentModeField.referralToSpecializedClinicMaxPatientShare]: string;
  [PaymentModeField.referralToSpecializedClinicPatientShare]: string;
  [PaymentModeField.replaceableBrandMedicationsMaxPatientShare]: string;
  [PaymentModeField.replaceableBrandMedicationsPatientShare]: string;
  [PaymentModeField.specializedClinicMaxPatientShare]: string;
  [PaymentModeField.specializedClinicPatientShare]: string;
  [PaymentModeField.start]: Date;
};
