import { takeEvery, put, select } from 'redux-saga/effects';
import {
  ADD_ADMIN_ROLE_SUCCESS,
  ADD_USER_ROLE_SUCCESS,
  DELETE_ADMIN_ROLE_SUCCESS,
  DELETE_USER_ROLE_SUCCESS,
  EDIT_USER_ROLE_SUCCESS,
} from 'app/redux/user-roles/user-roles.types';
import { toggleAddUserRole, toggleEditUserRole } from 'app/redux/modals/modals.actions';
import { usersApi } from 'app/services/users/users';

export function* afterAddUserRoleSuccess(action) {
  const {
    queryParams: { clinicUsers: clinicUsersParams },
  } = yield select();

  yield put(toggleAddUserRole());
  yield put(
    usersApi.endpoints.getClinicUsers.initiate(
      { clinicId: action.meta.clinicId, params: clinicUsersParams },
      { subscribe: false, forceRefetch: true },
    ),
  );
}

export function* afterEditUserRoleSuccess(action) {
  yield put(toggleEditUserRole());

  if (action.meta && action.meta.clinicId) {
    const {
      queryParams: { clinicUsers: clinicUsersParams },
    } = yield select();

    yield put(
      usersApi.endpoints.getClinicUsers.initiate(
        { clinicId: action.meta.clinicId, params: clinicUsersParams },
        { subscribe: false, forceRefetch: true },
      ),
    );
  } else {
    const {
      queryParams: { adminUsers: adminUsersParams },
    } = yield select();

    yield put(usersApi.endpoints.getAdminUsers.initiate(adminUsersParams, { subscribe: false, forceRefetch: true }));
  }
}

export function* afterDeleteUserRoleSuccess(action) {
  if (action.meta && action.meta.clinicId) {
    const {
      queryParams: { clinicUsers: clinicUsersParams },
    } = yield select();

    yield put(
      usersApi.endpoints.getClinicUsers.initiate(
        { clinicId: action.meta.clinicId, params: clinicUsersParams },
        { subscribe: false, forceRefetch: true },
      ),
    );
  } else {
    const {
      queryParams: { adminUsers: adminUsersParams },
    } = yield select();

    yield put(usersApi.endpoints.getAdminUsers.initiate(adminUsersParams, { subscribe: false, forceRefetch: true }));
  }
}

export function* afterAddAdminUserSuccess() {
  const {
    queryParams: { adminUsers: adminUsersParams },
  } = yield select();

  yield put(toggleAddUserRole());
  yield put(usersApi.endpoints.getAdminUsers.initiate(adminUsersParams, { subscribe: false, forceRefetch: true }));
}

function* userRolesSaga() {
  yield takeEvery(ADD_ADMIN_ROLE_SUCCESS, afterAddAdminUserSuccess);
  yield takeEvery(ADD_USER_ROLE_SUCCESS, afterAddUserRoleSuccess);
  yield takeEvery(EDIT_USER_ROLE_SUCCESS, afterEditUserRoleSuccess);
  yield takeEvery([DELETE_USER_ROLE_SUCCESS, DELETE_ADMIN_ROLE_SUCCESS], afterDeleteUserRoleSuccess);
}
export default userRolesSaga;
