import { put, select } from 'redux-saga/effects';
import { PayloadAction } from '@reduxjs/toolkit';
import { toggleModal } from 'app/redux/modals/modals.actions';
import { fetchBranchBillingMedications } from 'app/redux/remoteData/remoteData.actions';
import { RootState, Medication } from 'app/types';
import { Meta } from 'app/sagas/billingItem/types';

export function* afterEditMedicationSuccess({ meta }: PayloadAction<{ data: Medication }, string, Meta>) {
  const {
    remoteData: {
      branchBillingMedication: { params },
    },
  }: RootState = yield select();

  yield put(toggleModal('editBranchMedication', false));
  if (params) {
    yield put<any>(fetchBranchBillingMedications(meta.branchId, params));
  }
}
