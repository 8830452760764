import * as types from './patients.types';
import { PAGINATION_DEFAULT_PARAMS } from 'app/shared/constants/pagination.constants';
import { ADD_PATIENT_DEPENDENT_SUCCESS } from 'app/redux/dependents/dependents.types';

const initialParams = {
  ...PAGINATION_DEFAULT_PARAMS,
  orderAsc: false,
};

const initialState = {
  main: {
    data: [],
    loading: false,
    error: false,
    params: initialParams,
  },
  clinic: {
    data: [],
    loading: false,
    error: false,
    params: initialParams,
  },
  searchPatient: {
    loading: false,
    data: null,
    error: false,
  },
};

export const patientsReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.SEARCH_PATIENT_REQUEST:
      return {
        ...state,
        searchPatient: {
          loading: true,
          error: false,
          data: null,
        },
      };
    case types.SEARCH_PATIENT_SUCCESS:
      return {
        ...state,
        searchPatient: {
          loading: true,
          error: false,
          data: action.payload,
        },
      };
    case types.SEARCH_PATIENT_FAILURE:
      return {
        ...state,
        searchPatient: {
          loading: true,
          error: true,
          data: null,
        },
      };
    case types.SET_PARAMS:
      return {
        ...state,
        main: {
          ...state.main,
          params: {
            ...state.main.params,
            ...action.meta,
          },
        },
      };
    case types.SET_CLINIC_PARAMS:
      return {
        ...state,
        clinic: {
          ...state.clinic,
          params: {
            ...state.clinic.params,
            ...action.meta,
          },
        },
      };
    case ADD_PATIENT_DEPENDENT_SUCCESS:
      return {
        ...state,
        main: {
          ...state.main,
          list: state.main.data.map((patient) =>
            patient.id === action.meta.patientId
              ? { ...patient, numberOfDependents: patient.numberOfDependents + 1 }
              : patient,
          ),
        },
        clinic: {
          ...state.clinic,
          list: state.clinic.data.map((patient) =>
            patient.id === action.meta.patientId
              ? { ...patient, numberOfDependents: patient.numberOfDependents + 1 }
              : patient,
          ),
        },
      };
    default:
      return state;
  }
};
