import { ColumnFormatter } from 'react-bootstrap-table-next';
import { AvailablePackage, Locale } from 'app/types';
import { FormatExtraData } from 'app/features/invoicingProcess/process/components/availablePackages/availablePackages';

export const ItemsFormatter: ColumnFormatter<AvailablePackage, FormatExtraData> = (_, row, __, formatExtraData) => (
  <>
    {row.packageItems.map((item) => (
      <div key={item.id} className="text-nowrap">
        {formatExtraData?.locale === Locale.ar ? item.performerBillingItemNameAr : item.performerBillingItemNameEn}{' '}
        <strong>({formatExtraData?.quantityType === 'initial' ? item.initialQuantity : item.remainingQuantity})</strong>
      </div>
    ))}
  </>
);
