import toast from 'react-hot-toast';
import isEmpty from 'lodash/isEmpty';
import { DecoratedFormProps } from 'redux-form/lib/reduxForm';
import { AppDispatch } from 'app/types';
import { PatientFormData, PatientFormErrorType } from 'app/features/patient/patientForm/types';
import { PatientFormProps } from 'app/features/patient/patientForm/patientForm';
import { patientsApi } from 'app/services/patients/patients';
import { CheckClinicGovernmentIssuedIdParams, CheckGlobalGovernmentIssuedIdParams } from 'app/services/patients/types';

export const asyncValidate = async (
  values: PatientFormData,
  dispatch: AppDispatch,
  {
    initialValues,
    clinicId,
    clinicPatientId,
    patientId,
  }: DecoratedFormProps<PatientFormData, PatientFormProps, PatientFormErrorType>,
): Promise<any> => {
  const errors = {};

  if (
    values.typeOfGovernmentIssuedId &&
    values?.governmentIssuedIdNumber &&
    values.governmentIssuedIdNumber !== initialValues?.governmentIssuedIdNumber
  ) {
    if (clinicId) {
      // [i]: Checking in MI scope
      try {
        const params: CheckClinicGovernmentIssuedIdParams = {
          clinicId,
          clinicPatientId,
          governmentIssuedId: values.governmentIssuedIdNumber,
          governmentIssuedIdType: values.typeOfGovernmentIssuedId.value,
        };
        const promise = dispatch(patientsApi.endpoints?.checkClinicGovernmentIssuedId.initiate(params));
        const { data: responseData } = await promise;

        if (responseData?.data) {
          Object.assign(errors, { governmentIssuedIdNumber: 'ERRORS.GOVERNMENT-ISSUED-ID-ALREADY-USED' });
        }

        promise.unsubscribe();
      } catch (error) {
        toast.error('CORE.TEXT.DEFAULT-FAILURE-MESSAGE');
      }
    } else {
      // [i]: Checking in global scope
      try {
        const params: CheckGlobalGovernmentIssuedIdParams = {
          patientId,
          governmentIssuedId: values.governmentIssuedIdNumber,
          governmentIssuedIdType: values.typeOfGovernmentIssuedId.value,
        };
        const promise = dispatch(patientsApi.endpoints?.checkGlobalGovernmentIssuedId.initiate(params));
        const { data: responseData } = await promise;

        if (responseData?.data) {
          Object.assign(errors, { governmentIssuedIdNumber: 'ERRORS.GOVERNMENT-ISSUED-ID-ALREADY-USED' });
        }

        promise.unsubscribe();
      } catch (error) {
        toast.error('CORE.TEXT.DEFAULT-FAILURE-MESSAGE');
      }
    }
  }

  if (!isEmpty(errors)) {
    // eslint-disable-next-line no-throw-literal
    throw errors;
  }
};
