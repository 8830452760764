import { Button, Spinner } from 'reactstrap';
import { ColumnFormatter } from 'react-bootstrap-table-next';
import { InvoiceLogItem } from 'app/types';
import { FormatExtraData } from 'app/features/invoicingProcess/modals/invoiceHistoryModal/invoiceHistoryModal';

export const sourceFormatter: ColumnFormatter<InvoiceLogItem, FormatExtraData> | undefined = (
  cell,
  row,
  rowIndex,
  formatExtraData,
) => {
  const loading = formatExtraData?.loadingSource === row.id;

  return row.source?.url ? (
    <Button
      className="btn"
      color="primary-gradient"
      disabled={loading}
      onClick={() => formatExtraData?.downloadSource && formatExtraData.downloadSource(row.id, row.source?.url || '')}
      size="sm"
      style={{ minWidth: 100 }}
    >
      {loading && <Spinner size="sm" />}
      {!loading &&
        formatExtraData?.formatMessage &&
        formatExtraData.formatMessage({ id: 'CORE.BUTTON.DOWNLOAD', defaultMessage: 'Download' })}
    </Button>
  ) : (
    '-'
  );
};
