import { useMemo } from 'react';
import { IntlFormatters } from '@formatjs/intl/src/types';
import { ColumnDescription } from 'react-bootstrap-table-next';
import { AppointmentInvoice } from 'app/types';
import { FORMATS_DATE_AND_TIME } from 'app/shared';
import { InvoiceStatusBadge } from 'app/components';
import { formattedDate, useAppIntl } from 'app/helpers';
import { ActionsFormatter } from 'app/features/invoicingProcess/invoices/formatters/actionsFormatter';
import { InvoiceNumberFormatter } from 'app/features/invoicingProcess/invoices/formatters/invoiceNumberFormatter';
import {
  useInvoicingProcessActions,
  ViewDraft,
  ViewInvoice,
} from 'app/features/invoicingProcess/helpers/useInvoicingProcessActions';

interface Params {
  onDeleteDraft: (id: string) => void;
}

export interface FormatExtraData {
  formatMessage?: IntlFormatters['formatMessage'];
  onDeleteDraft?: Params['onDeleteDraft'];
  viewDraft?: ViewDraft;
  viewInvoice?: ViewInvoice;
}

export const useColumns = ({ onDeleteDraft }: Params) => {
  const { formatMessage } = useAppIntl();
  const { viewDraft, viewInvoice } = useInvoicingProcessActions();

  return useMemo<ColumnDescription<AppointmentInvoice, FormatExtraData>[]>(
    () => [
      {
        dataField: 'invoiceNumber',
        formatter: InvoiceNumberFormatter,
        formatExtraData: { viewDraft, viewInvoice, formatMessage },
        text: formatMessage({ id: 'CORE.LABEL.INVOICE-NUMBER', defaultMessage: 'Invoice Number' }),
      },
      {
        dataField: 'status',
        formatter: (_, row) => <InvoiceStatusBadge status={row.status} />,
        text: formatMessage({ id: 'CORE.LABEL.STATUS', defaultMessage: 'Status' }),
      },
      {
        classes: 'text-nowrap',
        dataField: 'timeOfCreation',
        formatter: (_, row) => formattedDate(row.timeOfCreation, FORMATS_DATE_AND_TIME),
        text: formatMessage({ id: 'CORE.LABEL.CREATION-DATE', defaultMessage: 'Creation Date' }),
      },
      {
        classes: 'text-center sticky sticky--end',
        dataField: 'actions',
        formatter: ActionsFormatter,
        formatExtraData: { onDeleteDraft },
        headerClasses: 'text-center sticky sticky--end',
        text: formatMessage({ id: 'CORE.TABLE.ACTIONS' }),
      },
    ],
    [formatMessage, onDeleteDraft, viewDraft, viewInvoice],
  );
};
