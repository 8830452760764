import { Input } from 'reactstrap';
import { useCallback, useMemo } from 'react';
import { ColumnDescription } from 'react-bootstrap-table-next';
import { FORMS_INVOICING_PROCESS_PAYMENT_MODE, LocalTable } from 'app/shared';
import { formattedDate, getTranslation, useAppDispatch, useAppIntl, useAppSelector } from 'app/helpers';
import {
  InvoiceBillingItemOption,
  InvoiceDiscountType,
  InvoiceEligibility,
  InvoiceInsuranceApprovalItem,
  InvoiceItem,
  InvoiceItemVat,
  InvoicePayBy,
  Locale,
} from 'app/types';
import { useInvoicingProcessState } from 'app/features/invoicingProcess/process/helpers';
import { addInvoiceItem, deleteInvoiceItem } from 'app/redux/invoicingProcess/invoicingProcess.actions';
import { PaymentModeField, PaymentModeFormData } from 'app/features/invoicingProcess/process/paymentMode/form/types';
import { FreeFollowUpBadge } from 'app/features/invoicingProcess/process/components/freeFollowUpBadge/freeFollowUpBadge';
import { RequiredSectionHeading } from 'app/features/invoicingProcess/process/components/requiredSectionHeading/requiredSectionHeading';

export const PreviousApproval = () => {
  const { formatMessage, locale } = useAppIntl();
  const dispatch = useAppDispatch();
  const { isCreatedAndNotCreateCreditNoteMode } = useInvoicingProcessState();
  const { data, loading } = useAppSelector((state) => state.invoicingProcess.insuranceApprovalItems);
  const items = useAppSelector((state) => state.invoicingProcess.items);
  const payBy: PaymentModeFormData['payBy'] | undefined = useAppSelector(
    (state) => state.form[FORMS_INVOICING_PROCESS_PAYMENT_MODE]?.values?.[PaymentModeField.payBy],
  );
  const eligibility: PaymentModeFormData['eligibility'] | undefined = useAppSelector(
    (state) => state.form[FORMS_INVOICING_PROCESS_PAYMENT_MODE]?.values?.[PaymentModeField.eligibility],
  );
  const insuranceCompany: PaymentModeFormData['insuranceCompany'] | undefined = useAppSelector(
    (state) => state.form[FORMS_INVOICING_PROCESS_PAYMENT_MODE]?.values?.[PaymentModeField.insuranceCompany],
  );
  const insuranceApproval: PaymentModeFormData['insuranceApproval'] | undefined = useAppSelector(
    (state) => state.form[FORMS_INVOICING_PROCESS_PAYMENT_MODE]?.values?.[PaymentModeField.insuranceApproval],
  );
  const isPreviousApproval = eligibility?.value === InvoiceEligibility.PreviousApproval;
  const isPayByInsurance = payBy?.value === InvoicePayBy.Insurance;

  const onItemSelect = useCallback(
    (item: InvoiceInsuranceApprovalItem, checked: boolean) => {
      if (checked) {
        const billingItem: InvoiceBillingItemOption = {
          details: {
            gtinCode: null,
            internalCode: item.internalCode,
            measureValue: null,
            medicalBillingNphiesCode: null,
            price: Number(item.price),
            quantity: item.quantity,
            saudisVatExempted: item.saudisVatExempted,
            unitMeasure: null,
            vatExempted: item.vatExempted,
          },
          label: locale === Locale.ar ? item.nameAr : item.nameEn,
          value: item.performerBillingItemId,
          translation: {
            ar: item.nameAr,
            en: item.nameEn,
          },
        };

        dispatch(
          addInvoiceItem({
            billingItem,
            billingItemSourceType: item.billingItemSourceType,
            discount: {
              discountTypeView: { label: '', value: InvoiceDiscountType.Amount },
              value: 0,
            },
            id: item.performerBillingItemId,
            price: Number(item.price),
            quantity: item.quantity,
            vat: InvoiceItemVat.Default,
          }),
        );
      } else {
        dispatch(deleteInvoiceItem(item.performerBillingItemId));
      }
    },
    [dispatch, locale],
  );

  const columns = useMemo<ColumnDescription<InvoiceInsuranceApprovalItem, InvoiceItem[]>[]>(
    () => [
      {
        dataField: 'internalCode',
        text: formatMessage({ id: 'BILLING-ITEMS.LABEL.INTERNAL-CODE' }),
        formatter: (_, row) => row.internalCode || '-',
      },
      {
        dataField: 'name',
        formatter: (_, row) => (locale === Locale.ar ? row.nameAr : row.nameEn),
        text: formatMessage({ id: 'CORE.LABEL.NAME' }),
        style: { width: '40%' },
      },
      {
        dataField: 'quantity',
        text: formatMessage({ id: 'CORE.LABEL.QUANTITY' }),
      },
      {
        dataField: 'speciality',
        text: formatMessage({ id: 'CORE.LABEL.SPECIALITY' }),
        formatter: (_, row) => row.specialities?.map((speciality) => getTranslation(speciality, locale))?.join(', '),
      },
      {
        dataField: 'practitioner',
        text: formatMessage({ id: 'CORE.LABEL.DOCTOR' }),
        formatter: (_, row) => getTranslation(row.practitioner, locale),
      },
      {
        dataField: 'approvalDate',
        text: formatMessage({ id: 'CLINICS.TEXT.APPROVAL-DATE' }),
        formatter: (_, row) => formattedDate(row.approvalDate),
      },
      {
        dataField: 'approvalExpiryDate',
        text: formatMessage({ id: 'INSURANCE-APPROVALS.TEXT.APPROVAL-EXPIRY-DATE' }),
        formatter: (_, row) => formattedDate(row.approvalExpiryDate),
      },
      {
        dataField: 'visitNumber',
        text: formatMessage({ id: 'CORE.LABEL.VISIT-NUMBER' }),
        formatter: (_, row) => row.visitNumber || '-',
      },
      {
        dataField: 'useInInvoice',
        text: formatMessage({ id: 'CORE.LABEL.USE-IN-INVOICE' }),
        formatter: (_, row, rowIndex, formatExtraData) => (
          <Input
            checked={formatExtraData?.some((item) => item.id === row.performerBillingItemId)}
            id={`useInInvoice-${row.performerBillingItemId}`}
            invalid={!formatExtraData?.length}
            onChange={(event) => onItemSelect(row, event.target.checked)}
            type="checkbox"
          />
        ),
        formatExtraData: items,
        classes: 'sticky sticky--end text-center',
        headerClasses: 'sticky sticky--end text-center',
      },
    ],
    [formatMessage, items, locale, onItemSelect],
  );

  if (
    isCreatedAndNotCreateCreditNoteMode ||
    !isPayByInsurance ||
    !isPreviousApproval ||
    !insuranceApproval ||
    !insuranceCompany
  ) {
    return null;
  }

  return (
    <div className="mt-3 mb-4">
      <RequiredSectionHeading
        title={formatMessage({ id: 'CORE.LABEL.INSURANCE-APPROVED-PROCEDURES-SERVICES' })}
        invalid={!items.length}
      />
      <FreeFollowUpBadge />
      <LocalTable
        classes="table-sm"
        columns={columns}
        data={data}
        id="insuranceApprovalItems"
        keyField="performerBillingItemId"
        loading={loading}
        noDataComponent={
          <div className="fw-medium text-center small py-3">{formatMessage({ id: 'CORE.LABEL.NO-DATA' })}</div>
        }
      />
    </div>
  );
};
