import moment from 'moment';
import union from 'lodash/union';
import sortBy from 'lodash/sortBy';
import { Badge, FormFeedback, FormGroup, Label } from 'reactstrap';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { WrappedFieldProps } from 'redux-form/lib/Field';
import classnames from 'classnames';
import { Option } from 'app/types';
import { useAppIntl, useAppSelector } from 'app/helpers';
import { FORMATS_API_TIME } from 'app/shared/constants/formats.constants';
import { getRelatedDaysForSelectedDays } from './helpers';
import './weekdaysSelector.scss';

export interface WeekdaysSelectorProps {
  disabled?: boolean;
  duration?: number;
  frequencyByWeeks?: number;
  isRequired?: boolean;
  label: string;
  startTime?: string;
}

export const WeekdaysSelector = ({
  disabled,
  duration, // minutes
  frequencyByWeeks = 1,
  input: { onChange, value },
  isRequired,
  label,
  meta,
  startTime,
}: WrappedFieldProps & WeekdaysSelectorProps) => {
  const inputValue: string[] | null | undefined = value;
  const { formatMessage } = useAppIntl();

  const [relatedDays, setRelatedDays] = useState<string[]>([]);
  const invalid = !!meta.error && meta.touched;

  // Days
  const dayOfWeek: Option[] = useAppSelector((state) => state.dictionaries.data['days-of-week'] || []);
  const days = useMemo(() => sortBy(dayOfWeek, 'value'), [dayOfWeek]);

  const dayOffset: number | undefined = useMemo(() => {
    if (duration && startTime) {
      const start = moment(startTime, FORMATS_API_TIME);
      const end = moment(startTime, FORMATS_API_TIME).endOf('day');
      const difference = duration - 1 - end.diff(start, 'minutes'); // -1 to prevent day exceeding

      if (difference <= 0) {
        // It's the same day
        return 0;
      }

      // Remaining days
      return Math.ceil(difference / 1440); // 1440 is the number of minutes in the day
    } else {
      return;
    }
  }, [startTime, duration]);

  const onClick = useCallback(
    (dayValue: string) => {
      if (inputValue) {
        if (inputValue.includes(dayValue)) {
          // Unselect day
          onChange(inputValue.filter((sd) => sd !== dayValue));
        } else {
          // Select day
          onChange(union(inputValue, [dayValue]));
        }
      } else {
        // Select day
        onChange([dayValue]);
      }
    },
    [onChange, inputValue],
  );

  useEffect(() => {
    // Update related days
    if (inputValue && dayOffset !== undefined) {
      setRelatedDays(getRelatedDaysForSelectedDays(inputValue, dayOffset, frequencyByWeeks));
    } else {
      setRelatedDays([]);
    }
  }, [inputValue, dayOffset, frequencyByWeeks]);

  return (
    <FormGroup>
      <Label className="mb-2">
        {label}
        {isRequired && <span className="text-danger">&nbsp;*</span>}
      </Label>
      <div className={classnames('mb-1', { 'is-invalid': invalid })}>
        {days.map((d) => {
          return (
            <Badge
              className={classnames('weekdaySelector__day me-1 mb-1', {
                'weekdaySelector__day--disabled': disabled,
              })}
              color={
                inputValue?.includes(d.value)
                  ? 'success'
                  : relatedDays.includes(d.value)
                  ? 'light-success'
                  : 'light-secondary'
              }
              key={d.value}
              onClick={() => !disabled && onClick(d.value)}
              pill
            >
              {d.label}
            </Badge>
          );
        })}
      </div>
      {invalid && <FormFeedback className="d-block">{formatMessage({ id: meta.error })}</FormFeedback>}
    </FormGroup>
  );
};
