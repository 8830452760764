import moment from 'moment';
import { useMemo } from 'react';
import { Option } from 'app/types';

export type MonthOption = Option<number>;

export const useMonthOptions = () => {
  return useMemo<MonthOption[]>(() => {
    const months = moment.months();

    return months.map((month, index) => ({
      label: month,
      value: index + 1,
    }));
  }, []);
};
