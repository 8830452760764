import { put, select } from 'redux-saga/effects';
import { fetchGeneralTreatmentInformation } from 'app/redux/treatment/treatment.actions';
import { RootState } from 'app/types';

export function* refreshTreatmentHeader(appointmentId: string) {
  const {
    treatment: {
      generalTreatmentInformation: { params },
    },
  }: RootState = yield select();

  if (appointmentId) {
    yield put<any>(fetchGeneralTreatmentInformation(appointmentId, params?.previousDoctorAssessmentId));
  }
}
