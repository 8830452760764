import { useAppIntl } from 'app/helpers';
import { Locale } from 'app/types';

interface Props {
  value: number;
}

export const FormattedPercent = ({ value }: Props) => {
  const { locale } = useAppIntl();

  if (locale === Locale.ar) {
    return <>%{value}</>;
  }

  return <>{value}%</>;
};
