import { put } from 'redux-saga/effects';
import { PayloadAction } from '@reduxjs/toolkit';
import { Appointment, RemoteData } from 'app/types';
import { toggleModal } from 'app/redux/modals/modals.actions';
import { resetFullEdit } from 'app/redux/appointment/appointment.actions';
import { refreshManageWaiting } from 'app/sagas/apppointment/generators/refreshManageWaiting';
import { refreshCalendarDayView, refreshCalendarWeekView } from 'app/sagas/calendar/generators';
import { refreshAppointmentList } from 'app/sagas/apppointment/generators/refreshAppointmentList';
import { refreshManageAppointments } from 'app/sagas/apppointment/generators/refreshManageAppointments';
import { EDIT_APPOINTMENT_SUCCESS, EDIT_WAITING_APPOINTMENT_SUCCESS } from 'app/redux/appointment/appointment.types';

export function* afterEditAppointment({ type }: PayloadAction<RemoteData<Appointment>>) {
  // Appointment
  if (type === EDIT_APPOINTMENT_SUCCESS) {
    yield refreshAppointmentList();
    yield refreshManageAppointments();
    yield put(toggleModal('editAppointment', false));
    yield put(toggleModal('fullEditAppointment', false));
    yield put(toggleModal('bookingWizard', false));
    yield put<any>(resetFullEdit());
  }

  // Waiting Appointment
  if (type === EDIT_WAITING_APPOINTMENT_SUCCESS) {
    yield refreshAppointmentList();
    yield refreshManageWaiting();
    yield put(toggleModal('editWaitingAppointment', false));
    yield put(toggleModal('bookingWizard', false));
  }

  // Refresh Calendar Day and Week View
  yield refreshCalendarDayView();
  yield refreshCalendarWeekView();
}
