import React, { useMemo } from 'react';
import { Col, Row } from 'reactstrap';
import { Field } from 'redux-form';
import { InputComponent, phoneNumber, minLength, PhoneNumber } from 'app/shared';
import { PatientSearchField, PatientSearchFormData } from 'app/shared/patientSearch/types';
import { useAppIntl, useAppSelector } from 'app/helpers';
import { useChangeHandler } from 'app/shared/patientSearch/patientSearchFilters/helpers/useChangeHandler';
import { PatientSelectedDetails } from 'app/shared/patientSearch/patientSelectedDetails/patientSelectedDetails';

export const MIN_SEARCH_PHONE_NUMBER_LENGTH = 7;
export const MIN_SEARCH_CLINICY_MRN_LENGTH = 8;
export const MIN_SEARCH_INTERNAL_FILE_NUMBER_LENGTH = 1;
export const MIN_SEARCH_GOVERNMENT_ID_LENGTH = 5;
export type PatientSearchFormValues = Pick<
  PatientSearchFormData,
  'searchPartialPhoneNumber' | 'searchClinicyMrn' | 'searchInternalFileNumber' | 'searchGovernmentIssuedId'
>;

interface Props {
  formName: string;
  anySelected: boolean;
  isDependent?: boolean;
  clinicId?: string;
  patientId?: string;
  onFiltersChanged?: (values: Partial<PatientSearchFormValues>) => void;
}

export const PatientSearchFilters = ({
  formName,
  anySelected,
  isDependent,
  clinicId,
  patientId,
  onFiltersChanged,
}: Props) => {
  const { formatMessage } = useAppIntl();

  const searchFormValues = useAppSelector((state) => ({
    [PatientSearchField.searchPhoneNumber]: state.form[formName]?.values?.[PatientSearchField.searchPhoneNumber],
    [PatientSearchField.searchClinicyMrn]: state.form[formName]?.values?.[PatientSearchField.searchClinicyMrn],
    [PatientSearchField.searchInternalFileNumber]:
      state.form[formName]?.values?.[PatientSearchField.searchInternalFileNumber],
    [PatientSearchField.searchGovernmentIssuedId]:
      state.form[formName]?.values?.[PatientSearchField.searchGovernmentIssuedId],
  }));

  const minClinicyMrnLength = useMemo(() => minLength(MIN_SEARCH_CLINICY_MRN_LENGTH), []);
  const minInternalFileNumberLength = useMemo(() => minLength(MIN_SEARCH_INTERNAL_FILE_NUMBER_LENGTH), []);
  const minGovernmentIdLength = useMemo(() => minLength(MIN_SEARCH_GOVERNMENT_ID_LENGTH), []);

  const handleOnChange = useChangeHandler({ anySelected, searchFormValues, onFiltersChanged });

  return (
    <Row xs={1} lg={2} xl={4}>
      {anySelected && <PatientSelectedDetails clinicId={clinicId} patientId={patientId} isDependent={isDependent} />}
      {!anySelected && (
        <>
          <Col>
            <Field
              name={PatientSearchField.searchPhoneNumber}
              component={PhoneNumber}
              label={formatMessage({ id: 'CORE.LABEL.MOBILE-NUMBER' })}
              onChange={handleOnChange}
              validate={[phoneNumber]}
              disabled={anySelected}
            />
          </Col>
          <Col>
            <Field
              component={InputComponent}
              name={PatientSearchField.searchClinicyMrn}
              type="text"
              label={formatMessage({ id: 'CORE.LABEL.CLINICY-MRN' })}
              onChange={handleOnChange}
              disabled={anySelected}
              validate={[minClinicyMrnLength]}
            />
          </Col>
          <Col>
            <Field
              component={InputComponent}
              name={PatientSearchField.searchInternalFileNumber}
              type="text"
              label={formatMessage({ id: 'CORE.LABEL.INTERNAL-FILE-NUMBER' })}
              onChange={handleOnChange}
              disabled={anySelected}
              validate={[minInternalFileNumberLength]}
            />
          </Col>
          <Col>
            <Field
              component={InputComponent}
              name={PatientSearchField.searchGovernmentIssuedId}
              type="text"
              label={formatMessage({ id: 'CORE.LABEL.GOVERNMENT-ID' })}
              onChange={handleOnChange}
              disabled={anySelected}
              validate={[minGovernmentIdLength]}
            />
          </Col>
        </>
      )}
    </Row>
  );
};
