import React from 'react';
import { Alert, AlertType } from 'app/redux/mass-adding/types';
import { AlertListItem } from 'app/components/alertList/item/item';

interface Props {
  alerts: Alert[];
  type?: AlertType;
  listKey?: string;
}

export const AlertList = ({ alerts, type = 'danger', listKey = 'errors' }: Props) => {
  if (alerts && alerts.length) {
    return (
      <div className={`alert alert-${type} pb-0`}>
        {alerts.map((alert, index) => (
          <AlertListItem item={alert} type={type} key={index} listKey={listKey} />
        ))}
      </div>
    );
  }

  return null;
};
