import { useMemo } from 'react';
import { useAppSelector } from 'app/helpers';
import { FORMS_INVOICING_PROCESS_PAYMENT_MODE } from 'app/shared';
import { ExtendedItemsSnapshot } from 'app/features/invoicingProcess/process/items/types';
import { PaymentModeFormData } from 'app/features/invoicingProcess/process/paymentMode/form/types';
import { generateExtendedItems } from 'app/features/invoicingProcess/process/items/helpers/generateExtendedItems';

export const useExtendedItemsSnapshots = (): ExtendedItemsSnapshot[] => {
  const itemsSnapshots = useAppSelector((state) => state.invoicingProcess.data?.itemsSnapshots);
  const invoiceType = useAppSelector((state) => state.invoicingProcess.data?.invoiceType?.value);
  const paymentModeValues: Partial<PaymentModeFormData> | undefined = useAppSelector(
    (state) => state.form[FORMS_INVOICING_PROCESS_PAYMENT_MODE],
  )?.values;
  const isPrimaryCareSpeciality = useAppSelector(
    (state) => !!state.invoicingProcess.data?.doctor?.details?.isPrimaryCareSpeciality,
  );

  return useMemo<ExtendedItemsSnapshot[]>(() => {
    if (itemsSnapshots && paymentModeValues) {
      return itemsSnapshots.map((snapshot) => ({
        ...snapshot,
        items: generateExtendedItems({
          invoiceType,
          isPrimaryCareSpeciality,
          items: snapshot.items,
          paymentModeValues,
        }),
      }));
    }

    return [];
  }, [itemsSnapshots, paymentModeValues, invoiceType, isPrimaryCareSpeciality]);
};
