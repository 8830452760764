import { DurationValues } from '../types';

export const getDurationValuesFromMinutes = (minutes: number): DurationValues => {
  let value = minutes;
  const minutesInDay = 24 * 60;
  const minutesInHour = 60;
  const result = {
    days: 0,
    hours: 0,
    minutes: 0,
  };

  if (minutes > minutesInDay) {
    const d = Math.floor(value / minutesInDay);
    value = value - d * minutesInDay;
    result.days = d;
  }

  if (value > minutesInHour) {
    const h = Math.floor(value / minutesInHour);
    value = value - h * minutesInHour;
    result.hours = h;
  }

  if (value === minutesInHour) {
    // 60 minutes -> 1 hour
    result.hours = 1;
    result.minutes = 0;
  } else {
    result.minutes = value;
  }

  return result;
};
