import toast from 'react-hot-toast';
import { destroy } from 'redux-form';
import { put, select } from 'redux-saga/effects';
import { RootState } from 'app/types';
import { DEFAULT_PAGINATION_PARAMS, FORMS_CREDIT_NOTE } from 'app/shared';
import { toggleModal } from 'app/redux/modals/modals.actions';
import { InvoicingProcessMode } from 'app/redux/invoicingProcess/types';
import { setMode } from 'app/redux/invoicingProcess/invoicingProcess.actions';
import { fetchAppointmentInvoices } from 'app/redux/remoteData/remoteData.actions';
import { refreshInvoiceList } from 'app/sagas/invoicingProcess/generators/refreshInvoiceList';

export function* afterCreateCreditNoteSuccess() {
  const {
    invoicingProcess: { appointmentId },
  }: RootState = yield select();

  yield put<any>(destroy(FORMS_CREDIT_NOTE));
  yield put<any>(toggleModal('creditNote', false));
  yield put<any>(toggleModal('creditNotePackageActions', false));
  yield toast.success('CORE.TEXT.CREDIT-NOTE-CREATED-SUCCESSFULLY');

  if (appointmentId) {
    yield put<any>(setMode(InvoicingProcessMode.Invoices));
    yield put<any>(fetchAppointmentInvoices(DEFAULT_PAGINATION_PARAMS, appointmentId));
  } else {
    yield put<any>(toggleModal('invoicingProcess', false));
  }

  yield refreshInvoiceList();
}
