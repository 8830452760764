import { delay, put, select, takeEvery } from 'redux-saga/effects';
import { push } from 'connected-react-router';
import toast from 'react-hot-toast';
import {
  ADD_INVOICE_ITEM_SUCCESS,
  ADD_INSURANCE_PAYMENT_SUCCESS,
  ADD_PATIENT_PAYMENT_SUCCESS,
  EDIT_PATIENT_PAYMENT_SUCCESS,
  EDIT_INSURANCE_PAYMENT_SUCCESS,
  FETCH_INVOICE_APPOINTMENT_SUCCESS,
  FETCH_INVOICE_APPOINTMENT_FAILURE,
  FETCH_INVOICE_DETAILS_SUCCESS,
  CREATE_INVOICE_SUCCESS,
  CREATE_INVOICE_FAILURE,
  DELETE_INVOICE_ITEM_SUCCESS,
  EDIT_INVOICE_ITEM_SUCCESS,
  DELETE_INVOICE_PAYMENT_SUCCESS,
} from 'app/redux/invoice/invoice.types';
import { fetchInvoiceDetails, fetchSpecialitiesDictionaries } from 'app/redux/invoice/invoice.actions';
import { fetchPatientInsurances, fetchSummary } from 'app/redux/patient/patient.actions';
import { ADD_INVOICE_PAYMENTS_SUCCESS } from 'app/redux/invoices/invoices.types';
import { fetchPatientInvoicesToPay } from 'app/redux/invoices/invoices.actions';
import { getClinicIdsFromCurrent } from 'app/helpers';

function* afterCreateInvoiceSuccess({ payload }) {
  yield put(push(`/invoices/${payload.id}`));
}

function* afterCreateInvoiceFailure({ payload }) {
  if (payload.status === 406) {
    yield toast.error('APPOINTMENTS.TEXT.NOT-CHECKED-IN-FAIL');
  }
}

function* afterFailedAppointmentFetch() {
  yield put(push('/'));
}

function* afterInvoiceModification(action) {
  const {
    meta: { invoiceId },
  } = action;
  yield put(fetchInvoiceDetails(invoiceId));
}

function* afterFetchInvoiceInfo(action) {
  const {
    appointmentDate,
    branch: { id },
    patient: { id: patientId },
  } = action.payload;
  const {
    router: { location },
  } = yield select();
  if (!location.pathname.includes('/hi/')) {
    yield put(fetchSpecialitiesDictionaries(id));
    yield put(fetchPatientInsurances(patientId, appointmentDate));
  }
}

function* afterCreateInvoicePaymentsSuccess(action) {
  const {
    meta: { patientId },
  } = action;
  yield put(fetchPatientInvoicesToPay(patientId));
  yield delay(3000); //TODO change to a smaller value or eliminate delay at best
  const {
    auth: { current },
  } = yield select();
  yield put(fetchSummary(patientId, { clinicIds: getClinicIdsFromCurrent(current) }));
}

function* invoiceSaga() {
  yield takeEvery(CREATE_INVOICE_SUCCESS, afterCreateInvoiceSuccess);
  yield takeEvery(CREATE_INVOICE_FAILURE, afterCreateInvoiceFailure);
  yield takeEvery([FETCH_INVOICE_DETAILS_SUCCESS, FETCH_INVOICE_APPOINTMENT_SUCCESS], afterFetchInvoiceInfo);
  yield takeEvery(FETCH_INVOICE_APPOINTMENT_FAILURE, afterFailedAppointmentFetch);
  yield takeEvery(
    [
      ADD_INVOICE_ITEM_SUCCESS,
      ADD_INSURANCE_PAYMENT_SUCCESS,
      ADD_PATIENT_PAYMENT_SUCCESS,
      DELETE_INVOICE_ITEM_SUCCESS,
      DELETE_INVOICE_PAYMENT_SUCCESS,
      EDIT_INVOICE_ITEM_SUCCESS,
      EDIT_INSURANCE_PAYMENT_SUCCESS,
      EDIT_PATIENT_PAYMENT_SUCCESS,
    ],
    afterInvoiceModification,
  );
  yield takeEvery(ADD_INVOICE_PAYMENTS_SUCCESS, afterCreateInvoicePaymentsSuccess);
}

export default invoiceSaga;
