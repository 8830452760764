import { v4 } from 'uuid';
import PropTypes from 'prop-types';
import { FormGroup } from 'reactstrap';
import React, { useCallback, useEffect, useMemo } from 'react';
import { Select } from 'app/components';
import { useAppIntl } from 'app/helpers';
import { CustomLabel } from 'app/components/customLabel/customLabel';
import { useOptionDisabled } from 'app/shared/form-elements/select/helpers';
import { Footer } from 'app/shared/form-elements/select/shared/footer/footer';

const SELECT_ALL_VALUE = 'SELECT_ALL';

export const SelectComponent = ({
  className,
  defaultValue,
  disabled = false,
  filterOption,
  formatOptionLabel,
  hasSelectAll,
  id,
  info,
  input: { onChange, value },
  isClearable = false,
  isMulti = false,
  isOptionDisabled,
  isRequired = false,
  isSearchable = true,
  label,
  menuPlacement,
  menuPosition,
  meta: { error, touched },
  noMargin,
  noOptionsMessage,
  options,
  optionsLimit,
  placeholder,
  size,
  styles,
  text,
}) => {
  const { formatMessage, isRtl, locale } = useAppIntl();
  const fieldId = useMemo(() => id || `select-${v4()}`, [id]);
  const isOptionDisabledByLimit = useOptionDisabled(value, optionsLimit);
  const invalid = !!error && touched;

  const allOptions = useMemo(
    () =>
      hasSelectAll && isMulti
        ? [
            {
              label: formatMessage({ id: 'CORE.TEXT.SELECT-ALL' }),
              value: SELECT_ALL_VALUE,
            },
            ...options,
          ]
        : options,
    [formatMessage, isMulti, options, hasSelectAll],
  );

  const defaultFormatOptionLabel = useCallback(
    (option) => {
      const label = option.translation ? option.translation[locale] : option.label;
      return option.value === SELECT_ALL_VALUE ? <strong>{label}</strong> : <>{label}</>;
    },
    [locale],
  );

  const handleOnChange = useCallback(
    (value) => {
      if (hasSelectAll && isMulti && value && value.some((o) => o.value === SELECT_ALL_VALUE)) {
        return onChange(options);
      }

      return onChange(value);
    },
    [isMulti, options, onChange, hasSelectAll],
  );

  useEffect(() => {
    if (!value && !!defaultValue) {
      onChange(defaultValue);
    }
    // eslint-disable-next-line
  }, []);

  return (
    <FormGroup className={className} noMargin={noMargin}>
      {label && <CustomLabel fieldId={fieldId} info={info} isRequired={isRequired} label={label} />}
      <Select
        closeMenuOnSelect={!isMulti}
        filterOption={filterOption}
        formatOptionLabel={formatOptionLabel || defaultFormatOptionLabel}
        getOptionLabel={(option) => option?.translation?.[locale] || option.label}
        id={fieldId}
        inputId={`${fieldId}-input`}
        invalid={invalid}
        isClearable={isClearable}
        isDisabled={disabled}
        isMulti={isMulti}
        isOptionDisabled={isOptionDisabled || isOptionDisabledByLimit}
        isRtl={isRtl}
        isSearchable={isSearchable}
        menuPlacement={menuPlacement}
        menuPosition={menuPosition}
        noOptionsMessage={() => noOptionsMessage}
        onChange={handleOnChange}
        options={allOptions}
        placeholder={placeholder}
        size={size}
        styles={styles}
        value={value}
      />
      <Footer error={error} formatMessage={formatMessage} text={text} />
    </FormGroup>
  );
};

SelectComponent.propTypes = {
  disabled: PropTypes.bool,
  filterOption: PropTypes.func,
  formatOptionLabel: PropTypes.func,
  hasSelectAll: PropTypes.bool,
  id: PropTypes.string,
  info: PropTypes.string,
  input: PropTypes.object.isRequired,
  isClearable: PropTypes.bool,
  isMulti: PropTypes.bool,
  isOptionDisabled: PropTypes.func,
  isRequired: PropTypes.bool,
  isSearchable: PropTypes.bool,
  label: PropTypes.string,
  menuPlacement: PropTypes.string,
  menuPosition: PropTypes.oneOf(['fixed', 'absolute']),
  meta: PropTypes.object.isRequired,
  noMargin: PropTypes.bool,
  noOptionsMessage: PropTypes.string,
  options: PropTypes.array.isRequired,
  optionsLimit: PropTypes.number,
  placeholder: PropTypes.string,
  size: PropTypes.oneOf(['sm', 'lg']),
  text: PropTypes.string,
};
