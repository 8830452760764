import { AnyAction } from 'redux';
import { PerformerBillingItem, RemoteData } from 'app/types';
import * as types from 'app/redux/billingItemsLists/billingItemsLists.types';

interface BillingItemsListsReducer {
  performerItemsList: RemoteData<PerformerBillingItem | null>;
  expandedPerformerId: string | null;
}

const initialState: BillingItemsListsReducer = {
  performerItemsList: {
    data: null,
    loading: false,
  },
  expandedPerformerId: null,
};

export const billingItemsListsReducer = (state = initialState, action: AnyAction): BillingItemsListsReducer => {
  switch (action.type) {
    // Fetch Performer Billing Item
    case types.FETCH_PERFORMER_BILLING_ITEM_REQUEST:
      return {
        ...state,
        performerItemsList: {
          data: initialState.performerItemsList.data,
          loading: true,
        },
      };
    case types.FETCH_PERFORMER_BILLING_ITEM_SUCCESS:
      return {
        ...state,
        performerItemsList: {
          data: action.payload.data,
          loading: false,
        },
      };
    case types.FETCH_PERFORMER_BILLING_ITEM_FAILURE:
      return {
        ...state,
        performerItemsList: {
          data: initialState.performerItemsList.data,
          loading: false,
        },
      };

    // Currently expanded performer
    case types.SET_EXPANDED_PERFORMER:
      return {
        ...state,
        expandedPerformerId: action.payload,
      };

    // Default
    default:
      return state;
  }
};
