import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  InsuranceContract,
  InsuranceContractor,
  InsurancePolicies,
  InsurancePoliciesClasses,
  InsuranceRemovedContract,
  RemovedInsuranceContractor,
  RemovedInsurancePolicies,
} from 'app/types';
import { ContractorState } from './types';
import {
  InsuranceBillingItemsContract,
  InsuranceBillingMedicationsContract,
  InsuranceBillingPackagesContract,
  InsuranceExtraBillingItemsContract,
} from 'app/types/InsuranceBillingItemsContract';

const initialState: ContractorState = {
  selectedContractor: null,
  selectedContract: null,
  selectedPolicies: null,
  selectedPoliciesClass: null,
  selectedBillingItems: null,
  selectedExtraBillingItems: null,
  selectedPackagesItems: null,
  selectedMedicalsItems: null,
};

const insuranceContractors = createSlice({
  name: 'insuranceContractors',
  initialState,
  reducers: {
    setContractor: (state, action: PayloadAction<InsuranceContractor | RemovedInsuranceContractor | null>) => {
      state.selectedContractor = action.payload;
    },

    setPolicies: (state, action: PayloadAction<InsurancePolicies | RemovedInsurancePolicies>) => {
      state.selectedPolicies = action.payload;
    },

    setPoliciesClass: (state, action: PayloadAction<InsurancePoliciesClasses>) => {
      state.selectedPoliciesClass = action.payload;
    },

    setContract: (state, action: PayloadAction<InsuranceContract | InsuranceRemovedContract | null>) => {
      state.selectedContract = action.payload;
    },

    setBillingItems: (state, action: PayloadAction<InsuranceBillingItemsContract>) => {
      state.selectedBillingItems = action.payload;
    },

    setExtraBillingItems: (state, action: PayloadAction<InsuranceExtraBillingItemsContract>) => {
      state.selectedExtraBillingItems = action.payload;
    },

    setPackagesItems: (state, action: PayloadAction<InsuranceBillingPackagesContract>) => {
      state.selectedPackagesItems = action.payload;
    },

    setMedicationsItems: (state, action: PayloadAction<InsuranceBillingMedicationsContract>) => {
      state.selectedMedicalsItems = action.payload;
    },
  },
});

export const {
  setContractor,
  setContract,
  setPoliciesClass,
  setPolicies,
  setBillingItems,
  setExtraBillingItems,
  setPackagesItems,
  setMedicationsItems,
} = insuranceContractors.actions;
export default insuranceContractors.reducer;
