import { Col, Row } from 'reactstrap';
import { DescriptionListProps } from './types';

export const DescriptionList = (props: DescriptionListProps) => (
  <Row xs={1} md={2} xl={3} {...props.rowProps}>
    {props.items.map((item, index) => {
      if (!item.value && !props.showEmpty) {
        return null;
      }

      return (
        <Col {...item.colProps} tag="dl" className="horizontal" key={item.id || index}>
          <dt>{item.label}:</dt>
          <dd className={item.ddClassName}>{item.value || '-'}</dd>
        </Col>
      );
    })}
  </Row>
);
