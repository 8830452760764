import { RawDraftContentState } from 'draft-js';
import { getPlainText } from './getPlainText';

interface Props {
  initialRich?: RawDraftContentState;
  currentRich?: RawDraftContentState;
}

export const isRichTextBoxPristine = ({ initialRich, currentRich }: Props): boolean => {
  // Conditions
  const currentPlainText = currentRich && getPlainText(currentRich.blocks);
  const initialPlainText = initialRich && getPlainText(initialRich.blocks);

  return !currentPlainText && !initialPlainText ? true : currentPlainText === initialPlainText;
};
