import { v4 } from 'uuid';
import { useMemo } from 'react';
import { IconHelpCircle } from '@tabler/icons-react';
import { Label as ReactstrapLabel, UncontrolledTooltip } from 'reactstrap';

interface Props {
  fieldId?: string;
  info?: string;
  isRequired?: boolean;
  label: string;
}

export const CustomLabel = ({ fieldId, info, isRequired, label }: Props) => {
  const infoId = useMemo(() => `fieldId-${v4()}`, []);

  return (
    <ReactstrapLabel for={fieldId}>
      {label}
      {isRequired && <span className="text-danger">&nbsp;*</span>}
      {info && (
        <>
          <IconHelpCircle
            className="ms-1 text-secondary cursor-pointer"
            id={infoId}
            size={16}
            strokeWidth={2.3}
            style={{ marginTop: -2 }}
          />
          <UncontrolledTooltip target={infoId}>{info}</UncontrolledTooltip>
        </>
      )}
    </ReactstrapLabel>
  );
};
