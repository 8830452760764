import { Col, Row } from 'reactstrap';
import { RowProps } from 'reactstrap/types/lib/Row';
import { InjectedFormProps } from 'redux-form/lib/reduxForm';
import { BaseFieldProps, Field, Form, reduxForm } from 'redux-form';
import { useAppIntl, useDate } from 'app/helpers';
import { DatePickerComponent, DatePickerComponentProps, FORMS_INVOICING_PROCESS_PAYMENT_METHOD_CFG } from 'app/shared';
import { useInvoicingProcessState } from 'app/features/invoicingProcess/process/helpers';
import { validate } from 'app/features/invoicingProcess/process/paymentMethod/paymentConfigurationForm/validate';
import {
  PaymentConfigurationField,
  PaymentConfigurationFormData,
} from 'app/features/invoicingProcess/process/paymentMethod/paymentConfigurationForm/types';

const FormComponent = ({ handleSubmit }: InjectedFormProps<PaymentConfigurationFormData>) => {
  const { todayStart } = useDate();
  const { formatMessage } = useAppIntl();
  const { isCreated, isCreateCreditNoteMode } = useInvoicingProcessState();
  const rowProps: RowProps = { xs: 1, lg: 3, xl: 4 };

  return (
    <Form onSubmit={handleSubmit}>
      <Row {...rowProps}>
        <Col>
          <Field<BaseFieldProps<DatePickerComponentProps>>
            name={PaymentConfigurationField.dueDate}
            component={DatePickerComponent}
            props={{
              disabled: isCreated && !isCreateCreditNoteMode,
              isClearable: false,
              isRequired: true,
              label: formatMessage({ id: 'CORE.LABEL.DUE-DATE', defaultMessage: 'Due Date' }),
              minDate: todayStart,
            }}
          />
        </Col>
      </Row>
    </Form>
  );
};

export const PaymentConfigurationForm = reduxForm<PaymentConfigurationFormData>({
  destroyOnUnmount: false,
  form: FORMS_INVOICING_PROCESS_PAYMENT_METHOD_CFG,
  onSubmit: () => null,
  validate,
})(FormComponent);
