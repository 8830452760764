import { connect } from 'react-redux';
import get from 'lodash/get';
import { toggleModal } from 'app/redux/modals/modals.actions';
import { ChangePasswordModalComponent } from './change-password-modal.component';

const mapStateToProps = ({ modal }) => ({
  formDescription: get(modal, 'changePassword.formDescription'),
  isOpen: get(modal, 'changePassword.isOpen'),
});

const mapDispatchToProps = (dispatch) => ({
  toggle: () => dispatch(toggleModal('changePassword')),
});

export const ChangePasswordModalContainer = connect(mapStateToProps, mapDispatchToProps)(ChangePasswordModalComponent);
