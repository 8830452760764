import {
  ADD_INSURANCE_SUCCESS,
  EDIT_INSURANCE_SUCCESS,
  DELETE_INSURANCE_SUCCESS,
} from 'app/redux/insurance/insurance.types';
import {
  CREATE_INVOICE_REQUEST_SUCCESS,
  POST_INSURANCE_APPROVAL_SUCCESS,
  PUT_INSURANCE_APPROVAL_STATUS_SUCCESS,
  PUT_INSURANCE_APPROVAL_SUCCESS,
} from 'app/redux/takeAction/takeAction.types';
import {
  refreshInsuranceApprovalsList,
  afterInsuranceModification,
  afterInsuranceDelete,
  afterCreateInsurancesSuccess,
} from 'app/sagas/insurances/generators';
import { takeEvery } from 'redux-saga/effects';
import { SEND_REQUEST_FOR_APPROVAL_SUCCESS } from 'app/redux/doctorAssessment/doctorAssessment.types';

function* insurancesSaga() {
  yield takeEvery(ADD_INSURANCE_SUCCESS, afterCreateInsurancesSuccess);
  yield takeEvery(EDIT_INSURANCE_SUCCESS, afterInsuranceModification);
  yield takeEvery(DELETE_INSURANCE_SUCCESS, afterInsuranceDelete);
  yield takeEvery(
    [
      PUT_INSURANCE_APPROVAL_STATUS_SUCCESS,
      SEND_REQUEST_FOR_APPROVAL_SUCCESS,
      PUT_INSURANCE_APPROVAL_SUCCESS,
      POST_INSURANCE_APPROVAL_SUCCESS,
      CREATE_INVOICE_REQUEST_SUCCESS,
    ],
    refreshInsuranceApprovalsList,
  );
}

export default insurancesSaga;
