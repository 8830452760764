import { Field } from 'redux-form';
import { InputComponent } from 'app/shared';
import { percentageNormalizer } from 'app/helpers/normalizers/percentageNormalizer';
import { PaymentModeFormData } from 'app/features/invoicingProcess/process/paymentMode/form/types';

interface Props {
  disabled: boolean;
  label: string;
  name: keyof PaymentModeFormData;
}
export const PatientShareField = ({ disabled, label, name }: Props) => {
  return (
    <Field
      disabled={disabled}
      name={name}
      appendContent="%"
      component={InputComponent}
      label={label}
      min={0}
      max={100}
      normalize={percentageNormalizer}
      step={1}
      type="number"
    />
  );
};
