import { takeEvery } from 'redux-saga/effects';
import { refreshTreatmentInfo } from 'app/sagas/treatment/generators';
import {
  ADD_PATIENT_ALLERGY_REQUEST,
  ADD_PATIENT_ALLERGY_SUCCESS,
  EDIT_PATIENT_ALLERGY_SUCCESS,
} from 'app/redux/patientAllergy/patientAllergy.types';
import {
  REMOVE_DOCTOR_ASSESSMENT_SUCCESS,
  SEND_ATTACHMENT_SUCCESS,
} from 'app/redux/doctorAssessment/doctorAssessment.types';
import {
  ADD_FAMILY_ILLNESS_SUCCESS,
  ADD_PATIENT_ILLNESS_SUCCESS,
  ADD_SPECIALTY_NOTE_SUCCESS,
  ADD_SURGERY_SUCCESS,
  DELETE_FAMILY_ILLNESS_SUCCESS,
  DELETE_PATIENT_ATTACHMENT_SUCCESS,
  DELETE_PATIENT_ILLNESS_SUCCESS,
  DELETE_SPECIALTY_NOTE_SUCCESS,
  DELETE_SURGERY_SUCCESS,
  EDIT_SPECIALTY_NOTE_SUCCESS,
} from 'app/redux/treatment/treatment.types';
import { ADD_UNRELATED_PRESCRIPTIONS_SUCCESS } from 'app/redux/prescription/prescription.types';
import { PUT_ASSESSMENT_DATA_SUCCESS } from 'app/redux/initialAssessmentWizard/initialAssessmentWizard.types';
import { REFRESH_TREATMENT_INFO } from 'app/sagas/treatment/types';

function* treatmentSaga() {
  yield takeEvery(
    [
      ADD_FAMILY_ILLNESS_SUCCESS,
      ADD_PATIENT_ALLERGY_SUCCESS,
      ADD_PATIENT_ILLNESS_SUCCESS,
      ADD_SURGERY_SUCCESS,
      ADD_PATIENT_ALLERGY_REQUEST,
      ADD_UNRELATED_PRESCRIPTIONS_SUCCESS,
      ADD_SPECIALTY_NOTE_SUCCESS,
      DELETE_FAMILY_ILLNESS_SUCCESS,
      DELETE_PATIENT_ILLNESS_SUCCESS,
      DELETE_PATIENT_ATTACHMENT_SUCCESS,
      DELETE_SURGERY_SUCCESS,
      DELETE_SPECIALTY_NOTE_SUCCESS,
      EDIT_PATIENT_ALLERGY_SUCCESS,
      EDIT_SPECIALTY_NOTE_SUCCESS,
      PUT_ASSESSMENT_DATA_SUCCESS,
      REMOVE_DOCTOR_ASSESSMENT_SUCCESS,
      SEND_ATTACHMENT_SUCCESS,
      REFRESH_TREATMENT_INFO,
    ],
    refreshTreatmentInfo,
  );
}

export default treatmentSaga;
