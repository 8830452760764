import { useEffect, useMemo } from 'react';
import classnames from 'classnames';
import moment from 'moment';
import ReactDatePicker, { ReactDatePickerProps, registerLocale, setDefaultLocale } from 'react-datepicker';
import ar from 'date-fns/locale/ar';
import { useAppIntl } from 'app/helpers';
import { Option } from 'app/types';
import { renderCustomHeader } from './helpers';
import './datePicker.scss';

interface CustomReactDatePickerProps {
  inactive?: boolean;
}

// Register additional locales
registerLocale('ar', { ...ar, options: { weekStartsOn: 0 } });

export const DatePicker = (props: ReactDatePickerProps & CustomReactDatePickerProps) => {
  const { formatMessage, locale } = useAppIntl();
  const minDate: Date = useMemo(() => props.minDate || moment().subtract(120, 'y').toDate(), [props.minDate]);
  const maxDate: Date = useMemo(() => props.maxDate || moment().add(40, 'y').toDate(), [props.maxDate]);

  const years: Option[] = useMemo(() => {
    const range = [moment(minDate).year(), moment(maxDate).year()];
    const years: Option[] = [];

    for (let y = range[1]; y >= range[0]; y--) {
      years.push({
        label: String(y),
        value: String(y),
      });
    }

    return years;
  }, [minDate, maxDate]);

  const months: Option[] = useMemo(
    () =>
      moment.months().map((m) => ({
        label: m,
        value: m,
      })),
    [],
  );

  useEffect(() => {
    setDefaultLocale(locale);
  }, [locale]);

  return (
    <ReactDatePicker
      disabledKeyboardNavigation
      popperPlacement="bottom"
      renderCustomHeader={(params) =>
        renderCustomHeader(params, {
          months: props.showMonthYearPicker ? undefined : months,
          years,
          showDisabledMonthNavigation: props.showDisabledMonthNavigation,
        })
      }
      todayButton={formatMessage({ id: 'CORE.TEXT.TODAY' })}
      useWeekdaysShort
      {...props}
      calendarClassName={classnames(props.calendarClassName, {
        'datePicker--inactive': props.inactive,
        datePicker__withTime: props.showTimeSelect,
      })}
      isClearable={props.disabled ? false : props.isClearable}
      minDate={minDate}
      maxDate={maxDate}
    />
  );
};
