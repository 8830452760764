import { useCallback } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { matchPath } from 'react-router';
import { LocationDescriptorObject } from 'history';
import classnames from 'classnames';
import { IconChevronLeft, IconChevronRight } from '@tabler/icons-react';
import { Collapse } from 'reactstrap';
import { CollapseIcon } from 'app/components';
import { useAppDispatch, useAppIntl, useWindowSize, useAppSelector, BREAKPOINT_SM } from 'app/helpers';
import { closeNavigationSubmenu, openNavigationSubmenu } from 'app/redux/app/app.actions';
import { useNavigationItems } from './helpers';
import './navigation.scss';

const CHEVRON_ICON_SIZE = 12;
const CHEVRON_STROKE_WIDTH = 3;

interface Props {
  collapseMenu: () => void;
}

export const Navigation = ({ collapseMenu }: Props) => {
  const dispatch = useAppDispatch();
  const { formatMessage, isRtl } = useAppIntl();
  const navigationItems = useNavigationItems();
  const windowSize = useWindowSize();
  const { pathname } = useLocation();

  const selectedSubmenu = useAppSelector((state) => state.app.navigation.selectedSubmenu);

  const closeMenu = useCallback(() => windowSize.width <= BREAKPOINT_SM && collapseMenu(), [collapseMenu, windowSize]);
  const openSubmenu = useCallback((id: string) => dispatch(openNavigationSubmenu(id)), [dispatch]);
  const closeSubmenu = useCallback((id: string) => dispatch(closeNavigationSubmenu(id)), [dispatch]);

  const isMatched = useCallback(
    (to: string | LocationDescriptorObject) => {
      const exactCategories = ['clinics', 'mass-adding'];
      return (
        typeof to === 'string' &&
        !!matchPath(pathname, {
          path: to,
          exact: exactCategories.some((c) => pathname.includes(c)),
          strict: false,
        })
      );
    },
    [pathname],
  );

  return (
    <ul className="navigation">
      {navigationItems.map((item) => {
        const hasSubMenu = !!item.subItems.length;

        return (
          <li
            className={classnames('navigation__item', {
              'navigation__item--withSubmenu': hasSubMenu,
            })}
            key={item.id}
          >
            {!hasSubMenu && (
              <Link
                className={classnames('navigation__link', {
                  'navigation__link--active': isMatched(item.to),
                })}
                id={item.id}
                onClick={() => {
                  if (hasSubMenu) {
                    selectedSubmenu.includes(item.id) ? closeSubmenu(item.id) : openSubmenu(item.id);
                  } else {
                    closeMenu();
                  }
                }}
                to={item.to}
              >
                {/* Icon */}
                {item.icon && <div className="navigation__linkIcon">{item.icon}</div>}
                {/* Text */}
                <span className="navigation__linkText menu__hiddenElement text-truncate">
                  {formatMessage({ id: item.text })}
                </span>
              </Link>
            )}

            {/* Submenu */}
            {hasSubMenu && (
              <>
                <div
                  className={classnames('navigation__link', {
                    'navigation__link--active': isMatched(item.to),
                  })}
                  id={item.id}
                  onClick={() => (selectedSubmenu.includes(item.id) ? closeSubmenu(item.id) : openSubmenu(item.id))}
                >
                  {/* Icon */}
                  {item.icon && <div className="navigation__linkIcon">{item.icon}</div>}
                  {/* Text */}
                  <span className="navigation__linkText menu__hiddenElement text-truncate">
                    {formatMessage({ id: item.text })}
                  </span>
                  {/* Chevron icon */}
                  <div className="navigation__linkChevronIcon menu__hiddenElement">
                    <CollapseIcon active={selectedSubmenu.includes(item.id)} />
                  </div>
                </div>
                <Collapse isOpen={selectedSubmenu.includes(item.id)}>
                  <ul className="subNavigation">
                    {item.subItems.map((subItem) => (
                      <li className="subNavigation__item" key={subItem.id}>
                        <Link
                          className={classnames('navigation__link', {
                            'navigation__link--active': isMatched(subItem.to),
                          })}
                          id={subItem.id}
                          onClick={closeMenu}
                          to={subItem.to}
                        >
                          {/* Submenu Icon */}
                          <div className="navigation__linkIcon">
                            {isRtl ? (
                              <IconChevronLeft size={CHEVRON_ICON_SIZE} strokeWidth={CHEVRON_STROKE_WIDTH} />
                            ) : (
                              <IconChevronRight size={CHEVRON_ICON_SIZE} strokeWidth={CHEVRON_STROKE_WIDTH} />
                            )}
                          </div>
                          {/* Submenu Text */}
                          <span className="navigation__linkText menu__hiddenElement text-truncate">
                            {formatMessage({ id: subItem.text })}
                          </span>
                        </Link>
                      </li>
                    ))}
                  </ul>
                </Collapse>
              </>
            )}
          </li>
        );
      })}
    </ul>
  );
};
