export const SET_ACTIVE_STEP = 'initialAssessmentWizard/SET_ACTIVE_STEP';

export const SET_ARRIVAL = 'initialAssessmentWizard/SET_ARRIVAL';

export const VITALS_STEP_COMPLETED = 'initialAssessmentWizard/VITALS_STEP_COMPLETED';
export const BACKGROUND_STEP_COMPLETED = 'initialAssessmentWizard/BACKGROUND_STEP_COMPLETED';
export const NOTE_STEP_COMPLETED = 'initialAssessmentWizard/NOTE_STEP_COMPLETED';

export const ADD_ASSESSMENT_DATA_REQUEST = 'initialAssessmentWizard/ADD_ASSESSMENT_DATA_REQUEST';
export const ADD_ASSESSMENT_DATA_SUCCESS = 'initialAssessmentWizard/ADD_ASSESSMENT_DATA_SUCCESS';
export const ADD_ASSESSMENT_DATA_FAILURE = 'initialAssessmentWizard/ADD_ASSESSMENT_DATA_FAILURE';

export const PUT_ASSESSMENT_DATA_REQUEST = 'initialAssessmentWizard/PUT_ASSESSMENT_DATA_REQUEST';
export const PUT_ASSESSMENT_DATA_SUCCESS = 'initialAssessmentWizard/PUT_ASSESSMENT_DATA_SUCCESS';
export const PUT_ASSESSMENT_DATA_FAILURE = 'initialAssessmentWizard/PUT_ASSESSMENT_DATA_FAILURE';

export const FETCH_ASSESSMENT_DATA_REQUEST = 'initialAssessmentWizard/FETCH_ASSESSMENT_DATA_REQUEST';
export const FETCH_ASSESSMENT_DATA_SUCCESS = 'initialAssessmentWizard/FETCH_ASSESSMENT_DATA_SUCCESS';
export const FETCH_ASSESSMENT_DATA_FAILURE = 'initialAssessmentWizard/FETCH_ASSESSMENT_DATA_FAILURE';

export const GET_BUTTONS_REQUEST = 'initialAssessmentWizard/GET_BUTTONS_REQUEST';
export const GET_BUTTONS_SUCCESS = 'initialAssessmentWizard/GET_BUTTONS_SUCCESS';
export const GET_BUTTONS_FAILURE = 'initialAssessmentWizard/GET_BUTTONS_FAILURE';

export const RESET = 'initialAssessmentWizard/RESET';
