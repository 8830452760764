import { Accept } from 'react-dropzone';

interface FileUploaderData {
  accept: Accept;
  fileInfo: { formats: string; maxSize?: number };
}

export const IMAGES_DATA: FileUploaderData = {
  accept: { 'image/jpeg': ['.jpg', '.jpeg'], 'image/png': ['.png'] },
  fileInfo: { formats: '.png, .jpg, .jpeg' },
};

export const DOCUMENT_IMAGES_DATA: FileUploaderData = {
  accept: { 'image/*': ['.png', '.jpg', '.tif'] },
  fileInfo: { formats: '.png .jpg .tif' },
};

export const INSURANCE_DATA: FileUploaderData = {
  accept: { 'image/*': ['.png', '.jpg', '.tif'], 'application/pdf': ['.pdf'] },
  fileInfo: { formats: '.pdf .png .jpg .tif' },
};

export const SPREADSHEETS_DATA: FileUploaderData = {
  accept: {
    'application/vnd.ms-excel': ['.xlsx', '.xls'],
  },
  fileInfo: { formats: '.xlsx, .xls' },
};

export const MEDICAL_ATTACHMENTS_DATA: FileUploaderData = {
  accept: {
    'image/*': ['.bmp', '.jpg', '.jpeg', '.png', '.tif'],
    'text/*': ['.doc', '.docx', '.odt', '.rtf', '.txt'],
    'application/pdf': ['.pdf'],
    'application/vnd.ms-excel': ['.xlsx', '.xls'],
  },
  fileInfo: {
    formats: '.doc, .docx, .odt, .rtf, .txt, .pdf, .xls, .xlsx, .bmp, .jpg, .jpeg, .png, .tif',
  },
};

export const MASS_ADDING_FILES_DATA: FileUploaderData = {
  accept: {
    'image/*': ['.bmp', '.jpg', '.jpeg', '.png', '.tif'],
    'text/*': ['.doc', '.docx', '.odt', '.rtf', '.txt'],
    'application/pdf': ['.pdf'],
    'application/vnd.ms-excel': ['.xlsx', '.xls'],
    'application/zip': ['.zip'],
  },
  fileInfo: {
    formats: '.doc, .docx, .odt, .rtf, .txt, .pdf, .xls, .xlsx, .bmp, .jpg, .jpeg, .png, .tif, .zip',
    maxSize: 3000,
    //[3000]: Measured in MB
  },
};
