import { getTranslation, useAppIntl, useAppSelector } from 'app/helpers';
import { Option } from 'app/types';

interface Props {
  value: string;
  type: 'patient' | 'invoice';
}

export const PaymentMethodFormatter = (props: Props) => {
  const { locale } = useAppIntl();
  const source = props.type === 'patient' ? 'patient-payment-methods' : 'invoice-payment-methods';
  const methods: Option[] = useAppSelector((state) => state.dictionaries.data[source] || []);
  const method = methods.find((m) => m.value === props.value);

  return method ? getTranslation(method, locale) : '-';
};
