import { GovernmentIssuedIdType } from 'app/types';

export const governmentIssuedId =
  (type: GovernmentIssuedIdType | undefined) =>
  (value: number | string): string | undefined => {
    if (value) {
      const stringValue = String(value);

      if (type === GovernmentIssuedIdType.SaudiId) {
        const saudiIdRegExp = /^[12]\d{9}$/; // 10-digit number starting with 1 or 2

        if (!saudiIdRegExp.test(stringValue)) {
          return 'ERRORS.SAUDI-GOVERNMENT-ID-NUMBER';
        }
      } else {
        const generalRegExp = /^[a-zA-Z0-9-]+$/; // only letters, numbers and special characters like '-'

        if (!generalRegExp.test(stringValue)) {
          return 'ERRORS.GENERAL-GOVERNMENT-ID-NUMBER';
        }
      }
    }

    return;
  };
