import union from 'lodash/union';

export const getRelatedDays = (day: string, dayOffset: number, frequencyByWeeks: number): string[] => {
  const relatedDays: string[] = [];
  const dayNumber = Number(day);
  const rightLimit = dayNumber + dayOffset;
  const leftLimit = rightLimit - 6;

  for (let i = dayNumber + 1; i <= rightLimit; i++) {
    if (i <= 6) {
      relatedDays.push(String(i));
    }
  }

  // Days that did not fit
  if (frequencyByWeeks <= 1) {
    for (let i = 0; i < leftLimit; i++) {
      if (i <= 6) {
        relatedDays.push(String(i));
      }
    }
  }

  return relatedDays;
};

export const getRelatedDaysForSelectedDays = (
  selectedDays: string[],
  dayOffset: number,
  frequencyByWeeks: number,
): string[] => {
  let relatedDays: string[] = [];

  selectedDays.forEach((dayValue) => {
    const relatedDaysValues = getRelatedDays(dayValue, dayOffset, frequencyByWeeks);
    relatedDays = union(relatedDays, relatedDaysValues);
  });

  return relatedDays;
};
