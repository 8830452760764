import { AppointmentStatus } from './Appointment';
import { Branch } from './Branch';
import { Doctor } from './Doctor';
import { Option } from './Option';
import { RemoteFile } from './RemoteFile';
import { WorkingTimeAutomaticPromotionStatus } from './WorkingTime';

export enum CalendarTabs {
  Day = 'day',
  Week = 'week',
}

export enum CalendarType {
  Normal = 'normal',
  Waiting = 'waiting',
}

export type CalendarSlot = {
  /** @example 0000-00-00T00:00:00 */
  start: string;
  /** @example 0000-00-00T00:00:00 */
  end: string;
  overlappingAppointmentsIds: string[];
};

export type CalendarWorkingTime = {
  automaticPromotion: Option<WorkingTimeAutomaticPromotionStatus>;
  defaultProductDuration: number;
  slots: CalendarSlot[];
  waitingAppointmentsCount: number;
  waitingAppointmentsLimit: number;
  /** @example 0000-00-00T00:00:00 */
  workingTimeEnd: string;
  workingTimeId: string;
  /** @example 0000-00-00T00:00:00 */
  workingTimeStart: string;
};

export type CalendarDay = {
  date: string;
  waitingAppointmentsCount: number;
  waitingAppointmentsLimit: number;
  workingTimesWithSlots: CalendarWorkingTime[];
};

export type CalendarWeek = {
  branchId: string;
  doctorId: string;
  productId: string;
  days: CalendarDay[];
};

export type CalendarMonth = {
  branchId: string;
  doctorId: string;
  productId: string;
  days: CalendarDay[];
};

export type CalendarItem = {
  branch: Pick<Branch, 'id' | 'nameAr' | 'nameEn'>;
  days: CalendarDay[];
  doctor: Doctor;
  id: string;
  product: Option;
  speciality: Option;
  subSpeciality: Option;
};

export type DayCalendar = {
  appointments: DayCalendarAppointment[];
  branch: Option;
  busyTimes: DayCalendarBusyTime[];
  /** @example 0000-00-00T00:00:00 */
  date: string;
  doctor: DayCalendarDoctor;
  waitingAppointments: DayCalendarWaitingAppointment[];
  workingTimes: DayCalendarWorkingTime[];
};

export type DayCalendarAppointment = {
  canBeModified: boolean;
  checkedIn: boolean;
  /** @example 0000-00-00T00:00:00 */
  end: string | null;
  id: string;
  invoiceId: string | null;
  isConflicting: boolean;
  isVerificationRequired: boolean;
  patient: PatientOption;
  product: Option;
  /** @example 0000-00-00T00:00:00 */
  start: string;
  status: Option<AppointmentStatus>;
};

export type DayCalendarBusyTime = {
  /** @example 0000-00-00T00:00:00 */
  end: string;
  id: string;
  /** @example 0000-00-00T00:00:00 */
  start: string;
};

export type DayCalendarDoctor = {
  clinicyId: string;
  id: string;
  image: RemoteFile | null;
  name: Option;
  speciality: Option;
};

export interface PatientOption extends Option {
  details: {
    clinicId: string;
  };
}

export type DayCalendarWaitingAppointment = {
  /** @example 0000-00-00T00:00:00 */
  end: string;
  id: string;
  isConflicting: boolean;
  isVerificationRequired: boolean;
  patient: PatientOption;
  product: Option;
  /** @example 0000-00-00T00:00:00 */
  start: string;
  workingTimeId: string;
};

export type DayCalendarWorkingTime = {
  /** @example 0000-00-00T00:00:00 */
  end: string;
  id: string;
  /** @example 0000-00-00T00:00:00 */
  start: string;
};
