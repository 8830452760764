import { BillingPractitionerPackagesReducer } from 'app/redux/practitionerBillingPackages/types';
import { calculateFromTotal } from 'app/redux/billingPackages/helpers/calculatePrice';
import { updatedItems } from 'app/redux/practitionerBillingPackages/helpers/updatedItems';

interface Payload {
  id: string;
  total: number;
}

export const changeItem = (state: BillingPractitionerPackagesReducer, payload: Payload) => {
  return {
    ...state,
    totalPrice: state.data ? calculateFromTotal(updatedItems(state.data.packageItems, payload.id, payload.total)) : 0,
    ...(state.data && {
      data: {
        ...state.data,
        packageItems: updatedItems(state.data.packageItems, payload.id, payload.total),
      },
    }),
  };
};
