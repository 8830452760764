const getFilename = (headers) => {
  try {
    const contentDisposition = headers.get('Content-Disposition');
    return contentDisposition.split('filename=')[1];
  } catch (e) {
    //if API doesn't provide filename in contentDisposition header
    console.warn('Filename is set to default because of problems with extraction filename from headers');

    return 'clinicy-file';
  }
};

export const saveFile = async (response, withOpening = false) => {
  const filename = getFilename(response.headers);
  const blob = await response.blob();

  //execute download for user
  const file = new Blob([blob], { type: response.headers.get('Content-Type') });
  const url = window.URL.createObjectURL(file);
  if (window.navigator.msSaveOrOpenBlob !== undefined) {
    window.navigator.msSaveOrOpenBlob(file, filename);
  } else {
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', filename);
    link.style = 'display:none';
    document.body.appendChild(link);
    link.click();
    withOpening && window.open(`${link}`);
    link.parentNode.removeChild(link);
  }
};
