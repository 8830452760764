import { PayloadAction } from '@reduxjs/toolkit';
import { Appointment } from 'app/types';
import { refreshManageWaiting } from 'app/sagas/apppointment/generators/refreshManageWaiting';
import { refreshCalendarDayView, refreshCalendarWeekView } from 'app/sagas/calendar/generators';
import { refreshAppointmentList } from 'app/sagas/apppointment/generators/refreshAppointmentList';
import { refreshManageAppointments } from 'app/sagas/apppointment/generators/refreshManageAppointments';
import {
  CANCEL_APPOINTMENT_SUCCESS,
  CANCEL_WAITING_APPOINTMENT_SUCCESS,
} from 'app/redux/appointment/appointment.types';

type Meta = {
  appointment: Appointment;
};

export function* afterCancelAppointment({ type }: PayloadAction<undefined, string, Meta>) {
  // Appointment
  if (type === CANCEL_APPOINTMENT_SUCCESS) {
    yield refreshAppointmentList();
    yield refreshManageAppointments();
  }

  // Waiting Appointment
  if (type === CANCEL_WAITING_APPOINTMENT_SUCCESS) {
    yield refreshAppointmentList();
    yield refreshManageWaiting();
  }

  // Refresh Calendar Day and Week View
  yield refreshCalendarDayView();
  yield refreshCalendarWeekView();
}
