import moment from 'moment';
import { ParsedQs } from 'qs';
import omitBy from 'lodash/omitBy';
import isDate from 'lodash/isDate';
import isEmpty from 'lodash/isEmpty';
import { FORMATS_API_DATE_AND_TIME } from 'app/shared';
import type { Field } from 'app/helpers/useInitialValuesFromUrlParams/useInitialValuesFromUrlParams';
import {
  generateOptionFromUrlParams,
  generateOptionsFromUrlParams,
  generateTimeRangeFromUrlParams,
} from 'app/helpers/generateFormValueFromUrlParams/generateFormValueFromUrlParams';

export const getFormValues = (fields: Field[], urlParams: ParsedQs): {} =>
  omitBy(
    Object.assign(
      {},
      ...fields.map((field) => {
        const name = field.name;
        const value = urlParams[name];

        if (field.type === 'option') {
          return { [name]: generateOptionFromUrlParams(name, urlParams) };
        }

        if (field.type === 'options') {
          return { [name]: generateOptionsFromUrlParams(name, urlParams) };
        }

        if (field.type === 'date' && value && typeof value === 'string') {
          return { [name]: moment(value, field.options?.dateFormat || FORMATS_API_DATE_AND_TIME).toDate() };
        }

        if (field.type === 'timeRange') {
          return { [name]: generateTimeRangeFromUrlParams(name, urlParams) };
        }

        // simple
        return {
          [name]: value,
        };
      }),
    ),
    (v) => isEmpty(v) && !isDate(v),
  );
