export enum ErrorStatus {
  NotFound = '404',
  Offline = 'Offline',
  ServerError = '500',
  UnderConstruction = 'UnderConstruction',
}

export type ErrorType =
  | string
  | {
      message: string;
      value: number | string;
    };

export type ErrorWithMessage = { data?: { message: string } };
