import deepDiff from 'deep-diff';
import { useCallback, useMemo } from 'react';
import { useAppDispatch, useAppSelector } from 'app/helpers';
import { FORMS_INVOICING_PROCESS_PAYMENT_METHOD_CFG, FORMS_INVOICING_PROCESS_PAYMENT_MODE } from 'app/shared';
import { updatePayments } from 'app/redux/invoicingProcess/invoicingProcess.actions';
import { PaymentModeFormData } from 'app/features/invoicingProcess/process/paymentMode/form/types';
import { paymentsAdapter } from 'app/features/invoicingProcess/process/actions/helpers/paymentsAdapter';
import { checkPaymentMethodStep } from 'app/features/invoicingProcess/process/actions/helpers/checkSteps';
import { PaymentConfigurationFormData } from 'app/features/invoicingProcess/process/paymentMethod/paymentConfigurationForm/types';

export const useOnUpdatePayments = () => {
  const dispatch = useAppDispatch();
  const mode = useAppSelector((state) => state.invoicingProcess.mode);
  const step = useAppSelector((state) => state.invoicingProcess.step);
  const items = useAppSelector((state) => state.invoicingProcess.items);
  const invoiceId = useAppSelector((state) => state.invoicingProcess.data?.invoiceId);
  const invoiceStatus = useAppSelector((state) => state.invoicingProcess.data?.invoiceStatus?.value);
  const invoiceType = useAppSelector((state) => state.invoicingProcess.data?.invoiceType?.value);
  const paymentMethodItems = useAppSelector((state) => state.invoicingProcess.paymentMethodItems);
  const updatePaymentsLoading = useAppSelector((state) => state.invoicingProcess.updatePaymentsLoading);
  const prevPaymentMethodItems = useAppSelector((state) => state.invoicingProcess.data?.steps?.paymentMethod?.items);
  const creditNotePayments = useAppSelector(
    (state) => state.invoicingProcess.data?.steps?.paymentMethod?.creditNotePayments || [],
  );
  const isPrimaryCareSpeciality = useAppSelector(
    (state) => !!state.invoicingProcess.data?.doctor?.details?.isPrimaryCareSpeciality,
  );
  const paymentModeValues = useAppSelector((state) => state.form[FORMS_INVOICING_PROCESS_PAYMENT_MODE]?.values) as
    | PaymentModeFormData
    | undefined;
  const paymentConfigurationValues = useAppSelector(
    (state) => state.form[FORMS_INVOICING_PROCESS_PAYMENT_METHOD_CFG]?.values,
  ) as PaymentConfigurationFormData | undefined;

  const updatePaymentsDisabled = useMemo<boolean>(() => {
    return deepDiff(prevPaymentMethodItems, paymentMethodItems) === undefined;
  }, [prevPaymentMethodItems, paymentMethodItems]);

  const onUpdatePayments = useCallback(async () => {
    if (step && mode && invoiceId && paymentModeValues && paymentConfigurationValues && paymentMethodItems) {
      const isStepValid = checkPaymentMethodStep({
        creditNotePayments,
        dispatch,
        invoiceStatus,
        invoiceType,
        isPrimaryCareSpeciality,
        items,
        mode,
        paymentConfigurationValues,
        paymentMethodItems,
        paymentModeValues,
        step,
      });

      if (isStepValid) {
        dispatch(updatePayments(invoiceId, paymentsAdapter(paymentMethodItems)));
      }
    }
  }, [
    creditNotePayments,
    dispatch,
    invoiceId,
    invoiceStatus,
    invoiceType,
    isPrimaryCareSpeciality,
    items,
    mode,
    paymentConfigurationValues,
    paymentMethodItems,
    paymentModeValues,
    step,
  ]);

  return {
    onUpdatePayments,
    updatePaymentsDisabled,
    updatePaymentsLoading,
  };
};
