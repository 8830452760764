import { InvoicingProcess } from 'app/redux/invoicingProcess/types';
import { DeclarationFormData } from 'app/features/invoicingProcess/process/items/declarationForm/types';

export const getDeclarationInitialValues = (
  invoicingProcess: InvoicingProcess,
): Partial<DeclarationFormData> | undefined => {
  return {
    declaration: invoicingProcess?.branchDeclaration || undefined,
  };
};
