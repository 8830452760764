import { useCallback } from 'react';
import { ReferralInfo } from 'app/redux/book-appointment/types';
import { fetchBranchesForReferral, fetchBranches } from 'app/redux/book-appointment/book-appointment.actions';
import { useAppDispatch } from 'app/helpers';
import { useClinicId } from 'app/bookAppointment/helpers/useClinicId';

export const useFetchBranches = (referralInfo: ReferralInfo | null) => {
  const dispatch = useAppDispatch();
  const clinicId = useClinicId();

  return useCallback(
    (patientSourceId?: string) => {
      if (!!referralInfo) {
        dispatch(fetchBranchesForReferral(clinicId, referralInfo.speciality.value, patientSourceId));
      } else {
        dispatch(fetchBranches(clinicId, patientSourceId));
      }
    },
    [dispatch, clinicId, referralInfo],
  );
};
