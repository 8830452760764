import { Option } from 'app/types/Option';
import { RemoteFile } from 'app/types/RemoteFile';
import { GovernmentIssuedIdType, PatientGender, PatientStatus, PatientType } from 'app/types/Patient';

export enum ReferralsTabs {
  Current = 'current',
  Past = 'past',
}

export enum ReferralStatus {
  Active = '1',
  Discarded = '2',
  BookedAppointment = '3',
}

export type ReferralSourceAppointment = {
  id: string;
  startDate: string;
  endDate: string;
  number: string;
};

export type ReferralDestinationAppointment = {
  id: string;
  isWaiting: boolean;
  startDate: string;
  endDate: string;
  number: string;
};

export type Referral = {
  appointmentReferredFrom: ReferralSourceAppointment;
  appointmentReferredTo: ReferralDestinationAppointment | null;
  clinicBranchId: string;
  clinicId: string;
  comment: string | null;
  createdBy: Option;
  dateOfAction: string | null;
  dateOfReferring: string;
  doctorReferredTo: Option | null;
  gender?: Option<PatientGender>;
  governmentIssuedId?: string;
  governmentIssuedIdType?: Option<GovernmentIssuedIdType>;
  guardianPatient: Option;
  guardianPatientId?: string;
  hasMergePatientsBadge: boolean;
  id: string;
  patient: Option;
  patientClinicyMrn: string;
  patientDateOfBirth: string;
  patientMobileNumber: string;
  patientStatus: Option<PatientStatus>;
  patientType: Option<PatientType>;
  specialityReferredFrom: Option;
  specialityReferredTo: Option;
  status: Option<ReferralStatus>;
};

export type ReferralAction = {
  actionTaker: Option;
  actionType: Option;
  comment: string | null;
  id: string;
  timeOfCreation: string;
};

export interface ReferralInsuranceApprovalOption extends Option {
  details?: {
    attachments: RemoteFile[];
    insuranceCompany: Option;
    internalNotes: string;
    remarks: string;
    submissionClinicyId: string;
  };
}
