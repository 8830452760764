import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQueryWithReAuth } from 'app/helpers';
import { QueryResponseWithPagination, QueryParams, Order } from 'app/types';

export const ordersApi = createApi({
  reducerPath: 'ordersApi',
  baseQuery: baseQueryWithReAuth,
  tagTypes: ['Orders', 'Order'],
  endpoints: (builder) => ({
    getOrders: builder.query<QueryResponseWithPagination<Order[]>, QueryParams | undefined>({
      query: (params) => ({
        url: 'orders',
        params,
      }),
      providesTags: [{ type: 'Orders' }],
    }),
    getClinicOrders: builder.query<
      QueryResponseWithPagination<Order[]>,
      { clinicId: string; params: QueryParams | undefined }
    >({
      query: ({ clinicId, params }) => ({
        url: 'orders',
        params: {
          ...params,
          clinicIds: [clinicId],
        },
      }),
      providesTags: [{ type: 'Orders' }],
    }),
  }),
});

export const { useGetOrdersQuery, useGetClinicOrdersQuery } = ordersApi;
