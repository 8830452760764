import type { ParsedQs } from 'qs';
import { PAGINATION_DEFAULT_PARAMS } from 'app/shared';

// Default query params
export interface QueryParams extends ParsedQs {
  keyword?: string;
  locale?: string;
  order?: string;
  orderAsc?: string;
  pageNumber?: string;
  pageSize?: string;
}

export interface QueryResponse<T> {
  data: T;
}

export interface QueryResponseWithPagination<T> {
  data: T;
  pagination: QueryResponsePagination;
}

export interface QueryResponsePagination {
  pageCount: number;
  pageNumber: number;
  pageSize: number;
  totalItemCount: number;
  totalItems: number;
}

export const DEFAULT_EMPTY_PAGINATION: QueryResponsePagination = {
  ...PAGINATION_DEFAULT_PARAMS,
  pageCount: 1,
  totalItemCount: 0,
  totalItems: 0,
};
