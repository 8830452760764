import { Option } from 'app/types/Option';
import { ClassBillingItemsGroup } from 'app/types/InsurancePolicies';
import { MedicationDrugType } from 'app/types/Medication';

export enum InsuranceBillingItemsContractStatus {
  Active = '0',
  Deactivate = '1',
}

export interface InsuranceBillingItemsContract {
  id: string;
  value: string;
  billingItemType: Option<ClassBillingItemsGroup>;
  contractNameAr: string;
  insuranceCompany: Option;
  contractStartDate: string;
  contractEndDate: string;
  billingItemTypeStatus: Option<InsuranceBillingItemsContractStatus>;
  itemsNumber: number;
  isHide: boolean;
}

export interface InsuranceExtraBillingItemsContract {
  id: string;
  value: string;
  clinicyId: string;
  nameAr: string;
  nameEn: string;
  itemName: Option;
  itemType: number;
  aliasNameEn: string;
  aliasNameAr: string;
  internalCode: string;
  insuranceCompany: Option;
  specialities: Option[];
  unitMeasureNumber: Option;
  unitMeasure: Option;
  insurancePrice: string;
  insuranceDiscountPercentage: number;
  vatExempted: boolean;
  relatedInventory: string;
  codeLinks: string;
  include?: boolean;
  preAuthorization: boolean;
  nphiesCode: string;
  contractEndDate: string;
  status: Option<InsuranceBillingItemsContractStatus>;
}

export interface InsuranceBillingMedicationsContract {
  administrationRoute: string;
  clinicyId?: string;
  drugGroup: string;
  drugType: Option<MedicationDrugType>;
  id: string;
  include?: boolean;
  insuranceDiscountPercentage: number;
  insurancePrice: number | null;
  internalCode?: string;
  measureValue: string;
  packageSize: string;
  packageType: string;
  pharmaceuticalForm: string;
  preAuthorization: boolean;
  replicable: boolean;
  scientificName: string;
  sfdaCode?: string;
  status: Option<InsuranceBillingItemsContractStatus>;
  strength: string;
  strengthUnit: string;
  tradeName: string;
  unitMeasure: string;
  vatExempted: boolean;
}

export interface InsuranceBillingPackagesContract {
  id: string;
  packageCode: Option;
  packageNameAr: string;
  packageNameEn: string;
  itemType: number;
  packageItemsAssigned: Option;
  saudiVatExempted: boolean;
  preAuthorization: boolean;
  cashDiscount: number;
  cashPrice: number;
  include?: boolean;
  items: PackageItem[];
  clinicyId: string;
  insuranceDiscountPercentage: number;
  specialities: Option[];
  insurancePrice: number;
  internalCode: string;
  status: Option<InsuranceBillingItemsContractStatus>;
}

export interface PackageItem {
  quantity: string;
  name: Option;
}
