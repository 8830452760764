import { useMemo } from 'react';
import moment from 'moment';
import { useAppSelector } from 'app/helpers';
import { PatientStatus } from 'app/types';

export const useAppointmentFormStatus = (appointmentDate: string | undefined) => {
  const patientStatus = useAppSelector((state) => state.appointment.selectedPatient.data?.status);

  const isAppointmentPassed = useMemo(() => moment(appointmentDate).endOf('day') <= moment(), [appointmentDate]);
  const isPatientBlocked = useMemo(() => patientStatus?.value === PatientStatus.Blocked, [patientStatus]);
  const isFormDisabled = isAppointmentPassed || isPatientBlocked;

  return {
    isFormDisabled,
  };
};
