import type { AnyAction, Middleware } from 'redux';
import { RSAA, RSAAAction, RSAACall, RSAAResultAction, isRSAA } from 'redux-api-middleware';
import { AuthState } from 'app/redux/auth/auth.reducer';
import { logout, refreshToken as refresh } from 'app/redux/auth/auth.actions';
import { getRefreshToken, getToken, getTokenExpirationDate } from 'app/redux/auth/helpers';

interface RefreshResponse {
  error?: boolean;
  meta?: { status: number | undefined } | undefined;
  payload: any;
}

let refreshPromise: Promise<RefreshResponse> | null = null;

export const authMiddleware: Middleware<{}, { auth: AuthState }, any> = (store) => (next: any) => (action) => {
  if (isRSAA(action)) {
    const apiCall = action[RSAA] as RSAACall & { skipAuth?: boolean };

    if (apiCall.skipAuth) {
      // skip authentication process
      delete apiCall.skipAuth;
      return next(action);
    }

    // create next action shape
    const nextRSAA = (action: RSAAAction | AnyAction) =>
      next({
        [RSAA]: {
          ...action[RSAA],
          headers: {
            ...action[RSAA].headers,
            // get fresh token
            Authorization: `Bearer ${getToken()}`,
          },
        },
      });

    const token = getToken();
    const refreshToken = getRefreshToken();
    const tokenExpiryDate = getTokenExpirationDate();

    if (token && refreshToken && tokenExpiryDate) {
      const isTokenExpired = new Date(tokenExpiryDate) <= new Date();

      if (isTokenExpired) {
        // token has expired
        if (!refreshPromise) {
          refreshPromise = store
            .dispatch(refresh({ jwtToken: token, refreshToken }))
            .then((resultAction: RSAAResultAction) => {
              refreshPromise = null;
              return resultAction;
            });
        }

        if (refreshPromise) {
          return refreshPromise.then((resultAction) => {
            if (resultAction && resultAction.error) {
              // token cannot be refreshed
              return store.dispatch(logout());
            }

            return nextRSAA(action);
          });
        }
      }

      // token has not expired
      return nextRSAA(action);
    }
  }

  // we do not have to modify this action
  return next(action);
};
