import { useEffect } from 'react';
import { useAppDispatch, useHubConnection } from 'app/helpers';
import { isDevelopmentEnv } from 'app/constants';
import {
  WS_REFRESH_BILLING_ITEMS_STATUSES,
  WS_REFRESH_ORDERS_STATUSES,
} from 'app/redux/doctorAssessment/doctorAssessment.types';

export enum BillingItemPaymentType {
  Regular = '0',
  Insurance = '1',
}

export type RefreshBillingItemsStatusesEvent = {
  AppointmentId: string;
  ActionType: BillingItemPaymentType;
};

export type RefreshOrdersStatusesEvent = {
  AppointmentId: string | null;
  EventName: string;
  ClinicId: string;
  BranchId: string;
};

export const useLiveRefresh = () => {
  const dispatch = useAppDispatch();
  const connection = useHubConnection('live-refresh-hub');

  useEffect(() => {
    if (connection) {
      connection
        .start()
        .then(() => {
          connection.on('RefreshDoctorAssessmentBillingSectionView', (event) => {
            const refreshEvent: RefreshBillingItemsStatusesEvent = JSON.parse(event);
            dispatch({ type: WS_REFRESH_BILLING_ITEMS_STATUSES, payload: refreshEvent });
          });
          connection.on('RefreshDoctorAssessmentOrderView', (event) => {
            const refreshEvent: RefreshOrdersStatusesEvent = JSON.parse(event);
            dispatch({ type: WS_REFRESH_ORDERS_STATUSES, payload: refreshEvent });
          });
        })
        .catch((error) => {
          if (isDevelopmentEnv) {
            console.error(error);
          }
        });
    }

    return () => {
      if (connection) {
        connection.stop().catch((error) => {
          if (isDevelopmentEnv) {
            console.error(error);
          }
        });
      }
    };
  }, [connection, dispatch]);
};
