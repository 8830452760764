import { useEffect } from 'react';
import classnames from 'classnames';
import SimpleBar from 'simplebar-react';
import { useHistory } from 'react-router';
import { Button, Fade } from 'reactstrap';
import { useAppIntl, useMenu, isSafari } from 'app/helpers';
import { Navigation } from './navigation/navigation';
import './menu.scss';

// Logo
import logoSygnet from 'assets/images/logo/clinicy-logo-sygnet-gradient.png';
import logoLogotype from 'assets/images/logo/clinicy-logo-logotype.png';

export const Menu = () => {
  const { isRtl } = useAppIntl();
  const dir = isRtl ? 'rtl' : 'ltr';
  const { collapseMenu, expandMenu, isMenuExpanded } = useMenu();
  const { push } = useHistory();

  const isSafariBrowser = isSafari();

  useEffect(() => {
    if (isMenuExpanded) {
      document.body.classList.add('withExpandedMenu');
    } else {
      document.body.classList.remove('withExpandedMenu');
    }
  }, [isMenuExpanded]);

  return (
    <>
      <div
        className={classnames('menu', { 'menu--expanded': isMenuExpanded })}
        onMouseEnter={expandMenu}
        onMouseLeave={collapseMenu}
      >
        {isMenuExpanded && (
          <Fade className="menu__close" tag="div">
            <Button close onClick={collapseMenu} />
          </Fade>
        )}
        <div className="menu__header">
          <div className="menu__logo" onClick={() => push('/')}>
            <img className="menu__logotype menu__hiddenElement" alt="logo logotype" src={logoLogotype} />
            <img className="menu__sygnet" alt="logo sygnet" src={logoSygnet} />
          </div>
        </div>
        <SimpleBar className="menu__simpleBar" data-simplebar-direction={dir} scrollbarMaxSize={100}>
          <Navigation collapseMenu={collapseMenu} />
          {/*IMPORTANT - THIS IS FIX FOR SAFARI ON IOS WHEN TOOLBAR OVERLAPS NAVIGATION AND PREVENTS SCROLLING*/}
          {isSafariBrowser && <div style={{ height: '50px' }} />}
        </SimpleBar>
      </div>
      {isMenuExpanded && <div className="menu__cover" onClick={collapseMenu} />}
    </>
  );
};
