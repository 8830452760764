import {
  REQUEST_APPROVE_FAILURE,
  REQUEST_APPROVE_REQUEST,
  REQUEST_APPROVE_SUCCESS,
  REQUEST_CANCEL_FAILURE,
  REQUEST_CANCEL_REQUEST,
  REQUEST_CANCEL_SUCCESS,
  REQUEST_REJECT_FAILURE,
  REQUEST_REJECT_REQUEST,
  REQUEST_REJECT_SUCCESS,
  FETCH_REQUEST_FAILURE,
  FETCH_REQUEST_REQUEST,
  FETCH_REQUEST_SUCCESS,
  FETCH_REQUESTS_HISTORY_LIST_FAILURE,
  FETCH_REQUESTS_HISTORY_LIST_REQUEST,
  FETCH_REQUESTS_HISTORY_LIST_SUCCESS,
  FETCH_REQUESTS_LIST_FAILURE,
  FETCH_REQUESTS_LIST_REQUEST,
  FETCH_REQUESTS_LIST_SUCCESS,
  REQUEST_EDIT_SUCCESS,
  SET_MAIN_PARAMS,
  SET_HISTORY_PARAMS,
} from './requests.types';
import { PAGINATION_DEFAULT_PARAMS } from 'app/shared/constants/pagination.constants';

const initialParams = {
  ...PAGINATION_DEFAULT_PARAMS,
  order: 'date',
  orderAsc: false,
};

const initialState = {
  main: {
    error: false,
    loading: true,
    data: [],
    params: initialParams,
  },
  history: {
    error: false,
    loading: true,
    data: [],
    params: initialParams,
  },
  request: {
    data: null,
    error: false,
    loading: true,
  },
  loading: false,
  error: false,
};

export const requestsReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_REQUESTS_LIST_REQUEST:
      return {
        ...state,
        main: {
          ...state.main,
          loading: true,
          error: false,
          data: [],
        },
      };
    case FETCH_REQUESTS_LIST_SUCCESS:
      return {
        ...state,
        main: {
          ...state.main,
          loading: false,
          data: action.payload.data,
          params: {
            ...state.main.params,
            ...action.payload.pagination,
          },
        },
      };
    case FETCH_REQUESTS_LIST_FAILURE:
      return {
        ...state,
        main: {
          ...state.main,
          loading: false,
          error: true,
          data: [],
        },
      };
    case FETCH_REQUESTS_HISTORY_LIST_REQUEST:
      return {
        ...state,
        history: {
          ...state.history,
          loading: true,
          error: false,
          data: [],
        },
      };
    case FETCH_REQUESTS_HISTORY_LIST_SUCCESS:
      return {
        ...state,
        history: {
          ...state.history,
          loading: false,
          data: action.payload.data,
          params: {
            ...state.history.params,
            ...action.payload.pagination,
          },
        },
      };
    case FETCH_REQUESTS_HISTORY_LIST_FAILURE:
      return {
        ...state,
        history: {
          ...state.history,
          loading: false,
          error: true,
          data: [],
        },
      };
    case FETCH_REQUEST_REQUEST:
      return {
        ...state,
        request: {
          data: null,
          error: false,
          loading: true,
        },
      };
    case FETCH_REQUEST_SUCCESS:
      return {
        ...state,
        request: {
          data: action.payload.data,
          error: false,
          loading: false,
        },
      };
    case FETCH_REQUEST_FAILURE:
      return {
        ...state,
        request: {
          data: null,
          error: true,
          loading: false,
        },
      };
    case REQUEST_APPROVE_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case REQUEST_APPROVE_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case REQUEST_APPROVE_FAILURE:
      return {
        ...state,
        loading: false,
        error: true,
      };
    case REQUEST_CANCEL_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case REQUEST_CANCEL_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case REQUEST_CANCEL_FAILURE:
      return {
        ...state,
        loading: false,
        error: true,
      };
    case REQUEST_REJECT_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case REQUEST_REJECT_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case REQUEST_REJECT_FAILURE:
      return {
        ...state,
        loading: false,
        error: true,
      };
    case REQUEST_EDIT_SUCCESS:
      return {
        ...state,
        request: {
          ...state.request,
          data: action.payload.data,
        },
      };
    case SET_MAIN_PARAMS:
      return {
        ...state,
        main: {
          ...state.main,
          params: {
            ...state.main.params,
            ...action.meta,
          },
        },
      };
    case SET_HISTORY_PARAMS:
      return {
        ...state,
        history: {
          ...state.history,
          params: {
            ...state.history.params,
            ...action.meta,
          },
        },
      };
    default:
      return state;
  }
};
