import { useCallback } from 'react';
import { getTranslation, useAppIntl } from 'app/helpers';
import { Option } from 'app/types';

export const usePolicyNumberOptionFormatter = () => {
  const { locale } = useAppIntl();

  return useCallback(
    (option: Option) => {
      return (
        <div className="d-flex align-items-center justify-content-between">
          <span>{getTranslation(option, locale)}</span>
          <>
            <span>&nbsp;</span>
            <small className="text-nowrap font-weight-light">{option.label}</small>
          </>
        </div>
      );
    },
    [locale],
  );
};
