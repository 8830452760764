import { put, select } from 'redux-saga/effects';
import { destroy } from 'redux-form';
import { push } from 'connected-react-router';
import { FORMS_CALENDAR_DAY_FILTER, FORMS_CALENDAR_WEEK_FILTER } from 'app/shared';
import { RootState } from 'app/types';
import { fetchTableViewItems } from 'app/redux/calendar/calendar.actions';
import { reset } from 'app/redux/remoteData/remoteData.actions';
import { generateCalendarSearchParams, getFiltersFromAppointment } from 'app/sagas/apppointment/helpers';

export function* afterSetRebook() {
  const {
    appointment: { rebook },
  }: RootState = yield select();

  if (rebook.data) {
    // Destroy the filters form to reinitialize the form
    yield put(destroy(FORMS_CALENDAR_DAY_FILTER));
    yield put(destroy(FORMS_CALENDAR_WEEK_FILTER));
    yield put(reset('dayCalendars'));
    yield put(reset('weekCalendar'));

    // Prefetch the calendar data
    yield put<any>(fetchTableViewItems(generateCalendarSearchParams(getFiltersFromAppointment(rebook.data, true))));

    // Redirect the user to the calendar
    yield put(push('/calendar/day'));
  }
}
