import { v4 } from 'uuid';
import { UncontrolledTooltip } from 'reactstrap';
import React, { ReactNode, useCallback, useMemo } from 'react';
import { getTranslation, useAppDispatch, useAppIntl } from 'app/helpers';
import { MergedPatient, PatientBadges as PB } from 'app/types';
import {
  IconClockX,
  IconNote,
  IconNotes,
  IconReportMedical,
  IconStethoscope,
  IconSwitchHorizontal,
  IconUserPlus,
  IconUsers,
  IconVirus,
  IconWallet,
} from '@tabler/icons-react';
import { setPatientBadgeMergeModalId } from 'app/redux/patient/patientSlice';
import { toggleModal } from 'app/redux/modals/modals.actions';

interface Props {
  isNoTimeAppointment?: boolean;
  patientBadges: PB;
  id: string;
  hasMergePatientsBadge: boolean;
  isOnPatientList?: boolean;
  mergedPatients?: MergedPatient[];
}

interface Item {
  icon: ReactNode;
  label: string | undefined;
  onClick?: () => void;
}

const iconProps = { size: 18, strokeWidth: 1.8 };

export const PatientBadges = ({
  isNoTimeAppointment,
  patientBadges,
  id,
  hasMergePatientsBadge,
  isOnPatientList,
}: Props) => {
  const coreId = useMemo<string>(() => `pb-${v4()}`, []);
  const { formatMessage, locale } = useAppIntl();
  const dispatch = useAppDispatch();

  const onPatientMergeClick = useCallback(() => {
    dispatch(setPatientBadgeMergeModalId(id));
    dispatch(toggleModal('patientMergeInfo', true));
  }, [dispatch, id]);

  const items = useMemo<Item[]>(
    () =>
      [
        {
          icon: patientBadges.patientCategory?.iconFile && (
            <img
              alt={patientBadges.patientCategory.iconFile.name}
              src={patientBadges.patientCategory.iconFile.url}
              style={{ minWidth: iconProps.size, maxWidth: iconProps.size, objectFit: 'contain' }}
            />
          ),
          label:
            patientBadges.patientCategory?.category && getTranslation(patientBadges.patientCategory.category, locale),
        },
        {
          icon: patientBadges.hasAnyUnsettledInvoices && <IconWallet {...iconProps} />,
          label: formatMessage({ id: 'PATIENTS-ARRIVAL.TEXT.OUTSTANDING-WALLET' }),
        },
        {
          icon: patientBadges.isAnyAllergyExists && <IconVirus {...iconProps} />,
          label: formatMessage({ id: 'PATIENTS-ARRIVAL.TEXT.PATIENT-HAS-ALLERGIES' }),
        },
        {
          icon: patientBadges.isCompletelyNewPatient && <IconUserPlus {...iconProps} />,
          label: formatMessage({ id: 'PATIENTS-ARRIVAL.TEXT.NEW-PATIENT' }),
        },
        {
          icon: patientBadges.isAnySpecialityNoteExists && <IconNotes {...iconProps} />,
          label: formatMessage({ id: 'PATIENTS-ARRIVAL.TEXT.PATIENT-HAS-SPECIALITY-NOTE' }),
        },
        {
          icon: patientBadges.generalNoteExists && <IconNote {...iconProps} />,
          label: formatMessage({ id: 'PATIENTS-ARRIVAL.TEXT.PATIENT-HAS-GENERAL-NOTE' }),
        },
        {
          icon: patientBadges.isAppointmentNoteExists && <IconReportMedical {...iconProps} />,
          label: formatMessage({ id: 'PATIENTS-ARRIVAL.TEXT.PATIENT-HAS-APPOINTMENT-NOTE' }),
        },
        {
          icon: patientBadges.isPatientVisitedManySpecialities && <IconStethoscope {...iconProps} />,
          label: formatMessage({ id: 'PATIENTS-ARRIVAL.TEXT.PATIENT-MANY-SPECIALITIES' }),
        },
        {
          icon: patientBadges.wasReferred && <IconSwitchHorizontal {...iconProps} />,
          label: formatMessage({ id: 'PATIENTS-ARRIVAL.TEXT.PATIENT-REFERRED' }),
        },
        {
          icon: isNoTimeAppointment && <IconClockX {...iconProps} />,
          label: formatMessage({ id: 'CORE.TEXT.WITHOUT-TIME' }),
        },
        {
          icon: hasMergePatientsBadge && !isOnPatientList && <IconUsers {...iconProps} />,
          label: formatMessage({ id: 'PATIENTS.TEXT.PATIENT-MERGE-TOOLTIP' }),
          onClick: onPatientMergeClick,
        },
      ].filter((item) => item.icon),
    [
      formatMessage,
      isNoTimeAppointment,
      patientBadges,
      locale,
      onPatientMergeClick,
      hasMergePatientsBadge,
      isOnPatientList,
    ],
  );

  return (
    <div className="d-flex align-items-center flex-wrap gap-1">
      {items.map((item, index) => {
        const targetId = `${coreId}-${index}`;

        return (
          <div id={targetId} key={targetId} onClick={item.onClick}>
            {item.icon}
            {item.label && (
              <UncontrolledTooltip target={targetId} delay={{ show: 0, hide: 0 }}>
                {item.label}
              </UncontrolledTooltip>
            )}
          </div>
        );
      })}
    </div>
  );
};
