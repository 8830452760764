import { Option } from 'app/types/Option';

export interface PatientInsurance {
  deactivated: boolean;
  end: string;
  id: string;
  insuranceClass: string;
  insuranceCompany: InsuranceCompany;
  insuranceId: string;
  maxPatientShare: number;
  number: string;
  patientShare: number;
  policyNumber: string;
  price: number;
  start: string;
}

export interface ActivePatientInsurance extends Option {
  details: PatientInsurance;
}

export interface InsuranceCompany {
  id: string;
  nameAr: string;
  nameEn: string;
  nphiesId: string;
  status: Option<InsuranceStatus>;
  clinicyId: string;
}

export enum InsuranceStatus {
  Enabled = '0',
  Disabled = '1',
  Deleted = '2',
}

export enum InsuranceClassPatientShareType {
  PrimaryClinic = '1',
  SpecializedClinic = '2',
  ReferralToSpecializedClinic = '3',
  NonReplaceableBrandMedications = '4',
  ReplaceableBrandMedications = '5',
}
export interface InsuranceClassPatientShare {
  maxPatientShare: number;
  sharePercentage: number;
  shareType: InsuranceClassPatientShareType;
}

export interface InsuranceClassOption extends Option {
  details: {
    otherRoomInformation: string | null;
    preAuthorizedLimit: number;
    roomType: Option;
    status: Option;
    patientShares: InsuranceClassPatientShare[];
  };
}
