export const SET_PRESCRIPTION = 'prescription/SET_PRESCRIPTION';

export const RESET_PRESCRIPTION = 'prescription/RESET_PRESCRIPTION';

export const ADD_UNRELATED_PRESCRIPTIONS_REQUEST = 'prescription/POST_UNRELATED_PRESCRIPTIONS_REQUEST';
export const ADD_UNRELATED_PRESCRIPTIONS_SUCCESS = 'prescription/POST_UNRELATED_PRESCRIPTIONS_SUCCESS';
export const ADD_UNRELATED_PRESCRIPTIONS_FAILURE = 'prescription/POST_UNRELATED_PRESCRIPTIONS_FAILURE';

export const FETCH_DATA_FOR_SINGLE_TRADE_NAME_REQUEST = 'prescription/FETCH_DATA_FOR_SINGLE_TRADE_NAME_REQUEST';
export const FETCH_DATA_FOR_SINGLE_TRADE_NAME_SUCCESS = 'prescription/FETCH_DATA_FOR_SINGLE_TRADE_NAME_SUCCESS';
export const FETCH_DATA_FOR_SINGLE_TRADE_NAME_FAILURE = 'prescription/FETCH_DATA_FOR_SINGLE_TRADE_NAME_FAILURE';

export const FETCH_PRESCRIPTION_INFO_REQUEST = 'prescription/FETCH_PRESCRIPTION_INFO_REQUEST';
export const FETCH_PRESCRIPTION_INFO_SUCCESS = 'prescription/FETCH_PRESCRIPTION_INFO_SUCCESS';
export const FETCH_PRESCRIPTION_INFO_FAILURE = 'prescription/FETCH_PRESCRIPTION_INFO_FAILURE';
