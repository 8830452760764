import { useState, useCallback } from 'react';
import { change } from 'redux-form';
import { Option } from 'app/types';
import { FORMS_PROCEDURE_LOCATION_NOTE_BILLING_ITEM } from 'app/shared';
import { NoteBillingItemsField } from 'app/assessment/medicalProfileSection/components/modals/noteBillingItemModal/form/types';
import { toggleModal } from 'app/redux/modals/modals.actions';
import { useAppDispatch } from 'app/helpers';

export const useManageGoalBillingItem = (formName: string) => {
  const dispatch = useAppDispatch();
  const [data, setData] = useState<any | null>(null);

  const manageBillingItemsModal = useCallback(
    (index: number, values: Option[] | undefined, fieldName: string) => {
      dispatch(change(FORMS_PROCEDURE_LOCATION_NOTE_BILLING_ITEM, NoteBillingItemsField.billingItems, values));
      dispatch(toggleModal('noteBillingItems', true));
      setData({ fieldName: fieldName, index: index });
    },
    [dispatch],
  );

  const onConfirmBillingItems = useCallback(
    (values?: Option[]) => {
      dispatch(
        change(formName, `${data.fieldName}.notes[${data.index}].${NoteBillingItemsField.billingItems}`, values || []),
      );
      dispatch(toggleModal('noteBillingItems', false));
      setData(null);
    },
    [formName, dispatch, data],
  );

  return {
    manageBillingItemsModal,
    onConfirmBillingItems,
  };
};
