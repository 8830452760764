import React from 'react';
import { Button, Col, Row, UncontrolledTooltip } from 'reactstrap';
import classNames from 'classnames';

import { Option } from 'app/types';
import { getTranslation, useAppIntl } from 'app/helpers';
import { Loader } from 'app/shared';

interface Props {
  data: Option[];
  selectedSubSpeciality: Option | null;
  onSubSpecialityChange: (option: Option) => void;
  loading: boolean;
}

export const SubSpecialityList = ({ onSubSpecialityChange, selectedSubSpeciality, data, loading }: Props) => {
  const { locale } = useAppIntl();

  return (
    <>
      {loading && <Loader />}

      {!loading && (
        <Row className="fadeIn">
          {data.map((option: Option, i: number) => {
            return (
              <Col key={i} xl={4} lg={6} md={12}>
                <UncontrolledTooltip target={`subSpecialityListOption-${i}`}>
                  {getTranslation(option, locale)}
                </UncontrolledTooltip>
                <Button
                  block
                  color="primary"
                  className={classNames('mt-2 btn d-flex justify-content-between subSpecialityList__option', {
                    selected: selectedSubSpeciality?.value === option.value,
                  })}
                  id={`subSpecialityListOption-${i}`}
                  outline={selectedSubSpeciality?.value !== option.value}
                  onClick={() => onSubSpecialityChange(option)}
                >
                  <div className="text-truncate">{getTranslation(option, locale)}</div>
                  <div className="subSpecialityList__option--clinicyId">{option.clinicyId}</div>
                </Button>
              </Col>
            );
          })}
        </Row>
      )}
    </>
  );
};
