import { Col, Row } from 'reactstrap';

import { Box } from 'app/components';
import { DoctorWorkingDayFormatter, TimeFormatter } from 'app/bookAppointment/bookAppointmentWizard/shared';
import { CalendarItem } from 'app/types';
import { SelectedDay } from 'app/redux/calendar/types';

interface Props {
  item?: CalendarItem;
  onSelectDate: (day: SelectedDay) => void;
}

export const DoctorWorkingDaySlots = ({ item, onSelectDate }: Props) => {
  if (!item) {
    return <></>;
  }

  return (
    <Box style={{ overflowY: 'scroll' }}>
      <Row>
        <Col>
          <DoctorWorkingDayFormatter doctor={item.doctor} item={item} onSelectDate={onSelectDate} />
        </Col>
        <Col>{TimeFormatter(null, item, -1, null)}</Col>
      </Row>
    </Box>
  );
};
