import { useRef } from 'react';
import { Mesh, MeshPhongMaterial } from 'three';
import { GLTF } from 'three/examples/jsm/loaders/GLTFLoader';
import { useGLTF, ContactShadows, useBVH } from '@react-three/drei';

const filesUrl = `${process.env.PUBLIC_URL}/files/models/primary-teeth-q15-transformed.glb`;

const material = new MeshPhongMaterial({
  color: '#d2d2d2',
  flatShading: false,
  shininess: 250,
});

type ModelResult = GLTF & {
  nodes: {
    ['51']: Mesh;
    ['52']: Mesh;
    ['53']: Mesh;
    ['54']: Mesh;
    ['55']: Mesh;
    ['61']: Mesh;
    ['62']: Mesh;
    ['63']: Mesh;
    ['64']: Mesh;
    ['65']: Mesh;
    ['71']: Mesh;
    ['72']: Mesh;
    ['73']: Mesh;
    ['74']: Mesh;
    ['75']: Mesh;
    ['81']: Mesh;
    ['82']: Mesh;
    ['83']: Mesh;
    ['84']: Mesh;
    ['85']: Mesh;
  };
  materials: {};
};

export const PrimaryTeethModel = (props: JSX.IntrinsicElements['group']) => {
  const { nodes } = useGLTF(filesUrl) as unknown as ModelResult;

  const m51Ref = useRef<Mesh>(null!);
  const m52Ref = useRef<Mesh>(null!);
  const m53Ref = useRef<Mesh>(null!);
  const m54Ref = useRef<Mesh>(null!);
  const m55Ref = useRef<Mesh>(null!);
  const m61Ref = useRef<Mesh>(null!);
  const m62Ref = useRef<Mesh>(null!);
  const m63Ref = useRef<Mesh>(null!);
  const m64Ref = useRef<Mesh>(null!);
  const m65Ref = useRef<Mesh>(null!);
  const m71Ref = useRef<Mesh>(null!);
  const m72Ref = useRef<Mesh>(null!);
  const m73Ref = useRef<Mesh>(null!);
  const m74Ref = useRef<Mesh>(null!);
  const m75Ref = useRef<Mesh>(null!);
  const m81Ref = useRef<Mesh>(null!);
  const m82Ref = useRef<Mesh>(null!);
  const m83Ref = useRef<Mesh>(null!);
  const m84Ref = useRef<Mesh>(null!);
  const m85Ref = useRef<Mesh>(null!);

  useBVH(m51Ref);
  useBVH(m52Ref);
  useBVH(m53Ref);
  useBVH(m54Ref);
  useBVH(m55Ref);
  useBVH(m61Ref);
  useBVH(m62Ref);
  useBVH(m63Ref);
  useBVH(m64Ref);
  useBVH(m65Ref);
  useBVH(m71Ref);
  useBVH(m72Ref);
  useBVH(m73Ref);
  useBVH(m74Ref);
  useBVH(m75Ref);
  useBVH(m81Ref);
  useBVH(m82Ref);
  useBVH(m83Ref);
  useBVH(m84Ref);
  useBVH(m85Ref);

  return (
    <group dispose={null}>
      <group scale={0.044} {...props}>
        <mesh geometry={nodes['51'].geometry} material={material} name="51" ref={m51Ref} />
        <mesh geometry={nodes['52'].geometry} material={material} name="52" ref={m52Ref} />
        <mesh geometry={nodes['53'].geometry} material={material} name="53" ref={m53Ref} />
        <mesh geometry={nodes['54'].geometry} material={material} name="54" ref={m54Ref} />
        <mesh geometry={nodes['55'].geometry} material={material} name="55" ref={m55Ref} />
        <mesh geometry={nodes['61'].geometry} material={material} name="61" ref={m61Ref} />
        <mesh geometry={nodes['62'].geometry} material={material} name="62" ref={m62Ref} />
        <mesh geometry={nodes['63'].geometry} material={material} name="63" ref={m63Ref} />
        <mesh geometry={nodes['64'].geometry} material={material} name="64" ref={m64Ref} />
        <mesh geometry={nodes['65'].geometry} material={material} name="65" ref={m65Ref} />
        <mesh geometry={nodes['71'].geometry} material={material} name="71" ref={m71Ref} />
        <mesh geometry={nodes['72'].geometry} material={material} name="72" ref={m72Ref} />
        <mesh geometry={nodes['73'].geometry} material={material} name="73" ref={m73Ref} />
        <mesh geometry={nodes['74'].geometry} material={material} name="74" ref={m74Ref} />
        <mesh geometry={nodes['75'].geometry} material={material} name="75" ref={m75Ref} />
        <mesh geometry={nodes['81'].geometry} material={material} name="81" ref={m81Ref} />
        <mesh geometry={nodes['82'].geometry} material={material} name="82" ref={m82Ref} />
        <mesh geometry={nodes['83'].geometry} material={material} name="83" ref={m83Ref} />
        <mesh geometry={nodes['84'].geometry} material={material} name="84" ref={m84Ref} />
        <mesh geometry={nodes['85'].geometry} material={material} name="85" ref={m85Ref} />
      </group>
      <ContactShadows castShadow opacity={0.3} scale={5} blur={5} position={[0, -1, 0]} />
    </group>
  );
};
