import React, { useCallback } from 'react';
import { Row, Button } from 'reactstrap';
import { BoxHeader } from 'app/components';
import { DoctorAppointments } from 'app/appointment/shared/patientSection/doctorAppointments/doctorAppointments';
import { PatientSelectedDetails } from 'app/shared/patientSearch/patientSelectedDetails/patientSelectedDetails';
import { PatientShortDetails, PatientSearchFilters, PatientSearchResults } from 'app/shared/patientSearch';
import { PatientType, DEFAULT_EMPTY_PAGINATION } from 'app/types';
import { resetPatient, setPatient } from 'app/redux/appointment/appointment.actions';
import { useAppSelector, useAppIntl, useAppDispatch, useCurrentUser } from 'app/helpers';
import { usePatientSearchHandlers } from 'app/shared/patientSearch/helpers/usePatientSearchHandlers';
import { PatientSearchField } from 'app/shared/patientSearch/types';
import { AppointmentAgeRestrictions } from 'app/appointment/form/types';
import { BookingRestrictionsStatus } from 'app/shared/patientSearch/patientSelectedDetails/bookingRestrictionStatus/bookingRestrictionsStatus';
import { ChangeAppointmentPatientModal } from 'app/features/patient/changeAppointmentPatientModal/changeAppointmentPatientModal';
import { toggleModal } from 'app/redux/modals/modals.actions';
import { ClinicPatientShortInfo } from 'app/services/patients/types';
import { useChangePatientHandler } from 'app/appointment/form/helpers';
import 'app/appointment/shared/patientSection/patientSection.scss';

interface Props {
  ageRestrictions?: AppointmentAgeRestrictions;
  clinicId: string;
  formName: string;
  showAppointments: boolean;
  showSearch: boolean;
  isFormDisabled: boolean;
  createdPatientMode?: boolean;
  resetMode: () => void;
  onAddNewPatient: () => void;
}

export const PatientSection = ({
  ageRestrictions,
  clinicId,
  formName,
  showAppointments,
  showSearch,
  isFormDisabled,
  createdPatientMode,
  onAddNewPatient,
  resetMode,
}: Props) => {
  const dispatch = useAppDispatch();
  const { formatMessage } = useAppIntl();
  const { currentUser } = useCurrentUser();

  const selectedPatient = useAppSelector((state) => state.appointment.selectedPatient?.data);
  const preselectedPatient = useAppSelector((state) => state.appointment.preselectedPatient);
  const mobileNumber = useAppSelector(
    (state) => state.form[formName]?.values?.[PatientSearchField.selectedPhoneNumber]?.value,
  );

  const {
    data,
    checkIsRowSelected,
    isAnySelected,
    isFetching,
    isLoading,
    params,
    selectionChanged,
    handleFilterChange,
    handleSetParams,
  } = usePatientSearchHandlers({
    formName,
    clinicId,
    resetPatient: resetPatient,
    setPatient: setPatient,
  });

  const changeCurrentPatient = useChangePatientHandler({ formName, setPatient });

  const onChangePatient = useCallback(() => {
    if (createdPatientMode) {
      resetMode();
    } else {
      dispatch(toggleModal('changeAppointmentPatient', true));
    }
  }, [dispatch, createdPatientMode, resetMode]);

  const onPatientSelected = useCallback(
    (data: ClinicPatientShortInfo) => {
      changeCurrentPatient(data);
      dispatch(toggleModal('changeAppointmentPatient', false));
    },
    [dispatch, changeCurrentPatient],
  );

  return (
    <div className="patientSection">
      <BoxHeader messageId={showSearch && !isAnySelected ? 'APPOINTMENTS.BUTTON.SEARCH-PATIENT' : 'CORE.LABEL.PATIENT'}>
        {currentUser.allowedForCreatePatientProfile && showSearch && !createdPatientMode && !isAnySelected && (
          <Button type="button" color="primary" onClick={onAddNewPatient} size="sm" disabled={isFormDisabled}>
            {formatMessage({ id: 'CORE.LABEL.ADD-NEW-PATIENT', defaultMessage: 'Add New Patient' })}
          </Button>
        )}
        {(!showSearch || createdPatientMode) && !preselectedPatient && selectedPatient && (
          <Button
            type="button"
            color="primary"
            onClick={onChangePatient}
            size="sm"
            disabled={isFormDisabled || !mobileNumber}
          >
            {createdPatientMode && formatMessage({ id: 'CORE.LABEL.CHANGE-PATIENT', defaultMessage: 'Change patient' })}
            {!createdPatientMode &&
              formatMessage({
                id: 'CORE.LABEL.CHANGE-APPOINTMENT-PATIENT',
                defaultMessage: 'Change Appointment To Related Patient',
              })}
          </Button>
        )}
      </BoxHeader>

      {showSearch && !createdPatientMode && (
        <>
          <PatientSearchFilters
            formName={formName}
            anySelected={isAnySelected}
            isDependent={selectedPatient?.patientType.value === PatientType.Dependent}
            clinicId={clinicId}
            patientId={selectedPatient?.id}
            onFiltersChanged={handleFilterChange}
          />
          <BookingRestrictionsStatus doctor={ageRestrictions} patient={selectedPatient} />
          <PatientSearchResults
            data={data?.data || []}
            isFetching={isFetching}
            isLoading={isLoading}
            params={params}
            setParams={handleSetParams}
            pagination={data?.pagination || { ...DEFAULT_EMPTY_PAGINATION, pageSize: 5 }}
            checkIsSelected={checkIsRowSelected}
            selectionChanged={selectionChanged}
          />
          {/*TODO Show doctor appointments button?*/}
          {showAppointments && <DoctorAppointments />}
        </>
      )}
      {(!showSearch || createdPatientMode) && selectedPatient && (
        <>
          <Row xs={2}>
            <PatientSelectedDetails
              clinicId={clinicId}
              patientId={selectedPatient.id}
              isDependent={selectedPatient.patientType.value === PatientType.Dependent}
              isDisabled={isFormDisabled}
              size="sm"
            />
          </Row>
          <BookingRestrictionsStatus doctor={ageRestrictions} patient={selectedPatient} />
          <PatientShortDetails data={selectedPatient} />
          <ChangeAppointmentPatientModal
            patientId={selectedPatient.id}
            mobileNumber={mobileNumber}
            onPatientSelected={onPatientSelected}
            ageRestrictions={ageRestrictions}
          />
        </>
      )}
    </div>
  );
};
