import { Modal } from 'reactstrap';
import toast from 'react-hot-toast';
import { useCallback, useMemo } from 'react';
import { useAddClinicPatientInsuranceDetailsMutation } from 'app/services/patients/patients';
import { PatientInsuranceFormData } from 'app/features/patient/patientInsuranceDetailsForm/types';
import { PatientInsuranceForm } from 'app/features/patient/patientInsuranceDetailsForm/patientInsuranceForm';
import { formValuesAdapter } from 'app/features/patient/patientInsuranceDetailsForm/helpers/formValuesAdapter';
import { useOnInsuranceCompanyUpdate } from 'app/features/patient/medicalInstitutionPatientProfile/insuranceDetails/helpers/useOnInsuranceCompanyUpdate';

interface Props {
  branchId?: string;
  clinicId?: string;
  isOpen: boolean;
  patientId: string;
  toggle: () => void;
}

export const AddMedicalInsuranceModal = ({ branchId, clinicId, isOpen, patientId, toggle }: Props) => {
  const onInsuranceCompanyUpdate = useOnInsuranceCompanyUpdate();
  const [addInsuranceDetails] = useAddClinicPatientInsuranceDetailsMutation();

  const initialValues = useMemo(() => {
    if (branchId && clinicId) {
      // Take initial values from predefined params (options are not rendered)
      return {
        clinic: { label: '', value: clinicId },
        clinicBranch: { label: '', value: branchId },
      };
    }

    return undefined;
  }, [branchId, clinicId]);

  const onSubmit = useCallback(
    async (values: PatientInsuranceFormData) => {
      try {
        await addInsuranceDetails({ patientId, body: formValuesAdapter(values) }).unwrap();
        toast.success('CORE.TEXT.ITEM-ADDED-SUCCESSFULLY');
        onInsuranceCompanyUpdate();
      } catch (e) {
        toast.error('CORE.TEXT.DEFAULT-FAILURE-MESSAGE');
      }
      toggle();
    },
    [addInsuranceDetails, onInsuranceCompanyUpdate, patientId, toggle],
  );

  return (
    <Modal backdrop="static" keyboard={false} size="lg" isOpen={isOpen} toggle={toggle}>
      <PatientInsuranceForm
        branchId={branchId}
        clinicId={clinicId}
        closeModal={toggle}
        headerText={'INSURANCES.TEXT.ADD-INSURANCE'}
        initialValues={initialValues}
        onSubmit={onSubmit}
        submitText={'CORE.BUTTON.SUBMIT'}
      />
    </Modal>
  );
};
