export const FETCH_BRANCHES_BY_CLINIC_LIST_REQUEST = 'branches/fetch/REQUEST';
export const FETCH_BRANCHES_BY_CLINIC_LIST_SUCCESS = 'branches/fetch/SUCCESS';
export const FETCH_BRANCHES_BY_CLINIC_LIST_FAILURE = 'branches/fetch/FAILURE';

export const FETCH_BRANCHES_BY_CLINIC_LIST_DICT_REQUEST = 'branches-dict/fetch/REQUEST';
export const FETCH_BRANCHES_BY_CLINIC_LIST_DICT_SUCCESS = 'branches-dict/fetch/SUCCESS';
export const FETCH_BRANCHES_BY_CLINIC_LIST_DICT_FAILURE = 'branches-dict/fetch/FAILURE';

export const FETCH_DELETED_BRANCHES_BY_CLINIC_LIST_REQUEST = 'branches-deleted/fetch/REQUEST';
export const FETCH_DELETED_BRANCHES_BY_CLINIC_LIST_SUCCESS = 'branches-deleted/fetch/SUCCESS';
export const FETCH_DELETED_BRANCHES_BY_CLINIC_LIST_FAILURE = 'branches-deleted/fetch/FAILURE';

export const FETCH_AVAILABLE_BRANCHES_REQUEST = 'calendar/FETCH_AVAILABLE_BRANCHES_REQUEST';
export const FETCH_AVAILABLE_BRANCHES_SUCCESS = 'calendar/FETCH_AVAILABLE_BRANCHES_SUCCESS';
export const FETCH_AVAILABLE_BRANCHES_FAILURE = 'calendar/FETCH_AVAILABLE_BRANCHES_FAILURE';

export const FETCH_ALL_BRANCHES_REQUEST = 'calendar/FETCH_ALL_BRANCHES_REQUEST';
export const FETCH_ALL_BRANCHES_SUCCESS = 'calendar/FETCH_ALL_BRANCHES_SUCCESS';
export const FETCH_ALL_BRANCHES_FAILURE = 'calendar/FETCH_ALL_BRANCHES_FAILURE';

export const FETCH_BRANCH_PRODUCT_OPTIONS_REQUEST = 'calendar/FETCH_BRANCH_PRODUCT_OPTIONS_REQUEST';
export const FETCH_BRANCH_PRODUCT_OPTIONS_SUCCESS = 'calendar/FETCH_BRANCH_PRODUCT_OPTIONS_SUCCESS';
export const FETCH_BRANCH_PRODUCT_OPTIONS_FAILURE = 'calendar/FETCH_BRANCH_PRODUCT_OPTIONS_FAILURE';

export const SET_PARAMS = 'branches/SET_PARAMS';
export const SET_DELETED_PARAMS = 'branches-deleted/SET_DELETED_PARAMS';
