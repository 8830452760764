export const FETCH_MEDICATION_REQUEST = 'medications/FETCH_MEDICATION_REQUEST';
export const FETCH_MEDICATION_SUCCESS = 'medications/FETCH_MEDICATION_SUCCESS';
export const FETCH_MEDICATION_FAILURE = 'medications/FETCH_MEDICATION_FAILURE';

export const ADD_MEDICATION_REQUEST = 'medications/ADD_MEDICATION_REQUEST';
export const ADD_MEDICATION_SUCCESS = 'medications/ADD_MEDICATION_SUCCESS';
export const ADD_MEDICATION_FAILURE = 'medications/ADD_MEDICATION_FAILURE';

export const EDIT_MEDICATION_REQUEST = 'medications/EDIT_MEDICATION_REQUEST';
export const EDIT_MEDICATION_SUCCESS = 'medications/EDIT_MEDICATION_SUCCESS';
export const EDIT_MEDICATION_FAILURE = 'medications/EDIT_MEDICATION_FAILURE';

export const REMOVE_MEDICATION_REQUEST = 'medications/REMOVE_MEDICATION_REQUEST';
export const REMOVE_MEDICATION_SUCCESS = 'medications/REMOVE_MEDICATION_SUCCESS';
export const REMOVE_MEDICATION_FAILURE = 'medications/REMOVE_MEDICATION_FAILURE';

export const CHECK_INTERNAL_CODE_REQUEST = 'medications/CHECK_INTERNAL_CODE_REQUEST';
export const CHECK_INTERNAL_CODE_SUCCESS = 'medications/CHECK_INTERNAL_CODE_SUCCESS';
export const CHECK_INTERNAL_CODE_FAILURE = 'medications/CHECK_INTERNAL_CODE_FAILURE';

export const TOGGLE_MEDICATION_STATUS_REQUEST = 'medications/TOGGLE_MEDICATION_STATUS_REQUEST';
export const TOGGLE_MEDICATION_STATUS_SUCCESS = 'medications/TOGGLE_MEDICATION_STATUS_SUCCESS';
export const TOGGLE_MEDICATION_STATUS_FAILURE = 'medications/TOGGLE_MEDICATION_STATUS_FAILURE';

export const EXPORT_BRANCH_BILLING_ITEMS_MEDICATIONS_REQUEST =
  'medications/EXPORT_BRANCH_BILLING_ITEMS_MEDICATIONS_REQUEST';
export const EXPORT_BRANCH_BILLING_ITEMS_MEDICATIONS_SUCCESS =
  'medications/EXPORT_BRANCH_BILLING_ITEMS_MEDICATIONS_SUCCESS';
export const EXPORT_BRANCH_BILLING_ITEMS_MEDICATIONS_FAILURE =
  'medications/EXPORT_BRANCH_BILLING_ITEMS_MEDICATIONS_FAILURE';

export const FETCH_PERFORMER_MEDICATION_REQUEST = 'medications/FETCH_PERFORMER_MEDICATION_REQUEST';
export const FETCH_PERFORMER_MEDICATION_SUCCESS = 'medications/FETCH_PERFORMER_MEDICATION_SUCCESS';
export const FETCH_PERFORMER_MEDICATION_FAILURE = 'medications/FETCH_PERFORMER_MEDICATION_FAILURE';

export const REMOVE_PERFORMER_MEDICATION_REQUEST = 'medications/REMOVE_PERFORMER_MEDICATION_REQUEST';
export const REMOVE_PERFORMER_MEDICATION_SUCCESS = 'medications/REMOVE_PERFORMER_MEDICATION_SUCCESS';
export const REMOVE_PERFORMER_MEDICATION_FAILURE = 'medications/REMOVE_PERFORMER_MEDICATION_FAILURE';

export const TOGGLE_PERFORMER_MEDICATION_STATUS_REQUEST = 'medications/TOGGLE_PERFORMER_MEDICATION_STATUS_REQUEST';
export const TOGGLE_PERFORMER_MEDICATION_STATUS_SUCCESS = 'medications/TOGGLE_PERFORMER_MEDICATION_STATUS_SUCCESS';
export const TOGGLE_PERFORMER_MEDICATION_STATUS_FAILURE = 'medications/TOGGLE_PERFORMER_MEDICATION_STATUS_FAILURE';

export const ASSIGN_MEDICATION_REQUEST = 'medications/ASSIGN_MEDICATION_REQUEST';
export const ASSIGN_MEDICATION_SUCCESS = 'medications/ASSIGN_MEDICATION_SUCCESS';
export const ASSIGN_MEDICATION_FAILURE = 'medications/ASSIGN_MEDICATION_FAILURE';

export const EXPORT_PERFORMER_BILLING_ITEMS_MEDICATIONS_REQUEST =
  'medications/EXPORT_PERFORMER_BILLING_ITEMS_MEDICATIONS_REQUEST';
export const EXPORT_PERFORMER_BILLING_ITEMS_MEDICATIONS_SUCCESS =
  'medications/EXPORT_PERFORMER_BILLING_ITEMS_MEDICATIONS_SUCCESS';
export const EXPORT_PERFORMER_BILLING_ITEMS_MEDICATIONS_FAILURE =
  'medications/EXPORT_PERFORMER_BILLING_ITEMS_MEDICATIONS_FAILURE';

export const SET_EXPANDED_PERFORMER = 'medications/SET_EXPANDED_PERFORMER';
