export const FETCH_CLINICS_SUMMARY_REQUEST = 'accountBalance/FETCH_CLINICS_SUMMARY_REQUEST';
export const FETCH_CLINICS_SUMMARY_SUCCESS = 'accountBalance/FETCH_CLINICS_SUMMARY_SUCCESS';
export const FETCH_CLINICS_SUMMARY_FAILURE = 'accountBalance/FETCH_CLINICS_SUMMARY_FAILURE';

export const FETCH_PATIENT_SUMMARY_REQUEST = 'accountBalance/FETCH_PATIENT_SUMMARY_REQUEST';
export const FETCH_PATIENT_SUMMARY_SUCCESS = 'accountBalance/FETCH_PATIENT_SUMMARY_SUCCESS';
export const FETCH_PATIENT_SUMMARY_FAILURE = 'accountBalance/FETCH_PATIENT_SUMMARY_FAILURE';

export const FETCH_PATIENT_CLINICS_REQUEST = 'accountBalance/FETCH_PATIENT_CLINICS_REQUEST';
export const FETCH_PATIENT_CLINICS_SUCCESS = 'accountBalance/FETCH_PATIENT_CLINICS_SUCCESS';
export const FETCH_PATIENT_CLINICS_FAILURE = 'accountBalance/FETCH_PATIENT_CLINICS_FAILURE';

export const FETCH_BRANCHES_REQUEST = 'accountBalance/FETCH_BRANCHES_REQUEST';
export const FETCH_BRANCHES_SUCCESS = 'accountBalance/FETCH_BRANCHES_SUCCESS';
export const FETCH_BRANCHES_FAILURE = 'accountBalance/FETCH_BRANCHES_FAILURE';

export const FETCH_SPECIALITIES_REQUEST = 'accountBalance/FETCH_SPECIALITIES_REQUEST';
export const FETCH_SPECIALITIES_SUCCESS = 'accountBalance/FETCH_SPECIALITIES_SUCCESS';
export const FETCH_SPECIALITIES_FAILURE = 'accountBalance/FETCH_SPECIALITIES_FAILURE';

export const FETCH_CLINICS_BALANCE_SUMMARY_REQUEST = 'accountBalance/FETCH_CLINICS_BALANCE_SUMMARY_REQUEST';
export const FETCH_CLINICS_BALANCE_SUMMARY_SUCCESS = 'accountBalance/FETCH_CLINICS_BALANCE_SUMMARY_SUCCESS';
export const FETCH_CLINICS_BALANCE_SUMMARY_FAILURE = 'accountBalance/FETCH_CLINICS_BALANCE_SUMMARY_FAILURE';

export const SET_CLINIC_BALANCE_SUMMARY_PARAMS = 'accountBalance/SET_CLINIC_BALANCE_SUMMARY_PARAMS';
export const SET_PATIENT_BALANCE_SUMMARY_PARAMS = 'accountBalance/SET_PATIENT_BALANCE_SUMMARY_PARAMS';

export const FETCH_PATIENT_BALANCE_SUMMARY_REQUEST = 'accountBalance/FETCH_PATIENT_BALANCE_SUMMARY_REQUEST';
export const FETCH_PATIENT_BALANCE_SUMMARY_SUCCESS = 'accountBalance/FETCH_PATIENT_BALANCE_SUMMARY_SUCCESS';
export const FETCH_PATIENT_BALANCE_SUMMARY_FAILURE = 'accountBalance/FETCH_PATIENT_BALANCE_SUMMARY_FAILURE';
