import { useCallback } from 'react';
import { InvoiceCreditNoteAction } from 'app/types';
import { useAppDispatch, useAppSelector } from 'app/helpers';
import {
  FORMS_INVOICING_PROCESS_DECLARATION,
  FORMS_INVOICING_PROCESS_PAYMENT_METHOD_CFG,
  FORMS_INVOICING_PROCESS_PAYMENT_MODE,
} from 'app/shared';
import { CreditNoteConfiguration } from 'app/redux/invoicingProcess/types';
import { createCreditNote } from 'app/redux/invoicingProcess/invoicingProcess.actions';
import { useInvoicingProcessState } from 'app/features/invoicingProcess/process/helpers';
import { checkSteps } from 'app/features/invoicingProcess/process/actions/helpers/checkSteps';
import { PaymentModeFormData } from 'app/features/invoicingProcess/process/paymentMode/form/types';
import { DeclarationFormData } from 'app/features/invoicingProcess/process/items/declarationForm/types';
import { creditNoteBodyAdapter } from 'app/features/invoicingProcess/process/actions/helpers/creditNoteBodyAdapter';
import { PaymentConfigurationFormData } from 'app/features/invoicingProcess/process/paymentMethod/paymentConfigurationForm/types';

export const useOnCreateCreditNote = () => {
  const dispatch = useAppDispatch();
  const { step, mode } = useInvoicingProcessState();
  const items = useAppSelector((state) => state.invoicingProcess.items);
  const appointmentId = useAppSelector((state) => state.invoicingProcess.appointmentId);
  const invoicingProcess = useAppSelector((state) => state.invoicingProcess.data);
  const paymentMethodItems = useAppSelector((state) => state.invoicingProcess.paymentMethodItems);
  const createCreditNoteLoading = useAppSelector((state) => state.invoicingProcess.createCreditNoteLoading);
  const paymentModeValues = useAppSelector((state) => state.form[FORMS_INVOICING_PROCESS_PAYMENT_MODE]?.values) as
    | PaymentModeFormData
    | undefined;
  const paymentConfigurationValues = useAppSelector(
    (state) => state.form[FORMS_INVOICING_PROCESS_PAYMENT_METHOD_CFG]?.values,
  ) as PaymentConfigurationFormData | undefined;
  const declarationValues = useAppSelector((state) => state.form[FORMS_INVOICING_PROCESS_DECLARATION]?.values) as
    | DeclarationFormData
    | undefined;
  const creditNoteConfiguration = useAppSelector((state) => state.invoicingProcess.creditNoteConfiguration);

  const onCreateCreditNote = useCallback(
    (customCreditNoteConfiguration?: CreditNoteConfiguration) => {
      const configuration = customCreditNoteConfiguration || creditNoteConfiguration;
      const isCompleteRefund = configuration?.action === InvoiceCreditNoteAction.Refund;
      const invoiceId = invoicingProcess?.invoiceId;

      if (step && mode && invoiceId && paymentModeValues && paymentConfigurationValues && configuration) {
        const invoiceStatus = invoicingProcess.invoiceStatus?.value;
        const invoiceType = invoicingProcess.invoiceType?.value;
        const isPrimaryCareSpeciality = invoicingProcess.doctor.details?.isPrimaryCareSpeciality;
        const creditNotePayments = invoicingProcess.steps?.paymentMethod?.creditNotePayments || [];

        const isValid = checkSteps({
          appointmentId,
          creditNotePayments,
          dispatch,
          invoiceStatus,
          invoiceType,
          invoicingProcess,
          isPrimaryCareSpeciality,
          items,
          mode,
          paymentConfigurationValues,
          paymentMethodItems,
          paymentModeValues,
          step,
        });

        if (isValid) {
          const body = creditNoteBodyAdapter({
            creditNoteConfiguration: configuration,
            declarationValues,
            invoiceId,
            invoiceType,
            isPrimaryCareSpeciality,
            items,
            paymentConfigurationValues,
            paymentMethodItems,
            paymentModeValues,
          });

          return dispatch(createCreditNote(body, isCompleteRefund));
        }
      }
    },
    [
      appointmentId,
      creditNoteConfiguration,
      declarationValues,
      dispatch,
      invoicingProcess,
      items,
      mode,
      paymentConfigurationValues,
      paymentMethodItems,
      paymentModeValues,
      step,
    ],
  );

  return {
    createCreditNoteLoading,
    onCreateCreditNote,
  };
};
