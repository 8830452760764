import { Option } from 'app/types/Option';

export enum InsurancePoliciesStatus {
  Active = '0',
  Deactivate = '1',
  Expired = '2',
  Removed = '3',
}
export interface RemovedInsuranceContractor {
  id: string;
  nameEn: string;
  nameAr: string;
  insuranceCompany: Option;
  contractingStartDate: string;
  contractingEndDate: string;
  clinicBranch: Option;
  status: Option<InsurancePoliciesStatus>;
}

export interface InsuranceRemovedContract {
  id: string;
  nameEn: string;
  nameAr: string;
  insuranceCompany: Option;
  contractStartDate: string;
  contractEndDate: string;
  contractorStatus: Option<InsurancePoliciesStatus>;
  status: Option<InsurancePoliciesStatus>;
}
