import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  MergedPatients,
  PatientMergeChooserData,
  PatientState,
  VerificationProcessData,
} from 'app/redux/patient/types';
import { PrimaryPatientBaseInfo } from 'app/features/patient/patientForm/types';
import { MergeProfilesFormData } from 'app/features/patients/mergeProfiles/form/types';

const initialState: PatientState = {
  addedPrimaryPatients: [],
  verificationProcess: null,
  mergeProcess: {
    patientMergeChooser: null,
    mergedPatients: null,
    patientBadgeMergeModalId: null,
    patientMergeValues: null,
  },
};

const patientSlice = createSlice({
  name: 'patientSlice',
  initialState,
  reducers: {
    setAddedPrimaryPatients: (state, action: PayloadAction<PrimaryPatientBaseInfo[]>) => {
      state.addedPrimaryPatients = action.payload;
    },

    setVerificationProcess: (state, action: PayloadAction<VerificationProcessData | null>) => {
      state.verificationProcess = action.payload;
    },

    setPatientMergeChooser: (state, action: PayloadAction<PatientMergeChooserData | null>) => {
      if (state.mergeProcess) {
        state.mergeProcess.patientMergeChooser = action.payload;
      }
    },
    setPatientMergeValues: (state, action: PayloadAction<MergeProfilesFormData | null>) => {
      if (state.mergeProcess) {
        state.mergeProcess.patientMergeValues = action.payload;
      }
    },
    setPatientBadgeMergeModalId: (state, action: PayloadAction<string | null>) => {
      if (state.mergeProcess) {
        state.mergeProcess.patientBadgeMergeModalId = action?.payload;
      }
    },
    setDestinationAndTargetPatients: (state, action: PayloadAction<MergedPatients | null>) => {
      if (state.mergeProcess) {
        state.mergeProcess.mergedPatients = action?.payload;
      }
    },
  },
});

export const {
  setAddedPrimaryPatients,
  setVerificationProcess,
  setPatientMergeChooser,
  setPatientMergeValues,
  setPatientBadgeMergeModalId,
  setDestinationAndTargetPatients,
} = patientSlice.actions;

export default patientSlice.reducer;
