import { InvoiceCreditNoteReason, InvoiceItem, InvoicePaymentMethodItem, InvoiceType } from 'app/types';
import { CreditNoteConfiguration, CreditNoteBody } from 'app/redux/invoicingProcess/types';
import { bodyAdapter } from 'app/features/invoicingProcess/process/actions/helpers/bodyAdapter';
import { PaymentModeFormData } from 'app/features/invoicingProcess/process/paymentMode/form/types';
import { DeclarationFormData } from 'app/features/invoicingProcess/process/items/declarationForm/types';
import { PaymentConfigurationFormData } from 'app/features/invoicingProcess/process/paymentMethod/paymentConfigurationForm/types';

interface Params {
  creditNoteConfiguration: CreditNoteConfiguration;
  declarationValues?: DeclarationFormData;
  invoiceId: string;
  invoiceType: InvoiceType | undefined;
  isPrimaryCareSpeciality: boolean;
  items: InvoiceItem[];
  paymentConfigurationValues: PaymentConfigurationFormData;
  paymentMethodItems: InvoicePaymentMethodItem[];
  paymentModeValues: PaymentModeFormData;
}

export const creditNoteBodyAdapter = ({
  creditNoteConfiguration,
  declarationValues,
  invoiceId,
  invoiceType,
  isPrimaryCareSpeciality,
  items,
  paymentConfigurationValues,
  paymentMethodItems,
  paymentModeValues,
}: Params): CreditNoteBody => {
  const { reason, packageAction, otherReason: other } = creditNoteConfiguration;
  const editedSteps = bodyAdapter({
    declarationValues,
    invoicePackageSnapshotId: null,
    invoiceType,
    isPrimaryCareSpeciality,
    items,
    paymentConfigurationValues,
    paymentMethodItems,
    paymentModeValues,
  });
  const otherReason = reason === InvoiceCreditNoteReason.Other && other ? other : undefined; // empty string is not allowed

  return {
    editedSteps,
    invoiceId,
    otherReason,
    packageActionAfterCreditNote: packageAction,
    reason,
  };
};
