import { useAppSelector } from 'app/helpers';
import { PreferredLanguage } from 'app/shared';
import {
  Option,
  PatientEmergencyContactRelation,
  PatientGender,
  PatientMaritalStatus,
  PatientOccupation,
} from 'app/types';

export const useOptions = () => {
  // Selectors - Dictionaries
  const cityOptions: Option[] = useAppSelector((state) => state.dictionaries.data.cities);
  const genderOptions: Option<PatientGender>[] = useAppSelector((state) => state.dictionaries.data.genders);
  const languageOptions: Option<PreferredLanguage>[] = useAppSelector(
    (state) => state.dictionaries.data['notification-languages'],
  );
  const martialStatusOptions: Option<PatientMaritalStatus>[] = useAppSelector(
    (state) => state.dictionaries.data['martial-statuses'],
  );
  const nationalityOptions: Option[] = useAppSelector((state) => state.dictionaries.data.nationalities);
  const occupationOptions: Option<PatientOccupation>[] = useAppSelector((state) => state.dictionaries.data.occupations);
  const emergencyRelations: Option<PatientEmergencyContactRelation>[] = useAppSelector(
    (state) => state.dictionaries.data['emergency-contact-relations'],
  );
  const governmentIssuedTypeOptions: Option[] = useAppSelector(
    (state) => state.dictionaries.data['government-issued-id-types'],
  );

  return {
    cityOptions,
    genderOptions,
    languageOptions,
    martialStatusOptions,
    nationalityOptions,
    occupationOptions,
    emergencyRelations,
    governmentIssuedTypeOptions,
  };
};
