import delay from 'lodash/delay';
import moment from 'moment';
import classnames from 'classnames';
import { Button, Spinner, UncontrolledTooltip } from 'reactstrap';
import React, { useCallback, useState } from 'react';
import { ColumnFormatter } from 'react-bootstrap-table-next';
import { ConfirmationModal } from 'app/shared';
import { Appointment, AppointmentStatus } from 'app/types';
import { getTranslation, useAppDispatch, useAppIntl, useCurrentUser } from 'app/helpers';
import { StatusChangeValue } from 'app/redux/appointment/types';
import { changeAppointmentStatus } from 'app/redux/appointment/appointment.actions';

interface Props {
  appointment: Appointment;
  rowIndex: number;
}

const Status = ({ appointment, rowIndex }: Props) => {
  const [loading, setLoading] = useState(false);
  const dispatch = useAppDispatch();
  const { formatMessage, locale } = useAppIntl();
  const {
    isViewer,
    isNurse,
    currentUser: { allowedForAppointmentsActions },
  } = useCurrentUser();
  const startMoment = moment(appointment.start);
  const diff = startMoment.diff(moment(), 'minutes');
  const statusValue = appointment.status?.value;

  // Helper Variables
  const statusTooltip = `tooltipTarget-status-button-${rowIndex}`;

  //Statuses
  const isDangerStatus =
    statusValue &&
    [
      AppointmentStatus.CancelledByAdmin,
      AppointmentStatus.CancelledByPatient,
      AppointmentStatus.CancelledByMedicalInstitution,
      AppointmentStatus.NoShow,
    ].includes(statusValue);
  const isSecondaryStatus =
    statusValue && [AppointmentStatus.Booked, AppointmentStatus.BookedBefore24].includes(statusValue);
  const isSuccessStatus =
    statusValue &&
    [AppointmentStatus.Attended, AppointmentStatus.Confirmed, AppointmentStatus.ConfirmedByStaff].includes(statusValue);

  const statusTranslation = appointment.status ? (
    <span
      className={classnames('fw-medium text-nowrap', {
        'text-danger': isDangerStatus,
        'text-secondary': isSecondaryStatus,
        'text-success': isSuccessStatus,
      })}
    >
      {getTranslation(appointment.status, locale)}
    </span>
  ) : (
    '-'
  );

  // Confirmation modal
  const [status, setStatus] = useState<StatusChangeValue | null>(null);
  const [confirmationStatus, setConfirmationStatus] = useState(false);
  const showConfirmation = () => setConfirmationStatus(true);
  const closeConfirmation = () => setConfirmationStatus(false);

  //todo: we need to find better solution
  const onConfirm = useCallback(async () => {
    if (status) {
      setLoading(true);
      closeConfirmation();
      await dispatch(changeAppointmentStatus(appointment.id, { status }));
      //We need this delay to prevent laggy transition
      delay(() => {
        setLoading(false);
      }, 2000);
    }
  }, [appointment, dispatch, status]);

  const changeStatus = useCallback((newStatus: StatusChangeValue) => {
    setStatus(newStatus);
    showConfirmation();
  }, []);

  if (loading) {
    return (
      <div className="text-center">
        <Spinner size="sm" />
      </div>
    );
  }

  if (!allowedForAppointmentsActions || isViewer || isNurse) {
    return <>{statusTranslation}</>;
  }

  return (
    <>
      <div className="mb-1">{statusTranslation}</div>

      <span id={statusTooltip} style={(!appointment.canBeModified && { cursor: 'not-allowed' }) || undefined}>
        {/* Upcoming appointments */}
        {diff > 0 && (
          <>
            {(statusValue === AppointmentStatus.Booked || statusValue === AppointmentStatus.BookedBefore24) && (
              <Button
                color="primary-gradient"
                className="mb-0"
                disabled={!appointment.canBeModified}
                onClick={() => changeStatus(StatusChangeValue.Confirm)}
                size="sm"
              >
                {formatMessage({ id: 'CORE.BUTTON.CONFIRM' })}
              </Button>
            )}

            {statusValue === AppointmentStatus.ConfirmedByStaff && (
              <Button
                color="primary-gradient"
                className="mb-0"
                disabled={!appointment.canBeModified}
                onClick={() => changeStatus(StatusChangeValue.Unconfirm)}
                size="sm"
              >
                {formatMessage({ id: 'CORE.BUTTON.CANCEL' })}
              </Button>
            )}
          </>
        )}

        {/* Past appointments */}
        {diff >= -(24 * 60) && diff <= 0 && (
          <>
            {statusValue === AppointmentStatus.Attended && (
              <Button
                color="primary-gradient"
                className="mb-0"
                disabled={!appointment.canBeModified}
                onClick={() => changeStatus(StatusChangeValue.NoShow)}
                size="sm"
              >
                {formatMessage({ id: 'APPOINTMENTS.TEXT.NO-SHOW' })}
              </Button>
            )}

            {statusValue === AppointmentStatus.NoShow && (
              <Button
                color="primary-gradient"
                className="mb-0"
                disabled={!appointment.canBeModified}
                onClick={() => changeStatus(StatusChangeValue.Attended)}
                size="sm"
              >
                {formatMessage({ id: 'APPOINTMENTS.TEXT.ATTENDED' })}
              </Button>
            )}
          </>
        )}
      </span>

      {!appointment.canBeModified && (
        <UncontrolledTooltip target={statusTooltip} fade={false} delay={{ show: 0, hide: 0 }}>
          {formatMessage({ id: 'APPOINTMENTS.TOOLTIP.CAN-NOT-EDIT' })}
        </UncontrolledTooltip>
      )}

      {/* Confirmation modal */}
      <ConfirmationModal
        description="APPOINTMENTS.TEXT.CHANGE-STATUS"
        isOpen={confirmationStatus}
        onCancel={closeConfirmation}
        onClose={closeConfirmation}
        onConfirm={onConfirm}
      />
    </>
  );
};

export const statusFormatter: ColumnFormatter<Appointment> = (_, row, rowIndex) => (
  <Status appointment={row} rowIndex={rowIndex} />
);
