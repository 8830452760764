import * as types from './insurance.types';

const initialState = {
  loading: false,
  error: false,
  data: {},
};

export const insuranceReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.FETCH_INSURANCE_REQUEST:
      return {
        ...state,
        data: initialState.data,
        loading: true,
      };
    case types.FETCH_INSURANCE_SUCCESS:
      return {
        ...state,
        data: action.payload.data,
        loading: false,
      };
    case types.FETCH_INSURANCE_FAILURE:
      return {
        ...state,
        loading: false,
        error: true,
      };
    case types.ADD_INSURANCE_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case types.ADD_INSURANCE_SUCCESS:
      return {
        ...state,
        data: action.payload.data,
        loading: false,
      };
    case types.ADD_INSURANCE_FAILURE:
      return {
        ...state,
        loading: false,
        error: true,
      };
    case types.DELETE_INSURANCE_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case types.DELETE_INSURANCE_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case types.DELETE_INSURANCE_FAILURE:
      return {
        ...state,
        loading: false,
        error: true,
      };
    case types.EDIT_INSURANCE_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case types.EDIT_INSURANCE_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case types.EDIT_INSURANCE_FAILURE:
      return {
        ...state,
        loading: false,
        error: true,
      };
    default:
      return state;
  }
};
