import { InvoiceCreditNoteAction, InvoiceCreditNotePackageAction, InvoiceCreditNoteReason } from 'app/types';

export const CreditNoteField = {
  action: 'action',
  otherReason: 'otherReason',
  reason: 'reason',
  packageAction: 'packageAction',
} as const;

export interface CreditNoteFormData {
  [CreditNoteField.action]: InvoiceCreditNoteAction;
  [CreditNoteField.otherReason]?: string;
  [CreditNoteField.reason]: InvoiceCreditNoteReason;
  [CreditNoteField.packageAction]?: InvoiceCreditNotePackageAction;
}
