import { AssessmentReferral } from 'app/redux/doctorAssessment/types';
import { AssessmentReferralParams } from 'app/assessment/referralSection/types';

export const referralValuesAdapter = (
  assessmentReferrals: AssessmentReferral[] | undefined,
): AssessmentReferralParams[] => {
  const notCompletedReferral = assessmentReferrals?.find((assessmentReferral) => !assessmentReferral.completed);

  if (notCompletedReferral) {
    return [
      {
        id: notCompletedReferral.id,
        specialityId: notCompletedReferral.specialityReferredTo.value,
        comment: notCompletedReferral.comment,
      },
    ];
  }

  return [];
};
