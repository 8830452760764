import React, { Component } from 'react';
import PropTypes from 'prop-types';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory, { PaginationProvider } from 'react-bootstrap-table2-paginator';
import { FormattedMessage } from 'react-intl';
import classNames from 'classnames';
import { StickyPagination } from 'app/components';
import { PAGINATION_DEFAULT_PARAMS } from '../constants/pagination.constants';
import { Loader } from '../loader/loader';

import 'app/shared/remoteTable/remoteTable.scss';

/**
 * This component is deprecated, use RemoteTable instead
 */
export class RemoteTableComponent extends Component {
  static propTypes = {
    classes: PropTypes.string,
    wrapperClasses: PropTypes.string,
    data: PropTypes.array.isRequired,
    onFetch: PropTypes.func.isRequired,
    onSetParams: PropTypes.func.isRequired,
    params: PropTypes.object.isRequired,
    id: PropTypes.string.isRequired,
    keyField: PropTypes.string.isRequired,
    columns: PropTypes.array.isRequired,
    loading: PropTypes.bool.isRequired,
    customNoDataMessageId: PropTypes.string,
  };

  static defaultProps = {
    remote: true,
  };

  state = {
    isInitialized: false,
  };

  componentDidMount() {
    this.props.onFetch(this.props.params).then(() => {
      this.setState({ isInitialized: true });
    });
  }

  componentDidUpdate() {
    if (!this.props.loading && this.props.params.pageNumber > 1 && this.props.data.length === 0) {
      this.props.onSetParams({ pageNumber: this.props.params.pageNumber - 1 });
      this.props.onFetch({
        ...this.props.params,
        pageNumber: this.props.params.pageNumber - 1,
      });
    }
  }

  getCellNumberColumn = () => {
    const pageNumber = this.props.params.pageNumber;
    const pageSize = this.props.params.pageSize || PAGINATION_DEFAULT_PARAMS.pageSize;

    return {
      dataField: '',
      text: '#',
      formatter: (cell, row, index) => {
        return <span>{index + 1 + (pageNumber - 1) * pageSize}</span>;
      },
      sort: false,
    };
  };

  getColumns = () => {
    return [this.getCellNumberColumn(), ...this.props.columns];
  };

  onTableChange = async (type, value) => {
    const page = type === 'pagination' ? value.page : 1;

    const params = {
      pageNumber: page,
      order: value.sortField || this.props.params.order,
      orderAsc: value.sortOrder !== undefined ? value.sortOrder === 'asc' : this.props.params.orderAsc,
      pageSize: value.sizePerPage,
    };

    this.props.onSetParams(params);

    if (this.state.isInitialized) {
      await this.props.onFetch({
        ...this.props.params,
        ...params,
      });
    }
  };

  render() {
    const { classes, wrapperClasses, params, loading, data, remote, customNoDataMessageId } = this.props;
    const hasItems = data.length > 0;

    const defaultSorted = params.order
      ? [{ dataField: params.order, order: params.orderAsc ? 'asc' : 'desc' }]
      : undefined;

    const paginationOptions = {
      custom: true,
      page: params.pageNumber,
      sizePerPage: params.pageSize,
      totalSize: params.totalItemCount,
      hideSizePerPage: true,
    };

    return (
      <PaginationProvider pagination={paginationFactory(paginationOptions)}>
        {({ paginationProps, paginationTableProps }) => (
          <>
            <BootstrapTable
              {...paginationTableProps}
              bordered={false}
              classes={classes}
              columns={this.getColumns()}
              data={data}
              defaultSorted={defaultSorted}
              id={this.props.id}
              keyField={this.props.keyField}
              onTableChange={this.onTableChange}
              remote={remote}
              wrapperClasses={classNames(
                'remoteTable table-responsive',
                {
                  'remoteTable--loading': loading,
                  'remoteTable--empty': !loading && !hasItems,
                },
                wrapperClasses,
              )}
            />

            {loading && <Loader />}

            {!loading && !hasItems && (
              <div className="fw-medium text-center my-4">
                <FormattedMessage id={customNoDataMessageId || 'CORE.LABEL.NO-DATA'} />
              </div>
            )}

            {paginationProps && (
              <StickyPagination
                loading={loading}
                onPageChange={paginationProps.onPageChange}
                onSizePerPageChange={paginationProps.onSizePerPageChange}
                page={paginationProps.page}
                sizePerPage={paginationProps.sizePerPage}
                totalSize={paginationProps.totalSize}
              />
            )}
          </>
        )}
      </PaginationProvider>
    );
  }
}
