import { Label } from 'reactstrap';

export interface SwitchComponentLabelProps {
  inputId: string;
  isRequired?: boolean;
  label: string;
}

export const SwitchComponentLabel = (props: SwitchComponentLabelProps) => (
  <Label for={props.inputId}>
    {props.label}
    {props.isRequired && <span className="text-danger">&nbsp;*</span>}
  </Label>
);
