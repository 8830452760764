import { useMemo } from 'react';
import { useAppSelector, useCurrentUser } from 'app/helpers';

export const useClinicId = () => {
  const { currentUser } = useCurrentUser();
  const referralClinicId = useAppSelector((state) => state.bookAppointment.referralInfo?.clinicId);

  return useMemo(() => {
    return referralClinicId || currentUser.clinic?.value || '';
  }, [currentUser, referralClinicId]);
};
