import moment from 'moment';
import { ParsedQs } from 'qs';
import { Option, TimeRange } from 'app/types';
import { FORMATS_API_TIME } from 'app/shared';
import { generateOption } from 'app/helpers/generateOptions/generateOptions';

export const generateOptionFromUrlParams = (name: string, urlParams: ParsedQs): Option | undefined => {
  const urlParam = urlParams[name];
  const labelParam = urlParams[`${name}Label`];
  const label = String(labelParam || urlParam);

  if (urlParam) {
    return generateOption({ nameAr: label, nameEn: label, id: String(urlParam) });
  }

  return;
};

export const generateOptionsFromUrlParams = (name: string, urlParams: ParsedQs): Option[] | undefined => {
  const urlParam = urlParams[name];

  if (!Array.isArray(urlParam)) {
    return;
  }

  return urlParam.map((value, index) => {
    const labelsParam = urlParams[`${name}Labels`];
    const label = String(Array.isArray(labelsParam) ? labelsParam[index] : value);
    return generateOption({ nameAr: label, nameEn: label, id: String(value) });
  });
};

export const generateTimeRangeFromUrlParams = (name: string, urlParams: ParsedQs): TimeRange | undefined => {
  const from = urlParams[`${name}From`];
  const to = urlParams[`${name}To`];

  if (from && typeof from === 'string' && to && typeof to === 'string') {
    return {
      from: moment(from, FORMATS_API_TIME).toDate(),
      to: moment(to, FORMATS_API_TIME).toDate(),
    };
  }

  return;
};
