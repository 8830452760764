import { round } from 'lodash';
import { PackageItem } from 'app/types';

export const calculateTotalPrice = (items: PackageItem[]) =>
  round(
    items.reduce((acc: number, item: PackageItem) => acc + item.quantity * item.pricePerUnit, 0),
    2,
  );

export const calculateOriginalTotalPrice = (items: PackageItem[]) =>
  round(
    items.reduce((acc: number, item: PackageItem) => acc + item.quantity * item.originalPrice, 0),
    2,
  );

export const calculateFromTotal = (items: PackageItem[]) =>
  round(
    items.reduce((acc: number, item: PackageItem) => acc + item.total, 0),
    2,
  );
