import { RootState } from 'app/types';
import { select, put } from 'redux-saga/effects';
import {
  fetchPerformerBillingMedications,
  fetchPerformerBillingMedicationsPerformers,
} from 'app/redux/remoteData/remoteData.actions';

export function* refreshPerformerMedicationLists(branchId: string, expandedOnly?: boolean) {
  const {
    medications: { expandedPerformerId },
    remoteData: {
      performerBillingMedications: { params },
    },
  }: RootState = yield select();

  if (params) {
    if (!expandedOnly) {
      // [?]: No one expanded - refresh only performer list
      yield put<any>(fetchPerformerBillingMedicationsPerformers(branchId, params, true));
    }

    // [?]: Performer expanded - refresh medications assigned to him
    if (expandedPerformerId) {
      yield put<any>(
        fetchPerformerBillingMedications(branchId, expandedPerformerId, { ...params, keyword: undefined }, true),
      );
    }
  }
}
