import classnames from 'classnames';
import styles from './switchButtons.module.scss';

export type SwitchButtonsItem = {
  active?: boolean;
  disabled?: boolean;
  onClick: () => void;
  text: string;
};

interface Props {
  className?: string;
  items: SwitchButtonsItem[];
}

export const SwitchButtons = (props: Props) => {
  return (
    <div className={classnames(styles.buttons, props.className)}>
      {props.items.map((item, index) => (
        <div
          className={classnames(styles.button, {
            [styles.active]: item.active,
            [styles.disabled]: item.disabled,
          })}
          key={index}
          onClick={item.onClick}
        >
          {item.text}
        </div>
      ))}
    </div>
  );
};
