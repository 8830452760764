import { useAppIntl } from 'app/helpers';
import { Actions } from 'app/features/invoicingProcess/process/summary/actions/actions';
import { ItemsPreview } from 'app/features/invoicingProcess/process/summary/itemsPreview/itemsPreview';
import { DetailsPreview } from 'app/features/invoicingProcess/process/summary/detailsPreview/detailsPreview';
import { SectionHeading } from 'app/features/invoicingProcess/process/components/sectionHeading/sectionHeading';
import { PaymentModePreview } from 'app/features/invoicingProcess/process/summary/paymentModePreview/paymentModePreview';
import { PaymentMethodPreview } from 'app/features/invoicingProcess/process/summary/paymentMethodPreview/paymentMethodPreview';

export const Summary = () => {
  const { formatMessage } = useAppIntl();

  return (
    <>
      <SectionHeading title={formatMessage({ id: 'CORE.LABEL.SUMMARY', defaultMessage: 'Summary' })} />
      <Actions />
      <hr className="mt-4 mb-3" />
      <DetailsPreview />
      <hr className="mt-1 mb-3" />
      <h5>{formatMessage({ id: 'CORE.LABEL.PAYMENT-MODE', defaultMessage: 'Payment Mode' })}</h5>
      <PaymentModePreview />
      <hr className="mt-1 mb-3" />
      <h5>{formatMessage({ id: 'CORE.LABEL.INVOICE-ITEMS', defaultMessage: 'Invoice Items' })}</h5>
      <ItemsPreview />
      <hr className="mt-1 mb-3" />
      <h5>{formatMessage({ id: 'CORE.LABEL.PAYMENT-METHOD', defaultMessage: 'Payment Method' })}</h5>
      <PaymentMethodPreview />
    </>
  );
};
