import round from 'lodash/round';
import { BillingPackagesReducer } from 'app/redux/billingPackages/billingPackages.reducer';

export const removeItem = (state: BillingPackagesReducer, id: string) => {
  const updatedItems = state.items.filter((item) => item.id !== id);

  return {
    ...state,
    items: updatedItems,
    totalPrice: updatedItems.reduce((acc, value) => round(acc + value.quantity * value.pricePerUnit, 2), 0),
    totalOriginalPrice: updatedItems.reduce((acc, value) => round(acc + value.quantity * value.originalPrice, 2), 0),
  };
};
