export const FETCH_APPOINTMENT_REQUEST = 'appointment/FETCH_APPOINTMENT_REQUEST';
export const FETCH_APPOINTMENT_SUCCESS = 'appointment/FETCH_APPOINTMENT_SUCCESS';
export const FETCH_APPOINTMENT_FAILURE = 'appointment/FETCH_APPOINTMENT_FAILURE';

export const FETCH_WA_DETAILS_REQUEST = 'appointment/FETCH_WA_DETAILS_REQUEST';
export const FETCH_WA_DETAILS_SUCCESS = 'appointment/FETCH_WA_DETAILS_SUCCESS';
export const FETCH_WA_DETAILS_FAILURE = 'appointment/FETCH_WA_DETAILS_FAILURE';
export const RESET_DETAILS = 'appointment/RESET_DETAILS';

export const ADD_APPOINTMENT_REQUEST = 'appointment/ADD_APPOINTMENT_REQUEST';
export const ADD_APPOINTMENT_SUCCESS = 'appointment/ADD_APPOINTMENT_SUCCESS';
export const ADD_APPOINTMENT_FAILURE = 'appointment/ADD_APPOINTMENT_FAILURE';

export const ADD_WAITING_APPOINTMENT_REQUEST = 'appointment/ADD_WAITING_APPOINTMENT_REQUEST';
export const ADD_WAITING_APPOINTMENT_SUCCESS = 'appointment/ADD_WAITING_APPOINTMENT_SUCCESS';
export const ADD_WAITING_APPOINTMENT_FAILURE = 'appointment/ADD_WAITING_APPOINTMENT_FAILURE';

export const CHECK_TIME_RANGE_REQUEST = 'appointment/CHECK_TIME_RANGE_REQUEST';
export const CHECK_TIME_RANGE_SUCCESS = 'appointment/CHECK_TIME_RANGE_SUCCESS';
export const CHECK_TIME_RANGE_FAILURE = 'appointment/CHECK_TIME_RANGE_FAILURE';

export const EDIT_APPOINTMENT_REQUEST = 'appointment/EDIT_APPOINTMENT_REQUEST';
export const EDIT_APPOINTMENT_SUCCESS = 'appointment/EDIT_APPOINTMENT_SUCCESS';
export const EDIT_APPOINTMENT_FAILURE = 'appointment/EDIT_APPOINTMENT_FAILURE';

export const EDIT_APPOINTMENT_NOTE_REQUEST = 'appointment/EDIT_APPOINTMENT_NOTE_REQUEST';
export const EDIT_APPOINTMENT_NOTE_SUCCESS = 'appointment/EDIT_APPOINTMENT_NOTE_SUCCESS';
export const EDIT_APPOINTMENT_NOTE_FAILURE = 'appointment/EDIT_APPOINTMENT_NOTE_FAILURE';

export const EDIT_WAITING_APPOINTMENT_NOTE_REQUEST = 'appointment/EDIT_WAITING_APPOINTMENT_NOTE_REQUEST';
export const EDIT_WAITING_APPOINTMENT_NOTE_SUCCESS = 'appointment/EDIT_WAITING_APPOINTMENT_NOTE_SUCCESS';
export const EDIT_WAITING_APPOINTMENT_NOTE_FAILURE = 'appointment/EDIT_WAITING_APPOINTMENT_NOTE_FAILURE';

export const EDIT_WAITING_APPOINTMENT_REQUEST = 'appointment/EDIT_WAITING_APPOINTMENT_REQUEST';
export const EDIT_WAITING_APPOINTMENT_SUCCESS = 'appointment/EDIT_WAITING_APPOINTMENT_SUCCESS';
export const EDIT_WAITING_APPOINTMENT_FAILURE = 'appointment/EDIT_WAITING_APPOINTMENT_FAILURE';

export const PROMOTE_WAITING_APPOINTMENT_REQUEST = 'appointment/PROMOTE_WAITING_APPOINTMENT_REQUEST';
export const PROMOTE_WAITING_APPOINTMENT_SUCCESS = 'appointment/PROMOTE_WAITING_APPOINTMENT_SUCCESS';
export const PROMOTE_WAITING_APPOINTMENT_FAILURE = 'appointment/PROMOTE_WAITING_APPOINTMENT_FAILURE';

export const SET_APPOINTMENT = 'appointment/SET_APPOINTMENT';
export const RESET_APPOINTMENT = 'appointment/RESET_APPOINTMENT';

export const SET_TICKET = 'appointment/SET_TICKET';
export const RESET_TICKET = 'appointment/RESET_TICKET';

export const SET_FULL_EDIT = 'appointment/SET_FULL_EDIT';
export const RESET_FULL_EDIT = 'appointment/RESET_FULL_EDIT';

export const SET_REBOOK = 'appointment/SET_REBOOK';
export const RESET_REBOOK = 'appointment/RESET_REBOOK';

export const SEARCH_PATIENT = 'appointment/SEARCH_PATIENT';

export const SET_PATIENT = 'appointment/SET_PATIENT';
export const RESET_PATIENT = 'appointment/RESET_PATIENT';
export const SET_PATIENT_DEPENDANTS = 'appointment/SET_PATIENT_DEPENDANTS';
export const SET_PRESELECTED_PATIENT = 'appointment/SET_PRESELECTED_PATIENT';
export const RESET_PRESELECTED_PATIENT = 'appointment/RESET_PRESELECTED_PATIENT';

export const CANCEL_APPOINTMENT_REQUEST = 'appointment/CANCEL_APPOINTMENT_REQUEST';
export const CANCEL_APPOINTMENT_SUCCESS = 'appointment/CANCEL_APPOINTMENT_SUCCESS';
export const CANCEL_APPOINTMENT_FAILURE = 'appointment/CANCEL_APPOINTMENT_FAILURE';

export const CANCEL_WAITING_APPOINTMENT_REQUEST = 'appointment/CANCEL_WAITING_APPOINTMENT_REQUEST';
export const CANCEL_WAITING_APPOINTMENT_SUCCESS = 'appointment/CANCEL_WAITING_APPOINTMENT_SUCCESS';
export const CANCEL_WAITING_APPOINTMENT_FAILURE = 'appointment/CANCEL_WAITING_APPOINTMENT_FAILURE';

export const CHANGE_APPOINTMENT_STATUS_REQUEST = 'appointment/CHANGE_APPOINTMENT_STATUS_REQUEST';
export const CHANGE_APPOINTMENT_STATUS_SUCCESS = 'appointment/CHANGE_APPOINTMENT_STATUS_SUCCESS';
export const CHANGE_APPOINTMENT_STATUS_FAILURE = 'appointment/CHANGE_APPOINTMENT_STATUS_FAILURE';

export const SET_APPOINTMENT_REGISTER_PATIENT = 'appointment/SET_APPOINTMENT_REGISTER_PATIENT';
export const RESET_APPOINTMENT_REGISTER_PATIENT = 'appointment/RESET_APPOINTMENT_REGISTER_PATIENT';

export const SET_APPOINTMENT_PERIODS = 'appointment/SET_APPOINTMENT_PERIODS';

export const FETCH_MEDICAL_SERVICES_REQUEST = 'appointment/FETCH_MEDICAL_SERVICES_REQUEST';
export const FETCH_MEDICAL_SERVICES_SUCCESS = 'appointment/FETCH_MEDICAL_SERVICES_SUCCESS';
export const FETCH_MEDICAL_SERVICES_FAILURE = 'appointment/FETCH_MEDICAL_SERVICES_FAILURE';

export const FETCH_DAY_REQUEST = 'appointment/FETCH_DAY_REQUEST';
export const FETCH_DAY_SUCCESS = 'appointment/FETCH_DAY_SUCCESS';
export const FETCH_DAY_FAILURE = 'appointment/FETCH_DAY_FAILURE';

export const FETCH_DAY_SLOTS_REQUEST = 'appointment/FETCH_DAY_SLOTS_REQUEST';
export const FETCH_DAY_SLOTS_SUCCESS = 'appointment/FETCH_DAY_SLOTS_SUCCESS';
export const FETCH_DAY_SLOTS_FAILURE = 'appointment/FETCH_DAY_SLOTS_FAILURE';

export const FETCH_PRODUCTS_REQUEST = 'appointment/FETCH_PRODUCTS_REQUEST';
export const FETCH_PRODUCTS_SUCCESS = 'appointment/FETCH_PRODUCTS_SUCCESS';
export const FETCH_PRODUCTS_FAILURE = 'appointment/FETCH_PRODUCTS_FAILURE';

export const FETCH_WORKING_HOUR_MEDICAL_SERVICES_REQUEST = 'appointment/FETCH_WORKING_HOUR_MEDICAL_SERVICES_REQUEST';
export const FETCH_WORKING_HOUR_MEDICAL_SERVICES_SUCCESS = 'appointment/FETCH_WORKING_HOUR_MEDICAL_SERVICES_SUCCESS';
export const FETCH_WORKING_HOUR_MEDICAL_SERVICES_FAILURE = 'appointment/FETCH_WORKING_HOUR_MEDICAL_SERVICES_FAILURE';
