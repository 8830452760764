import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import ReactGA from 'react-ga';

const TRACKING_ID = process.env.REACT_APP_GOOGLE_ANALYTICS_TRACKING_ID;

export const useGoogleAnalytics = () => {
  const [isInitialized, setIsInitialized] = useState(false);
  const isLocalhost = window.location.href.includes('localhost');
  const location = useLocation();

  useEffect(() => {
    if (!!TRACKING_ID && !isLocalhost) {
      ReactGA.initialize(TRACKING_ID);
      setIsInitialized(true);
    }
  }, [isLocalhost]);

  useEffect(() => {
    if (isInitialized) {
      ReactGA.pageview(location.pathname + location.search);
    }
  }, [isInitialized, location]);
};
