import { Option } from 'app/types/Option';
import { RemoteFile } from 'app/types/RemoteFile';
import { BillingItemPaymentStatus } from 'app/types/Assessment';
import { BillingItemSourceType, BillingItemType } from 'app/types/BillingItem';
import { Note } from 'app/components/locationSelector/procedureLocation/types';

export interface OrderPatientOption extends Option {
  details: {
    clinicyMrn: string | null;
    internalFile: string | null;
    nationalId: string | null;
  };
}

export interface OrderAppointmentOption extends Option {
  details: {
    patient: Option;
    speciality: Option;
    doctor?: Option;
    clinicBranch?: Option;
    date: string;
    start: string;
    end: string | null;
  };
}

// [?]: PerformerBillingItem from OrderForm dropdown - value: performerBillingItemId
export interface OrderPerformerBillingItemOption extends Option {
  // [?]: Optional for initializing edit OrderForm
  details?: {
    price: number;
    internalCode: string | null;
    medicalBillingNphiesCode: string | null;
    saudisVatExempted?: string;
    unitMeasure: Option | null;
    measureValue: string | null;
    invoicePackageItemSnapshotId?: string;
    performerBillingItemId?: string;
    billingItemSourceType?: Option<BillingItemSourceType>;
  };
}

// [?]: OrderItem from getExtendedOrder using to edit - value: orderItemId
export interface ExtendedOrderPerformerBillingItemOption extends Option {
  details: {
    performerBillingItemId: string;
    medicalBilling: string | null;
    internalCode: string | null;
    price: number;
    quantity: number;
    invoicePackageItemSnapshotId?: string;
    billingItemSourceType?: Option<BillingItemSourceType>;
  };
}

export enum OrderType {
  Patient = '1',
  Appointment = '2',
}

export enum OrderStatus {
  NotSend = '1',
  Pending = '2',
  InProgress = '3',
  Cancelled = '4',
  Done = '5',
  SampleCollected = '6',
  SampleSorting = '7',
  SampleResult = '8',
  FirstVerification = '9',
  NeedsSecondVerification = '10',
}

export type OrderLink = {
  id?: string;
  url: string;
  name?: string;
};

export interface Order {
  appointmentDate: string | null;
  appointmentEnd: string | null;
  appointmentId: string | null;
  appointmentNumber: string | null;
  appointmentStart: string | null;
  attachmentsCount: number;
  billingItemType: Option<BillingItemType> | null;
  billingStatus: Option<BillingItemPaymentStatus>;
  branch: Option;
  clinic: Option;
  details: string | null;
  doctor: Option;
  dueDate: string | null;
  hasMergePatientsBadge: boolean;
  id: string;
  isFromDoctorAssessment: boolean;
  locationsCount: number;
  number: string;
  orderItem: OrderPerformerBillingItemOption;
  orderType: Option<OrderType>;
  orderedBy: Option;
  patient: OrderPatientOption;
  invoicePackageSnapshotId?: string;
  status: Option<OrderStatus>;
  timeOfCreation: string;
}

// [?]: Used on 'MoreInfo' details ticket and 'Edit'
export interface ExtendedOrder {
  id: string;
  number: string;
  timeOfCreation: string;
  orderedBy: Option;
  branch: Option;
  clinic: Option;
  patient: OrderPatientOption;
  billingItemType: Option<BillingItemType>;
  orderItem: ExtendedOrderPerformerBillingItemOption;
  details: string | null;
  appointment: OrderAppointmentOption | null;
  type: Option<OrderType>;
  locations: Note[];
  attachments: RemoteFile[];
  status: Option<OrderStatus>;
  dueDate: string | null;
  links: OrderLink[];
  doctor: Option;
  hasMergePatientsBadge: boolean;
}

export interface OrderComment {
  id: string;
  date: string;
  comment: string;
  commentator: Option;
}

export interface OrderDetailsAction {
  id: string;
  logDate: string;
  actionTaker: Option;
  actionCommitted: Option;
  attachmentsNumber: number;
}
