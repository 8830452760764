import { Link } from 'react-router-dom';
import { ColumnFormatter } from 'react-bootstrap-table-next';
import { Appointment } from 'app/types';
import { ProtectedPhoneNumber } from 'app/components';
import { PatientBadges } from 'app/components/patientBadges/patientBadges';
import { FormatExtraData } from 'app/features/appointments/helpers/useColumns';

export const patientFormatter: ColumnFormatter<Appointment, FormatExtraData> = (_, row, rowIndex, formatExtraData) => {
  const name = row.guardianName || row.patientName;
  const id = row.guardianName || row.patientId;

  if (row.patientIsDeleted || row.guardianIsDeleted || !formatExtraData?.isLink) {
    return (
      <>
        <div>{name}</div>
        {formatExtraData?.withMobileNumber && <ProtectedPhoneNumber phoneNumber={row.patientPhoneNumber} />}
        {row.patientBadges && (
          <PatientBadges
            patientBadges={row.patientBadges}
            id={row.id}
            hasMergePatientsBadge={row.hasMergePatientsBadge}
          />
        )}
      </>
    );
  }

  return (
    <>
      <Link to={`/clinics/${row.clinic.value}/patients/${id}`}>{name}</Link>
      {formatExtraData?.withMobileNumber && <ProtectedPhoneNumber phoneNumber={row.patientPhoneNumber} />}
      {row.patientBadges && (
        <PatientBadges
          patientBadges={row.patientBadges}
          id={row.id}
          hasMergePatientsBadge={row.hasMergePatientsBadge}
        />
      )}
    </>
  );
};
