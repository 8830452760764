import { Col, Row } from 'reactstrap';
import { ReactNode, useCallback } from 'react';
import { Field, Form, reduxForm } from 'redux-form';
import { InjectedFormProps } from 'redux-form/lib/reduxForm';
import { FormatOptionLabelMeta } from 'react-select/dist/declarations/src/Select';
import { useAppDispatch, useAppIntl, useAppSelector } from 'app/helpers';
import { AsyncSelectComponent, FORMS_INVOICING_PROCESS_DETAILS } from 'app/shared';
import { BranchClinicMode, BranchStatus, ErrorType, PatientType, RemoteDataParams } from 'app/types';
import { validate } from 'app/features/invoicingProcess/process/details/form/validate';
import { useInvoicingProcessState } from 'app/features/invoicingProcess/process/helpers';
import { fetchDropdownOptions } from 'app/redux/dropdownOptions/dropdownOptions.actions';
import { DetailsField, DetailsFormData, PatientOption } from 'app/features/invoicingProcess/process/details/form/types';

export const detailsSubmitButtonId = 'detailsSubmitButton';

const FormComponent = ({ change, form, handleSubmit }: InjectedFormProps<DetailsFormData, {}, ErrorType>) => {
  const dispatch = useAppDispatch();
  const { formatMessage } = useAppIntl();
  const { isCreated } = useInvoicingProcessState();
  const branchId: string | undefined = useAppSelector(
    (state) => state.form[form]?.values?.[DetailsField.branch]?.value,
  );
  const patient: PatientOption | undefined = useAppSelector(
    (state) => state.form[form]?.values?.[DetailsField.patient],
  );
  const patientId = patient?.value;
  const disabled = isCreated;

  const fetchBranchOptions = useCallback(
    (params) =>
      dispatch(
        fetchDropdownOptions('/branches/dropdown', {
          ...params,
          clinicMode: BranchClinicMode.Operational,
          statuses: BranchStatus.Online,
        }),
      ),
    [dispatch],
  );

  const fetchPractitionerOptions = useCallback(
    (params) => {
      if (branchId) {
        return dispatch(fetchDropdownOptions(`/day-calendars/branch/${branchId}/practitioners/dropdown`, params));
      }
    },
    [branchId, dispatch],
  );

  const fetchPatientOptions = useCallback(
    (params: RemoteDataParams) => {
      if (branchId) {
        return dispatch(fetchDropdownOptions(`/branches/${branchId}/clinic-patients/dropdown`, params));
      }
    },
    [branchId, dispatch],
  );

  const fetchDependantOptions = useCallback(
    (params: RemoteDataParams) => {
      if (patientId) {
        return dispatch(fetchDropdownOptions(`/dependants/clinic-patients/${patientId}/guardians/dropdown`, params));
      }
    },
    [dispatch, patientId],
  );

  const onBranchChange = useCallback(() => {
    change(DetailsField.doctor, null);
    change(DetailsField.patient, null);
    change(DetailsField.dependant, null);
  }, [change]);

  const onPatientChange = useCallback(() => {
    change(DetailsField.dependant, null);
  }, [change]);

  const formatPatientOptionLabel = useCallback(
    (data: PatientOption, formatOptionLabelMeta: FormatOptionLabelMeta<PatientOption>): ReactNode => {
      if (formatOptionLabelMeta.context === 'value') {
        return data.label;
      }

      return (
        <>
          <span className="d-block fw-medium">{data.label}</span>
          {data.details && (
            <span className="d-block fw-light">
              <span dir="ltr">{data.details.phoneNumber}</span>
            </span>
          )}
        </>
      );
    },
    [],
  );

  const isDependent = patient?.details?.patientType?.value === PatientType.Dependent;

  return (
    <Form onSubmit={handleSubmit}>
      <Row xs={1} lg={2} xxl={3}>
        <Col>
          <Field
            name={DetailsField.branch}
            component={AsyncSelectComponent}
            onChange={onBranchChange}
            props={{
              disabled,
              fetchOptions: fetchBranchOptions,
              isRequired: true,
              label: formatMessage({ id: 'CORE.TABLE.BRANCH' }),
              placeholder: formatMessage({ id: 'CORE.PLACEHOLDER.SELECT' }),
              showClinicyId: false,
            }}
          />
        </Col>
        {branchId && (
          <Col>
            <Field
              name={DetailsField.doctor}
              component={AsyncSelectComponent}
              props={{
                cacheUniqs: [branchId],
                disabled,
                fetchOptions: fetchPractitionerOptions,
                isRequired: true,
                label: formatMessage({ id: 'CORE.LABEL.DOCTOR' }),
                placeholder: formatMessage({ id: 'CORE.PLACEHOLDER.SELECT' }),
                showClinicyId: false,
              }}
            />
          </Col>
        )}
        {branchId && (
          <Col>
            <Field
              name={DetailsField.patient}
              component={AsyncSelectComponent}
              onChange={onPatientChange}
              props={{
                cacheUniqs: [branchId],
                disabled,
                fetchOptions: fetchPatientOptions,
                formatOptionLabel: formatPatientOptionLabel,
                isRequired: true,
                label: formatMessage({ id: 'CORE.LABEL.PATIENT' }),
                placeholder: formatMessage({ id: 'CORE.PLACEHOLDER.SELECT' }),
                showClinicyId: false,
              }}
            />
          </Col>
        )}
        {isDependent && (
          <Col>
            <Field
              name={DetailsField.dependant}
              component={AsyncSelectComponent}
              props={{
                cacheUniqs: [patientId],
                disabled,
                fetchOptions: fetchDependantOptions,
                isClearable: true,
                label: formatMessage({ id: 'CORE.LABEL.RELATED-GUARDIAN', defaultMessage: 'Related Guardian' }),
                placeholder: formatMessage({ id: 'CORE.PLACEHOLDER.SELECT' }),
                showClinicyId: false,
              }}
            />
          </Col>
        )}
      </Row>
      <button className="visually-hidden" id={detailsSubmitButtonId} type="submit" />
    </Form>
  );
};

export const DetailsForm = reduxForm<DetailsFormData, {}, ErrorType>({
  destroyOnUnmount: false,
  form: FORMS_INVOICING_PROCESS_DETAILS,
  validate,
})(FormComponent);
