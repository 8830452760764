export const FETCH_APPOINTMENT_TREATMENT_PLAN_REQUEST = 'treatment/FETCH_APPOINTMENT_TREATMENT_PLAN_REQUEST';
export const FETCH_APPOINTMENT_TREATMENT_PLAN_SUCCESS = 'treatment/FETCH_APPOINTMENT_TREATMENT_PLAN_SUCCESS';
export const FETCH_APPOINTMENT_TREATMENT_PLAN_FAILURE = 'treatment/FETCH_APPOINTMENT_TREATMENT_PLAN_FAILURE';

export const FETCH_GENERAL_TREATMENT_INFO_REQUEST = 'treatment/FETCH_GENERAL_TREATMENT_INFO_REQUEST';
export const FETCH_GENERAL_TREATMENT_INFO_SUCCESS = 'treatment/FETCH_GENERAL_TREATMENT_INFO_SUCCESS';
export const FETCH_GENERAL_TREATMENT_INFO_FAILURE = 'treatment/FETCH_GENERAL_TREATMENT_INFO_FAILURE';

export const FETCH_PATIENT_TREATMENT_PLAN_REQUEST = 'treatment/FETCH_PATIENT_TREATMENT_PLAN_REQUEST';
export const FETCH_PATIENT_TREATMENT_PLAN_SUCCESS = 'treatment/FETCH_PATIENT_TREATMENT_PLAN_SUCCESS';
export const FETCH_PATIENT_TREATMENT_PLAN_FAILURE = 'treatment/FETCH_PATIENT_TREATMENT_PLAN_FAILURE';

export const FETCH_PATIENT_ATTACHMENT_REQUEST = 'treatment/FETCH_PATIENT_ATTACHMENT_REQUEST';
export const FETCH_PATIENT_ATTACHMENT_SUCCESS = 'treatment/FETCH_PATIENT_ATTACHMENT_SUCCESS';
export const FETCH_PATIENT_ATTACHMENT_FAILURE = 'treatment/FETCH_PATIENT_ATTACHMENT_FAILURE';

export const DELETE_PATIENT_ATTACHMENT_REQUEST = 'treatment/DELETE_PATIENT_ATTACHMENT_REQUEST';
export const DELETE_PATIENT_ATTACHMENT_SUCCESS = 'treatment/DELETE_PATIENT_ATTACHMENT_SUCCESS';
export const DELETE_PATIENT_ATTACHMENT_FAILURE = 'treatment/DELETE_PATIENT_ATTACHMENT_FAILURE';

export const FETCH_PATIENT_ALLERGY_REQUEST = 'treatment/FETCH_PATIENT_ALLERGY_REQUEST';
export const FETCH_PATIENT_ALLERGY_SUCCESS = 'treatment/FETCH_PATIENT_ALLERGY_SUCCESS';
export const FETCH_PATIENT_ALLERGY_FAILURE = 'treatment/FETCH_PATIENT_ALLERGY_FAILURE';

export const FETCH_PATIENT_PRESCRIPTION_REQUEST = 'treatment/FETCH_PATIENT_PRESCRIPTION_REQUEST';
export const FETCH_PATIENT_PRESCRIPTION_SUCCESS = 'treatment/FETCH_PATIENT_PRESCRIPTION_SUCCESS';
export const FETCH_PATIENT_PRESCRIPTION_FAILURE = 'treatment/FETCH_PATIENT_PRESCRIPTION_FAILURE';

export const ADD_PATIENT_ILLNESS_REQUEST = 'treatment/ADD_PATIENT_ILLNESS_REQUEST';
export const ADD_PATIENT_ILLNESS_SUCCESS = 'treatment/ADD_PATIENT_ILLNESS_SUCCESS';
export const ADD_PATIENT_ILLNESS_FAILURE = 'treatment/ADD_PATIENT_ILLNESS_FAILURE';

export const DELETE_PATIENT_ILLNESS_REQUEST = 'treatment/DELETE_PATIENT_ILLNESS_REQUEST';
export const DELETE_PATIENT_ILLNESS_SUCCESS = 'treatment/DELETE_PATIENT_ILLNESS_SUCCESS';
export const DELETE_PATIENT_ILLNESS_FAILURE = 'treatment/DELETE_PATIENT_ILLNESS_FAILURE';

export const ADD_FAMILY_ILLNESS_REQUEST = 'treatment/ADD_FAMILY_ILLNESS_REQUEST';
export const ADD_FAMILY_ILLNESS_SUCCESS = 'treatment/ADD_FAMILY_ILLNESS_SUCCESS';
export const ADD_FAMILY_ILLNESS_FAILURE = 'treatment/ADD_FAMILY_ILLNESS_FAILURE';

export const DELETE_FAMILY_ILLNESS_REQUEST = 'treatment/DELETE_FAMILY_ILLNESS_REQUEST';
export const DELETE_FAMILY_ILLNESS_SUCCESS = 'treatment/DELETE_FAMILY_ILLNESS_SUCCESS';
export const DELETE_FAMILY_ILLNESS_FAILURE = 'treatment/DELETE_FAMILY_ILLNESS_FAILURE';

export const ADD_SURGERY_REQUEST = 'treatment/ADD_SURGERY_REQUEST';
export const ADD_SURGERY_SUCCESS = 'treatment/ADD_SURGERY_SUCCESS';
export const ADD_SURGERY_FAILURE = 'treatment/ADD_SURGERY_FAILURE';

export const DELETE_SURGERY_REQUEST = 'treatment/DELETE_SURGERY_REQUEST';
export const DELETE_SURGERY_SUCCESS = 'treatment/DELETE_SURGERY_SUCCESS';
export const DELETE_SURGERY_FAILURE = 'treatment/DELETE_SURGERY_FAILURE';

export const ADD_SPECIALTY_NOTE_REQUEST = 'treatment/ADD_SPECIALTY_NOTE_REQUEST';
export const ADD_SPECIALTY_NOTE_SUCCESS = 'treatment/ADD_SPECIALTY_NOTE_SUCCESS';
export const ADD_SPECIALTY_NOTE_FAILURE = 'treatment/ADD_SPECIALTY_NOTE_FAILURE';

export const EDIT_SPECIALTY_NOTE_REQUEST = 'treatment/EDIT_SPECIALTY_NOTE_REQUEST';
export const EDIT_SPECIALTY_NOTE_SUCCESS = 'treatment/EDIT_SPECIALTY_NOTE_SUCCESS';
export const EDIT_SPECIALTY_NOTE_FAILURE = 'treatment/EDIT_SPECIALTY_NOTE_FAILURE';

export const DELETE_SPECIALTY_NOTE_REQUEST = 'treatment/DELETE_SPECIALTY_NOTE_REQUEST';
export const DELETE_SPECIALTY_NOTE_SUCCESS = 'treatment/DELETE_SPECIALTY_NOTE_SUCCESS';
export const DELETE_SPECIALTY_NOTE_FAILURE = 'treatment/DELETE_SPECIALTY_NOTE_FAILURE';

export const SET_ATTACHMENT = 'treatment/SET_ATTACHMENT';
export const SET_ADD_PRESCRIPTION_REMOTE_MODE = 'treatment/SET_ADD_PRESCRIPTION_REMOTE_MODE';
export const RESET_APPOINTMENT_TREATMENT_PLAN = 'treatment/RESET_APPOINTMENT_TREATMENT_PLAN';
