import React, { useCallback } from 'react';
import delay from 'lodash/delay';
import { FormattedMessage } from 'react-intl';
import { FormFeedback, FormGroup, Label } from 'reactstrap';
import { ReactDatePickerProps } from 'react-datepicker';
import { WrappedFieldProps } from 'redux-form/lib/Field';
import { DatePicker } from 'app/components';
import { CustomInput } from './customInput/customInput';

export interface DatePickerComponentProps extends Partial<ReactDatePickerProps> {
  inactive?: boolean;
  isRequired?: boolean;
  label: string;
  placeholder?: string;
  size?: 'lg' | 'sm';
}

export const DatePickerComponent = ({
  dateFormat = 'dd/MM/yyyy',
  input,
  isRequired,
  label,
  meta,
  placeholder,
  onCalendarOpen,
  ...restProps
}: WrappedFieldProps & DatePickerComponentProps) => {
  const inputId = restProps.id || input.name;
  const invalid = !!meta.error && meta.touched;

  const handleOpen = useCallback(() => {
    if (onCalendarOpen) {
      onCalendarOpen();
    }

    delay(() => {
      const selectedItem = document.querySelector<HTMLElement>('.react-datepicker__time-list-item--selected');
      const parent = document.querySelector<HTMLElement>('.react-datepicker__time-list');

      if (selectedItem && parent) {
        const valueToCenter = parent.offsetWidth / 2 - selectedItem.offsetWidth / 2;
        parent.scrollTo({
          left: selectedItem.offsetLeft - valueToCenter,
          behavior: 'smooth',
        });
      }
    }, 100);
  }, [onCalendarOpen]);

  return (
    <FormGroup>
      {label && (
        <Label for={inputId}>
          {label}
          {isRequired && <span className="text-danger">&nbsp;*</span>}
        </Label>
      )}
      <DatePicker
        customInput={
          <CustomInput
            disabled={restProps.disabled}
            inputId={inputId}
            inputPlaceholder={placeholder}
            invalid={invalid}
            bsSize={restProps.size}
          />
        }
        dateFormat={dateFormat}
        isClearable
        selected={input.value || null}
        {...restProps}
        onChange={input.onChange}
        onCalendarOpen={handleOpen}
      />
      {invalid && (
        <FormFeedback className="d-block">
          <FormattedMessage id={meta.error} />
        </FormFeedback>
      )}
    </FormGroup>
  );
};
