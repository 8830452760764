import React from 'react';
import classnames from 'classnames';
import { FormattedMessage } from 'react-intl';
import { FormFeedback, FormGroup, Label } from 'reactstrap';
import { WrappedFieldProps } from 'redux-form/lib/Field';
import { PhoneInput } from 'app/components';
import { IconX } from '@tabler/icons-react';
import './phoneNumber.scss';

export interface PhoneNumberProps {
  disabled?: boolean;
  isRequired?: boolean;
  label?: string;
  size?: 'sm' | 'lg';
  onEnterKeyPress?: (event: React.KeyboardEvent<HTMLInputElement>) => void;
}

export const PhoneNumber = ({
  input,
  meta,
  disabled,
  isRequired,
  label,
  onEnterKeyPress,
  size,
}: WrappedFieldProps & PhoneNumberProps) => {
  const invalid = input.value ? meta.invalid : meta.invalid && meta.touched;
  const valid = !disabled && input.value && meta.valid;
  const error = meta.error;

  return (
    <FormGroup>
      <Label for={input.name}>
        {label}
        {isRequired && <span className="text-danger">&nbsp;*</span>}
      </Label>
      <div className="position-relative">
        <PhoneInput
          country="sa" // default country
          disabled={disabled}
          invalid={invalid}
          name={input.name}
          onChange={(value, data, event, formattedValue) => input.onChange(formattedValue)}
          onEnterKeyPress={onEnterKeyPress}
          onMount={(value, data) => {
            if (input.value && !('countryCode' in data)) {
              // If no country code is detected, add a default one (+966)
              input.onChange(`+966${value}`);
            }
          }}
          valid={valid}
          value={input.value}
          size={size}
        />
        {input.value && !disabled && (
          <div
            className={classnames('phoneNumber__close', { 'phoneNumber__close--withIcon': invalid || valid })}
            onClick={() => input.onChange('')}
          >
            <IconX size={12} strokeWidth={2.5} />
          </div>
        )}
      </div>
      {invalid && error && (
        <FormFeedback className="d-block">
          <FormattedMessage id={error.message ? error.message : error} values={{ value: error.value }} />
        </FormFeedback>
      )}
    </FormGroup>
  );
};
