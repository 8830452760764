import React from 'react';
import PropTypes from 'prop-types';
import { Button, Form } from 'reactstrap';
import { Field } from 'redux-form';
import { FormattedMessage } from 'react-intl';

import { PasswordInput, InputComponent, required, email, minLength } from 'app/shared';

const minLength2 = minLength(2);

export const FormComponent = ({ handleSubmit, intl: { formatMessage }, pristine, submitting }) => (
  <Form onSubmit={handleSubmit}>
    <Field
      name="email"
      component={InputComponent}
      isRequired
      label={formatMessage({ id: 'CORE.LABEL.EMAIL' })}
      type="email"
      validate={[required, email]}
      isRtl={false}
    />

    <Field
      name="password"
      component={PasswordInput}
      isRequired
      label={formatMessage({ id: 'CORE.LABEL.NEW-PASSWORD' })}
      type="password"
      validate={[required, minLength2]}
    />

    <div className="form__actions">
      <Button block color="primary-gradient" disabled={submitting || pristine} type="submit">
        <FormattedMessage id="CORE.BUTTON.SUBMIT" />
      </Button>
    </div>
  </Form>
);

FormComponent.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  intl: PropTypes.object.isRequired,
  pristine: PropTypes.bool.isRequired,
  submitting: PropTypes.bool.isRequired,
};
