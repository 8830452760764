import { put } from 'redux-saga/effects';
import { PayloadAction } from '@reduxjs/toolkit';
import { WsRefreshPayload } from 'app/sagas/assessment/types';
import { updateInvoiceBillingItemStatuses } from 'app/redux/doctorAssessment/doctorAssessment.actions';

export function* fetchNotCoveredBillingItemsStatuses({ payload }: PayloadAction<WsRefreshPayload>) {
  const { body, appointmentId } = payload;

  yield put<any>(updateInvoiceBillingItemStatuses(appointmentId, body));
}
