import { connect } from 'react-redux';
import { updateIntl } from 'react-intl-redux';
import { translations } from '../../../i18n/index';

import InfoPageHeaderComponent from './info-page-header.component';

const mapStateToProps = (state) => ({
  language: state.intl.locale,
});

const mapDispatchToProps = (dispatch) => ({
  changeLanguage: (code) =>
    dispatch(
      updateIntl({
        locale: code,
        messages: translations[code],
      }),
    ),
});

export const InfoPageHeaderContainer = connect(mapStateToProps, mapDispatchToProps)(InfoPageHeaderComponent);
