import { RootState } from 'app/types';
import { put, select } from 'redux-saga/effects';
import { fetchAllergies } from 'app/redux/remoteData/remoteData.actions';

export function* refreshAllergies() {
  const {
    remoteData: {
      allergy: { params },
    },
    initialAssessmentWizard: { initialAssessment, arrival },
  }: RootState = yield select();
  if (params && arrival) {
    yield put<any>(fetchAllergies(params, arrival.patient.value));
  }
  if (params && initialAssessment) {
    yield put<any>(fetchAllergies(params, initialAssessment.patient.value));
  }
}
