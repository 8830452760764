import { formatOptions } from 'app/invoice/shared/utils';
import { saudiVatExemptedOptions } from 'app/billingItems/types';
import { useAppIntl } from 'app/helpers/useAppIntl/useAppIntl';

export const useSaudiVatExemptedFormOptions = () => {
  const { formatMessage } = useAppIntl();
  const saudiVatExemptedFormOptions = formatOptions<boolean>(saudiVatExemptedOptions, formatMessage);

  return {
    saudiVatExemptedFormOptions,
  };
};
