import { FormErrors } from 'redux-form';
import { required } from 'app/shared';
import {
  PaymentConfigurationField,
  PaymentConfigurationFormData,
} from 'app/features/invoicingProcess/process/paymentMethod/paymentConfigurationForm/types';

export const validate = (values?: Partial<PaymentConfigurationFormData>): FormErrors<PaymentConfigurationFormData> => {
  let errors: Partial<{ [Property in keyof PaymentConfigurationFormData]: string | undefined }> = {};

  const validation = required(values?.dueDate);

  if (validation) {
    errors = {
      ...errors,
      [PaymentConfigurationField.dueDate]: validation,
    };
  }

  return errors;
};
