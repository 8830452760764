import { put, select } from 'redux-saga/effects';
import { RootState } from 'app/types';
import { fetchInsuranceCompanies } from 'app/redux/remoteData/remoteData.actions';

export function* afterCreateInsurancesSuccess() {
  const {
    remoteData: {
      insuranceCompanies: { params },
    },
  }: RootState = yield select();

  if (params) {
    yield put<any>(fetchInsuranceCompanies(params));
  }
}
