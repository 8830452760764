import * as types from './branches.types';
import { PAGINATION_DEFAULT_PARAMS } from 'app/shared';

const initialState = {
  dictionaries: [],
  availableDictionaries: [],
  allDictionaries: [],
  clinic: {
    error: false,
    loading: true,
    data: [],
    params: {
      ...PAGINATION_DEFAULT_PARAMS,
      statuses: 'online,offline,blocked',
    },
  },
  clinicDeleted: {
    error: false,
    loading: true,
    data: [],
    params: PAGINATION_DEFAULT_PARAMS,
  },
};

export const branchesReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.FETCH_BRANCHES_BY_CLINIC_LIST_REQUEST:
      return {
        ...state,
        clinic: {
          ...state.clinic,
          data: [],
          loading: true,
        },
      };
    case types.FETCH_BRANCHES_BY_CLINIC_LIST_DICT_SUCCESS:
      return {
        ...state,
        dictionaries: action.payload.data
          .filter((item) => item.status !== 'Deleted')
          .map((item) => ({
            label: item.nameEn,
            value: item.id,
            translation: {
              en: item.nameEn,
              ar: item.nameAr,
            },
          })),
      };
    case types.FETCH_BRANCHES_BY_CLINIC_LIST_SUCCESS:
      return {
        ...state,
        clinic: {
          ...state.clinic,
          data: action.payload.data,
          params: {
            ...state.clinic.params,
            ...action.payload.pagination,
          },
          loading: false,
        },
      };
    case types.FETCH_BRANCHES_BY_CLINIC_LIST_FAILURE:
      return {
        ...state,
        clinic: {
          ...state.clinic,
          loading: false,
          error: true,
        },
      };
    case types.FETCH_AVAILABLE_BRANCHES_REQUEST:
      return {
        ...state,
        availableDictionaries: [],
      };
    case types.FETCH_AVAILABLE_BRANCHES_SUCCESS:
      return {
        ...state,
        availableDictionaries: action.payload.data.map((item) => ({
          value: item.id,
          city: item.city,
          clinicyId: item.clinicyId,
          translation: { en: item.nameEn, ar: item.nameAr },
        })),
      };
    case types.FETCH_ALL_BRANCHES_SUCCESS:
      return {
        ...state,
        allDictionaries: action.payload.data.map((item) => ({
          value: item.id,
          clinicyId: item.clinicyId,
          translation: { en: item.nameEn, ar: item.nameAr },
        })),
      };
    case types.FETCH_AVAILABLE_BRANCHES_FAILURE:
      return {
        ...state,
        availableDictionaries: [],
      };
    case types.FETCH_DELETED_BRANCHES_BY_CLINIC_LIST_REQUEST:
      return {
        ...state,
        clinicDeleted: {
          ...state.clinicDeleted,
          data: [],
          loading: true,
        },
      };
    case types.FETCH_DELETED_BRANCHES_BY_CLINIC_LIST_SUCCESS:
      return {
        ...state,
        clinicDeleted: {
          ...state.clinicDeleted,
          data: action.payload.data,
          params: {
            ...state.clinicDeleted.params,
            ...action.payload.pagination,
          },
          loading: false,
        },
      };
    case types.FETCH_DELETED_BRANCHES_BY_CLINIC_LIST_FAILURE:
      return {
        ...state,
        clinicDeleted: {
          ...state.clinicDeleted,
          loading: false,
          error: true,
        },
      };
    case types.SET_PARAMS:
      return {
        ...state,
        clinic: {
          ...state.clinic,
          params: {
            ...state.clinic.params,
            ...action.meta,
          },
        },
      };
    case types.SET_DELETED_PARAMS:
      return {
        ...state,
        clinicDeleted: {
          ...state.clinicDeleted,
          params: {
            ...state.clinicDeleted.params,
            ...action.meta,
          },
        },
      };
    default:
      return state;
  }
};
