import moment from 'moment';
import { useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { useAppSelector } from 'app/helpers';
import {
  AppointmentStatus,
  CalendarTabs,
  DayCalendar,
  DayCalendarAppointment,
  DayCalendarWaitingAppointment,
  DayCalendarWorkingTime,
} from 'app/types';
import { findWorkingTime } from 'app/calendar/components/calendar/helpers';

type EventDetails = {
  appointment: DayCalendarAppointment | DayCalendarWaitingAppointment;
  branchId: string;
  doctorId: string;
  workingTime?: DayCalendarWorkingTime;
};

type AppointmentState = {
  canBeModified: boolean;
  eventStart: Date;
  hasInvoice: boolean;
  isAttended: boolean;
  isCheckedIn: boolean;
  isConflicting: boolean;
  isNoShow: boolean;
  isNoTime: boolean;
  isPast: boolean;
};

export const useEventDetails = (selectedEvent: string | null) => {
  const { tabId } = useParams<{ tabId?: CalendarTabs }>();
  const { data: dayCalendarsData } = useAppSelector((state) => state.remoteData.dayCalendars);
  const { data: weekCalendarData } = useAppSelector((state) => state.remoteData.weekCalendar);

  const eventDetails = useMemo((): EventDetails | undefined => {
    let calendars: DayCalendar[] = [];

    // Select resource
    if (tabId === CalendarTabs.Day) {
      calendars = dayCalendarsData;
    } else if (tabId === CalendarTabs.Week) {
      calendars = weekCalendarData;
    }

    if (calendars && selectedEvent) {
      // Find day calendar
      const dayCalendar = calendars.find(
        (c) =>
          c.appointments.find((a) => a.id === selectedEvent) ||
          c.waitingAppointments.find((w) => w.id === selectedEvent),
      );

      if (dayCalendar) {
        // Find appointment
        const appointment =
          dayCalendar.appointments.find((a) => a.id === selectedEvent) ||
          dayCalendar.waitingAppointments.find((a) => a.id === selectedEvent);

        if (appointment) {
          // Find Working Time
          const workingTime = findWorkingTime(
            dayCalendar.workingTimes,
            moment(appointment.start).toDate(),
            appointment.end ? moment(appointment.end).toDate() : undefined,
          );

          // Return event details
          return {
            appointment,
            branchId: dayCalendar.branch.value,
            doctorId: dayCalendar.doctor.id,
            workingTime: workingTime || dayCalendar.workingTimes[0],
          };
        }
      }

      // Event details cannot be generated
      return;
    }
  }, [dayCalendarsData, selectedEvent, tabId, weekCalendarData]);

  const appointmentState = useMemo(
    (): AppointmentState => ({
      canBeModified:
        eventDetails && 'canBeModified' in eventDetails.appointment ? eventDetails.appointment.canBeModified : false,
      eventStart: moment(eventDetails?.appointment.start).toDate(), // start or current date
      hasInvoice:
        eventDetails && 'invoiceId' in eventDetails.appointment ? !!eventDetails.appointment.invoiceId : false,
      isAttended:
        eventDetails && 'status' in eventDetails.appointment
          ? eventDetails.appointment.status.value === AppointmentStatus.Attended
          : false,
      isCheckedIn: eventDetails && 'checkedIn' in eventDetails.appointment ? eventDetails.appointment.checkedIn : false,
      isConflicting: !!eventDetails?.appointment.isConflicting,
      isNoShow:
        eventDetails && 'status' in eventDetails.appointment
          ? eventDetails.appointment.status.value === AppointmentStatus.NoShow
          : false,
      isNoTime: eventDetails ? !eventDetails.appointment.end : false,
      isPast:
        eventDetails && eventDetails.appointment.start
          ? moment(eventDetails.appointment.start).isBefore(moment())
          : false,
    }),
    [eventDetails],
  );

  return {
    ...appointmentState,
    eventDetails,
  };
};
