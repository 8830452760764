import { Option } from 'app/types/Option';
import { RemoteFile } from 'app/types/RemoteFile';

export interface PatientTreatmentData {
  id: string;
  patient: Option;
  doctor: Option;
  start: string;
  end: string;
  status: Option;
  doctorImage: RemoteFile;
  number: number;
  nationality: Option;
  nationalId: string;
  speciality: Option;
  subspeciality: Option;
  service: Option;
  gender: Option;
  age: number;
  birth: Date;
  insurance: Option;
  insuranceId: string;
  patientAppearance: Option;
  lastUpdated?: string | Date;
  lastVisit?: string | Date;
  checkedIn: boolean;
  invoiceId: string | null;
}

export enum AttachmentType {
  Radiology = '0',
  LabResults = '1',
  PreviousMedicalRecord = '2',
  MedicalRecordFromAnotherPractitioner = '3',
  Consents = '4',
  PreviousPrescriptions = '5',
  Other = '6',
}
