import { takeEvery } from 'redux-saga/effects';
import { refreshInvoicesRequest } from 'app/sagas/financial/generators';
import {
  ADD_INVOICES_SUCCESS,
  PUT_INVOICES_DISCARD_SUCCESS,
  PUT_INVOICES_MOVE_SUCCESS,
} from 'app/redux/invoices/invoices.types';

function* financialSaga() {
  yield takeEvery(
    [PUT_INVOICES_DISCARD_SUCCESS, ADD_INVOICES_SUCCESS, PUT_INVOICES_MOVE_SUCCESS],
    refreshInvoicesRequest,
  );
}

export default financialSaga;
