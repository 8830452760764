import { AnyAction } from 'redux';
import { PatientAllergy } from 'app/redux/patientAllergy/types';
import * as types from 'app/redux/patientAllergy/patientAllergy.types';

export interface PatientAllergyReducer {
  data: PatientAllergy | null;
  error: boolean;
  loading: boolean;
}

const initialState: PatientAllergyReducer = {
  data: null,
  error: false,
  loading: false,
};

export const patientAllergyReducer = (state = initialState, action: AnyAction): PatientAllergyReducer => {
  switch (action.type) {
    case types.ADD_PATIENT_ALLERGY_REQUEST:
      return {
        ...state,
        error: false,
        loading: true,
      };
    case types.ADD_PATIENT_ALLERGY_SUCCESS:
      return {
        ...state,
        data: action.payload.data,
        error: false,
        loading: false,
      };
    case types.ADD_PATIENT_ALLERGY_FAILURE:
      return {
        ...state,
        error: true,
        loading: false,
      };
    default:
      return state;
  }
};
