import { RSAA } from 'redux-api-middleware';
import * as types from './dropdownOptions.types';
import { DropdownOptionsParams } from './types';

export const fetchDropdownOptions = (endpoint: string, params: DropdownOptionsParams) => ({
  [RSAA]: {
    endpoint,
    method: 'GET',
    params,
    types: [types.FETCH_OPTIONS_REQUEST, types.FETCH_OPTIONS_SUCCESS, types.FETCH_OPTIONS_FAILURE],
  },
});
