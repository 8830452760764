import { Dispatch } from 'redux';
import { change } from 'redux-form';
import { FORMS_EDIT_APPOINTMENT } from 'app/shared';
import { TimeRange } from 'app/types';
import { WaitingAppointmentFormData, WorkingTimeOption } from '../types';

const generateTimeRange = (workingTime: WorkingTimeOption): TimeRange => ({
  from: workingTime.details.from,
  to: workingTime.details.to,
});

export const onWaitingFormChange = (
  values: Partial<WaitingAppointmentFormData>,
  dispatch: Dispatch<any>,
  props: any,
  previousValues: Partial<WaitingAppointmentFormData>,
): void => {
  const { form } = props;
  const isEditForm = form === FORMS_EDIT_APPOINTMENT;

  // workingTime has been changed
  if (values.workingTime && values.workingTime !== previousValues.workingTime) {
    if (!isEditForm) {
      // We need to adapt this field to the selected workingTime, even if the initial values do not exist
      dispatch(change(form, 'preferredTimeRange', generateTimeRange(values.workingTime)));
    }

    // We need to reset these fields in the edit form
    if (isEditForm && previousValues.workingTime) {
      dispatch(change(form, 'subSpeciality', null));
      dispatch(change(form, 'product', null));
    }
  }

  if (isEditForm) {
    // subSpeciality has been changed
    if (values.workingTime && previousValues.subSpeciality && values.subSpeciality !== previousValues.subSpeciality) {
      dispatch(change(form, 'product', null));
      dispatch(change(form, 'preferredTimeRange', generateTimeRange(values.workingTime)));
    }

    // product has been changed
    if (values.workingTime && previousValues.product && values.product !== previousValues.product) {
      dispatch(change(form, 'preferredTimeRange', generateTimeRange(values.workingTime)));
    }
  }
};
