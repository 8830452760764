import { v4 } from 'uuid';
import { UncontrolledTooltip } from 'reactstrap';
import { ColumnFormatter } from 'react-bootstrap-table-next';
import { FormattedPrice } from 'app/components';
import { invoicingProcessContentId } from 'app/features/invoicingProcess/process/process';
import { FormatExtraData, ExtendedItem } from 'app/features/invoicingProcess/process/items/types';

export const PatientShareFormatter: ColumnFormatter<ExtendedItem, FormatExtraData> = (
  _,
  row,
  rowIndex,
  formatExtraData,
) => {
  const vatExemptionId = `vatExemption-${v4()}`;

  return row.vatExemptAmount > 0 ? (
    <div>
      <span className="text-decoration-underline cursor-pointer" id={vatExemptionId}>
        <FormattedPrice amount={row.patientShareTotal} />
      </span>
      <UncontrolledTooltip
        autohide={false}
        container={`#${invoicingProcessContentId}`}
        placement="top"
        target={vatExemptionId}
      >
        {formatExtraData?.formatMessage?.({ id: 'CORE.LABEL.VAT-EXEMPT-AMOUNT' })}
        {': '}
        <span className="text-nowrap">
          <FormattedPrice amount={row.vatExemptAmount} />
        </span>
      </UncontrolledTooltip>
    </div>
  ) : (
    <FormattedPrice amount={row.patientShareTotal} />
  );
};
