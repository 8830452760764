import { useCallback, useMemo, useState } from 'react';
import { ColumnFormatter } from 'react-bootstrap-table-next';
import { useAppDispatch, useAppIntl, useCurrentUser } from 'app/helpers';
import { AppointmentInvoice } from 'app/types';
import { DotsMenu, DotsMenuItem } from 'app/components';
import { download } from 'app/redux/downloads/downloads.actions';
import { invoicesModalBodyId } from 'app/features/invoicingProcess/invoices/invoices';
import { FormatExtraData } from 'app/features/invoicingProcess/invoices/helpers/useColumns';
import { useInvoicingProcessActions } from 'app/features/invoicingProcess/helpers/useInvoicingProcessActions';

const Actions = ({
  appointmentId,
  billingItemsBased,
  id,
  isDraft,
  onDeleteDraft,
}: AppointmentInvoice & FormatExtraData) => {
  const { locale } = useAppIntl();
  const dispatch = useAppDispatch();
  const { token } = useCurrentUser();
  const { formatMessage } = useAppIntl();
  const { viewDraft, viewInvoice } = useInvoicingProcessActions();
  const [loading, setLoading] = useState<boolean>(false);
  const isBillingItemsBasedOrDraft = billingItemsBased || isDraft;

  const downloadInvoice = useCallback<(invoiceId: string) => void>(
    async (invoiceId) => {
      setLoading(true);
      await dispatch(
        download({
          token,
          locale,
          endpoint: `invoices/${invoiceId}/print-view`,
          withOpening: false,
          body: false,
        }),
      );
      setLoading(false);
    },
    [dispatch, locale, token],
  );

  const items: DotsMenuItem[] = useMemo(
    () => [
      {
        isVisible: isBillingItemsBasedOrDraft,
        label: formatMessage({ id: 'CORE.LABEL.VIEW', defaultMessage: 'View' }),
        onClick: () => (isDraft ? viewDraft(id, appointmentId) : viewInvoice(id)),
      },
      {
        disabled: loading,
        isVisible: !isBillingItemsBasedOrDraft,
        label: formatMessage({ id: 'CORE.BUTTON.DOWNLOAD', defaultMessage: 'Download' }),
        onClick: () => downloadInvoice(id),
      },
      {
        isVisible: isDraft,
        label: formatMessage?.({ id: 'CORE.BUTTON.DELETE' }),
        onClick: () => onDeleteDraft?.(id),
      },
    ],
    [
      appointmentId,
      downloadInvoice,
      formatMessage,
      id,
      isBillingItemsBasedOrDraft,
      isDraft,
      loading,
      onDeleteDraft,
      viewDraft,
      viewInvoice,
    ],
  );

  return <DotsMenu container={`#${invoicesModalBodyId}`} items={items} />;
};

export const ActionsFormatter: ColumnFormatter<AppointmentInvoice, FormatExtraData> = (
  _,
  row,
  rowIndex,
  formatExtraData,
) => <Actions {...row} {...formatExtraData} />;
