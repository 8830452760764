import { Normalizer } from 'redux-form/lib/Field';

export const percentageNormalizer: Normalizer = (value: string | undefined) => {
  if (!value) {
    return value;
  }

  const discountValue = Number(value);

  if (discountValue < 0) {
    return 0;
  }

  if (discountValue > 100) {
    return 100;
  }

  return Math.floor(discountValue);
};
