import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Button, Form } from 'reactstrap';
import { Field } from 'redux-form';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import { email, InputComponent, required } from 'app/shared';

class FormComponent extends Component {
  static propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    intl: PropTypes.object.isRequired,
  };

  render() {
    const { formatMessage } = this.props.intl;

    return (
      <Form onSubmit={this.props.handleSubmit}>
        <Field
          name="email"
          component={InputComponent}
          type="email"
          label={formatMessage({ id: 'CORE.LABEL.EMAIL' })}
          isRequired
          validate={[required, email]}
          isRtl={false}
        />
        <Button
          block
          className="mb-2 btn"
          type="submit"
          disabled={this.props.submitting || this.props.pristine}
          color="primary-gradient"
        >
          <FormattedMessage id="CORE.BUTTON.SUBMIT" />
        </Button>
        <Button className="btn" block tag={Link} to="/" color="primary" outline>
          <FormattedMessage id="CORE.TEXT.LOGIN-PAGE" />
        </Button>
      </Form>
    );
  }
}

export default FormComponent;
