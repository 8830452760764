import deepDiff from 'deep-diff';
import delay from 'lodash/delay';
import { Modal, ModalBody } from 'reactstrap';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useAppIntl, useWindowSize } from 'app/helpers';
import { Selector } from 'app/components/locationSelector/procedureLocation/selector/selector';
import {
  Note,
  Step,
  Template,
  TemplateConfiguration,
  Values,
} from 'app/components/locationSelector/procedureLocation/types';
import { TEMPLATE_CONFIGURATION } from 'app/components/locationSelector/procedureLocation/constants';
import { TemplateSelector } from 'app/components/locationSelector/procedureLocation/templateSelector/templateSelector';
import { ConfirmationModal } from 'app/components/confirmationModal/confirmationModal';
import styles from './procedureLocation.module.scss';

interface Props {
  close: () => void;
  dir?: string;
  initialNotes?: Note[];
  initialTemplate?: Template;
  isOpen: boolean;
  preselectedNote?: Note;
  save: (values: Values) => void;
  withStatus?: boolean;
  isReadOnly?: boolean;
}

export const ProcedureLocation = ({
  close,
  dir,
  initialNotes,
  initialTemplate,
  isOpen,
  preselectedNote,
  save,
  withStatus = true,
  isReadOnly = false,
}: Props) => {
  const { width } = useWindowSize();
  const { formatMessage } = useAppIntl();
  const [step, setStep] = useState<Step | null>(Step.SelectTemplate);
  const [notes, setNotes] = useState<Note[]>([]);
  const [template, setTemplate] = useState<Template | null>(null);
  const [closeConfirmation, setCloseConfirmation] = useState(false);
  const [isSelectorVisible, setIsSelectorVisible] = useState<boolean>(false);

  const templateConfiguration = useMemo<TemplateConfiguration | null>(() => {
    if (template) {
      const configuration = TEMPLATE_CONFIGURATION[template];

      if (width <= 480) {
        return {
          ...configuration,
          maxDistance: 23,
        };
      } else {
        return configuration;
      }
    } else {
      return null;
    }
  }, [template, width]);

  const initialize = useCallback(() => {
    setStep(initialTemplate ? Step.SelectLocation : Step.SelectTemplate);
    setTemplate(preselectedNote?.template || initialTemplate || null);
    setNotes(initialNotes || []);

    // Very important ($modal-transition time + 50ms)
    delay(() => setIsSelectorVisible(true), 750);
  }, [initialNotes, initialTemplate, preselectedNote?.template]);

  const onClose = useCallback(() => {
    const hasChangedNotes = deepDiff(initialNotes || [], notes);
    const hasChangedTemplate = !!initialTemplate && initialTemplate !== template;

    if (hasChangedNotes || hasChangedTemplate) {
      setCloseConfirmation(true);
    } else {
      close();
    }
  }, [close, initialNotes, initialTemplate, notes, template]);

  const onConfirmClose = useCallback(() => {
    setCloseConfirmation(false);
    close();
  }, [close]);

  const onSave = useCallback(() => {
    if (template) {
      save({
        template: template,
        notes: notes,
      });
    }
  }, [notes, save, template]);

  useEffect(() => {
    setTemplate(initialTemplate || null);
  }, [initialTemplate]);

  useEffect(() => {
    setNotes(initialNotes || []);
  }, [initialNotes]);

  useEffect(() => {
    if (template === null && step === Step.SelectLocation) {
      setStep(Step.SelectTemplate);
    }
  }, [template, step]);

  return (
    <Modal
      backdrop={false}
      fullscreen
      isOpen={isOpen}
      onClosed={() => setIsSelectorVisible(false)}
      onEnter={() => setIsSelectorVisible(false)}
      onOpened={initialize}
      toggle={onClose}
    >
      <ModalBody className={styles.body} dir={dir}>
        {step === Step.SelectTemplate && (
          <TemplateSelector close={onClose} setStep={setStep} setTemplate={setTemplate} template={template} />
        )}
        {step === Step.SelectLocation && template && templateConfiguration && isSelectorVisible && (
          <Selector
            close={onClose}
            notes={notes}
            preselectedNote={preselectedNote}
            save={onSave}
            setNotes={setNotes}
            setStep={setStep}
            template={template}
            templateConfiguration={templateConfiguration}
            withStatus={withStatus}
            isReadOnly={isReadOnly}
          />
        )}
        <ConfirmationModal
          isOpen={closeConfirmation}
          onClose={() => setCloseConfirmation(false)}
          onConfirm={onConfirmClose}
          description={formatMessage({
            id: 'CORE.TEXT-EXIT-WITHOUT-SAVING-WARNING',
            defaultMessage: 'Are you sure you want to exit without saving changes?',
          })}
        />
      </ModalBody>
    </Modal>
  );
};
