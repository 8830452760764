import { Locale, PatientType } from 'app/types';
import { getPatientFullName } from 'app/helpers';
import { InvoicingProcess } from 'app/redux/invoicingProcess/types';
import { DetailsFormData } from 'app/features/invoicingProcess/process/details/form/types';

export const getDetailsInitialValues = (
  invoicingProcess: InvoicingProcess,
  locale: Locale = Locale.en,
): Partial<DetailsFormData> | undefined => {
  return {
    branch: invoicingProcess.clinicBranch,
    doctor: invoicingProcess.doctor,

    ...(invoicingProcess.patient && {
      patient: {
        label: getPatientFullName(invoicingProcess.patient, locale),
        value: invoicingProcess.patient.id,
        details: {
          patientType:
            invoicingProcess?.guardianPatient?.patientType?.value === PatientType.Dependent
              ? invoicingProcess.guardianPatient.patientType
              : invoicingProcess.patient.patientType,
        },
      },
    }),

    ...(invoicingProcess.guardianPatient && {
      dependant: {
        label: getPatientFullName(invoicingProcess.guardianPatient, locale),
        value: invoicingProcess.guardianPatient.id,
      },
    }),
  };
};
