import { PatientFormData } from 'app/features/patient/patientForm/types';
import { PatientBodyParams } from 'app/services/patients/types';
import { getApiDate } from 'app/helpers';

export const formValuesAdapter = (values: PatientFormData): PatientBodyParams => {
  return {
    firstNameArabic: values.firstNameAr,
    fatherNameArabic: values.fatherNameAr || undefined,
    grandFatherNameArabic: values.grandFatherNameAr || undefined,
    familyNameArabic: values.familyNameAr || undefined,
    aliasNameArabic: values.preferredNameAr || undefined,

    firstNameEnglish: values.firstNameEn,
    fatherNameEnglish: values.fatherNameEn || undefined,
    grandFatherNameEnglish: values.grandFatherNameEn || undefined,
    familyNameEnglish: values.familyNameEn || undefined,
    aliasNameEnglish: values.preferredNameEn || undefined,

    internalFileNumber: values.internalPatientFileNumber || undefined,
    preferredNotificationLanguage: values.preferredLanguage?.value,
    gender: values.gender?.value,
    dateOfBirth: values.dateOfBirth ? getApiDate(values.dateOfBirth) : undefined,
    nationalityId: values.nationality?.value,
    governmentIssuedIDType: values.typeOfGovernmentIssuedId?.value,
    governmentIssuedID: values.governmentIssuedIdNumber || undefined,
    cityId: values.city?.value,
    maritalStatus: values.maritalStatus?.value,
    otherMaritalStatus: values.otherMaritalStatus,
    occupationId: values.occupation?.value,
    patientCategoryId: values.patientClassification?.value,
    email: values.email || undefined,
    emergencyContactName: values.emergencyContactName || undefined,
    emergencyContactRelation: values.emergencyContactRelation?.value || undefined,
    otherEmergencyContactRelation: values.otherEmergencyContactRelation,
    emergencyContactNumber: values.emergencyContactNumber || undefined,
    patientSourceId: values.sourceOfPatient?.value || undefined,
    generalNote: values.generalNote || undefined,
    phoneNumbers: values.patientMobileNumbers,

    links: values.primaryPatients
      ? values.primaryPatients.map((primaryPatient) => ({
          guardianClinicPatientId: primaryPatient.id,
          relation: primaryPatient.relation?.value!,
          otherRelationText: primaryPatient.otherRelationText,
        }))
      : undefined,
  };
};
