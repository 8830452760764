import { AnyAction } from 'redux';
import { Speciality, SubInformation } from 'app/types';
import * as types from './speciality.types';

export interface SpecialityState {
  loading: boolean;
  error: boolean;
  data: null | Speciality;
  sub: SubInformation;
}

const initialState: SpecialityState = {
  loading: false,
  error: false,
  data: null,
  sub: {
    loading: false,
    error: false,
    single: {},
    list: [],
  },
};

// TODO: [PT-6401] One thing left to remove whole reducer: rewrite App -> Doctor -> Details -> Speciality
export const specialityReducer = (state = initialState, action: AnyAction): SpecialityState => {
  switch (action.type) {
    case types.FETCH_SUB_SPECIALITIES_BY_SPECIALITY_REQUEST:
      return {
        ...state,
        sub: { ...state.sub, loading: true },
      };
    case types.FETCH_SUB_SPECIALITIES_BY_SPECIALITY_SUCCESS:
      return {
        ...state,
        sub: { ...state.sub, loading: false, list: action.payload.data },
      };
    case types.FETCH_SUB_SPECIALITIES_BY_SPECIALITY_FAILURE:
      return {
        ...state,
        sub: { ...state.sub, loading: false },
      };
    default:
      return state;
  }
};
