import { put, select } from 'redux-saga/effects';
import { InvoicesItemsTabs, RootState, InvoicesRequestsItemsTabs } from 'app/types';
import { invoicesApi } from 'app/services/invoices/invoices';

export function* refreshInvoiceList() {
  const {
    router: {
      location: { pathname },
    },
    queryParams: { invoices: invoicesParams, invoicesRequests: requestsParams },
  }: RootState = yield select();

  const isInvoiceList = pathname.includes(`invoices/${InvoicesItemsTabs.InvoicesItems}`);
  const isInvoiceRequestList =
    pathname.includes(`invoices/${InvoicesRequestsItemsTabs.InvoicesRequestsItems}`) ||
    pathname.includes(`invoices/${InvoicesRequestsItemsTabs.InvoicesDiscardItems}`);

  if (isInvoiceList) {
    yield put<any>(
      invoicesApi.endpoints?.getInvoices.initiate(invoicesParams, { subscribe: false, forceRefetch: true }),
    );
    return;
  }

  if (isInvoiceRequestList) {
    const isDiscardedRequestsPage = pathname.includes(`invoices/${InvoicesRequestsItemsTabs.InvoicesDiscardItems}`);
    yield put<any>(
      invoicesApi.endpoints?.getInvoicesRequests.initiate(
        { ...requestsParams, discarded: isDiscardedRequestsPage.toString() },
        { subscribe: false, forceRefetch: true },
      ),
    );
  }
}
