import { useCallback } from 'react';
import { destroy } from 'redux-form';
import { FORMS_BOOKING_WIZARD } from 'app/shared';
import { resetBookingWizard } from 'app/redux/bookingWizard/bookWizard.actions';
import { toggleModal } from 'app/redux/modals/modals.actions';
import { useAppDispatch } from 'app/helpers';

export const useModalActions = () => {
  const dispatch = useAppDispatch();

  const toggle = useCallback(() => {
    dispatch(toggleModal('bookingWizard'));
  }, [dispatch]);

  const onClosed = useCallback(() => {
    dispatch(destroy(FORMS_BOOKING_WIZARD));
    dispatch(resetBookingWizard());
  }, [dispatch]);

  return {
    toggle,
    onClosed,
  };
};
