import { RSAA } from 'redux-api-middleware';
import * as types from './book-appointment.types';
import { DICTIONARIES_LIMIT } from 'app/shared';
import { DropdownOptionsParams } from '../dropdownOptions/types';
import { BranchClinicMode, BranchStatus, Dependant, Option } from 'app/types';
import { SelectedSlot } from '../calendar/types';
import { DoctorData, ReferralInfo } from 'app/redux/book-appointment/types';
import { PatientInfoShort } from 'app/shared/patientSearch';

export const fetchDoctors = (branchId: string, keyword?: string, specialityId?: string, patientSourceId?: string) => ({
  [RSAA]: {
    endpoint: `/wizard/doctor-path/step1/doctors`,
    method: 'GET',
    params: {
      branchId: branchId,
      keyword: keyword,
      specialityId: specialityId,
      patientSourceId,
    },
    types: [types.FETCH_DOCTORS_REQUEST, types.FETCH_DOCTORS_SUCCESS, types.FETCH_DOCTORS_FAILURE],
  },
});

export const fetchSpecialities = (branchId: string, patientSourceId?: string) => ({
  [RSAA]: {
    endpoint: `/wizard/speciality-path/step1/specialities`,
    params: {
      branchId,
      patientSourceId,
    },
    method: 'GET',
    types: [types.FETCH_SPECIALITIES_REQUEST, types.FETCH_SPECIALITIES_SUCCESS, types.FETCH_SPECIALITIES_FAILURE],
  },
});

export const fetchBranches = (clinicId: string, patientSourceId?: string) => ({
  [RSAA]: {
    endpoint: `/branches/dropdown`,
    params: {
      clinicIds: clinicId,
      clinicMode: BranchClinicMode.Operational,
      patientSourceId,
      order: 'name',
      orderAsc: true,
      pageSize: DICTIONARIES_LIMIT,
      statuses: BranchStatus.Online,
    },
    method: 'GET',
    types: [types.FETCH_BRANCHES_REQUEST, types.FETCH_BRANCHES_SUCCESS, types.FETCH_BRANCHES_FAILURE],
  },
});

export const fetchBranchesForReferral = (clinicId: string, specialityId: string, patientSourceId?: string) => ({
  [RSAA]: {
    endpoint: `/wizard/branches-path/step1/branches`,
    params: {
      clinicId,
      specialityId,
      patientSourceId,
    },
    method: 'GET',
    types: [types.FETCH_BRANCHES_REQUEST, types.FETCH_BRANCHES_SUCCESS, types.FETCH_BRANCHES_FAILURE],
  },
});

export const fetchSubSpecialities = (specialityId: string, branchId: string, patientSourceId?: string) => ({
  [RSAA]: {
    endpoint: `/wizard/speciality-path/step2/sub-specialities`,
    params: {
      branchId: branchId,
      specialityId: specialityId,
      patientSourceId: patientSourceId,
    },
    method: 'GET',
    types: [
      types.FETCH_SUB_SPECIALITIES_REQUEST,
      types.FETCH_SUB_SPECIALITIES_SUCCESS,
      types.FETCH_SUB_SPECIALITIES_FAILURE,
    ],
  },
});

export const fetchDoctorSubSpecialities = (doctorId: string, branchId: string, patientSourceId?: string) => {
  return {
    [RSAA]: {
      endpoint: `/wizard/doctor-path/step2/doctor-sub-specialities`,
      method: 'GET',
      params: {
        branchId: branchId,
        doctorId: doctorId,
        patientSourceId: patientSourceId,
      },
      types: [
        types.FETCH_SUB_SPECIALITIES_REQUEST,
        types.FETCH_SUB_SPECIALITIES_SUCCESS,
        types.FETCH_SUB_SPECIALITIES_FAILURE,
      ],
    },
  };
};

export const fetchProducts = (params: DropdownOptionsParams) => {
  return {
    [RSAA]: {
      endpoint: `/wizard/speciality-path/step3/product-names`,
      method: 'GET',
      params: {
        subSpecialityId: params.subSpecialityId,
        branchId: params.branchId,
        patientSourceId: params.patientSourceId,
      },
      types: [types.FETCH_PRODUCTS_REQUEST, types.FETCH_PRODUCTS_SUCCESS, types.FETCH_PRODUCTS_FAILURE],
    },
  };
};

export const fetchDoctorProducts = (params: DropdownOptionsParams) => {
  return {
    [RSAA]: {
      endpoint: `/wizard/doctor-path/step3/doctor-product-names`,
      method: 'GET',
      params: {
        branchId: params.branchId,
        doctorId: params.doctorId,
        subSpecialityId: params.subSpecialityId,
        patientSourceId: params.patientSourceId,
      },
      types: [types.FETCH_PRODUCTS_REQUEST, types.FETCH_PRODUCTS_SUCCESS, types.FETCH_PRODUCTS_FAILURE],
    },
  };
};

export const fetchDoctorUser = (doctorId: string) => ({
  [RSAA]: {
    endpoint: `/doctors/${doctorId}`,
    method: 'GET',
    types: [types.FETCH_DOCTOR_REQUEST, types.FETCH_DOCTOR_SUCCESS, types.FETCH_DOCTOR_FAILURE],
  },
});

export const setAppointmentRegisterPatient = (patientId: string) => ({
  type: types.SET_APPOINTMENT_REGISTER_PATIENT,
  meta: { patientId },
});

export const resetRegisterPatient = () => ({
  type: types.RESET_APPOINTMENT_REGISTER_PATIENT,
});

export const selectSlot = (slot: SelectedSlot | null) => ({
  type: types.SELECT_SLOT,
  payload: slot,
});

export const setPatient = (payload: PatientInfoShort) => ({
  type: types.SET_PATIENT,
  payload,
});

export const setPatientDependants = (payload: Dependant[]) => ({
  type: types.SET_PATIENT_DEPENDANTS,
  payload,
});

export const setAddDependantMode = (payload: boolean) => ({
  type: types.SET_ADD_DEPENDANT_MODE,
  payload,
});

export const selectBranch = (option: Option | null, keepDoctor?: boolean, keepSpeciality?: boolean) => ({
  type: types.SELECT_BRANCH,
  payload: option,
  meta: { keepDoctor, keepSpeciality },
});

export const resetPatient = () => ({
  type: types.RESET_PATIENT,
});

export const resetBookAppointmentData = () => ({
  type: types.RESET,
});

export const selectSpeciality = (option: Option | null) => ({
  type: types.SELECT_SPECIALITY,
  payload: option,
});

export const selectSubSpeciality = (option: Option | null) => ({
  type: types.SELECT_SUB_SPECIALITY,
  payload: option,
});

export const selectDoctor = (doctor: DoctorData | null) => ({
  type: types.SELECT_DOCTOR,
  payload: doctor,
});

export const selectService = (option: Option | null) => ({
  type: types.SELECT_SERVICE,
  payload: option,
});

export const setAppointmentReferralInfo = (referralInfo: ReferralInfo | null) => ({
  type: types.SET_REFERRAL_INFO,
  payload: referralInfo,
});
