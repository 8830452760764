export const AddGuardianToDependentFormField = {
  mobileNumber: 'mobileNumber',
  fileNumber: 'fileNumber',
  governmentId: 'governmentId',
} as const;

export interface AddGuardianToDependentFormData {
  [AddGuardianToDependentFormField.mobileNumber]: string;
  [AddGuardianToDependentFormField.fileNumber]: string;
  [AddGuardianToDependentFormField.governmentId]: string;
}
