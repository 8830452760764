import { IconChevronDown } from '@tabler/icons-react';
import classnames from 'classnames';
import './collapseIcon.scss';

interface Props {
  active?: boolean;
  className?: string;
  size?: number;
  strokeWidth?: number;
}

export const CollapseIcon = ({ active, className, size = 12, strokeWidth = 3 }: Props) => {
  return (
    <IconChevronDown
      className={classnames('collapseIcon', { 'collapseIcon--active': active }, className)}
      size={size}
      strokeWidth={strokeWidth}
    />
  );
};
