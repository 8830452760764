import { useEffect } from 'react';
import { DEFAULT_TIME_FROM, DEFAULT_TIME_TO } from 'app/shared';
import { useAppDispatch, useAppSelector } from 'app/helpers';
import { fetchProducts, fetchProductsForWaiting } from 'app/redux/appointment/appointment.actions';

/**
 * Generates Product Options for Regular Appointments
 */
export const useProductOptions = (subSpecialityId: string | null | undefined) => {
  const dispatch = useAppDispatch();
  const appointment = useAppSelector((state) => state.appointment.data);
  const productOptions = useAppSelector((state) => state.appointment.products.data);

  useEffect(() => {
    if (appointment && subSpecialityId) {
      dispatch(
        fetchProducts({
          branchId: appointment.branch.value,
          date: appointment.start,
          doctorId: appointment.doctorId,
          from: DEFAULT_TIME_FROM,
          subSpecialityId,
          to: DEFAULT_TIME_TO,
        }),
      );
    }
  }, [appointment, dispatch, subSpecialityId]);

  return productOptions;
};

/**
 * Generates Product Options for Waiting Appointments
 */
export const useWaitingProductOptions = (
  workingTimeId: string | undefined,
  subSpecialityId: string | null | undefined,
) => {
  const dispatch = useAppDispatch();
  const productOptions = useAppSelector((state) => state.appointment.products.data);

  useEffect(() => {
    if (subSpecialityId && workingTimeId) {
      dispatch(fetchProductsForWaiting(workingTimeId, subSpecialityId));
    }
  }, [dispatch, subSpecialityId, workingTimeId]);

  return productOptions;
};
