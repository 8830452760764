import { useEffect } from 'react';
import { formattedDateAndTime, useAppDispatch, useAppIntl, useAppSelector } from 'app/helpers';
import { fetchClinicLogo } from 'app/redux/doctorAssessment/doctorAssessment.actions';
import styles from './printPractitionerAssessment.module.scss';

export const CustomHeaderPrint = () => {
  const { formatMessage } = useAppIntl();

  const dispatch = useAppDispatch();

  //Selectors
  const doctorName = useAppSelector(
    (state) => state.treatment.generalTreatmentInformation.data?.appointmentInformation.doctorName,
  );
  const firstName = useAppSelector(
    (state) => state.treatment.generalTreatmentInformation.data?.patientInformation.firstName,
  );
  const fatherName = useAppSelector(
    (state) => state.treatment.generalTreatmentInformation.data?.patientInformation.firstName,
  );
  const clinicId = useAppSelector(
    (state) => state.treatment.generalTreatmentInformation.data?.clinicInformation.clinicId,
  );
  const appointmentDate = useAppSelector(
    (state) => state.treatment.generalTreatmentInformation.data?.appointmentInformation.start,
  );
  const remoteFile = useAppSelector((state) => state.doctorAssessment.clinicLogo);

  useEffect(() => {
    if (clinicId) {
      dispatch(fetchClinicLogo(clinicId));
    }
  }, [dispatch, clinicId]);

  return (
    <div className="text-black text-right">
      <div className="d-flex justify-content-center align-items-center mb-4">
        <img className={styles.logo} src={remoteFile?.url} alt={remoteFile?.name} />
      </div>
      <div>
        <span className={styles.sectionTitle}> {formatMessage({ id: 'CORE.LABEL.DOCTOR-NAME' })}</span>:
        <span className={styles.sectionSubtitle}>{doctorName}</span>
      </div>
      <div>
        <span className={styles.sectionTitle}>{formatMessage({ id: 'CORE.LABEL.PATIENT-NAME' })}</span>:
        <span className={styles.sectionSubtitle}>{`${firstName} ${fatherName}`}</span>
      </div>
      {appointmentDate && (
        <div>
          <span className={styles.sectionTitle}>{formatMessage({ id: 'CORE.LABEL.DATE' })}</span>:
          <span className={styles.sectionSubtitle}>{formattedDateAndTime(appointmentDate)}</span>
        </div>
      )}
    </div>
  );
};
