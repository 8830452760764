import { useMemo } from 'react';
import { Option } from 'app/types';

// Types
export type YearOption = Option<number>;

// Default values
const defaultEndYear = new Date().getFullYear();
const defaultStartYear = defaultEndYear - 120;

export const useYearOptions = (startYear: number = defaultStartYear, endYear: number = defaultEndYear) => {
  return useMemo<YearOption[]>(() => {
    const options: YearOption[] = [];

    for (let year = endYear; year >= startYear; year--) {
      options.push({
        label: `${year}`,
        value: year,
      });
    }

    return options;
  }, [endYear, startYear]);
};
