import moment from 'moment/moment';

export const dateToGreaterThanFrom = (dateFrom: Date, dateTo: Date): string | undefined => {
  const errorMessage = 'ERRORS.END-DATE-MUST-BE-GREATER';

  if (moment(dateFrom).isSameOrBefore(dateTo)) {
    return undefined;
  }

  return errorMessage;
};
