import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQueryWithReAuth } from 'app/helpers';
import { QueryParams, QueryResponseWithPagination, Referral } from 'app/types';

export const referralsApi = createApi({
  reducerPath: 'referralsApi',
  baseQuery: baseQueryWithReAuth,
  endpoints: (builder) => ({
    getCurrentReferrals: builder.query<QueryResponseWithPagination<Referral[]>, QueryParams | undefined>({
      query: (params) => {
        return {
          url: 'referrals',
          params: {
            ...params,
            active: true,
          },
        };
      },
    }),
    getPastReferrals: builder.query<QueryResponseWithPagination<Referral[]>, QueryParams | undefined>({
      query: (params) => {
        return {
          url: 'referrals',
          params: {
            ...params,
            active: false,
          },
        };
      },
    }),
    getReferralsReport: builder.mutation<void, { params: QueryParams | undefined; active: boolean }>({
      query: ({ active, params }) => ({ url: 'referrals/excel', params: { ...params, active } }),
    }),
  }),
});

export const { useGetCurrentReferralsQuery, useGetPastReferralsQuery, useGetReferralsReportMutation } = referralsApi;
