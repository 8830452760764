import { useMemo } from 'react';
import { useAppIntl } from 'app/helpers';

interface Props {
  isEdit?: boolean;
  isNoTime?: boolean;
  isSlotFree: boolean;
  isWaiting?: boolean;
}

export const useModalTitle = ({ isEdit, isNoTime, isWaiting, isSlotFree }: Props) => {
  const { formatMessage } = useAppIntl();

  let messageId = '';
  if (isEdit) {
    if (isWaiting) {
      messageId = 'APPOINTMENTS.TEXT.EDIT-WAITING-APPOINTMENT';
    } else {
      messageId = 'APPOINTMENTS.TEXT.EDIT-APPOINTMENT';
    }
  } else {
    if (isWaiting) {
      messageId = 'APPOINTMENTS.TEXT.ADD-WAITING-APPOINTMENT';
    } else if (isNoTime) {
      messageId = 'APPOINTMENTS.TEXT.ADD-NO-TIME-APPOINTMENT';
    } else {
      messageId = isSlotFree ? 'APPOINTMENTS.TEXT.ADD-APPOINTMENT' : 'APPOINTMENTS.TEXT.ADD-PHANTOM-APPOINTMENT';
    }
  }

  return useMemo(() => formatMessage({ id: messageId }), [formatMessage, messageId]);
};
