import { useCallback } from 'react';
import { useAppIntl, useAppDispatch } from 'app/helpers';
import { toggleModal } from 'app/redux/modals/modals.actions';
import { setReferralId } from 'app/redux/referrals/referrals.actions';
import { Button } from 'reactstrap';

interface Props {
  referralId: string | null;
}

export const AppointmentReferral = ({ referralId }: Props) => {
  const dispatch = useAppDispatch();
  const { formatMessage } = useAppIntl();

  const openReferralDetails = useCallback(() => {
    if (referralId) {
      dispatch(setReferralId(referralId));
      dispatch(toggleModal('appointmentTicket', false));
      dispatch(toggleModal('referralDetails', true));
    }
  }, [referralId, dispatch]);

  return (
    <Button className="mb-0" size="sm" color="light" onClick={openReferralDetails}>
      {formatMessage({ id: 'CORE.BUTTON.SHOW' })}
    </Button>
  );
};
