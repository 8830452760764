import React from 'react';
import PropTypes from 'prop-types';
import { Row, Col } from 'reactstrap';
import { FormattedMessage } from 'react-intl';
import { BreadcrumbItemsType } from './breadcrumb/breadcrumb';
import './page-header.scss';

export const PageHeaderComponent = (props) => {
  const colSize = props.children ? 6 : 12;

  return (
    <div className="page-header">
      <Row>
        <Col md={colSize}>
          <h2 className="page-header__title">
            <FormattedMessage id={props.titleTranslationKey} />
          </h2>
        </Col>
        {props.children && (
          <Col md={colSize}>
            <div className="page-header__actions text-nowrap">{props.children}</div>
          </Col>
        )}
      </Row>
      {/*{props.breadcrumbItems?.length > 0 && <Breadcrumb items={props.breadcrumbItems} />}*/}
    </div>
  );
};

PageHeaderComponent.propTypes = {
  breadcrumbItems: BreadcrumbItemsType,
  children: PropTypes.element,
  titleTranslationKey: PropTypes.string.isRequired,
};
