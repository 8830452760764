import { useMemo } from 'react';
import { useIntl } from 'react-intl';
import { Locale } from 'app/types';
import { checkIsRtl } from 'app/helpers/checkIsRtl/checkIsRtl';

export const useAppIntl = () => {
  const intl = useIntl();

  return useMemo(
    () => ({
      formatMessage: intl.formatMessage,
      isRtl: checkIsRtl(intl.locale),
      locale: intl.locale as Locale,
    }),
    [intl],
  );
};
