export const appointmentTab = {
  today: 'today',
  upcoming: 'upcoming',
  past: 'past',
  waiting: 'waiting',
  pastWaiting: 'past-waiting',
} as const;

export const appointmentsPathCore = '/appointments';
export const generalAppointmentsPath = `${appointmentsPathCore}/:tab(${Object.values(appointmentTab).join('|')})`;
export const branchAppointmentsPath = `${appointmentsPathCore}/branch/:branchId/:tab(${Object.values(
  appointmentTab,
).join('|')})`;
export const patientAppointmentsPath = `${appointmentsPathCore}/patient/:patientId/scope/:scope/:tab(${Object.values(
  appointmentTab,
).join('|')})`;
export const patientStatisticsAppointmentsPath = `${appointmentsPathCore}/patient/:patientId/scope/:scope/mode/:mode/source/:source?`;
export const doctorAppointmentsPath = `${appointmentsPathCore}/doctor/:doctorId/:tab(${Object.values(
  appointmentTab,
).join('|')})`;
export const conflictingAppointmentsPath = `${appointmentsPathCore}/conflicting/branch/:branchId/doctor/:doctorId/:tab(${Object.values(
  appointmentTab,
).join('|')})`;
