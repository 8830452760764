import { useEffect } from 'react';

export const useSilentRefresh = (refreshData: () => void, seconds: number = 60) => {
  useEffect(() => {
    const timer = setInterval(() => {
      refreshData();
    }, seconds * 1000);

    return () => clearTimeout(timer);
  }, [refreshData, seconds]);
};
