import React, { useCallback, useState, useMemo } from 'react';
import { Modal, ModalHeader, ModalFooter, ModalBody, Row, FormGroup, Label, Input, Col, Button } from 'reactstrap';
import { useAppSelector, useModalCleaner, useAppDispatch, useAppIntl, isWithinAgeRestrictions } from 'app/helpers';
import { toggleModal } from 'app/redux/modals/modals.actions';
import { List } from 'app/features/patient/changeAppointmentPatientModal/list/list';
import { AppointmentAgeRestrictions } from 'app/appointment/form/types';
import { BookingRestrictionsStatus } from 'app/shared/patientSearch/patientSelectedDetails/bookingRestrictionStatus/bookingRestrictionsStatus';
import { ClinicPatientShortInfo } from 'app/services/patients/types';

interface Props {
  ageRestrictions?: AppointmentAgeRestrictions | null;
  patientId: string;
  mobileNumber: string;
  onPatientSelected: (data: ClinicPatientShortInfo) => void;
}

export const ChangeAppointmentPatientModal = ({
  ageRestrictions,
  patientId,
  mobileNumber,
  onPatientSelected,
}: Props) => {
  const dispatch = useAppDispatch();
  const { formatMessage } = useAppIntl();
  const [selectedPatient, setSelectedPatient] = useState<ClinicPatientShortInfo | null>(null);

  const isOpen = useAppSelector((state) => state.modal.changeAppointmentPatient.isOpen);
  const canUsePatient = useMemo(
    () =>
      isWithinAgeRestrictions({
        age: selectedPatient?.age,
        min: ageRestrictions?.acceptablePatientAgeFrom,
        max: ageRestrictions?.acceptablePatientAgeTo,
      }),
    [ageRestrictions, selectedPatient],
  );

  // Handlers
  const onToggle = useCallback(() => {
    dispatch(toggleModal('changeAppointmentPatient'));
    setSelectedPatient(null);
  }, [dispatch]);

  const selectionChanged = useCallback((data: ClinicPatientShortInfo | null) => {
    setSelectedPatient(data);
  }, []);

  const handleConfirmSelection = useCallback(() => {
    if (selectedPatient) {
      onPatientSelected(selectedPatient);
    }
    setSelectedPatient(null);
  }, [selectedPatient, onPatientSelected]);

  useModalCleaner('changeAppointmentPatient');

  return (
    <Modal backdrop="static" keyboard={false} isOpen={isOpen} size="xl" toggle={onToggle}>
      <ModalHeader toggle={onToggle}>
        {formatMessage({ id: 'CORE.LABEL.RELATED-PATIENTS', defaultMessage: 'Related Patients' })}
      </ModalHeader>
      <ModalBody>
        <Row>
          <Col md={6}>
            <FormGroup>
              <Label for="mobileNumber">{formatMessage({ id: 'CORE.LABEL.MOBILE-NUMBER' })}</Label>
              <Input id="mobileNumber" name="mobileNumber" type="text" value={mobileNumber} disabled />
            </FormGroup>
          </Col>
        </Row>
        <h6 className="my-3">
          {formatMessage({
            id: 'CORE.LABEL.CHOOSE-PATIENT-FOR-APPOINTMENT',
            defaultMessage: 'Choose the patient for the appointment',
          })}
        </h6>
        <BookingRestrictionsStatus doctor={ageRestrictions} patient={selectedPatient} />
        <List patientId={patientId} phoneNumber={mobileNumber} selectionChanged={selectionChanged} />
      </ModalBody>
      <ModalFooter>
        <Button type="button" color="secondary" onClick={onToggle}>
          {formatMessage({ id: 'CORE.BUTTON.CANCEL' })}
        </Button>
        <Button
          disabled={!selectedPatient || !canUsePatient}
          type="submit"
          color="primary-gradient"
          onClick={handleConfirmSelection}
        >
          {formatMessage({ id: 'CORE.BUTTON.CONFIRM' })}
        </Button>
      </ModalFooter>
    </Modal>
  );
};
