import toast from 'react-hot-toast';
import { useCallback, useMemo } from 'react';
import { FormSubmitHandler } from 'redux-form/lib/reduxForm';
import { generateTemporaryId } from 'app/helpers';
import { FORMS_INVOICING_PROCESS_ADD_PAYMENT_METHOD } from 'app/shared';
import { addPaymentMethodItem } from 'app/redux/invoicingProcess/invoicingProcess.actions';
import { useLeftToPay } from 'app/features/invoicingProcess/process/paymentMethod/helpers/useLeftToPay';
import { PaymentMethodFormData } from 'app/features/invoicingProcess/process/paymentMethod/paymentMethodForm/types';
import { PaymentMethodForm } from 'app/features/invoicingProcess/process/paymentMethod/paymentMethodForm/paymentMethodForm';

export const AddPaymentMethodForm = () => {
  const leftToPay = useLeftToPay();

  const initialValues = useMemo<Partial<PaymentMethodFormData>>(
    () => ({
      amount: String(leftToPay),
    }),
    [leftToPay],
  );

  const onAddPaymentMethod = useCallback<FormSubmitHandler<PaymentMethodFormData>>((values, dispatch, props) => {
    dispatch(
      addPaymentMethodItem({
        amount: Number(values.amount),
        creditNotePayment: false,
        id: generateTemporaryId(),
        isDefault: false,
        notes: values.notes || null,
        otherPaymentMethod: values.otherPaymentMethod,
        paymentMethod: values.paymentMethod,
        timeOfCreation: new Date().toISOString(),
      }),
    );
    props.reset && props.reset();
    toast.success('CORE.TEXT.ITEM-ADDED-SUCCESSFULLY');
  }, []);

  return (
    <PaymentMethodForm
      form={FORMS_INVOICING_PROCESS_ADD_PAYMENT_METHOD}
      initialValues={initialValues}
      onSubmit={onAddPaymentMethod}
    />
  );
};
