import { useCallback } from 'react';
import { DropdownParams } from 'app/types';
import { fetchDropdownOptions } from 'app/redux/dropdownOptions/dropdownOptions.actions';
import { useAppDispatch } from 'app/helpers';

interface Props {
  clinicId?: string;
  patientId?: string;
}

export const useFetchOptions = ({ clinicId, patientId }: Props) => {
  const dispatch = useAppDispatch();

  const fetchPhoneNumbers = useCallback(
    (params: DropdownParams) => {
      if (patientId && clinicId) {
        return dispatch(
          fetchDropdownOptions(`clinics/${clinicId}/clinic-patients/${patientId}/phone-numbers/dropdown`, params),
        );
      }
      return [];
    },
    [dispatch, clinicId, patientId],
  );

  const fetchGuardian = useCallback(
    (params: DropdownParams) => {
      if (patientId) {
        return dispatch(fetchDropdownOptions(`dependants/clinic-patients/${patientId}/guardians/dropdown`, params));
      }
      return [];
    },
    [dispatch, patientId],
  );

  return {
    fetchPhoneNumbers,
    fetchGuardian,
  };
};
