import { AppointmentType, AppointmentTimeType } from 'app/types';

enum AppointmentsEndpointSourceType {
  Branch = 'branch',
  Doctor = 'doctor',
  Patient = 'patient',
}

export const generateAppointmentsEndpoint = (
  pathname: string | undefined,
  type: AppointmentType,
  timeType: AppointmentTimeType,
): string => {
  if (pathname) {
    const hasBranchId = pathname.includes(AppointmentsEndpointSourceType.Branch);
    const hasDoctorId = pathname.includes(AppointmentsEndpointSourceType.Doctor);
    const hasPatientId = pathname.includes(AppointmentsEndpointSourceType.Patient);

    if (hasBranchId || hasDoctorId || hasPatientId) {
      let source = '';

      if (hasBranchId) {
        source = AppointmentsEndpointSourceType.Branch;
      }

      if (hasDoctorId) {
        source = AppointmentsEndpointSourceType.Doctor;
      }

      if (hasPatientId) {
        source = AppointmentsEndpointSourceType.Patient;
      }

      const idBase = pathname.match(`${source}/(.*)`);

      if (idBase) {
        let id = idBase[1];

        if (id.includes('/')) {
          id = id.substring(0, id.indexOf('/'));
        }

        if (timeType === AppointmentTimeType.Current && hasBranchId) {
          return `/${type}/${timeType}`;
        }

        return `/${type}/${source}/${id}/${timeType}`;
      }
    }
  }

  return `/${type}/${timeType}`;
};
