import { useColumns, useUnstyledColumns } from 'app/assessment/medicalProfileSection/components/helpers';

export const useColumnsHelper = () => {
  const chiefComplaintColumns = useUnstyledColumns({
    dataField: 'chiefComplaint',
    text: 'TREATMENT-PLAN.LABEL.CHIEF-COMPLAINT',
    modalColumns: useColumns({
      dataField: 'chiefComplaint',
      text: 'TREATMENT-PLAN.LABEL.CHIEF-COMPLAINT',
    }),
  });
  const presentIllnessHistoryColumns = useUnstyledColumns({
    dataField: 'presentIllnessHistory',
    text: 'TREATMENT-PLAN.LABEL.HISTORY-OF-PRESENT-ILLNESS',
    modalColumns: useColumns({
      dataField: 'presentIllnessHistory',
      text: 'TREATMENT-PLAN.LABEL.HISTORY-OF-PRESENT-ILLNESS',
    }),
  });
  const assessmentColumns = useUnstyledColumns({
    dataField: 'assessment',
    text: 'TREATMENT-PLAN.TEXT.ASSESSMENT',
    modalColumns: useColumns({
      dataField: 'assessment',
      text: 'TREATMENT-PLAN.TEXT.ASSESSMENT',
    }),
  });
  const medicalConditionColumns = useUnstyledColumns({
    dataField: 'medicalCondition',
    text: 'TREATMENT-PLAN.LABEL.MEDICAL-CONDITION',
    modalColumns: useColumns({
      dataField: 'medicalCondition',
      text: 'TREATMENT-PLAN.LABEL.MEDICAL-CONDITION',
    }),
  });
  const systemReviewColumns = useUnstyledColumns({
    dataField: 'systemReview',
    text: 'TREATMENT-PLAN.LABEL.REVIEW-OF-SYSTEM',
    modalColumns: useColumns({
      dataField: 'systemReview',
      text: 'TREATMENT-PLAN.LABEL.REVIEW-OF-SYSTEM',
    }),
  });
  const clinicalExaminationColumns = useUnstyledColumns({
    dataField: 'clinicalExamination',
    text: 'TREATMENT-PLAN.LABEL.CLINICAL-EXAMINATION',
    modalColumns: useColumns({
      dataField: 'clinicalExamination',
      text: 'TREATMENT-PLAN.LABEL.CLINICAL-EXAMINATION',
    }),
  });
  const otherCondition = useUnstyledColumns({
    dataField: 'condition',
    text: 'TREATMENT-PLAN.LABEL.CONDITION',
    modalColumns: useColumns({
      dataField: 'condition',
      text: 'TREATMENT-PLAN.LABEL.CONDITION',
    }),
  });
  const commentColumns = useUnstyledColumns({
    dataField: 'comment',
    text: 'TREATMENT-PLAN.TABLE.COMMENTS',
    modalColumns: useColumns({
      dataField: 'comment',
      text: 'TREATMENT-PLAN.TABLE.COMMENTS',
    }),
  });
  const procedureUpdateColumns = useUnstyledColumns({
    dataField: 'procedureUpdate',
    text: 'TREATMENT-PLAN.LABEL.PROCEDURE-UPDATE',
    modalColumns: useColumns({
      dataField: 'procedureUpdate',
      text: 'TREATMENT-PLAN.LABEL.PROCEDURE-UPDATE',
    }),
  });
  const healthEducationColumns = useUnstyledColumns({
    dataField: 'healthEducation',
    text: 'TREATMENT-PLAN.LABEL.HEALTH-EDUCATION',
    modalColumns: useColumns({
      dataField: 'healthEducation',
      text: 'TREATMENT-PLAN.LABEL.HEALTH-EDUCATION',
    }),
  });

  return {
    assessmentColumns,
    chiefComplaintColumns,
    clinicalExaminationColumns,
    commentColumns,
    otherCondition,
    healthEducationColumns,
    medicalConditionColumns,
    presentIllnessHistoryColumns,
    procedureUpdateColumns,
    systemReviewColumns,
  };
};
