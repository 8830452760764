import { Button } from 'reactstrap';
import { formattedDate, useAppIntl, useAppSelector } from 'app/helpers';
import { CalendarDay } from 'app/types';

interface Props {
  day: CalendarDay;
  isWithNoTimeMode: boolean;
  manageWaiting: (date: string) => void;
}

export const TimeFormatterHeader = ({ day, isWithNoTimeMode, manageWaiting }: Props) => {
  const { formatMessage } = useAppIntl();
  const disabled = useAppSelector((state) => state.appointment.fullEdit.isActive);

  return (
    <div className="timeFormatter__header">
      <h5 className="font-weight-normal mt-2">
        {formatMessage({ id: 'CALENDAR-TABLE.TEXT.SELECTED-DAY' })}: {formattedDate(day.date)}
      </h5>
      {!isWithNoTimeMode && (
        <Button color="warning" disabled={disabled} size="sm" onClick={() => manageWaiting(day.date)}>
          {formatMessage({ id: 'APPOINTMENTS.TEXT.MANAGE-WAITING' })}
          {` (${day.waitingAppointmentsCount}/${day.waitingAppointmentsLimit})`}
        </Button>
      )}
    </div>
  );
};
