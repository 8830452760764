import { ErrorCode } from 'react-dropzone';

export const getErrorMessage = (errorCode: ErrorCode | string): string => {
  // File too large error occurred
  if (errorCode === ErrorCode.FileTooLarge) {
    return 'ERRORS.TOO-LARGE-FILE';
  }

  // Invalid file type error occurred
  if (errorCode === ErrorCode.FileInvalidType) {
    return 'ERRORS.INVALID-FILE-TYPE';
  }

  return '';
};
