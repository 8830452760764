import React, { useCallback } from 'react';
import { FormGroup } from 'reactstrap';
import PropTypes from 'prop-types';
import { LANGUAGES_AR_CODE, LANGUAGES_EN_CODE, PAGINATION_DEFAULT_PARAMS } from 'app/shared/index';
import { checkIsRtl, useAppIntl } from 'app/helpers';
import { AsyncSelect } from 'app/components';
import { CustomLabel } from 'app/components/customLabel/customLabel';
import { Footer } from 'app/shared/form-elements/select/shared/footer/footer';
import { useOptionDisabled } from 'app/shared/form-elements/select/helpers';

export const defaultAdditional = {
  pageNumber: 1,
};

export const getAlternativeLocale = (locale) => (locale === LANGUAGES_AR_CODE ? LANGUAGES_EN_CODE : LANGUAGES_AR_CODE);

export const AsyncSelectComponent = ({
  cacheUniqs,
  defaultOptions,
  disabled = false,
  fetchOptions,
  filterOption,
  formatOptionLabel,
  id,
  info,
  input: { name, onChange, value },
  isClearable = false,
  isMulti = false,
  isOptionDisabled,
  isRequired = false,
  isSearchable = true,
  label,
  maxLength,
  meta: { error, touched },
  optionsLimit,
  placeholder,
  showClinicyId = true,
  size,
  text,
}) => {
  const { formatMessage, locale } = useAppIntl();
  const fieldId = (id || name).split('.').join('');
  const isRtl = checkIsRtl(locale);
  const invalid = !!error && touched;
  const defaultIsOptionDisabled = useOptionDisabled(value, optionsLimit);

  const loadOptions = useCallback(
    async (keyword, prevOptions, { pageNumber }) => {
      const res = await fetchOptions({
        ...(keyword && { keyword }),
        pageNumber,
        pageSize: PAGINATION_DEFAULT_PARAMS.pageSize,
      });

      const options = res?.payload?.data;
      const pagination = res?.payload?.pagination;

      if (options && pagination) {
        const hasMore = pagination.totalItemCount > prevOptions.length + PAGINATION_DEFAULT_PARAMS.pageSize;
        return {
          options,
          hasMore,
          additional: {
            pageNumber: pageNumber + 1,
          },
        };
      }

      return { options: options || [], hasMore: false };
    },
    [fetchOptions],
  );

  const defaultFormatOptionLabel = useCallback(
    (option) => (
      <div className="d-flex align-items-center justify-content-between">
        <span>
          {option?.translation
            ? option.translation[locale] || option.translation[getAlternativeLocale(locale)] || option.label
            : option.label}
        </span>
        {showClinicyId && option.clinicyId && (
          <>
            <span>&nbsp;</span>
            <small className="text-nowrap font-weight-light">{option.clinicyId}</small>
          </>
        )}
      </div>
    ),
    [locale, showClinicyId],
  );

  return (
    <FormGroup>
      <CustomLabel fieldId={fieldId} info={info} isRequired={isRequired} label={label} />
      <AsyncSelect
        additional={defaultAdditional}
        cacheUniqs={cacheUniqs}
        closeMenuOnSelect={!isMulti}
        debounceTimeout={300}
        defaultOptions={defaultOptions}
        formatOptionLabel={formatOptionLabel || defaultFormatOptionLabel}
        id={fieldId}
        inputId={`${fieldId}Input`}
        invalid={invalid}
        isClearable={isClearable}
        isDisabled={disabled}
        isMulti={isMulti}
        isOptionDisabled={isOptionDisabled || defaultIsOptionDisabled}
        isRtl={isRtl}
        isSearchable={isSearchable}
        loadOptions={loadOptions}
        onChange={onChange}
        placeholder={placeholder}
        size={size}
        value={value}
        filterOption={filterOption}
        maxLength={maxLength}
      />
      <Footer error={error} formatMessage={formatMessage} text={text} />
    </FormGroup>
  );
};

AsyncSelectComponent.propTypes = {
  cacheUniqs: PropTypes.any,
  defaultOptions: PropTypes.oneOfType([PropTypes.bool, PropTypes.arrayOf(PropTypes.object)]),
  disabled: PropTypes.bool,
  fetchOptions: PropTypes.func.isRequired,
  filterOption: PropTypes.func,
  formatOptionLabel: PropTypes.func,
  id: PropTypes.string,
  info: PropTypes.string,
  input: PropTypes.object.isRequired,
  isClearable: PropTypes.bool,
  isMulti: PropTypes.bool,
  isOptionDisabled: PropTypes.func,
  isRequired: PropTypes.bool,
  isSearchable: PropTypes.bool,
  label: PropTypes.string.isRequired,
  meta: PropTypes.object.isRequired,
  optionsLimit: PropTypes.number,
  placeholder: PropTypes.string,
  showClinicyId: PropTypes.bool,
  size: PropTypes.oneOf(['sm', 'lg']),
  text: PropTypes.string,
};
