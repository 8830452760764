import isNil from 'lodash/isNil';
import omitBy from 'lodash/omitBy';
import isEmpty from 'lodash/isEmpty';
import debounce from 'lodash/debounce';
import { useCallback, useMemo } from 'react';
import { ConfigProps, FormSubmitHandler } from 'redux-form/lib/reduxForm';
import type { QueryParams } from 'app/types';
import { defaultValues } from 'app/constants';
import { getCoreQueryParams } from 'app/helpers/queryParams/queryParams';

const isNilOrEmptyString = (p: string | null | undefined): boolean => isNil(p) || p === '';

export function useFilterFormActions<V = {} | undefined, P = {}>(
  params: QueryParams | undefined,
  setParams: (newParams?: QueryParams) => void,
  formValuesAdapter: (values: V) => QueryParams,
) {
  const handleOnChange: ConfigProps<V, P>['onChange'] = useCallback(
    (values, dispatch, props, previousValues) => {
      const isInitialChange = isEmpty(previousValues) && !props.dirty;

      setParams(
        omitBy(
          {
            ...getCoreQueryParams(params),
            ...formValuesAdapter(values),
            ...(!isInitialChange && { pageNumber: '1' }),
          },
          isNilOrEmptyString,
        ),
      );
    },
    [formValuesAdapter, params, setParams],
  );

  const handleOnSubmit: FormSubmitHandler<V, P> = useCallback(
    (values) => {
      setParams(
        omitBy(
          {
            ...getCoreQueryParams(params),
            ...formValuesAdapter(values),
            pageNumber: '1',
          },
          isNilOrEmptyString,
        ),
      );
    },
    [formValuesAdapter, params, setParams],
  );

  const onChange = useMemo(() => debounce(handleOnChange, defaultValues.formDebounceWait), [handleOnChange]);

  const onSubmit = useMemo(
    () => debounce(handleOnSubmit, defaultValues.formDebounceWait, { leading: true, trailing: false }),
    [handleOnSubmit],
  );

  return {
    onChange,
    onSubmit,
  };
}
