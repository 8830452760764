import { useEffect, useState } from 'react';

export const useInitialFetch = (fetchData: () => void) => {
  const [initialized, setInitialized] = useState(false);

  useEffect(() => {
    if (!initialized) {
      setInitialized(true);
      fetchData();
    }
  }, [initialized, fetchData]);
};
