import { takeEvery, put, all, take } from 'redux-saga/effects';
import { startSubmit, stopSubmit } from 'redux-form';

import {
  EDIT_NOTIFICATION_SCENARIO,
  EDIT_NOTIFICATION_SCENARIO_CONTENT_SUCCESS,
  EDIT_NOTIFICATION_SCENARIO_CONTENT_FAILURE,
  EDIT_NOTIFICATION_SCHEDULED_SUCCESS,
} from 'app/redux/notifications/notifications.types';
import {
  editNotificationScenarioContent,
  fetchNotificationScheduled,
} from 'app/redux/notifications/notifications.actions';

function* editNotificationScenario({ payload: { arabicContent, englishContent, formId } }) {
  yield put(startSubmit(formId));

  const allKeys = Object.keys(arabicContent);
  const actions = allKeys.map((key) =>
    put(
      editNotificationScenarioContent({ arabicContent: arabicContent[key], englishContent: englishContent[key] }, key),
    ),
  );

  yield all(actions);

  yield all(
    allKeys.map(() => take([EDIT_NOTIFICATION_SCENARIO_CONTENT_SUCCESS, EDIT_NOTIFICATION_SCENARIO_CONTENT_FAILURE])),
  );

  yield put({
    type: 'Notifications',
    meta: {
      notification: {
        type: 'success',
        title: 'NOTIFICATIONS.TEXT.CONTENT-SAVE-SUCCESS',
      },
    },
  });

  yield put(stopSubmit(formId));
}

function* refreshScheduled() {
  yield put(fetchNotificationScheduled());
}

function* notificationSaga() {
  yield takeEvery(EDIT_NOTIFICATION_SCENARIO, editNotificationScenario);
  yield takeEvery(EDIT_NOTIFICATION_SCHEDULED_SUCCESS, refreshScheduled);
}

export default notificationSaga;
