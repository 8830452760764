export const FETCH_REQUESTS_LIST_REQUEST = 'requests/fetch/REQUEST';
export const FETCH_REQUESTS_LIST_SUCCESS = 'requests/fetch/SUCCESS';
export const FETCH_REQUESTS_LIST_FAILURE = 'requests/fetch/FAILURE';

export const FETCH_REQUESTS_HISTORY_LIST_REQUEST = 'requestsHistory/fetch/REQUEST';
export const FETCH_REQUESTS_HISTORY_LIST_SUCCESS = 'requestsHistory/fetch/SUCCESS';
export const FETCH_REQUESTS_HISTORY_LIST_FAILURE = 'requestsHistory/fetch/FAILURE';

export const FETCH_REQUEST_REQUEST = 'clinicRequest/fetch/REQUEST';
export const FETCH_REQUEST_SUCCESS = 'clinicRequest/fetch/SUCCESS';
export const FETCH_REQUEST_FAILURE = 'clinicRequest/fetch/FAILURE';

export const REQUEST_APPROVE_REQUEST = 'clinicRequestApprove/fetch/REQUEST';
export const REQUEST_APPROVE_SUCCESS = 'clinicRequestApprove/fetch/SUCCESS';
export const REQUEST_APPROVE_FAILURE = 'clinicRequestApprove/fetch/FAILURE';

export const REQUEST_CANCEL_REQUEST = 'requests/REQUEST_CANCEL_REQUEST';
export const REQUEST_CANCEL_SUCCESS = 'requests/REQUEST_CANCEL_SUCCESS';
export const REQUEST_CANCEL_FAILURE = 'requests/REQUEST_CANCEL_FAILURE';

export const REQUEST_REJECT_REQUEST = 'clinicRequestReject/fetch/REQUEST';
export const REQUEST_REJECT_SUCCESS = 'clinicRequestReject/fetch/SUCCESS';
export const REQUEST_REJECT_FAILURE = 'clinicRequestReject/fetch/FAILURE';

export const REQUEST_EDIT_REQUEST = 'clinicRequestEDIT/fetch/REQUEST';
export const REQUEST_EDIT_SUCCESS = 'clinicRequestEDIT/fetch/SUCCESS';
export const REQUEST_EDIT_FAILURE = 'clinicRequestEDIT/fetch/FAILURE';

export const SET_MAIN_PARAMS = 'requests/SET_MAIN_PARAMS';
export const SET_HISTORY_PARAMS = 'requestsHistory/SET_HISTORY_PARAMS';
