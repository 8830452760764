import { createAction, RSAA } from 'redux-api-middleware';
import { MedicalAttachment } from 'app/types';
import { AddFamilyIllnessBody, AddIllnessBody, AddSurgeryBody, SpecialtyNoteBody } from 'app/redux/treatment/types';
import * as types from 'app/redux/treatment/treatment.types';

export const fetchAppointmentTreatmentPlan = (appointmentId: string, isSilent?: boolean) => ({
  [RSAA]: {
    endpoint: `/appointments/${appointmentId}/treatment-plan`,
    method: 'GET',
    types: [
      {
        type: types.FETCH_APPOINTMENT_TREATMENT_PLAN_REQUEST,
        meta: { isSilent },
      },
      types.FETCH_APPOINTMENT_TREATMENT_PLAN_SUCCESS,
      types.FETCH_APPOINTMENT_TREATMENT_PLAN_FAILURE,
    ],
  },
});

export const fetchGeneralTreatmentInformation = (appointmentId: string, previousDoctorAssessmentId?: string) => ({
  [RSAA]: {
    endpoint: `/appointments/${appointmentId}/general-treatment-information`,
    method: 'GET',
    types: [
      types.FETCH_GENERAL_TREATMENT_INFO_REQUEST,
      types.FETCH_GENERAL_TREATMENT_INFO_SUCCESS,
      types.FETCH_GENERAL_TREATMENT_INFO_FAILURE,
    ],
    params: { previousDoctorAssessmentId },
  },
});

export const fetchPatientGeneralTreatment = (
  clinicId: string,
  patientId: string,
  branchId?: string,
  practitionerId?: string,
  dateFrom?: string,
  dateTo?: string,
) => ({
  [RSAA]: {
    endpoint: `/clinics/${clinicId}/patients/${patientId}/treatment-plan`,
    method: 'GET',
    types: [
      types.FETCH_PATIENT_TREATMENT_PLAN_REQUEST,
      types.FETCH_PATIENT_TREATMENT_PLAN_SUCCESS,
      types.FETCH_PATIENT_TREATMENT_PLAN_FAILURE,
    ],
    params: { branchId, practitionerId, dateFrom, dateTo },
  },
});

export const fetchClinicPatientTreatment = (
  patientId: string,
  branchId?: string,
  practitionerId?: string,
  dateFrom?: string,
  dateTo?: string,
) => ({
  [RSAA]: {
    endpoint: `/clinic-patients/${patientId}/treatment-plan`,
    method: 'GET',
    types: [
      types.FETCH_PATIENT_TREATMENT_PLAN_REQUEST,
      types.FETCH_PATIENT_TREATMENT_PLAN_SUCCESS,
      types.FETCH_PATIENT_TREATMENT_PLAN_FAILURE,
    ],
    params: { branchId, practitionerId, dateFrom, dateTo },
  },
});

export const fetchPatientAttachment = (attachmentId: string) => ({
  [RSAA]: {
    endpoint: `/attachments/${attachmentId}`,
    method: 'GET',
    types: [
      types.FETCH_PATIENT_ATTACHMENT_REQUEST,
      types.FETCH_PATIENT_ATTACHMENT_SUCCESS,
      types.FETCH_PATIENT_ATTACHMENT_FAILURE,
    ],
  },
});

export const deletePatientAttachment = (attachmentId: string) =>
  createAction({
    endpoint: `/attachments/${attachmentId}`,
    method: 'DELETE',
    types: [
      types.DELETE_PATIENT_ATTACHMENT_REQUEST,
      types.DELETE_PATIENT_ATTACHMENT_SUCCESS,
      types.DELETE_PATIENT_ATTACHMENT_FAILURE,
    ],
  });

export const fetchPatientAllergy = (patientId: string, patientAllergyId: string) => ({
  [RSAA]: {
    endpoint: `/patients/${patientId}/patient-allergies/${patientAllergyId}`,
    method: 'GET',
    types: [
      types.FETCH_PATIENT_ALLERGY_REQUEST,
      types.FETCH_PATIENT_ALLERGY_SUCCESS,
      types.FETCH_PATIENT_ALLERGY_FAILURE,
    ],
  },
});

export const fetchPatientPrescription = (prescriptionId: string) => {
  return {
    [RSAA]: {
      types: [
        types.FETCH_PATIENT_PRESCRIPTION_REQUEST,
        types.FETCH_PATIENT_PRESCRIPTION_SUCCESS,
        types.FETCH_PATIENT_PRESCRIPTION_FAILURE,
      ],
      endpoint: `/prescriptions/${prescriptionId}`,
      method: 'GET',
    },
  };
};

export const addPatientIllness = (body: AddIllnessBody, patientId: string) =>
  createAction({
    endpoint: `/${patientId}/patient-illnesses`,
    method: 'POST',
    types: [types.ADD_PATIENT_ILLNESS_REQUEST, types.ADD_PATIENT_ILLNESS_SUCCESS, types.ADD_PATIENT_ILLNESS_FAILURE],
    // @ts-ignore custom body middleware
    body,
  });

export const deletePatientIllness = (id: string, patientId: string) =>
  createAction({
    endpoint: `/${patientId}/patient-illnesses/${id}`,
    method: 'DELETE',
    types: [
      types.DELETE_PATIENT_ILLNESS_REQUEST,
      types.DELETE_PATIENT_ILLNESS_SUCCESS,
      types.DELETE_PATIENT_ILLNESS_FAILURE,
    ],
  });

export const addFamilyIllness = (body: AddFamilyIllnessBody, patientId: string) =>
  createAction({
    endpoint: `/${patientId}/family-illnesses`,
    method: 'POST',
    types: [types.ADD_FAMILY_ILLNESS_REQUEST, types.ADD_FAMILY_ILLNESS_SUCCESS, types.ADD_FAMILY_ILLNESS_FAILURE],
    // @ts-ignore custom body middleware
    body,
  });

export const deleteFamilyIllness = (id: string, patientId: string) =>
  createAction({
    endpoint: `/${patientId}/family-illnesses/${id}`,
    method: 'DELETE',
    types: [
      types.DELETE_FAMILY_ILLNESS_REQUEST,
      types.DELETE_FAMILY_ILLNESS_SUCCESS,
      types.DELETE_FAMILY_ILLNESS_FAILURE,
    ],
  });

export const addSurgery = (body: AddSurgeryBody, patientId: string) =>
  createAction({
    endpoint: `/${patientId}/patient-surgeries`,
    method: 'POST',
    types: [types.ADD_SURGERY_REQUEST, types.ADD_SURGERY_SUCCESS, types.ADD_SURGERY_FAILURE],
    // @ts-ignore custom body middleware
    body,
  });

export const deleteSurgery = (id: string, patientId: string) =>
  createAction({
    endpoint: `/${patientId}/patient-surgeries/${id}`,
    method: 'DELETE',
    types: [types.DELETE_SURGERY_REQUEST, types.DELETE_SURGERY_SUCCESS, types.DELETE_SURGERY_FAILURE],
  });

export const setAttachment = (attachment: MedicalAttachment) => ({
  type: types.SET_ATTACHMENT,
  payload: { attachment },
});

export const setAddPrescriptionIsRemoteMode = (isRemoteAddMode: boolean) => ({
  type: types.SET_ADD_PRESCRIPTION_REMOTE_MODE,
  payload: isRemoteAddMode,
});

export const resetTreatmentPlanData = () => ({
  type: types.RESET_APPOINTMENT_TREATMENT_PLAN,
});

export const addSpecialtyNote = (body: SpecialtyNoteBody, patientId: string) =>
  createAction({
    endpoint: `/${patientId}/patient-specialty-notes`,
    method: 'POST',
    types: [types.ADD_SPECIALTY_NOTE_REQUEST, types.ADD_SPECIALTY_NOTE_SUCCESS, types.ADD_SPECIALTY_NOTE_FAILURE],
    // @ts-ignore custom body middleware
    body,
  });

export const editSpecialtyNote = (body: SpecialtyNoteBody, id: string, patientId: string) =>
  createAction({
    endpoint: `/${patientId}/patient-specialty-notes/${id}`,
    method: 'PUT',
    types: [types.EDIT_SPECIALTY_NOTE_REQUEST, types.EDIT_SPECIALTY_NOTE_SUCCESS, types.EDIT_SPECIALTY_NOTE_FAILURE],
    // @ts-ignore custom body middleware
    body,
  });

export const deleteSpecialtyNote = (id: string, patientId: string) =>
  createAction({
    endpoint: `/${patientId}/patient-specialty-notes/${id}`,
    method: 'DELETE',
    types: [
      types.DELETE_SPECIALTY_NOTE_REQUEST,
      types.DELETE_SPECIALTY_NOTE_SUCCESS,
      types.DELETE_SPECIALTY_NOTE_FAILURE,
    ],
  });
