import { WorkingTime } from 'app/redux/bookingWizard/types';
import { Option, PatientSourceOption } from 'app/types';
import { PatientSearchFormData } from 'app/shared/patientSearch/types';

export const BookingFormField = {
  workingTime: 'workingTime',
  start: 'start',
  end: 'end',
  date: 'date',
  isNoTime: 'isNoTime',
  patientId: 'patientId',
  note: 'note',
  patientSource: 'patientSource',
  referralId: 'referralId',
} as const;

export interface BookingFormData extends PatientSearchFormData {
  [BookingFormField.workingTime]: Option<WorkingTime> | null;
  [BookingFormField.start]: Date;
  [BookingFormField.end]?: Date;
  [BookingFormField.date]: Date;
  [BookingFormField.isNoTime]?: boolean;
  [BookingFormField.note]: string | null;
  [BookingFormField.referralId]: string | null;
  [BookingFormField.patientSource]?: PatientSourceOption;
}

export interface InitialBookingData {
  branchId: string;
  doctorId: string;
  appointmentId?: string;
  start: Date;
  end?: Date;
  workingTimeId?: string;
  isWaiting: boolean;
}
