import React, { useCallback, useMemo, useRef } from 'react';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import ReactToPrint from 'react-to-print';
import { submit } from 'redux-form';
import { PrintTemplate } from 'app/components';
import { useAppDispatch, useAppIntl, useAppSelector } from 'app/helpers';
import { FORMS_ASSESSMENT_PRINT_MODAL_FORM, FORMS_ASSESSMENT_SIGNATURE_FORM } from 'app/shared';
import { PrintModalBody } from 'app/assessment/medicalProfileSection/components/modals/printModal/printModalBody';
import {
  CustomHeaderPrint,
  PrintAssessment,
  PrintVisitAssessment,
} from 'app/assessment/medicalProfileSection/components/helpers';
import { useCheckViewStatus } from 'app/assessment/helpers';
import { logDoctorAssessmentPrintAction } from 'app/redux/doctorAssessment/doctorAssessment.actions';
import styles from 'app/assessment/medicalProfileSection/components/modals/printModal/printModal.module.scss';

interface Props {
  isOpen: boolean;
  toggle: () => void;
}

export const PrintModal = ({ isOpen, toggle }: Props) => {
  const dispatch = useAppDispatch();
  const componentRef = useRef<HTMLDivElement>(null);
  const { formatMessage } = useAppIntl();

  const signatureUrl = useAppSelector(
    (state) => state.form[FORMS_ASSESSMENT_SIGNATURE_FORM]?.values?.signatureFile.url,
  );
  const assessmentData = useAppSelector((state) => state.doctorAssessment.data);
  const medicalHistory = useAppSelector((state) => state.treatment.generalTreatmentInformation?.data?.medicalHistory);

  const { isCompleted, isContinue, isReadOnly } = useCheckViewStatus(
    assessmentData?.viewStatus,
    assessmentData?.completedAt,
  );

  const submitForm = useCallback(() => {
    dispatch(submit(FORMS_ASSESSMENT_PRINT_MODAL_FORM));
  }, [dispatch]);

  const onSubmit = useCallback(() => {
    toggle();
  }, [toggle]);

  const initialValues = useMemo(() => {
    return {
      vitals: true,
      pastMedicalHistory: true,
      surgicalHistory: true,
      plan: true,
      goal: true,
      referrals: isCompleted,

      pastMedicalHistoryTable: medicalHistory?.patientMedicalIllnesses,
      surgicalHistoryTable: medicalHistory?.previousSurgeries,
    };
  }, [medicalHistory, isCompleted]);

  const exportToPdfButton = useCallback(
    () => (
      <Button type="submit" color="primary-gradient" onClick={submitForm}>
        {formatMessage({ id: 'CORE.BUTTON.PRINT' })}
      </Button>
    ),
    [submitForm, formatMessage],
  );

  const afterPrint = useCallback(() => {
    assessmentData?.appointmentId && dispatch(logDoctorAssessmentPrintAction(assessmentData.appointmentId));
  }, [assessmentData?.appointmentId, dispatch]);

  const onBeforeGetContent = useCallback(async () => {
    await signatureUrl;
  }, [signatureUrl]);

  return (
    <Modal backdrop="static" keyboard={false} size="lg" isOpen={isOpen} toggle={toggle}>
      <ModalHeader toggle={toggle}>{formatMessage({ id: 'TREATMENT-PLAN.TEXT.PRINT-OPTIONS' })}</ModalHeader>
      <ModalBody>
        <PrintModalBody
          onSubmit={onSubmit}
          initialValues={initialValues}
          canPrintReferrals={isCompleted && !!assessmentData?.referralSection.referrals?.length}
        />
      </ModalBody>
      <ModalFooter className="m-auto">
        <Button type="button" color="secondary" onClick={toggle}>
          {formatMessage({ id: 'CORE.BUTTON.CANCEL' })}
        </Button>
        <div onClick={submitForm}>
          <ReactToPrint
            bodyClass={styles.printComponent}
            trigger={exportToPdfButton}
            content={() => componentRef.current}
            onAfterPrint={afterPrint}
            onBeforeGetContent={onBeforeGetContent}
          />
        </div>
      </ModalFooter>
      <PrintTemplate
        body={isCompleted || isReadOnly || isContinue ? <PrintVisitAssessment /> : <PrintAssessment />}
        ref={componentRef}
        header={<CustomHeaderPrint />}
      />
    </Modal>
  );
};
