import { useCallback } from 'react';
import { change } from 'redux-form';
import { FORMS_INVOICING_PROCESS_PAYMENT_MODE } from 'app/shared';
import { generateTemporaryId, useAppDispatch, useAppSelector } from 'app/helpers';
import { PaymentModeField } from 'app/features/invoicingProcess/process/paymentMode/form/types';
import { setInsuranceCompanyCacheUniq } from 'app/redux/invoicingProcess/invoicingProcess.actions';

export const useOnInsuranceCompanyUpdate = () => {
  const dispatch = useAppDispatch();
  const isInvoicingProcessOpen = useAppSelector((state) => state.modal.invoicingProcess.isOpen);

  return useCallback(() => {
    if (isInvoicingProcessOpen) {
      dispatch(change(FORMS_INVOICING_PROCESS_PAYMENT_MODE, PaymentModeField.insuranceCompany, null, true));
      dispatch(setInsuranceCompanyCacheUniq(generateTemporaryId()));
    }
  }, [dispatch, isInvoicingProcessOpen]);
};
