// Deprecated. Use enum UserRoles instead.
export const ROLES_NONE = '0';
export const ROLES_MASTER_ADMIN = '1';
export const ROLES_MANAGER_ADMIN = '2';
export const ROLES_RECEPTION_ADMIN = '3';
export const ROLES_OWNER = '4';
export const ROLES_MANAGER = '5';
export const ROLES_RECEPTION = '6';
export const ROLES_DOCTOR = '7';
export const ROLES_VIEWER = '8';
export const ROLES_NURSE = '10';
