import React, { useCallback, useState } from 'react';
import { AddPatientDependentModal } from 'app/features/patient/addPatientDependentModal/addPatientDependentModal';
import { AddPatientModal } from 'app/features/patient/addPatientModal/addPatientModal';
import { Patient } from 'app/types';
import { PreSearchClinicPatientModal } from 'app/features/patient/preSearchClinicPatientModal/preSearchClinicPatientModal';
import { PreSearchDependentResult } from 'app/features/patient/preSearchClinicPatientModal/form/types';
import { toggleModal } from 'app/redux/modals/modals.actions';
import { useAppDispatch } from 'app/helpers';

interface Props {
  clinicId?: string;
  setPatientData?: (data: Patient) => void;
  initialPhoneNumber: string | null;
}

export const CreatePatientModals = ({ clinicId, setPatientData, initialPhoneNumber }: Props) => {
  const dispatch = useAppDispatch();
  const [preSearchPhoneNumber, setPreSearchPhoneNumber] = useState<string | null>(null);
  const [preSearchDependentResult, setPreSearchDependentResult] = useState<PreSearchDependentResult | null>(null);

  // Helper Variables
  const phoneNumber = preSearchPhoneNumber || initialPhoneNumber;

  // Handlers
  const openAddPatientModal = useCallback(
    (phoneNumber: string) => {
      setPreSearchPhoneNumber(phoneNumber);
      dispatch(toggleModal('addPatient', true));
    },
    [dispatch],
  );

  const openAddDependentModal = useCallback(
    (result: PreSearchDependentResult) => {
      setPreSearchDependentResult(result);
      dispatch(toggleModal('addPatientDependent', true));
    },
    [dispatch],
  );

  return (
    <>
      {phoneNumber && (
        <AddPatientModal clinicId={clinicId} initialPhoneNumber={phoneNumber} onPatientCreated={setPatientData} />
      )}
      {clinicId && (
        <>
          <PreSearchClinicPatientModal
            clinicId={clinicId}
            openAddPatientModal={openAddPatientModal}
            openAddDependentModal={openAddDependentModal}
          />
          {preSearchDependentResult && (
            <AddPatientDependentModal
              clinicId={clinicId}
              primaryPatientData={preSearchDependentResult}
              onDependentPatientCreated={setPatientData}
            />
          )}
        </>
      )}
    </>
  );
};
