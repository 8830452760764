import React, { FC, useMemo } from 'react';
import { Field, Form, InjectedFormProps, reduxForm } from 'redux-form';
import { Button, Col, Row } from 'reactstrap';
import { DatePickerComponent, InputComponent, SelectComponent, required, AsyncSelectComponent } from 'app/shared';
import { getApiDate, useAppIntl, useAppSelector, useCurrentUser, useDate } from 'app/helpers';
import { PatientSection } from 'app/appointment/shared';
import {
  useCalendarDays,
  useDaySlotOptions,
  useMedicalServiceOptions,
  useOnChangeFunctions,
  useProductOptions,
  useSlotLabelFormatter,
} from 'app/appointment/form/helpers';
import {
  AppointmentFormData,
  CurrentParams,
  AppointmentFormField,
  AppointmentAgeRestrictions,
} from 'app/appointment/form/types';
import { useFetchPatientSourceOptions } from 'app/appointment/form/helpers/useFetchPatientSourceOptions';
import { useSourceOfPatientFormatter } from 'app/features/patient/patientForm/helpers/useSourceOfPatientFormatter';

interface Props {
  clinicId: string;
  isEdit?: boolean;
  isFormDisabled: boolean;
  isFullEdit?: boolean;
  onFullEdit?: () => void;
  showFullSlots?: boolean;
  showSlotsWithNumber?: boolean;
  createdPatientMode?: boolean;
  onAddNewPatient: () => void;
  resetMode: () => void;
  ageRestrictions?: AppointmentAgeRestrictions;
}

const colProps = { lg: 6 };

const FormComponent: FC<Props & InjectedFormProps<AppointmentFormData, Props>> = ({
  change,
  form,
  handleSubmit,
  initialValues,
  ageRestrictions,
  clinicId,
  isEdit,
  isFormDisabled,
  isFullEdit,
  onFullEdit,
  showFullSlots,
  showSlotsWithNumber,
  createdPatientMode,
  onAddNewPatient,
  resetMode,
}) => {
  const { todayStart } = useDate();
  const { formatMessage } = useAppIntl();
  const {
    currentUser: { allowedForBookAppointments, allowedForEditAppointments },
    isDoctor,
  } = useCurrentUser();

  // Selectors
  const selectedPatient = useAppSelector((state) => state.appointment.selectedPatient.data);
  const specialityValue: string | undefined = useAppSelector((state) => state.form[form]?.values?.speciality?.value);
  const subSpecialityValue: string | undefined = useAppSelector(
    (state) => state.form[form]?.values?.subSpeciality?.value,
  );
  const productValue: string | undefined = useAppSelector((state) => state.form[form]?.values?.product?.value);
  const dateValue: Date | null = useAppSelector((state) => state.form[form]?.values?.date);

  // Variables
  const { branchId, doctorId } = initialValues;
  const fullEditTextId = isDoctor ? 'CORE.BUTTON.EDIT-DATE' : 'CORE.BUTTON.EDIT-DOCTOR-DATE';

  // Current Params
  const currentParams: CurrentParams = useMemo(
    () => ({
      branchId,
      date: dateValue ? getApiDate(dateValue) : undefined,
      doctorId,
      productId: productValue,
      specialityId: specialityValue,
      subSpecialityId: subSpecialityValue,
    }),
    [branchId, doctorId, dateValue, productValue, specialityValue, subSpecialityValue],
  );

  // Options
  const subSpecialityOptions = useMedicalServiceOptions();
  const productOptions = useProductOptions(subSpecialityValue);
  const slotOptions = useDaySlotOptions(currentParams, showFullSlots, showSlotsWithNumber);
  const fetchPatientSources = useFetchPatientSourceOptions({
    branchIds: branchId,
    clinicId,
    practitionerIds: doctorId,
    specialityIds: specialityValue,
    subSpecialityIds: subSpecialityValue,
    serviceIds: productValue,
  });
  const formatPatientSourceLabel = useSourceOfPatientFormatter();

  // Calendar Days
  const { availableDays, isCalendarInactive, onCalendarChange } = useCalendarDays(currentParams);

  // Change Functions
  const { onDateChange, onProductChange, onSubSpecialityChange } = useOnChangeFunctions(change, dateValue);

  // Slot formatter
  const formatSlotOptionLabel = useSlotLabelFormatter(!!showSlotsWithNumber);

  return (
    <Form className="form" onSubmit={handleSubmit} data-cy="appointmentForm">
      <PatientSection
        showAppointments
        showSearch={!(isEdit || isFullEdit)}
        formName={form}
        createdPatientMode={createdPatientMode}
        clinicId={clinicId}
        isFormDisabled={isFormDisabled}
        onAddNewPatient={onAddNewPatient}
        resetMode={resetMode}
        ageRestrictions={ageRestrictions}
      />

      {selectedPatient && (
        <>
          <hr />
          <Row>
            <Col {...colProps}>
              <Field
                name={AppointmentFormField.subSpeciality}
                component={SelectComponent}
                disabled={!isEdit || isFormDisabled}
                isRequired
                isSearchable
                label={formatMessage({ id: 'APPOINTMENTS.TEXT.SUB-SPECIALITY' })}
                noOptionsMessage={formatMessage({ id: 'CORE.TEXT.NO-OPTIONS' })}
                onChange={onSubSpecialityChange}
                options={subSpecialityOptions}
                placeholder={formatMessage({ id: 'CALENDAR.TEXT.SELECT-SUB-SPECIALITY' })}
                validate={[required]}
              />
            </Col>
            <Col {...colProps}>
              <Field
                name={AppointmentFormField.product}
                component={SelectComponent}
                disabled={!isEdit || isFormDisabled}
                isRequired
                isSearchable
                label={formatMessage({ id: 'CORE.TEXT.SERVICE' })}
                noOptionsMessage={formatMessage({ id: 'CORE.TEXT.NO-OPTIONS' })}
                onChange={onProductChange}
                options={productOptions}
                placeholder={formatMessage({ id: 'CALENDAR.TEXT.SELECT-SERVICE' })}
                validate={[required]}
              />
            </Col>
            <Col {...colProps}>
              <Field
                name={AppointmentFormField.date}
                component={DatePickerComponent}
                disabled={!isEdit || isFormDisabled || !productValue}
                inactive={isCalendarInactive}
                includeDates={availableDays}
                label={formatMessage({ id: 'APPOINTMENTS.TEXT.DATE' })}
                minDate={todayStart}
                onCalendarOpen={() => dateValue && onCalendarChange(dateValue)}
                onChange={onDateChange}
                onMonthChange={(date: Date) => onCalendarChange(date, 'month')}
                onYearChange={(date: Date) => onCalendarChange(date, 'month')}
                placeholder={formatMessage({ id: 'CORE.PLACEHOLDER.SELECT-DATE' })}
                todayButton=""
                showDisabledMonthNavigation
                isRequired
                validate={[required]}
              />
            </Col>
            <Col {...colProps}>
              <Field
                name={AppointmentFormField.slot}
                component={SelectComponent}
                disabled={!isEdit || isFormDisabled || !productValue || !dateValue}
                formatOptionLabel={formatSlotOptionLabel}
                isRequired
                isSearchable
                label={formatMessage({ id: 'APPOINTMENTS.TEXT.TIME' })}
                noOptionsMessage={formatMessage({ id: 'CORE.TEXT.NO-OPTIONS' })}
                options={slotOptions}
                placeholder={formatMessage({ id: 'CORE.PLACEHOLDER.SELECT-TIME' })}
                validate={[required]}
              />
            </Col>
            <Col {...colProps}>
              <Field
                name={AppointmentFormField.doctorName}
                component={InputComponent}
                disabled
                label={formatMessage({ id: 'APPOINTMENTS.TEXT.DOCTOR-NAME' })}
                type="text"
              />
              {/*Full edit action requires both book and edit permissions*/}
              {allowedForBookAppointments && allowedForEditAppointments && isEdit && onFullEdit && (
                <div>
                  <Button
                    className="btn-responsive mb-3"
                    color="primary"
                    disabled={isFormDisabled}
                    onClick={onFullEdit}
                    outline
                    data-cy="fullEditButton"
                  >
                    <span>{formatMessage({ id: fullEditTextId })}</span>
                  </Button>
                </div>
              )}
            </Col>
            <Col {...colProps}>
              <Field
                name={AppointmentFormField.patientSource}
                component={AsyncSelectComponent}
                fetchOptions={fetchPatientSources}
                formatOptionLabel={formatPatientSourceLabel}
                label={formatMessage({ id: 'CORE.LABEL.PATIENT-SOURCE', defaultMessage: 'Patient Source' })}
                disabled={isFormDisabled || !doctorId || !specialityValue || !subSpecialityValue || !productValue}
                cacheUniqs={[doctorId, specialityValue, subSpecialityValue, productValue]}
                showClinicyId={false}
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <Field
                name={AppointmentFormField.note}
                component={InputComponent}
                disabled={isFormDisabled}
                label={formatMessage({ id: 'APPOINTMENTS.TEXT.APPOINTMENT-NOTE' })}
                type="textarea"
              />
            </Col>
          </Row>
        </>
      )}
    </Form>
  );
};

export const AppointmentForm = reduxForm<AppointmentFormData, Props>({})(FormComponent);
