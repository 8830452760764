import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Button } from 'reactstrap';
import { FormattedMessage } from 'react-intl';
import { setLanguageToSwap } from '../../helpers';

class InfoPageHeaderComponent extends Component {
  static propTypes = {
    changeLanguage: PropTypes.func.isRequired,
    language: PropTypes.string.isRequired,
  };

  render() {
    const languageToSwap = setLanguageToSwap(this.props.language);

    return (
      <div className="info-page__header">
        <div className="info-page__logo">
          <Link to="/" />
        </div>
        <Button
          className="button__lang"
          size="sm"
          color="primary-gradient"
          onClick={() => this.props.changeLanguage(languageToSwap)}
        >
          <FormattedMessage id="CORE.TEXT.LANG-SWAP-SIGN" />
        </Button>
      </div>
    );
  }
}

export default InfoPageHeaderComponent;
