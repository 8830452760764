export enum AssessmentPhaseType {
  NewOrVisit = 'newOrVisit',
  Completed = 'completed',
  Uncompleted = 'uncompleted',
}

// For BE viewStatus response
export enum DoctorAssessmentViewStatus {
  New,
  Continue,
  Draft,
  Completed,
  ReadOnly,
}

// For url initial checking
export enum DoctorAssessmentPhaseType {
  New = 'new',
  View = 'view',
  Continue = 'continue',
}

// [i]: Determines from where the user entered the Assessment page
export enum TreatmentPageType {
  GeneralTreatmentPage = 'generalTreatmentPage',
  ClinicTreatmentPage = 'clinicTreatmentPage',
  ArrivalTreatmentPage = 'arrivalTreatmentPage',
}
