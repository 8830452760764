import { UncontrolledTooltip } from 'reactstrap';
import { InvoicePaymentMethodItem } from 'app/types';
import { getTranslation, useAppIntl } from 'app/helpers';
import { invoicingProcessContentId } from 'app/features/invoicingProcess/process/process';

interface Props {
  paymentMethodItems: InvoicePaymentMethodItem[];
}

export const PaymentMethodLabel = ({ paymentMethodItems }: Props) => {
  const { formatMessage, locale } = useAppIntl();
  const isMulti = paymentMethodItems.length > 1;
  const hasItems = !!paymentMethodItems.length;
  const tooltipId = 'paymentMethodLabelTooltipId';

  const text = isMulti
    ? formatMessage({ id: 'CORE.LABEL.MULTI', defaultMessage: 'Multi' })
    : hasItems
    ? getTranslation(paymentMethodItems[0].paymentMethod, locale)
    : '-';

  return (
    <>
      <span style={{ cursor: isMulti ? 'default' : 'auto' }} id={tooltipId}>
        {text}
      </span>
      {isMulti && (
        <UncontrolledTooltip container={`#${invoicingProcessContentId}`} placement="top" target={tooltipId}>
          {paymentMethodItems.map((item) => getTranslation(item.paymentMethod, locale)).join(', ')}
        </UncontrolledTooltip>
      )}
    </>
  );
};
