export const EXPORT_BRANCH_BILLING_PACKAGES_REQUEST = 'branchBillingPackages/EXPORT_BRANCH_BILLING_PACKAGES_REQUEST';
export const EXPORT_BRANCH_BILLING_PACKAGES_SUCCESS = 'branchBillingPackages/EXPORT_BRANCH_BILLING_PACKAGES_SUCCESS';
export const EXPORT_BRANCH_BILLING_PACKAGES_FAILURE = 'branchBillingPackages/EXPORT_BRANCH_BILLING_PACKAGES_FAILURE';

export const ADD_BRANCH_BILLING_PACKAGES_REQUEST = 'branchBillingPackages/ADD_BRANCH_BILLING_PACKAGES_REQUEST';
export const ADD_BRANCH_BILLING_PACKAGES_SUCCESS = 'branchBillingPackages/ADD_BRANCH_BILLING_PACKAGES_SUCCESS';
export const ADD_BRANCH_BILLING_PACKAGES_FAILURE = 'branchBillingPackages/ADD_BRANCH_BILLING_PACKAGES_FAILURE';

export const EDIT_BRANCH_BILLING_PACKAGES_REQUEST = 'branchBillingPackages/EDIT_BRANCH_BILLING_PACKAGES_REQUEST';
export const EDIT_BRANCH_BILLING_PACKAGES_SUCCESS = 'branchBillingPackages/EDIT_BRANCH_BILLING_PACKAGES_SUCCESS';
export const EDIT_BRANCH_BILLING_PACKAGES_FAILURE = 'branchBillingPackages/EDIT_BRANCH_BILLING_PACKAGES_FAILURE';

export const REMOVE_BRANCH_BILLING_PACKAGES_REQUEST = 'branchBillingPackages/REMOVE_BRANCH_BILLING_PACKAGES_REQUEST';
export const REMOVE_BRANCH_BILLING_PACKAGES_SUCCESS = 'branchBillingPackages/REMOVE_BRANCH_BILLING_PACKAGES_SUCCESS';
export const REMOVE_BRANCH_BILLING_PACKAGES_FAILURE = 'branchBillingPackages/REMOVE_BRANCH_BILLING_PACKAGES_FAILURE';

export const ADD_ITEM = 'branchBillingPackages/ADD_ITEM';
export const REMOVE_ITEM = 'branchBillingPackages/REMOVE_ITEM';
export const RESET_ITEMS = 'branchBillingPackages/RESET_ITEMS';
export const SET_VIEW_MODE = 'branchBillingPackages/SET_VIEW_MODE';
export const EDIT_PACKAGE = 'branchBillingPackages/EDIT_PACKAGE';
