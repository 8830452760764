import moment from 'moment';
import { useMemo } from 'react';

import { DEFAULT_TIME_FROM, DEFAULT_TIME_TO, FORMATS_API_TIME } from 'app/shared';
import { generateTimeOption } from '../generateTimeOption/generateTimeOption';

export const useTimeOptions = (
  start = DEFAULT_TIME_FROM,
  end = DEFAULT_TIME_TO,
  duration = 5, // minutes
) =>
  useMemo(() => {
    // Return an empty array if start or end is an empty string
    if (!start || !end) {
      return [];
    }

    const slotTime = moment(start, FORMATS_API_TIME).utcOffset(0, true);
    const endTime = moment(end, FORMATS_API_TIME).utcOffset(0, true);
    let options = [];

    // Generate options
    while (slotTime <= endTime) {
      options.push(generateTimeOption(slotTime));
      slotTime.add(duration, 'minutes');
    }

    // Assign the index of the last option
    const lastOptionIndex = options.length - 1;

    // Replace midnight with 23:59 if this is the last option
    if (options[lastOptionIndex].value === DEFAULT_TIME_FROM) {
      options.splice(lastOptionIndex, 1, generateTimeOption(DEFAULT_TIME_TO));
    }

    return options;
  }, [start, end, duration]);
