import { takeEvery } from 'redux-saga/effects';
import { APPOINTMENT_CHECKIN_SUCCESS, APPOINTMENT_UNCHECKIN_SUCCESS } from 'app/redux/appointments/appointments.types';
import {
  ADD_APPOINTMENT_SUCCESS,
  ADD_WAITING_APPOINTMENT_SUCCESS,
  CANCEL_APPOINTMENT_SUCCESS,
  CANCEL_WAITING_APPOINTMENT_SUCCESS,
  CHANGE_APPOINTMENT_STATUS_SUCCESS,
  EDIT_APPOINTMENT_NOTE_SUCCESS,
  EDIT_APPOINTMENT_SUCCESS,
  EDIT_WAITING_APPOINTMENT_NOTE_SUCCESS,
  EDIT_WAITING_APPOINTMENT_SUCCESS,
  PROMOTE_WAITING_APPOINTMENT_SUCCESS,
  SET_FULL_EDIT,
  SET_REBOOK,
} from 'app/redux/appointment/appointment.types';
import {
  afterAddAppointment,
  afterCancelAppointment,
  afterChangeStatus,
  afterEditAppointment,
  afterEditNote,
  afterPromoteWaiting,
  afterSetFullEdit,
  afterSetRebook,
  afterCheckInChange,
} from 'app/sagas/apppointment/generators';

function* appointmentSaga() {
  yield takeEvery([ADD_APPOINTMENT_SUCCESS, ADD_WAITING_APPOINTMENT_SUCCESS], afterAddAppointment);
  yield takeEvery([EDIT_APPOINTMENT_SUCCESS, EDIT_WAITING_APPOINTMENT_SUCCESS], afterEditAppointment);
  yield takeEvery([EDIT_APPOINTMENT_NOTE_SUCCESS, EDIT_WAITING_APPOINTMENT_NOTE_SUCCESS], afterEditNote);
  yield takeEvery([CANCEL_APPOINTMENT_SUCCESS, CANCEL_WAITING_APPOINTMENT_SUCCESS], afterCancelAppointment);
  yield takeEvery(CHANGE_APPOINTMENT_STATUS_SUCCESS, afterChangeStatus);
  yield takeEvery([APPOINTMENT_CHECKIN_SUCCESS, APPOINTMENT_UNCHECKIN_SUCCESS], afterCheckInChange);
  yield takeEvery(PROMOTE_WAITING_APPOINTMENT_SUCCESS, afterPromoteWaiting);
  yield takeEvery(SET_REBOOK, afterSetRebook);
  yield takeEvery(SET_FULL_EDIT, afterSetFullEdit);
}

export default appointmentSaga;
