import { InvoicingProcess, InvoicingProcessPatient } from 'app/redux/invoicingProcess/types';

export const getPatientFieldValue = (
  invoicingProcess: InvoicingProcess,
  field: keyof Pick<InvoicingProcessPatient, 'governmentIssuedId' | 'governmentIssuedIdType'>,
) => {
  const paymentModeValue = invoicingProcess.steps?.paymentMode?.[field];
  const guardianPatientValue = invoicingProcess.guardianPatient?.[field];
  const patientValue = invoicingProcess.patient?.[field];

  if (paymentModeValue) {
    // 1. Take from the payment mode step
    return paymentModeValue;
  }

  if (guardianPatientValue) {
    // 2. Take from the guardianPatient
    return guardianPatientValue;
  }

  if (patientValue && !invoicingProcess.guardianPatient) {
    // 3. Take from the patient (if is not a dependant)
    return patientValue;
  }

  // 4. Field value is undefined
  return;
};
