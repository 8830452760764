import moment from 'moment';
import { FORMATS_API_DATE } from 'app/shared';
import { Option } from 'app/types';
import { FiltersFormData } from 'app/calendar/components/calendar/filters/types';

const generateOption = (searchParams: URLSearchParams, name: keyof FiltersFormData): Option | undefined => {
  const label = searchParams.get(`${name}Name`);
  const value = searchParams.get(`${name}Id`);

  if (label && value) {
    return { label, value };
  }

  // Option could not be generated (missing label or value)
  return;
};

export const getFilterValuesFromUrl = (): Partial<FiltersFormData> => {
  const searchParams = new URLSearchParams(window.location.search);
  const date = searchParams.get('date');

  return {
    branch: generateOption(searchParams, 'branch'),
    date: date ? moment(date, FORMATS_API_DATE).toDate() : undefined,
    doctor: generateOption(searchParams, 'doctor'),
    speciality: generateOption(searchParams, 'speciality'),
    subSpeciality: generateOption(searchParams, 'subSpeciality'),
    service: generateOption(searchParams, 'service'),
  };
};
