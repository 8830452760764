import { useCallback } from 'react';
import { useParams } from 'react-router';
import { DropdownOptionsParams } from 'app/redux/dropdownOptions/types';
import { fetchDropdownOptions } from 'app/redux/dropdownOptions/dropdownOptions.actions';
import { useAppDispatch } from 'app/helpers';
import { InsuranceContractEntityStatus, InsuranceStatus, Option, RemoteDataParams } from 'app/types';

interface Props {
  clinicBranchId?: string;
  insuranceCompanyId?: string;
  insurancePolicyId?: string;
  selectedClinic?: Option;
}

export const useFetchOptions = ({ insuranceCompanyId, insurancePolicyId, selectedClinic, clinicBranchId }: Props) => {
  const dispatch = useAppDispatch();
  const { clinicId, patientId } = useParams<{ clinicId?: string; patientId: string }>();

  // Handlers
  const fetchInsuranceOptions = useCallback(
    (params: DropdownOptionsParams) => {
      return dispatch(
        fetchDropdownOptions('/insurance-companies/dropdown', { ...params, statuses: InsuranceStatus.Enabled }),
      );
    },
    [dispatch],
  );

  const fetchInsurancePoliciesOptions = useCallback(
    (params: DropdownOptionsParams) => {
      if (insuranceCompanyId)
        return dispatch(
          fetchDropdownOptions(`insurance-companies/${insuranceCompanyId}/insurance-policies/dropdown`, {
            ...params,
            clinicBranchId,
            policyStatuses: [InsuranceContractEntityStatus.Active],
          }),
        );
    },
    [clinicBranchId, dispatch, insuranceCompanyId],
  );

  const fetchInsuranceClassesOptions = useCallback(
    (params: DropdownOptionsParams) => {
      if (insurancePolicyId)
        return dispatch(
          fetchDropdownOptions(`insurance-classes/dropdown`, {
            ...params,
            insurancePolicyId,
            classStatuses: [InsuranceContractEntityStatus.Active],
          }),
        );
    },
    [dispatch, insurancePolicyId],
  );

  const fetchClinicOptions = (params: RemoteDataParams) => {
    return dispatch(
      fetchDropdownOptions(`patients/${patientId}/clinics-dropdown`, {
        ...params,
        showValidRegistrationsForActions: true,
      }),
    );
  };

  const fetchBranchOptions = useCallback(
    (params: RemoteDataParams) => {
      return dispatch(
        fetchDropdownOptions('/branches/dropdown', {
          ...params,
          clinicIds: clinicId ? [clinicId] : [selectedClinic?.value],
        }),
      );
    },
    [dispatch, clinicId, selectedClinic?.value],
  );

  return {
    fetchInsuranceOptions,
    fetchInsurancePoliciesOptions,
    fetchInsuranceClassesOptions,
    fetchClinicOptions,
    fetchBranchOptions,
  };
};
