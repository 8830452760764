import { ClinicPatientShortInfo } from 'app/services/patients/types';
import { QueryParams, QueryResponsePagination } from 'app/types';
import { RemoteBootstrapTable } from 'app/components/remoteBootstrapTable/remoteBootstrapTable';
import { useColumns } from 'app/shared/patientSearch/patientSearchResults/helpers/useColumns';

interface Props {
  data: ClinicPatientShortInfo[];
  isFetching: boolean;
  isLoading: boolean;
  pagination?: QueryResponsePagination;
  params?: QueryParams;
  setParams: (params?: QueryParams) => void;
  selectionChanged: (index: number) => void;
  checkIsSelected: (index: number) => boolean;
}

export const PatientSearchResults = ({
  data,
  isFetching,
  isLoading,
  pagination,
  params,
  setParams,
  checkIsSelected,
  selectionChanged,
}: Props) => {
  const columns = useColumns({ checkIsSelected, selectionChanged });

  return (
    <RemoteBootstrapTable
      classes="table-sm table-stripe"
      columns={columns}
      data={data || []}
      id="patientSearchResults"
      keyField="id"
      isFetching={isFetching}
      isLoading={isLoading}
      pagination={pagination}
      params={params}
      setParams={setParams}
    />
  );
};
