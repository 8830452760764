import { ReactNode } from 'react';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { useAppIntl } from 'app/helpers';

interface Props {
  content?: ReactNode;
  description: string;
  disabled?: boolean;
  isOpen: boolean;
  size?: string;
  onCancel: () => void;
  onClose: () => void;
  onConfirm: () => void;
  title?: string;
}

export const ConfirmationModal = ({
  description,
  disabled,
  isOpen,
  size = 'md',
  onCancel,
  onClose,
  onConfirm,
  title,
  content,
}: Props) => {
  const { formatMessage } = useAppIntl();

  return (
    <Modal isOpen={isOpen} toggle={onClose} size={size}>
      <ModalHeader toggle={onClose}>{formatMessage({ id: title || 'CORE.TEXT.CONFIRMATION' })}</ModalHeader>
      <ModalBody className="text-center">
        {formatMessage({ id: description })}
        {content && <div>{content}</div>}
      </ModalBody>
      <ModalFooter className="justify-content-center">
        <Button color="primary" outline disabled={disabled} onClick={onCancel}>
          {formatMessage({ id: 'CORE.BUTTON.CANCEL' })}
        </Button>
        <Button color="primary-gradient" disabled={disabled} onClick={onConfirm}>
          {formatMessage({ id: 'CORE.BUTTON.CONFIRM' })}
        </Button>
      </ModalFooter>
    </Modal>
  );
};
