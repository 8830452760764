export const FETCH_DETAILS_REQUEST = 'take-action/FETCH_DETAILS_REQUEST';
export const FETCH_DETAILS_SUCCESS = 'take-action/FETCH_DETAILS_SUCCESS';
export const FETCH_DETAILS_FAILURE = 'take-action/FETCH_DETAILS_FAILURE';

export const FETCH_DATA_REQUEST = 'take-action/FETCH_DATA_REQUEST';
export const FETCH_DATA_SUCCESS = 'take-action/FETCH_DATA_SUCCESS';
export const FETCH_DATA_FAILURE = 'take-action/FETCH_DATA_FAILURE';

export const SET_FORM = 'take-action/SET_FORM';
export const SET_INFO = 'take-action/SET_INFO';

export const FETCH_INSURANCE_APPROVAL_REQUEST = 'take-action/FETCH_INSURANCE_APPROVAL_REQUEST';
export const FETCH_INSURANCE_APPROVAL_SUCCESS = 'take-action/FETCH_INSURANCE_APPROVAL_SUCCESS';
export const FETCH_INSURANCE_APPROVAL_FAILURE = 'take-action/FETCH_INSURANCE_APPROVAL_FAILURE';

export const POST_INSURANCE_APPROVAL_REQUEST = 'take-action/POST_INSURANCE_APPROVAL_REQUEST';
export const POST_INSURANCE_APPROVAL_SUCCESS = 'take-action/POST_INSURANCE_APPROVAL_SUCCESS';
export const POST_INSURANCE_APPROVAL_FAILURE = 'take-action/POST_INSURANCE_APPROVAL_FAILURE';

export const PUT_INSURANCE_APPROVAL_REQUEST = 'take-action/PUT_INSURANCE_APPROVAL_REQUEST';
export const PUT_INSURANCE_APPROVAL_SUCCESS = 'take-action/PUT_INSURANCE_APPROVAL_SUCCESS';
export const PUT_INSURANCE_APPROVAL_FAILURE = 'take-action/PUT_INSURANCE_APPROVAL_FAILURE';

export const PUT_INSURANCE_APPROVAL_STATUS_REQUEST = 'take-action/PUT_INSURANCE_APPROVAL_STATUS_REQUEST';
export const PUT_INSURANCE_APPROVAL_STATUS_SUCCESS = 'take-action/PUT_INSURANCE_APPROVAL_STATUS_SUCCESS';
export const PUT_INSURANCE_APPROVAL_STATUS_FAILURE = 'take-action/PUT_INSURANCE_APPROVAL_STATUS_FAILURE';

export const CREATE_INVOICE_REQUEST_REQUEST = 'take-action/CREATE_INVOICE_REQUEST_REQUEST';
export const CREATE_INVOICE_REQUEST_SUCCESS = 'take-action/CREATE_INVOICE_REQUEST_SUCCESS';
export const CREATE_INVOICE_REQUEST_FAILURE = 'take-action/CREATE_INVOICE_REQUEST_FAILURE';

export const SEND_REQUEST_FOR_APPROVAL_REQUEST = 'take-action/SEND_REQUEST_FOR_APPROVAL_REQUEST';
export const SEND_REQUEST_FOR_APPROVAL_SUCCESS = 'take-action/SEND_REQUEST_FOR_APPROVAL_SUCCESS';
export const SEND_REQUEST_FOR_APPROVAL_FAILURE = 'take-action/SEND_REQUEST_FOR_APPROVAL_FAILURE';

export const FETCH_BILLING_ITEMS_REQUEST = 'take-action/FETCH_BILLING_ITEMS_REQUEST';
export const FETCH_BILLING_ITEMS_SUCCESS = 'take-action/FETCH_BILLING_ITEMS_SUCCESS';
export const FETCH_BILLING_ITEMS_FAILURE = 'take-action/FETCH_BILLING_ITEMS_FAILURE';

export const SET_DETAILS = 'take-action/SET_DETAILS';
