import React, { useMemo } from 'react';
import { Doctor } from 'app/types';
import { Item } from 'app/components/descriptionList/types';
import { SelectedSlot } from 'app/redux/calendar/types';
import { useAppIntl, getDoctorFullName, getTranslation, formattedDate, formattedPeriod } from 'app/helpers';

interface Props {
  doctor?: Doctor;
  selectedSlot: SelectedSlot | null;
}

export const useItems = ({ doctor, selectedSlot }: Props) => {
  const { formatMessage, locale } = useAppIntl();
  const hasAgeRestrictions = !!(doctor?.acceptablePatientAgeFrom || doctor?.acceptablePatientAgeTo);

  return useMemo((): Item[] => {
    if (!doctor || !selectedSlot) {
      return [];
    }

    return [
      {
        label: formatMessage({ id: 'CORE.LABEL.DOCTOR' }),
        value: getDoctorFullName(doctor, locale),
      },
      {
        label: formatMessage({ id: 'CORE.LABEL.CLINICY-ID' }),
        value: doctor.clinicyId,
      },
      {
        label: formatMessage({ id: 'CORE.LABEL.SPECIALITY' }),
        value: getTranslation(selectedSlot.speciality, locale),
      },
      {
        label: formatMessage({ id: 'APPOINTMENTS.TEXT.SUB-SPECIALITY' }),
        value: getTranslation(selectedSlot.subSpeciality, locale),
      },
      {
        label: formatMessage({ id: 'APPOINTMENTS.TEXT.DATE' }),
        value: formattedDate(selectedSlot.date),
      },
      {
        label: formatMessage({ id: 'APPOINTMENTS.TEXT.TIME' }),
        value:
          selectedSlot.start && selectedSlot.end
            ? formattedPeriod(selectedSlot.start, selectedSlot.end)
            : formatMessage({ id: 'CORE.TEXT.WITHOUT-TIME' }),
      },
      {
        label: formatMessage({ id: 'CORE.TEXT.SERVICE' }),
        value: getTranslation(selectedSlot.product, locale),
      },
      {
        label: formatMessage({ id: 'CORE.LABEL.BOOKING-RESTRICTIONS' }),
        value: hasAgeRestrictions ? (
          <>
            {!doctor.acceptablePatientAgeTo &&
              formatMessage(
                { id: 'CORE.LABEL.YEARS-AGE-OR-ABOVE', defaultMessage: '{ageFrom} years old or above' },
                { ageFrom: doctor.acceptablePatientAgeFrom },
              )}
            {!!doctor.acceptablePatientAgeTo &&
              formatMessage(
                { id: 'CORE.LABEL.YEARS-AGE-RANGE', defaultMessage: '{ageFrom} - {ageTo} years old' },
                { ageFrom: doctor.acceptablePatientAgeFrom, ageTo: doctor.acceptablePatientAgeTo },
              )}
          </>
        ) : null,
      },
    ];
  }, [formatMessage, locale, hasAgeRestrictions, doctor, selectedSlot]);
};
