import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import { Modal } from 'reactstrap';
import { useModalCleaner } from 'app/helpers';
import { Ticket } from 'app/appointment/ticket/ticket';

export const AppointmentTicketModalComponent = ({ isOpen, toggle }) => {
  const modalRef = useRef(null);

  useModalCleaner('appointmentTicket', modalRef);

  return (
    <Modal ref={modalRef} size="xl" isOpen={isOpen} toggle={toggle}>
      <Ticket toggle={toggle} />
    </Modal>
  );
};

AppointmentTicketModalComponent.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  toggle: PropTypes.func.isRequired,
};
