import moment from 'moment';
import { Tokens } from 'app/redux/auth/types';
import { AuthState } from 'app/redux/auth/auth.reducer';
import * as authStorageKeys from 'app/redux/auth/auth.constants';

export const setTokens = (payload: Partial<Tokens>): Pick<AuthState, 'token' | 'tokenExpiryDate' | 'refreshToken'> => {
  // Set local storage data
  localStorage.setItem(authStorageKeys.STORAGE_TOKEN_KEY, payload.access_token || '');
  localStorage.setItem(authStorageKeys.STORAGE_REFRESH_TOKEN_KEY, payload.refresh_token || '');
  localStorage.setItem(
    authStorageKeys.STORAGE_TOKEN_EXPIRATION_DATE,
    payload.expires_in ? moment().add(payload.expires_in, 'seconds').toISOString() : '',
  );

  return {
    token: payload.access_token || null,
    tokenExpiryDate: payload.expires_in ? moment().add(payload.expires_in, 'seconds').toISOString() : null,
    refreshToken: payload.refresh_token || null,
  };
};

export const getToken = () => localStorage.getItem(authStorageKeys.STORAGE_TOKEN_KEY);
export const getRefreshToken = () => localStorage.getItem(authStorageKeys.STORAGE_REFRESH_TOKEN_KEY);
export const getTokenExpirationDate = () => localStorage.getItem(authStorageKeys.STORAGE_TOKEN_EXPIRATION_DATE);
