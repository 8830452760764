import { put, select } from 'redux-saga/effects';
import { PayloadAction } from '@reduxjs/toolkit';
import { WsRefreshPayload } from '../types';
import { RootState } from 'app/types';
import { fetchBillingItemsPerformers, fetchPerformerBillingItems } from 'app/redux/remoteData/remoteData.actions';
import { DEFAULT_PAGINATION_PARAMS } from 'app/shared';

export function* refreshPerformerBillingItemsSoft({ payload }: PayloadAction<WsRefreshPayload>) {
  const {
    billingItemsLists: { expandedPerformerId },
  }: RootState = yield select();
  const { branchId, params, performerId } = payload;

  if (branchId && params) {
    // refreshing list of performer billing items for specific requested via signalR
    if (!!performerId && performerId === expandedPerformerId) {
      yield put<any>(fetchPerformerBillingItems(branchId, performerId, { ...DEFAULT_PAGINATION_PARAMS }, true));
    } else if (!performerId) {
      // refreshing list of available performers, optionally refreshing expanded performer billing items if any
      yield put<any>(fetchBillingItemsPerformers(branchId, params, true));
      if (!!expandedPerformerId) {
        yield put<any>(
          fetchPerformerBillingItems(branchId, expandedPerformerId, { ...DEFAULT_PAGINATION_PARAMS }, true),
        );
      }
    }
  }
}
