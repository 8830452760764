import { otherText } from '../../registration/registration.constants';

export const discountTypes = {
  NONE: '0',
  PERCENTAGE: '1',
  AMOUNT: '2',
};

export const insurancePrefix = 'insuranceDetails';

export const otherOption = { label: otherText, value: otherText, translation: { ar: 'ورقي', en: 'Other (manual)' } };

export const vatApplicableOptions = [
  {
    value: true,
    label: 'CORE.LABEL.YES',
  },
  {
    value: false,
    label: 'CORE.LABEL.NO',
  },
];

export const insuranceCoverOptions = [
  {
    value: true,
    label: 'CORE.LABEL.YES',
  },
  {
    value: false,
    label: 'CORE.LABEL.NO',
  },
];
