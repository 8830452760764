// Locals
export const SET_SELECTED_REFERRAL = 'referrals/SET_SELECTED_REFERRAL';
export const RESET_SELECTED_REFERRAL = 'referrals/RESET_SELECTED_REFERRAL';

export const RESET_REFERRAL = 'referrals/RESET_REFERRAL';
export const SET_REFERRAL_ID = 'referrals/SET_REFERRAL_ID';

// Remotes
export const DISCARD_REFERRAL_REQUEST = 'referrals/DISCARD_REFERRAL_REQUEST';
export const DISCARD_REFERRAL_SUCCESS = 'referrals/DISCARD_REFERRAL_SUCCESS';
export const DISCARD_REFERRAL_FAILURE = 'referrals/DISCARD_REFERRAL_FAILURE';

export const MOVE_BACK_REFERRAL_REQUEST = 'referrals/MOVE_BACK_REFERRAL_REQUEST';
export const MOVE_BACK_REFERRAL_SUCCESS = 'referrals/MOVE_BACK_REFERRAL_SUCCESS';
export const MOVE_BACK_REFERRAL_FAILURE = 'referrals/MOVE_BACK_REFERRAL_FAILURE';

export const FETCH_REFERRAL_REQUEST = 'referrals/FETCH_REFERRAL_REQUEST';
export const FETCH_REFERRAL_SUCCESS = 'referrals/FETCH_REFERRAL_SUCCESS';
export const FETCH_REFERRAL_FAILURE = 'referrals/FETCH_REFERRAL_FAILURE';
