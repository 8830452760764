import moment from 'moment/moment';
import { InsuranceDetailsItem } from 'app/types';
import { generateOption } from 'app/helpers';
import { PatientInsuranceFormData } from 'app/features/patient/patientInsuranceDetailsForm/types';

export const useInitialValues = (
  patientInsurance?: InsuranceDetailsItem | null,
): Partial<PatientInsuranceFormData> | undefined => {
  if (patientInsurance) {
    return {
      activated: !patientInsurance.deactivated,
      end: moment(patientInsurance.end).toDate(),
      clinic: patientInsurance.clinic,
      clinicBranch: patientInsurance.clinicBranch,
      insuranceClass: patientInsurance.insuranceClass,
      insuranceCompany: generateOption(patientInsurance.insuranceCompany),
      number: patientInsurance.number,
      policyNumber: patientInsurance.insurancePolicy,
      start: moment(patientInsurance.start).toDate(),
    };
  }
  return undefined;
};
