// Environment
export const isDevelopmentEnv = process.env.NODE_ENV === 'development';
export const isTestingEnv = ['test.clinicy.com.sa'].some((name) => window.location.hostname.includes(name));

// Default values
export const defaultValues = {
  formDebounceWait: 500,
  limits: {
    searchLength: 2083 - 160, // 2083 is the minimum URL length limit for modern browsers, and 160 is the maximum length of our base url
    options: 5,
  },
  paginationQueryParams: {
    pageNumber: '1',
    pageSize: '10',
  },
  queryOptions: {
    pollingInterval: 30000,
    refetchOnFocus: true,
    refetchOnMountOrArgChange: 15,
    refetchOnReconnect: true,
  },
} as const;
