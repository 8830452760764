import toast from 'react-hot-toast';
import { useCallback, useMemo } from 'react';
import { SubmissionError } from 'redux-form';
import { FormSubmitHandler } from 'redux-form/lib/reduxForm';
import { ErrorType, InvoiceItem } from 'app/types';
import { useAppIntl, useAppSelector } from 'app/helpers';
import { FORMS_INVOICING_PROCESS_EDIT_ITEM } from 'app/shared';
import { editInvoiceItem } from 'app/redux/invoicingProcess/invoicingProcess.actions';
import { useInvoicingProcessState } from 'app/features/invoicingProcess/process/helpers';
import { ItemFormData } from 'app/features/invoicingProcess/process/items/itemForm/types';
import { getPriceAfterDiscount } from 'app/features/invoicingProcess/process/items/helpers';
import { ItemForm, ItemFormProps } from 'app/features/invoicingProcess/process/items/itemForm/itemForm';

interface Props {
  onSubmitSuccess: () => void;
}

export const EditItemForm = ({ onSubmitSuccess }: Props) => {
  const { formatMessage } = useAppIntl();
  const { isCreateCreditNoteMode } = useInvoicingProcessState();
  const invoiceItems = useAppSelector((state) => state.invoicingProcess.data?.steps?.items);
  const selectedItem = useAppSelector((state) => state.invoicingProcess.selectedItem);
  const selectedItemId = selectedItem?.id;

  const invoiceItem = useMemo(
    () => invoiceItems?.find((item) => item.id === selectedItemId),
    [selectedItemId, invoiceItems],
  );

  const onSubmit = useCallback<FormSubmitHandler<ItemFormData, ItemFormProps, ErrorType>>(
    (values, dispatch) => {
      if (selectedItem) {
        const newValues: Omit<InvoiceItem, 'id'> = {
          billingItem: values.item,
          billingItemSourceType: values.itemSourceType,
          discount: {
            discountTypeView: { label: '', value: values.discountType }, // label is not required
            value: Number(values.discountValue || 0),
          },
          price: Number(values.item.details.price),
          quantity: Number(values.quantity),
          vat: selectedItem.vat,
        };

        // Total Price
        const prevTotalPrice = invoiceItem ? getPriceAfterDiscount(invoiceItem) : undefined;
        const totalPrice = getPriceAfterDiscount(newValues);

        if (prevTotalPrice !== undefined && totalPrice > prevTotalPrice && isCreateCreditNoteMode) {
          // Total Price cannot be higher than Previous Total Price in Credit Note mode
          throw new SubmissionError({
            _error: formatMessage({
              id: 'ERRORS.TOTAL-PRICE-HIGHER-THAN-BEFORE',
              defaultMessage: 'Total price cannot be higher than before',
            }),
          });
        } else {
          dispatch(
            editInvoiceItem({
              ...selectedItem,
              ...newValues,
            }),
          );
          onSubmitSuccess();
          toast.success('CORE.TEXT.ITEM-UPDATED-SUCCESSFULLY');
        }
      }
    },
    [formatMessage, invoiceItem, isCreateCreditNoteMode, onSubmitSuccess, selectedItem],
  );

  const initialValues = useMemo<Partial<ItemFormData> | undefined>(() => {
    if (selectedItem) {
      return {
        itemSourceType: selectedItem.billingItemSourceType,
        discountType: selectedItem.discount.discountTypeView.value,
        discountValue: String(selectedItem.discount.value),
        item: selectedItem.billingItem,
        quantity: String(selectedItem.quantity),
      };
    }

    return;
  }, [selectedItem]);

  return <ItemForm form={FORMS_INVOICING_PROCESS_EDIT_ITEM} initialValues={initialValues} onSubmit={onSubmit} />;
};
