import { ReactNode, useMemo } from 'react';
import { Button, Col, Modal, ModalBody, ModalHeader, Row } from 'reactstrap';
import { useAppIntl } from 'app/helpers';
import { isTouchEnabled } from 'app/helpers/isTouchEnabled/isTouchEnabled';
import arrowsMove from 'assets/images/icons/gesture/arrows-move.svg';
import click from 'assets/images/icons/gesture/click.svg';
import doubleTap from 'assets/images/icons/gesture/double-tap.svg';
import freeDrag from 'assets/images/icons/gesture/free-drag.svg';
import mouse from 'assets/images/icons/gesture/mouse.svg';
import zoomIn from 'assets/images/icons/gesture/zoom-in.svg';

interface Props {
  close: () => void;
  isOpen: boolean;
}

interface Item {
  description: string | ReactNode;
  icon: ReactNode;
}

const defaultHelpKey = 'LOCATION_SELECTOR_DEFAULT_HELP';
const defaultHelpKeyDisabledValue = '0';
export const hasDefaultHelp = () => localStorage.getItem(defaultHelpKey) !== defaultHelpKeyDisabledValue;

export const HelpModal = ({ close, isOpen }: Props) => {
  const { formatMessage } = useAppIntl();

  const onConfirm = () => {
    localStorage.setItem(defaultHelpKey, defaultHelpKeyDisabledValue);
    close();
  };

  const touchDeviceItems = useMemo<Item[]>(
    () => [
      {
        description: formatMessage({ id: 'CORE.TEXT.3DS-HELP.CREATE-NOTE-MOBILE' }),
        icon: <img width="54" height="80" alt="tip icon" src={doubleTap} />,
      },
      {
        description: formatMessage({ id: 'CORE.TEXT.3DS-HELP.ZOOM-MOBILE' }),
        icon: <img width="75" height="69" alt="tip icon" src={zoomIn} />,
      },
      {
        description: formatMessage({ id: 'CORE.TEXT.3DS-HELP.MOVE-MOBILE' }),
        icon: <img width="61" height="77" alt="tip icon" src={freeDrag} />,
      },
    ],
    [formatMessage],
  );

  const nonTouchDeviceItems = useMemo<Item[]>(
    () => [
      {
        description: formatMessage({ id: 'CORE.TEXT.3DS-HELP.CREATE-NOTE' }),
        icon: <img width={80} height={80} alt="tip icon" src={click} />,
      },
      {
        description: formatMessage({ id: 'CORE.TEXT.3DS-HELP.ZOOM' }),
        icon: <img width={80} height={80} alt="tip icon" src={mouse} />,
      },
      {
        // @ts-ignore - formatMessage with React Node
        description: formatMessage(
          { id: 'CORE.TEXT.3DS-HELP.MOVE' },
          { code: (chunks) => <code className="code--key">{chunks}</code> },
        ),
        icon: <img width={80} height={80} alt="tip icon" src={arrowsMove} />,
      },
    ],
    [formatMessage],
  );

  const items = isTouchEnabled() ? touchDeviceItems : nonTouchDeviceItems;

  return (
    <Modal centered isOpen={isOpen} size="lg" toggle={close}>
      <ModalHeader className="border-0 p-2 pb-0" toggle={close} />
      <ModalBody className="text-center pt-1">
        <Row xs={1} lg={3} className="g-1">
          {items.map((item, index) => (
            <Col className="mb-3" key={index}>
              <div className="d-flex justify-content-center align-items-end mb-2" style={{ minHeight: 80 }}>
                {item.icon}
              </div>
              <div className="fw-medium">{item.description}</div>
            </Col>
          ))}
        </Row>
        {hasDefaultHelp() && (
          <Button className="mt-2" color="primary-gradient" onClick={onConfirm}>
            {formatMessage({ id: 'CORE.BUTTON.DO-NOT-SHOW-AGAIN' })}
          </Button>
        )}
      </ModalBody>
    </Modal>
  );
};
