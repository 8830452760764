import React, { useCallback } from 'react';
import { IconCircleCheck } from '@tabler/icons-react';
import { PatientPhoneNumberOption } from 'app/types';

export const useOptionFormatter = () => {
  return useCallback((option: PatientPhoneNumberOption, meta: { context: 'menu' | 'value' }) => {
    const { value, label } = option;

    return (
      <div key={value} className="d-flex align-items-center">
        {meta.context === 'menu' && (
          <span className="me-2" style={{ width: 20 }}>
            {option.details?.isDefault && <IconCircleCheck size={18} strokeWidth={1.8} color="#2ace73" />}
          </span>
        )}
        <span>{label}</span>
      </div>
    );
  }, []);
};
