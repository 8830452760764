import { IntlFormatters } from '@formatjs/intl/src/types';
import { Modal, ModalBody, ModalHeader } from 'reactstrap';
import { useCallback, useMemo, useRef, useState } from 'react';
import { ColumnDescription } from 'react-bootstrap-table-next';
import {
  formattedDate,
  getTranslation,
  useAppDispatch,
  useAppIntl,
  useAppSelector,
  useCurrentUser,
  useModalCleaner,
} from 'app/helpers';
import { FORMATS_DATE_AND_TIME, RemoteTable } from 'app/shared';
import { InvoiceLogItem, Locale, RemoteDataParams } from 'app/types';
import { toggleModal } from 'app/redux/modals/modals.actions';
import { download } from 'app/redux/downloads/downloads.actions';
import { fetchInvoiceLogs } from 'app/redux/remoteData/remoteData.actions';
import {
  actionTakerFormatter,
  sourceFormatter,
  walletTransactionFormatter,
} from 'app/features/invoicingProcess/modals/invoiceHistoryModal/formatters';

export interface FormatExtraData {
  downloadSource?: (id: string, endpoint: string) => void;
  formatMessage?: IntlFormatters['formatMessage'];
  loadingSource?: string;
  locale?: Locale;
}

export const InvoiceHistoryModal = () => {
  const { token } = useCurrentUser();
  const dispatch = useAppDispatch();
  const modalRef = useRef(null);
  const { formatMessage, locale } = useAppIntl();
  const [loadingSource, setLoadingSource] = useState<string>('');
  const isOpen = useAppSelector((state) => state.modal.invoiceHistory.isOpen);
  const invoiceId = useAppSelector((state) => state.invoicingProcess.data?.invoiceId);
  const { data, loading, params } = useAppSelector((state) => state.remoteData.invoiceLogs);

  const closeModal = useCallback(() => {
    dispatch(toggleModal('invoiceHistory', false));
  }, [dispatch]);

  const downloadSource = useCallback(
    async (id: string, endpoint: string) => {
      setLoadingSource(id);
      await dispatch(
        download({
          token,
          locale,
          endpoint,
          withOpening: false,
          body: false,
        }),
      );
      setLoadingSource('');
    },
    [dispatch, locale, token],
  );

  const fetchData = useCallback(
    (params: RemoteDataParams) => {
      if (invoiceId) {
        dispatch(fetchInvoiceLogs(invoiceId, params));
      }
    },
    [dispatch, invoiceId],
  );

  const columns = useMemo<ColumnDescription<InvoiceLogItem, FormatExtraData>[]>(
    () => [
      {
        dataField: 'timeOfCreation',
        formatter: (cell, row) => formattedDate(row.timeOfCreation, FORMATS_DATE_AND_TIME),
        text: formatMessage({ id: 'CORE.LABEL.DATE-AND-TIME-OF-CREATION', defaultMessage: 'Date & Time of Creation' }),
      },
      {
        dataField: 'actionTaker',
        formatter: actionTakerFormatter,
        text: formatMessage({ id: 'CORE.LABEL.ACTION-TAKER', defaultMessage: 'Action Taker' }),
      },
      {
        dataField: 'actionType',
        formatter: (cell, row) => getTranslation(row.actionType, locale),
        text: formatMessage({ id: 'CORE.LABEL.TYPE', defaultMessage: 'Type' }),
      },
      {
        dataField: 'source',
        formatter: sourceFormatter,
        formatExtraData: { downloadSource, formatMessage, loadingSource },
        text: formatMessage({ id: 'CORE.LABEL.SOURCE', defaultMessage: 'Source' }),
      },
      {
        dataField: 'walletTransactionType',
        formatter: walletTransactionFormatter,
        formatExtraData: { formatMessage, locale },
        text: formatMessage({ id: 'CORE.LABEL.WALLET-TRANSACTION', defaultMessage: 'Wallet Transaction' }),
      },
    ],
    [downloadSource, formatMessage, loadingSource, locale],
  );

  useModalCleaner('invoiceHistory', modalRef);

  return (
    <Modal centered isOpen={isOpen} ref={modalRef} size="xl" toggle={closeModal}>
      <ModalHeader toggle={closeModal}>
        {formatMessage({ id: 'INVOICES.TEXT.INVOICE-HISTORY', defaultMessage: 'Invoice History' })}
      </ModalHeader>
      <ModalBody className="pt-2 pb-3">
        <RemoteTable
          columns={columns}
          data={data}
          fetchData={fetchData}
          id="invoiceHistory"
          initialFetch
          keyField="id"
          loading={loading}
          params={params}
          showRowId
        />
      </ModalBody>
    </Modal>
  );
};
