import { useMemo } from 'react';
import round from 'lodash/round';
import sumBy from 'lodash/sumBy';
import { checkIsTemporaryId, useAppSelector } from 'app/helpers';
import { InvoicePaymentMethodItem, PatientPaymentMethod } from 'app/types';

export const useAccountCredit = (): number => {
  const paymentMethodItems = useAppSelector((state) => state.invoicingProcess.paymentMethodItems);
  const totalAccountCreditAmount =
    useAppSelector((state) => state.invoicingProcess.data?.patientBalance.totalAccountCreditAmount) || 0;

  return useMemo<number>(() => {
    const accountCreditPayments = paymentMethodItems.filter(
      // take only newly created payment methods
      (item) => item.paymentMethod.value === PatientPaymentMethod.FromAccountCredit && checkIsTemporaryId(item.id),
    );

    const accountCreditPaymentsTotal = round(
      sumBy<InvoicePaymentMethodItem>(accountCreditPayments, (item) => item.amount),
      2,
    );

    return round(totalAccountCreditAmount - accountCreditPaymentsTotal, 2);
  }, [paymentMethodItems, totalAccountCreditAmount]);
};
