import moment from 'moment';

import { FORMATS_API_TIME, FORMATS_TIME, LANGUAGES_AR_CODE, LANGUAGES_EN_CODE } from 'app/shared';

export const generateTimeOption = (time) => {
  const timeObject = time instanceof moment ? time : moment(time, FORMATS_API_TIME);
  const label = timeObject.locale(LANGUAGES_EN_CODE).format(FORMATS_TIME);

  return {
    label,
    value: timeObject.locale(LANGUAGES_EN_CODE).format(FORMATS_API_TIME),
    translation: {
      en: label,
      ar: timeObject.locale(LANGUAGES_AR_CODE).format(FORMATS_TIME),
    },
  };
};
