import { Option } from 'app/types';

export enum Step {
  SelectTemplate,
  SelectLocation,
}

export enum Template {
  'MaleFullBody' = 'maleFullBody',
  'FemaleFullBody' = 'femaleFullBody',
  'PrimaryTeeth' = 'primaryTeeth',
  'AdultTeeth' = 'adultTeeth',
}

export interface TemplateConfiguration {
  fov: number;
  maxDistance: number;
  maxPolarAngle: number;
  minDistance: number;
  minPolarAngle: number;
}

export type Position = {
  x: number;
  y: number;
  z: number;
};

export enum NoteStatus {
  Open = '0',
  Completed = '1',
}

export interface Note {
  cameraPosition: Position;
  description?: string;
  element?: string;
  id: string;
  lookAt: Position;
  status: NoteStatus;
  template: Template;
  title?: string;
  /* ISOString */
  updateDate: string;
  billingItems?: Option[];
}

export interface TemplateNote extends Note {
  number: number;
}

export interface Values {
  notes: Note[];
  template: Template;
}
