import toast from 'react-hot-toast';
import { useCallback, useEffect, useState } from 'react';
import { IconPlaylistAdd } from '@tabler/icons-react';
import { Button, ModalBody, ModalHeader } from 'reactstrap';
import { RemoteDataParams } from 'app/types';
import { DEFAULT_PAGINATION_PARAMS, Loader, RemoteTable } from 'app/shared';
import { useAppDispatch, useAppIntl, useAppSelector, useCurrentUser, useModalState } from 'app/helpers';
import { useDeleteInvoiceDraftMutation } from 'app/services/invoices/invoices';
import { fetchAppointmentInvoices } from 'app/redux/remoteData/remoteData.actions';
import { useColumns } from 'app/features/invoicingProcess/invoices/helpers/useColumns';
import { ConfirmationModal } from 'app/components/confirmationModal/confirmationModal';
import { useInvoicingProcessActions } from 'app/features/invoicingProcess/helpers/useInvoicingProcessActions';

interface Props {
  closeInvoicingProcess: () => void;
}

export const invoicesModalBodyId = 'invoicesModalBody';

export const Invoices = ({ closeInvoicingProcess }: Props) => {
  const dispatch = useAppDispatch();
  const { formatMessage } = useAppIntl();
  const [selectedId, setSelectedId] = useState<string | undefined>();
  const [confirmationMessage, setConfirmationMessage] = useState<string>('');
  const [confirmationLoading, setConfirmationLoading] = useState<boolean>(false);
  const {
    isOpen: isConfirmationModalOpen,
    open: openConfirmationModal,
    close: closeConfirmationModal,
  } = useModalState();
  const { createInvoice } = useInvoicingProcessActions();
  const {
    currentUser: { allowedForCreateInvoice },
    isViewer,
  } = useCurrentUser();
  const appointmentId = useAppSelector((state) => state.invoicingProcess.appointmentId);
  const { data, loading, params } = useAppSelector((state) => state.remoteData.appointmentInvoices);
  const [deleteDraftTrigger] = useDeleteInvoiceDraftMutation();
  const hasInvoices = !!data.length;

  const fetchData = useCallback(
    (params: RemoteDataParams) => {
      if (appointmentId) {
        return dispatch(fetchAppointmentInvoices(params, appointmentId));
      }
    },
    [appointmentId, dispatch],
  );

  const onDeleteDraft = useCallback<(id: string) => void>(
    (id) => {
      setSelectedId(id);
      setConfirmationMessage(formatMessage({ id: 'CORE.TEXT.DELETE-ITEM-CONFIRMATION' }));
      openConfirmationModal();
    },
    [formatMessage, openConfirmationModal],
  );

  const onDeleteDraftConfirmation = useCallback(async () => {
    try {
      setConfirmationLoading(true);

      if (selectedId && params && appointmentId) {
        await deleteDraftTrigger(selectedId).unwrap();
        await dispatch(fetchAppointmentInvoices(params, appointmentId));
        closeConfirmationModal();
      }
    } catch (error) {
      toast.error('CORE.TEXT.DEFAULT-FAILURE-MESSAGE');
    } finally {
      setConfirmationLoading(false);
    }
  }, [appointmentId, closeConfirmationModal, deleteDraftTrigger, dispatch, params, selectedId]);

  const columns = useColumns({ onDeleteDraft });

  useEffect(() => {
    fetchData(DEFAULT_PAGINATION_PARAMS);
  }, [fetchData]);

  return (
    <>
      <ModalHeader toggle={closeInvoicingProcess}>
        {formatMessage({ id: 'CORE.TEXT.INVOICES', defaultMessage: 'Invoices' })}
      </ModalHeader>
      <ModalBody className="pt-1" id={invoicesModalBodyId}>
        {loading && <Loader />}
        {!loading && hasInvoices && (
          <RemoteTable
            columns={columns}
            data={data}
            fetchData={fetchData}
            id="appointmentInvoicesTable"
            keyField="id"
            loading={loading}
            params={params}
            showRowId
          />
        )}
        {!loading && (
          <div className="text-center mt-3">
            {!hasInvoices && (
              <p>
                {formatMessage({
                  id: 'CORE.TEXT.NO-APPOINTMENT-INVOICE',
                  defaultMessage: 'No invoice has been assigned to this appointment yet.',
                })}
              </p>
            )}
            {!!allowedForCreateInvoice && !isViewer && (
              <Button color="primary-gradient" onClick={() => createInvoice(appointmentId)}>
                <IconPlaylistAdd className="buttonIcon buttonIcon--left" size={22} strokeWidth={1.8} />
                {formatMessage({ id: 'CORE.BUTTON.ADD-NEW-INVOICE', defaultMessage: 'Add New Invoice' })}
              </Button>
            )}
          </div>
        )}
        <ConfirmationModal
          description={confirmationMessage}
          isOpen={isConfirmationModalOpen}
          loading={confirmationLoading}
          onClose={closeConfirmationModal}
          onConfirm={onDeleteDraftConfirmation}
        />
      </ModalBody>
    </>
  );
};
