import { ComponentType, useCallback, useMemo } from 'react';
import { ToolbarProps } from 'react-big-calendar';
import { Col, Row } from 'reactstrap';
import { SwitchButtons, SwitchButtonsItem } from 'app/components';
import { useAppDispatch, useAppIntl, useAppSelector } from 'app/helpers';
import { CalendarType } from 'app/types';
import { setCalendarType } from 'app/redux/calendar/calendar.actions';
import { CalendarDayEvent, CalendarDayResource } from 'app/calendar/types';
import styles from './toolbar.module.scss';

export const Toolbar: ComponentType<ToolbarProps<CalendarDayEvent, CalendarDayResource>> = (props) => {
  const dispatch = useAppDispatch();
  const { formatMessage } = useAppIntl();
  const type = useAppSelector((state) => state.calendar.type);
  const fullEditActive = useAppSelector((state) => state.appointment.fullEdit.isActive);

  const setType = useCallback(
    (type: CalendarType) => {
      dispatch(setCalendarType(type));
    },
    [dispatch],
  );

  const typeItems = useMemo(
    (): SwitchButtonsItem[] => [
      {
        active: type === CalendarType.Normal,
        onClick: () => setType(CalendarType.Normal),
        text: formatMessage({ id: 'CALENDAR.TEXT.NORMAL-TYPE' }),
      },
      {
        active: type === CalendarType.Waiting,
        onClick: () => setType(CalendarType.Waiting),
        text: formatMessage({ id: 'CALENDAR.TEXT.WAITING-TYPE' }),
        disabled: fullEditActive,
      },
    ],
    [formatMessage, fullEditActive, setType, type],
  );

  return (
    <Row className={styles.wrapper}>
      <Col />
      <Col md={6}>
        <h4 className="text-center mb-0">{props.label}</h4>
      </Col>
      <Col className="d-flex justify-content-center justify-content-md-end mt-3 mt-md-0">
        <SwitchButtons items={typeItems} />
      </Col>
    </Row>
  );
};
