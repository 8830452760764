import React, { useCallback, useMemo } from 'react';
import { ColumnDescription, ColumnFormatter } from 'react-bootstrap-table-next';
import { FormGroup, Input } from 'reactstrap';
import classnames from 'classnames';
import { useAppIntl, getTranslation } from 'app/helpers';
import { DotsMenu } from 'app/components';
import { LocalTable } from 'app/shared/localTable/localTable';
import { Note, NoteStatus, Template } from 'app/components/locationSelector/procedureLocation/types';
import { Option } from 'app/types';

interface FormatExtraDataProps {
  notesContainerId?: string;
}

interface Props {
  className?: string;
  deleteNote: (id: string) => void;
  editNote: (note: Note) => void;
  onManageBillingItems?: (index: number, values?: Option[]) => void;
  changeStatus: (id: string, newStatus: NoteStatus) => void;
  notes: Note[] | undefined;
  isReadOnly?: boolean;
  notesContainerId?: string;
  withStatus: boolean;
}

export const Notes = ({
  className,
  editNote,
  deleteNote,
  onManageBillingItems,
  changeStatus,
  notes,
  isReadOnly,
  notesContainerId,
  withStatus,
}: Props) => {
  const { formatMessage, locale } = useAppIntl();

  // Conditions
  const hasToothNumberCol = !!notes?.some((note) =>
    [Template.AdultTeeth, Template.PrimaryTeeth].includes(note.template),
  );

  // Formatters
  const actionsFormatter: ColumnFormatter<Note, FormatExtraDataProps> = useCallback(
    (_, row, rowIndex, formatExtraData) => {
      const items = [
        {
          isVisible: true,
          label: formatMessage({ id: 'CORE.BUTTON.EDIT' }),
          onClick: () => editNote(row),
        },
        {
          isVisible: !!onManageBillingItems,
          label: formatMessage({ id: 'CORE.TEXT.BILLING-ITEMS' }),
          onClick: () => onManageBillingItems && onManageBillingItems(rowIndex, row.billingItems),
        },
        {
          isVisible: true,
          label: formatMessage({ id: 'CORE.BUTTON.DELETE' }),
          onClick: () => deleteNote(row.id),
        },
      ];

      return <DotsMenu items={items} container={formatExtraData?.notesContainerId} />;
    },
    [deleteNote, editNote, formatMessage, onManageBillingItems],
  );

  const statusFormatter = useCallback<ColumnFormatter<Note>>((_, row, rowIndex, { changeStatus, isReadOnly }) => {
    return (
      <FormGroup check inline className="m-0 mt-1" style={{ transform: 'scale(0.9)' }}>
        <Input
          defaultChecked={row.status === NoteStatus.Completed}
          id={`locationNoteStatusCheckbox${rowIndex}`}
          name="locationNoteStatusCheckbox"
          type="checkbox"
          disabled={isReadOnly}
          onChange={(e) => changeStatus(row.id, e.target.checked ? NoteStatus.Completed : NoteStatus.Open)}
        />
      </FormGroup>
    );
  }, []);

  // Columns
  const columns = useMemo<ColumnDescription<Note>[]>(
    () => [
      {
        dataField: 'id',
        formatter: (_, row, rowIndex) => rowIndex + 1,
        text: '#',
      },
      {
        dataField: 'status',
        hidden: !withStatus,
        formatter: statusFormatter,
        formatExtraData: { changeStatus, isReadOnly },
        text: formatMessage({ id: 'CORE.LABEL.STATUS' }),
        classes: 'text-center',
        headerClasses: 'text-center',
      },
      {
        dataField: 'title',
        formatter: (_, row) => (
          <span className={classnames({ 'text-decoration-line-through': row.status === NoteStatus.Completed })}>
            {row.title || '-'}
          </span>
        ),
        style: { minWidth: 100 },
        text: formatMessage({ id: 'CORE.LABEL.TITLE', defaultMessage: 'Title' }),
      },
      {
        dataField: 'description',
        formatter: (_, row) => (
          <span className={classnames({ 'text-decoration-line-through': row.status === NoteStatus.Completed })}>
            {row.description || '-'}
          </span>
        ),
        style: { minWidth: 180 },
        text: formatMessage({ id: 'CORE.LABEL.DESCRIPTION', defaultMessage: 'Description' }),
      },
      {
        dataField: 'billingItems',
        hidden: !onManageBillingItems,
        formatter: (_, row) => (
          <>{row.billingItems ? row.billingItems.map((item) => getTranslation(item, locale)).join(', ') : '-'}</>
        ),
        style: { minWidth: 100 },
        text: formatMessage({ id: 'CORE.TEXT.BILLING-ITEMS', defaultMessage: 'Billing Items' }),
      },
      {
        dataField: 'element',
        hidden: !hasToothNumberCol,
        formatter: (_: string, row: Note) => row.element || '-',
        style: { width: 120 },
        text: formatMessage({ id: 'CORE.LABEL.TOOTH-NUMBER', defaultMessage: 'Tooth number' }),
      },
      {
        classes: 'text-center sticky sticky--end',
        dataField: 'actions',
        hidden: !!isReadOnly,
        formatter: actionsFormatter,
        formatExtraData: notesContainerId ? { notesContainerId } : undefined,
        headerClasses: 'text-center sticky sticky--end',
        text: formatMessage({ id: 'CORE.TABLE.ACTIONS' }),
      },
    ],
    [
      actionsFormatter,
      changeStatus,
      formatMessage,
      hasToothNumberCol,
      isReadOnly,
      notesContainerId,
      statusFormatter,
      withStatus,
      onManageBillingItems,
      locale,
    ],
  );

  return (
    <LocalTable
      classes="table-sm"
      columns={columns}
      data={notes || []}
      id="procedureLocationFieldNotes"
      keyField="id"
      loading={false}
      noDataComponent={<></>}
      wrapperClasses={className}
    />
  );
};
