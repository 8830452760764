import { useCallback, useMemo } from 'react';
import truncate from 'lodash/truncate';
import { IconEye } from '@tabler/icons-react';
import { ColumnDescription } from 'react-bootstrap-table-next';
import { formattedDate, useAppDispatch, useAppIntl } from 'app/helpers';
import { IconButton } from 'app/components';
import { toggleModal } from 'app/redux/modals/modals.actions';
import { setInformation } from 'app/redux/doctorAssessment/doctorAssessment.actions';
import { ProcedureResponse } from 'app/redux/doctorAssessment/types';

interface Props {
  dataField: string;
  text: string;
  modalColumns: any;
}

export const useUnstyledColumns = ({ dataField, text }: Props) => {
  const dispatch = useAppDispatch();
  const { formatMessage } = useAppIntl();

  const toggle = useCallback(
    (data: ProcedureResponse) => {
      dispatch(setInformation(data, text));
      dispatch(toggleModal('assessmentInformation'));
    },
    [text, dispatch],
  );

  return useMemo(
    (): ColumnDescription<ProcedureResponse>[] => [
      {
        dataField: dataField,
        text: formatMessage({ id: text }),
        formatter: (_, row) => <span>{truncate(row.text, { length: 60 })}</span>,
        classes: 'w-75',
      },
      {
        dataField: 'date',
        text: formatMessage({ id: 'CORE.TABLE.DATE' }),
        formatter: (_, row) => formattedDate(row.createdAt) || '-',
      },
      {
        dataField: '',
        text: '',
        formatExtraData: { toggle },
        formatter: (_, row, i, { toggle }) => (
          <IconButton
            icon={<IconEye size={22} strokeWidth={1.8} />}
            tooltipMessageId="CORE.BUTTON.SHOW"
            onClick={() => toggle(row)}
          />
        ),
        classes: 'text-right',
      },
    ],
    [toggle, dataField, text, formatMessage],
  );
};
