import { select, put } from 'redux-saga/effects';
import { PayloadAction } from '@reduxjs/toolkit';
import round from 'lodash/round';
import { RootState } from 'app/types';
import { BillingItemPaymentType, RefreshBillingItemsStatusesEvent } from 'app/assessment/helpers/useLiveRefresh';
import {
  WS_FETCH_COVERED_BILLING_ITEMS_STATUSES,
  WS_FETCH_NOT_COVERED_BILLING_ITEMS_STATUSES,
  WsRefreshAction,
} from 'app/sagas/assessment/types';
import { BillingFormData } from 'app/assessment/billingSection/forms/billingForm/billingForm';
import { FORMS_ASSESSMENT_BILLING } from 'app/shared';

export function* wsUpdateBillingItemsStatuses({ payload }: PayloadAction<RefreshBillingItemsStatusesEvent>) {
  const {
    doctorAssessment: { data },
    form,
  }: RootState = yield select();

  // Helper Variables
  const formValues: BillingFormData | undefined = form[FORMS_ASSESSMENT_BILLING]?.values;
  const items = formValues?.assessmentBillingItems;

  // Conditions
  const isAppointmentIdMatch = data?.appointmentId === payload.AppointmentId;
  const patientInsuranceId = data?.billingSection.patientInsuranceId;

  if (isAppointmentIdMatch) {
    if (payload.ActionType === BillingItemPaymentType.Insurance && patientInsuranceId && items) {
      const filteredItems = items.filter(
        (item) => item.canBeCoveredByInsurance && item.onInsurance && !item.invoicePackageSnapshotId,
      );

      if (filteredItems) {
        yield put<WsRefreshAction>({
          type: WS_FETCH_COVERED_BILLING_ITEMS_STATUSES,
          payload: {
            patientInsuranceId,
            appointmentId: data.appointmentId,
            body: filteredItems.map((item) => ({
              performerBillingItemId: item.performerBillingItemId,
              quantity: item.quantity,
              totalItemBillingSum: round(item.quantity * item.insurancePricePerItem!, 2),
              invoicePackageSnapshotId: item.invoicePackageSnapshotId,
              invoicePackageItemSnapshotId: item.invoicePackageItemSnapshotId,
            })),
          },
        });
      }
    }

    if (payload.ActionType === BillingItemPaymentType.Regular && items) {
      // [i]: We don't need to filter items - PT-6802 and PT-8695
      yield put<WsRefreshAction>({
        type: WS_FETCH_NOT_COVERED_BILLING_ITEMS_STATUSES,
        payload: {
          appointmentId: data.appointmentId,
          body: items.map((item) => ({
            performerBillingItemId: item.performerBillingItemId,
            quantity: item.quantity,
            totalItemBillingSum: round(item.quantity * item.originalPricePerItem, 2),
            invoicePackageSnapshotId: item.invoicePackageSnapshotId,
            invoicePackageItemSnapshotId: item.invoicePackageItemSnapshotId,
          })),
        },
      });
    }
  }
}
