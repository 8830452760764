import { exclusionsTab } from 'app/routes/insurancesManagement/constants';
import { ExclusionsTab } from 'app/routes/insurancesManagement/types';

export const generateUrlChunk = (exclusionsTabId: ExclusionsTab) => {
  switch (exclusionsTabId) {
    case exclusionsTab.billingItem:
      return 'billing-item-exclusions';
    case exclusionsTab.specialty:
      return 'speciality-exclusions';
    case exclusionsTab.practitioner:
      return 'practitioner-exclusions';
    case exclusionsTab.ICD10:
      return 'ICD10-exclusions';
  }
};
