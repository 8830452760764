import { PayloadAction } from '@reduxjs/toolkit';
import { Meta } from 'app/sagas/billingItemLists/types';
import { refreshPerformerItemLists } from 'app/sagas/billingItemLists/generators/refreshPerformerItemLists';
import { REMOVE_PERFORMER_BILLING_ITEM_SUCCESS } from 'app/redux/billingItemsLists/billingItemsLists.types';

export function* afterRemoveBillingItemSuccess({ meta, type }: PayloadAction<void, string, Meta>) {
  if (type === REMOVE_PERFORMER_BILLING_ITEM_SUCCESS) {
    yield refreshPerformerItemLists(meta.branchId, true);
  }
}
