export const getCustomTimeSlotOptions = () => {
  return Array.from(
    {
      // number of items in range 5 to 480
      length: 96,
    },
    (v, i) => {
      let m = (i + 1) * 5;
      return {
        label: `${m} minutes`,
        translation: {
          ar: `${m} الدقائق `,
          en: `${m} minutes`,
        },
        value: m,
      };
    },
  );
};
