import { generatePath } from 'react-router';
import { doctorsBranchPath, doctorsPath } from 'app/routes/doctors/constants';
import { DoctorsTab } from 'app/routes/doctors/types';

export const generateDoctorsPath = (tab: DoctorsTab, clinicId: string) =>
  generatePath(doctorsPath, {
    clinicId,
    tab,
  });

export const generateDoctorsBranchPath = (tab: DoctorsTab, clinicId: string, branchId: string) =>
  generatePath(doctorsBranchPath, {
    clinicId,
    branchId,
    tab,
  });
