import { AnyAction } from 'redux';
import { Doctor, Option, RemoteData } from 'app/types';
import * as types from './book-appointment.types';
import { PatientInfoShort } from 'app/shared/patientSearch';
import { DoctorData, ReferralInfo } from './types';
import { SelectedSlot } from '../calendar/types';

export interface BookAppointmentReducer {
  branches: RemoteData<Option[]>;
  doctors: RemoteData<Doctor[]>;
  specialities: RemoteData<Option[]>;
  subSpecialities: RemoteData<Option[]>;
  products: RemoteData<Option[]>;
  selectedPatient: PatientInfoShort | null;
  selectedSlot: SelectedSlot | null;
  selectedBranch: Option | null;
  selectedSpeciality: Option | null;
  selectedDoctor: DoctorData | null;
  selectedService: Option | null;
  selectedSubSpeciality: Option | null;
  registerPatientModal: { patientId: string } | null;
  referralInfo: ReferralInfo | null;
}

export const initialState: BookAppointmentReducer = {
  branches: {
    data: [],
    loading: false,
  },
  doctors: {
    data: [],
    loading: false,
  },
  specialities: {
    data: [],
    loading: false,
  },
  subSpecialities: {
    data: [],
    loading: false,
  },
  products: {
    data: [],
    loading: false,
  },

  // Patient data
  selectedPatient: null,

  selectedBranch: null,
  selectedSlot: null,
  selectedSpeciality: null,
  selectedDoctor: null,
  selectedService: null,
  selectedSubSpeciality: null,
  registerPatientModal: null,
  referralInfo: null,
};

export const bookAppointmentReducer = (state = initialState, action: AnyAction): BookAppointmentReducer => {
  switch (action.type) {
    case types.FETCH_BRANCHES_REQUEST:
      return {
        ...state,
        branches: {
          data: initialState.branches.data,
          loading: true,
        },
      };
    case types.FETCH_BRANCHES_SUCCESS:
      return {
        ...state,
        branches: {
          data: action.payload.data,
          loading: false,
          params: {
            ...state.branches.params,
            ...action.payload.pagination,
          },
        },
      };
    case types.FETCH_BRANCHES_FAILURE:
      return {
        ...state,
        branches: {
          ...initialState.branches,
          error: true,
        },
      };
    case types.FETCH_DOCTORS_REQUEST:
      return {
        ...state,
        doctors: {
          data: initialState.doctors.data,
          loading: true,
        },
      };
    case types.FETCH_DOCTORS_SUCCESS:
      return {
        ...state,
        doctors: {
          data: action.payload.data,
          loading: false,
          params: {
            ...state.doctors.params,
            ...action.payload.pagination,
          },
        },
      };
    case types.FETCH_DOCTORS_FAILURE:
      return {
        ...state,
        doctors: {
          ...initialState.doctors,
          error: true,
        },
      };
    case types.FETCH_SPECIALITIES_REQUEST:
      return {
        ...state,
        specialities: {
          data: state.specialities.data,
          loading: true,
        },
      };
    case types.FETCH_SPECIALITIES_SUCCESS:
      return {
        ...state,
        specialities: {
          data: action.payload.data,
          loading: false,
        },
      };
    case types.FETCH_SPECIALITIES_FAILURE:
      return {
        ...state,
        subSpecialities: {
          ...state.subSpecialities,
          error: true,
        },
      };
    case types.FETCH_SUB_SPECIALITIES_REQUEST:
      return {
        ...state,
        subSpecialities: {
          data: state.subSpecialities.data,
          loading: true,
        },
      };
    case types.FETCH_SUB_SPECIALITIES_SUCCESS:
      return {
        ...state,
        subSpecialities: {
          data: action.payload.data,
          loading: false,
        },
      };
    case types.FETCH_SUB_SPECIALITIES_FAILURE:
      return {
        ...state,
        subSpecialities: {
          ...state.subSpecialities,
          error: true,
        },
      };

    case types.FETCH_PRODUCTS_REQUEST:
      return {
        ...state,
        products: {
          data: state.products.data,
          loading: true,
        },
      };
    case types.FETCH_PRODUCTS_SUCCESS:
      return {
        ...state,
        products: {
          data: action.payload.data,
          loading: false,
        },
      };
    case types.FETCH_PRODUCTS_FAILURE:
      return {
        ...state,
        products: {
          ...state.products,
          error: true,
        },
      };
    case types.FETCH_DOCTOR_REQUEST:
      return {
        ...state,
        selectedDoctor: initialState.selectedDoctor,
        selectedSpeciality: initialState.selectedSpeciality,
      };
    case types.FETCH_DOCTOR_SUCCESS:
      return {
        ...state,
        selectedDoctor: action.payload.data,
        selectedSpeciality: action.payload.data.speciality,
      };
    case types.FETCH_DOCTOR_FAILURE:
      return {
        ...state,
        selectedDoctor: null,
        selectedSpeciality: null,
      };
    case types.SET_PATIENT:
      return {
        ...state,
        selectedPatient: action.payload,
      };
    case types.RESET_PATIENT:
      return {
        ...state,
        selectedPatient: initialState.selectedPatient,
      };
    case types.SET_PATIENT_DEPENDANTS:
      return {
        ...state,
        selectedPatient: null,
      };
    // Register Patient Modal
    case types.SET_APPOINTMENT_REGISTER_PATIENT:
      return {
        ...state,
        registerPatientModal: {
          patientId: action.meta.patientId,
        },
        selectedPatient: initialState.selectedPatient,
      };
    case types.RESET_APPOINTMENT_REGISTER_PATIENT:
      return {
        ...state,
        registerPatientModal: initialState.registerPatientModal,
      };
    // Select time slot
    case types.SELECT_SLOT:
      return {
        ...state,
        selectedSlot: action.payload,
      };
    // Select branch
    case types.SELECT_BRANCH:
      return {
        ...state,
        selectedBranch: action.payload,
        ...(action.meta.keepDoctor ? {} : { selectedDoctor: null }),
        ...(action.meta.keepSpeciality ? {} : { selectedSpeciality: null }),
        selectedService: null,
        selectedSubSpeciality: null,
      };

    // Select doctor
    case types.SELECT_DOCTOR:
      return {
        ...state,
        selectedDoctor: action.payload,
        selectedSpeciality: action.payload?.speciality || null,
        selectedSubSpeciality: null,
      };

    // Select service
    case types.SELECT_SERVICE:
      return {
        ...state,
        selectedService: action.payload,
      };

    // Select speciality
    case types.SELECT_SPECIALITY:
      return {
        ...state,
        selectedSpeciality: action.payload,
        selectedDoctor: null,
        selectedService: null,
        selectedSubSpeciality: null,
      };

    // Select subSpeciality
    case types.SELECT_SUB_SPECIALITY:
      return {
        ...state,
        selectedSubSpeciality: action.payload,
        selectedService: null,
      };

    case types.SET_REFERRAL_INFO:
      return {
        ...state,
        referralInfo: action.payload,
      };

    case types.RESET:
      return initialState;
    // Default
    default:
      return state;
  }
};
