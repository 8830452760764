import { select } from 'redux-saga/effects';
import { matchPath, match } from 'react-router-dom';
import {
  isTreatmentPlanPage,
  isDoctorAssessmentPage,
  isTreatmentRoute,
  isGeneralTreatmentPage,
  isClinicTreatmentPage,
} from 'app/sagas/treatment/helpers';
import { refreshTreatmentPlan } from 'app/sagas/treatment/generators/refreshTreatmentPlan';
import { refreshTreatmentHeader } from 'app/sagas/treatment/generators/refreshTreatmentHeader';
import { RootState } from 'app/types';
import { FORMS_GENERAL_TREATMENT_PAGE_FORM, ROLES_DOCTOR, ROLES_NURSE, ROLES_OWNER } from 'app/shared';
import { FiltersFormData } from 'app/generalTreatmentPage/types';
import { checkAccess } from 'app/helpers';
import { refreshGeneralTreatmentPage } from 'app/sagas/treatment/generators/refreshGeneralTreatmentPage';
import { refreshClinicTreatmentPage } from 'app/sagas/treatment/generators/refreshClinicTreatmentPage';

export function* refreshTreatmentInfo() {
  const pathname = window.location.pathname;
  const {
    form,
    auth: { current },
  }: RootState = yield select();

  if (isTreatmentRoute(pathname)) {
    // url is: '/treatment/:appointmentId' or '/treatment/:appointmentId/<rest of url>'
    const match: match<{ appointmentId: string }> | null = matchPath(pathname, {
      path: '/treatment/:appointmentId',
    });

    const appointmentId = match?.params?.appointmentId || '';

    if (isTreatmentPlanPage(pathname) && appointmentId) {
      yield refreshTreatmentPlan(appointmentId);
    } else if (isDoctorAssessmentPage(pathname) && appointmentId) {
      yield refreshTreatmentHeader(appointmentId);
    }
  } else if (isGeneralTreatmentPage(pathname)) {
    // url is: '/treatment/patients/:patientId/general'
    const match: match<{ patientId: string }> | null = matchPath(pathname, {
      path: '/treatment/patients/:patientId/general',
    });

    const patientId = match?.params?.patientId || '';
    const hasAccess = checkAccess([ROLES_DOCTOR, ROLES_OWNER, ROLES_NURSE], current.roles);

    let clinicId;
    if (hasAccess) {
      clinicId = current.clinic?.value;
    } else {
      const formValues: Partial<FiltersFormData> | undefined = form[FORMS_GENERAL_TREATMENT_PAGE_FORM]?.values;
      clinicId = formValues?.clinic?.value;
    }

    if (patientId && clinicId) {
      yield refreshGeneralTreatmentPage(clinicId, patientId);
    }
  } else if (isClinicTreatmentPage(pathname)) {
    const match: match<{ patientId: string }> | null = matchPath(pathname, {
      path: '/treatment/clinics/:clinicId/patients/:patientId',
    });

    const patientId = match?.params?.patientId || '';

    if (patientId) {
      yield refreshClinicTreatmentPage(patientId);
    }
  }
}
