import { WorkingTime } from 'app/redux/bookingWizard/types';
import moment from 'moment';

export const isTimePeriodValid = (workingTime: WorkingTime, start: Date | string, end: Date | string) => {
  const startTime = moment(workingTime.startTime);
  const endTime = moment(workingTime.endTime);

  const testStart = moment(start);
  const testEnd = moment(end);

  if (testStart >= testEnd) {
    return false;
  }

  return testStart >= startTime && testEnd <= endTime && testStart < endTime && testEnd > startTime;
};
