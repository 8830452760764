import { FormErrors } from 'redux-form';
import { ErrorType } from 'app/types';
import { required } from 'app/shared';
import { DetailsField, DetailsFormData } from 'app/features/invoicingProcess/process/details/form/types';

export const validate = (values?: Partial<DetailsFormData>): FormErrors<DetailsFormData, ErrorType> => {
  let errors: Partial<{ [Property in keyof DetailsFormData]: ErrorType }> = {};

  // #1. Check required fields
  const requiredFields = [DetailsField.branch, DetailsField.doctor, DetailsField.patient];

  requiredFields.forEach((field) => {
    const validation = required(values?.[field]);

    if (validation) {
      errors = {
        ...errors,
        [field]: validation,
      };
    }
  });

  return errors;
};
