export const MASS_ADD_PATIENTS_REQUEST = 'mass-add/patients/REQUEST';
export const MASS_ADD_PATIENTS_SUCCESS = 'mass-add/patients/SUCCESS';
export const MASS_ADD_PATIENTS_FAILURE = 'mass-add/patients/FAILURE';

export const MASS_ADD_PRODUCTS_REQUEST = 'mass-add/products/REQUEST';
export const MASS_ADD_PRODUCTS_SUCCESS = 'mass-add/products/SUCCESS';
export const MASS_ADD_PRODUCTS_FAILURE = 'mass-add/products/FAILURE';
export const MASS_ADD_PRODUCTS_RESET = 'mass-add/products/RESET';

export const MASS_ADD_BRANCH_BILLING_ITEMS_REQUEST = 'mass-add/branch-billing-items/REQUEST';
export const MASS_ADD_BRANCH_BILLING_ITEMS_SUCCESS = 'mass-add/branch-billing-items/SUCCESS';
export const MASS_ADD_BRANCH_BILLING_ITEMS_FAILURE = 'mass-add/branch-billing-items/FAILURE';
export const MASS_ADD_BRANCH_BILLING_ITEMS_RESET = 'mass-add/branch-billing-items/RESET';

export const MASS_ADD_BILLING_ITEMS_REQUEST = 'mass-add/billingItems/REQUEST';
export const MASS_ADD_BILLING_ITEMS_SUCCESS = 'mass-add/billingItems/SUCCESS';
export const MASS_ADD_BILLING_ITEMS_FAILURE = 'mass-add/billingItems/FAILURE';
export const MASS_ADD_BILLING_ITEMS_RESET = 'mass-add/billingItems/RESET';

export const MASS_IMPORT_BILLING_ITEMS_REQUEST = 'mass-add/importBillingItems/REQUEST';
export const MASS_IMPORT_BILLING_ITEMS_SUCCESS = 'mass-add/importBillingItems/SUCCESS';
export const MASS_IMPORT_BILLING_ITEMS_FAILURE = 'mass-add/importBillingItems/FAILURE';
export const MASS_IMPORT_BILLING_ITEMS_RESET = 'mass-add/importBillingItems/RESET';

export const MASS_ADD_BRANCH_PACKAGES_REQUEST = 'mass-add/MASS_ADD_BRANCH_PACKAGES_REQUEST';
export const MASS_ADD_BRANCH_PACKAGES_SUCCESS = 'mass-add/MASS_ADD_BRANCH_PACKAGES_SUCCESS';
export const MASS_ADD_BRANCH_PACKAGES_FAILURE = 'mass-add/MASS_ADD_BRANCH_PACKAGES_FAILURE';
export const MASS_ADD_BRANCH_PACKAGES_RESET = 'mass-add/branchPackage/RESET';

export const MASS_ADD_PRACTITIONER_PACKAGES_REQUEST = 'mass-add/MASS_ADD_PRACTITIONER_PACKAGES_REQUEST';
export const MASS_ADD_PRACTITIONER_PACKAGES_SUCCESS = 'mass-add/MASS_ADD_PRACTITIONER_PACKAGES_SUCCESS';
export const MASS_ADD_PRACTITIONER_PACKAGES_FAILURE = 'mass-add/MASS_ADD_PRACTITIONER_PACKAGES_FAILURE';
export const MASS_ADD_PRACTITIONER_PACKAGES_RESET = 'mass-add/practitionerPackage/RESET';

export const MASS_IMPORT_PRACTITIONER_PACKAGES_REQUEST = 'mass-add/importPractitionerPackages/REQUEST';
export const MASS_IMPORT_PRACTITIONER_PACKAGES_SUCCESS = 'mass-add/importBillingItems/SUCCESS';
export const MASS_IMPORT_PRACTITIONER_PACKAGES_FAILURE = 'mass-add/importBillingItems/FAILURE';
export const MASS_IMPORT_PRACTITIONER_PACKAGES_RESET = 'mass-add/importBillingItems/RESET';

export const MASS_ADD_BRANCH_MEDICATIONS_REQUEST = 'mass-add/MASS_ADD_BRANCH_MEDICATION_REQUEST';
export const MASS_ADD_BRANCH_MEDICATIONS_SUCCESS = 'mass-add/MASS_ADD_BRANCH_MEDICATION_SUCCESS';
export const MASS_ADD_BRANCH_MEDICATIONS_FAILURE = 'mass-add/MASS_ADD_BRANCH_MEDICATION_FAILURE';
export const MASS_ADD_BRANCH_MEDICATIONS_RESET = 'mass-add/branchMedication/RESET';

export const MASS_ADD_PERFORMERS_MEDICATIONS_REQUEST = 'mass-add/MASS_ADD_PERFORMERS_MEDICATION_REQUEST';
export const MASS_ADD_PERFORMERS_MEDICATIONS_SUCCESS = 'mass-add/MASS_ADD_PERFORMERS_MEDICATION_SUCCESS';
export const MASS_ADD_PERFORMERS_MEDICATIONS_FAILURE = 'mass-add/MASS_ADD_PERFORMERS_MEDICATION_FAILURE';
export const MASS_ADD_PERFORMERS_MEDICATIONS_RESET = 'mass-add/performerMedication/RESET';
