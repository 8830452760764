import moment from 'moment/moment';

import { CalendarItem } from 'app/types';
import { CalendarWeekParams } from 'app/redux/calendar/types';
import { DEFAULT_TIME_FROM, DEFAULT_TIME_TO, FORMATS_API_DATE } from 'app/shared';
import { TimeRange } from 'app/bookAppointment/types';

export const generateCalendarUpdateParams = (
  data: CalendarItem[],
  timeRange: TimeRange,
  customDuration?: string,
): CalendarWeekParams[] => {
  const dateOrNow = (date: string): moment.Moment => (moment(date).isBefore(moment()) ? moment() : moment(date));
  const { timeFrom, timeTo } = timeRange || {};

  return data.map((item) => {
    const dateFrom = item.days[0]?.date;
    return {
      branchId: item.branch.id,
      date: dateOrNow(dateFrom).format(FORMATS_API_DATE),
      doctorId: item.doctor.id,
      timeFrom: timeFrom || DEFAULT_TIME_FROM,
      timeTo: timeTo || DEFAULT_TIME_TO,
      productRelatedFilters: {
        productId: item.product.value,
        specialityId: item.speciality.value,
        subSpecialityId: item.subSpeciality.value,
        customDurationInMinutes: customDuration,
      },
    };
  });
};
