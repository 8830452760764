import { useCallback } from 'react';
import { FormattedMessage } from 'react-intl';
import { FormGroup, Label } from 'reactstrap';
import { MapWithPlaceAutocomplete } from 'app/components/map/mapWithPlaceAutocomplete/mapWithPlaceAutocomplete';

export const MapComponent = (props) => {
  const {
    id,
    input: { onChange, name, value },
    isRequired,
    label,
    meta: { error, touched },
  } = props;
  const fieldId = id || name;
  const invalid = !!error && touched;
  const defaultCenter = value || undefined;
  const position = value || null;

  const setPosition = useCallback((location) => onChange(location), [onChange]);

  return (
    <FormGroup>
      <Label className="label--relative" for={fieldId}>
        {label}
        {isRequired && <span className="text-danger">&nbsp;*</span>}
      </Label>
      <MapWithPlaceAutocomplete
        defaultCenter={defaultCenter}
        invalid={invalid}
        position={position}
        setPosition={setPosition}
      />
      {invalid && (
        <div className="invalid-msg">
          <FormattedMessage id={error} />
        </div>
      )}
    </FormGroup>
  );
};
