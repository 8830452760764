import { submit } from 'redux-form';
import React, { useCallback, useRef } from 'react';
import { FormSubmitHandler } from 'redux-form/lib/reduxForm';
import { FORMS_CREDIT_NOTE_PACKAGE_ACTIONS } from 'app/shared';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader, Spinner } from 'reactstrap';
import { useAppDispatch, useAppIntl, useAppSelector, useModalCleaner } from 'app/helpers';
import { toggleModal } from 'app/redux/modals/modals.actions';
import { useOnCreateCreditNote } from 'app/features/invoicingProcess/process/actions/helpers/useOnCreateCreditNote';
import { CreditNotePackageActionsFormData } from 'app/features/invoicingProcess/modals/creditNotePackageActionsModal/creditNotePackageActionsForm/types';
import { CreditNotePackageActionsForm } from 'app/features/invoicingProcess/modals/creditNotePackageActionsModal/creditNotePackageActionsForm/creditNotePackageActionsForm';

export const CreditNotePackageActionsModal = () => {
  const dispatch = useAppDispatch();
  const { formatMessage } = useAppIntl();
  const modalRef = useRef(null);
  const { createCreditNoteLoading, onCreateCreditNote } = useOnCreateCreditNote();
  const isOpen = useAppSelector((state) => state.modal.creditNotePackageActions.isOpen);
  const creditNoteConfiguration = useAppSelector((state) => state.invoicingProcess.creditNoteConfiguration);
  const closeModal = useCallback(() => {
    dispatch(toggleModal('creditNotePackageActions', false));
  }, [dispatch]);

  const submitForm = useCallback(() => {
    dispatch(submit(FORMS_CREDIT_NOTE_PACKAGE_ACTIONS));
  }, [dispatch]);

  const onSubmit = useCallback<FormSubmitHandler<CreditNotePackageActionsFormData>>(
    async (values, dispatch) => {
      if (creditNoteConfiguration) {
        onCreateCreditNote({
          ...creditNoteConfiguration,
          packageAction: values.packageAction,
        });
      }
    },
    [creditNoteConfiguration, onCreateCreditNote],
  );

  useModalCleaner('creditNotePackageActions', modalRef);

  return (
    <Modal centered isOpen={isOpen} ref={modalRef} size="lg" toggle={closeModal}>
      <ModalHeader toggle={closeModal}>{formatMessage({ id: 'CORE.TEXT.CONFIRMATION' })}</ModalHeader>
      <ModalBody className="pb-1">
        <CreditNotePackageActionsForm onSubmit={onSubmit} />
      </ModalBody>
      <ModalFooter>
        <Button
          className="btn btn-responsive btn-responsive--lg"
          disabled={createCreditNoteLoading}
          color="primary"
          onClick={closeModal}
          outline
        >
          {formatMessage({ id: 'CORE.BUTTON.CANCEL' })}
        </Button>
        <Button
          className="btn btn-responsive btn-responsive--lg"
          color="primary-gradient"
          disabled={createCreditNoteLoading}
          onClick={submitForm}
        >
          {createCreditNoteLoading ? <Spinner size="sm" /> : formatMessage({ id: 'CORE.BUTTON.CONFIRM' })}
        </Button>
      </ModalFooter>
    </Modal>
  );
};
