import { Option } from 'app/types/Option';

export interface MassAddingFile {
  id: string;
  fileName: string;
  date: string;
  clinic: Option;
  massAddingStatus: Option<MassAddingFilesStatus>;
  uploadingStatus: Option<MassAddingFilesUploadingStatus>;
  uploadedBy: Option;
}

export interface ClinicMassAddingFile {
  id: string;
  name: string;
  url: string;
}

export interface ClinicMassAddingFilePreUpload {
  items: {
    fileId: string;
    storingFileName: string;
    originalFileName: string;
    zipProcessingRequired?: boolean;
  }[];
}

export enum MassAddingFilesStatus {
  Pending = '0',
  Processing = '1',
  Processed = '2',
}
export enum MassAddingFilesUploadingStatus {
  Uploading = '0',
  Finished = '1',
}
