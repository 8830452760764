import { getApiDate } from 'app/helpers';
import {
  PatientInsuranceField,
  PatientInsuranceFormData,
} from 'app/features/patient/patientInsuranceDetailsForm/types';

export const formEditValuesAdapter = (values: PatientInsuranceFormData) => ({
  deactivated: !values[PatientInsuranceField.activated] || false,
  clinicBranchId: values[PatientInsuranceField.clinicBranch].value,
  end: getApiDate(values[PatientInsuranceField.end]),
  insuranceClassId: values[PatientInsuranceField.insuranceClass].value,
  number: values[PatientInsuranceField.number],
  start: getApiDate(values[PatientInsuranceField.start]),
});
