import { useCallback, useMemo } from 'react';
import { DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown } from 'reactstrap';
import classnames from 'classnames';
import { Locale } from 'app/types';
import { useAppIntl } from 'app/helpers';
import { useChangeLocale } from 'app/helpers/useChangeLocale/useChangeLocale';
import 'app/components/languageSelector/languageSelector.scss';

import saudiArabiaFlag from 'assets/images/flags/saudi-arabia-96.png';
import usaFlag from 'assets/images/flags/usa-96.png';

interface Props {
  change?: (locale: Locale) => void;
  className?: string;
}

export const LanguageSelector = ({ change, className }: Props) => {
  const changeLocale = useChangeLocale();
  const { formatMessage, locale, isRtl } = useAppIntl();

  const languages = useMemo(
    () => ({
      [Locale.ar]: {
        flag: saudiArabiaFlag,
        text: formatMessage({ id: 'CORE.LABEL.ARABIC' }),
      },
      [Locale.en]: {
        flag: usaFlag,
        text: formatMessage({ id: 'CORE.LABEL.ENGLISH' }),
      },
    }),
    [formatMessage],
  );

  const renderLanguageWithFlag = useCallback(
    (locale: Locale) => {
      if (languages[locale]) {
        return (
          <div className="languageSelector__item">
            <img className="languageSelector__itemFlag me-2" alt="language" src={languages[locale].flag} />
            <span className="languageSelector__itemText">{languages[locale].text}</span>
          </div>
        );
      }

      return null;
    },
    [languages],
  );

  return (
    <UncontrolledDropdown className={classnames('languageSelector', className)}>
      <DropdownToggle className="languageSelector__dropdownToggle" tag="div">
        {renderLanguageWithFlag(locale)}
      </DropdownToggle>
      <DropdownMenu end={isRtl}>
        <DropdownItem onClick={() => (change ? change(Locale.ar) : changeLocale(Locale.ar))}>
          {renderLanguageWithFlag(Locale.ar)}
        </DropdownItem>
        <DropdownItem onClick={() => (change ? change(Locale.en) : changeLocale(Locale.en))}>
          {renderLanguageWithFlag(Locale.en)}
        </DropdownItem>
      </DropdownMenu>
    </UncontrolledDropdown>
  );
};
