import { useEffect } from 'react';
import { updateIntl } from 'react-intl-redux';
import { translations } from 'i18n';
import { Locale } from 'app/types';
import { useAppDispatch } from 'app/helpers/useTypedHooks/useTypedHooks';
import { useAppIntl } from 'app/helpers/useAppIntl/useAppIntl';

export const useForceLanguage = (forcedLanguage: Locale): void => {
  const dispatch = useAppDispatch();
  const { locale } = useAppIntl();
  useEffect(() => {
    if (locale !== forcedLanguage) {
      dispatch(
        updateIntl({
          locale: forcedLanguage,
          messages: translations[forcedLanguage],
        }),
      );
    }
  }, [dispatch, locale, forcedLanguage]);
};
