import { useEffect } from 'react';
import { isDevelopmentEnv } from 'app/constants';
import { GeneralNotificationsEvent } from 'app/types';
import { useCurrentUser, useHubConnection } from 'app/helpers';

export const useLiveRefresh = (refreshNotifications: () => void) => {
  const connection = useHubConnection('live-refresh-hub');
  const {
    currentUser: { id: userId },
  } = useCurrentUser();

  useEffect(() => {
    if (connection) {
      connection
        .start()
        .then(() => {
          connection.on('RefreshGeneralNotificationView', (event) => {
            const generalNotificationsEvent: GeneralNotificationsEvent = JSON.parse(event);

            if (userId && userId === generalNotificationsEvent.UserProfileId) {
              refreshNotifications();
            }
          });
        })
        .catch((error) => {
          if (isDevelopmentEnv) {
            console.error(error);
          }
        });
    }

    return () => {
      if (connection) {
        connection.stop().catch((error) => {
          if (isDevelopmentEnv) {
            console.error(error);
          }
        });
      }
    };
  }, [connection, refreshNotifications, userId]);
};
