import classNames from 'classnames';
import { ReactNode, useCallback, useMemo } from 'react';
import paginationFactory, { PaginationProvider } from 'react-bootstrap-table2-paginator';
import BootstrapTable, { BootstrapTableProps, TableChangeHandler } from 'react-bootstrap-table-next';
import { Loader } from 'app/shared';
import { useAppIntl } from 'app/helpers';
import { defaultValues } from 'app/constants';
import type { Option, QueryParams, QueryResponsePagination } from 'app/types';
import { StickyPagination } from 'app/components/stickyPagination/stickyPagination';

interface Props {
  bootstrapTableProps?: BootstrapTableProps;
  classes?: BootstrapTableProps['classes'];
  columns: BootstrapTableProps['columns'];
  data: BootstrapTableProps['data'];
  id: BootstrapTableProps['id'];
  isFetching: boolean;
  isLoading: boolean;
  keyField: BootstrapTableProps['keyField'];
  noDataComponent?: ReactNode;
  pageSizeOptions?: Option<number>[];
  pagination: QueryResponsePagination | undefined;
  params: QueryParams | undefined | void;
  setParams: (newParams?: QueryParams) => void;
  selectRow?: BootstrapTableProps['selectRow'];
  wrapperClasses?: BootstrapTableProps['wrapperClasses'];
  hideOrdinalNumber?: boolean;
}

export function RemoteBootstrapTable({
  bootstrapTableProps,
  classes,
  columns,
  data,
  id,
  isFetching,
  isLoading,
  keyField,
  noDataComponent,
  pageSizeOptions,
  pagination,
  params,
  setParams,
  wrapperClasses,
  hideOrdinalNumber = false,
  selectRow,
}: Props) {
  const hasItems = data.length > 0;
  const { formatMessage } = useAppIntl();
  const pageNumberBase = ((pagination?.pageNumber || 1) - 1) * (pagination?.pageSize || 1);

  const customColumns = useMemo<BootstrapTableProps['columns']>(
    () => [
      {
        dataField: 'rowId',
        text: '#',
        hidden: !!hideOrdinalNumber,
        formatter: (_, __, rowIndex, formatExtraData) => formatExtraData.pageNumberBase + rowIndex + 1,
        formatExtraData: { pageNumberBase },
      },
      ...columns,
    ],
    [columns, hideOrdinalNumber, pageNumberBase],
  );

  const sort = useMemo<BootstrapTableProps['sort']>(
    () =>
      params?.order ? { dataField: params.order, order: params?.orderAsc === 'true' ? 'asc' : 'desc' } : undefined,
    [params?.order, params?.orderAsc],
  );

  const onTableChange: TableChangeHandler<any> = useCallback(
    (type, newState) => {
      const newParams: QueryParams = {
        ...params,
        pageNumber: String(newState.page),
        pageSize: String(newState.sizePerPage),
        order: newState.sortField || undefined,
        orderAsc: newState.sortField ? (newState.sortOrder === 'asc' ? 'true' : 'false') : undefined,
      };

      setParams(newParams);
    },
    [params, setParams],
  );

  return (
    <PaginationProvider
      pagination={paginationFactory({
        custom: true,
        page: pagination?.pageNumber,
        sizePerPage: pagination?.pageSize,
        totalSize: pagination?.totalItemCount,
      })}
    >
      {({ paginationProps, paginationTableProps }) => (
        <>
          <BootstrapTable
            {...paginationTableProps}
            bordered={false}
            classes={classes}
            columns={customColumns}
            data={data}
            id={id}
            keyField={keyField}
            onTableChange={onTableChange}
            remote
            sort={sort}
            wrapperClasses={classNames('table-responsive', wrapperClasses)}
            selectRow={selectRow}
            {...bootstrapTableProps}
          />

          {/* initial loader */}
          {isLoading && <Loader />}

          {/* no data message */}
          {!isLoading && !hasItems ? (
            noDataComponent ? (
              noDataComponent
            ) : (
              <div className="text-center fs-7 py-4">{formatMessage({ id: 'CORE.LABEL.NO-DATA' })}</div>
            )
          ) : null}

          <StickyPagination
            loading={isLoading || isFetching}
            onPageChange={paginationProps.onPageChange}
            onSizePerPageChange={paginationProps.onSizePerPageChange}
            page={paginationProps.page || 1}
            pageSizeOptions={pageSizeOptions}
            refreshing={!isLoading && isFetching}
            sizePerPage={paginationProps.sizePerPage || Number(defaultValues.paginationQueryParams.pageSize)}
            totalSize={paginationProps.totalSize || 1}
          />
        </>
      )}
    </PaginationProvider>
  );
}
