import moment from 'moment';
import { Badge } from 'reactstrap';
import classnames from 'classnames';
import { FormattedMessage } from 'react-intl';
import { IconArrowRight } from '@tabler/icons-react';
import { Appointment } from 'app/types';
import { FORMATS_DAY_OF_WEEK, FORMATS_EXTENDED_DATE } from 'app/shared';
import { formatDateToTime, formattedDate, formattedPeriod } from 'app/helpers';
import styles from './dateAndTime.module.scss';

interface Props {
  appointment: Pick<Appointment, 'end' | 'isConflicting' | 'start' | 'workingTimeId'>;
  className?: string;
  conflictingLabel?: boolean;
  inline?: boolean;
}

export const DateAndTime = ({ appointment, className, conflictingLabel = true, inline }: Props) => {
  const isWithNoTime = !appointment.end && !appointment.workingTimeId;
  const isSameDay = appointment.end && moment(appointment.start).isSame(moment(appointment.end), 'day');

  return (
    <div className={className}>
      {isSameDay && !isWithNoTime && (
        <div>
          <div className={classnames(styles.dateText, { 'd-inline-block me-1': inline })}>
            {formattedDate(appointment.start, FORMATS_EXTENDED_DATE)}
          </div>
          {appointment.end && (
            <div className={classnames(styles.dateText, { 'd-inline-block': inline })}>
              {formattedPeriod(appointment.start, appointment.end)}
            </div>
          )}
        </div>
      )}

      {!isSameDay && !isWithNoTime && (
        <div className={styles.dateRange}>
          <div>
            <div className={styles.dateText}>{formattedDate(appointment.start, FORMATS_DAY_OF_WEEK)}</div>
            <div className={styles.dateText}>{formattedDate(appointment.start)}</div>
            <div className={styles.dateText}>{formatDateToTime(appointment.start)}</div>
          </div>
          {appointment.end && (
            <>
              <IconArrowRight size={16} className={styles.arrow} />
              <div>
                <div className={styles.dateText}>{formattedDate(appointment.end, FORMATS_DAY_OF_WEEK)}</div>
                <div className={styles.dateText}>{formattedDate(appointment.end)}</div>
                <div className={styles.dateText}>{formatDateToTime(appointment.end)}</div>
              </div>
            </>
          )}
        </div>
      )}

      {isWithNoTime && (
        <div>
          <div className={styles.dateText}>{formattedDate(appointment.start, FORMATS_EXTENDED_DATE)}</div>
          <Badge color="light-secondary" className="mt-1">
            <FormattedMessage id="CORE.TEXT.WITHOUT-TIME" />
          </Badge>
        </div>
      )}

      {conflictingLabel && appointment.isConflicting && (
        <div>
          <Badge color="light-danger" className="mt-1">
            <FormattedMessage id="CORE.TEXT.CONFLICTING" />
          </Badge>
        </div>
      )}
    </div>
  );
};
