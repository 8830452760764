export const FORMS_ADD_APPOINTMENT = 'add-appointment';
export const FORMS_BRANCH_FILTERS = 'branch-filters';
export const FORMS_ADD_BRANCH_FORM = 'add-branch';
export const FORMS_ADD_BRANCH_SERVICES = 'add-branch-services';
export const FORMS_ADD_DOCTOR_BUSY_TIME_FORM = 'add-doctor-busy-time';
export const FORMS_ADD_DOCTOR_FORM = 'add-doctor';
export const FORMS_ADD_DOCTOR_WORKING_HOURS_FORM = 'add-doctor-working-hours';
export const FORMS_ADD_DRAFT_ITEM = 'add-draft-item';
export const FORMS_ADD_FILES_TO_CLINIC_FORM = 'add-files-to-clinic';
export const FORMS_ADD_INSURANCE_FORM = 'add-insurance';
export const FORMS_ADD_INVOICE_ITEM = 'add-invoice-item';
export const FORMS_ADD_INVOICE_PAYMENT_FORM = 'add-patient-invoice-payment';
export const FORMS_ADD_INVOICE_PAYMENT_ITEM = 'add-invoice-payment-item';
export const FORMS_ADD_MEDICAL_SERVICE_FORM = 'add-medical-service';
export const FORMS_ADD_PATIENT_FORM = 'add-patient';
export const FORMS_ADD_BRANCH_BILLING_ITEM = 'add-branch-billing-item';
export const FORMS_ADD_PERFORMER_BILLING_ITEM = 'add-performer-billing-item';
export const FORMS_EDIT_PERFORMER_BILLING_ITEM = 'edit-performer-billing-item';
export const FORMS_EDIT_BRANCH_BILLING_ITEM = 'edit-branch-billing-item';
export const FORMS_ADD_PRODUCT_FORM = 'add-product';
export const FORMS_ADD_SPECIALITY_FORM = 'add-speciality';
export const FORMS_ADD_SUB_SPECIALITY_FORM = 'add-sub-speciality';
export const FORMS_ADD_USER_ROLES_FORM = 'add-user-roles';
export const FORMS_APPOINTMENTS_MASS_ADDING_FORM = 'appointments-mass-adding-form';
export const FORMS_APPOINTMENTS_SEARCH_FORM = 'appointments-search';
export const FORMS_APPOINTMENT_TICKET_FORM = 'appointment-ticket';
export const FORMS_BALANCE_PATIENTS_FILTERS_FORM = 'balance-patients-filters';
export const FORMS_BALANCE_PATIENT_CLINICS_FILTERS_FORM = 'balance-patient-clinic-filters';
export const FORMS_BALANCE_SEARCH_FORM = 'account-balance';
export const FORMS_CLINIC_BALANCE_TRANSACTIONS_FILTERS_FORM = 'balance-clinic-transactions-filters';
export const FORMS_PATIENT_BALANCE_TRANSACTIONS_FILTERS_FORM = 'balance-patient-transactions-filters';
export const FORMS_BILLING_ITEMS_FILTER_FORM = 'billing-items-filters';
export const FORMS_SPECIAL_BILLING_ITEMS_FILTER_FORM = 'special-billing-items-filters';
export const FORMS_BLOCK_PATIENT_FORM = 'block-patient';
export const FORMS_BOOKING_WIZARD = 'booking-wizard';
export const FORMS_BOOK_APPOINTMENT = 'forms-book-appointment';
export const FORMS_BRANCH_APPOINTMENTS_FILTER_FORM = 'branch-appointments-filters';
export const FORMS_BRANCH_BILLING_ITEMS_MASS_ADDING_FORM = 'branch-billing-items-mass-adding-form';
export const FORMS_BRANCH_IMAGES = 'branch-images';
export const FORMS_BRANCH_SERVICES_FILTERS = 'branch-services-filters';
export const FORMS_CALENDAR_DAY_FILTER = 'calendar-day-filter';
export const FORMS_CALENDAR_WEEK_FILTER = 'calendar-week-filter';
export const FORMS_CANCEL_DOCTOR_WORKING_HOURS_FORM = 'cancel-doctor-working-hours';
export const FORMS_CLINIC_ACCOUNT_BALANCE_SUMMARY = 'forms-clinic-account-balance-summary';
export const FORMS_CLINIC_SELECTION_FORM = 'clinic-selection';
export const FORMS_DASHBOARD_FILTER_FORM = 'dashboard-filters';
export const FORMS_DOCTOR_APPOINTMENTS_FILTER_FORM = 'doctor-appointments-filters';
export const FORMS_EDIT_APPOINTMENT = 'edit-appointment';
export const FORMS_EDIT_BRANCH_SERVICES = 'edit-branch-services';
export const FORMS_EDIT_CLINIC_FORM = 'edit-clinic';
export const FORMS_EDIT_DOCTOR_FORM = 'edit-doctor';
export const FORMS_EDIT_DOCTOR_WORKING_HOURS_FORM = 'edit-doctor-working-hours';
export const FORMS_EDIT_DRAFT_ITEM = 'edit-draft-item';
export const FORMS_EDIT_INSURANCE_FORM = 'edit-insurance';
export const FORMS_EDIT_INVOICE_ITEM = 'edit-invoice-item';
export const FORMS_EDIT_MEDICAL_SERVICE_FORM = 'edit-medical-service';
export const FORMS_EDIT_MEDICAL_SPECIALITY_FORM = 'edit-medical-speciality';
export const FORMS_EDIT_PATIENT_FORM = 'edit-patient';
export const FORMS_EDIT_PATIENT_NUMBER_FORM = 'edit-patient-number';
export const FORMS_EDIT_PRODUCT_FORM = 'edit-product';
export const FORMS_EDIT_PROFILE_FORM = 'edit-profile';
export const FORMS_EDIT_SETTINGS_FORM = 'edit-settings';
export const FORMS_OTP_VERIFICATION_CODE = 'otp-verification-code';
export const FORMS_EDIT_MEDICAL_LIBRARIES_FORM = 'edit-medical-libraries';
export const FORMS_EDIT_SPECIALITY_FORM = 'edit-speciality';
export const FORMS_EDIT_SUB_SPECIALITY_FORM = 'edit-sub-speciality';
export const FORMS_EDIT_SYSTEMOWNER_FORM = 'edit-systemowner';
export const FORMS_EDIT_USER_ROLES_FORM = 'edit-user-roles';
export const FORMS_EXPORT_TO_XLS = 'export-xls';
export const FORMS_EXPORT_PERFORMER_BILLING_ITEMS_TO_XLS = 'export-performer-billing-items-xls';
export const FORMS_CLINICS_FILTERS_FORM = 'clinics-filters';
export const FORMS_DELETED_CLINICS_FILTERS_FORM = 'deleted-clinics-filters';
export const FORMS_REJECTED_CLINICS_FILTERS_FORM = 'rejected-clinics-filters';
export const FORMS_PENDING_CLINICS_FILTERS_FORM = 'pending-clinics-filters';
export const FORMS_FILTER_CONFLICT_UPCOMING_APPOINTMENTS = 'filter-upcoming-conflict-appointments';
export const FORMS_FILTER_CONFLICT_WAITING_APPOINTMENTS = 'filter-waiting-conflict-appointments';
export const FORMS_FILTER_DOCTORS = 'filter-doctors';
export const FORMS_FILTER_BRANCH_DOCTORS = 'filter-branch-doctors';
export const FORMS_FILTER_INVOICES = 'filter-invoices';
export const FORMS_FILTER_ORDERS = 'filter-orders';
export const FORMS_FILTER_USER_ROLES_ALL_FORM = 'user-roles-all-filters';
export const FORMS_FILTER_USER_ROLES_CLINIC_FORM = 'user-roles-clinic-filters';
export const FORMS_FILTER_WALLETS = 'filter-wallets';
export const FORMS_FORGOTTEN_PASSWORD = 'forgotten-password';
export const FORMS_FULL_EDIT_APPOINTMENT = 'full-edit-appointment';
export const FORMS_GENERAL_APPOINTMENTS_FILTER_FORM = 'general-appointments-filters';
export const FORMS_GENERAL_ITEMS_GROUP = 'general-billing-items';
export const FORMS_INSURANCES_FILTERS_FORM = 'insurances-filters';
export const FORMS_INVOICE_DUE_DATE = 'invoice-due-date';
export const FORMS_LOGIN_FORM = 'login';
export const FORMS_MANAGE_NOTIFICATION_CONTENT_FORM = 'manage-notification-content';
export const FORMS_MASS_MESSAGING_NOTIFICATION_FORM = 'mass-messaging-notification';
export const FORMS_MODAL_CHANGE_PASSWORD_FORM = 'modal-change-password';
export const FORMS_PATIENT_ACCOUNT_BALANCE_SUMMARY = 'forms-patient-account-balance-summary';
export const FORMS_PATIENT_APPOINTMENTS_FILTER_FORM = 'patient-appointments-filters';
export const FORMS_PATIENT_INSURANCE = 'patient-insurance';
export const FORMS_PATIENT_MASS_ADDING_FORM = 'patient-mass-adding-form';
export const FORMS_PATIENT_MOBILE_NUMBER_EXISTS_FORM = 'patient-mobile-number-exists';
export const FORMS_BILLING_ITEMS_LIST_MASS_ADDING_FORM = 'billing-items-list-mass-adding-form';
export const FORMS_BRANCH_PACKAGE_MASS_ADDING_FORM = 'packages-list-mass-adding-form';
export const FORMS_BRANCH_PRACTITIONER_MASS_ADDING_FORM = 'practitioner-package-mass-adding-form';
export const FORMS_PRODUCTS_MASS_ADDING_FORM = 'products-mass-adding-form';
export const FORMS_PROMOTE_WAITING = 'promote-waiting-appointment';
export const FORMS_REGISTER_PATIENT_FORM = 'register-patient';
export const FORMS_REGISTRATION_FORM = 'registration';
export const FORMS_REJECT_PENDING_CLINIC_FORM = 'reject-pending-clinic';
export const FORMS_RESET_PASSWORD = 'reset-password';
export const FORMS_SELECT_DOCTOR_WORKING_HOURS_BRANCH_FORM = 'select-doctor-working-hours-branch';
export const FORMS_SPECIALITIES_FILTERS_FORM = 'specialities-filters';
export const FORMS_SUB_SPECIALITIES_FILTERS_FORM = 'sub-specialities-filters';
export const FORMS_UNBLOCK_PATIENT_FORM = 'unblock-patient';
export const FORMS_USER_LOG_FILTER_FORM = 'user-log-filters';
export const FORMS_WH_PRODUCTS_FILTERS = 'wh-products-filters';
export const FORMS_WH_PRODUCTS_OPTIONS = 'wh-products-options';
export const FORM_ADD_PATIENT_DEPENDEND = 'add-patient-dependend';
export const FORM_CONVERT_DEPENDEND_TO_PATIENT = 'convert-dependend-to-patient';
export const FORMS_INSURANCE_APPROVALS_FILTERS_FORM = 'insurance-approvals-filters';
export const FORMS_INSURANCE_CONTRACT_SET_UP_FILTERS_FORM = 'insurance-contract-set-up-filters';
export const FORMS_INSURANCE_REMOVED_CONTRACTOR_FILTERS_FORM = 'insurance-removed-contractor-filters';
export const FORMS_INSURANCE_POLICIES_SET_UP_FILTERS_FORM = 'insurance-policies-set-up-filters';
export const FORMS_INSURANCE_REMOVED_POLICIES_SET_UP_FILTERS_FORM = 'insurance-removed-policies-set-up-filters';
export const FORMS_INSURANCE_CONTRACT_MASS_ADDING_FORM = 'insurance-contract-mass-adding-form';
export const FORMS_INSURANCE_CONTRACT_MASS_ADDING_BILLING_ITEMS_FORM = 'insurance-contract-mass-adding-form';
export const FORMS_INSURANCE_CONTRACT_ITEM_PRICE_FORM = 'insurance-contract-item-price-form';
export const FORMS_INSURANCE_BILLING_ITEMS_CONTRACTS_SET_UP_FILTERS_FORM =
  'insurance-billing-items-contract-set-up-filters';
export const FORMS_INSURANCE_BILLING_ITEMS_INCLUSION_SET_UP_FILTERS_FORM =
  'insurance-billing-items-inclusion-set-up-filters';
export const FORMS_PATIENTS_ORDERS_FILTERS_FORM = 'orders/patients-orders-filters';
export const FORMS_CLINIC_ORDERS_FILTERS_FORM = 'orders/clinic-orders-filters';
export const FORMS_PATIENTS_ARRIVAL_FILTER_FORM = 'patients-arrival';
export const FORMS_INITIAL_ASSESSMENT = 'initial-assessment';
export const FORMS_EDIT_INITIAL_ASSESSMENT = 'edit-initial-assessment';
export const FORMS_CREATE_INSURANCE_REQUEST = 'insurance/create-insurance-request';
export const FORMS_INSURANCE_ADD_BILLING_ITEM = 'insurance/add-billing-item';
export const FORMS_INSURANCE_ADD_MEDICALS_ITEM = 'insurance/add-medication';
export const FORMS_INSURANCE_REFERRAL_APPROVAL = 'insurance/add-referral-approval';
export const FORMS_INSURANCE_CONTRACT_POLITY_SET_UP = 'insurance/add-contract-policy-set-up';
export const FORMS_INSURANCE_EDIT_CONTRACT_POLICY_SET_UP = 'insurance/edit-contract-policy-set-up';
export const FORMS_INSURANCE_EDIT_BILLING_ITEM_ALIAS_NAME = 'insurance/edit-billing-item-alias-name';
export const FORMS_INSURANCE_EDIT_BILLING_ITEM_PRICE = 'insurance/edit-billing-item-price';
export const FORMS_FINANCIAL_INVOICES_FILTERS_FORM = 'financial-invoices-filters';
export const FORMS_INVOICES_FILTERS_FORM = 'invoices-filters';
export const FORMS_PATIENT_INVOICES_FILTERS_FORM = 'patient-invoices-filters';
export const FORMS_INVOICES_REQUESTS_FILTERS_FORM = 'invoices-requests-filters';
export const FORM_INSURANCE_APPROVAL_ADD_DIAGNOSIS_FORM = 'insurance/add-diagnosis';
export const FORMS_MODAL_ADD_ALLERGY_FORM = 'assessment/add-allergy';
export const FORMS_MODAL_EDIT_ALLERGY_FORM = 'assessment/edit-allergy';
export const FORMS_ASSESSMENT_BILLING = 'assessment/billing';
export const FORMS_ASSESSMENT_ORDERS = 'assessment/orders';
export const FORMS_ORDER_DETAILS = 'order-details';
export const FORMS_ASSESSMENT_EDIT_BILLING_ITEM_FORM = 'assessment/edit-billing-item';
export const FORMS_ASSESSMENT_ADD_BILLING_ITEM_FORM = 'assessment/add-billing-item';
export const FORMS_ASSESSMENT_ADD_BILLING_ITEMS_FROM_PACKAGE = 'assessment/add-billing-items-from-package';
export const FORMS_ASSESSMENT_MEDICAL_PROFILE_FORM = 'assessment/medicalProfile';
export const FORMS_ASSESSMENT_SIGNATURE_FORM = 'assessment/signature';
export const FORMS_ASSESSMENT_PRESCRIPTIONS = 'assessment/prescriptions';
export const FORMS_ASSESSMENT_REFERRALS = 'assessment/referrals';
export const FORMS_ADD_PRESCRIPTION_FORM = 'assessment/add-prescription';
export const FORMS_EDIT_PRESCRIPTION_FORM = 'assessment/edit-prescription';
export const FORMS_ASSESSMENT_PRINT_MODAL_FORM = 'assessment/print-modal';
export const FORMS_ASSESSMENT_ADD_ATTACHMENT_FORM = 'assessment/add-attachment';
export const FORMS_TAKE_ACTION_MODAL_FORM = 'assessment/take-action';
export const FORMS_INVOICING_PROCESS_DETAILS = 'invoicing-process-details';
export const FORMS_INVOICING_PROCESS_PAYMENT_MODE = 'invoicing-process-payment-mode';
export const FORMS_INVOICING_PROCESS_ADD_PAYMENT_METHOD = 'invoicing-process-add-payment-method';
export const FORMS_INVOICING_PROCESS_EDIT_PAYMENT_METHOD = 'invoicing-process-edit-payment-method';
export const FORMS_INVOICING_PROCESS_PAYMENT_METHOD_CFG = 'invoicing-process-payment-method-cfg';
export const FORMS_INVOICING_PROCESS_ADD_ITEM = 'invoicing-process-add-item';
export const FORMS_INVOICING_PROCESS_EDIT_ITEM = 'invoicing-process-edit-item';
export const FORMS_INVOICING_PROCESS_DECLARATION = 'invoicing-process-declaration';
export const FORMS_CREDIT_NOTE = 'credit-note';
export const FORMS_CREDIT_NOTE_PACKAGE_ACTIONS = 'credit-note-package-actions';
export const FORMS_GENERAL_TREATMENT_PAGE_FORM = 'general-treatment-filters';
export const FORMS_ADD_ASSESSMENT_ORDER = 'assessment/add-assessment-order';
export const FORMS_REFERRALS_REFER_PATIENT = 'referrals/refer-patient';
export const FORMS_REFERRALS_FILTERS_FORM = 'referrals/filters';
export const FORMS_REFERRALS_DISCARD_FORM = 'referrals/discard';
export const FORMS_REFERRALS_MOVE_BACK_FORM = 'referrals/move-back';
export const FORMS_ADD_ILLNESS = 'add-illness';
export const FORMS_ADD_FAMILY_ILLNESS = 'add-family-illness';
export const FORMS_ADD_SURGERY = 'add-surgery';
export const FORMS_BRANCH_BILLING_ITEMS_PACKAGES = 'billingItems/packages';
export const FORMS_BRANCH_BILLING_ITEMS_ADD_EDIT_PACKAGES = 'billingItems/add-edit-packages';
export const FORMS_PRACTITIONER_BILLING_ITEMS_ADD_EDIT_PACKAGES = 'billingItems/add-edit-practitioner-packages';
export const FORMS_PATIENTS_FILTERS_FORM = 'patients-filters';
export const FORMS_PRACTITIONERS_PACKAGES_FORM = 'billingItems/practitioner-packages';
export const FORMS_EXPORT_PERFORMER_BILLING_PACKAGES_TO_XLS = 'billingItems/export-performer-billing-packages-xls';
export const FORMS_BRANCH_BILLING_ITEMS_MEDICATIONS_FILTERS = 'billingItems-medications-filters';
export const FORMS_PRACTITIONERS_BILLING_ITEMS_MEDICATIONS_FILTERS = 'practitioners-medications-filters';
export const FORMS_ADD_BRANCH_MEDICATION = 'add-branch-billing-medication';
export const FORMS_EDIT_BRANCH_MEDICATION = 'edit-branch-billing-medication';
export const FORMS_BRANCH_MEDICATIONS_MASS_ADDING_FORM = 'branch-medications-mass-adding-form';
export const FORMS_PRACTITIONERS_MEDICATIONS_MASS_ADDING_FORM = 'practitioners-medications-mass-adding-form';
export const FORMS_ASSIGN_MEDICATIONS_TO_PERFORMERS_FORM = 'assign-medications-to-performers-form';
export const FORMS_EXCLUSIONS_FILTERS_FORM = 'exclusions/filters';
export const FORMS_EXCLUSIONS_ADD_EXCLUSION_FORM = 'exclusions/add-exclusion';
export const FORMS_EXCLUSIONS_ADD_MULTI_EXCLUSIONS_FORM = 'exclusions/add-multi-exclusions';
export const FORMS_EXCLUSIONS_ACTIONS_LOG_FILTERS_FORM = 'exclusions/actions-log-filters';
export const FORMS_IMPORT_MEDICATION_TO_INSURANCE = 'insurance-billing-items/import-medication-to-insurance';
export const FORMS_ADD_POLICY = 'contractsAndPolicies/add-policy';
export const FORMS_EDIT_POLICY = 'contractsAndPolicies/edit-policy';
export const FORMS_RETRIEVE = 'insurance/retrieve';
export const FORMS_ADD_CONTRACT_ATTACHMENT = 'contractsAndPolicies/add-contract-attachment';
export const FORMS_IMPORT_PACKAGE_TO_INSURANCE = 'insurance-billing-items/import-package-to-insurance';
export const FORMS_IMPORT_ITEMS_CLASS_SETUP = 'contractsAndPolicies/import-items-class-setup';
export const FORMS_IMPORT_PACKAGES_CLASS_SETUP = 'contractsAndPolicies/import-packages-class-setup';
export const FORMS_IMPORT_MEDICATIONS_CLASS_SETUP = 'contractsAndPolicies/import-medications-class-setup';
export const FORMS_ADD_POLICY_CLASS = 'contractsAndPolicies/add-policy-class';
export const FORMS_EDIT_POLICY_CLASS = 'contractsAndPolicies/edit-policy-class';
export const FORMS_PATIENTS_FILTERS = 'patients-filters';
export const FORMS_PATIENT_FILTERS = 'patient-filters';
export const FORMS_ADD_ORDER = 'order/add-order';
export const FORMS_EDIT_ORDER = 'order/edit-order';
export const FORMS_SEND_INVOICE_REQUEST_ORDER = 'order/send-invoice-request-order';
export const FORMS_ADD_REPORT_ORDER = 'order/add-report-order';
export const FORMS_ADD_COMMENT_ORDER = 'order/add-comment-order';
export const FORMS_CHANGE_ORDER_STATUS = 'order/change-order-status';
export const FORMS_DONEE_ORDER_STATUS = 'order/done-order-status';
export const FORMS_EDIT_CLINIC_PENDING_FORM = 'clinics/edit-clinic-pending';
export const FORMS_CURRENT_CLINICS_REQUESTS_FORM = 'clinics/current-clinics-requests';
export const FORMS_PAST_CLINICS_REQUESTS_FORM = 'clinics/past-clinics-requests';
export const FORMS_MASS_ADDING = 'mass-adding';
export const FORMS_MASS_ADDING_TEMPLATE = 'mass-adding-template';
export const FORMS_MASS_ADDING_IMPORT = 'mass-adding-import';
export const FORMS_APPOINTMENTS_FILTERS = 'appointments-filters';
export const FORMS_MASS_ADDING_FILES = 'mass-adding-files';
export const FORMS_MASS_ADDING_IMPORT_UPLOAD = 'mass-adding-files-upload';
export const FORMS_PATIENT_ACTION_LOG = 'patient-profile/patient-action-log';
export const FORMS_MEDICAL_INSTITUTIONS_PATIENT_ACTION_LOG = 'patient-profile/medical-institutions-patient-action-log';
export const FORMS_MERGE_PATIENT_PROFILES = 'merge-patient-profiles';
export const FORMS_ADD_PATIENT = 'patient-profile/add-patient';
export const FORMS_ADD_PATIENT_DEPENDENT = 'patient-profile/add-patient-dependent';
export const FORMS_CONVERT_PATIENT_DEPENDENT = 'patient-profile/convert-patient-dependent';
export const FORMS_SWITCH_PATIENT_DEPENDENT = 'patient-profile/switch-patient-dependent';
export const FORMS_EDIT_PATIENT = 'patient-profile/edit-patient';
export const FORMS_EDIT_PATIENT_DEPENDENT = 'patient-profile/edit-patient-dependent';
export const FORMS_PATIENT_PROFILE_INSURANCE = 'patient-profile/patient-insurance';
export const FORMS_ADD_GUARDIAN = 'medical-institution-patient-profile/add-guardian';
export const FORMS_PATIENT_CLASSIFICATION_FILTERS = 'patient-classification-filter';
export const FORMS_PATIENT_INFO_FILTERS = 'patient-info-filters';
export const FORMS_ADD_PATIENT_CATEGORIES = 'add-patient-categories';
export const FORMS_ADD_PATIENT_TO_CATEGORIES_FILTERS = 'add-patient-to-categories-filters';
export const FORMS_ADD_PATIENT_TO_CATEGORIES = 'add-patient-to-categories';
export const FORMS_EDIT_PATIENT_CATEGORIES = 'edit-patient-categories';
export const FORMS_PATIENT_CATEGORIES_MASS_ADDING = 'patient-categories-mass-adding';
export const FORMS_MOVE_PATIENT_FROM_CATEGORIES = 'move-patient-from-categories';
export const FORMS_DELETE_PATIENT_FROM_CATEGORIES = 'delete-patient-from-categories';
export const FORMS_IMPORT_PATIENT_SOURCES = 'patient-sources/import';
export const FORMS_ADD_PATIENT_SOURCE = 'patient-sources/add';
export const FORMS_PATIENT_SOURCES_FILTERS = 'patient-sources/page-filters';
export const FORMS_EXTEND_PATIENT_SOURCE = 'patient-sources/extend';
export const FORMS_ICON_LIBRARY_FILTERS = 'icon-library-filter';
export const FORMS_ADD_ICON_LIBRARY = 'add-icon-library';
export const FORMS_EDIT_ICON_LIBRARY = 'edit-icon-library';
export const FORMS_SPECIALTY_NOTE = 'specialty-note';
export const FORMS_PATIENT_PROFILE_GUARDIAN = 'patient-profile/edit-guardian';
export const FORMS_PATIENT_MERGE_CHOOSER = 'patient-merge-chooser';
export const FORMS_PATIENT_PROFILE_RELATED_PROFILE = 'patient-profile/change-status-related-profile';
export const FORMS_ADD_PATIENT_TO_GUARDIAN_FILTERS = 'add-patient-to-guardian-filters';
export const FORMS_PATIENT_EMERGENCY_CONTACT = 'patient-profile/emergency-contact';
export const FORMS_PRE_SEARCH_CLINIC_PATIENT = 'pre-search-clinic-patient';
export const FORMS_PRE_SEARCH_REGULAR_PATIENT = 'pre-search-regular-patient';
export const FORMS_ADD_GUARDIAN_TO_DEPENDENT = 'add-guardian-to-dependent';
export const FORMS_PROCEDURE_LOCATION_NOTE_BILLING_ITEM = 'procedure-location-note-billing-item';
export const FORMS_PATIENT_VERIFICATIONS = 'patient-verifications';
export const FORMS_BRANCH_DECLARATIONS_FILTERS = 'branch-declarations';
export const FORMS_BRANCH_DECLARATION = 'branch-declaration';
export const FORMS_ACCOUNT_CREDIT_DECLARATION = 'account-credit-declaration';
export const FORMS_SPECIALITY_EMERGENCY_CONTACT = 'speciality-emergency-contact';
export const FORMS_UNCLAIMED_INSURANCES_FORM = 'claims/unclaimed-insurances-form';
export const FORMS_CLAIMED_INSURANCES_FORM = 'claims/claimed-insurances-form';
export const FORMS_SETTLEMENTS_FORM = 'claims/settlements-form';
export const FORMS_CANCELED_INSURANCES_FORM = 'claims/canceled-insurances-form';
export const FORMS_EXPORT_CLAIMS_FORM = 'claims/export-claims-form';
export const FORMS_SETTLEMENT_BY_AMOUNT_FORM = 'claims/settlement-by-amount-form';
export const FORMS_PARTIALLY_APPROVED_FORM = 'claims/partially-approved-form';
export const FORMS_REJECTED_FORM = 'claims/rejected-form';
export const FORMS_ADD_COMMENT_FORM = 'claims/add-comment';
export const FORMS_UPLOAD_SETTLEMENT_FORM = 'claims/upload-settlement-form';
export const FORMS_CLAIMS_LOG_FORM = 'claims/log-form';
export const FORMS_ADD_ATTACHMENT_FORM = 'claims/add-attachment';
