import { HIDE_SIDEBAR, SHOW_SIDEBAR } from './sidebar.types';

const initialState = {
  isSidebarOpen: false,
};

export const sidebarReducer = (state = initialState, action) => {
  switch (action.type) {
    case SHOW_SIDEBAR:
      return {
        ...state,
        isSidebarOpen: true,
      };
    case HIDE_SIDEBAR:
      return {
        ...state,
        isSidebarOpen: false,
      };
    default:
      return state;
  }
};
