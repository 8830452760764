import { useCallback, useMemo, useRef } from 'react';
import { Modal, ModalBody, ModalHeader } from 'reactstrap';
import { PatientStatus } from 'app/types';
import { useAppDispatch, useAppIntl, useAppSelector, useModalCleaner } from 'app/helpers';
import { toggleModal } from 'app/redux/modals/modals.actions';
import { InsuranceDetails } from 'app/features/patient/medicalInstitutionPatientProfile/insuranceDetails/InsuranceDetails';

export const InsuranceDetailsModal = () => {
  const modalRef = useRef(null);
  const dispatch = useAppDispatch();
  const { formatMessage } = useAppIntl();
  const isOpen = useAppSelector((state) => state.modal.invoicingProcessInsuranceDetails.isOpen);
  const clinicId = useAppSelector((state) => state.invoicingProcess.data?.clinic.value);
  const branchId = useAppSelector((state) => state.invoicingProcess.data?.clinicBranch.value);
  const patient = useAppSelector((state) => state.invoicingProcess.data?.patient);
  const guardianPatient = useAppSelector((state) => state.invoicingProcess.data?.guardianPatient);
  const patientId = guardianPatient ? guardianPatient.id : patient?.id;

  const isMergedOrPatientDeleted = useMemo(() => {
    let patientStatus = patient?.status?.value;

    if (guardianPatient) {
      patientStatus = guardianPatient.status?.value;
    }

    return patientStatus === PatientStatus.Merged || patientStatus === PatientStatus.Deleted;
  }, [guardianPatient, patient]);

  const closeModal = useCallback(() => {
    dispatch(toggleModal('invoicingProcessInsuranceDetails', false));
  }, [dispatch]);

  useModalCleaner('invoicingProcessInsuranceDetails', modalRef);

  return (
    <Modal centered isOpen={isOpen} ref={modalRef} size="xl" toggle={closeModal}>
      <ModalHeader toggle={closeModal}>{formatMessage({ id: 'CORE.LABEL.MANAGE-PATIENT-INSURANCE' })}</ModalHeader>
      <ModalBody>
        <InsuranceDetails
          branchId={branchId}
          clinicId={clinicId}
          isMergedOrPatientDeleted={isMergedOrPatientDeleted}
          patientId={patientId}
        />
      </ModalBody>
    </Modal>
  );
};
