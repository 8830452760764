import { useMemo } from 'react';
import sumBy from 'lodash/sumBy';
import { InvoicePayBy } from 'app/types';
import { useAppIntl, useAppSelector } from 'app/helpers';
import { DescriptionList, FormattedPrice } from 'app/components';
import { getGrossPrice, useExtendedItems } from 'app/features/invoicingProcess/process/items/helpers';

export const ItemsPreview = () => {
  const doctor = useAppSelector((state) => state.invoicingProcess.data?.doctor);
  const invoicingProcessItems = useAppSelector((state) => state.invoicingProcess.items);
  const isPayByInsurance =
    useAppSelector((state) => state.invoicingProcess.data?.steps?.paymentMode?.payBy?.value) === InvoicePayBy.Insurance;
  const items = useExtendedItems(invoicingProcessItems);
  const { formatMessage } = useAppIntl();

  const listItems = useMemo(() => {
    const totalPrice = sumBy(items, (i) => getGrossPrice(i));
    const totalDiscount = sumBy(items, (item) => item.discountValue);
    const totalVat = sumBy(items, (item) => item.vatValue);
    const grandTotal = sumBy(items, (item) => item.grandTotal);
    const insuranceShare = sumBy(items, (item) => item.insuranceShareTotal);
    const patientShare = sumBy(items, (item) => item.patientShareTotal);
    const vatExemptAmount = sumBy(items, (item) => item.vatExemptAmount);

    return [
      ...(doctor
        ? [
            {
              label: formatMessage({ id: 'CORE.LABEL.DOCTOR' }),
              value: doctor.label,
            },
          ]
        : []),
      {
        label: formatMessage({ id: 'CORE.LABEL.NUMBER-OF-ITEMS', defaultMessage: 'Number of Items' }),
        value: items.length,
      },
      {
        label: formatMessage({ id: 'CORE.LABEL.TOTAL-PRICE', defaultMessage: 'Total Price' }),
        value: <FormattedPrice amount={totalPrice} />,
      },
      {
        label: formatMessage({ id: 'CORE.LABEL.TOTAL-DISCOUNT', defaultMessage: 'Total Discount' }),
        value: <FormattedPrice amount={totalDiscount} />,
      },
      {
        label: formatMessage({ id: 'CORE.LABEL.TOTAL-VAT', defaultMessage: 'Total VAT' }),
        value: <FormattedPrice amount={totalVat} />,
      },
      {
        label: formatMessage({ id: 'CORE.LABEL.GRAND-TOTAL', defaultMessage: 'Grand Total' }),
        value: <FormattedPrice amount={grandTotal} />,
      },
      ...(isPayByInsurance
        ? [
            {
              label: formatMessage({ id: 'CORE.LABEL.INSURANCE-SHARE', defaultMessage: 'Insurance Share' }),
              value: <FormattedPrice amount={insuranceShare} />,
            },
          ]
        : []),
      {
        label: formatMessage({ id: 'CORE.LABEL.PATIENT-SHARE', defaultMessage: 'Patient Share' }),
        value: <FormattedPrice amount={patientShare} />,
      },
      {
        label: formatMessage({
          id: 'CORE.LABEL.PATIENT-TOTAL-VAT-EXEMPTION',
          defaultMessage: 'Total VAT Exemption',
        }),
        value: <FormattedPrice amount={vatExemptAmount} />,
      },
    ];
  }, [doctor, formatMessage, isPayByInsurance, items]);

  return <DescriptionList rowProps={{ xl: 4 }} items={listItems} />;
};
