import { compose } from 'redux';
import { connect } from 'react-redux';
import { reduxForm } from 'redux-form';
import { injectIntl } from 'react-intl';
import get from 'lodash/get';
import { FORMS_MODAL_CHANGE_PASSWORD_FORM } from 'app/shared';
import { changePassword } from 'app/redux/auth/auth.actions';
import FormComponent from './form.component';

const mapStateToProps = (state) => ({
  form: FORMS_MODAL_CHANGE_PASSWORD_FORM,
  username: get(state, 'auth.current.username'),
});

const mapDispatchToProps = (dispatch) => ({
  onSubmit: (values) => dispatch(changePassword({ oldPassword: values.oldPassword, newPassword: values.newPassword })),
});

export const FormContainer = compose(
  injectIntl,
  connect(mapStateToProps, mapDispatchToProps),
  reduxForm(),
)(FormComponent);
