import { Option, Locale } from 'app/types';

export const getPatientNameFromOption = (patient: Option, locale: Locale): string => {
  const nameAr = patient.translation?.[Locale.ar] || '';
  const nameEn = patient.translation?.[Locale.en] || '';

  if (locale === Locale.ar) {
    return nameAr || nameEn;
  }

  return nameEn || nameAr;
};
