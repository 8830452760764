import { refreshCalendarDayView, refreshCalendarWeekView } from 'app/sagas/calendar/generators';
import { refreshAppointmentList } from 'app/sagas/apppointment/generators/refreshAppointmentList';
import { refreshManageAppointments } from 'app/sagas/apppointment/generators/refreshManageAppointments';

export function* afterChangeStatus() {
  // Refresh appointment list
  yield refreshAppointmentList();
  yield refreshManageAppointments();

  // Refresh calendar
  yield refreshCalendarDayView();
  yield refreshCalendarWeekView();
}
