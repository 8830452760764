import classNames from 'classnames';
import toast from 'react-hot-toast';
import { useCallback, useState } from 'react';
import { IconPlaylistAdd } from '@tabler/icons-react';
import { Button, FormFeedback, FormGroup } from 'reactstrap';
import { WrappedFieldProps } from 'redux-form/lib/Field';
import { useAppIntl } from 'app/helpers';
import { Note, NoteStatus, Values } from 'app/components/locationSelector/procedureLocation/types';
import { ConfirmationModal } from 'app/components/confirmationModal/confirmationModal';
import { ProcedureLocation } from 'app/components/locationSelector/procedureLocation/procedureLocation';
import { Notes } from 'app/shared/form-elements/procedureLocationField/notes/notes';
import { Option } from 'app/types';

export interface ProcedureLocationFieldProps {
  disabled?: boolean;
  isReadOnly?: boolean;
  notesContainerId?: string;
  onManageBillingItems?: (index: number, values?: Option[]) => void;
  withStatus?: boolean;
}

export const ProcedureLocationField = ({
  input: { onChange, value },
  meta,
  isReadOnly,
  disabled,
  notesContainerId,
  onManageBillingItems,
  withStatus = true,
}: WrappedFieldProps & ProcedureLocationFieldProps) => {
  const { formatMessage } = useAppIntl();
  const [isOpen, setIsOpen] = useState(false);
  const [deleteConfirmation, setDeleteConfirmation] = useState(false);
  const [selectedNoteId, setSelectedNoteId] = useState<string | null>(null);
  const [preselectedNote, setPreselectedNote] = useState<Note | undefined>(undefined);
  const inputValue: Values | null | undefined = value;
  const hasNotes = !!inputValue?.notes?.length;
  const invalid = !!meta.error && meta.touched;

  const close = useCallback(() => {
    setIsOpen(false);
    setPreselectedNote(undefined);
  }, []);

  const save = useCallback<(values: Values) => void>(
    (values) => {
      onChange(values);
      setIsOpen(false);
      toast.success('CORE.TEXT.UPDATED-SUCCESSFULLY');
    },
    [onChange],
  );

  const editNote = useCallback<(note: Note) => void>((note) => {
    setPreselectedNote(note);
    setIsOpen(true);
  }, []);

  const deleteNote = useCallback<(id: string) => void>((id) => {
    setSelectedNoteId(id);
    setDeleteConfirmation(true);
  }, []);

  const onConfirmDelete = useCallback(() => {
    if (selectedNoteId && inputValue) {
      onChange({
        template: inputValue.template,
        notes: inputValue.notes.filter((note) => note.id !== selectedNoteId),
      });
    }

    setDeleteConfirmation(false);
    setSelectedNoteId(null);
  }, [inputValue, onChange, selectedNoteId]);

  const changeStatus = useCallback<(id: string, newStatus: NoteStatus) => void>(
    (id, newStatus) => {
      if (inputValue) {
        onChange({
          template: inputValue.template,
          notes: inputValue.notes.map((note) => {
            if (note.id === id) {
              return {
                ...note,
                status: newStatus,
              };
            }
            return note;
          }),
        });
      }
    },
    [inputValue, onChange],
  );

  return (
    <FormGroup>
      <Notes
        className="mb-3"
        deleteNote={deleteNote}
        editNote={editNote}
        onManageBillingItems={onManageBillingItems}
        changeStatus={changeStatus}
        notes={inputValue?.notes}
        isReadOnly={isReadOnly}
        notesContainerId={notesContainerId}
        withStatus={withStatus}
      />
      <div className="text-center">
        {!isReadOnly && (
          <Button
            className={classNames({ 'border-danger': invalid })}
            color="primary-gradient"
            onClick={() => setIsOpen(true)}
            type="button"
            size="sm"
            disabled={disabled}
          >
            <IconPlaylistAdd className="buttonIcon buttonIcon--left" size={20} strokeWidth={1.8} />
            <span>
              {formatMessage(
                hasNotes
                  ? {
                      id: 'CORE.BUTTON.UPDATE-NOTES',
                      defaultMessage: 'Update Notes',
                    }
                  : {
                      id: 'CORE.BUTTON.CREATE-NOTES',
                      defaultMessage: 'Create Notes',
                    },
              )}
            </span>
          </Button>
        )}
        {invalid && <FormFeedback className="d-block">{formatMessage({ id: meta.error })}</FormFeedback>}
      </div>
      <ProcedureLocation
        close={close}
        initialNotes={inputValue?.notes}
        initialTemplate={inputValue?.template}
        isOpen={isOpen}
        preselectedNote={preselectedNote}
        save={save}
        withStatus={withStatus}
      />
      <ConfirmationModal
        isOpen={deleteConfirmation}
        onClose={() => setDeleteConfirmation(false)}
        onConfirm={onConfirmDelete}
        description={formatMessage({
          id: 'CORE.TEXT.DELETE-NOTE-WARNING',
          defaultMessage: 'Are you sure you want to delete this note?',
        })}
      />
    </FormGroup>
  );
};
