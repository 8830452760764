import React from 'react';
import { Field, fieldPropTypes } from 'redux-form';
import { FormFeedback, FormGroup, Label } from 'reactstrap';
import { injectIntl } from 'react-intl';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import { checkIsRtl } from 'app/helpers';
import { InputComponent } from './components/input.component';
import { PrefixComponent } from './components/prefix.component';

import './inputWithPrefix.scss';

export const InputWithPrefix = ({
  input,
  intl: { formatMessage, locale },
  isRequired,
  isRtl,
  label,
  meta,
  prefixOptions,
}) => {
  const defaultIsRtl = checkIsRtl(locale);
  const isRtlDir = isRtl === undefined ? defaultIsRtl : isRtl;

  return (
    <FormGroup className="input-with-prefix">
      <Label>
        {label}
        {isRequired && <span className="text-danger">&nbsp;*</span>}
      </Label>
      <div
        className={classnames({
          'form-control d-flex': true,
          'is-invalid': !!meta.error && meta.touched,
          'is-ltr': !isRtlDir,
          'is-rtl': isRtlDir,
        })}
      >
        <Field
          component={PrefixComponent}
          isRtl={isRtlDir}
          locale={locale}
          name={`${input.name}.prefix`}
          options={prefixOptions}
        />
        <Field name={`${input.name}.value`} component={InputComponent} />
      </div>
      {!!meta.error && <FormFeedback>{formatMessage({ id: meta.error })}</FormFeedback>}
    </FormGroup>
  );
};

InputWithPrefix.propTypes = {
  ...fieldPropTypes,
  intl: PropTypes.object.isRequired,
  isRequired: PropTypes.bool,
  label: PropTypes.string.isRequired,
  prefixOptions: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export const InputWithPrefixComponent = injectIntl(InputWithPrefix);
