interface Props {
  invalid: boolean;
  title: string;
}

export const RequiredSectionHeading = ({ invalid, title }: Props) => (
  <h5 className={invalid ? 'is-invalid' : undefined}>
    {title}
    <span className="text-danger">&nbsp;*</span>
  </h5>
);
