import { useCallback, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { Modal, ModalBody, ModalHeader } from 'reactstrap';
import { SuccessIcon } from 'app/components';
import { useAppDispatch, useAppIntl, useAppSelector, useCurrentUser } from 'app/helpers';
import { toggleModal } from 'app/redux/modals/modals.actions';

import ar from 'i18n/ar.json';
import en from 'i18n/en.json';

export const AppointmentConfirmation = () => {
  const dispatch = useAppDispatch();
  const { locale } = useAppIntl();
  const {
    currentUser: { preferredNotificationLanguage },
  } = useCurrentUser();
  const isOpen = useAppSelector((state) => state.modal.appointmentConfirmation.isOpen);
  const { pathname } = useLocation();

  // Yes, it is strange, but that was the customer's requirement (PT-3288)
  const isArabic = preferredNotificationLanguage?.value ? preferredNotificationLanguage.value === '0' : locale === 'ar';
  const messages = isArabic ? ar : en;

  const toggle = useCallback(() => dispatch(toggleModal('appointmentConfirmation')), [dispatch]);

  //Close modal if location changes
  useEffect(() => {
    dispatch(toggleModal('appointmentConfirmation', false));
  }, [pathname, dispatch]);

  return (
    <Modal style={{ maxWidth: 640 }} isOpen={isOpen} toggle={toggle}>
      <ModalHeader className="border-0" toggle={toggle} />
      <ModalBody dir={isArabic ? 'rtl' : 'ltr'}>
        <div className="d-flex justify-content-center" style={{ marginTop: -40 }}>
          <SuccessIcon />
        </div>
        <h3 className="text-center">{messages['APPOINTMENTS.TEXT.ADD-SUCCESS']}</h3>
        <hr className="mt-6 mb-3" />
        <h6>{messages['APPOINTMENTS.TEXT.ADD-SUCCESS-INFO-TITLE']}</h6>
        <p className="bg-light-secondary fw-light p-3 mb-0 rounded-2">
          {messages['APPOINTMENTS.TEXT.ADD-SUCCESS-INFO']}
        </p>
      </ModalBody>
    </Modal>
  );
};
