import { useAppIntl } from 'app/helpers';
import { UnauthorizedPage } from 'app/components';
import { FormContainer } from './form/form.container';

export const ResetPassword = () => {
  const { formatMessage } = useAppIntl();

  return (
    <UnauthorizedPage>
      <h3 className="text-center">{formatMessage({ id: 'FORGOTTEN-PASSWORD.TEXT.RESET-PASSWORD' })}</h3>
      <FormContainer />
    </UnauthorizedPage>
  );
};
