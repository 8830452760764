import { PayloadAction } from '@reduxjs/toolkit';
import { put, select } from 'redux-saga/effects';
import { RootState } from 'app/types';
import { fetchPractitionersBillingPackages } from 'app/redux/remoteData/remoteData.actions';
import { Meta } from 'app/sagas/billingItemLists/types';

export function* refreshPractitionerPackages({ meta }: PayloadAction<void, string, Meta>) {
  const {
    remoteData: { practitionerPackages },
    billingItemsLists: { expandedPerformerId },
  }: RootState = yield select();
  if (practitionerPackages.params && expandedPerformerId) {
    yield put<any>(fetchPractitionersBillingPackages(meta.branchId, expandedPerformerId, practitionerPackages.params));
  }
}
