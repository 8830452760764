import * as types from './user-roles.types';

const initialState = {
  temporaryPassword: {
    loading: false,
    data: '',
    userId: '',
  },
  selectedUser: null,
  loading: false,
  error: false,
  isEmailUsed: false,
  userBranches: {
    data: [],
    error: false,
    loading: false,
  },
  userSpecialities: {
    data: [],
    error: false,
    loading: false,
  },
  clinicBranches: {
    data: [],
    error: false,
    loading: false,
  },
  doctors: {
    data: [],
    error: false,
    loading: false,
  },
};

export const userRolesReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.FETCH_TEMPORARY_PASSWORD_REQUEST:
      return {
        ...state,
        temporaryPassword: {
          ...state.temporaryPassword,
          data: '',
          loading: true,
        },
      };
    case types.FETCH_TEMPORARY_PASSWORD_SUCCESS:
      return {
        ...state,
        temporaryPassword: {
          ...state.temporaryPassword,
          loading: false,
          data: action.payload.data,
        },
      };
    case types.FETCH_TEMPORARY_PASSWORD_FAILURE:
      return {
        ...state,
        temporaryPassword: {
          ...state.temporaryPassword,
          data: '',
          loading: false,
        },
      };
    case types.FETCH_USER_BRANCHES_REQUEST:
      return {
        ...state,
        userBranches: {
          ...initialState.userBranches,
          loading: true,
        },
      };
    case types.FETCH_USER_BRANCHES_SUCCESS:
      return {
        ...state,
        userBranches: {
          ...initialState.userBranches,
          data: action.payload,
        },
      };
    case types.FETCH_USER_BRANCHES_FAILURE:
      return {
        ...state,
        userBranches: {
          ...initialState.userBranches,
          error: true,
        },
      };
    case types.FETCH_USER_SPECIALITIES_REQUEST:
      return {
        ...state,
        userSpecialities: {
          ...initialState.userSpecialities,
          loading: true,
        },
      };
    case types.FETCH_USER_SPECIALITIES_SUCCESS:
      return {
        ...state,
        userSpecialities: {
          ...initialState.userSpecialities,
          data: action.payload.data,
        },
      };
    case types.FETCH_USER_SPECIALITIES_FAILURE:
      return {
        ...state,
        userSpecialities: {
          ...initialState.userSpecialities,
          error: true,
        },
      };
    case types.FETCH_CLINIC_BRANCHES_REQUEST:
      return {
        ...state,
        clinicBranches: {
          ...initialState.clinicBranches,
          loading: true,
        },
      };
    case types.FETCH_CLINIC_BRANCHES_SUCCESS:
      return {
        ...state,
        clinicBranches: {
          ...initialState.clinicBranches,
          data: action.payload.data,
        },
      };
    case types.FETCH_CLINIC_BRANCHES_FAILURE:
      return {
        ...state,
        clinicBranches: {
          ...initialState.clinicBranches,
          error: true,
        },
      };
    case types.FETCH_DOCTORS_REQUEST:
      return {
        ...state,
        doctors: {
          ...initialState.doctors,
          loading: true,
        },
      };
    case types.FETCH_DOCTORS_SUCCESS:
      return {
        ...state,
        doctors: {
          ...initialState.doctors,
          data: action.payload.data,
        },
      };
    case types.FETCH_DOCTORS_FAILURE:
      return {
        ...state,
        doctors: {
          ...initialState.doctors,
          error: true,
        },
      };

    case types.ADD_USER_ROLE_REQUEST:
    case types.EDIT_USER_ROLE_REQUEST:
    case types.DELETE_USER_ROLE_REQUEST:
    case types.ADD_ADMIN_ROLE_REQUEST:
    case types.EDIT_ADMIN_ROLE_REQUEST:
    case types.DELETE_ADMIN_ROLE_REQUEST:
    case types.CHECK_USER_EMAIL_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case types.ADD_USER_ROLE_FAILURE:
    case types.EDIT_USER_ROLE_FAILURE:
    case types.DELETE_USER_ROLE_FAILURE:
    case types.ADD_ADMIN_ROLE_FAILURE:
    case types.EDIT_ADMIN_ROLE_FAILURE:
    case types.DELETE_ADMIN_ROLE_FAILURE:
    case types.CHECK_USER_EMAIL_FAILURE:
      return {
        ...state,
        loading: false,
        error: true,
      };
    case types.ADD_USER_ROLE_SUCCESS:
    case types.DELETE_USER_ROLE_SUCCESS:
    case types.ADD_ADMIN_ROLE_SUCCESS:
    case types.EDIT_ADMIN_ROLE_SUCCESS:
    case types.DELETE_ADMIN_ROLE_SUCCESS:
    case types.EDIT_USER_ROLE_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case types.FETCH_USER_REQUEST:
      return {
        ...state,
        loading: true,
        selectedUser: initialState.selectedUser,
      };
    case types.FETCH_USER_SUCCESS:
      return {
        ...state,
        loading: false,
        selectedUser: action.payload.data,
      };
    case types.FETCH_USER_FAILURE:
      return {
        ...state,
        loading: false,
        selectedUser: initialState.selectedUser,
      };
    case types.CHECK_USER_EMAIL_SUCCESS:
      return {
        ...state,
        loading: false,
        isEmailUsed: action.payload,
      };
    case types.SET_TEMPORARY_PASSWORD_USER_ID:
      return {
        ...state,
        temporaryPassword: {
          ...state.temporaryPassword,
          userId: action.payload,
        },
      };
    default:
      return state;
  }
};
