import React, { useCallback, useMemo } from 'react';
import { WrappedFieldArrayProps } from 'redux-form/lib/FieldArray';
import { IconUserPlus } from '@tabler/icons-react';
import { LocalTable } from 'app/shared';
import { IconButton } from 'app/components';
import { useAppDispatch, useAppIntl, useAppSelector } from 'app/helpers';
import { setAddedPrimaryPatients } from 'app/redux/patient/patientSlice';
import { toggleModal } from 'app/redux/modals/modals.actions';
import { PrimaryPatientBaseInfo } from 'app/features/patient/patientForm/types';
import { useColumns } from 'app/features/patient/patientForm/fields/primaryPatientsFieldArrayTable/helpers/useColumns';
import { AddGuardianToDependentModal } from 'app/features/patient/addGuardianToDependentModal/addGuardianToDependentModal';

export interface PrimaryPatientsFieldArrayProps {
  clinicId: string;
}

export const PrimaryPatientsFieldArray = ({
  fields,
  clinicId,
  meta,
}: WrappedFieldArrayProps<PrimaryPatientBaseInfo> & PrimaryPatientsFieldArrayProps) => {
  const dispatch = useAppDispatch();
  const { formatMessage } = useAppIntl();

  // Selectors
  const primaryPatients: PrimaryPatientBaseInfo[] | undefined = useAppSelector(
    (state) => state.form[meta.form]?.values?.primaryPatients,
  );

  // Helper Variables
  let items = useMemo(() => fields.getAll() || [], [fields]);

  // Handlers
  const openAddPrimary = useCallback(() => {
    if (primaryPatients) {
      dispatch(setAddedPrimaryPatients(primaryPatients));
      dispatch(toggleModal('addGuardianToDependent', true));
    }
  }, [dispatch, primaryPatients]);

  const removeField = useCallback(
    (rowIndex: number) => {
      fields.remove(rowIndex);
    },
    [fields],
  );

  // Helper Hooks
  const columns = useColumns({ clinicId, removeField, form: meta.form });

  return (
    <>
      <div className="d-flex justify-content-between align-items-center">
        <h4 className="mt-2">{formatMessage({ id: 'CORE.LABEL.ADD-UNDER-PRIMARY' })}</h4>
        <IconButton
          icon={<IconUserPlus size={22} strokeWidth={1.8} />}
          onClick={openAddPrimary}
          tooltipMessageId="CORE.BUTTON.ADD-NEW"
        />
      </div>

      <LocalTable
        classes="table-striped table-sm"
        columns={columns}
        data={items}
        id="primaryPatientsTable"
        keyField="id"
        loading={false}
      />

      {/* Modals */}
      <AddGuardianToDependentModal clinicId={clinicId} />
    </>
  );
};
