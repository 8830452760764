import { change } from 'redux-form';
import { ChangeEvent, useCallback } from 'react';
import { EventWithDataHandler } from 'redux-form/lib/Field';
import { Locale } from 'app/types';
import { useAppDispatch } from 'app/helpers';
import { PatientFormField } from 'app/features/patient/patientForm/types';
import { useLazyGetTranslationQuery } from 'app/services/general/general';

const supportedFields: string[] = [
  PatientFormField.firstNameAr,
  PatientFormField.fatherNameAr,
  PatientFormField.grandFatherNameAr,
  PatientFormField.familyNameAr,
  PatientFormField.preferredNameAr,
  PatientFormField.firstNameEn,
  PatientFormField.fatherNameEn,
  PatientFormField.grandFatherNameEn,
  PatientFormField.familyNameEn,
  PatientFormField.preferredNameEn,
];

const getLang = (name: string): string => name.slice(-2);

const generateTargetName = (name: string): string => `${name.slice(0, -2)}${getLang(name) === 'Ar' ? 'En' : 'Ar'}`;

export const useAutoTranslate = (form: string, isActive: boolean) => {
  const dispatch = useAppDispatch();
  const [getTranslation] = useLazyGetTranslationQuery();

  return useCallback<EventWithDataHandler<ChangeEvent<any>>>(
    async (event, newValue: string | undefined, previousValue, name) => {
      if (isActive && newValue && name && supportedFields.includes(name)) {
        try {
          const isArLang = getLang(name) === 'Ar';
          const res = await getTranslation({
            destinationLanguage: isArLang ? Locale.en : Locale.ar,
            sourceLanguage: isArLang ? Locale.ar : Locale.en,
            text: newValue,
          }).unwrap();
          if (!!res.success) {
            dispatch(change(form, generateTargetName(name), res.translations[0])); // We want to use global change here to prevent memory leak
          }
        } catch (e) {
          console.error(e); // We want to show only console error
        }
      }
    },
    [dispatch, form, getTranslation, isActive],
  );
};
