export const FETCH_ALLERGIES_REQUEST = 'ALLERGIES/fetch/REQUEST';
export const FETCH_ALLERGIES_SUCCESS = 'ALLERGIES/fetch/SUCCESS';
export const FETCH_ALLERGIES_FAILURE = 'ALLERGIES/fetch/FAILURE';

export const FETCH_DRUGS_REQUEST = 'DRUGS/fetch/REQUEST';
export const FETCH_DRUGS_SUCCESS = 'DRUGS/fetch/SUCCESS';
export const FETCH_DRUGS_FAILURE = 'DRUGS/fetch/FAILURE';

export const FETCH_DIAGNOSES_REQUEST = 'DIAGNOSES/fetch/REQUEST';
export const FETCH_DIAGNOSES_SUCCESS = 'DIAGNOSES/fetch/SUCCESS';
export const FETCH_DIAGNOSES_FAILURE = 'DIAGNOSES/fetch/FAILURE';

export const FETCH_SURGERIES_REQUEST = 'SURGERIES/fetch/REQUEST';
export const FETCH_SURGERIES_SUCCESS = 'SURGERIES/fetch/SUCCESS';
export const FETCH_SURGERIES_FAILURE = 'SURGERIES/fetch/FAILURE';

export const FETCH_PATIENT_ILLNESSES_REQUEST = 'PATIENT_ILLNESSES/fetch/REQUEST';
export const FETCH_PATIENT_ILLNESSES_SUCCESS = 'PATIENT_ILLNESSES/fetch/SUCCESS';
export const FETCH_PATIENT_ILLNESSES_FAILURE = 'PATIENT_ILLNESSES/fetch/FAILURE';

export const FETCH_FAMILY_ILLNESSES_REQUEST = 'FAMILY_ILLNESSES/fetch/REQUEST';
export const FETCH_FAMILY_ILLNESSES_SUCCESS = 'FAMILY_ILLNESSES/fetch/SUCCESS';
export const FETCH_FAMILY_ILLNESSES_FAILURE = 'FAMILY_ILLNESSES/fetch/FAILURE';
