import { useMemo } from 'react';
import { ColumnDescription } from 'react-bootstrap-table-next';
import { formattedDateAndTime, useAppIntl } from 'app/helpers';
import { ProcedureResponse } from 'app/redux/doctorAssessment/types';

interface Props {
  dataField: string;
  text: string;
}

export const useColumns = ({ dataField, text }: Props) => {
  const { formatMessage } = useAppIntl();
  const timeNow = formattedDateAndTime(new Date());

  return useMemo(
    (): ColumnDescription<ProcedureResponse>[] => [
      {
        dataField: dataField,
        text: formatMessage({ id: text }),
        formatter: (_, row) => row.text && <span dangerouslySetInnerHTML={{ __html: row.text }} />,
        classes: 'w-75',
        style: { fontSize: '9px', color: 'black' },
        headerStyle: { fontSize: '10px', color: 'black' },
      },
      {
        dataField: 'date',
        text: formatMessage({ id: 'CORE.TABLE.DATE' }),
        formatter: (_, row) => (row.createdAt ? formattedDateAndTime(row.createdAt) : timeNow),
        style: { fontSize: '9px', color: 'black' },
        headerStyle: { fontSize: '10px', color: 'black' },
      },
    ],
    [timeNow, dataField, text, formatMessage],
  );
};
