import sumBy from 'lodash/sumBy';
import { useCallback, useMemo, useState } from 'react';
import { InvoicePayBy } from 'app/types';
import { useAppDispatch, useAppSelector } from 'app/helpers';
import { FORMS_INVOICING_PROCESS_PAYMENT_MODE } from 'app/shared';
import { InvoicingProcessStep } from 'app/redux/invoicingProcess/types';
import { setStep } from 'app/redux/invoicingProcess/invoicingProcess.actions';
import { getTotalPrice } from 'app/features/invoicingProcess/process/items/helpers';
import { detailsSubmitButtonId } from 'app/features/invoicingProcess/process/details/form/form';
import { PaymentModeField, PaymentModeFormData } from 'app/features/invoicingProcess/process/paymentMode/form/types';
import { checkItemsStep, checkPaymentModeStep } from 'app/features/invoicingProcess/process/actions/helpers/checkSteps';

export const useOnContinue = () => {
  const dispatch = useAppDispatch();
  const [extendedLimitModal, setExtendedLimitModal] = useState<boolean>(false);
  const appointmentId = useAppSelector((state) => state.invoicingProcess.appointmentId);
  const invoicingProcess = useAppSelector((state) => state.invoicingProcess.data);
  const step = useAppSelector((state) => state.invoicingProcess.step);
  const items = useAppSelector((state) => state.invoicingProcess.items);
  const isPayByInsurance =
    useAppSelector(
      (state) => state.form[FORMS_INVOICING_PROCESS_PAYMENT_MODE]?.values?.[PaymentModeField.payBy]?.value,
    ) === InvoicePayBy.Insurance;
  const benefitLimit: string | undefined = useAppSelector(
    (state) => state.form[FORMS_INVOICING_PROCESS_PAYMENT_MODE]?.values?.[PaymentModeField.benefitLimit],
  );
  const continueLoading: boolean = useAppSelector(
    // @ts-ignore (missing in types)
    (state) => state.form[FORMS_INVOICING_PROCESS_PAYMENT_MODE]?.asyncValidating,
  );
  const paymentModeValues = useAppSelector((state) => state.form[FORMS_INVOICING_PROCESS_PAYMENT_MODE]?.values) as
    | PaymentModeFormData
    | undefined;

  const isLimitExtended = useMemo<boolean>(() => {
    const totalPrice = sumBy(items, (item) => getTotalPrice(item));

    if (isPayByInsurance && benefitLimit !== undefined && benefitLimit !== '') {
      return totalPrice > Number(benefitLimit);
    }

    return false;
  }, [benefitLimit, isPayByInsurance, items]);

  const onCancelExtendedLimitModal = useCallback(() => {
    setExtendedLimitModal(false);
  }, []);

  const onConfirmExtendedLimitModal = useCallback(() => {
    setExtendedLimitModal(false);
    dispatch(setStep(InvoicingProcessStep.PaymentMethod));
  }, [dispatch]);

  const onContinue = useCallback(() => {
    if (step === InvoicingProcessStep.Details) {
      const submitButton = document.getElementById(detailsSubmitButtonId);
      if (submitButton) {
        submitButton.click();
      }
    }

    if (step === InvoicingProcessStep.Appointment) {
      dispatch(setStep(InvoicingProcessStep.PaymentMode));
    }

    if (step === InvoicingProcessStep.PaymentMode) {
      const isStepValid =
        !!paymentModeValues &&
        checkPaymentModeStep({
          appointmentId,
          dispatch,
          invoicingProcess,
          items,
          paymentModeValues,
          step,
        });

      if (isStepValid) {
        dispatch(setStep(InvoicingProcessStep.Items));
        return;
      }
    }

    if (step === InvoicingProcessStep.Items) {
      const isStepValid = checkItemsStep({
        dispatch,
        items,
        step,
      });

      if (isStepValid) {
        if (isLimitExtended) {
          // Limit is extended (show warning)
          setExtendedLimitModal(true);
          return;
        }

        dispatch(setStep(InvoicingProcessStep.PaymentMethod));
        return;
      }
    }

    if (step === InvoicingProcessStep.PaymentMethod) {
      dispatch(setStep(InvoicingProcessStep.Summary));
    }
  }, [appointmentId, dispatch, isLimitExtended, invoicingProcess, items, paymentModeValues, step]);

  return {
    continueLoading,
    extendedLimitModal,
    onCancelExtendedLimitModal,
    onConfirmExtendedLimitModal,
    onContinue,
  };
};
