import { useCallback } from 'react';
import { Button } from 'reactstrap';
import { InfoBar } from 'app/shared';
import { useAppDispatch, useAppIntl, useAppSelector } from 'app/helpers';
import { resetRebook } from 'app/redux/appointment/appointment.actions';

export const RebookBar = () => {
  const dispatch = useAppDispatch();
  const { formatMessage } = useAppIntl();
  const { data } = useAppSelector((state) => state.appointment.rebook);

  const onCancel = useCallback(() => {
    dispatch(resetRebook());
  }, [dispatch]);

  if (!data) {
    return null;
  }

  return (
    <InfoBar>
      <div>
        {formatMessage(
          { id: 'CALENDAR-TABLE.TEXT.REBOOK-INFO' },
          { patientName: data.guardianName || data.patientName },
        )}
      </div>
      <Button color="light" className="mx-2 my-1" size="sm" onClick={onCancel}>
        {formatMessage({ id: 'CORE.BUTTON.CANCEL' })}
      </Button>
    </InfoBar>
  );
};
