import { NamesSectionValues } from 'app/features/patient/patientForm/types';

export const getIsRequiredFlags = (
  values: Partial<NamesSectionValues>,
  isFatherNameRequired: boolean,
  isGrandFatherNameRequired: boolean,
) => {
  const {
    firstNameAr,
    familyNameAr,
    fatherNameAr,
    grandFatherNameAr,
    firstNameEn,
    familyNameEn,
    fatherNameEn,
    grandFatherNameEn,
  } = values;

  const isEnglishFilled = firstNameEn || familyNameEn || fatherNameEn || grandFatherNameEn;
  const isArabicFilled = firstNameAr || familyNameAr || fatherNameAr || grandFatherNameAr;

  // Arabic Names - rules
  const isFirstNameArRequiredValue =
    !isEnglishFilled ||
    (isEnglishFilled && isArabicFilled) ||
    (!isEnglishFilled && (familyNameAr || fatherNameAr || grandFatherNameAr));
  const isFamilyNameArRequiredValue =
    !isEnglishFilled ||
    (isEnglishFilled && isArabicFilled) ||
    (!isEnglishFilled && (fatherNameAr || grandFatherNameAr));
  const isFatherNameArRequiredValue =
    (!isEnglishFilled && isFatherNameRequired) ||
    (isEnglishFilled && isArabicFilled && isFatherNameRequired) ||
    grandFatherNameAr ||
    ((firstNameAr || familyNameAr) && (fatherNameEn || grandFatherNameEn));
  const isGrandFatherNameArRequiredValue =
    (!isEnglishFilled && isGrandFatherNameRequired) ||
    (isEnglishFilled && isArabicFilled && isGrandFatherNameRequired) ||
    ((firstNameAr || familyNameAr || fatherNameAr) && grandFatherNameEn);

  // English Names - rules
  const isFirstNameEnRequiredValue =
    !isArabicFilled ||
    (isEnglishFilled && isArabicFilled) ||
    (!isArabicFilled && (familyNameEn || fatherNameEn || grandFatherNameEn));
  const isFamilyNameEnRequiredValue =
    !isArabicFilled || (isEnglishFilled && isArabicFilled) || (!isArabicFilled && (fatherNameEn || grandFatherNameEn));
  const isFatherNameEnRequiredValue = (!isArabicFilled && isFatherNameRequired) || grandFatherNameEn;
  const isGrandFatherNameEnRequiredValue = !isArabicFilled && isGrandFatherNameRequired;

  return {
    isFirstNameArRequired: !!isFirstNameArRequiredValue,
    isFamilyNameArRequired: !!isFamilyNameArRequiredValue,
    isFatherNameArRequired: !!isFatherNameArRequiredValue,
    isGrandFatherNameArRequired: !!isGrandFatherNameArRequiredValue,

    isFirstNameEnRequired: !!isFirstNameEnRequiredValue,
    isFamilyNameEnRequired: !!isFamilyNameEnRequiredValue,
    isFatherNameEnRequired: !!isFatherNameEnRequiredValue,
    isGrandFatherNameEnRequired: isGrandFatherNameEnRequiredValue,
  };
};
