export const FETCH_UPCOMING_APPOINTMENTS_REQUEST = 'appointments/FETCH_UPCOMING_APPOINTMENTS_REQUEST';
export const FETCH_UPCOMING_APPOINTMENTS_SUCCESS = 'appointments/FETCH_UPCOMING_APPOINTMENTS_SUCCESS';
export const FETCH_UPCOMING_APPOINTMENTS_FAILURE = 'appointments/FETCH_UPCOMING_APPOINTMENTS_FAILURE';

export const FETCH_BRANCH_APPOINTMENTS_REQUEST = 'appointments/FETCH_BRANCH_APPOINTMENTS_REQUEST';
export const FETCH_BRANCH_APPOINTMENTS_SUCCESS = 'appointments/FETCH_BRANCH_APPOINTMENTS_SUCCESS';
export const FETCH_BRANCH_APPOINTMENTS_FAILURE = 'appointments/FETCH_BRANCH_APPOINTMENTS_FAILURE';

export const APPOINTMENT_CHECKIN_REQUEST = 'appointments/APPOINTMENT_CHECKIN_REQUEST';
export const APPOINTMENT_CHECKIN_SUCCESS = 'appointments/APPOINTMENT_CHECKIN_SUCCESS';
export const APPOINTMENT_CHECKIN_FAILURE = 'appointments/APPOINTMENT_CHECKIN_FAILURE';

export const APPOINTMENT_UNCHECKIN_REQUEST = 'appointments/APPOINTMENT_UNCHECKIN_REQUEST';
export const APPOINTMENT_UNCHECKIN_SUCCESS = 'appointments/APPOINTMENT_UNCHECKIN_SUCCESS';
export const APPOINTMENT_UNCHECKIN_FAILURE = 'appointments/APPOINTMENT_UNCHECKIN_FAILURE';

export const FETCH_APPOINTMENT_CONFIRMATION_REQUEST = 'appointments/FETCH_APPOINTMENT_CONFIRMATION_REQUEST';
export const FETCH_APPOINTMENT_CONFIRMATION_SUCCESS = 'appointments/FETCH_APPOINTMENT_CONFIRMATION_SUCCESS';
export const FETCH_APPOINTMENT_CONFIRMATION_FAILURE = 'appointments/FETCH_APPOINTMENT_CONFIRMATION_FAILURE';

export const CONFIRM_APPOINTMENT_REQUEST = 'appointments/CONFIRM_APPOINTMENT_REQUEST';
export const CONFIRM_APPOINTMENT_SUCCESS = 'appointments/CONFIRM_APPOINTMENT_SUCCESS';
export const CONFIRM_APPOINTMENT_FAILURE = 'appointments/CONFIRM_APPOINTMENT_FAILURE';

export const CANCEL_APPOINTMENT_REQUEST = 'appointments/CANCEL_APPOINTMENT_REQUEST';
export const CANCEL_APPOINTMENT_SUCCESS = 'appointments/CANCEL_APPOINTMENT_SUCCESS';
export const CANCEL_APPOINTMENT_FAILURE = 'appointments/CANCEL_APPOINTMENT_FAILURE';

export const EXPORT_APPOINTMENTS_REQUEST = 'appointments/EXPORT_APPOINTMENTS_REQUEST';
export const EXPORT_APPOINTMENTS_SUCCESS = 'appointments/EXPORT_APPOINTMENTS_SUCCESS';
export const EXPORT_APPOINTMENTS_FAILURE = 'appointments/EXPORT_APPOINTMENTS_FAILURE';
