import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQueryWithReAuth } from 'app/helpers';
import {
  InsuranceContractor,
  InsuranceContractEntityActionLog,
  InsuranceContractEntityAttachment,
  QueryParams,
  QueryResponseWithPagination,
  QueryResponse,
} from 'app/types';
import {
  RemoveContractorParams,
  ActivateContractorParams,
  DeactivateContractorParams,
  EditContractorsAttachmentParams,
  ContractorsExcelReportParams,
  BranchContractorsParams,
  CreateContractParams,
  RetrieveContractorParams,
  ContractorEditPiceParams,
  ContractorEditPackages,
  ContractorEditMedications,
  ImportContractorBillingItemParams,
  EditContractBodyParams,
  EditBillingItemPriceBodyParams,
  EditBillingItemAliasNameParams,
} from 'app/services/insuranceContractors/types';
import {
  InsuranceBillingItemsContract,
  InsuranceBillingMedicationsContract,
  InsuranceBillingPackagesContract,
  InsuranceExtraBillingItemsContract,
} from 'app/types/InsuranceBillingItemsContract';

export const insuranceContractorApi = createApi({
  reducerPath: 'insuranceContractorApi',
  baseQuery: baseQueryWithReAuth,
  tagTypes: ['InsuranceContract', 'InsuranceContractBillingItems'],
  endpoints: (builder) => ({
    getInsuranceContractor: builder.query<QueryResponse<InsuranceContractor>, string>({
      query: (contractorId) => ({
        url: `insurance-contractors/${contractorId}`,
        method: 'GET',
      }),
      providesTags: [{ type: 'InsuranceContract' }],
    }),

    getInsuranceContractorsAndContracts: builder.query<
      QueryResponseWithPagination<InsuranceContractor[]>,
      QueryParams | undefined
    >({
      query: (params) => ({
        url: 'insurance-contractors',
        params,
      }),
      providesTags: [{ type: 'InsuranceContract' }],
    }),

    getInsuranceContractorAttachments: builder.query<
      QueryResponseWithPagination<InsuranceContractEntityAttachment[]>,
      string | undefined
    >({
      query: (contractorId) => ({
        url: `insurance-contractors/${contractorId}/attachments`,
      }),
      providesTags: (_, __, contractorId) => [
        {
          type: 'InsuranceContract',
          id: contractorId,
        },
      ],
    }),

    editContractorAttachments: builder.mutation<void, { contractorId: string; body: EditContractorsAttachmentParams }>({
      query: ({ contractorId, body }) => ({
        url: `insurance-contractors/${contractorId}/attachments`,
        method: 'PUT',
        body,
      }),
      invalidatesTags: ['InsuranceContract'],
    }),

    contractorExcel: builder.mutation<void, { body: ContractorsExcelReportParams }>({
      query: ({ body }) => ({
        url: `insurance-contractors/excel`,
        method: 'POST',
        body,
      }),
      invalidatesTags: ['InsuranceContract'],
    }),

    contractorsBranches: builder.mutation<void, { branchId: string; body: BranchContractorsParams }>({
      query: ({ branchId, body }) => ({
        url: `branches/${branchId}/insurance-contractors`,
        method: 'POST',
        body,
      }),
      invalidatesTags: (_, __, { branchId }) => [{ type: 'InsuranceContract', branchId }],
    }),

    editContractor: builder.mutation<void, { insuranceContractorId: string; body: BranchContractorsParams }>({
      query: ({ insuranceContractorId, body }) => ({
        url: `insurance-contractors/${insuranceContractorId}`,
        method: 'PUT',
        body,
      }),
      invalidatesTags: (_, __, { insuranceContractorId }) => [{ type: 'InsuranceContract', insuranceContractorId }],
    }),

    editContract: builder.mutation<void, { contractId: string; body: EditContractBodyParams }>({
      query: ({ contractId, body }) => ({
        url: `insurance-contracts/${contractId}`,
        method: 'PUT',
        body,
      }),
      invalidatesTags: ['InsuranceContract'],
    }),

    contractorsMassAdding: builder.mutation<void, { insuranceContractorId: string; body: FormData }>({
      query: ({ insuranceContractorId, body }) => ({
        url: `insurance-contractors/${insuranceContractorId}/insurance-contracts/mass-adding`,
        method: 'POST',
        body,
      }),
      invalidatesTags: ['InsuranceContract'],
    }),

    activateContractor: builder.mutation<void, ActivateContractorParams>({
      query: (body) => ({
        url: `insurance-contractors/${body.contractorId}/activate`,
        method: 'PUT',
      }),
      invalidatesTags: ['InsuranceContract'],
    }),

    deactivateContractor: builder.mutation<void, DeactivateContractorParams>({
      query: (body) => ({
        url: `insurance-contractors/${body.contractorId}/deactivate`,
        method: 'PUT',
      }),
      invalidatesTags: ['InsuranceContract'],
    }),

    retrieveContractor: builder.mutation<void, { contractorId: string; body: RetrieveContractorParams }>({
      query: ({ contractorId, body }) => ({
        url: `insurance-contractors/${contractorId}/retrieve`,
        method: 'PUT',
        body,
      }),
      invalidatesTags: ['InsuranceContract'],
    }),

    removeContractor: builder.mutation<void, RemoveContractorParams>({
      query: (body) => ({
        url: `insurance-contractors/${body.contractorId}/remove`,
        method: 'DELETE',
      }),
      invalidatesTags: ['InsuranceContract'],
    }),

    createContract: builder.mutation<void, { contractorId: string; body: CreateContractParams }>({
      query: ({ contractorId, body }) => ({
        url: `contractors/${contractorId}/insurance-contracts`,
        method: 'POST',
        body,
      }),
      invalidatesTags: (_, __, { contractorId }) => [{ type: 'InsuranceContract', contractorId }],
    }),

    getInsuranceContractorsActionLogs: builder.query<
      QueryResponseWithPagination<InsuranceContractEntityActionLog[]>,
      QueryParams | undefined
    >({
      query: (params) => ({
        url: `insurance-contractors/action-logs`,
        params,
      }),
      providesTags: ['InsuranceContract'],
    }),

    getInsuranceContractsActionLogs: builder.query<
      QueryResponseWithPagination<InsuranceContractEntityActionLog[]>,
      QueryParams | undefined
    >({
      query: (params) => ({
        url: `insurance-contracts/action-logs`,
        params,
      }),
      providesTags: [{ type: 'InsuranceContract' }],
    }),

    getReport: builder.query<void, QueryParams | undefined>({
      query: (params) => ({
        params,
        url: 'insurance-contractors/excel',
      }),
    }),

    getInsuranceContractorsBillingItemsActionLogs: builder.query<
      QueryResponseWithPagination<InsuranceContractEntityActionLog[]>,
      { contractId: string; contractorId: string; pageName: string; params: QueryParams | undefined }
    >({
      query: ({ contractId, contractorId, pageName, params }) => ({
        url: contractorId
          ? `insurance-contractors/${contractorId}/${pageName}/action-logs`
          : `insurance-contracts/${contractId}/${pageName}/action-logs`,
        params,
      }),
      providesTags: ['InsuranceContractBillingItems'],
    }),

    getInsuranceContractorsBillingItems: builder.query<
      QueryResponseWithPagination<InsuranceBillingItemsContract[]>,
      { contractorId?: string; contractId?: string; params: QueryParams | undefined }
    >({
      query: ({ contractorId, contractId, params }) => ({
        url: contractorId
          ? `insurance-contractors/${contractorId}/branch-billing-items/group/statuses`
          : `insurance-contracts/${contractId}/branch-billing-items/group/statuses`,
        params,
      }),
      providesTags: ['InsuranceContractBillingItems'],
    }),

    getBillingItemReport: builder.mutation<
      void,
      { contractorId: string; contractId: string; typeId?: string; pageName: string; params: QueryParams | undefined }
    >({
      query: ({ contractorId, contractId, typeId, pageName, params }) => ({
        params: {
          ...params,
          type: typeId,
        },
        url: contractorId
          ? `insurance-contractors/${contractorId}/${pageName}/excel`
          : `insurance-contracts/${contractId}/${pageName}/excel`,
      }),
    }),

    getBillingItemContractor: builder.query<
      QueryResponseWithPagination<InsuranceExtraBillingItemsContract[]>,
      { contractorId?: string; contractId?: string; typeId?: number; params: QueryParams | undefined }
    >({
      query: ({ contractorId, contractId, typeId, params }) => ({
        params: {
          ...params,
          type: typeId,
        },
        url: contractorId
          ? `insurance-contractors/${contractorId}/branch-billing-items`
          : `insurance-contracts/${contractId}/branch-billing-items`,
      }),
      providesTags: [{ type: 'InsuranceContractBillingItems' }],
    }),
    getBillingItemPackagesContractor: builder.query<
      QueryResponseWithPagination<InsuranceBillingPackagesContract[]>,
      { contractorId: string; contractId: string; params: QueryParams | undefined }
    >({
      query: ({ contractorId, contractId, params }) => ({
        params,
        url: contractorId
          ? `insurance-contractors/${contractorId}/branch-billing-packages`
          : `insurance-contracts/${contractId}/branch-billing-packages`,
      }),
      providesTags: [{ type: 'InsuranceContractBillingItems' }],
    }),

    getBillingItemMedicationsContractor: builder.query<
      QueryResponseWithPagination<InsuranceBillingMedicationsContract[]>,
      { contractorId: string; contractId: string; params: QueryParams | undefined }
    >({
      query: ({ contractorId, contractId, params }) => ({
        params,
        url: contractorId
          ? `insurance-contractors/${contractorId}/branch-billing-medications`
          : `insurance-contracts/${contractId}/branch-billing-medications`,
      }),
      providesTags: [{ type: 'InsuranceContractBillingItems' }],
    }),

    getBillingItemsContractor: builder.query<
      QueryResponseWithPagination<InsuranceExtraBillingItemsContract[]>,
      { contractorId: string; contractId: string; pageName: string; type: string; params: QueryParams | undefined }
    >({
      query: ({ contractorId, contractId, pageName, type, params }) => ({
        params: {
          ...params,
          type,
        },
        url: contractorId
          ? `insurance-contractors/${contractorId}/${pageName}`
          : `insurance-contracts/${contractId}/${pageName}`,
      }),
      providesTags: ['InsuranceContractBillingItems'],
    }),

    billingItemsContractorsMassAdding: builder.mutation<
      void,
      { contractorId: string; contractId: string; body: FormData; type: string }
    >({
      query: ({ contractorId, contractId, body, type }) => ({
        url: contractorId
          ? `insurance-contractors/${contractorId}/branch-billing-items/mass-adding`
          : `insurance-contracts/${contractId}/branch-billing-items/mass-adding`,
        method: 'POST',
        params: {
          type: type,
        },
        body,
      }),
      invalidatesTags: ['InsuranceContractBillingItems'],
    }),
    billingItemsPackagesContractorsMassAdding: builder.mutation<
      void,
      { contractorId: string; contractId: string; body: FormData }
    >({
      query: ({ contractorId, contractId, body }) => ({
        url: contractorId
          ? `insurance-contractors/${contractorId}/packages/mass-adding`
          : `insurance-contracts/${contractId}/packages/mass-adding`,
        method: 'POST',
        body,
      }),
      invalidatesTags: ['InsuranceContractBillingItems'],
    }),
    billingItemsMedicationsContractorsMassAdding: builder.mutation<
      void,
      { contractorId: string; contractId: string; body: FormData }
    >({
      query: ({ contractorId, contractId, body }) => ({
        url: contractorId
          ? `insurance-contractors/${contractorId}/medications/mass-adding`
          : `insurance-contracts/${contractId}/medications/mass-adding`,
        method: 'POST',
        body,
      }),
      invalidatesTags: ['InsuranceContractBillingItems'],
    }),

    deactivateContractorBillingItems: builder.mutation<
      void,
      { contractorId: string; contractId: string; body: InsuranceBillingItemsContract }
    >({
      query: ({ contractorId, contractId, body }) => ({
        url: contractorId
          ? `insurance-contractors/${contractorId}/branch-billing-items/group/deactivate`
          : `insurance-contracts/${contractId}/branch-billing-items/group/deactivate`,
        method: 'PUT',
        params: {
          type: body.billingItemType.value,
        },
      }),
      invalidatesTags: ['InsuranceContractBillingItems'],
    }),
    activateContractorBillingItems: builder.mutation<
      void,
      { contractorId: string; contractId: string; body: InsuranceBillingItemsContract }
    >({
      query: ({ contractorId, contractId, body }) => ({
        url: contractorId
          ? `insurance-contractors/${contractorId}/branch-billing-items/group/activate`
          : `insurance-contracts/${contractId}/branch-billing-items/group/activate`,
        method: 'PUT',
        params: {
          type: body.billingItemType.value,
        },
      }),
      invalidatesTags: ['InsuranceContractBillingItems'],
    }),

    getBillingItemsPackages: builder.query<
      QueryResponseWithPagination<InsuranceBillingPackagesContract[]>,
      { branchId: string; branchBillingPackageId: string; params: QueryParams | undefined }
    >({
      query: ({ branchId, branchBillingPackageId, params }) => ({
        url: `branches/${branchId}/branch-billing-packages/${branchBillingPackageId}`,
        params,
      }),
    }),

    billingItemsPackagesContractors: builder.mutation<
      void,
      { contractorId: string; contractId: string; body: ContractorEditPackages }
    >({
      query: ({ contractorId, contractId, body }) => ({
        url: contractorId
          ? `insurance-contractors/${contractorId}/branch-billing-packages`
          : `insurance-contracts/${contractId}/branch-billing-packages`,
        method: 'POST',
        body,
      }),
      invalidatesTags: ['InsuranceContractBillingItems'],
    }),

    billingItemsMedicationContractors: builder.mutation<
      void,
      { contractorId: string; contractId: string; body: ContractorEditMedications }
    >({
      query: ({ contractorId, contractId, body }) => ({
        url: contractorId
          ? `insurance-contractors/${contractorId}/branch-billing-medications`
          : `insurance-contracts/${contractId}/branch-billing-medications`,
        method: 'POST',
        body,
      }),
      invalidatesTags: ['InsuranceContractBillingItems'],
    }),

    importBillingItems: builder.mutation<
      void,
      {
        contractorId: string;
        contractId: string;
        body: ImportContractorBillingItemParams;
      }
    >({
      query: ({ contractorId, contractId, body }) => ({
        url: contractorId
          ? `insurance-contractors/${contractorId}/branch-billing-items`
          : `insurance-contracts/${contractId}/branch-billing-items`,
        method: 'POST',
        body,
      }),
      invalidatesTags: ['InsuranceContractBillingItems'],
    }),
    importClassesBillingItems: builder.mutation<
      void,
      {
        classId: string;
        body: ImportContractorBillingItemParams;
      }
    >({
      query: ({ classId, body }) => ({
        url: `insurance-classes/${classId}/branch-billing-items`,
        method: 'POST',
        body,
      }),
      invalidatesTags: ['InsuranceContractBillingItems'],
    }),

    // [i]: Billing Items Setup - Items tab
    editBillingItemAliasName: builder.mutation<void, EditBillingItemAliasNameParams>({
      query: ({ contractorId, contractId, branchBillingItemId, pageName, body }) => ({
        url: contractorId
          ? `insurance-contractors/${contractorId}/${pageName}/${branchBillingItemId}/alias-name`
          : `insurance-contracts/${contractId}/${pageName}/${branchBillingItemId}/alias-name`,
        method: 'PUT',
        body,
      }),
      invalidatesTags: ['InsuranceContractBillingItems'],
    }),

    // [i]: Billing Items Setup - Items tab
    editBillingItemPrice: builder.mutation<
      void,
      {
        contractorId: string;
        contractId: string;
        pageName: string;
        branchId: string;
        body: EditBillingItemPriceBodyParams;
      }
    >({
      query: ({ contractorId, contractId, pageName, branchId, body }) => ({
        url: contractorId
          ? `insurance-contractors/${contractorId}/${pageName}/${branchId}`
          : `insurance-contracts/${contractId}/${pageName}/${branchId}`,
        method: 'PUT',
        body,
      }),
      invalidatesTags: ['InsuranceContractBillingItems'],
    }),

    // [i]: Billing Items Setup - Packages & Medications tabs
    editBillingItemsPrice: builder.mutation<
      void,
      { contractorId: string; contractId: string; branchId: string; body: ContractorEditPiceParams; pageName: string }
    >({
      query: ({ contractorId, contractId, branchId, body, pageName }) => ({
        url: contractorId
          ? `insurance-contractors/${contractorId}/branch-billing-${pageName}/${branchId}`
          : `insurance-contracts/${contractId}/branch-billing-${pageName}/${branchId}`,
        method: 'PUT',
        body,
      }),
      invalidatesTags: ['InsuranceContractBillingItems'],
    }),

    activatedBillingItemsPrice: builder.mutation<
      void,
      { contractorId: string; contractId: string; pageName: string; branchId: string }
    >({
      query: ({ contractorId, contractId, pageName, branchId }) => ({
        url: contractorId
          ? `insurance-contractors/${contractorId}/${pageName}/${branchId}/activate`
          : `insurance-contracts/${contractId}/${pageName}/${branchId}/activate`,
        method: 'PUT',
      }),
      invalidatesTags: ['InsuranceContractBillingItems'],
    }),

    deactivatedBillingItemsPrice: builder.mutation<
      void,
      { contractorId: string; contractId: string; pageName: string; branchId: string }
    >({
      query: ({ contractorId, contractId, pageName, branchId }) => ({
        url: contractorId
          ? `insurance-contractors/${contractorId}/${pageName}/${branchId}/deactivate`
          : `insurance-contracts/${contractId}/${pageName}/${branchId}/deactivate`,
        method: 'PUT',
      }),
      invalidatesTags: ['InsuranceContractBillingItems'],
    }),

    remoteBillingItemsPrice: builder.mutation<
      void,
      { contractorId: string; contractId: string; pageName: string; branchId: string }
    >({
      query: ({ contractorId, contractId, pageName, branchId }) => ({
        url: contractorId
          ? `insurance-contractors/${contractorId}/${pageName}/${branchId}/remove`
          : `insurance-contracts/${contractId}/${pageName}/${branchId}/remove`,
        method: 'DELETE',
      }),
      invalidatesTags: ['InsuranceContractBillingItems'],
    }),

    preAuthorizationBillingItemsEnable: builder.mutation<
      void,
      { contractorId: string; contractId: string; branchBillingItemId: string }
    >({
      query: ({ contractorId, contractId, branchBillingItemId }) => ({
        url: contractorId
          ? `insurance-contractors/${contractorId}/branch-billing-items/${branchBillingItemId}/pre-authorization/enable`
          : `insurance-contracts/${contractId}/branch-billing-items/${branchBillingItemId}/pre-authorization/enable`,
        method: 'PUT',
      }),
      invalidatesTags: ['InsuranceContractBillingItems'],
    }),

    preAuthorizationBillingItemsDisable: builder.mutation<
      void,
      { contractorId: string; contractId: string; branchBillingItemId: string }
    >({
      query: ({ contractorId, contractId, branchBillingItemId }) => ({
        url: contractorId
          ? `insurance-contractors/${contractorId}/branch-billing-items/${branchBillingItemId}/pre-authorization/disable`
          : `insurance-contracts/${contractId}/branch-billing-items/${branchBillingItemId}/pre-authorization/disable`,
        method: 'PUT',
      }),
      invalidatesTags: ['InsuranceContractBillingItems'],
    }),

    preAuthorizationBillingPackagesItemsEnable: builder.mutation<
      void,
      { contractorId: string; contractId: string; pageName: string; branchBillingItemId: string }
    >({
      query: ({ contractorId, contractId, pageName, branchBillingItemId }) => ({
        url: contractorId
          ? `insurance-contractors/${contractorId}/${pageName}/${branchBillingItemId}/pre-authorization/enable`
          : `insurance-contracts/${contractId}/${pageName}/${branchBillingItemId}/pre-authorization/enable`,
        method: 'PUT',
      }),
      invalidatesTags: ['InsuranceContractBillingItems'],
    }),

    preAuthorizationBillingPackagesItemsDisable: builder.mutation<
      void,
      { contractorId: string; contractId: string; pageName: string; branchBillingItemId: string }
    >({
      query: ({ contractorId, contractId, pageName, branchBillingItemId }) => ({
        url: contractorId
          ? `insurance-contractors/${contractorId}/${pageName}/${branchBillingItemId}/pre-authorization/disable`
          : `insurance-contracts/${contractId}/${pageName}/${branchBillingItemId}/pre-authorization/disable`,
        method: 'PUT',
      }),
      invalidatesTags: ['InsuranceContractBillingItems'],
    }),
  }),
});

export const {
  useEditBillingItemAliasNameMutation,
  useEditBillingItemPriceMutation,
  useGetInsuranceContractorQuery,
  useGetInsuranceContractorsAndContractsQuery,
  useRemoveContractorMutation,
  useActivateContractorMutation,
  useDeactivateContractorMutation,
  useEditContractorMutation,
  useContractorsBranchesMutation,
  useContractorsMassAddingMutation,
  useContractorExcelMutation,
  useEditContractorAttachmentsMutation,
  useEditContractMutation,
  useGetInsuranceContractorAttachmentsQuery,
  useRetrieveContractorMutation,
  useCreateContractMutation,
  useGetInsuranceContractorsActionLogsQuery,
  useGetInsuranceContractsActionLogsQuery,
  useLazyGetReportQuery,
  useGetInsuranceContractorsBillingItemsActionLogsQuery,
  useGetInsuranceContractorsBillingItemsQuery,
  useGetBillingItemReportMutation,
  useLazyGetBillingItemContractorQuery,
  useGetBillingItemPackagesContractorQuery,
  useGetBillingItemMedicationsContractorQuery,
  useBillingItemsContractorsMassAddingMutation,
  useDeactivateContractorBillingItemsMutation,
  useActivateContractorBillingItemsMutation,
  useGetBillingItemsPackagesQuery,
  useBillingItemsPackagesContractorsMutation,
  useBillingItemsMedicationContractorsMutation,
  useEditBillingItemsPriceMutation,
  useActivatedBillingItemsPriceMutation,
  useDeactivatedBillingItemsPriceMutation,
  useRemoteBillingItemsPriceMutation,
  usePreAuthorizationBillingItemsEnableMutation,
  usePreAuthorizationBillingItemsDisableMutation,
  usePreAuthorizationBillingPackagesItemsDisableMutation,
  usePreAuthorizationBillingPackagesItemsEnableMutation,
  useGetBillingItemsContractorQuery,
  useBillingItemsMedicationsContractorsMassAddingMutation,
  useBillingItemsPackagesContractorsMassAddingMutation,
  useLazyGetInsuranceContractorQuery,
  useImportBillingItemsMutation,
  useImportClassesBillingItemsMutation,
} = insuranceContractorApi;
