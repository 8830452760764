import { useMemo } from 'react';
import { UserRoles } from 'app/types';
import { checkAccess } from 'app/helpers/checkAccess/checkAccess';
import { useAppSelector } from 'app/helpers/useTypedHooks/useTypedHooks';
import { clinicModeValues } from 'app/shared/constants/clinic-modes.constants';

export const useCurrentUser = () => {
  const currentUser = useAppSelector((state) => state.auth.current);
  const roles = useAppSelector((state) => state.auth.current.roles);
  const token = useAppSelector((state) => state.auth.token);
  const isAuthenticated = !!currentUser.id;

  const isAdmin = useMemo(() => checkAccess([UserRoles.MasterAdmin], roles), [roles]);
  const isDoctor = useMemo(() => checkAccess([UserRoles.Doctor], roles), [roles]);
  const isManager = useMemo(() => checkAccess([UserRoles.Manager], roles), [roles]);
  const isManagerAdmin = useMemo(() => checkAccess([UserRoles.ManagerAdmin], roles), [roles]);
  const isOwner = useMemo(() => checkAccess([UserRoles.Owner], roles), [roles]);
  const isReception = useMemo(() => checkAccess([UserRoles.Reception], roles), [roles]);
  const isReceptionAdmin = useMemo(() => checkAccess([UserRoles.ReceptionAdmin], roles), [roles]);
  const isViewer = useMemo(() => checkAccess([UserRoles.Viewer], roles), [roles]);
  const isNurse = useMemo(() => checkAccess([UserRoles.Nurse], roles), [roles]);
  const isAdminGroup = useMemo(
    () => checkAccess([UserRoles.MasterAdmin, UserRoles.ManagerAdmin, UserRoles.ReceptionAdmin], roles),
    [roles],
  );
  const isClinicUser = useMemo(
    () =>
      checkAccess([UserRoles.Doctor, UserRoles.Viewer, UserRoles.Nurse, UserRoles.Reception, UserRoles.Manager], roles),
    [roles],
  );
  const isViewOnlyMode = currentUser?.clinicMode?.value === clinicModeValues.VIEW_ONLY;

  return {
    currentUser,
    isAdmin,
    isAdminGroup,
    isAuthenticated,
    isClinicUser,
    isDoctor,
    isManager,
    isManagerAdmin,
    isOwner,
    isReception,
    isReceptionAdmin,
    isViewOnlyMode,
    isViewer,
    isNurse,
    token,
  };
};
