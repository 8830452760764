import { BillingPractitionerPackagesReducer } from 'app/redux/practitionerBillingPackages/types';

interface Payload {
  id: string;
  total: number;
}

export const updatedInsurances = (state: BillingPractitionerPackagesReducer, payload: Payload) => {
  //todo: implement during 2nd phase
  const updatedInsurances =
    state.data?.insurances.map((item) =>
      item.id === payload.id
        ? {
            ...item,
            totalPrice: Number(payload.total),
          }
        : item,
    ) || [];

  return {
    ...state,
    ...(state.data && {
      data: {
        ...state.data,
        insurances: updatedInsurances,
      },
    }),
  };
};
