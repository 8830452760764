export const FETCH_NOTIFICATION_SCENARIOS_REQUEST = 'notifications/FETCH_NOTIFICATION_SCENARIOS_REQUEST';
export const FETCH_NOTIFICATION_SCENARIOS_SUCCESS = 'notifications/FETCH_NOTIFICATION_SCENARIOS_SUCCESS';
export const FETCH_NOTIFICATION_SCENARIOS_FAILURE = 'notifications/FETCH_NOTIFICATION_SCENARIOS_FAILURE';

export const FETCH_STAKEHOLDER_OPTIONS_REQUEST = 'notifications/FETCH_STAKEHOLDER_OPTIONS_REQUEST';
export const FETCH_STAKEHOLDER_OPTIONS_SUCCESS = 'notifications/FETCH_STAKEHOLDER_OPTIONS_SUCCESS';
export const FETCH_STAKEHOLDER_OPTIONS_FAILURE = 'notifications/FETCH_STAKEHOLDER_OPTIONS_FAILURE';

export const FETCH_NOTIFICATION_SCENARIOS_BY_STAKEHOLDER_REQUEST =
  'notifications/FETCH_NOTIFICATION_SCENARIOS_BY_STAKEHOLDER_REQUEST';
export const FETCH_NOTIFICATION_SCENARIOS_BY_STAKEHOLDER_SUCCESS =
  'notifications/FETCH_NOTIFICATION_SCENARIOS_BY_STAKEHOLDER_SUCCESS';
export const FETCH_NOTIFICATION_SCENARIOS_BY_STAKEHOLDER_FAILURE =
  'notifications/FETCH_NOTIFICATION_SCENARIOS_BY_STAKEHOLDER_FAILURE';

export const FETCH_NOTIFICATION_SCENARIO_CONTENT_REQUEST = 'notifications/FETCH_NOTIFICATION_SCENARIO_CONTENT_REQUEST';
export const FETCH_NOTIFICATION_SCENARIO_CONTENT_SUCCESS = 'notifications/FETCH_NOTIFICATION_SCENARIO_CONTENT_SUCCESS';
export const FETCH_NOTIFICATION_SCENARIO_CONTENT_FAILURE = 'notifications/FETCH_NOTIFICATION_SCENARIO_CONTENT_FAILURE';

export const FETCH_NOTIFICATION_CLINICS_REQUEST = 'notifications/FETCH_NOTIFICATION_CLINICS_REQUEST';
export const FETCH_NOTIFICATION_CLINICS_SUCCESS = 'notifications/FETCH_NOTIFICATION_CLINICS_SUCCESS';
export const FETCH_NOTIFICATION_CLINICS_FAILURE = 'notifications/FETCH_NOTIFICATION_SCLINICS_FAILURE';

export const FETCH_NOTIFICATION_SCHEDULED_REQUEST = 'notifications/FETCH_NOTIFICATION_SCHEDULED_REQUEST';
export const FETCH_NOTIFICATION_SCHEDULED_SUCCESS = 'notifications/FETCH_NOTIFICATION_SCHEDULED_SUCCESS';
export const FETCH_NOTIFICATION_SCHEDULED_FAILURE = 'notifications/FETCH_NOTIFICATION_SCHEDULED_FAILURE';

export const ADD_NOTIFICATION_MASS_MESSAGING_REQUEST = 'notifications/ADD_NOTIFICATION_MASS_MESSAGING_REQUEST';
export const ADD_NOTIFICATION_MASS_MESSAGING_SUCCESS = 'notifications/ADD_NOTIFICATION_MASS_MESSAGING_SUCCESS';
export const ADD_NOTIFICATION_MASS_MESSAGING_FAILURE = 'notifications/ADD_NOTIFICATION_MASS_MESSAGING_FAILURE';

export const EDIT_NOTIFICATION_SCHEDULED_REQUEST = 'notifications/EDIT_NOTIFICATION_SCHEDULED_REQUEST';
export const EDIT_NOTIFICATION_SCHEDULED_SUCCESS = 'notifications/EDIT_NOTIFICATION_SCHEDULED_SUCCESS';
export const EDIT_NOTIFICATION_SCHEDULED_FAILURE = 'notifications/EDIT_NOTIFICATION_SCHEDULED_FAILURE';

export const EDIT_NOTIFICATION_SCENARIO = 'EDIT_NOTIFICATION_SCENARIO';

export const EDIT_NOTIFICATION_SCENARIO_CONTENT_REQUEST = 'notifications/EDIT_NOTIFICATION_SCENARIO_CONTENT_REQUEST';
export const EDIT_NOTIFICATION_SCENARIO_CONTENT_SUCCESS = 'notifications/EDIT_NOTIFICATION_SCENARIO_CONTENT_SUCCESS';
export const EDIT_NOTIFICATION_SCENARIO_CONTENT_FAILURE = 'notifications/EDIT_NOTIFICATION_SCENARIO_CONTENT_FAILURE';

export const EDIT_NOTIFICATION_SCENARIO_STATUS_REQUEST = 'notifications/EDIT_NOTIFICATION_SCENARIO_STATUS_REQUEST';
export const EDIT_NOTIFICATION_SCENARIO_STATUS_SUCCESS = 'notifications/EDIT_NOTIFICATION_SCENARIO_STATUS_SUCCESS';
export const EDIT_NOTIFICATION_SCENARIO_STATUS_FAILURE = 'notifications/EDIT_NOTIFICATION_SCENARIO_STATUS_FAILURE';

export const ENABLE_CLINICS_NOTIFICATIONS_REQUEST = 'notifications/ENABLE_CLINICS_NOTIFICATIONS_REQUEST';
export const ENABLE_CLINICS_NOTIFICATIONS_SUCCESS = 'notifications/ENABLE_CLINICS_NOTIFICATIONS_SUCCESS';
export const ENABLE_CLINICS_NOTIFICATIONS_FAILURE = 'notifications/ENABLE_CLINICS_NOTIFICATIONS_FAILURE';

export const DISABLE_CLINICS_NOTIFICATIONS_REQUEST = 'notifications/DISABLE_CLINICS_NOTIFICATIONS_REQUEST';
export const DISABLE_CLINICS_NOTIFICATIONS_SUCCESS = 'notifications/DISABLE_CLINICS_NOTIFICATIONS_SUCCESS';
export const DISABLE_CLINICS_NOTIFICATIONS_FAILURE = 'notifications/DISABLE_CLINICS_NOTIFICATIONS_FAILURE';

export const NOTIFICATION_RESET = 'notifications/NOTIFICATION_RESET';
