import * as types from './dependents.types';

const initialState = {
  searchPatient: {
    loading: false,
    data: null,
    error: false,
  },
  dependentModalIsOpen: false,
  pagination: {},
};

export const dependentsReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.FETCH_PATIENT_DEPENDENTS_REQUEST:
      return {
        ...state,
        data: [],
        loading: true,
      };
    case types.FETCH_PATIENT_DEPENDENTS_SUCCESS:
      return {
        ...state,
        data: action.payload.data,
        pagination: action.payload.pagination,
        loading: false,
      };
    case types.FETCH_PATIENT_DEPENDENTS_FAILURE:
      return {
        ...state,
        data: action.payload,
        loading: false,
        error: true,
      };
    default:
      return state;
  }
};
