import { IconCircleCheck, IconInfoCircle } from '@tabler/icons-react';

interface Props {
  complete: boolean;
}

export const CompletionStatusIcon = ({ complete }: Props) => {
  return (
    <div className="wizardStep__completionStatus me-2">
      {complete && <IconCircleCheck className="text-success" size={36} strokeWidth={1.2} />}
      {!complete && <IconInfoCircle className="text-danger" size={36} strokeWidth={1.2} />}
    </div>
  );
};
