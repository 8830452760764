import { useRef } from 'react';
import { Mesh, MeshPhongMaterial } from 'three';
import { GLTF } from 'three/examples/jsm/loaders/GLTFLoader';
import { useGLTF, ContactShadows, useBVH } from '@react-three/drei';

const filesUrl = `${process.env.PUBLIC_URL}/files/models/permanent-teeth-q10-transformed.glb`;

const material = new MeshPhongMaterial({
  color: '#d2d2d2',
  flatShading: false,
  shininess: 250,
});

type ModelResult = GLTF & {
  nodes: {
    ['11']: Mesh;
    ['12']: Mesh;
    ['13']: Mesh;
    ['14']: Mesh;
    ['15']: Mesh;
    ['16']: Mesh;
    ['17']: Mesh;
    ['18']: Mesh;
    ['21']: Mesh;
    ['22']: Mesh;
    ['23']: Mesh;
    ['24']: Mesh;
    ['25']: Mesh;
    ['26']: Mesh;
    ['27']: Mesh;
    ['28']: Mesh;
    ['31']: Mesh;
    ['32']: Mesh;
    ['33']: Mesh;
    ['34']: Mesh;
    ['35']: Mesh;
    ['36']: Mesh;
    ['37']: Mesh;
    ['38']: Mesh;
    ['41']: Mesh;
    ['42']: Mesh;
    ['43']: Mesh;
    ['44']: Mesh;
    ['45']: Mesh;
    ['46']: Mesh;
    ['47']: Mesh;
    ['48']: Mesh;
  };
  materials: {};
};

export const PermanentTeethModel = (props: JSX.IntrinsicElements['group']) => {
  const { nodes } = useGLTF(filesUrl) as unknown as ModelResult;

  const m11Ref = useRef<Mesh>(null!);
  const m12Ref = useRef<Mesh>(null!);
  const m13Ref = useRef<Mesh>(null!);
  const m14Ref = useRef<Mesh>(null!);
  const m15Ref = useRef<Mesh>(null!);
  const m16Ref = useRef<Mesh>(null!);
  const m17Ref = useRef<Mesh>(null!);
  const m18Ref = useRef<Mesh>(null!);
  const m21Ref = useRef<Mesh>(null!);
  const m22Ref = useRef<Mesh>(null!);
  const m23Ref = useRef<Mesh>(null!);
  const m24Ref = useRef<Mesh>(null!);
  const m25Ref = useRef<Mesh>(null!);
  const m26Ref = useRef<Mesh>(null!);
  const m27Ref = useRef<Mesh>(null!);
  const m28Ref = useRef<Mesh>(null!);
  const m31Ref = useRef<Mesh>(null!);
  const m32Ref = useRef<Mesh>(null!);
  const m33Ref = useRef<Mesh>(null!);
  const m34Ref = useRef<Mesh>(null!);
  const m35Ref = useRef<Mesh>(null!);
  const m36Ref = useRef<Mesh>(null!);
  const m37Ref = useRef<Mesh>(null!);
  const m38Ref = useRef<Mesh>(null!);
  const m41Ref = useRef<Mesh>(null!);
  const m42Ref = useRef<Mesh>(null!);
  const m43Ref = useRef<Mesh>(null!);
  const m44Ref = useRef<Mesh>(null!);
  const m45Ref = useRef<Mesh>(null!);
  const m46Ref = useRef<Mesh>(null!);
  const m47Ref = useRef<Mesh>(null!);
  const m48Ref = useRef<Mesh>(null!);

  useBVH(m11Ref);
  useBVH(m12Ref);
  useBVH(m13Ref);
  useBVH(m14Ref);
  useBVH(m15Ref);
  useBVH(m16Ref);
  useBVH(m17Ref);
  useBVH(m18Ref);
  useBVH(m21Ref);
  useBVH(m22Ref);
  useBVH(m23Ref);
  useBVH(m24Ref);
  useBVH(m25Ref);
  useBVH(m26Ref);
  useBVH(m27Ref);
  useBVH(m28Ref);
  useBVH(m31Ref);
  useBVH(m32Ref);
  useBVH(m33Ref);
  useBVH(m34Ref);
  useBVH(m35Ref);
  useBVH(m36Ref);
  useBVH(m37Ref);
  useBVH(m38Ref);
  useBVH(m41Ref);
  useBVH(m42Ref);
  useBVH(m43Ref);
  useBVH(m44Ref);
  useBVH(m45Ref);
  useBVH(m46Ref);
  useBVH(m47Ref);
  useBVH(m48Ref);

  return (
    <group dispose={null}>
      <group scale={0.029} {...props}>
        <mesh geometry={nodes['11'].geometry} material={material} name="11" ref={m11Ref} />
        <mesh geometry={nodes['12'].geometry} material={material} name="12" ref={m12Ref} />
        <mesh geometry={nodes['13'].geometry} material={material} name="13" ref={m13Ref} />
        <mesh geometry={nodes['14'].geometry} material={material} name="14" ref={m14Ref} />
        <mesh geometry={nodes['15'].geometry} material={material} name="15" ref={m15Ref} />
        <mesh geometry={nodes['16'].geometry} material={material} name="16" ref={m16Ref} />
        <mesh geometry={nodes['17'].geometry} material={material} name="17" ref={m17Ref} />
        <mesh geometry={nodes['18'].geometry} material={material} name="18" ref={m18Ref} />
        <mesh geometry={nodes['21'].geometry} material={material} name="21" ref={m21Ref} />
        <mesh geometry={nodes['22'].geometry} material={material} name="22" ref={m22Ref} />
        <mesh geometry={nodes['23'].geometry} material={material} name="23" ref={m23Ref} />
        <mesh geometry={nodes['24'].geometry} material={material} name="24" ref={m24Ref} />
        <mesh geometry={nodes['25'].geometry} material={material} name="25" ref={m25Ref} />
        <mesh geometry={nodes['26'].geometry} material={material} name="26" ref={m26Ref} />
        <mesh geometry={nodes['27'].geometry} material={material} name="27" ref={m27Ref} />
        <mesh geometry={nodes['28'].geometry} material={material} name="28" ref={m28Ref} />
        <mesh geometry={nodes['31'].geometry} material={material} name="31" ref={m31Ref} />
        <mesh geometry={nodes['32'].geometry} material={material} name="32" ref={m32Ref} />
        <mesh geometry={nodes['33'].geometry} material={material} name="33" ref={m33Ref} />
        <mesh geometry={nodes['34'].geometry} material={material} name="34" ref={m34Ref} />
        <mesh geometry={nodes['35'].geometry} material={material} name="35" ref={m35Ref} />
        <mesh geometry={nodes['36'].geometry} material={material} name="36" ref={m36Ref} />
        <mesh geometry={nodes['37'].geometry} material={material} name="37" ref={m37Ref} />
        <mesh geometry={nodes['38'].geometry} material={material} name="38" ref={m38Ref} />
        <mesh geometry={nodes['41'].geometry} material={material} name="41" ref={m41Ref} />
        <mesh geometry={nodes['42'].geometry} material={material} name="42" ref={m42Ref} />
        <mesh geometry={nodes['43'].geometry} material={material} name="43" ref={m43Ref} />
        <mesh geometry={nodes['44'].geometry} material={material} name="44" ref={m44Ref} />
        <mesh geometry={nodes['45'].geometry} material={material} name="45" ref={m45Ref} />
        <mesh geometry={nodes['46'].geometry} material={material} name="46" ref={m46Ref} />
        <mesh geometry={nodes['47'].geometry} material={material} name="47" ref={m47Ref} />
        <mesh geometry={nodes['48'].geometry} material={material} name="48" ref={m48Ref} />
      </group>
      <ContactShadows castShadow opacity={0.3} scale={5} blur={5} position={[0, -1, 0]} />
    </group>
  );
};
