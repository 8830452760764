import { AnyAction } from 'redux';
import { createAction } from 'redux-api-middleware';
import { Referral } from 'app/types';
import { DiscardReferralBodyParams, MoveBackReferralBodyParams } from 'app/redux/referrals/types';
import * as types from 'app/redux/referrals/referrals.types';

// Locals
export const setSelectedReferral = (referral: Referral, rowIndex: number): AnyAction => ({
  type: types.SET_SELECTED_REFERRAL,
  payload: { referral, rowIndex },
});

export const resetSelectedReferral = (): AnyAction => ({
  type: types.RESET_SELECTED_REFERRAL,
});

// Remotes
export const discardReferral = (referralId: string, body: DiscardReferralBodyParams) =>
  createAction<any, Referral, unknown>({
    endpoint: `referrals/${referralId}/discard`,
    method: 'PUT',
    types: [
      types.DISCARD_REFERRAL_REQUEST,
      {
        type: types.DISCARD_REFERRAL_SUCCESS,
        meta: {
          notification: {
            type: 'success',
            title: 'REFERRALS.TEXT.DISCARD-SUCCESS',
          },
        },
      },
      {
        type: types.DISCARD_REFERRAL_FAILURE,
        meta: {
          notification: {
            type: 'danger',
            title: 'CORE.TEXT.DEFAULT-FAILURE-MESSAGE',
          },
        },
      },
    ],
    // @ts-ignore custom body middleware
    body,
  });

export const moveBackReferral = (referralId: string, body: MoveBackReferralBodyParams) =>
  createAction<any, Referral, unknown>({
    endpoint: `referrals/${referralId}/active`,
    method: 'PUT',
    types: [
      types.MOVE_BACK_REFERRAL_REQUEST,
      {
        type: types.MOVE_BACK_REFERRAL_SUCCESS,
        meta: {
          notification: {
            type: 'success',
            title: 'REFERRALS.TEXT.MOVE-BACK-SUCCESS',
          },
        },
      },
      {
        type: types.MOVE_BACK_REFERRAL_FAILURE,
        meta: {
          notification: {
            type: 'danger',
            title: 'CORE.TEXT.DEFAULT-FAILURE-MESSAGE',
          },
        },
      },
    ],
    // @ts-ignore custom body middleware
    body,
  });

export const fetchReferral = (referralId: string) =>
  createAction<any, Referral, unknown>({
    endpoint: `referrals/${referralId}`,
    method: 'GET',
    types: [types.FETCH_REFERRAL_REQUEST, types.FETCH_REFERRAL_SUCCESS, types.FETCH_REFERRAL_FAILURE],
  });

export const setReferralId = (referralId: string | null) => ({
  type: types.SET_REFERRAL_ID,
  payload: referralId,
});

export const resetReferral = () => ({
  type: types.RESET_REFERRAL,
});
