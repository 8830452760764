export const ADD_DRAFT_ITEM = 'invoice/ADD_DRAFT_ITEM';

export const ADD_INVOICE_ITEM_REQUEST = 'invoice/ADD_INVOICE_ITEM_REQUEST';
export const ADD_INVOICE_ITEM_SUCCESS = 'invoice/ADD_INVOICE_ITEM_SUCCESS';
export const ADD_INVOICE_ITEM_FAILURE = 'invoice/ADD_INVOICE_ITEM_FAILURE';

export const ADD_PATIENT_PAYMENT_REQUEST = 'invoice/ADD_PATIENT_PAYMENT_REQUEST';
export const ADD_PATIENT_PAYMENT_SUCCESS = 'invoice/ADD_PATIENT_PAYMENT_SUCCESS';
export const ADD_PATIENT_PAYMENT_FAILURE = 'invoice/ADD_PATIENT_PAYMENT_FAILURE';

export const ADD_INSURANCE_PAYMENT_REQUEST = 'invoice/ADD_INSURANCE_PAYMENT_REQUEST';
export const ADD_INSURANCE_PAYMENT_SUCCESS = 'invoice/ADD_INSURANCE_PAYMENT_SUCCESS';
export const ADD_INSURANCE_PAYMENT_FAILURE = 'invoice/ADD_INSURANCE_PAYMENT_FAILURE';

export const CREATE_INVOICE_REQUEST = 'invoice/CREATE_INVOICE_REQUEST';
export const CREATE_INVOICE_SUCCESS = 'invoice/CREATE_INVOICE_SUCCESS';
export const CREATE_INVOICE_FAILURE = 'invoice/CREATE_INVOICE_FAILURE';

export const EDIT_INSURANCE_PAYMENT_REQUEST = 'invoice/EDIT_INSURANCE_PAYMENT_REQUEST';
export const EDIT_INSURANCE_PAYMENT_SUCCESS = 'invoice/EDIT_INSURANCE_PAYMENT_SUCCESS';
export const EDIT_INSURANCE_PAYMENT_FAILURE = 'invoice/EDIT_INSURANCE_PAYMENT_FAILURE';

export const EDIT_PATIENT_PAYMENT_REQUEST = 'invoice/EDIT_PATIENT_PAYMENT_REQUEST';
export const EDIT_PATIENT_PAYMENT_SUCCESS = 'invoice/EDIT_PATIENT_PAYMENT_SUCCESS';
export const EDIT_PATIENT_PAYMENT_FAILURE = 'invoice/EDIT_PATIENT_PAYMENT_FAILURE';

export const FETCH_INVOICE_APPOINTMENT_REQUEST = 'invoice/FETCH_INVOICE_APPOINTMENT_REQUEST';
export const FETCH_INVOICE_APPOINTMENT_SUCCESS = 'invoice/FETCH_INVOICE_APPOINTMENT_SUCCESS';
export const FETCH_INVOICE_APPOINTMENT_FAILURE = 'invoice/FETCH_INVOICE_APPOINTMENT_FAILURE';

export const FETCH_INVOICE_DETAILS_REQUEST = 'invoice/FETCH_INVOICE_DETAILS_REQUEST';
export const FETCH_INVOICE_DETAILS_SUCCESS = 'invoice/FETCH_INVOICE_DETAILS_SUCCESS';
export const FETCH_INVOICE_DETAILS_FAILURE = 'invoice/FETCH_INVOICE_DETAILS_FAILURE';

export const DELETE_INVOICE_ITEM_REQUEST = 'invoice/DELETE_INVOICE_ITEM_REQUEST';
export const DELETE_INVOICE_ITEM_SUCCESS = 'invoice/DELETE_INVOICE_ITEM_SUCCESS';
export const DELETE_INVOICE_ITEM_FAILURE = 'invoice/DELETE_INVOICE_ITEM_FAILURE';

export const DELETE_INVOICE_PAYMENT_REQUEST = 'invoice/DELETE_INVOICE_PAYMENT_REQUEST';
export const DELETE_INVOICE_PAYMENT_SUCCESS = 'invoice/DELETE_INVOICE_PAYMENT_SUCCESS';
export const DELETE_INVOICE_PAYMENT_FAILURE = 'invoice/DELETE_INVOICE_PAYMENT_FAILURE';

export const EDIT_INVOICE_ITEM_REQUEST = 'invoice/EDIT_INVOICE_ITEM_REQUEST';
export const EDIT_INVOICE_ITEM_SUCCESS = 'invoice/EDIT_INVOICE_ITEM_SUCCESS';
export const EDIT_INVOICE_ITEM_FAILURE = 'invoice/EDIT_INVOICE_ITEM_FAILURE';

export const EDIT_DRAFT_ITEM = 'invoice/EDIT_DRAFT_ITEM';

export const DELETE_DRAFT_ITEM = 'invoice/DELETE_DRAFT_ITEM';

export const FETCH_INVOICE_HISTORY_REQUEST = 'invoice/FETCH_INVOICE_HISTORY_REQUEST';
export const FETCH_INVOICE_HISTORY_SUCCESS = 'invoice/FETCH_INVOICE_HISTORY_SUCCESS';
export const FETCH_INVOICE_HISTORY_FAILURE = 'invoice/FETCH_INVOICE_HISTORY_FAILURE';

export const FETCH_SPECIALITIES_DICTIONARIES_REQUEST = 'invoice/FETCH_SPECIALITIES_DICTIONARIES_REQUEST';
export const FETCH_SPECIALITIES_DICTIONARIES_SUCCESS = 'invoice/FETCH_SPECIALITIES_DICTIONARIES_SUCCESS';
export const FETCH_SPECIALITIES_DICTIONARIES_FAILURE = 'invoice/FETCH_SPECIALITIES_DICTIONARIES_FAILURE';

export const FETCH_SUBSPECIALITIES_DICTIONARIES_REQUEST = 'invoice/FETCH_SUBSPECIALITIES_DICTIONARIES_REQUEST';
export const FETCH_SUBSPECIALITIES_DICTIONARIES_SUCCESS = 'invoice/FETCH_SUBSPECIALITIES_DICTIONARIES_SUCCESS';
export const FETCH_SUBSPECIALITIES_DICTIONARIES_FAILURE = 'invoice/FETCH_SUBSPECIALITIES_DICTIONARIES_FAILURE';

export const FETCH_DOCTOR_PRODUCT_PRICE_LIST_REQUEST = 'invoice/FETCH_DOCTOR_PRODUCT_PRICE_LIST_REQUEST';
export const FETCH_DOCTOR_PRODUCT_PRICE_LIST_SUCCESS = 'invoice/FETCH_DOCTOR_PRODUCT_PRICE_LIST_SUCCESS';
export const FETCH_DOCTOR_PRODUCT_PRICE_LIST_FAILURE = 'invoice/FETCH_DOCTOR_PRODUCT_PRICE_LIST_FAILURE';

export const FETCH_INVOICE_SHORT_REQUEST = 'invoice/FETCH_INVOICE_SHORT_REQUEST';
export const FETCH_INVOICE_SHORT_SUCCESS = 'invoice/FETCH_INVOICE_SHORT_SUCCESS';
export const FETCH_INVOICE_SHORT_FAILURE = 'invoice/FETCH_INVOICE_SHORT_FAILURE';

export const FETCH_CREDIT_NOTE_SHORT_REQUEST = 'invoice/FETCH_CREDIT_NOTE_SHORT_REQUEST';
export const FETCH_CREDIT_NOTE_SHORT_SUCCESS = 'invoice/FETCH_CREDIT_NOTE_SHORT_SUCCESS';
export const FETCH_CREDIT_NOTE_SHORT_FAILURE = 'invoice/FETCH_CREDIT_NOTE_SHORT_FAILURE';
