import { Badge } from 'reactstrap';
import { useAppIntl, useAppSelector } from 'app/helpers';
import { useInvoicingProcessState } from 'app/features/invoicingProcess/process/helpers';

export const FreeFollowUpBadge = () => {
  const { formatMessage } = useAppIntl();
  const { isCreated, isCreateCreditNoteMode, invoicePackageSnapshotId } = useInvoicingProcessState();
  const hasPreviousAppointments = useAppSelector(
    (state) => state.invoicingProcess.data?.hasPreviousAppointmentIn14Days,
  );
  const hasBadge = !isCreated && !isCreateCreditNoteMode && hasPreviousAppointments;

  if (!hasBadge || !!invoicePackageSnapshotId) {
    return null;
  }

  return (
    <Badge className="text-wrap mb-3" color="light-warning">
      {formatMessage({ id: 'CORE.TEXT.ELIGIBLE-FOR-FREE-FOLLOW-UP' })}
    </Badge>
  );
};
