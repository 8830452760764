import { AnyAction } from 'redux';
import findIndex from 'lodash/findIndex';
import { RemoteData } from 'app/types';
import { generateOptions } from 'app/helpers';
import * as types from 'app/redux/invoice/invoice.types';
import { generateParams } from 'app/redux/remoteData/helpers';
import {
  Invoice,
  InvoiceDictionaries,
  InvoiceDraft,
  InvoiceHistory,
  InvoiceItem,
  PriceList,
  ShortInfo,
} from 'app/redux/invoice/types';

interface InvoiceReducer {
  loading: boolean;
  error: boolean;
  data: Invoice;
  history: RemoteData<InvoiceHistory[]>;
  draft: InvoiceDraft;
  dictionaries: RemoteData<InvoiceDictionaries>;
  priceListPerWorkingTime: RemoteData<PriceList[]>;
  shortInfo: ShortInfo;
}

const initialState: InvoiceReducer = {
  loading: true,
  error: false,
  data: {
    patientPayments: [],
    insurancePayments: [],
    items: [],
  } as unknown as Invoice,
  history: {
    loading: false,
    data: [],
    error: false,
  },
  draft: {
    appointmentId: '',
    invoiceItems: [],
  } as unknown as InvoiceDraft,
  dictionaries: {
    loading: false,
    error: false,
    data: {
      doctors: [],
      specialities: [],
      subSpecialities: [],
    },
  },
  priceListPerWorkingTime: {
    loading: false,
    data: [],
    error: false,
  },
  shortInfo: {
    creditNote: null,
    invoice: null,
    loading: false,
  },
};

const updateItems = (oldItems: InvoiceItem[], newItems: InvoiceItem[]) => {
  const items = [...oldItems];

  newItems.forEach((newItem: any) => {
    const duplicateIndex = findIndex(items, { id: newItem.id });

    if (duplicateIndex !== -1) {
      // update if item has a duplicate
      items.splice(duplicateIndex, 1, newItem);
    } else {
      items.push(newItem);
    }
  });

  return items;
};

export const invoiceReducer = (state = initialState, action: AnyAction): InvoiceReducer => {
  switch (action.type) {
    case types.ADD_DRAFT_ITEM:
      return {
        ...state,
        draft: {
          ...state.draft,
          appointmentId: action.payload.appointmentId,
          invoiceItems: [...state.draft.invoiceItems, action.payload],
        },
      };
    case types.ADD_INVOICE_ITEM_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case types.ADD_INVOICE_ITEM_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case types.ADD_INVOICE_ITEM_FAILURE:
      return {
        ...state,
        loading: false,
        error: true,
      };
    case types.ADD_INSURANCE_PAYMENT_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case types.ADD_INSURANCE_PAYMENT_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case types.ADD_INSURANCE_PAYMENT_FAILURE:
      return {
        ...state,
        loading: false,
        error: true,
      };
    case types.ADD_PATIENT_PAYMENT_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case types.ADD_PATIENT_PAYMENT_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case types.ADD_PATIENT_PAYMENT_FAILURE:
      return {
        ...state,
        loading: false,
        error: true,
      };

    case types.CREATE_INVOICE_REQUEST:
      return {
        ...state,
        data: initialState.data,
        loading: true,
      };
    case types.CREATE_INVOICE_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case types.CREATE_INVOICE_FAILURE:
      return {
        ...state,
        loading: false,
        error: true,
      };
    case types.DELETE_INVOICE_ITEM_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case types.DELETE_INVOICE_ITEM_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case types.DELETE_INVOICE_ITEM_FAILURE:
      return {
        ...state,
        loading: false,
        error: true,
      };
    case types.DELETE_DRAFT_ITEM:
      return {
        ...state,
        draft: {
          ...state.draft,
          invoiceItems: [...state.draft.invoiceItems].filter((item, index) => index !== action.payload),
        },
      };

    case types.DELETE_INVOICE_PAYMENT_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case types.DELETE_INVOICE_PAYMENT_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case types.DELETE_INVOICE_PAYMENT_FAILURE:
      return {
        ...state,
        loading: false,
        error: true,
      };

    case types.EDIT_DRAFT_ITEM:
      return {
        ...state,
        draft: {
          ...state.draft,
          invoiceItems: state.draft.invoiceItems.map((item, index) =>
            index === action.payload.itemIndex ? action.payload.body : item,
          ),
        },
      };
    case types.EDIT_INVOICE_ITEM_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case types.EDIT_INVOICE_ITEM_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case types.EDIT_INVOICE_ITEM_FAILURE:
      return {
        ...state,
        loading: false,
        error: true,
      };
    case types.EDIT_PATIENT_PAYMENT_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case types.EDIT_PATIENT_PAYMENT_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case types.EDIT_PATIENT_PAYMENT_FAILURE:
      return {
        ...state,
        loading: false,
        error: true,
      };

    case types.FETCH_INVOICE_APPOINTMENT_REQUEST:
      return {
        ...state,
        data: initialState.data,
        draft: initialState.draft,
        loading: true,
      };
    case types.FETCH_INVOICE_APPOINTMENT_SUCCESS:
      return {
        ...state,
        draft: {
          ...state.draft,
          ...action.payload,
        },
        loading: false,
      };
    case types.FETCH_INVOICE_APPOINTMENT_FAILURE:
      return {
        ...state,
        loading: false,
        error: true,
      };
    case types.FETCH_INVOICE_DETAILS_REQUEST:
      return {
        ...state,
        data: initialState.data,
        loading: true,
      };
    case types.FETCH_INVOICE_DETAILS_SUCCESS:
      return {
        ...state,
        data: {
          ...action.payload,
          items: updateItems(state.data.items, [action.payload.appointmentServiceItem, ...action.payload.items]),
        },
        loading: false,
      };
    case types.FETCH_INVOICE_DETAILS_FAILURE:
      return {
        ...state,
        loading: false,
        error: true,
      };
    case types.FETCH_INVOICE_HISTORY_REQUEST:
      return {
        ...state,
        history: {
          ...state.history,
          loading: true,
        },
      };
    case types.FETCH_INVOICE_HISTORY_SUCCESS:
      return {
        ...state,
        history: {
          loading: false,
          data: action.payload.data,
          params: generateParams(action),
        },
      };
    case types.FETCH_INVOICE_HISTORY_FAILURE:
      return {
        ...state,
        history: {
          ...state.history,
          loading: false,
          error: true,
        },
      };

    case types.FETCH_SPECIALITIES_DICTIONARIES_REQUEST:
      return {
        ...state,
        dictionaries: {
          ...state.dictionaries,
          data: {
            ...state.dictionaries.data,
            specialities: [],
          },
          loading: true,
        },
      };
    case types.FETCH_SPECIALITIES_DICTIONARIES_SUCCESS:
      return {
        ...state,
        dictionaries: {
          ...state.dictionaries,
          data: {
            ...state.dictionaries.data,
            specialities: action.payload.data.map((item: any) => ({
              label: item.nameEn,
              value: item.id,
              translation: {
                en: item.nameEn,
                ar: item.nameAr,
              },
            })),
          },
          loading: false,
        },
      };
    case types.FETCH_SPECIALITIES_DICTIONARIES_FAILURE:
      return {
        ...state,
        dictionaries: {
          ...state.dictionaries,
          data: {
            ...state.dictionaries.data,
          },
          loading: false,
          error: true,
        },
      };

    case types.FETCH_SUBSPECIALITIES_DICTIONARIES_REQUEST:
      return {
        ...state,
        dictionaries: {
          ...state.dictionaries,
          data: {
            ...state.dictionaries.data,
            subSpecialities: [],
          },
          loading: true,
        },
      };
    case types.FETCH_SUBSPECIALITIES_DICTIONARIES_SUCCESS:
      return {
        ...state,
        dictionaries: {
          ...state.dictionaries,
          data: {
            ...state.dictionaries.data,
            subSpecialities: generateOptions(action.payload.data),
          },
          loading: false,
        },
      };
    case types.FETCH_SUBSPECIALITIES_DICTIONARIES_FAILURE:
      return {
        ...state,
        dictionaries: {
          ...state.dictionaries,
          data: {
            ...state.dictionaries.data,
          },
          loading: false,
          error: true,
        },
      };

    case types.FETCH_DOCTOR_PRODUCT_PRICE_LIST_REQUEST:
      return {
        ...state,
        priceListPerWorkingTime: {
          error: false,
          data: [],
          loading: true,
        },
      };

    case types.FETCH_DOCTOR_PRODUCT_PRICE_LIST_SUCCESS:
      return {
        ...state,
        priceListPerWorkingTime: {
          error: false,
          data: action.payload,
          loading: false,
        },
      };

    case types.FETCH_DOCTOR_PRODUCT_PRICE_LIST_FAILURE:
      return {
        ...state,
        priceListPerWorkingTime: {
          error: true,
          data: [],
          loading: false,
        },
      };

    // Invoice Short Info
    case types.FETCH_INVOICE_SHORT_REQUEST:
      return {
        ...state,
        shortInfo: {
          ...state.shortInfo,
          invoice: null,
          loading: true,
        },
      };
    case types.FETCH_INVOICE_SHORT_SUCCESS:
      return {
        ...state,
        shortInfo: {
          ...state.shortInfo,
          invoice: action.payload.data,
          loading: false,
        },
      };
    case types.FETCH_INVOICE_SHORT_FAILURE:
      return {
        ...state,
        shortInfo: initialState.shortInfo,
      };

    // Credit Note Short Info
    case types.FETCH_CREDIT_NOTE_SHORT_REQUEST:
      return {
        ...state,
        shortInfo: {
          ...state.shortInfo,
          creditNote: null,
          loading: true,
        },
      };
    case types.FETCH_CREDIT_NOTE_SHORT_SUCCESS:
      return {
        ...state,
        shortInfo: {
          ...state.shortInfo,
          creditNote: action.payload.data,
          loading: false,
        },
      };
    case types.FETCH_CREDIT_NOTE_SHORT_FAILURE:
      return {
        ...state,
        shortInfo: initialState.shortInfo,
      };

    default:
      return state;
  }
};
