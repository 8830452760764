export const ADD_CLINIC_REQUEST = 'clinic/ADD_CLINIC_REQUEST';
export const ADD_CLINIC_SUCCESS = 'clinic/ADD_CLINIC_SUCCESS';
export const ADD_CLINIC_FAILURE = 'clinic/ADD_CLINIC_FAILURE';

export const FETCH_CLINIC_REQUEST = 'clinic/FETCH_CLINIC_REQUEST';
export const FETCH_CLINIC_SUCCESS = 'clinic/FETCH_CLINIC_SUCCESS';
export const FETCH_CLINIC_FAILURE = 'clinic/FETCH_CLINIC_FAILURE';

export const FETCH_CLINIC_MODE_REQUEST = 'clinic/FETCH_CLINIC_MODE_REQUEST';
export const FETCH_CLINIC_MODE_SUCCESS = 'clinic/FETCH_CLINIC_MODE_SUCCESS';
export const FETCH_CLINIC_MODE_FAILURE = 'clinic/FETCH_CLINIC_MODE_FAILURE';

export const EDIT_CLINIC_REQUEST = 'clinic/EDIT_CLINIC_REQUEST';
export const EDIT_CLINIC_SUCCESS = 'clinic/EDIT_CLINIC_SUCCESS';
export const EDIT_CLINIC_FAILURE = 'clinic/EDIT_CLINIC_FAILURE';

export const EDIT_SYSTEM_OWNER_REQUEST = 'clinic/EDIT_SYSTEM_OWNER_REQUEST';
export const EDIT_SYSTEM_OWNER_SUCCESS = 'clinic/EDIT_SYSTEM_OWNER_SUCCESS';
export const EDIT_SYSTEM_OWNER_FAILURE = 'clinic/EDIT_SYSTEM_OWNER_FAILURE';

export const SET_CLINIC = 'clinic/SET_CLINIC';

export const APPROVE_CLINIC_REQUEST = 'clinic/APPROVE_CLINIC_REQUEST';
export const APPROVE_CLINIC_SUCCESS = 'clinic/APPROVE_CLINIC_SUCCESS';
export const APPROVE_CLINIC_FAILURE = 'clinic/APPROVE_CLINIC_FAILURE';

export const REJECT_CLINIC_REQUEST = 'clinic/REJECT_CLINIC_REQUEST';
export const REJECT_CLINIC_SUCCESS = 'clinic/REJECT_CLINIC_SUCCESS';
export const REJECT_CLINIC_FAILURE = 'clinic/REJECT_CLINIC_FAILURE';

export const FETCH_SYSTEM_OWNER_REQUEST = 'clinic/FETCH_SYSTEM_OWNER_REQUEST';
export const FETCH_SYSTEM_OWNER_SUCCESS = 'clinic/FETCH_SYSTEM_OWNER_SUCCESS';
export const FETCH_SYSTEM_OWNER_FAILURE = 'clinic/FETCH_SYSTEM_OWNER_FAILURE';

export const FETCH_CLINIC_SPECIALITIES_REQUEST = 'clinic/FETCH_CLINIC_SPECIALITIES_REQUEST';
export const FETCH_CLINIC_SPECIALITIES_SUCCESS = 'clinic/FETCH_CLINIC_SPECIALITIES_SUCCESS';
export const FETCH_CLINIC_SPECIALITIES_FAIL = 'clinic/FETCH_CLINIC_SPECIALITIES_FAIL';

export const FETCH_CLINIC_BRANCHES_REQUEST = 'clinic/FETCH_CLINIC_BRANCHES_REQUEST';
export const FETCH_CLINIC_BRANCHES_SUCCESS = 'clinic/FETCH_CLINIC_BRANCHES_SUCCESS';
export const FETCH_CLINIC_BRANCHES_FAIL = 'clinic/FETCH_CLINIC_BRANCHES_FAIL';
