import toast from 'react-hot-toast';
import { push } from 'connected-react-router';
import { put, select, takeEvery } from 'redux-saga/effects';
import { ROLES_OWNER } from 'app/shared';
import { checkAccess } from 'app/helpers';
import {
  CHANGE_PASSWORD_SUCCESS,
  EDIT_USER_PROFILE_SUCCESS,
  FETCH_CURRENT_USER_SUCCESS,
  FORGOTTEN_PASSWORD_SUCCESS,
  LOGIN_USER_FAILURE,
  LOGIN_USER_SUCCESS,
  LOGOUT,
  RESET_PASSWORD_SUCCESS,
  SOFT_LOGOUT,
} from 'app/redux/auth/auth.types';
import { fetchCurrentUser, logout } from 'app/redux/auth/auth.actions';
import { fetchDictionaries } from 'app/redux/dictionaries/dictionaries.actions';
import { toggleChangePasswordModal, toggleEditProfileModal } from 'app/redux/modals/modals.actions';
import { fetchClinicSystemOwner } from 'app/redux/clinic/clinic.actions';
import * as authStorageKeys from 'app/redux/auth/auth.constants';

const EXCLUDED_PATHS = [
  'access-denied',
  'forgotten-password',
  'password-reset',
  'offline',
  'registration',
  '400',
  '500',
];

export function* afterLoginSuccess() {
  const {
    router: { location },
  } = yield select();
  let prevPathname = location.prevPathname;

  if (!prevPathname || EXCLUDED_PATHS.some((pathPart) => prevPathname.includes(pathPart))) {
    prevPathname = '/';
  }

  yield put(fetchCurrentUser());
  yield put(fetchDictionaries());
  yield put(push(prevPathname));
  yield toast.success('CORE.TEXT.LOGIN-SUCCESS-MESSAGE');
}

export function* afterLoginFailure(props) {
  if (!props.payload?.response?.message) {
    yield toast.error('ERRORS.INVALID-CREDENTIALS');
  } else if (props.payload?.response?.message) {
    yield toast.error(props.payload?.response?.message);
  }
}

export function* afterLogoutSuccess() {
  localStorage.setItem(authStorageKeys.STORAGE_TOKEN_KEY, '');
  localStorage.setItem(authStorageKeys.STORAGE_TOKEN_EXPIRATION_DATE, '');
  localStorage.setItem(authStorageKeys.STORAGE_REFRESH_TOKEN_KEY, '');
  yield put(push('/'));
  window.location.reload();
}

export function* afterSoftLogout() {
  yield put(push('/'));
  window.location.reload();
}

export function* afterFetchCurrentUserSuccess() {
  const currentUser = yield select((state) => state.auth.current);

  if (currentUser.username && currentUser.hasToChangePassword) {
    yield put(toggleChangePasswordModal({ formDescription: 'CHANGE-PASSWORD.TEXT.DESCRIPTION' }));
  }
}

export function* afterforgottenPasswordSuccess() {
  yield put(push('/'));
}

export function* afterChangePasswordSuccess() {
  yield put(toggleChangePasswordModal());
  yield put(logout());
  yield toast.success('CHANGE-PASSWORD.TEXT.SUCCESS');
}

export function* afterEditProfileSuccess() {
  yield put(toggleEditProfileModal());

  const currentUser = yield select((state) => state.auth.current);

  if (checkAccess([ROLES_OWNER], currentUser.roles)) {
    yield put(fetchClinicSystemOwner(currentUser.clinic.value));
  }
}

function* authSaga() {
  yield takeEvery(LOGIN_USER_SUCCESS, afterLoginSuccess);
  yield takeEvery(LOGIN_USER_FAILURE, afterLoginFailure);
  yield takeEvery(LOGOUT, afterLogoutSuccess);
  yield takeEvery(SOFT_LOGOUT, afterSoftLogout);
  yield takeEvery([FORGOTTEN_PASSWORD_SUCCESS, RESET_PASSWORD_SUCCESS], afterforgottenPasswordSuccess);
  yield takeEvery(CHANGE_PASSWORD_SUCCESS, afterChangePasswordSuccess);
  yield takeEvery(FETCH_CURRENT_USER_SUCCESS, afterFetchCurrentUserSuccess);
  yield takeEvery(EDIT_USER_PROFILE_SUCCESS, afterEditProfileSuccess);
}

export default authSaga;
