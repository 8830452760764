import { RootState } from 'app/types';
import { select, put } from 'redux-saga/effects';
import { fetchBillingItemsPerformers, fetchPerformerBillingItems } from 'app/redux/remoteData/remoteData.actions';

export function* refreshPerformerItemLists(branchId: string, expandedOnly?: boolean) {
  const {
    billingItemsLists: { expandedPerformerId },
    remoteData: {
      billingItemsPerformers: { params },
    },
  }: RootState = yield select();

  if (params) {
    if (!expandedOnly) {
      // refreshing list of available practitioners
      yield put<any>(fetchBillingItemsPerformers(branchId, params, true));
    }

    // refreshing list of performer billing items if expanded
    if (expandedPerformerId) {
      yield put<any>(
        fetchPerformerBillingItems(branchId, expandedPerformerId, { ...params, keyword: undefined }, true),
      );
    }
  }
}
