import { useMemo } from 'react';
import { DescriptionList } from 'app/components';
import { getPatientFullName, getTranslation, useAppIntl, useAppSelector } from 'app/helpers';

export const DetailsPreview = () => {
  const { formatMessage, locale } = useAppIntl();
  const invoicingProcess = useAppSelector((state) => state.invoicingProcess.data);

  const items = useMemo(() => {
    const appointment = invoicingProcess?.appointment;

    return invoicingProcess
      ? [
          {
            label: formatMessage({ id: 'CORE.LABEL.INVOICE-NUMBER' }),
            value: invoicingProcess.invoiceNumber,
          },
          {
            label: formatMessage({ id: 'CORE.LABEL.PATIENT-NAME' }),
            value: getPatientFullName(invoicingProcess?.patient, locale),
          },
          {
            label: formatMessage({ id: 'CORE.LABEL.DOCTOR' }),
            value: invoicingProcess.doctor.label,
          },
          ...(appointment
            ? [
                {
                  label: formatMessage({ id: 'CORE.LABEL.MEDICAL-SPECIALITY' }),
                  value: getTranslation(appointment.speciality, locale),
                },
                {
                  label: formatMessage({ id: 'CORE.TABLE.MEDICAL-SERVICE' }),
                  value: getTranslation(appointment.subSpeciality, locale),
                },
                {
                  label: formatMessage({ id: 'CORE.TEXT.SERVICE' }),
                  value: getTranslation(appointment.product, locale),
                },
              ]
            : []),
        ]
      : [];
  }, [formatMessage, invoicingProcess, locale]);

  return <DescriptionList items={items} />;
};
