import classnames from 'classnames';
import './feedbackIcons.scss';

interface IconProps {
  className?: string;
}

export const SuccessIcon = ({ className }: IconProps) => (
  <div className={classnames('feedbackIcon', className)}>
    <div className="feedbackIcon-success">
      <div className="feedbackIcon-success-tip" />
      <div className="feedbackIcon-success-long" />
      <div className="feedbackIcon-success-placeholder" />
      <div className="feedbackIcon-success-fix" />
    </div>
  </div>
);

export const WarningIcon = ({ className }: IconProps) => (
  <div className={classnames('feedbackIcon', className)}>
    <div className="feedbackIcon-warning">
      <div className="feedbackIcon-warning-body" />
      <div className="feedbackIcon-warning-dot" />
    </div>
  </div>
);

export const ErrorIcon = ({ className }: IconProps) => (
  <div className={classnames('feedbackIcon', className)}>
    <div className="feedbackIcon-error">
      <div className="feedbackIcon-error-x">
        <div className="feedbackIcon-error-left" />
        <div className="feedbackIcon-error-right" />
      </div>
      <div className="feedbackIcon-error-placeholder" />
      <div className="feedbackIcon-error-fix" />
    </div>
  </div>
);
