import React from 'react';
import { Input } from 'reactstrap';
import { fieldPropTypes } from 'redux-form';
import PropTypes from 'prop-types';
import { LANGUAGES_AR_CODE, LANGUAGES_EN_CODE } from 'app/shared/index';
import classnames from 'classnames';

export const PrefixComponent = ({ input, isRtl, options }) => (
  <Input
    {...input}
    className={classnames({
      'input-with-prefix__select': true,
      'input-with-prefix__select--rtl': isRtl,
    })}
    type="select"
  >
    {options.map((option) => (
      <option value={option.value} key={option.value}>
        {option.translation ? option.translation[isRtl ? LANGUAGES_AR_CODE : LANGUAGES_EN_CODE] : option.label}
      </option>
    ))}
  </Input>
);

PrefixComponent.propTypes = {
  ...fieldPropTypes,
  isRtl: PropTypes.bool.isRequired,
  locale: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(PropTypes.object).isRequired,
};
