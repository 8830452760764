export const requestTypeValues = {
  EDIT_CLINIC: 'EditClinic',
  ADD_BRANCH: 'AddBranch',
  EDIT_BRANCH: 'EditBranch',
};

export const getRequestsTypes = (formatMessage) => [
  {
    label: formatMessage({ id: 'REQUESTS.TEXT.EDIT-CLINIC' }),
    value: requestTypeValues.EDIT_CLINIC,
  },
  {
    label: formatMessage({ id: 'REQUESTS.TEXT.ADD-BRANCH' }),
    value: requestTypeValues.ADD_BRANCH,
  },
  {
    label: formatMessage({ id: 'REQUESTS.TEXT.EDIT-BRANCH' }),
    value: requestTypeValues.EDIT_BRANCH,
  },
];

export const getRequestTypeLabel = (type, formatMessage) => {
  const types = getRequestsTypes(formatMessage).filter((s) => s.value === type);

  if (types[0]) {
    return types[0].label;
  }

  return type;
};
