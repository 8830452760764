import isEqual from 'lodash/isEqual';
import { ICD10Code } from 'app/types';

interface Props {
  initialTable?: ICD10Code[] | null;
  currentTable?: ICD10Code[] | null;
}

export const isMedicalProfileTablePristine = ({ initialTable, currentTable }: Props): boolean => {
  // Helper Variables
  const currentContainCodes = currentTable && currentTable.length > 0;
  const initialContainCodes = initialTable && initialTable.length > 0;

  // Conditions
  const areBothEmpty = !currentContainCodes && !initialContainCodes;

  return areBothEmpty ? true : isEqual(initialTable, currentTable);
};
