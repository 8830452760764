import { createAction, RSAA } from 'redux-api-middleware';
import { PatientsArrival } from 'app/types';
import { InitialAssessmentBody } from 'app/redux/initialAssessmentWizard/types';
import * as types from 'app/redux/initialAssessmentWizard/initialAssessmentWizard.types';

export const setActiveStep = (step: string | null) => ({
  type: types.SET_ACTIVE_STEP,
  payload: step,
});

export const setBackgroundCompleted = () => ({
  type: types.BACKGROUND_STEP_COMPLETED,
});

export const setVitalsCompleted = () => ({
  type: types.VITALS_STEP_COMPLETED,
});

export const setNoteCompleted = () => ({
  type: types.NOTE_STEP_COMPLETED,
});
export const reset = () => ({
  type: types.RESET,
});

export const setArrival = (arrival: PatientsArrival) => ({
  type: types.SET_ARRIVAL,
  payload: arrival,
});

export const postInitialAssessment = (
  initialAssessmentBody: InitialAssessmentBody,
  appointmentId: string,
  branchId: string,
) =>
  createAction<any, undefined, unknown>({
    endpoint: `/branches/${branchId}/appointments/${appointmentId}/initial-assessment`,
    method: 'POST',
    types: [
      types.ADD_ASSESSMENT_DATA_REQUEST,
      {
        type: types.ADD_ASSESSMENT_DATA_SUCCESS,
        meta: {
          notification: {
            type: 'success',
            title: 'PATIENTS-ARRIVAL.TEXT.INITIAL-ASSESSMENT-NOTIFICATION',
          },
        },
      },
      {
        type: types.ADD_ASSESSMENT_DATA_FAILURE,
        meta: {
          notification: {
            type: 'danger',
            title: 'CORE.TEXT.DEFAULT-FAILURE-MESSAGE',
          },
        },
      },
    ],
    // @ts-ignore custom body middleware
    body: initialAssessmentBody,
  });

export const postEditedAssessment = (
  initialAssessmentBody: InitialAssessmentBody,
  appointmentId: string,
  branchId: string,
) =>
  createAction<any, undefined, unknown>({
    endpoint: `/branches/${branchId}/appointments/${appointmentId}/initial-assessment`,
    method: 'PUT',
    types: [
      types.PUT_ASSESSMENT_DATA_REQUEST,
      {
        type: types.PUT_ASSESSMENT_DATA_SUCCESS,
        meta: {
          notification: {
            type: 'success',
            title: 'PATIENTS-ARRIVAL.TEXT.EDIT-INITIAL-ASSESSMENT-NOTIFICATION',
          },
        },
      },
      {
        type: types.PUT_ASSESSMENT_DATA_FAILURE,
        meta: {
          notification: {
            type: 'danger',
            title: 'CORE.TEXT.DEFAULT-FAILURE-MESSAGE',
          },
        },
      },
    ],
    // @ts-ignore custom body middleware
    body: initialAssessmentBody,
  });

export const fetchInitialAssessment = (appointmentId: string, branchId: string) => {
  return {
    [RSAA]: {
      endpoint: `/branches/${branchId}/appointments/${appointmentId}/initial-assessment`,
      method: 'GET',
      types: [
        types.FETCH_ASSESSMENT_DATA_REQUEST,
        types.FETCH_ASSESSMENT_DATA_SUCCESS,
        types.FETCH_ASSESSMENT_DATA_FAILURE,
      ],
    },
  };
};

export const fetchButtons = (specialityType: string, patientId?: string, selectedGender?: string) => {
  return {
    [RSAA]: {
      endpoint: `/specialities/speciality-types/${specialityType}/illness-types`,
      method: 'GET',
      types: [types.GET_BUTTONS_REQUEST, types.GET_BUTTONS_SUCCESS, types.GET_BUTTONS_FAILURE],
      params: { patientId, selectedGender },
    },
  };
};
