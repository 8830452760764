import { StepType } from 'app/bookAppointment/types';

export const getPreviousStep = (step: StepType) => {
  switch (step) {
    case StepType.Patient:
      return StepType.ServiceWithWorkingDay;
    case StepType.ServiceWithWorkingDay:
      return StepType.SubSpeciality;
    case StepType.SubSpeciality:
      return StepType.Speciality;
    default:
      return StepType.Speciality;
  }
};
