import { PhoneNumberUtil } from 'google-libphonenumber';

export const getCountryCodeLength = (phoneNumber: string): number => {
  const phoneUtil = PhoneNumberUtil.getInstance();

  try {
    const parsedNumber = phoneUtil.parse(phoneNumber);
    return String(parsedNumber.getCountryCode()).length;
  } catch (e) {
    return 0;
  }
};
