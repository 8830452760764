import React from 'react';
import PropTypes from 'prop-types';
import { Modal } from 'reactstrap';
import { useModalCleaner } from 'app/helpers';
import { FormContainer } from './form/form.container';

export const ChangePasswordModalComponent = (props) => {
  useModalCleaner('changePassword');

  return (
    <Modal backdrop="static" keyboard={false} isOpen={props.isOpen} toggle={props.toggle}>
      <FormContainer closeModal={props.toggle} formDescription={props.formDescription} />
    </Modal>
  );
};

ChangePasswordModalComponent.propTypes = {
  toggle: PropTypes.func.isRequired,
  formDescription: PropTypes.string,
  isOpen: PropTypes.bool.isRequired,
};
