export const FETCH_APPOINTMENTS_SUMMARY_REQUEST = 'dashboard/FETCH_APPOINTMENTS_SUMMARY_REQUEST';
export const FETCH_APPOINTMENTS_SUMMARY_SUCCESS = 'dashboard/FETCH_APPOINTMENTS_SUMMARY_SUCCESS';
export const FETCH_APPOINTMENTS_SUMMARY_FAILURE = 'dashboard/FETCH_APPOINTMENTS_SUMMARY_FAILURE';

export const FETCH_USER_PERFORMANCE_REQUEST = 'dashboard/FETCH_USER_PERFORMANCE_REQUEST';
export const FETCH_USER_PERFORMANCE_SUCCESS = 'dashboard/FETCH_USER_PERFORMANCE_SUCCESS';
export const FETCH_USER_PERFORMANCE_FAILURE = 'dashboard/FETCH_USER_PERFORMANCE_FAILURE';

export const FETCH_MIN_DATE_REQUEST = 'dashboard/FETCH_MIN_DATE_REQUEST';
export const FETCH_MIN_DATE_SUCCESS = 'dashboard/FETCH_MIN_DATE_SUCCESS';
export const FETCH_MIN_DATE_FAILURE = 'dashboard/FETCH_MIN_DATE_FAILURE';
