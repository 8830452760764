import React from 'react';
import { Badge } from 'reactstrap';
import { useAppIntl } from 'app/helpers';

interface Props {
  isExcluded: boolean;
  onInsurance?: boolean;
}

export const ExcludedStatus = ({ isExcluded, onInsurance }: Props) => {
  const { formatMessage } = useAppIntl();

  const exclusionBadgeColor = isExcluded ? 'light-danger' : 'light-success';
  const exclusionStatusMessageId = isExcluded ? 'CORE.LABEL.EXCLUDED' : 'CORE.LABEL.INCLUDED';

  if (typeof isExcluded === 'undefined') {
    return null;
  }

  if (typeof onInsurance === 'undefined') {
    return <Badge color={exclusionBadgeColor}>{formatMessage({ id: exclusionStatusMessageId })}</Badge>;
  }

  return onInsurance ? (
    <Badge color={exclusionBadgeColor}>{formatMessage({ id: exclusionStatusMessageId })}</Badge>
  ) : (
    <>{formatMessage({ id: 'CORE.LABEL.NA' })}</>
  );
};
