import { useMemo } from 'react';
import { ColumnFormatter } from 'react-bootstrap-table-next';
import { useAppIntl } from 'app/helpers';
import { DotsMenu, DotsMenuItem } from 'app/components';
import { invoicingProcessContentId } from 'app/features/invoicingProcess/process/process';
import { FormatExtraData, ExtendedItem } from 'app/features/invoicingProcess/process/items/types';

interface Props extends FormatExtraData {
  item: ExtendedItem;
}

const Actions = ({ deleteItem, editItem, item }: Props) => {
  const { formatMessage } = useAppIntl();

  const items = useMemo<DotsMenuItem[]>(
    () => [
      {
        isVisible: !!editItem,
        label: formatMessage({ id: 'CORE.BUTTON.EDIT' }),
        onClick: () => editItem && editItem(item),
      },
      {
        isVisible: !!deleteItem,
        label: formatMessage({ id: 'CORE.BUTTON.DELETE' }),
        onClick: () => deleteItem && deleteItem(item),
      },
    ],
    [deleteItem, editItem, formatMessage, item],
  );

  return <DotsMenu container={`#${invoicingProcessContentId}`} items={items} />;
};

export const ActionsFormatter: ColumnFormatter<ExtendedItem, FormatExtraData> = (_, row, __, formatExtraData) => (
  <Actions item={row} {...formatExtraData} />
);
