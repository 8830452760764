import { PayloadAction } from '@reduxjs/toolkit';
import { refreshAppointmentList } from 'app/sagas/apppointment/generators';
import { bulkActionTypes } from 'app/conflict-appointments/conflict-appointments.constants';
import { BULK_ACTION_CONFLICT_APPOINTMENTS_SUCCESS } from 'app/redux/conflict-appointments/conflict-appointments.types';

export function* afterBulkAction({ meta, type }: PayloadAction<any, string, { type?: string }>) {
  if (type === BULK_ACTION_CONFLICT_APPOINTMENTS_SUCCESS && meta.type === bulkActionTypes.CancelAll) {
    yield refreshAppointmentList();
  }
}
