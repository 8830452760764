import { useMemo } from 'react';
import { DateAndTime } from 'app/appointment/shared';
import { FORMATS_EXTENDED_DATE_AND_TIME } from 'app/shared';
import { DescriptionList, ProtectedPhoneNumber } from 'app/components';
import { AppointmentStatusWithAction } from 'app/appointment/ticket/components';
import { formattedDate, getPatientFullName, getTranslation, useAppIntl, useAppSelector } from 'app/helpers';
import { SectionHeading } from 'app/features/invoicingProcess/process/components/sectionHeading/sectionHeading';

export const Appointment = () => {
  const { formatMessage, locale } = useAppIntl();
  const invoicingProcess = useAppSelector((state) => state.invoicingProcess.data);

  const items = useMemo(() => {
    if (!invoicingProcess || !invoicingProcess.appointment) {
      return [];
    }

    const appointment = invoicingProcess.appointment;
    const phoneNumber = invoicingProcess.patient?.mobileNumber || null;

    return [
      {
        label: formatMessage({ id: 'CORE.LABEL.STATUS' }),
        value: <AppointmentStatusWithAction appointment={appointment} statusChangeAvailable={false} />,
      },
      {
        label: formatMessage({ id: 'CORE.TABLE.DATE-AND-TIME' }),
        value: <DateAndTime appointment={{ ...appointment, isConflicting: false }} conflictingLabel={false} inline />,
      },
      {
        label: formatMessage({ id: 'CORE.LABEL.PATIENT-NAME' }),
        value: getPatientFullName(invoicingProcess?.patient, locale),
      },
      {
        label: formatMessage({ id: 'CORE.LABEL.PHONE-NUMBER' }),
        value: <ProtectedPhoneNumber phoneNumber={phoneNumber} withoutProtection />,
      },
      {
        label: formatMessage({ id: 'CORE.LABEL.INTERNAL-FILE-NUMBER' }),
        value: appointment.internalFileNumber,
      },
      {
        label: formatMessage({ id: 'CORE.LABEL.DOCTOR' }),
        value: getTranslation(invoicingProcess.doctor, locale),
      },
      {
        label: formatMessage({ id: 'CORE.TABLE.MEDICAL-INSTITUTION' }),
        value: getTranslation(invoicingProcess.clinic, locale),
      },
      {
        label: formatMessage({ id: 'CORE.TABLE.BRANCH' }),
        value: getTranslation(invoicingProcess.clinicBranch, locale),
      },
      {
        label: formatMessage({ id: 'CORE.LABEL.MEDICAL-SPECIALITY' }),
        value: getTranslation(appointment.speciality, locale),
      },
      {
        label: formatMessage({ id: 'CORE.TABLE.MEDICAL-SERVICE' }),
        value: getTranslation(appointment.subSpeciality, locale),
      },
      {
        label: formatMessage({ id: 'CORE.TEXT.SERVICE' }),
        value: getTranslation(appointment.product, locale),
      },
      {
        label: formatMessage({ id: 'CORE.LABEL.CREATOR' }),
        value: appointment.createdBy,
      },
      {
        label: formatMessage({ id: 'CORE.LABEL.CREATE-DATE' }),
        value: formattedDate(appointment.createdAt, FORMATS_EXTENDED_DATE_AND_TIME),
      },
    ];
  }, [formatMessage, invoicingProcess, locale]);

  return (
    <>
      <SectionHeading title={formatMessage({ id: 'CORE.LABEL.APPOINTMENT', defaultMessage: 'Appointment' })} />
      <DescriptionList items={items} rowProps={{ md: 1, lg: 2, xl: 3 }} />
    </>
  );
};
