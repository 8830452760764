import { GovernmentIssuedIdType, Option } from 'app/types';
import { InvoicingProcess } from 'app/redux/invoicingProcess/types';
import { PaymentModeFormData } from 'app/features/invoicingProcess/process/paymentMode/form/types';
import { getPatientFieldValue } from 'app/features/invoicingProcess/process/helpers/getPatientFieldValue';
import { mapInsuranceClassPatientSharesToFields } from 'app/features/invoicingProcess/process/helpers/mapInsuranceClassPatientSharesToFields';

export const getPaymentModeInitialValues = (
  invoicingProcess: InvoicingProcess,
): Partial<PaymentModeFormData> | undefined => {
  const insurance = invoicingProcess.steps?.paymentMode?.insurance;
  const activePatientInsurance = invoicingProcess.activePatientInsurance;

  let result: Partial<PaymentModeFormData> = {
    payBy: invoicingProcess.steps?.paymentMode?.payBy,
    governmentIssuedId: getPatientFieldValue(invoicingProcess, 'governmentIssuedId') as string | undefined,
    governmentIssuedIdType: getPatientFieldValue(invoicingProcess, 'governmentIssuedIdType') as
      | Option<GovernmentIssuedIdType>
      | undefined,
  };

  if (activePatientInsurance) {
    result = {
      ...result,
      end: new Date(activePatientInsurance.details.end),
      insuranceClass: activePatientInsurance.details.insuranceClass,
      insuranceCompany: activePatientInsurance,
      insuranceNumber: activePatientInsurance.details.number,
      policyNumber: activePatientInsurance.details.insurancePolicy,
      start: new Date(activePatientInsurance.details.start),
      // Shares
      maxPatientShare: String(activePatientInsurance.details.maxPatientShare),
      patientShare: String(activePatientInsurance.details.patientShare),
      ...mapInsuranceClassPatientSharesToFields(activePatientInsurance.details.insuranceClass?.details?.patientShares),
    };
  }

  if (insurance) {
    result = {
      ...result,
      approvalNumber: insurance.approvalNumber,
      approvalThreshold: String(insurance.approvalThreshold),
      approvalType: insurance.approvalType,
      benefitLimit: String(insurance.benefitLimit),
      eligibility: insurance.eligibilityType,
      eligibilityNumber: insurance.eligibilityNumber,
      end: insurance.end ? new Date(insurance.end) : undefined,
      insuranceApproval: insurance.insuranceApproval || undefined,
      insuranceClass: insurance.insuranceClass,
      insuranceCompany: insurance.company,
      insuranceNumber: insurance.number,
      insuranceReferralApproval: insurance.referralInsuranceApproval,
      maxConsultFee: String(insurance.maxConsultFee),
      policyNumber: insurance.insurancePolicy,
      start: insurance.start ? new Date(insurance.start) : undefined,
      // Shares
      maxPatientShare: String(insurance.maxPatientShare),
      patientShare: String(insurance.patientShare),
      ...mapInsuranceClassPatientSharesToFields(insurance.insuranceClass?.details?.patientShares),
    };
  }

  return result;
};
