import React, { useCallback } from 'react';
import { OptionContext } from 'react-select';
import { Option, InvoicePayBy } from 'app/types';
import { useAppIntl } from 'app/helpers';

export const usePayByOptionFormatter = (doctorCertificate?: string) => {
  const { formatMessage } = useAppIntl();

  return useCallback(
    (option: Option, meta: { context: OptionContext }) => {
      if (meta.context === 'value' || option.value !== InvoicePayBy.Insurance || !!doctorCertificate) {
        return <span>{option.label}</span>;
      }

      return (
        <>
          <div>
            <span>{option.label}</span>
          </div>
          <div>
            <span className="fw-lighter small lh-1">
              {formatMessage({
                id: 'CORE.TEXT.PRACTITIONER-CERTIFICATE-NUMBER-MISSING-WARNING',
                defaultMessage: 'Practitioner certificate number is required',
              })}
            </span>
          </div>
        </>
      );
    },
    [doctorCertificate, formatMessage],
  );
};
