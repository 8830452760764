import { useCallback } from 'react';
import { useAppDispatch, useAppSelector } from 'app/helpers';
import { toggleMenu } from 'app/redux/app/app.actions';

export const useMenu = () => {
  const dispatch = useAppDispatch();
  const isExpanded = useAppSelector((state) => state.app.menu.isExpanded);

  const expand = useCallback(() => dispatch(toggleMenu(true)), [dispatch]);
  const collapse = useCallback(() => dispatch(toggleMenu(false)), [dispatch]);
  const toggle = useCallback(() => dispatch(toggleMenu()), [dispatch]);

  return {
    collapseMenu: collapse,
    expandMenu: expand,
    isMenuExpanded: isExpanded,
    toggleMenu: toggle,
  };
};
