import moment from 'moment';
import { Appointment } from 'app/types';
import { PatientInfoShort } from 'app/shared/patientSearch/patientShortDetails/types';

export const appointmentToPatientShortAdapter = (appointment: Appointment): PatientInfoShort => {
  const age = appointment.patientDateOfBirth ? moment().diff(moment(appointment.patientDateOfBirth), 'years') : 0;
  return {
    id: appointment.patientId,
    clinicyMrn: appointment.patientClinicyMrn,
    fullNameArabic: appointment.patientName,
    fullNameEnglish: appointment.patientName,
    age,
    dateOfBirth: appointment.patientDateOfBirth,
    gender: appointment.patientGender,
    governmentIssuedId: appointment.governmentIssuedId || '',
    governmentIssuedIdType: appointment.governmentIssuedIdType,
    patientType: appointment.patientType,
    status: appointment.patientStatus,
  };
};
