import { AnyAction } from 'redux';
import { RSAAFailureType, RSAARequestType, RSAASuccessType } from 'redux-api-middleware';
import { DEFAULT_PAGINATION_PARAMS } from 'app/shared';
import { RemoteData, RemoteDataParams } from 'app/types';
import * as types from '../remoteData.types';
import { RemoteDataAction, RemoteDataActionConfiguration, RemoteDataEntityName } from '../types';

/** Generate a default state for data collection */
export const generateInitialState = (): RemoteData => ({
  data: [],
  error: false,
  loading: false,
  params: DEFAULT_PAGINATION_PARAMS,
});

/** Generate new parameters after getting a response from the server */
export const generateParams = (action: RemoteDataAction | AnyAction) => {
  const params = action.meta.params;
  const pagination = action.payload.pagination;

  return {
    ...params,
    ...(pagination && { totalSize: pagination.totalItemCount }),
  };
};

/** Generate generic types */
export const generateTypes = (
  name: RemoteDataEntityName,
  params: object,
  configuration?: RemoteDataActionConfiguration,
): [RSAARequestType, RSAASuccessType, RSAAFailureType] => {
  const isSilent = configuration?.isSilent;
  const isSoft = configuration?.isSoft;

  return [
    {
      type: types.FETCH_DATA_REQUEST,
      meta: { name, isSilent, isSoft },
    },
    {
      type: types.FETCH_DATA_SUCCESS,
      meta: { name, params },
    },
    {
      type: types.FETCH_DATA_FAILURE,
      meta: { name },
    },
  ];
};

/** Adjust the parameters to the API requirements */
export const paramsAdapter = (params: RemoteDataParams) => ({
  ...params,
  pageNumber: params.page,
  pageSize: params.sizePerPage,

  // Clear Bootstrap Table params
  page: undefined,
  sizePerPage: undefined,
  totalSize: undefined,
});
